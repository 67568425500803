import React, { useState, useEffect } from 'react'
import {
    createStyles,
    makeStyles,
    Theme,
    Button,
    Grid,
    TextField,
    Box
} from "@material-ui/core";
import { connect } from 'react-redux';
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import AssetTypeService from '../../../services/assetType';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';

import {toast} from 'react-toastify';
toast.configure();
const assetTypeApi = new AssetTypeService();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            fontSize: "12pt",
            boxShadow: "none",
        },
        pageHeader: {
            fontSize: "1.5rem",
        },
        pageTopBar: {
            backgroundColor: "#fff",
        },
        item1: {
            color: "red"
        }
    })
);

export interface IAssetProps {
    user: any
  }

function Asset(props: IAssetProps) {
    const [asset, setAsset] = useState("");
    const [status, setStatus] = useState("");
    const [assetTypes, setAssetType] = useState([]);
    const[actualStatus, setActualStatus ] = useState(false);
    let history = useHistory();
    const headers = {
        'Authorization': `Bearer ${props.user.token}`,
        'X-Frame-Options':'DENY'
    }

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
        setActualStatus(true);
    };

    const handleChangeSelect = (event: SelectChangeEvent) => {
        if(event.target.value === 'true') {
            setStatus(event.target.value as string);
            setActualStatus(true);
            //setAge(event.target.value as string);
        } else if(event.target.value === 'inactive'){
            setStatus(event.target.value as string);
            setActualStatus(false);
        } else {setStatus(event.target.value as string);
            setActualStatus(false);
        }
    };
    
    const handleUpdate = () => {
    //   console.log(asset);
      assetTypeApi.updateAssetType(headers, asset).then((res: any) => {
        // console.log(res);
        // setAssetType(res);
        history.push('/AssetType')   
      });
    }

    const getAssetTypes = () => {
        assetTypeApi.getAssetsType(headers).then((res: any) => {
            // console.log(res);
            setAssetType(res);   
        });
    }
    useEffect(()=>{
      getAssetTypes();
    },[]);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.pageTopBar}>
                <div className={classes.pageHeader}>
                    ASSET
                </div>
                <hr></hr>

                <div className="layout-border">
                    <Stack direction="column" spacing={3}>
                        <Box>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ color: "red" }}>
                                <InputLabel id="demo-simple-select-label">Asset Type</InputLabel>
                                <Select
                                    // labelId="demo-simple-select-label",
                                    id="demo-simple-select"
                                    value={asset}
                                    label="Asset Status"    
                                    data-testid='assettype'
                                    onChange={handleChange}
                                >
                                    {assetTypes.map((asset)=> {
                                       return <MenuItem value={asset.id} style={{color:"#000"}}>{asset.name}</MenuItem>
                                    })}
                                    
                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ color: "red" }}>
                            <InputLabel id="demo-simple-select-label">Asset Status</InputLabel>
                                <Select
                                    // labelId="demo-simple-select-label1",
                                    id="demo-simple-select"
                                    value={status}
                                    label="Asset Status"
                                    onChange={handleChangeSelect}
                                >
                                    <MenuItem className={classes.item1} value={'true'} sx={{ color: 'green' }} style={{color:"#000"}}>Active</MenuItem>
                                    <MenuItem value={'inactive'} sx={{ color: 'red' }} style={{color:"#000"}}>Inactive</MenuItem>
                                    <MenuItem value={'false'} sx={{ color: 'yellow' }} style={{color:"#000"}}>Under Maintenance</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        </Grid>      
                        </Box>

                        <Button fullWidth type="button" color="primary" variant="contained" onClick={handleUpdate}>
                            Submit
                        </Button>
                    </Stack>

                </div>
            </div>
        </div>

    )
}

function mapStateToProps(state: any, ownProps: {}) {
    // console.log(state);
    return {
      user: state.user.currentUser
    };
  }
export default connect(mapStateToProps, null)(Asset);