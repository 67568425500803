import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import TelemeteryAPI from "../../../../services/telemeteryService";
import source from "../../../../assets/images/source.svg";
import destination from "../../../../assets/images/destination.svg";
import ShipperHelper, { defaultZoomLevel } from "../../../../services/_helpers";
import moment from "moment";
import axios from "axios";

declare var MapmyIndia: any; // Declaring Mapmyindia
declare var L: any;

export function RouteMap(props: any) {
  // const [waypoints, setWaypoints] = useState([]);
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const apikey = process.env.REACT_APP_HERE_MAP_KEY;
  // const waypoints = [
  //   { lat: 64.144, lng: -21.94 }, // Starting point
  //   { lat: 64.150, lng: -21.93 }, // Waypoint 1
  //   { lat: 64.156, lng: -21.92 }, // Waypoint 2
  //   { lat: 64.162, lng: -21.91 }, // Waypoint 3
  //   { lat: 64.168, lng: -21.90 }, // Waypoint 4
  //   { lat: 64.174, lng: -21.89 }, // Waypoint 5
  //   { lat: 64.180, lng: -21.88 }, // Waypoint 6
  //   { lat: 64.186, lng: -21.87 }, // Waypoint 7
  // ];

  useEffect(() => {
    const telemetryService = new TelemeteryAPI();
    const getPath = async () => {
      if (props.assetId) {
        let fromDate: any = moment().subtract(1, "days");
        let toDate: any = moment();
        let parsedFromDate = moment(fromDate).utc();
        fromDate = parsedFromDate.format("YYYYMMDDHHmm");
        let parsedToDate = moment(toDate).utc();
        toDate = parsedToDate.format("YYYYMMDDHHmm");
        if (props.actualPickUpTime) {
          fromDate = moment.utc(props.actualPickUpTime).format("YYYYMMDDHHmm");
        }
        axios
          .get(
            `${process.env.REACT_APP_API_TELEMETRY}/telemetry/gatewayanddevice?from=${fromDate}&to=${toDate}&assetId=${props.assetId}&tripId=${props.tripId}`
          )
          .then((res) => {
            let waypoints: any = [];
            if (res.data && res.data.length) {
              // let waypoints;
              res.data.forEach((ele: any) => {
                if (+ele.lat  && +ele.lng ) {
                  // const point = [...waypoints, { lat: ele.lat, lng: ele.lng }];
                  // setWaypoints((prevWaypoints) => [
                  //   ...prevWaypoints,
                  //   { lat: ele.lat, lng: ele.lng },
                  // ]);
                  waypoints.push({ lat: ele.lat, lng: ele.lng })
                }
              });
              if (!map.current && waypoints.length > 0) {
                platform.current = new H.service.Platform({ apikey });
          
                const defaultLayers = platform.current.createDefaultLayers();
          
                const newMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                  pixelRatio: window.devicePixelRatio,
                  zoom: defaultZoomLevel,
                });
          
                const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap));
          
                const routeGroup = new H.map.Group();
                newMap.addObject(routeGroup);
                // If there is only one waypoint, add a marker and zoom to it
              if (waypoints.length === 1) {
                const singleMarker = new H.map.Marker(
                  new H.geo.Point(
                    waypoints[0].lat,
                    waypoints[0].lng
                  ),
                  {
                    icon: new H.map.Icon(source, { size: { w: 30, h: 30 } }),
                    data: {},
                  }
                );
                routeGroup.addObject(singleMarker);
                map.current = newMap;
                // Zoom to the single marker
                newMap.setCenter({ lat: waypoints[0].lat, lng: waypoints[0].lng });
                newMap.setZoom(12);
              } 
              else {
                const lineString = new H.geo.LineString();
                waypoints.forEach((waypoint: any) =>
                  lineString.pushPoint(new H.geo.Point(waypoint.lat, waypoint.lng))
                );
          
                const routeLine = new H.map.Polyline(lineString, {
                  style: {
                    lineWidth: 8,
                    strokeColor: 'green',
                  },
                  data: {},
                });

                const sourceIconMarker = new H.map.Marker(
                  new H.geo.Point(waypoints[0].lat, waypoints[0].lng),
                  {
                    icon: new H.map.Icon(source, { size: { w: 30, h: 30 } }),
                    data: {}, // Add an empty data property to satisfy the Options type
                  }
                );
                
                const destinationIconMarker = new H.map.Marker(
                  new H.geo.Point(waypoints[waypoints.length - 1].lat, waypoints[waypoints.length - 1].lng),
                  {
                    icon: new H.map.Icon(destination, { size: { w: 30, h: 30 } }),
                    data: {}, // Add an empty data property to satisfy the Options type
                  }
                );
                
                routeGroup.addObject(sourceIconMarker);
                routeGroup.addObject(destinationIconMarker);
          
                routeGroup.addObject(routeLine);
          
                let minLat = Number.MAX_VALUE;
                let minLng = Number.MAX_VALUE;
                let maxLat = Number.MIN_VALUE;
                let maxLng = Number.MIN_VALUE;
          
                lineString.eachLatLngAlt((lat, lng) => {
                  minLat = Math.min(minLat, lat);
                  minLng = Math.min(minLng, lng);
                  maxLat = Math.max(maxLat, lat);
                  maxLng = Math.max(maxLng, lng);
                });
                const lastWaypoint = waypoints[waypoints.length - 1];
                const routeBounds = new H.geo.Rect(waypoints[0].lat, waypoints[0].lng, lastWaypoint.lat, lastWaypoint.lng);
          
                newMap.getViewModel().setLookAtData({
                  bounds: routeBounds,
                  zoom: 12
                });
          
                map.current = newMap;
              }
            }
              // if (!map.current && waypoints.length > 0) {
              //   platform.current = new H.service.Platform({ apikey });
          
              //   const defaultLayers = platform.current.createDefaultLayers();
          
              //   const newMap = new H.Map(
              //     mapRef.current,
              //     defaultLayers.vector.normal.map,
              //     {
              //       pixelRatio: window.devicePixelRatio,
              //       center: { lat: waypoints[0].lat, lng: waypoints[0].lng },
              //       zoom: 12,
              //     }
              //   );
          
              //   const behavior = new H.mapevents.Behavior(
              //     new H.mapevents.MapEvents(newMap)
              //   );
          
              //   // Create a group that will hold the route
              //   const routeGroup = new H.map.Group();
              //   newMap.addObject(routeGroup);
          
              //   // Create a polyline with a green stroke for the route
              //   const lineString = new H.geo.LineString();
              //   waypoints.forEach((waypoint: any) =>
              //     lineString.pushPoint(new H.geo.Point(waypoint.lat, waypoint.lng))
              //   );
          
              //   const routeLine = new H.map.Polyline(lineString, {
              //     style: {
              //       lineWidth: 5,
              //       strokeColor: "green",
              //     } as H.map.SpatialStyle,
              //     data: {}, // Add an empty data property to satisfy the Options type
              //   });
          
              //   // Add the polyline to the route group
              //   routeGroup.addObject(routeLine);
          
              //   map.current = newMap;
              // }
            }
          });
      }
    };

    getPath();
  });

 // Function to calculate optimal zoom level based on bounding box
function calculateOptimalZoom(bounds: any, map: any) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const ne = map.geoToScreen({ lat: bounds.getTop(), lng: bounds.getRight() });
  const sw = map.geoToScreen({ lat: bounds.getBottom(), lng: bounds.getLeft() });

  const dx = ne.x - sw.x;
  const dy = sw.y - ne.y;

  const zoomX = Math.log(WORLD_DIM.width * 360 / dx / 256) / Math.LN2;
  const zoomY = Math.log(WORLD_DIM.height * 180 / dy / 256) / Math.LN2;

  return Math.min(ZOOM_MAX, Math.min(zoomX, zoomY));
}


  // useEffect(() => {
  //   if (!map.current && waypoints.length > 0) {
  //     platform.current = new H.service.Platform({ apikey });

  //     const defaultLayers = platform.current.createDefaultLayers();

  //     const newMap = new H.Map(
  //       mapRef.current,
  //       defaultLayers.vector.normal.map,
  //       {
  //         pixelRatio: window.devicePixelRatio,
  //         center: { lat: waypoints[0].lat, lng: waypoints[0].lng },
  //         zoom: 12,
  //       }
  //     );

  //     const behavior = new H.mapevents.Behavior(
  //       new H.mapevents.MapEvents(newMap)
  //     );

  //     // Create a group that will hold the route
  //     const routeGroup = new H.map.Group();
  //     newMap.addObject(routeGroup);

  //     // Create a polyline with a green stroke for the route
  //     const lineString = new H.geo.LineString();
  //     waypoints.forEach((waypoint: any) =>
  //       lineString.pushPoint(new H.geo.Point(waypoint.lat, waypoint.lng))
  //     );

  //     const routeLine = new H.map.Polyline(lineString, {
  //       style: {
  //         lineWidth: 5,
  //         strokeColor: "green",
  //       } as H.map.SpatialStyle,
  //       data: {}, // Add an empty data property to satisfy the Options type
  //     });

  //     // Add the polyline to the route group
  //     routeGroup.addObject(routeLine);

  //     map.current = newMap;
  //   }
  // }, [apikey, waypoints]);
  //     useEffect(()=>{

  //       const telemetryService = new TelemeteryAPI();

  //       if(props.assetId){
  //         let fromDate: any = moment().subtract(1, 'days');
  //         let toDate: any = moment();
  //         let parsedFromDate = moment(fromDate).utc();
  //         fromDate = parsedFromDate.format("YYYYMMDDHHmm")
  //         let parsedToDate = moment(toDate).utc()
  //         toDate =  parsedToDate.format("YYYYMMDDHHmm")
  //         if(props.actualPickUpTime){
  //         fromDate = moment.utc(props.actualPickUpTime).format("YYYYMMDDHHmm")
  //         }
  //         axios.get(`${process.env.REACT_APP_API_TELEMETRY}/telemetry/gatewayanddevice?from=${fromDate}&to=${toDate}&assetId=${props.assetId}&tripId=${props.tripId}`)
  //         .then(res => {
  //           if(res.data && res.data.length){
  //             res.data.forEach((ele: any) => {
  //               if(+ele.lat > 10 && +ele.lng > 10){
  //                 waypoints.push({
  //                   lat: ele.lat,
  //                   lng: ele.lng
  //                 })
  //               }
  //             })
  //           }
  //         //   poly.push( new L.LatLng(value.latitude, value.longitude),)
  //         // })

  //        /* istanbul ignore next */
  //       var center = poly[0];
  //        /* istanbul ignore next */
  //       var container = L.DomUtil.get('map1');
  //        /* istanbul ignore next */
  //       if(container != null){ container._leaflet_id = null; }
  //         /* istanbul ignore next */
  //       map1 = new MapmyIndia.Map('map1', {center:center, zoomControl: true, hybrid: true});
  //       /* istanbul ignore next */
  //       map1.setZoom(defaultZoomLevel);

  //        /* istanbul ignore next */
  //       var poly1param = {
  //         weight: 4,
  //         opacity: 0.5,
  //         color:'#1A0DAB'
  //     };

  //     /* istanbul ignore next */
  //     var poly1 = new L.Polyline(poly, poly1param);
  //      /* istanbul ignore next */
  //     poly1.addTo(map1);
  //      /* istanbul ignore next */
  //       var icon = L.icon({
  //          iconUrl: source,
  //          iconRetinaUrl: source,
  //         iconSize: [30, 30],
  //         popupAnchor: [-3, -15]
  //     });
  //      /* istanbul ignore next */
  //     var icon2= L.icon({
  //       iconUrl: destination,
  //       iconRetinaUrl: destination,
  //      iconSize: [30, 30],
  //      popupAnchor: [-3, -15]
  //  });/* istanbul ignore next */
  //       var mk = new L.Marker( poly[0], {icon: icon,title:"Start"});/*marker source */
  //        /* istanbul ignore next */

  //       /* istanbul ignore next */
  //       var mk = new L.Marker  (poly[poly.length-1], {icon: icon2,title:"End"});/*marker Destination */
  //        /* istanbul ignore next */
  //       mk.addTo(map1);
  //         /* istanbul ignore next */
  //       map1.on("contextmenu", function (e:any)
  //       {

  //         map1.panTo(poly[0],true,1.0);
  //          //setTimeout(()=>{map.panTo(poly[0],true,1.0);},1000)
  //          setTimeout(()=>{ map1.setZoom(defaultZoomLevel);},1000)

  //       });

  // }).catch((err:any)=>{
  //    /* istanbul ignore next */
  //   var container = L.DomUtil.get('map1');
  //    /* istanbul ignore next */
  //       if(container != null){ container._leaflet_id = null; }
  //  /* istanbul ignore next */
  //       map1=new MapmyIndia.Map('map1', {center:[28.61, 77.23], zoomControl: true, hybrid: true});
  // })
  //       }

  //     },[])

  return (
    <div>
      <div style={{ width: "100%", height: "500px" }} ref={mapRef} />
    </div>
  );
}