import  React, {} from 'react';
import { Field, useField, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { at } from 'lodash';

// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     container: {
//       display: "flex",
//       flexWrap: "wrap"
//     },
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       width:220
//     }
//   })
// );

export default function StartDatePickerField(props:any) {
 const [field, meta] = useField(props);
//  const classes = useStyles();
   
  const { value } = field;

  const onChange = React.useCallback((e: any) => {
    setFieldValue(field.name, e.target.value);
  }, [field.name, props.value, value, field.value]);
  
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
  
  const { setFieldValue } = useFormikContext();

  

  return (
    <Grid container>
      <Field
        component={TextField}
        name={field.name}
        onChange={onChange}
        variant="outlined"
        data-testid="startDatePicker"
        {...props}
        type="date"
        value={ field.value || props.value || value }
        error={meta.touched && meta.error && true}
        helperText={_renderHelperText()}
        InputLabelProps={{
          shrink: true,
        }} />
      {/* <TextField
        variant="outlined"
        id="date"
        {...field}
        {...props}
        {...rest}
        type="date"
        value={ props.value || value }
        error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
        InputLabelProps={{
          shrink: true,
        }}

        
      /> */}
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker 
          {...field}
          {...props}
          value={selectedDate}
          onChange={_onChange}
          style={{width:400}}
          //error={isError}
          //invalidDateMessage={isError && error}
          //helperText={isError && error}
          //className={classes.dateField}
          
        />
      </MuiPickersUtilsProvider> */}
    </Grid>
  );
}
