import { useEffect } from 'react';
import './style.css';
import { defaultZoomLevel } from '../../../../services/_helpers';
import { Grid } from '@material-ui/core';
import Hubs from "../../../../assets/images/Hubs.svg";
import Customer from "../../../../assets/images/Customer.svg";

declare var mappls: any; // Declaring Mapmyindia
declare var L: any;
const Headingstyle: any = {
    textAlign: 'left',
    marginLeft: '20px',
    paddingTop: '1rem',
   marginTop:'-24px',
    fontFamily: 'Roboto',
    
    fontSize: '1rem',
    color: "#000000DE"
}

export default function HubLocation(props: any) {

  var map: any = null;
  useEffect(() => {
    document.getElementById('weathermap').innerHTML = ''
    document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
    map = new mappls.Map('map', {
      center: [28.61, 77.23],
      zoom: defaultZoomLevel,
    });
    map.setZoom(defaultZoomLevel);
  }, [])


  return (
    
    <div >
          <div style={Headingstyle}>Customer / Hub Locations</div>

      <Grid item xs={12} sm={12} lg={12}>
            <div className="hoverDiv" style={{marginRight:"1rem",height:"auto" }}>
            
              <p className="hoverP"  >Customer</p><img alt="img1" src={Customer} className="hoverImg" />
              <p className="hoverP"  >Hubs</p><img alt="img1" src={Hubs} className="hoverImg" />
             </div>
      <div id='weathermap' style={{marginTop:"38px",marginLeft:"20px",marginRight:"20px"}}></div>
      </Grid>
    </div>
  )
}