import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Stepper, Step, StepLabel, Button, Grid, Paper, Box } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import { IconButton } from "@material-ui/core";
import checkoutFormModel from "../../../CheckoutPage/FormModel/checkoutFormModel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import ArrowForward from "@mui/icons-material/ArrowForward";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {  useParams, useLocation,useHistory, Link } from "react-router-dom";
import ShipperHelper, {
  ShippingRequestStatus,
} from "../../../../../services/_helpers";
import { assetBaseURL, marketBaseURL } from "../../../../../services/_url";
import DoneIcon from "@material-ui/icons/Done";
import UserService from "../../../../../services/userService";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import Editasset from "./Editasset";
import { setCurrentUser } from "../../../../../redux/user/user.action";
import { selectCurrentUser } from "../../../../../redux/user/user.selector";

import { ResponsiveDrawer } from "../../../common/Drawer";
import { toast } from "react-toastify";

import formInitialValues from "../../../Denso/CreateShipment/FormModel/formInitialValues";
import { createStructuredSelector } from "reselect";
import ShipperAPI from "../../../../../services/shippingLanding";
import moment from "moment";
import { log } from "console";

const steps = ["Basic Details"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginRight: "48px",
    },
    backBtn: {
      color: "#1A0DAB",
    },
  })
);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    color: "#5A5A5A",
  },
  active: {
    "& $line": {
      borderColor: "#0078A5",
      backgroundColor: "#0078A5",
    },
    color: "#5A5A5A",
  },
  paper: {
    textAlign: "center",
    color: "#000",
    minHeight: "15rem",
  },

  completed: {
    "& $line": {
      borderColor: "#007AA6",
    },
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: "#D8EEF3",
  },
})(StepConnector);

const { formId, formField } = checkoutFormModel;

function _renderStepContent(
  assetFormHandler : (name:string, value:any) => void,
  hardwareFormHandler : (name:string, value:string) => void,
  locationFormHandler : (name:string, value:string) => void,
  step: number,
  setFieldValue?: any,
  values?: any,
  getrequestId?: any,  
  locationData?:any,
  storedData?:any,
) {
  switch (step) {
    case 0:
      return <Editasset formData={assetFormHandler} location={locationData}/>;
   
    default:
      return <div>Internal Error.</div>;
  }
}

export function EditAsset(props: any) {
  const id: any = useParams();
  let {   currentUser, setCurrentUser } = props;
  const location = useLocation();
  const [searchReqId, setSearchReqId] = useState<string>("");
  let history = useHistory();
  const item:any = location.state;
  const { activeStepProp } = props;
  const [activeStep, setActiveStep] = useState(
    activeStepProp ? activeStepProp : 0
  );
  const [btnFlag, setBtnFlag] = useState(false);
  const [getrequestId, setgetrequestId] = useState("");
  const [tentant, SetTentant] = React.useState([]);
//   const isLastStep = activeStep === steps.length - 1;
  const classes = useStyles();
  const [locationDataHandler, setLocationDataHandler] = useState({locality:"", city:"", zone:"", region:"",createdAt:"",lastUpdatedAt:""});
  const [assetDataHandler, setAssetDataHandler] = useState({AssetType:"",AssetNumber:"",AssetID:"",status:"",id:"", CustomerAssetType: "",id1:"",underMaintenance:"",backup:""});
  const [hardwareDataHandler, setHardwareDataHandler] = useState({GatewayId:"",HardwareKitId:""});
  const [assetId, SetAssertId] = useState(item[0].id);
  const [tenantID,setTenantID] = useState(item[1])
  const [currentTenant,setCurrentTenant] = useState("")
  const [newtenantID,setNewTenantID] = useState()
  const [tenantAssignmentID,setTenantAssignmentID] = useState(item[2])
  var updateInitalValues = {
    
  };
  const assetFormHandler = (name:string, value:any) => {
    //setAssetDataHandler(value);
    setAssetDataHandler(prevState => ({
      ...prevState, [name]:value
    }))
   
  }
  
  const hardwareFormHandler = (name:string, value:string) => {
    //setHardwareDataHandler(value);
    setHardwareDataHandler(prevState => ({
      ...prevState, [name]:value
    }))
  }
  
  const locationFormHandler = (name:string, value:string) => {
   // setLocationDataHandler(value);
   setLocationDataHandler(prevState => ({
    ...prevState, [name]:value
  }))
  }

  const success = () => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Asset Edited Succesfully.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const isUpdateMode = !!id["id"];
  //   const currentValidationSchema = validationSchema[activeStep];
  //   const updatevalidationSchema = UpdatevalidationSchema[activeStep];
  //   const [activeStep, setActiveStep] = useState(
  //     activeStepProp ? activeStepProp : 0
  //   );

  const getTransporterId: any = (name: any) =>
    tentant.filter((val: any) => val.name === name);

  function _submitForm(values: any, actions: any) {
    let Ship = new ShipperHelper();
    setBtnFlag(true);
   
    
 try {
  let postData = {


    id:assetDataHandler.id !== "" ? assetDataHandler.id1 : item[0].id,
    
  assetType:{   
    active : assetDataHandler.status !== "Yes"  ? false : true,
    
    status:assetDataHandler.status !== "" ? assetDataHandler.status : item[0].assetType.status,
    id:assetDataHandler.id !== "" ? assetDataHandler.id   : item[0].assetType.id,
    name:assetDataHandler.AssetType !== "" ? assetDataHandler.AssetType : item[0].assetType.name,
    // createdAt:locationDataHandler.createdAt && locationDataHandler.createdAt !== "" ? locationDataHandler.createdAt:item.createdAt,
    description:item[0].assetType.description,
    // lastUpdatedAt:locationDataHandler.lastUpdatedAt && locationDataHandler.lastUpdatedAt !== "" ? locationDataHandler.lastUpdatedAt:item.lastUpdatedAt,
  },
  backup:assetDataHandler.backup !== "Yes"  ? false : true,
  city:locationDataHandler.city && locationDataHandler.city !== "" ? locationDataHandler.city : item[0].city,
  createdAt:locationDataHandler.createdAt && locationDataHandler.createdAt !== "" ? locationDataHandler.createdAt:item[0].createdAt,
  number:assetDataHandler.AssetNumber !== "" ? assetDataHandler.AssetNumber :  item[0].number,
  lastUpdatedAt:locationDataHandler.lastUpdatedAt && locationDataHandler.lastUpdatedAt !== "" ? locationDataHandler.lastUpdatedAt:item[0].lastUpdatedAt,
  locality:locationDataHandler.locality && locationDataHandler.locality !== "" ? locationDataHandler.locality :  item[0].locality,
  zone:locationDataHandler.zone && locationDataHandler.zone !== "" ? locationDataHandler.zone :  item[0].zone,
 
  region:locationDataHandler.region && locationDataHandler.region !== "" ? locationDataHandler.region :  item[0].region,
  underMaintenance:assetDataHandler.underMaintenance !== "Yes"  ? false : true,
  monitoringRequired: (item[0].monitoringRequired === true || item[0].monitoringRequired === false) ? item[0].monitoringRequired : null,
  teleMonitoringPhNo: item[0].teleMonitoringPhNo ?? null,
  teleMonitoringTimeRange: item[0].teleMonitoringTimeRange ?? null

  };
  // console.log(postData,"postdatss")
  createAPI(postData);

  let todaydate = new Date();
  todaydate.setHours(todaydate.getHours() + 6)
  let formatdate = todaydate.toISOString()
    let startdate= formatdate.replace("Z","+0000")
  let date = new Date();
  date.setFullYear(date.getFullYear() + 1)
  let enddatestring = date.toISOString()
let enddate = enddatestring.replace("Z","+0000")
  
 
  let tenantdata = {
    id: tenantAssignmentID , //assetAssignmentIdAlreadyAssigned
tenantId: newtenantID, //tenantId selected in dropdown
asset: {
active: item[0].active,
id: item[0].id,
assetType: {
active: item[0].assetType.active,
id: item[0].assetType.id,
name: item[0].assetType.name,
description: item[0].assetType.description
},
number: item[0].number
},
startDate: startdate,
endDate: enddate
  }
  let newtenantdata={
    tenantId: newtenantID, 
    asset: {
    active: item[0].active,
    id: item[0].id,
    assetType: {
    active: item[0].assetType.active,
    id: item[0].assetType.id,
    name: item[0].assetType.name,
    description: item[0].assetType.description
    },
    number: item[0].number
    },
    startDate: startdate,
    endDate: enddate
  }
  assignTenant(tenantdata,newtenantdata)
} catch (e) {
  return e;
}  
  }
  
  const createAPI = async(postData:any) => {
    // console.log(postData,"postdatahardware  ")
    let resultData = await axios.patch(`${assetBaseURL}/assets/${assetId}`,postData).then((res)=>{
        if (res.status === 200) {
            success();
          }
     // return res.data;
     
    }).catch((err: any) => {});
  }

  const assignTenant = async(tenantData:any,newtenantdata:any) => {
    if (currentTenant !== "" && currentTenant !== assetDataHandler.CustomerAssetType){
      let resultData = await axios.patch(`${assetBaseURL}/assetAssignments/${tenantAssignmentID}`,tenantData).then((res)=>{
     // return res.data;
     
    }).catch((err: any) => {});

    }else if(currentTenant === "" && assetDataHandler.CustomerAssetType !== ""){
      let resultData = await axios.post(`${assetBaseURL}/assetAssignments`,newtenantdata).then((res)=>{
        
     // return res.data;
     
    }).catch((err: any) => {});
    }
    
  }
  
  //load Current tenant name to whom asset is assigned
  useEffect(() => {
    const shipperService = new ShipperAPI();
    shipperService.getShipperID()
      .then((res: any) => {
        res.map((val: any) => {
          
          if (tenantID === val.id){
            setCurrentTenant( val.name)
          }
          if(assetDataHandler.CustomerAssetType === val.name){
            setNewTenantID(val.id)
          }
        })
      })
  })
console.log(currentTenant,tenantID,"tenant");

  function _handleSubmit(values: any, actions: any) {
    if (activeStep ===0 ) {
      actions.setSubmitting(true);
      _submitForm(values, actions);
       history.push("/assetoverview");
    } else if (activeStep >= 1) {
      if (getrequestId === "") {
        let Ship = new ShipperHelper();
        let requestId =
          values.shipper.substring(0, 3).toUpperCase() + Ship.requestNumber();
        setgetrequestId(requestId);

        setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      } else {
        setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
      actions.setTouched({});
    }
  }
  function _handleBack() {
    setActiveStep(activeStep);
  }

  return (
    <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:"-10px" }}>
       <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
       <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <Fragment>
      <div className={classes.root}>
        <Formik
          initialValues={isUpdateMode ? updateInitalValues : formInitialValues}
          enableReinitialize={true}
          data-testid="checkoutFormikInitialisation"
          // validationSchema={
          //   isUpdateMode ? updatevalidationSchema : currentValidationSchema
          // }
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue }) => (
            <Form id={formId}>
              <Grid container spacing={3} style={{ marginBottom: '1rem' }}>
                <Grid item xs={5} style={{ position: "static" }}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    data-testid="backToDenso"
                    onClick={() => {
                      window.history.back();
                    }}
                    style={{ color: "#5A5A5A", cursor: "pointer",marginTop:'-22px' }}
                  >
                    {/* <ArrowBackIcon /> */}
                  </IconButton>
                  <span style={{ fontSize: "16px", fontWeight: "normal", marginLeft:"20px"}}>
                    {isUpdateMode ? "Update" : ""}Edit Asset
                  </span>
                </Grid>
                <Grid item xs={7}>
                  <div >
                  {activeStep >= 1 ?
                          <Button
                      variant="outlined"
                      onClick={_handleBack}
                      disabled={activeStep === 0}
                      style={{textDecoration:"none",width:"65px",
                    
                      
                    
                      height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"1px",textTransform: 'capitalize'
                      ,backgroundColor:"#fff",color:"grey"}}
                      className={activeStep === 0 ? "" : classes.backBtn}
                    >
                          <ArrowBackIcon  style={{marginLeft:"-3px",fontSize:"15px"}}/>
                      <span style={{marginLeft:"3px"}}>Back</span> 
                    </Button>: 
                    <Link to={{ pathname: '/assetoverview'}}><button style={{textDecoration:"none",width:"65px",marginLeft:"610px",
             
                    
                    height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"1px",textTransform: 'capitalize'
                    ,backgroundColor:"#fff",color:"grey"}}>
                    
                       <ArrowBackIcon  style={{marginLeft:"-2px",fontSize:"15px"}}/>
                     <span style={{marginRight:"2px"}}>    Back</span>
                
                   </button></Link>
                      
                    }

                    <Button
                      disabled={btnFlag}
                      type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      style={{
                        marginLeft: "43rem",
                        backgroundColor: "#007AA6",
                        color: "#fff",
                        border: 0,
                        marginTop:"-32px",
                        height:"32px",
                        textTransform:"capitalize"
                        
                      }}
                    >
                      
                        Update
                        {/* <ArrowForward style={{marginLeft:"6.5px",fontSize:'15px'}}/> */}
                     
                    
                    </Button>
                  </div>
                </Grid>
              </Grid>

          
              <Paper className="Paper" style={{boxShadow:"none"}}>
                {_renderStepContent(
                   assetFormHandler,
                   hardwareFormHandler,
                   locationFormHandler,
                  activeStep,
                  setFieldValue,
                  values,
                  getrequestId,
                  item                 
                )}
              </Paper>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
    </Box>
    </Box>
  )
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(EditAsset);