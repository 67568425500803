import { createStyles, makeStyles } from '@material-ui/core/styles';
const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        backgroundColor:'#fff'
      },
    },
   
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
    paper: {
      // padding: theme.spacing(2),
      textAlign: 'center',
      // color: theme.palette.text.secondary,
    },
    rowTop:{
        //backgroundColor: "#F2F2F2",
        border: 'none',
        borderBottom:'none'
      },
      rowBottom:{
        //backgroundColor: "#F2F2F2",
        border: 'none',
        borderTop:'none'
      },
      requestId:{
        left:"22px",
        fontSize:'14px ',
        fontWeight:400,
        color:'#0078a5 ',
        letterSpacing:'0.1px'
    },
   
  });
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: '90%',
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      
    }),
  );

  export{useStyles,useRowStyles}
  