import { UserActionTypes } from "./user.types"

const INITIAL_STATE: { currentUser: any } = {
    currentUser: null
}

const userReducer = (state = INITIAL_STATE, action: any) => {
   // coldchainLog(action);
    //coldchainLog(action?.payload);
    switch(action.type) {
        case UserActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        default:
            return state;
    }
}

export default userReducer;