import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, Typography } from "@material-ui/core";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import { InputField, SelectField} from "../../common/FormFields";
import "./styles.css";
import AddIcon from '@material-ui/icons/Add';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default function HardwareForm(props: any) {
    const {
        formField: {
    hname,
    hid,
    gid,
   // add,
        },
    }= props;
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          flexGrow: 1,
          "@media (max-width:500px)": {
            width: "20rem",
          },
        },
      })
    );
    const classes = useStyles();
    
    
    return (
        <React.Fragment>
          <div className={classes.root}>
            <div className="layout-border">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <p className="cardTitle">Hardware Information</p>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <InputField
                        name={hname.name}
                        label={hname.label}
                        style={{ width: "80%" }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                    <InputField
                        name={hid.name}
                        label={hid.label}
                        style={{ width: "80%" }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                    <InputField
                        name={gid.name}
                        label={gid.label}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                    <Button 
                       type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      style={{
                       
                        border: "2px solid #1A0DAB",
                        color: "#1A0DAB",
                      }}
                    > <AddIcon />Add New Field
                        </Button>
                    </Grid> */}
                    
                   </Grid>
              </Grid>
              </Grid>
            </div>
          </div>
        </React.Fragment>
      );
    }