export default {
  formId: 'checkoutForm',
  formField: {
        //Shipper Form Fields

    shipdoorBuilding: {
      name: 'shipdoorBuilding',
      label: 'Door No and Building Name',
      requiredErrorMsg: 'Door and Building details are required',
    
    },
   
    shipaddress: {
      name: 'shipaddress',
      label: 'Street address',
      requiredErrorMsg: 'Shipping address is required',
    
    },
    shipcity: {
      name: 'shipcity',
      label: 'City',
      requiredErrorMsg: ' City is required'
    
    },
    shippincode: {
      name: 'shippincode',
      label: 'Pincode/ZipCode',
      requiredErrorMsg: 'Pincode/ZipCode is required',
      invalidErrorMsg: 'Invalid Pincode/ZipCode format'
    },
    shiplandmark: {
      name: 'shiplandmark',
      label: 'Landmark'
     
    
    },
    shipmobile: {
      name: 'shipmobile',
      label: 'Mobile Number',
      requiredErrorMsg: ' Mobile Number is required',
      invalidErrorMsg: 'Mobile Numbers is invalid'
    
    },
    shipmobilePrefix: {
      name: 'shipmobilePrefix',
      label: 'Ship Mobile Number',
      requiredErrorMsg: 'Mobile Number is required',
    },
    shipEmail: {
      name: 'shipEmail',
      label: 'Email',
      requiredErrorMsg: 'Email Address is required',
    },
   
    //Delivery Form Fields
    deldoorBuilding: {
      name: 'deldoorBuilding',
      label: 'Door No and Building Name',
      requiredErrorMsg: 'Door and Building details are required',
      
    },
    delstreetaddress: {
      name: 'delstreetaddress',
      label: 'Street address',
      requiredErrorMsg: 'Delivery address details are required',
    },
    delcity: {
      name: 'delcity',
      label: 'City',
      requiredErrorMsg: 'City is required',
    },
    delpincode: {
      name: 'delpincode',
      label: 'Pincode/ZipCode',
      requiredErrorMsg: 'delivery Pincode/ZipCode is  required',
    },
    dellandmark: {
      name: 'dellandmark',
      label: 'Landmark'
      
    },
    delmobilePrefix: {
      name: 'delmobilePrefix',
      label: 'Customers Mobile Number',
      requiredErrorMsg: 'Mobile Number is required',
    },
    delmobile: {
      name: 'delmobilenumber',
      label: 'Customers Mobile Number',
      requiredErrorMsg: 'Mobile Number is required',
    },
    delname: {
      name: 'delcustomername',
      label: 'Customer Name',
      requiredErrorMsg: 'Customer name is required'
      
    },
    delEmail: {
      name: 'delEmail',
      label: 'Email',
      requiredErrorMsg: 'Email Address is required',
    },
    
    source: {
      name: 'source',
      label: 'Source'
    
    },

    destination: {
      name: 'destination',
      label: 'Destination'
    
    },
    pickupStartDate: {
      name: 'pickupStartDate',
      label: 'Select Date',
      invalidErrorMsg:`Pickup Start Date should not lesser than current date`
    
    },
    pickupEndDate: {
      name: 'pickupEndDate',
      label: 'Select Date',
      invalidErrorMsg:`Pickup End Date should be equal or greater than Start Date`
    
    },

    dropStartDate: {
      name: 'dropStartDate',
      label: 'Select Date',
      invalidErrorMsg:`Delivery Start Date should not lesser than current date`,
    

    
    },
    dropEndDate: {
      name: 'dropEndDate',
      label: 'Select Date',
      invalidErrorMsg:`Delivery End Date should be equal or greater than Start Date`
    
    },
    temperature: {
      name: 'temperature',
      label: 'temperature',

    
    },
    pickupTimeStart: {
      name: 'pickupTimeStart',
      label: 'pickupTimeStart',
      invalidErrorMsg:`Pickup Start time should be greater than current time`
    
    },
    pickupTimeEnd: {
      name: 'pickupTimeEnd',
      label: 'pickupTimeEnd',
      invalidErrorMsg:`Pickup End time should be equal to or greater than Pickup time`
    
    },
    dropTimeStart:{
        name:"dropTimeStart",
        label:'dropTimeStart',
        invalidErrorMsg:`Delivery Start time should be greater than current time`

    },
    dropTimeEnd:{
      name:"dropTimeEnd",
      label:'dropTimeEnd',
      invalidErrorMsg:`Delivery End time should be equal to or greater than Pickup time`

  },
  dropTimeEnd2:{
    name:"dropTimeEnd2",
    label:'dropTimeEnd2',
    invalidErrorMsg:`Delivery time should be equal to or greater than Start Time`

},
    range:{
      name:"range",
        label:'range'
    },

    
    volume:{
      name:'volume',
      label:'Volume(Ltr)',
      requiredErrorMsg:'Volume is requried field'
    },
    weight:{
      name:'weight',
      label:'Weight(Kg)(Optional)'
    },
    instructions:{
      name:'instructions',
      label:'Instructions',
      requiredErrorMsg:"Please provide instructions"
    },
    indicativePrice:{
      name:'indicativePrice',
      label:'Indicative Price'

    },
    fragileItem:{
      name:'fragileItem',
      label:'fragile Item'
    },
    transporter:{
      name: 'transporter',
      label:'transporter'

    },
    assetName:{
      name:'assetName',
      label:'assertName'
    },
   
    driverName:{
      name:'driverName',
      label:'Driver Name'
    },
    driverNumber:{
      name:'driverNumber',
      label:'Mobile Number',
      requiredErrorMsg: 'Mobile Number is required',
    

    },
    driverNumberPrefix: {
      name: 'driverNumberPrefix',
      label: 'Driver Mobile Number',
      requiredErrorMsg: 'Mobile Number is required',
    },
    assetId:{
      name:'assetId',
      label:'assetId'
    },
    shipper: {
      name: 'shipper',
      label: 'Shipper',
      requiredErrorMsg: 'Shipper is required',
      invalidErrorMsg: 'Shipper Numbers is invalid'
    
    },
   

    
  }
};