import {Select,MenuItem,FormControl, InputLabel} from "@material-ui/core";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@mui/material/FormHelperText';
import { at } from 'lodash';
import { useField } from 'formik';
const useStyles = makeStyles(theme =>
    createStyles({
    
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      // selectEmpty: {
      //   marginTop: theme.spacing(2)
      // },
      menuPaper: {
        // maxHeight: 250,
        // marginTop:"3.5rem"
      }
      
    }),
  );
  

export default function SelectField (props:any) {
 const classes = useStyles();
 const { label, name, options, ...rest } = props;
 const [field, meta] = useField(props);

  
 function _renderHelperText() {
   const [touched, error] = at(meta, 'touched', 'error');
   if (touched && error) {
     return error;
   }
 }
  return (
    <div >

 <FormControl variant="outlined"   style={{width:"100%", marginLeft:"-2px"}}>
  <InputLabel id="demo-simple-select-label"   style={{ color: (meta.touched === true && meta.error === "Shipper is required") ? '#dc3545': ''}}>
    {label}
  </InputLabel>
  <Select
    labelWidth={150}
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    inputProps={{'data-testid': 'selectField'}}
    MenuProps={{ classes: { paper: classes.menuPaper } }}
    name={name}
    {...rest}
    error={meta.touched && meta.error && true}
    helperText={_renderHelperText()}
    {...field}    
    fullWidth
  >
          {options.map((option:any) => { 
          return (   
             <MenuItem key ={option.value }value={option.value}>{option.value}</MenuItem>
          )
        })}
        </Select>
        <FormHelperText style={{color:'#dc3545', paddingLeft:'15px'}}>{(meta.touched === true && meta.error === "Shipper is required") ? meta.error: ""}</FormHelperText>
      </FormControl> 
 
 </div>


    
  )
}





















