import React from 'react';
import Plot from 'react-plotly.js';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';

const ShipmentsOverview = (props: any) => {

    const Headingstyle: any = {
        textAlign: 'left',
        marginLeft: '1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
      
        fontSize: '1rem',
        color: "#000000DE"
    }

    const state = props.shipmentOverview
    // console.log(state,)
    const legendName = props.legendNames
    const Nodata:any = props.noDataShipment

    return (
        <div>
            <div style={Headingstyle}>Shipments Overview</div>
            {Nodata !== '' ? <div style={{minHeight:'330px'}}>{Nodata}</div> :
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <div>
                            <Plot
                                data={[
                                    state
                                    
                                ]}

                                layout={
                                    {
                                        margin: { l: 20, r: 0, b: 0, t: 0 },
                                        height: 320,
                                        // width:400,
                                        annotations: [
                                            {
                                                // font: {
                                                //     size: 20
                                                // },
                                                showarrow: false,
                                                text: 'Total Requests<br>' + state.values[2],
                                            },
                                        ]
                                        
                                    }
                                }
                                
                                style={{ minHeight: '330px' }}
                                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
                            />
                           
                          
                        </div>
                    </Grid>
                    <Grid item xs={4} >
                        {state.labels.map((data: any, index: any) => {
                            
                            return (
                                <div style={{ textAlign: 'left'}}>
                                
                                <div style={{ backgroundColor: state.marker.colors[index],
                                         width: '12px', height: '12px', display: 'inline-block',marginLeft:"30px" }}>
                                        
                                    </div>
                                    <div style={{ display: 'inline-block',color: state.marker.colors[index],marginLeft:"20px" }}
                                    ><p>{data} - {state.values[index]}</p></div>
                                      
                                </div>
                            )
    })}
                    </Grid>
                    <Grid item xs={4}>
                        {legendName.map((data: any, index: any) => {
                             if(props.selectedClosedMarket==data.name){
                            return (
                                <div style={{ textAlign: 'left' }}>
                                    <div style={{ backgroundColor: state.marker.colors[index + 1], width: '12px', height: '12px', display: 'inline-block' }} />
                                    <div style={{ display: 'inline-block', paddingLeft: '10px', color: state.marker.colors[index + 1] }}>{data} - {state.values[index + 1]}</div>
                                </div>
                            )
  }  })}
                    </Grid>
                    
                </Grid>
            }
        </div>
    )
}

export default ShipmentsOverview