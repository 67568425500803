import { jsPDF } from 'jspdf';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import fragileIcon from '../../../../assets/images/fragileIcon.png'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ShipperHelper from '../../../../services/_helpers';
import Typography from '@mui/material/Typography';


const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        modalHeadDenso: {
            textAlign: 'left',
            font: 'italic bold 20px Roboto'
        },
        modalHeadChallan: {
            textAlign: 'center',
            font: 'bold 25px Roboto'
        },
        modalHeadIssued: {
            textAlign: 'right',
            font: 'bold 16px Roboto'
        },
        modalCommonHeading: {
            textAlign: 'left',
            font: 'bold 16px Roboto'
        },
        modalCommonBody: {
            font: '16px Roboto'
        },
        modalScroll: {
            overflowY: "scroll"
        },
        modalfragile: {
            font: '14px Roboto',
            paddingTop: "5px"
        },
    })
)

const ChallanGenerator = (props: any) => {

    const row = props.row
    const openPDF = props.openPDF
    const handleClosePDF = props.handleClosePDF

    const styles = useStyle()

    let dateHelper = new ShipperHelper();

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 950,
        height: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const pdfGenerator = () => {

        const input = document.getElementById("pdfGen")
/*istanbul ignore next*/
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF()
                pdf.addImage(imgData, 'PNG', 10, 10, 190, 150)
                pdf.save(row.requestNumber + ".pdf")
            })
    }

    return (
        <div>
            <Modal
                open={openPDF}
            >
                <Box sx={modalStyle} className={styles.modalScroll}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}><div style={{ paddingBottom: '10px',float:"right",marginRight:"-445px"}}><IconButton data-testid="pdf-button" onClick={pdfGenerator}><GetAppIcon /></IconButton></div></Grid>
                        <Grid item xs={6}><div style={{ paddingBottom: '10px',float:"right" }}><IconButton data-testid="cancel-button" onClick={handleClosePDF}><CloseIcon /></IconButton></div></Grid>
                    </Grid>
                    <div id="pdfGen">
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <div className={styles.modalHeadDenso} style={{marginLeft:"20px"}}>DENSO</div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={styles.modalHeadChallan}>
                                    Challan
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={styles.modalHeadIssued}>
                                    <div style={{textAlign:'left', paddingLeft:'70px', paddingBottom:'5px'}}>Issued On</div>
                                    <div className={styles.modalCommonBody}>{dateHelper.dateConversion(new Date())}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Typography style={{ wordWrap: "break-word" }} >
                                    <div style={{ textAlign: 'left', border: '1px solid black', minHeight: '120px', maxHeight: 'auto' }}>
                                        <div className={styles.modalCommonHeading} style={{ padding: '10px' }}>Instruction:</div>
                                        <div className={styles.modalCommonBody} style={{ padding: '0px 10px' }}>{row.instructions === '' ? 'None' : row.instructions}</div>
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {row.fragile === 1 ?
                                    <div style={{ textAlign: 'center', border: '2px solid black', margin: '0px 0px 0px 70px ', height: '120px', padding: '10px' }}>
                                        <img src={fragileIcon} style={{ height: '80px' }} />
                                        <div className={styles.modalfragile}>
                                            FRAGILE ITEM
                                        </div>
                                    </div> : <div></div>}
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <div className={styles.modalCommonHeading} style={{ padding: "20px 0px" }}>
                                    <div>Shipment Request ID</div>
                                    <div className={styles.modalCommonBody}>{row.requestNumber}</div>
                                </div>
                                <div className={styles.modalCommonHeading} style={{ padding: "20px 0px" }}>
                                    <div>Expected Pick-up Time</div>
                                    <div className={styles.modalCommonBody}>{dateHelper.dateConversion(row.expectedPickUpTime?.from)}</div>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={styles.modalCommonHeading} style={{ padding: "20px 0px" }}>
                                    <div>Ordered On</div>
                                    <div className={styles.modalCommonBody}>{dateHelper.dateConversion(row.createdAt)}</div>
                                </div>
                                <div className={styles.modalCommonHeading} style={{ padding: "20px 0px" }}>
                                    <div>Expected Delivery Time</div>
                                    <div className={styles.modalCommonBody}>{dateHelper.dateConversion(row.expectedDeliveryTime?.from)}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{ wordWrap: "break-word" }} >
                                    <div className={styles.modalCommonHeading} style={{ padding: "20px 0px", maxHeight: 'auto' }}>
                                        <div>Transported By</div>
                                        <div className={styles.modalCommonBody}>{row?.shipper?.name ? row.shipper.name : '---'}</div>
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ textAlign: 'right', padding: "20px 0px" }}>
                                    <QRCode value={row.requestNumber} size={120} ></QRCode>
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography style={{ wordWrap: "break-word" }} >
                                    <div className={styles.modalCommonHeading} style={{ padding: "20px 0px", maxHeight: 'auto' }}>
                                        <div>Pickup Address</div>
                                        <div className={styles.modalCommonBody}>{row.fromLocation.doorNoAndBuildingName + ' ' + row.fromLocation.streetAddress + ' ' +
                                            row.fromLocation.landmark + ' ' + row.fromLocation.city + ' ' + row.fromLocation.pincode + ' ' + row.fromLocation.mobileNo}</div>
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ wordWrap: "break-word" }} >
                                    <div className={styles.modalCommonHeading} style={{ padding: "20px 0px", maxHeight: 'auto' }}>
                                        <div>Delivery Address</div>
                                        <div className={styles.modalCommonBody}>{row.toLocation.doorNoAndBuildingName + ' ' + row.toLocation.streetAddress + ' ' +
                                            row.toLocation.landmark + ' ' + row.toLocation.city + ' ' + row.toLocation.pincode + ' ' + row.toLocation.mobileNo}</div>
                                    </div>
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div className={styles.modalCommonHeading} style={{ padding: "20px 0px" }}>
                                    <div>Temperature Range to be Maintained</div>
                                    <div className={styles.modalCommonBody}>{row.temperatureRange?.minTemperature + '° C to ' + row.temperatureRange?.maxTemperature + '° C'}</div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={styles.modalCommonHeading} style={{ padding: "20px 0px" }}>
                                    <div>Capacity</div>
                                    <div className={styles.modalCommonBody}>{row.indicativeCapacity?.volume + ' Ltr / ' + row.indicativeCapacity?.weight + ' Kg'}</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ChallanGenerator