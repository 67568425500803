import React, { useEffect, useState } from "react";
import { Theme } from "@material-ui/core/styles";
import {
  Tab as Taab,
  Tabs as Taabs,
  InputAdornment,
  IconButton,
  Paper,
  InputBase,
  withStyles,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import Details from "./details";
import HardwareInfo from "../../common/hardwareInfo";
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../../AssetManagement/style.css";
import axios from "axios";
import { setAssetToken } from "../../../../../services/tokens";
import SearchIcon from "@material-ui/icons/Search";
import ShipperHelper from "../../../../../services/_helpers";
import filter from "../../../../../assets/images/filter.svg";
import TelemeteryAPI from "../../../../../services/telemeteryService";
import UserService from "../../../../../services/userService";
import { telemetry, assetBaseURL } from "../../../../../services/_url";
import { log } from "console";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: '1px', backgroundColor: "white" }}>
          <div style={{ width: '100%' }}>
            <hr style={{ marginTop: '2.4rem', border: "1px solid lightgrey", width: '100%', paddingTop: '0.1px', borderTop: '0', borderLeft: '0', borderRight: '0' }} />
            <Typography style={{ width: '100%' }}>{children}</Typography>
          </div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    fontSize: "12pt",
    boxShadow: "none",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#000",
    minHeight: "15rem",
  },
  pageHeader: {
    fontSize: "1.5rem",
  },
  pageTopBar: {
    backgroundColor: "#fff",
  },
  tabsWrapper: {
    marginTop: 0,
    position: "relative",
    "@media (max-width:500px)": {
      marginTop: "3rem",
    },
  },
  tabs: {},
  tab: {
    textTransform: "capitalize",
    "&.Mui-selected": {
      outline: "none",
      color: "#0078A5",
    },
    outline: "none",
    color: "#737373",
  },
  tabsLeft: {
    "& .MuiTabs-flexContainer": {
      backgroundColor: "#E5E5E5",
      borderRadius: "40px",
      marginTop: "1rem",
      marginLeft: "1rem",
      marginRight: "0.6rem",
      border: "5px solid #E5E5E5",
      color: "#E5E5E5"
    }
  },
  tabTruck: {
    "&.Mui-selected": {
      outline: "none",
      color: "#0078A5",
      backgroundColor: "#ffffff",
      borderRadius: "40px",

      "@media only screen and (max-width:750px) and (min-width:1030px)": {
        width: "50%",
        fontSize: "18px",
      }
    },
    outline: "none",
    color: "#737373",
    borderRadius: "40px",
    width: "50%",
  },
  tabPanel: {
    backgroundColor: "#fafafa",
  },
}));

export default function VerticalTabs(props: any) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [modArray, setModArray] = useState([]);
  const [modArray1, setModArray1] = useState([]);
  const [modArray2, setModArray2] = useState([]);
  const [modArray3, setModArray3] = useState([]);
  const [err] = useState("");
  const [assetData, setAssetData] = useState([]);
  const [assetOrginalData, setAssetOrginalData] = useState([]);
  const [assetCities, setAssetCities] = useState<any>([]);

  const [assetData1, setAssetData1] = useState([]);
  const [assetCategory, setAssetCategory] = useState({ categories: { Active: false, Inactive: false, Bangalore: false, Delhi: false, Chandigarh: false } })
  const [idleStatus, setIdleStatus] = useState("");
  const [keys, setKeys] = useState("DETAILS");
  const [textFieldValue, setTextFieldValue] = useState<string>("");
  const [page, setPage] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalAssetsByPage, setTotalAssetsByPage] = useState(0);
  const [TotalAssetCount, setTotalAssetCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [selAssetData, setSelAssetData] = useState({ data: {}, id: '' });
  var itemOption: any[] = [];
  const [period, setPeriod] = React.useState("daily");
  const [dDialog, setDDialog] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);
  const [locationVisible, setLocationVisible] = useState(false);
  const [statusFlag, setStatusFlag] = useState(false);
  const [eventsFlag, setEventsFlag] = useState(false);
  const [locationFlag, setLocationFlag] = useState(false);
  const telemeteryService = new TelemeteryAPI();
  const userService = new UserService();
  const userDetails = userService.getUserDetails();
  const [overviewData, setOverviewData] = useState(props.overviewData)

  const [overviewAllData, setOverviewAllData] = useState([]);
  const [fleetData, setFleetData] = useState([]);
  const [overviewDataDH, setOverviewDataDH] = useState({ data: {}, id: '' });
  let dateHelper = new ShipperHelper();
  const [truckClickedFlag, setTruckClickedFlag] = useState(false);

  const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }

  const handleChangeST = (event: React.ChangeEvent<{}>, newValue: number, item: any) => {
    if (overviewData.length > 1) {
      overviewData.map((id: any, index: any) => {
        if (id.number === item.number) {
          setValue1(-1);
          setTimeout(() => {
            setValue1(index);
          }, 250);
        }
      })
    }
    else {
      setValue1(1);
    }
  };


  window.addEventListener("click", (event: any) => {
    if (!event.target.matches(".dropdown")
      && (!event.target.matches(".divZ"))
      && (!event.target.matches(".insideDiv"))
      && (!event.target.matches(".cbBox"))
      && !event.target.matches(".dropbtn") && !event.target.matches(".li")) {

      if (dDialog) {
        setDDialog(false);
      }
    }
  });

  const showDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (dDialog) {
      setDDialog(false);
    } else {
      setDDialog(true);
    }
  }

  const handleLeftBarChange = (

    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    props.handleLeftBarChange(newValue);
  };
  const handleChangeKey = (k: any) => {
    setTabValue(0);
  };

  const [selectedCity, setSelectedCity] = useState([])

  const checkboxHandlerCity = (e: any, val: string) => {
    const selectedCities = [...selectedCity];
    const findIndex = selectedCities.indexOf(val);
    if (findIndex !== -1) {
      selectedCities.splice(findIndex, 1);
      setSelectedCity(selectedCities);

    }
    else {
      selectedCities.push(val);
      setSelectedCity(selectedCities);
    }
  }

  const clearFilter = () => {
    setSelectedCity([]);
    const temp = assetOrginalData.sort((a, b) => {
      return a.number - b.number;
    })
    setAssetData(temp);
  }

  useEffect(() => {
    if (selectedCity.length) {
      let values: any = []
      selectedCity.map(val => {
        const tmp = [...assetOrginalData];
        let temp: any = [];
        if (val === 'Active') {
          if (values.length == 0) {
            tmp.map((item: any) => {
              if (item.active && item.underMaintenance) {
                item.active = false;
              }
            })
            temp = tmp.filter((item) => !item.underMaintenance)
            return values = [...values, ...temp]
          }
          else {
            let actTemp = values;

            tmp.map((item: any) => {
              if (item.active && item.underMaintenance) {
                item.active = false;
              }
            })

            const foundData = values.filter((item: any) => {
              return !item.underMaintenance;
            })

            if (foundData.length > 0) {
              values = [];
              return values = [...values, ...foundData];
            }

            temp = tmp.filter((item: any) => !item.underMaintenance)
            if (temp.length > 0) {
              values = [];
              values = [...values, ...temp];
              values = [...values, ...actTemp];
            }
          }
        }
        if (val === 'Inactive') {
          if (values.length == 0) {
            tmp.map((item: any) => {
              if (item.active && item.underMaintenance) {
                item.active = false;
              }
            })

            temp = tmp.filter((item) => item.underMaintenance)
            values = [...values, ...temp]
          }
          else {
            let inactTemp = values;

            tmp.map((item: any) => {
              if (item.active && item.underMaintenance) {
                item.active = false;
              }
            })

            const foundData = values.filter((item: any) => {
              return item.underMaintenance;
            })

            if (foundData.length > 0) {
              values = [];
              return values = [...values, ...foundData];
            }

            temp = tmp.filter((item: any) => item.underMaintenance)
            if (temp.length > 0) {
              values = [];
              values = [...values, ...temp]
              values = [...values, ...inactTemp];
            }
          }
        }
        if (values.length == 0) {
          temp = tmp.filter((item) => {
            return item.city === val;
          })
          values = [...values, ...temp];
        }
        else {
          const cityTemp = values;

          const foundData = cityTemp.filter((item: any) => {
            return item.city === val;
          })

          if (foundData.length > 0) {
            values = [];
            return values = [...values, ...foundData];
          }

          temp = tmp.filter((item: any) => {
            return item.city === val;
          })
          values = [];
          values = [...values, ...temp];
          values = [...values, ...cityTemp];
          let t1: any = [];
          selectedCity.map(val => {
            values.map((item: any) => {
              if (val === "Active" && item.active && !item.underMaintenance) {
                t1.push(item);
              }
              else if (val === "Inactive" && item.underMaintenance) {
                t1.push(item);
              }
            })
          })
          if (t1.length > 0) {
            values = [];
            values = [...values, ...t1];
          }
        }
      })

      if (values.length > 0) {
        let tempResult: any = [];
        let uniIds: any = [];
        assetOrginalData.map((item1: any) => {
          values.map((item2: any) => {
            if (item2.number === item1.number) {
              tempResult.push(item2);
            }
          })
        })

        //check duplicates
        const finalData = tempResult.filter((item3: any, idx: number) => {
          const isdup = uniIds.includes(item3.number);
          if (!isdup) {
            uniIds.push(item3.number);
            return true;
          }
          return false;
        })
        const temp = finalData.sort((a: any, b: any) => {
          return a.number - b.number;
        })
        setAssetData(temp);
      }
      else {
        const temp = assetOrginalData.sort((a, b) => {
          return a.number - b.number;
        })
        setAssetData(temp);
      }
    }
    else {
      const temp = assetOrginalData.sort((a, b) => {
        return a.number - b.number;
      })
      setAssetData(temp);
    }
  }, [selectedCity])

  const searchAsset = (page?: any, textValues?: any) => {

  };

  const handleClearSearch = () => {
    setTextFieldValue("");

  };

  useEffect(() => {
    let tempObj = { data: {}, id: '' };
    let tempArray: any[] = [];
    if (overviewAllData !== undefined) {
      if (overviewAllData.length > 0 && value1 !== -1) {
        overviewAllData.map((id: any, index: any) => {
          if (index == value1) {
            tempObj.data = id;
            tempObj.id = id?.assetId;
          }
          tempArray.push(id);
        });
        setOverviewDataDH(tempObj);
        setTimeout(() => {
          setTabValue(1);
          setTabValue(0);
        }, 100);
      }
      if (tempArray.length > 0) {
        setModArray(tempArray);
      }
    }
  }, [value1]);


  const filterHandler = (event: any, name: string) => {
    if (name === "status") {
      if (!statusFlag) {
        setStatusFlag(true);
      }
      else {
        setStatusFlag(false);
      }
    }
    else if (name === "location") {
      if (!locationFlag) {
        setLocationFlag(true);
      }
      else {
        setLocationFlag(false);
      }
    }
    else if (name === "events") {
      if (!eventsFlag) {
        setEventsFlag(true);
      }
      else {
        setEventsFlag(false);
      }
    }
  }

  const overviewAPI = async (selectedCloseMarket: string, fleetFlag: boolean) => {
    let url: any;
    if (selectedCloseMarket === "ALL") {
      url = `${telemetry}/telemetry/intellicar/assetlocation?assetId=ALL&srvCst=1&drv=1&is4wice=1`;
    } else {
      url = `${telemetry}/telemetry/intellicar/assetlocation?tenantId=${selectedCloseMarket}&assetId=ALL&srvCst=1&drv=1`;
    }

    const resultData: any = await axios.get(url).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      console.log(err);
    })
    if (resultData && resultData !== null && props.value === 0) {

      let data = resultData.response.map((ele: any) => {
        // ele.assetType = { active: true,
        //   description: props.value ? "2wheeler type" : "3wheeler type",
        //   id: ele.assetId,
        //   name : ele.name
        //   };
        // let result = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${ele.assetId}`)
        // ele.status = result.data.lastSyncTs ? (result.data.onTrip ? "On Trip" : "Idle") : ""
        ele.number = ele.vehicleNum;
        ele.id = ele?.assetId;

        return ele;
      })
      // setOverviewData(data);
      let data1 = resultData.response.map((ele: any) => {
        let customer = ele.servedCustomer ? ele.servedCustomer.customerDesc : 'NA';
        if (customer === props.customer) {
          ele.number = ele.vehicleNum;
          ele.id = ele?.assetId
          return ele;
        }
      })
      if (props.customer === 'ALL' || 'LithPwr' || 'ISE') {
        setOverviewData(data);
      } else {
        setOverviewData(data1);
      }
    }
    if (resultData && resultData !== null) {
      if (!fleetFlag) {
        setOverviewAllData(resultData?.response);
        let tempObj = { data: {}, id: '' };
        tempObj.data = resultData.response[0];
        tempObj.id = resultData.response[0]?.assetId;
        setOverviewDataDH(tempObj);
      }
      else {
        setFleetData(resultData.response);
      }
    }
  }


  useEffect(() => {
    // if(props.selectedCloseMarket !== undefined && props.selectedCloseMarket !== "All" && props.selectedCloseMarket!== ""){
    overviewAPI(props.selectedCloseMarket, false);
    // }
  }, [props.selectedCloseMarket, props.customer]);

  useEffect(() => {
    if (props.topBarValue === 1) {
      overviewAPI("ALL", true);
    }
  }, [props.topBarValue]);


  useEffect(() => {
    let url = "";
    if (props.value !== 0) {
      props.value === 0 ?
        url = `${assetBaseURL}/assets/type?type=3W_EV&page=0&size=100&tenantId=` :
        url = `${assetBaseURL}/assets/type?type=2W_EV&page=0&size=100&tenantId=`;
      if (props.selectedCloseMarket !== 'ALL') {
        url = `${url}${props.selectedCloseMarket}`
      }

      axios
        .get(url)
        .then((res: any) => {
          if (res.status === 200) {
            let temp = res.data.data;
            temp = temp.sort((a: any, b: any) => {
              return a.number - b.number;
            })
            setOverviewData(temp);
            let cities: any = new Set(res.data.data.map((ele: any) => ele.city)
            )
            cities = [...cities]
            setAssetCities(cities)
            setAssetData1(res.data.data);
            setAssetOrginalData(res.data.data);
            setTotalAssetsByPage(res.data.data.length);
            setTotalAssetCount(res.data.data.length);
            setTotalAssets(res.data.total);
            // let tempObj = { data: {}, id: '' };
            // tempObj.data = temp[0];
            //  tempObj.id = temp[0].id;
            // setOverviewDataDH(tempObj);
          }
        }).catch((err: any) => {
          const UserToken = new ShipperHelper()
          UserToken.UserTokenExpiration()
        })
    }

    document.addEventListener("click", () => {
      if (dDialog) {
        setDDialog(false);
      }
    })
  }, [props.selectedCloseMarket])

  const highlightTruckNo = (latLng: any) => {
    const lat = (Math.round(latLng.lat * 100) / 100).toFixed(2);
    const lang = (Math.round(latLng.lng * 100) / 100).toFixed(2);

    overviewData.map((id: any, index: number) => {
      const ovLat = (Math.round(id.data.lat * 100) / 100).toFixed(2);
      const ovLang = (Math.round(id.data.lng * 100) / 100).toFixed(2);
      setTruckClickedFlag(false);
      if (ovLat === lat && ovLang === lang) {
        setTruckClickedFlag(true);
      }
    });
  }


  return (
    <div className={classes.root}>
      <Grid >
        {!props.evOverview &&
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={classes.paper + " p-0"} style={{ height: "100%" }}>
              <Taabs
                value={props.value}
                onChange={handleLeftBarChange}
                style={{ width: "100%" }}
                textColor="inherit"
                className={classes.tabsLeft}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#0078A5"
                  }
                }}
              >
                <Taab
                  className={classes.tabTruck}
                  {...a11yProps(value)}
                  label="3 Wheeler"
                />
                <Taab
                  className={classes.tabTruck}
                  {...a11yProps(value)}
                  label="2 Wheeler"
                />
              </Taabs>
              <div style={{ height: "36rem", paddingTop: '2rem' }}>

                <FormControl style={{ width: "22rem" }}>
                  <TextField
                    style={{ width: '100%', border: "1px solid #CED4D9", marginTop: "-1rem" }}
                    id="search-input-with-icon-adornment"
                    variant="standard"
                    value={textFieldValue}
                    autoComplete="off"
                    data-testid='assetSearch'
                    onChange={(e: any) => {
                      setTextFieldValue(e.target.value)
                      const text = e.target.value;
                      if (text.length >= 1 && (text.length - 1) % 1 === 0 || text.length == 0) {
                        if (text.length === 0) {
                          handleClearSearch()
                        }
                        else {
                          setTextFieldValue(text);
                          searchAsset(0, text)
                        }
                      }
                    }}

                    InputProps={{
                      startAdornment: (<InputAdornment position="start" style={{ color: "gray" }}>
                        <SearchIcon />
                      </InputAdornment>),
                      endAdornment: (textFieldValue ?
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                          </IconButton>
                        </InputAdornment>
                        : <>
                          <div style={{ minWidth: '45px', fontSize: '16px', color: "#CED4D9" }}></div>
                        </>)
                    }}
                    placeholder='Search…'
                  />
                  <span className="divider" />
                  <div>
                    <div className="dropdown">
                      <ul className="dropbtn" onClick={showDropdown} >
                        <li className="li" style={{ listStyle: "none", float: "right", marginTop: "-2.9rem", color: "#74788D", marginRight: "1rem" }}> Filter <img src={filter} style={{ width: "16px", marginLeft: "1rem" }} onClick={clearFilter} /></li>
                      </ul>

                      {dDialog ? <div id="myDropdown" className="dropdown-content divZ">

                        <div className="insideDiv" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                          <li className="cbBox"

                            style={{ height: "1rem", width: "1rem", listStyle: "none", textAlign: "left", marginLeft: "2rem" }} >
                            <button className="cbBox" style={{ backgroundColor: "white", border: "none" }} onClick={(e) => filterHandler(e, "status")} >Status</button></li>
                        </div>

                        {statusFlag && <div className="cbBox" style={{ marginLeft: "6rem" }}>
                          <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "-11.9rem" }}>
                            <input className="cbBox" type="checkbox" checked={selectedCity.includes("Active")} onChange={(e) => checkboxHandlerCity(e, "Active")}
                              style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "5rem" }} />
                            <span className="cbBox">Active</span></div>
                          <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                            <input className="cbBox" type="checkbox" checked={selectedCity.includes("Inactive")} onChange={(e) => checkboxHandlerCity(e, "Inactive")}
                              style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "-3.8rem" }} />
                            <span className="cbBox">Maintenance</span></div>
                        </div>}

                        <hr />

                        <div className="insideDiv" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                          <li className="cbBox"

                            style={{ height: "1rem", width: "1rem", listStyle: "none", textAlign: "left", marginLeft: "2rem" }} >
                            <button className="cbBox" style={{ backgroundColor: "white", border: "none" }}
                              onClick={(e) => filterHandler(e, "location")} >Location</button></li>
                        </div>

                        {locationFlag && <div className="cbBox" style={{ marginLeft: "5.3rem" }}>
                          {
                            assetCities && assetCities.map((ele: any) => {
                              return (
                                <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem", textAlign: "start" }}>
                                  <input className="cbBox" type="checkbox" checked={selectedCity.includes(ele)} onChange={(e) => checkboxHandlerCity(e, ele)}
                                    style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "-3.2rem" }} />
                                  <span className="cbBox">{ele}</span></div>
                              )
                            })
                          }
                        </div>}
                        <hr />
                      </div> : null}
                    </div>
                  </div>
                </FormControl>

                <Taabs
                  orientation="vertical"
                  variant="scrollable"
                  indicatorColor="primary"
                  scrollButtons="off"
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                  value={value1}
                  style={{ minWidth: "150px", clear: "both", paddingTop: "1rem", width: '100%', overflowY: "auto", height: "74vh" }}
                >
                  {overviewData !== undefined && overviewData.map((id: any, index: number) => {
                    return (
                      <div>
                        <Taab
                          onChange={(e) => handleChangeST(e, index, id)}
                          value={index}
                          style={{
                            height: "70px",
                            width: '100%',
                            border: "1px solid lightgrey",
                            maxWidth: "100%",
                          }}
                          icon={
                            <div style={{ width: "100%" }}>
                              <div style={{ marginTop: "1.7rem", marginLeft: "1rem" }}>
                                <span style={{ float: "left", color: "#000000", opacity: 1 }}>
                                  {id.number}
                                </span>
                              </div>
                            </div>
                          }
                          {...a11yProps(index)}
                          onClick={() => {
                            setAssetToken("true");
                          }}
                        />
                      </div>
                    )
                  })}
                </Taabs>
              </div>
            </Paper>
          </Grid>
        }
        <Grid item xs={12} md={10} lg={10}>
          <Paper className={classes.paper + " p-0"} style={{ height: "42rem", marginRight: "-210px", marginBottom: "-30px", boxShadow: "none " }}>

            <Taabs
              value={tabValue}
              onChange={changeTab}
              indicatorColor="primary"
              textColor="inherit"

              TabIndicatorProps={{
                style: {
                  backgroundColor: "#0078A5"
                }
              }}
            >
              <Taab
                className={classes.tab}
                {...a11yProps(value1)}
                style={{ color: "rgb(0, 120, 165)" }}
                label="Details"
              />
              {!props.evOverview &&
                <Taab
                  className={classes.tab}
                  {...a11yProps(value1)}
                  label="Hardware Info"
                />
              }
            </Taabs>


            <TabPanel>
              {tabValue == 0 ?

                <Details
                  data={overviewDataDH?.data}
                  assetID={overviewDataDH?.id}
                  filter={props.filter}
                  fromAndTo={props.fromAndTo}
                  loadingCircleData={props.loadingCircleData}
                  keys={keys}
                  overviewData={overviewData}
                  handleChangeKey={handleChangeKey}
                  highlightTruckNo={highlightTruckNo}
                  topBarValue={props.topBarValue}
                  selectedIndex={value1}
                  fleetFlag={props.evOverview}
                  propsValue={props.value}
                  fleetData={fleetData}
                  customer={props.customer}
                />

                : <HardwareInfo
                  data={overviewDataDH?.data}
                  assetID={overviewDataDH?.id}
                  filter={props.filter}
                  dates={props.dates}
                  fromAndTo={props.fromAndTo}
                  loadingCircleData={props.loadingCircleData}
                />
              }
            </TabPanel>

          </Paper>
        </Grid>


      </Grid>
    </div>
  );
}