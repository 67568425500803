import React, { Fragment, useState } from "react";
import { Stepper, Step, StepLabel, Button, Grid, Paper, Box } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import back from "../../../../assets/images/back.svg";
import { useHistory, useParams } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import LocationForm from "../../CheckoutPage/Forms/LocationForm";
import TimeSlotForm from "../../CheckoutPage/Forms/TimeSlotForm";
import RequirementForm from "../../CheckoutPage/Forms/RequirementForm";
import { Link } from "react-router-dom";
import Assign from "./Forms/Assign";
import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "../../CheckoutPage/FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";
import axios from "axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { assetBaseURL, marketBaseURL } from "../../../../services/_url";
import ShipperAPI from "../../../../services/shippingLanding";
import { useEffect } from "react";
import { toast } from "react-toastify";
import InfoIcon from "@material-ui/icons/Info";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ShipperHelper, {
  ShippingRequestStatus,
} from "../../../../services/_helpers";
import "../../../../web/components/common/Alert/style.css";
import { IconButton } from "@material-ui/core";
import TransporterAPI from "../../../../services/transporterLanding";
import mapMyIndiaApi from '../../../../services/mapMyIndia';
import MarketPlaceAPI from "../../../../services/marketPlaceService";
import UserService from "../../../../services/userService";
import UpdatevalidationSchema from "./FormModel/updateValidationSchema";
import coldchainLog from "../../../../services/consoleHelper";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../../redux/user/user.action";
import { selectCurrentUser } from "../../../../redux/user/user.selector";
import { ResponsiveDrawer } from "../../common/Drawer";

const steps = ["01","02","03","04"]
const steps1 = ["Basic Details","Time Slot","Requirement Criteria","Assign"]
const { formId, formField } = checkoutFormModel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginRight: "48px",
    },
    backBtn: {
      color: "#1A0DAB",
    },
  })
);
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    color: "#5A5A5A",
  },
  active: {
    "& $line": {
      borderColor: "#0078A5",
      backgroundColor: "#0078A5",
    },
    color: "#5A5A5A",
  },
  paper: {
    textAlign: "center",
    color: "#000",
    minHeight: "15rem",
  },

  completed: {
    "& $line": {
      borderColor: "#007AA6",
    },
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor:"#D8EEF3",
  },
})(StepConnector);

function _renderStepContent(
  step: number,
  setFieldValue?: any,
  values?: any,
  getrequestId?: any
) {
  switch (step) {
    case 0:
      return <LocationForm formField={formField} />;
    case 1:
      return (
        <TimeSlotForm formField={formField} setFieldValue={setFieldValue} />
      );
    case 2:
      return (
        <RequirementForm
          formField={formField}
          setFieldValue={setFieldValue}
          values={values}
          getrequestId={getrequestId}
        />
      );
    case 3:
      return (
        <Assign
          formField={formField}
          values={values}
          setFieldValue={setFieldValue}
        />
      );
    default:
      return <div>Internal Error.</div>;
  }
}

export  function CreateShipmentDenso(props: any) {
  const { activeStepProp } = props;
  const id: any = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [ activeStep, setActiveStep] = useState(
    activeStepProp ? activeStepProp : 0
  );
  const [updateData, setUpdateData] = useState([]);
  const [tentant, SetTentant] = React.useState([]);
  const [stmt, setStmt] = React.useState("");
  const [assert, SetAssert] = useState([]);
  const [trips, setTrips] = useState([]);
  const [btnFlag, setBtnFlag] = useState(false);
  const userDetails = new UserService().getUserDetails();
  const mapApi = new mapMyIndiaApi();
  const [getrequestId, setgetrequestId] = useState("");
  const [searchReqId, setSearchReqId] = useState<string>("");
  const currentValidationSchema = validationSchema[activeStep];
  const updatevalidationSchema = UpdatevalidationSchema[activeStep];
  let {   currentUser, setCurrentUser } = props;
  const isLastStep = activeStep === steps.length - 1;
  const isUpdateMode = !!id["id"];
  const updateTemp: any[] = [];
  //intial values
  var updateInitalValues = {
    instructions: "",
    delcity: "",
    delcustomername: "",
    deldoorBuilding: "",
    dellandmark: "",
    delmobilenumber: "",
    delmobilenumberPrefix: "",
    delpincode: "",
    delstreetaddress: "",
    destination: "",
    fragileItem: false,
    pickupStartDate: "",
    pickupEndDate: "",
    dropStartDate: "",
    dropEndDate: "",
    pickupTimeStart: "",
    pickupTimeEnd: "",
    dropTimeStart: "",
    dropTimeEnd: "",
    shipaddress: "",
    shipcity: "",
    shipdoorBuilding: "",
    shiplandmark: "",
    shipmobile: "",
    shipmobilePrefix: "",
    shippincode: "",
    shipper: "",
    shipEmail: "",
    source: "",
    temperature: updateTemp,
    volume: "",
    weight: "",
    transporter: "",
    assetName: "",
    assetId: "",
    driverName: "",
    driverNumber: "",
    driverNumberPrefix: "",
    indicativePrice: "",
  };

  // create shipment
  const success = (id: any) => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Successfully created shipment request {id}.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };
  // update shipment
  const CustomSuccessPatch = () => {
    return (
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Successfully updated shipment request.</span>
        </div>
      </div>
    );
  };
  const successPatch = () => {
    toast.success(<CustomSuccessPatch />, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  };
  useEffect(() => {
    let ship = new ShipperAPI();
    if (isUpdateMode === true) {
      const marketApi = new MarketPlaceAPI();
      marketApi
        .getRequestsByRequestNumber(id["id"])
        .then((resp: any) => {
          setUpdateData(resp);
        })
        .catch((err: any) => {
          console.error(err);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    }
    ship.getShipperID().then((res) => {
      SetTentant(res);
    });
    axios
      .get(`${marketBaseURL}/marketplace/trips`)
      .then((res: any) => {
        setTrips(res.data);
      })
      .catch((e: any) => {
        console.error(e);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  }, []);
  const getTransporterName: any = (id: any) =>
    tentant.filter((val: any) => val.id === id);
  const getTransporterId: any = (name: any) =>
    tentant.filter((val: any) => val.name === name);

  coldchainLog("ss", updateData.length, isUpdateMode);
  /* istanbul ignore next */
  if (updateData.length !== 0) {
    //assign values to forms in update mode
    let ship = new ShipperHelper();
    if (getrequestId === "") {
      setgetrequestId(updateData["requestNumber"]);
    }
    //let ShipperID = getTransporterName(updateData["shipperId"])[0]?.name;
    updateInitalValues["delcity"] = updateData["toLocation"]["city"];
    updateInitalValues["delcustomername"] =
      updateData["consumerDetails"]["name"];
    updateInitalValues["deldoorBuilding"] =
      updateData["toLocation"]["doorNoAndBuildingName"];
    updateInitalValues["dellandmark"] = updateData["toLocation"]["landmark"];
    updateInitalValues["delstreetaddress"] =
      updateData["toLocation"]["streetAddress"];
    updateInitalValues["delpincode"] = updateData["toLocation"]["pincode"];
    //updateInitalValues['delmobilenumber']=updateData['toLocation']['mobileNo']
    updateInitalValues["delmobilenumber"] = `${updateData["toLocation"]["mobileNo"]
    }`;
    updateInitalValues["delEmail"] = updateData["toLocation"]["email"];
    // updateInitalValues['delmobilenumber']=`${updateData['toLocation']['mobileNo'].startsWith('+91')?updateData['toLocation']['mobileNo'].slice(3,13):updateData['toLocation']['mobileNo']}`
    updateInitalValues["shipcity"] = updateData["fromLocation"]["city"];
    updateInitalValues["shipdoorBuilding"] =
      updateData["fromLocation"]["doorNoAndBuildingName"];
    updateInitalValues["shiplandmark"] = updateData["fromLocation"]["landmark"];
    updateInitalValues["shipaddress"] =
      updateData["fromLocation"]["streetAddress"];
    updateInitalValues["shippincode"] = updateData["fromLocation"]["pincode"];

    updateInitalValues["shipmobile"] = `${updateData["fromLocation"]["mobileNo"]
    }`;
    updateInitalValues["shipper"] = updateData["shipper"]["name"];
    updateInitalValues["shipEmail"] = updateData["fromLocation"]["email"];
    updateInitalValues["pickupStartDate"] = ship.putAPIDate(
      updateData["expectedPickUpTime"]["from"]
    );
    updateInitalValues["pickupEndDate"] = ship.putAPIDate(
      updateData["expectedPickUpTime"]["to"]
    );
    updateInitalValues["dropStartDate"] = ship.putAPIDate(
      updateData["expectedDeliveryTime"]["from"]
    );
    updateInitalValues["dropEndDate"] = ship.putAPIDate(
      updateData["expectedDeliveryTime"]["to"]
    );

    updateInitalValues["pickupTimeStart"] = ship.putTimeConversion(
      updateData["expectedPickUpTime"]["from"]
    );
    updateInitalValues["pickupTimeEnd"] = ship.putTimeConversion(
      updateData["expectedPickUpTime"]["to"]
    );
    updateInitalValues["dropTimeStart"] = ship.putTimeConversion(
      updateData["expectedDeliveryTime"]["from"]
    );
    updateInitalValues["dropTimeEnd"] = ship.putTimeConversion(
      updateData["expectedDeliveryTime"]["to"]
    );

    updateInitalValues["volume"] = updateData["indicativeCapacity"]["volume"];
    updateInitalValues["weight"] = updateData["indicativeCapacity"]["weight"];
    updateInitalValues["temperature"] = [
      updateData["temperatureRange"]["minTemperature"],
      updateData["temperatureRange"]["maxTemperature"],
    ];
    updateInitalValues["fragileItem"] =
      updateData["fragile"] === 0 ? false : true;
    updateInitalValues["instructions"] = updateData["instructions"];
    updateInitalValues["indicativePrice"] =
      updateData["indicativePrice"]["price"];
    //updateInitalValues['transporter']= TransporterID
    //   updateInitalValues['assetName']= updateData['asset']?.number
    // updateInitalValues['driverName']= updateData["driverDetails"]?.name
    //   updateInitalValues['driverNumber']= updateData["driverDetails"]?.phoneNumber
  } else {
    // do nothing
  }

  async function _submitForm(values: any, actions: any) {
    let Ship = new ShipperHelper();
    setBtnFlag(true);
    var pickFrom = Ship.dateToUTC(
      values.pickupStartDate + " " + values.pickupTimeStart
    );
    var pickTo = Ship.dateToUTC(
      values.pickupEndDate + " " + values.pickupTimeEnd
    );
    var dropFrom = Ship.dateToUTC(
      values.dropStartDate + " " + values.dropTimeStart
    );
    var dropTo = Ship.dateToUTC(values.dropEndDate + " " + values.dropTimeEnd);
    //  const getTransporterId:any =(name:any) => tentant.filter((val: any) => val.name === name);
    axios
      .get(
        assetBaseURL +
          `/assets?tenantId=${getTransporterId(values.transporter)[0]?.id}&page=0&size=200`
      )
      .then((res: any) => {
        SetAssert(res.data);
      });
      // let eLocFrom = await mapApi.getELocforAddress(`${String(values.shipdoorBuilding)}, ${values.shipaddress}, ${values.shipcity}, ${values.shippincode}`);
      // let eLocTo = await mapApi.getELocforAddress(`${values.deldoorBuilding}, ${values.delstreetaddress}, ${values.delcity}, ${values.delpincode}`);
      // console.log(eLocFrom, eLocTo);
      let locationELocFrom = await mapApi.getLatitudeAndLongitude(`${String(values.shipdoorBuilding)}, ${values.shipaddress}, ${values.shipcity}, ${values.shippincode}`);
      let locationELocTo = await mapApi.getLatitudeAndLongitude(`${values.deldoorBuilding}, ${values.delstreetaddress}, ${values.delcity}, ${values.delpincode}`);
      // console.log(locationELocFrom, locationELocTo);
      //debugger;
    var postData = {
      requestNumber: getrequestId,
      fromLocation: {
        doorNoAndBuildingName: String(values.shipdoorBuilding),
        streetAddress: values.shipaddress,
        city: values.shipcity,
        pincode: values.shippincode,
        landmark: values.shiplandmark,
        mobileNo: `${values.shipmobilePrefix || '+91'}${values.shipmobile}`,
        lat: locationELocFrom.latitude, 
        lng : locationELocFrom.longitude,
        email: values.shipEmail
      },
      toLocation: {
        doorNoAndBuildingName: values.deldoorBuilding,
        streetAddress: values.delstreetaddress,
        city: values.delcity,
        pincode: values.delpincode,
        landmark: values.dellandmark,
        mobileNo: `${values.delmobilePrefix || '+91'}${values.delmobilenumber}`,
        lat: locationELocTo.latitude, 
        lng : locationELocTo.longitude,
        consumerName: values.delcustomername,
        consumerMobleNo: `${values.delmobilePrefix || '+91'}${values.delmobilenumber}`,
        email: values.delEmail
      },
      expectedPickUpTime: {
        from: pickFrom,
        to: pickTo,
      },
      expectedDeliveryTime: {
        from: dropFrom,
        to: dropTo,
      },
      temperatureRange: {
        minTemperature: values.temperature[0],
        maxTemperature: values.temperature[1],

        temperatureUOM: "F",
      },
      indicativeCapacity: {
        weight: values.weight,
        weightUOM: "KG",
        volume: values.volume,
        volumeUOM: "L",
      },
      indicativePrice: {
        price: values.indicativePrice,
        currency: "Rupee",
      },
      consumerDetails: {
        name: values.delcustomername,
        phoneNumber: `${values.delmobilePrefix || '+91'}${values.delmobilenumber}`,
        email: "", // we dint have data from email
      },
      paymentDetails: {
        paid: "",
        paidBy: "",
        paidOn: "",
        actualAmount: "",
        paymentMode: "",
      },
      calculatedDistance: {
        distance: 0.0,
        distanceUOM: "KM",
      },

      fragile: Number(`${values.fragileItem ? 1.0 : 0.0}`),
      instructions: values.instructions,
      status: Ship.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
      shipperId: "",
      transporterId: "",
      audit: [
        {
          action: "Created",
          userId: userDetails?.userId,
          timestamp: Ship.dateToUTC(new Date()),
        },
      ],
    };

    // get assetid for seleted assetName
    // debugger

    // get transporter id for seleted transporter

    let TransporterID = getTransporterId(values.transporter)[0]?.id;
    let ShipperID = getTransporterId(values.shipper)[0]?.id;
    //update transporter id for postData
    postData.transporterId = TransporterID;
    postData.shipperId = ShipperID;

    //trip post data
    let data = {
      shippingRequestId: "",
      assetId: values.assetId,
      status: "Accepted",
      driverDetails: {
        name: values.driverName,
        phoneNumber: `${values.driverNumberPrefix}${values.driverNumber}`,
      },
      transporterId: TransporterID,
      audit: [
        {
          action: "Created",
          userId: userDetails?.userId,
          timestamp: Ship.dateToUTC(new Date()),
        },
      ],
    };
    /* istanbul ignore else */
    if (!isUpdateMode) {
      let trans = new TransporterAPI();
      // create shipment and trip against the created shipment
      trans
        .postRequest(postData)
        .then((res: any) => {
          data.shippingRequestId = res.id;
          if (values.transporter) {
            trans.createTrip(data).then((resp) => {
              setStmt(res.requestNumber);
              history.push("/denso");
              success(res.requestNumber);
            });
          } else {
            setStmt(res.requestNumber);
            history.push("/denso");
            success(res.requestNumber);
          }
        })
        .catch((err: any) => {
          console.error(err);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
      //actions.setSubmitting(false);
    } else {
      var testPatchData = updateData;
      var auditUpdate = { action: "", timestamp: "", userId: "" };

      auditUpdate.action = "Updated";
      auditUpdate.timestamp = Ship.dateToUTC(new Date());
      auditUpdate.userId = userDetails?.userId;

      testPatchData["audit"].push(auditUpdate);
      testPatchData["fromLocation"].doorNoAndBuildingName = String(
        values.shipdoorBuilding
      );
      testPatchData["fromLocation"].streetAddress = values.shipaddress;
      testPatchData["fromLocation"].city = values.shipcity;
      testPatchData["fromLocation"].pincode = values.shippincode;
      testPatchData["fromLocation"].landmark = values.shiplandmark;
      testPatchData["fromLocation"].mobileNo = `${values.shipmobilePrefix}${values.shipmobile}`;
      let ShipperID = getTransporterId(values.shipper)[0]?.id;
      testPatchData["shipper"].name = values.shipper;
      testPatchData["shipper"].id = ShipperID;
      testPatchData["shipperId"] = ShipperID;

      testPatchData["toLocation"].doorNoAndBuildingName = String(
        values.deldoorBuilding
      );
      testPatchData["toLocation"].streetAddress = values.delstreetaddress;
      testPatchData["toLocation"].city = values.delcity;
      testPatchData["toLocation"].pincode = values.delpincode;
      testPatchData["toLocation"].landmark = values.dellandmark;
      testPatchData["toLocation"].mobileNo = `${values.delmobilePrefix}${values.delmobilenumber}`;
      testPatchData["expectedPickUpTime"].from = pickFrom;
      testPatchData["expectedPickUpTime"].to = pickTo;

      testPatchData["expectedDeliveryTime"].from = dropFrom;
      testPatchData["expectedDeliveryTime"].to = dropTo;

      testPatchData["temperatureRange"].minTemperature = values.temperature[0];
      testPatchData["temperatureRange"].maxTemperature = values.temperature[1];

      testPatchData["indicativeCapacity"].weight = values.weight;
      testPatchData["indicativeCapacity"].volume = values.volume;

      testPatchData["indicativePrice"].price = values.indicativePrice;

      testPatchData["consumerDetails"].name = values.delcustomername;
      testPatchData["consumerDetails"].phoneNumber =
      `${values.delmobilePrefix}${values.delmobilenumber}`;

      testPatchData["fragile"] = `${values.fragileItem ? 1 : 0}`;
      testPatchData["instructions"] = values.instructions;
      // Update the shipment and trip

      // var patchData = {
      //   createdAt:updateData['createdAt'],
      //   id:updateData['id'],//from API
      //   requestNumber : updateData['requestNumber'],//from API
      //   fromLocation: {
      //     doorNoAndBuildingName: String(values.shipdoorBuilding),
      //     streetAddress: values.shipaddress,
      //     city: values.shipcity,
      //     pincode: values.shippincode,
      //     landmark: "+91"+values.shiplandmark,
      //     mobileNo: values.shipmobile
      //   },
      //   toLocation: {
      //     doorNoAndBuildingName: values.deldoorBuilding,
      //     streetAddress: values.deladdress,
      //     city: values.delcity,
      //     pincode: values.delpincode,
      //     landmark: values.dellandmark,
      //     mobileNo:"+91"+ values.delmobilenumber
      //   },
      //   expectedPickUpTime: {
      //     from:pickFrom,
      //     to:pickTo

      //   },
      //   expectedDeliveryTime: {
      //     from:dropFrom,
      //     to:dropTo

      //   },
      //   temperatureRange: {
      //     minTemperature: values.temperature[0],
      //     maxTemperature: values.temperature[1],

      //     temperatureUOM: "F"
      //   },
      //   indicativeCapacity: {
      //     weight: values.weight,
      //     weightUOM: "KG",
      //     volume: values.volume,
      //     volumeUOM: "L"
      //   },
      //   indicativePrice: {
      //     price: values.indicativePrice,//price not known
      //     currency: "Rupee"
      //   },
      //   consumerDetails: {
      //     name: values.delcustomername,
      //     phoneNumber:"+91"+values.delmobilenumber,
      //     email: ""// we didnt have data from email
      //   },
      //   paymentDetails: { // payment details not captured
      //     paid: "",
      //     paidBy: "",
      //     paidOn: "",
      //     actualAmount:"",
      //     paymentMode: ""
      //   },
      //   calculatedDistance: {
      //     distance: 0.0,
      //     distanceUOM: "KM"
      // },
      //   fragile: `${values.fragileItem ?1:0}`,
      //   instructions: values.instructions,
      //   status: updateData['status'],// from API
      //   shipperId: updateData['shipperId'],//from API
      //   transporterId: updateData['transporterId'],// from API

      //   audit: [
      //     {
      //       action: "Updated",
      //       userId: updateData['audit'][0]["userId"],//from API
      //       timestamp: Ship.dateToUTC(new Date())
      //     }
      //   ]
      // }
      // const getTripId:any =(id:any) => trips.filter((val: any) => val.shippingRequestId === id);
      //   let tripId = getTripId(updateData[0]['id'])[0].id;
      let data = {
        shippingRequestId: updateData["id"],
        assetId: values.assetId,
        status: "Accepted",
        driverDetails: {
          name: values.driverName,
          phoneNumber: values.driverNumber,
        },
        transporterId: getTransporterId(values.transporter)[0]?.id,
        audit: [
          {
            action: "Created",
            userId: userDetails?.userId,
            timestamp: Ship.dateToUTC(new Date()),
          },
        ],
      };

      if (updateData["tripId"]) {
        axios
          .patch(
            `${marketBaseURL}/marketplace/shippingRequests/${updateData["id"]}`,
            testPatchData
          )
          .then((res) => {
            axios
              .patch(
                `${marketBaseURL}/marketplace/trips/${updateData["tripId"]}`,
                data
              )
              .then((res: any) => {
                successPatch();
                history.push("/denso");
              })
              .catch((err: any) => {
                console.error(err);
                const UserToken = new ShipperHelper();
                UserToken.UserTokenExpiration();
              });
          })
          .catch((err: any) => {
            console.error(err);
            const UserToken = new ShipperHelper();
            UserToken.UserTokenExpiration();
          });
      } else {
        if (values.transporter) {
          axios
            .patch(
              `${marketBaseURL}/marketplace/shippingRequests/${updateData["id"]}`,
              testPatchData
            )
            .then((res) => {
              axios
                .post(`${marketBaseURL}/marketplace/trips`, data)
                .then((res: any) => {
                  successPatch();
                  history.push("/denso");
                })
                .catch((err: any) => {
                  console.error(err);
                  const UserToken = new ShipperHelper();
                  UserToken.UserTokenExpiration();
                });
            })
            .catch((err: any) => {
              console.error(err);
              const UserToken = new ShipperHelper();
              UserToken.UserTokenExpiration();
            });
        } else {
          axios
            .patch(
              `${marketBaseURL}/marketplace/shippingRequests/${updateData["id"]}`,
              testPatchData
            )
            .then((res) => {
              successPatch();
              history.push("/denso");
            })
            .catch((err: any) => {
              console.error(err);
              const UserToken = new ShipperHelper();
              UserToken.UserTokenExpiration();
            });
        }
      }
      //actions.setSubmitting(false);
    }
  }

  function _handleSubmit(values: any, actions: any) {
    if (activeStep >= 3) {
      actions.setSubmitting(true);
      _submitForm(values, actions);
    } else if (activeStep === 0) {
      if (getrequestId === "") {
        let Ship = new ShipperHelper();
        let requestId =
          values.shipper.substring(0, 3).toUpperCase() + Ship.requestNumber();
        setgetrequestId(requestId);

        setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      } else {
        setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
      actions.setTouched({});
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  return (
    
    <Fragment>
       <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:'-22px' }}>
    <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
    <Box component={"main"} sx={{flexGrow:1,p:3}}>
      
      <div className={classes.root}>
        <Formik
          initialValues={isUpdateMode ? updateInitalValues : formInitialValues}
          enableReinitialize={true}
          data-testid="checkoutFormikInitialisation"
          validationSchema={
            isUpdateMode ? updatevalidationSchema : currentValidationSchema
          }
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue }) => (
            <Form id={formId} >
              <Grid container spacing={3} style={{marginBottom:'1rem'}}>
                <Grid item xs={5} >
                  {/* <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    data-testid="backToDenso"
                    onClick={() => {
                      window.history.back();
                    }}
                    style={{ color: "#5A5A5A", cursor: "pointer" }}
                  >
                    <ArrowBackIcon />
                  </IconButton> */}
                  <br />
                  <span style={{ fontSize: "16px", fontWeight: 400,marginLeft:"7px" }}>
                    {isUpdateMode ? "Update" : "Update Details"}
                  </span>
                </Grid>
                <Grid item xs={7} >
                  <div style={{ float: "right",marginRight:"22px" }}>
                    {activeStep >= 1 ?
                    <Button
                      variant="outlined"
                      onClick={_handleBack}
                      disabled={activeStep === 0}
                      style={{  marginLeft:(isLastStep ? "445px": "-30px"), marginTop:"10px",
                      textDecoration:"none",width:"65px",
                    
                      
                    
                      height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",textTransform: 'capitalize'
                      ,backgroundColor:"#fff",color:"grey" }}
                      className={activeStep === 0 ? "" : classes.backBtn} 
                    >
                       <ArrowBackIcon  style={{marginLeft:"-3px",fontSize:"15px"}}/>
                      <span style={{marginLeft:"3px"}}>Back</span> 
                    </Button>: 
                    <Link to={{ pathname: '/denso'}}><button style={{textDecoration:"none",width:"65px",marginLeft:"-30px",
             
                    
                    height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"10px",textTransform: 'capitalize'
                    ,backgroundColor:"#fff",color:"grey"}}>
                    
                       <ArrowBackIcon  style={{marginLeft:"-2px",fontSize:"15px"}}/>
                     <span style={{marginRight:"2px"}}>    Back</span>
                
                   </button></Link>
                      
                    }

                    <Button
                      disabled={btnFlag}
                      type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      style={{
                        marginLeft:(isLastStep ? "520px" : "45px"),
                         
                        backgroundColor:"#007AA6",
                        width: (isLastStep ? '160px' : '65px'),
                        height:"32px",
                        color: "#fff",
                        border:0,
                        marginTop:"-32px",
                        fontSize:"13px",
                        textTransform:'capitalize'
                       

                      }}
                    >
                      {isLastStep ? (
                        <>
                          {isUpdateMode ? "Update" : 
                          
                          <span style={{marginLeft:"-6px"}}>Finish And Publish</span>}
                          <DoneIcon  style={{marginTop:"-3px",marginRight:"-6px",fontSize:"17px",marginLeft:"3px"}}/>
                        </>
                      ) : (
                        <>
                         
                          Next
                        <ArrowForward style={{marginLeft:"6.5px",fontSize:'15px'}}/>
                        </>
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
             <Paper style={{border:"1px solid #fff",boxShadow:'none'}}>
              <Stepper
                style={{ borderRadius:"3px",height:"90px",marginLeft:"95px",marginRight:'50px'}}
            
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps1.map((label) => (
                  <Step key={label} style={{marginTop:"-19px"}}>
                    <StepLabel >
                      <span style={{fontSize:"14px"}}>{label}</span></StepLabel>
                    
                  </Step>
                ))}
                
              </Stepper>
              </Paper>
           
             {/* <div>
             <p style={{marginLeft:"8.2rem",color:"#707070",marginTop:"-36px"}}>
              Basic Details </p>
              <p  style={{marginLeft:"485px",color:"#707070",marginTop:"-37px"}}>Time Slot</p>
         
              <p  style={{marginTop:"-37px",marginLeft:"52.1rem",color:"#707070"}}>Requirement Criteria</p>
              <p  style={{marginTop:"-36px",marginLeft:"73.8rem",color:"#707070"}}>Assign</p>
             </div> */}
              <Paper className="Paper" style={{marginTop:"2px",boxShadow:"none"}}>
              {_renderStepContent(
                activeStep,
                setFieldValue,
                values,
                getrequestId
              )}
              </Paper>
            </Form>
          )}
        </Formik>
      </div>
      </Box>
      </Box>
    </Fragment>
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(CreateShipmentDenso);