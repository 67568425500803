import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, Typography } from "@material-ui/core";


import { InputField, SelectField } from "../../common/FormFields";
import "./styles.css";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { flowRight } from "lodash";
import UserService from "../../../../../services/userService";


export default function LocationForm(props: any) {
    const {
        formField: {
    locality,
    city,
    zone,
    region,
    
        },
    }= props;
    const [tenantId, setTenantId] = useState([]);
   
    
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          flexGrow: 1,
          "@media (max-width:500px)": {
            width: "20rem",
          },
        },
      })
    );
    const classes = useStyles();
    
    return (
        <React.Fragment>
          <div className={classes.root}>
            <div className="layout-border">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <p className="cardTitle">Location Information</p>
                    </Grid>
                    <Grid item xs={6}>
                       <InputField
                        name={locality.name}
                        label={locality.label}
                        
                        style={{ width: "80%" }}
                        fullWidth
                      />
                    </Grid>
                    
                    <Grid item xs={6}>
                    <InputField
                        name={zone.name}
                        label={zone.label}
                        style={{ width: "80%" }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                      <InputField
                        name={city.name}
                        label={city.label}
                        style={{ width: "80%" }}
                        fullWidth
                      />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                    <InputField
                        name={region.name}
                        label={region.label}
                      />
                    </Grid>
                    
                   </Grid>
              </Grid>
              </Grid>
            </div>
          </div>
        </React.Fragment>
      );
    }