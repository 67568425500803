import {createStyles, Grid, makeStyles, Table, TableBody, TableRow, Theme, withStyles} from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-circular-progressbar/dist/styles.css';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MuiTableCell from "@material-ui/core/TableCell";
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SensoreData } from './SensorData';
import { ConsumerFeedback } from '../../components/Utils/ConsumerFeedback/consumerFeedback';
import {Images} from '../../components/Utils/ImageUploaded/index';
import { RouteMap } from '../Utils/RouteMap/index';
import ShipperHelper, { ShippingRequestStatus } from '../../../services/_helpers';
import HomeWorkOutlined from "@material-ui/icons/HomeWorkOutlined";

const TableCell = withStyles({
  root: {
    borderBottom: "none",

  }
})(MuiTableCell);

export interface IRequestDetailsProps {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

      marginRight: '50px',
      marginLeft: '20px'

    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },

    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    imageBox: {

      marginTop: '1rem'

    }

  }),
);

export default function RequestDetails(props: IRequestDetailsProps) {
  const id: any = useParams();
  const assetId: any = useParams();

  const classes = useStyles();
  const [data]: [any, any] = React.useState(null);
  const [tripId] = React.useState('')
  const history = useHistory();



  let dateHelper = new ShipperHelper();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
        <IconButton><ArrowBackIcon onClick={() => { history.goBack(); }} style={{ color: '#5A5A5A' }} /></IconButton>&nbsp;&nbsp;
          <span style={{ fontSize: '24px', fontWeight: 300 }}>{id.id}</span><br />
          <span style={{ fontSize: '12px', fontWeight: 800, letterSpacing: "2px", marginLeft: '2rem' }}>Trip Management</span>
          <span><NavigateNextIcon /></span><span style={{ letterSpacing: "2px" }}>{id.id}</span>
        </Grid>


      </Grid>
      <hr />
      {data ?
        <>
          <p style={{ fontSize: '20px', fontWeight: 500 }}>Shipment Request Details</p>
          {ShippingRequestStatus[data.status] === ShippingRequestStatus.PendingAcceptance ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusRed' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
          {ShippingRequestStatus[data.status] === ShippingRequestStatus.InTransit ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusOrange' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
          {ShippingRequestStatus[data.status] === ShippingRequestStatus.Completed ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusGreen' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
          {ShippingRequestStatus[data.status] === ShippingRequestStatus.Accepted ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusAccepted' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
          {ShippingRequestStatus[data.status] === ShippingRequestStatus.Cancelled ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusGrey' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}


          <Grid container spacing={1}>
            <Grid item xs>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <DirectionsBoatOutlinedIcon style={{  marginRight: '-1rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }} >SHIPPER</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">
                          {data?.shipper?.name ? data?.shipper?.name : "-----"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>

            </Grid>
            <Grid item xs>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <DateRangeIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                </div>

                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>EXPECTED PICK-UP TIME</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">

                          {dateHelper.dateConversion(data?.expectedPickUpTime?.from) + " - "
                            + dateHelper.dateConversion(data?.expectedPickUpTime?.to)}

                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

              </div>

            </Grid>
            <Grid item xs>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <DateRangeIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>EXPECTED DELIVERY TIME</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">

                          {dateHelper.dateConversion(data?.expectedDeliveryTime?.from) + " - "
                            + dateHelper.dateConversion(data?.expectedDeliveryTime?.to)}

                          {/* 07-Jul-21 05:30pm */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

              </div>

            </Grid>
            <Grid item xs>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <EventAvailableIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>ACTUAL PICK-UP TIME</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">
                          {data?.actualPickUpTime ? dateHelper.dateConversion(data?.actualPickUpTime) : "---"}

                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

              </div>

            </Grid>
            <Grid item xs>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <EventAvailableIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>ACTUAL DELIVERY TIME</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">
                          {/* 07-Jul-21 05:26pm */}
                          {data?.actualDeliveryTime ? dateHelper.dateConversion(data?.actualDeliveryTime) : "---"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>

            </Grid>
            <Grid item xs>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <FontAwesomeIcon icon={faTachometerAlt} style={{  marginRight: '-4rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>CAPACITY</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">
                          <span style={{ color: '#00000099', display: 'inline-block', marginLeft: '-10px' }}>
                            {data?.indicativeCapacity?.volume} &nbsp;Ltr&nbsp;&nbsp;&nbsp;
                            <span className="dot" ></span>&nbsp;&nbsp;
                            <li style={{ color: '#00000099', display: 'inline-block' }}>
                              {data?.indicativeCapacity?.weight} &nbsp;Kg
                            </li>

                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>

            </Grid>
          </Grid>
          <Grid container spacing={1}>
          <Grid item xs={2}>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <LocalShippingOutlinedIcon style={{  marginRight: '-1rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }} >PROVIDER</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">
                          {data?.transporter?.name ? data?.transporter?.name : "-----"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>

            </Grid>
            <Grid item xs md={2}>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight" style={{ color: "grey", fontSize: '1.3rem' }}>
                  <HomeWorkOutlined style={{  marginRight: '-5rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }} >SOURCE DETAILS</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell >
                          {data['fromLocation']['doorNoAndBuildingName']}, {data['fromLocation']['streetAddress']}  {data['fromLocation']['city']}-{data['fromLocation']['pincode']}

                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>

            </Grid>
            <Grid item xs md={2}>
              <div className="d-flex flex-row bd-highlight mb-3">

                <div className="p-2 bd-highlight">
                  <HomeOutlinedIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>DESTINATION DETAILS</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">
                          {data['toLocation']['doorNoAndBuildingName']}, {data['toLocation']['streetAddress']}  {data['toLocation']['city']}-{data['toLocation']['pincode']}


                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

              </div>

            </Grid>
            <Grid item xs md={2}>
              <div className="d-flex flex-row bd-highlight mb-3" style={{paddingLeft:'5px'}}>
                <div className="p-2 bd-highlight">
                  <i className="fa fa-money" aria-hidden="true" style={{  marginRight: '-1rem', color: '#828282' }} ></i>


                </div>
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>PRICE</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">

                          {data?.paymentDetails?.actualAmount ? "₹" + data?.paymentDetails?.actualAmount : '---'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

              </div>

            </Grid>
            <Grid item xs md={2}>
              <div className="d-flex flex-row bd-highlight mb-3">

                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>PAYMENT STATUS</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">

                          {data?.paymentDetails?.paidOn ?
                            <>
                              <span>Paid</span> &nbsp;
                              < CheckCircleIcon style={{ fontSize: '15px', color: 'limegreen' }} />
                            </>
                            : "Pending"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

              </div>

            </Grid>
            <Grid item xs>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <Table size="small" >
                    <TableRow>
                      <TableCell style={{ fontSize: '10px' }}>PAID ON</TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row">
                          {dateHelper.dateConversion(data?.paymentDetails?.paidOn)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>

            </Grid>
            <Grid item xs>


            </Grid>
          </Grid>



          <p style={{ fontSize: '20px', fontWeight: 500 }} className={classes.imageBox}>Consumer Feedback</p>


          <ConsumerFeedback id={data.id} avgScore={data.freshnessIndex} />

          <Grid container spacing={3} className={classes.imageBox}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <p style={{ fontSize: '14px', fontWeight: 600,color:"#000", }} >Images Uploaded</p>
                  <Images id={data.id} imageUploads={data.imageUploads} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <p style={{ fontSize: '20px', fontWeight: 500 }}>Sensor Data</p>
          <SensoreData tripId={tripId}  dataId={data.id}/>
          <p style={{ fontSize: '20px', fontWeight: 500 }}>Trip Route</p>
          <RouteMap tripId={data.tripId} actualPickUpTime={data.actualPickUpTime} />
        </> : ""}
    </div>

  );
}
