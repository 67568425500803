



import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Tabs,
  Tab,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Input,
  withStyles,
  InputBase
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DashboardMarketPlace from "./MarketPlace";
import DashboardShipments from "./Shipments";
import DashboardInfrastructure from "./infrastructure";
import "./style.css";
import { assetBaseURL } from "../../../services/_url";
import axios from "axios";
import DashboardReports from "./Reports";
import DashboardTransport from "./Transport";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import moment from "moment";
import DashboardChartAPI from "../../../services/ChartServices";
import ShipperHelper from "../../../services/_helpers";
import UserService from "../../../services/userService";
import { Autocomplete } from "@mui/material";
import Download from "../../../assets/images/Download.svg";
import calendar from "../../../assets/images/calendar.svg";
import viewBy from "../../../assets/images/viewBy.svg";
import { visibility } from "html2canvas/dist/types/css/property-descriptors/visibility";
import { ResponsiveDrawer } from "../common/Drawer";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../redux/user/user.action";
import { selectCurrentUser } from "../../../redux/user/user.selector";

const BootstrapInput = withStyles((theme: Theme) =>
 //css of input and select box
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "null",
      fontSize: 16,
      // width: "150px",
      textAlign: "right",

      // transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        // borderColor: "#80bdff",
        // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const   useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
      marginRight:'15px',
      boxShadow: "none",
    },
    pageHeader: {
      fontSize: "1.5rem",
    },
    // pageTopBar: {
    //   backgroundColor: "#fff",
    // },
    // tabsWrapper: {
    //   marginTop: "1.5rem",
    //   marginBottom: "1.5rem",
    //   position: "relative",
    //   "@media (max-width:500px)": {
    //     marginTop: "3rem",
    //   },
    // },
    tabs: {},
    tab: {
      "&.Mui-selected": {
        outline: "none",
        color: '#0078A5'
      },
      outline: "none",
      textTransform: "none",
      color:"#000"
    },
    tabPanel: {
      backgroundColor: "#F3F6F8",
      marginLeft: "-1rem"
    },
    formControl: {
      margin: theme.spacing(1),
      marginRight: "5rem",
      minWidth: 145,
    },
    tabsWrapper: {
      position: "relative",
      "@media (max-width:500px)": {
        marginTop: "3rem",
      },
    },
    // timePeriodSelect: {
    //   float: "right",
    //   marginRight: "1rem",
    // },
    indicator: {
      backgroundColor: '#0078a5',
    },
    topIconsWrapper: {
      marginRight: "0.75rem",
      position: "absolute",
      top: 0,
      right: 0,
    },
    cnDiv:{
      // textAlign:"right",
      // width:"20%",
      float:"right", 
      // marginRight:'-10px',
      '@media (max-width:700px)': {
        width:'40%'
    }
  },
    topIconWrapper: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginLeft: "9rem",
      color: "#5A5A5A",
      padding: "6px",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
  })
);


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: '1px' }}>
          <div style={{ width: '100%' }}>
            <hr style={{ marginTop: '2.3rem', border: "2px solid #212529", width: '100%', paddingTop: '0.1px', borderTop: '0', borderLeft: '0', borderRight: '0' }} />
            <Typography style={{ width: '100%' }}>{children}</Typography>
          </div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    value: index,
  };
}

export interface IDashboardProps { }

export   function Dashboard(props: any) {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(1);
  const [timePeriod, setTimePeriod] = React.useState("daily");
  const [pdfFlag, setpdfFlag] = useState(false);
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [startAndEnd, setStartAndEnd] = useState("");
  //const [selectedClosedMarket, setSelectedClosedMarket] = useState("");
  const [endNotGreater, setendNotGreater] = useState(false);
  const [dDialog, setDDialog] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);
  //const [locationVisible, setLocationVisible] = useState(false);
  const [assetTypeVisible, setAssetTypeVisible] = useState(false);
  const getShipmentAPI = new DashboardChartAPI();
  const dateselect = new ShipperHelper();
  const getTenantAPI = new UserService();
  const [tenantData, setTenantData] = useState([]);
  const userDetails = getTenantAPI.getUserDetails();
  const [selectedOption, setSelectedOption] = useState('All')
  const [tenantInfo, setTenantInfo] = useState([]);
  const [selTenantData, setSelTenantData] = useState({ data: {}, name: '' });
  const [searchReqId, setSearchReqId] = useState<string>("");
  let {   currentUser, setCurrentUser } = props;
  const handleTabChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleTimePeriodChange = (event: any) => {
    if (event.target.value === "custom") {
      setcustomDialog(true);
      setTimePeriod(event.target.value)
     
      setstartDate("");
      setendDate("");
      setStartAndEnd("");
    } else {
      setTimePeriod(event.target.value)
   
      setstartDate("");
      setendDate("");
      setStartAndEnd("");
    }
  };
  const handleTimePeriodChangeCustom = (event: any) => {
    /* istanbul ignore next */
    if (timePeriod === "custom" && event.target.value === 0) {
      setcustomDialog(true);
    }
  };

  const handleDates = (e: any) => {
    let startandend =
      "startDate=" +
      startDate +
      "T00:00:00Z" +
      "&endDate=" +
      endDate +
      "T23:59:59Z";

    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    seterrorText(false);
    setValidationStart(false);
    setValidationEnd(false);
    setendNotGreater(false);

    if (startDate === "") {
      setValidationStart(true);
    } else if (endDate === "") {
      setValidationEnd(true);
    } else if (startDate > endDate) {
      seterrorText(true);
    } else if (endDate > todaysdate) {
      setendNotGreater(true);
    } else {
      setStartAndEnd(startandend);
      setTimePeriod("custom");
      setcustomDialog(false);
    }
  };

  const handleCancel = () => {
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    if (startDate === "") {
      setStartAndEnd("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate === "") {
      setStartAndEnd("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (startDate > endDate) {
      setStartAndEnd("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate > todaysdate) {
      setStartAndEnd("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    }
    setcustomDialog(false);
  };
  const showDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (dDialog) {
      setDDialog(false);
    } else {


      setDDialog(true);
      setTimeout(() => {
        let el = document.getElementById("popupDiv");
        if (!el) {
          return showDropdown(e)
        }
        el.style.position = "absolute";
        el.style.left = (e.pageX - 100) + 'px';
        el.style.top = (e.pageY + 25) + 'px';

      }, 0);

    }
  }
  useEffect(() => {
    //  console.log("cvdgvchd");
    let datesForShipment = "";
    if (timePeriod === "custom") {
      datesForShipment = startAndEnd;
    } else {
      datesForShipment = dateselect.DashboardDate(timePeriod);
    }

    //Top5Shipper
    let topFiveTotal: any = 0;
    // console.log("timePeriod");
    // console.log(datesForShipment);
    getTenantAPI
      // datesForShipment
      .getTenants()
      .then((values: any) => {
        // console.log(values);
        setTenantData(values);
        // console.log(tenantData);

        //   values.map((data: any) => {

        //   console.log(data.name)
        // });
      })

  }, [timePeriod, startAndEnd]);
  const handleChangeView = (event: any) => {
    setSelectedOption(event.target.value)
  }
  const pdfStatechange = () => {
    setpdfFlag(!pdfFlag);
  };
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDDialog(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleChangeCustom = (event: any) => {
    // if (timePeriod === "custom" && event.target.value === 0) {
    //   setcustomDialog(true);
      
    
    // }
  }

  return (
    <Box   sx={{ display: 'flex',marginLeft:"-110px",marginTop:'-10px'}}>
    <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
    <Box component={"main"} sx={{flexGrow:1,p:3,overflow:"auto"}}>
      
    <div className={classes.root}>
        <div >
          <div> <p>Data Analytics</p></div>
   
    
       
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center",marginRight:"27rem" }}   className={classes.cnDiv}>
              {userDetails.roles.includes('ADMIN') && <Button onClick={(e) => showDropdown(e)} id="multiple-formcontrol1"
                style={{ textAlign: "left", height: '2.5rem', width: '8rem', 
                marginTop: "-50px", paddingLeft:"6px",  border: "1px solid #CED4D9", textTransform: 'none', 
                backgroundColor: "#CED4D9", color: "#000000",fontWeight:"lighter" }}>
                View by<img src={viewBy} style={{paddingLeft:"20px"}}/>
              </Button>}
               </div>
               <div style={{ marginTop:"-44px",marginRight:"14.5rem"}} className={classes.cnDiv}>
               <input type="text" 
              disabled value="All" style={{border:"1px solid rgb(206, 212, 217)",borderRadius:"4px", 
              height: '2.5rem', width: "12rem", backgroundColor: "white",  paddingLeft: "10px",
             marginLeft:"61.5rem",
              fontSize:"initial" }} />
               </div>
              <div   className={classes.cnDiv} style={{ border: "1px solid rgb(206, 212, 217)",borderRadius:"4px",  backgroundColor: "white",
              width:"168px", marginTop:"-44px",
               paddingRight: "-10px",height:'2.5rem', marginRight:"3.3rem"}}>
                <img src={calendar} style={{ width: "24px", margin: "0px 7px 0px 12px",marginTop:"-3px" }} />
                <Select
                 style={{marginTop:"2px"}}
                  inputProps={{ IconComponent:"null" }}
                  labelId="demo-customized-select-label"
                  data-testid="period"
                  id="demo-customized-select"
                  value={timePeriod}
                  onChange={handleTimePeriodChange}
                  input={<BootstrapInput />}
                >
                  <MenuItem
                  
                    style={{color:"#000"}}
                    value={"daily"}>Last 24 Hours</MenuItem>
                  <MenuItem
                   
                    style={{color:"#000"}}
                    value={"weekly"}>Last 7 Days</MenuItem>
                  <MenuItem
                    
                    style={{color:"#000"}}
                    value={"monthly"}>Last 30 Days</MenuItem>
                  <MenuItem
                  
                    style={{color:"#000"}}
                    value={"custom"}>Custom Date</MenuItem>

                </Select>
               
              </div>
              <div   className={classes.cnDiv} style={{ padding: "3px", border: "1px solid rgb(206, 212, 217)",
               backgroundColor: "#fff", width: "40px", marginLeft: "-1px", marginTop: "-44px",
                borderRadius: "4px", height: "2.5rem", paddingTop: "0" }}
             
                // onClick={pdfGen}
                data-testid="assetDownload"
              >
                <img src={Download} style={{ width: "21px",height:"30px", marginLeft: "6.3px", marginTop: "5px" }} />
                {/* <span>file_download</span> */}
              </div>

            </div>
       
       

        <div  style={{marginTop:"18px",marginLeft:"4px"}} className={classes.tabsWrapper}>
        <Paper className={classes.root} style={{marginLeft:"3px"}} >
                
            <Tabs
            style={{marginTop:"1px"}}
              value={value}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="inherit"
              data-testid="requestTabs"
              classes={{
                indicator: classes.indicator
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#0078A5"
                }
              }}
              className={classes.tabs}
            >
              
              <Tab
                className={classes.tab}               
                {...a11yProps(0)}
                label="Shipments"
              />
              <Tab
                className={classes.tab}
                {...a11yProps(1)}
                label="Transport"
              />
              <Tab
                className={classes.tab}
                {...a11yProps(2)}
                label="Market Place"
              />
              <Tab
                className={classes.tab}
                {...a11yProps(3)}
                label="Reports"
              />
               <Tab
                className={classes.tab}
                {...a11yProps(4)}
                label="IT Infrastructure"
              />
              
            </Tabs>

            {/* <hr /> */}

            {/* <div className={classes.topIconsWrapper}>
              <div className={classes.topIconWrapper}>
                <IconButton
                  style={{ height: "10px", width: "10px" }}
                  disabled={true}
                >
                  <FullscreenIcon />
                </IconButton>
              </div>
              <div
                className={classes.topIconWrapper}
                onClick={pdfStatechange}
                data-testid="pdfDashboard"
              >
                <GetAppIcon />
              </div>
            </div> */}
         
             
            <TabPanel className={classes.tabPanel} value={value} index={4}>
              <DashboardInfrastructure
                timeDuration={timePeriod}
                data={pdfFlag}
                pdfStatechange={pdfStatechange}
                startAndEnd={startAndEnd}
                selectedClosedMarket={selectedOption}
              />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={0}>
              <DashboardShipments
                timeDuration={timePeriod}
                data={pdfFlag}
                pdfStatechange={pdfStatechange}
                startAndEnd={startAndEnd}
                selectedClosedMarket={selectedOption}
              />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={1}>
              <DashboardTransport
                timeDuration={timePeriod}
                data={pdfFlag}
                pdfStatechange={pdfStatechange}
                startAndEnd={startAndEnd}
                selectedClosedMarket={selectedOption}
              />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={2}>
              <DashboardMarketPlace
                timeDuration={timePeriod}
                data={pdfFlag}
                pdfStatechange={pdfStatechange}
                startAndEnd={startAndEnd}
                selectedClosedMarket={selectedOption}
              />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={3}>
              <DashboardReports
                timeDuration={timePeriod}
                data={pdfFlag}
                pdfStatechange={pdfStatechange}
                startAndEnd={startAndEnd}
                selectedClosedMarket={selectedOption}
                period={timePeriod}
              />
            </TabPanel>
          </Paper>
          
        </div>
        <div>
          <Dialog
            open={customDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
          >
            <DialogTitle id="alert-dialog-title" style={{color:'#000'}}>{"Custom Date"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <br />
                <TextField
                  id="date"
                  data-testid="startDate"
                  label="Start date"
                  type="date"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errorText === true ? errorText : validationStart}
                  helperText={
                    errorText === true
                      ? "start date cannot be greater than end date"
                      : validationStart === true
                        ? "Select start date"
                        : ""
                  }
                  value={startDate}
                  onChange={(e) => setstartDate(e.target.value)}
                />
                &nbsp;&nbsp;
                <TextField
                  id="date"
                  data-testid="endDate"
                  label="End date"
                  type="date"
                  // defaultValue="2017-05-24"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={endDate}
                  error={validationEnd === true ? validationEnd : endNotGreater}
                  helperText={
                    validationEnd === true
                      ? "Select end date"
                      : endNotGreater === true
                        ? "End date cannot be greater than todays date"
                        : ""
                  }
                  onChange={(e) => setendDate(e.target.value)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button data-testid="okButton" onClick={handleDates}>
                Ok
              </Button>
              <Button data-testid="cancelButton" onClick={handleCancel}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
     
      {dDialog && tenantData.length ? (<div id="popupDiv" ref={wrapperRef}
        style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #CED4D9', width: '15rem', height: '50rem', color: '#737373' }}>
        <div style={{ width: '100%' }}>
          <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }} onChange={handleChangeView} checked={selectedOption === 'All'} value="All" type="checkbox" name="all" />
          <label htmlFor="all">All</label>
        </div>
        {
          tenantData && tenantData.map(ele => {
            return (
              <>
                <hr style={{ marginTop: '0.1px' }} />
                <div style={{ width: '100%' }} >

                  <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }} checked={selectedOption === ele.id} onChange={handleChangeView} type="checkbox" name={ele.id} value={ele.id} />
                  <label htmlFor={ele.id}>{ele.name}</label>
                </div>
              </>
            )
          })
        }
      </div>) : null}
  
    </div>
    </Box>
    </Box>
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);