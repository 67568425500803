import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@mui/material/Badge';
import FilterListIcon from '@material-ui/icons/FilterList';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import {  Tab, Tabs } from 'react-bootstrap';
import ShipmentRequest from './PastRequest/index';
import './style.css';
import ShipmentRequestactive from './ActiveRequest/index';
import { useEffect, useRef, useState } from 'react';
import {CSVLink} from "react-csv";
import { Box, Popover, Tooltip } from '@material-ui/core';
import TableFilter from '../common/TableFilter';
import moment from 'moment';
import UserService from '../../../services/userService';
import Helper, { ShippingRequestStatus } from '../../../services/_helpers';
import MarketPlaceAPI from '../../../services/marketPlaceService';
import { TableManageColumnsFormData } from '../Dashboard/Common/Interfaces/custom';
import TableColumn from '../common/TableManageColumn/columnReorder';
import ShipperHelper from '../../../services/_helpers';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { setCurrentUser } from '../../../redux/user/user.action';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../../../redux/user/user.selector';
import { ResponsiveDrawer } from '../common/Drawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginRight:'50px',
      marginLeft:'20px'
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary
    },
    title:{
      fontSize:'24px',
      float:'left'
    },
    disableExportIcon: {
      color: '#999999',
      cursor: 'progress'
    },
    button: {
        margin: theme.spacing(1),
        border:' 2px solid #1A0DAB',
        color:'#1A0DAB',
        '&:hover':{
          border:' 2px solid #1A0DAB',
            color:'#1A0DAB',
        }
      },
      tableWidth:{
          width:'100%',
         
      },
      tab: {
          '&.Mui-selected': {
              outline: 'none'
          },
          outline: 'none'
      }
  }),
);

export interface IShipperLandingProps {
  searchReqId: string
}

export function ShipperLanding (props: any) {
    const { searchReqId } = props;
    let {   currentUser, setCurrentUser } = props;
    const classes = useStyles();
    const [dataToDownload, setDataToDownload] = useState<any>([
      ["firstname", "lastname", "email"],
      ["Ahmed", "Tomi", "ah@smthing.co.com"],
      ["Raed", "Labes", "rl@smthing.co.com"],
      ["Yezzi", "Min  l3b", "ymin@cocococo.com"]
    ]);
    const [clickedExport, setClickedExport] = useState<boolean>(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [searchReqId1, setSearchReqId1] = useState<string>("");
    const [userName, setUserName] = useState<string>('user-error');
    // const csvLinkRef = useRef<{ link: HTMLAnchorElement }>(null);
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>();
    const [anchorElFilterPopOver, setAnchorElFilterPopOver] = useState(null);
    const [filterOptions, setFilterOptions] = useState(JSON.parse(sessionStorage.ApplyFilter));
    const [anchorElManageColumnsPopOver] = useState(null);
    // const [manageColumnsOptions, setManageColumnsOptions] = useState<TableManageColumnsFormData[]>([
    //   {title: 'STATUS', defaultOrder: 1, order: 1},
    //   {title: 'REQUEST ID', defaultOrder: 2, order: 2},
    //   {title: 'PROVIDER', defaultOrder: 3, order: 3},
    //   {title: 'PRICE (INR)', defaultOrder: 4, order: 4},
    //   {title: 'CREATED ON', defaultOrder: 5, order: 5},
    // ]);
    const [manageColumnsOptions, setManageColumnsOptions] = useState<TableManageColumnsFormData[]>(JSON.parse(sessionStorage.ManageColumn));
    const [value] = useState(0);
    const helper = new Helper();

    const [keys, setKeys] = useState('activeRequest')
    const handleChangeKey = (k:any) => {
      setKeys(k)
    }

    const handleExportIconClick = (event?: any) => {
      // let isActive = true; // todo get from tab selection commented by sarath on 27.09.2021
      if(!exportLoading) {
        setExportLoading(true);
        let marketApi = new MarketPlaceAPI();
        
        const allStatuses = [
          helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
          helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
          helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
          helper.getStatusKeyByValue(ShippingRequestStatus.Completed),
          helper.getStatusKeyByValue(ShippingRequestStatus.Cancelled)
        ];
        marketApi.getRequestsByQuery(0, 1000, 'DESC', { status: allStatuses })
          .then((data: any) => {
            let columnsData:any = []
            manageColumnsOptions.forEach((item:any) => {
              columnsData = [...columnsData, item.title]
            })
            setUserName((new UserService()).getUserDetails().email.split('@')[0]);
            // let mappedData = [["Status", "Request ID", "Provider", "Price (INR)", "Created On"]];
            let mappedData = [columnsData];
            data.data.forEach((item: any) => {
              let DataToPush:any = []
              /* istanbul ignore next*/

              for (let index = 0; index < columnsData.length; index++) {                
                if(columnsData[index] === "STATUS"){
                  DataToPush = [...DataToPush, (item?.status) ? ShippingRequestStatus[item.status] : '!Error']
                }
                else if(columnsData[index] === "REQUEST ID"){
                  DataToPush = [...DataToPush, (item?.requestNumber) ? String(item.requestNumber) : '!Error']
                }
                else if(columnsData[index] === "PROVIDER"){
                  DataToPush = [...DataToPush, (item?.transporter?.name) ? item.transporter.name : 'N/A']
                }
                else if(columnsData[index] === "PRICE (INR)"){
                  DataToPush = [...DataToPush, (item?.paymentDetails?.actualAmount || item?.paymentDetails?.actualAmount === 0) ? item.paymentDetails?.actualAmount : 'N/A']
                }
                else if(columnsData[index] === "CREATED ON"){
                  DataToPush = [...DataToPush, (item?.createdAt) ? moment(new Date(item.createdAt)).format('DD-MMM-YY hh:mma') : 'Date Error']
                }
              }
              mappedData.push(
                DataToPush
              );
              // mappedData.push([
              //   (item?.status) ? ShippingRequestStatus[item.status] : '!Error',
              //   (item?.requestNumber) ? String(item.requestNumber) : '!Error',
              //   (item?.shipper?.name) ? item.shipper.name : 'N/A',
              //   (item?.paymentDetails?.actualAmount || item?.paymentDetails?.actualAmount === 0) ? item.paymentDetails?.actualAmount : 'N/A',
              //   (item?.createdAt) ? moment(new Date(item.createdAt)).format('DD-MMM-YY hh:mma') : 'Date Error'
              // ]);
            });
            setDataToDownload(mappedData);
            setClickedExport(true);
            setExportLoading(false);
          })
          .catch((err: any) => {
           
            console.error(err);
            setExportLoading(false);
            const UserToken = new ShipperHelper()
            UserToken.UserTokenExpiration()
          })
      }
    }

    useEffect(() => {
      if(clickedExport && csvLinkRef?.current && dataToDownload) {
        csvLinkRef.current.link.click();
        setClickedExport(false);
        setDataToDownload('');
      }
      
    }, [clickedExport])
  
    const handleFilterPopOverClick = (event: any) => {
      setAnchorElFilterPopOver(event.currentTarget);
    };
  
    const handleFilterPopOverClose = () => {
      setAnchorElFilterPopOver(null);
    };
  
   
  
    const openFilterPopOver = Boolean(anchorElFilterPopOver);
    const filterPopOverId = openFilterPopOver ? 'shipper-filter-popover' : undefined;
    const openManageColumnsPopOver = Boolean(anchorElManageColumnsPopOver);
    const manageColumnsPopOverId = openManageColumnsPopOver ? 'transporter-manage-columns-popover' : undefined;
   

  return (
    <Box sx={{ display: 'flex',width:"98.5rem",marginTop:"-13px",marginLeft:"-115px" }}>
     <ResponsiveDrawer setSearchReqId={setSearchReqId1} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
    <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <div className={classes.root}>
       
        <div>
           <div style={{width:"50%",textAlign:"left",float:"left",marginLeft:'1rem'}}>
           <p className={classes.title}>Trip Management</p>
           </div>
         
            <div style={{textAlign:"right",width:"20%",float:"right", marginRight:'-10px'}} > 
         
          
         
            <Link to='/createshipment' style={{color:"#1A0DAB",textDecorationLine:'none'}}>   <Button 
         variant="outlined"
         
         className={classes.button}
         
         > <AddIcon /> CREATE NEW </Button></Link>

            </div>
         
          
</div>
<br />  <br/><br /> <br/>
<div>
<div style={{width:'100%',float:'left',marginRight:'48px'}}>
<div>
  <span
    aria-describedby={filterPopOverId}
    data-testid="filterIcon"
    onClick={handleFilterPopOverClick}
    id='filterIcon'
  >
    <Badge color="info" variant="dot" invisible={!filterOptions}>
      <Tooltip title="Apply Filters" placement="bottom">
          <FilterListIcon  />
      </Tooltip>
    </Badge>
  </span>
  <Popover
    id={filterPopOverId}
    open={openFilterPopOver}
    anchorEl={anchorElFilterPopOver}
    onClose={handleFilterPopOverClose}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    PaperProps={{
      style: { width: '35rem' }
    }}
  >
    <TableFilter
      setFilterOptions={setFilterOptions}
      filterOptions={filterOptions}
      closeTableFilter={handleFilterPopOverClose}
    />
  </Popover>
   <span
    id='columnIcon'
    >
      <TableColumn 
       setManageColumnsOptions={setManageColumnsOptions} 
      manageColumnsOptions={manageColumnsOptions} 
      />
    </span>
  

<span  id='exportIcon' onClick={handleExportIconClick} data-testid="exportCsvIcon">
  <Tooltip title="Export All to CSV" placement="bottom">
    <ScreenShareOutlinedIcon
      data-testid="exportIcon"
      className={exportLoading ? `rotating ${classes.disableExportIcon}` : ``}
    />
  </Tooltip>
  <CSVLink
    data={dataToDownload}
    filename={`export_data_by_${userName}_${moment().format('YYYY-MM-DD_hh-mm-ssa')}.csv`}
    className="hidden"
    id="transportExportCsvLink"
    ref={csvLinkRef}
    target="_blank"
  >download</CSVLink>
</span>
</div>

<Tabs
  activeKey={keys}
  id="uncontrolled-tab-example"
  style={{width:'100%'}}
  data-testid="requestTabs"
  onSelect={handleChangeKey}
  value={value}
>

<Tab eventKey="activeRequest" title="ACTIVE" value={0} data-testid="activeRequest">
<ShipmentRequestactive searchReqId={searchReqId} filterOptions={filterOptions} manageColumnsOptions={manageColumnsOptions} handleChangeKey={handleChangeKey} /> 

</Tab>
<Tab eventKey="pastRequest" title="COMPLETED" value={1} data-testid="pastRequest">
<ShipmentRequest searchReqId={searchReqId} filterOptions={filterOptions} manageColumnsOptions={manageColumnsOptions} handleChangeKey={handleChangeKey} /> 
</Tab>

</Tabs>
</div>
</div>

    </div>
    </Box>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(ShipperLanding);