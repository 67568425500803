import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, Typography } from "@material-ui/core";
import AssetBaseAPI from "../../../../../services/assetService";

import { InputField, SelectField } from "../../common/FormFields";
import "./styles.css";
import ShipperAPI from "../../../../../services/shippingLanding";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import UserService from "../../../../../services/userService";
export default function AsetForm(props: any) {
    const {
        formField: {
            assettype,
    assetname,
    status,
    assettyp,
    assetid,
        },
    }= props;
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          flexGrow: 1,
          "@media (max-width:500px)": {
            width: "20rem",
          },
        },
      })
    );
    const classes = useStyles();
    const [assetData, setAssetData] = useState([]);
    const [userData, setUserData] = useState([]);
   
    let assetTypeOptions: any = [];
    let assetTypOptions: any = [];
    let idOptions: any = [];
   
    let assetResult: any = assetData.map((val: any) => {
     
        let test = { key: "", value: "" }
        test.key = val.id;
        test.value = val.name;
        assetTypeOptions = [...assetTypeOptions, test];
      
    });
    let userResult: any = userData.map((val: any) => {
     
      let test = { key: "", value: "" }
      test.key = val.id;
      test.value = val.name;
      assetTypOptions = [...assetTypOptions, test];
    
  });
   
    useEffect(() => {
     
      const assetAPI = new AssetBaseAPI();
      
      assetAPI
        .getAssetID()
        .then((res: any) => {
          setAssetData(res);
          // console.log(res);
          // console.log(assetData);
        });
        const ship=new ShipperAPI();
        ship.getShipperID()
        .then((res:any)=>{
            setUserData(res);
            // console.log(res);
            

        })
        

    }, []);
    

    let statusOptions: any = [{key:"1",value:"Active"},{key:"2",value:"inactive"},{key:"3",value:"under maintenence"}];
    return (
        <React.Fragment>
          <div className={classes.root}>
            <div className="layout-border">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <p className="cardTitle">Asset Details</p>
                    </Grid>
                    <Grid item xs={12}>
                       <SelectField
                        name={assettype.name}
                        label={assettype.label}
                        options={assetTypeOptions}
                        style={{ width: "40%" }}
                       
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField 
                        name={assetname.name}
                        label={assetname.label}
                      />
                    </Grid>
                    
                    {/* <Grid item xs={6}>
                    
                    <SelectField
                        name={assetid.name}
                        label={assetid.label}
                        options={idOptions}
                        style={{ width: "80%"}}

                        fullWidth
                      />
                      
                    
                    </Grid> */}
                    <Grid item xs={6}>
                    <SelectField
                        name={status.name}
                        label={status.label}
                        options={statusOptions}
                        style={{ width: "80%" }}
                        fullWidth
                      />
                    </Grid>
                   
                    <Grid item xs={12}>
                      <p className="cardTitle" data-testid="customerDetails">
                        Attach To Customer
                      </p>
                    </Grid>
                    <Grid item xs={12}  lg={6} md={6}>
                       <SelectField
                        name={assettyp.name}
                        label={assettyp.label}
                        options={assetTypOptions}
                        style={{ width: "80%" }}
                        fullWidth
                      />
                    </Grid>
                   </Grid>
              </Grid>
              </Grid>
            </div>
          </div>
        </React.Fragment>
      );
    }