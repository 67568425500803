import Plot from 'react-plotly.js'

const PickupandDeliverytime = (props: any) => {

    const Headingstyle: any = {
        textAlign:'left',
        marginLeft:'8px',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
       
        fontSize: '14px',
        color: "#000000DE"
    }

    var bar: any = {
        x:props.xaxis,
        y: props.pickup,
        type: 'lines+markers' || 'bar',
        width:0.5,
        name: 'Pick-up time',
        marker: {
            color: '#8246AF'
        }
    }

    var markerline: any = {
        x: props.xaxis,
        y: props.delivery,
        mode: 'lines+markers',
        type: 'scatter',
        connectgaps: true,
        name: 'Delivery time',
        marker: {
            color: '#fff',
            size: 10,
            line:{
                color:'#11873E',
                width:4
            }
        },
        line: {
            width: 2,
            color: '#11873E',
        }
    }

    var dotlinegreen: any = {
        x: props.xaxis,
        y: props.avgDeliveryTime,
        mode: 'lines',
        name: 'Avg. Delivery time',
        line: {
            color: '#11873E',
            dash: 'dot',
            width: 3
        }
    }

    var dotlinepurple: any = {
        x: props.xaxis,
        y: props.avgPickupTime,
        mode: 'lines',
        name: 'Avg. Pick-up time',
        line: {
            color: '#8246AF',
            dash: 'dot',
            width: 3
        }
    }
    
    return (
        <div>
            <div style={Headingstyle} >Pick-up and Delivery Time</div>
            {props.noData !== '' ? <div style={{minHeight:'330px',color:"#000",fontSize:"14px"}}>{props.noData}</div> :
            <Plot
                data={[bar, markerline,dotlinepurple, dotlinegreen]}
                layout={
                    {
                        margin: { l: 30, r: 5, b: 10, t: 10 },
                        xaxis:{
                            tickfont:{
                                family:"Roboto",
                                size:14
                            },
                            tick0: 0,
                            rangemode: 'tozero',
                          
                         
                        },
                        yaxis:{
                            tickfont:{
                                family:"Roboto",
                                size:14
                            },
                           
                            tick0: 0,
                            zeroline: false,
                            showline: false,
                            zerolinewidth: 5,
                            rangemode: 'nonnegative',
                            // nticks:3
                            showticklabels: true, 

                          
        
                            
                        },
                        height: 330,
                        
                        legend: {
                            x: 0.1,
                            y: -0.6,
                            orientation: 'h'

                        }
                    }}
                style={{ maxWidth: '1500px', maxHeight: '300px'}}
                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
            />}
        </div>
    )
}

export default PickupandDeliverytime