import React, { Fragment, useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { InputField } from "../../../common/FormFields";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { assetBaseURL } from "../../../../../services/_url";
import axios from "axios";
import ShipperAPI from "../../../../../services/shippingLanding";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export default function Editasset(props: any) {
  // console.log(props.location);
  const [assetName, setAssetName] = useState([]);
  // const [tenantId, setTenantId] = useState("");
  const [tenantName, setTenantName] = useState("");
  // const [tenantAssignmentId, setTenantAssignmentId] = useState("");
//  let tenantName= ""
  const [assetState, setAssetState] = useState({ AssetType: props.location[0].assetType.name, 
    AssetName: props.location[0].assetType.name, AssetNumber:props.location[0].number, createdAt:props.location[0].assetType.createdAt,
    lastUpdatedAt:props.location[0].lastUpdatedAt,id:props.location[0].assetType.id,id1:props.location[0].id,
    status: props.location[0].assetType.active ? "Yes" : "No",   CustomerAssetType: tenantName,
  city:props.location[0].city, locality:props.location[0].locality,region:props.location[0].region,
zone:props.location[0].zone,backup: props.location[0].backup ? "Yes" : "No",underMaintenance: props.location[0].underMaintenance ? "Yes" : "No",tenantId:props.location[1], customerName: props.location[0].servedCustomer?.id || "", driverName: ""})
  const classes = useStyles();
  const [assetData, setAssetData] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [type, setType] = useState(props.location[0].assetType.name);
  
  const changeHandler = (e: any) => {
    const { name, value } = e.target;
   /* if(name === 'AssetName') {
      setAssetState(prevState => ({
        ...prevState, [name]: value.number
      }))
      props.formData(assetState);
      return;
    }
    if (name === 'AssetType') {
      setType(value)
    }
    setAssetState(prevState => ({
      ...prevState, [name]: value
    }))*/

    if (name === 'AssetType') {
      setType(value)
    }
    setAssetState(prevState => ({
      ...prevState, [name]: value
    }))
    props.formData(name, value);
    if(name === 'AssetNumber') {
      setAssetState(prevState => ({
        ...prevState, [name]: value
      }))
      
    }
    props.formData(name,value);
      // console.log(props.formData(assetState),"formssssasetttttdata")
    
  }
  
  useEffect(() => {
    const type = "assets";
    axios
      .get(`${assetBaseURL}/assetTypes`)
      .then((res: any) => {
        // console.log(res.data)
        setAssetData(res.data);
      })
      .catch((err: any) => {
        setAssetData([]);
      });
      const shipperService = new ShipperAPI();
    shipperService.getShipperID()
      .then((res: any) => {
        let transporterOptions: any = []
        res.map((val: any) => {
          if (val.roles[0]?.name === "ClosedMarketplace" && (val.name === "ISE foods" || val.name === "ISE" || val.name === "LithPwr" || val.name === "Blowhorn" || val.id === assetState.tenantId )  ) {
            let test = { key: "", value: "" }
            test.key = val.id;
            test.value = val.name
            transporterOptions = [...transporterOptions, test]
          }
          
          if (assetState.tenantId === val.id){
            setTenantName( val.name)
            setAssetState((prevAssetState) => ({
              ...prevAssetState,
              CustomerAssetType: val.name
            }));
          }
        })
        setTransporterData(transporterOptions);
      })
     
  }, []);

  // useEffect(() => {
  //   // getting current tenant deatils
  //         axios
  //           .get(`${assetBaseURL}/assetAssignments/asset/${assetState.id1}`)
  //           .then((res: any) => {
  //              setTenantId(res.data[0].tenantId)
  //              setTenantAssignmentId(res.data[0].id)
               
  //           })
  //           .catch((err: any) => {
  //             setTenantId("")
  //             setTenantAssignmentId("")
  //           });
           
    
  //     }, []);

  useEffect(() => {
    if (type) {
      axios
        .get(`${assetBaseURL}/assets/type?type=${type}&page=0&size=100`)
        .then((res: any) => {
          // console.log(res.data.data)
          setAssetName(res.data.data);
        })
        .catch((err: any) => {
          setAssetName([]);
        });
    }

  }, [type]);

  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement">
          <Grid container spacing={3} style={{ marginLeft: "2rem" }}>
            <Grid item xs={12} >
              <Grid item xs={12} lg={6} style={{float:"right",width:"100%",marginTop:"-2.7rem"}}>
                <br /><br />
                <p style={{ fontSize: "14px" }} className="cardTitle">
                  Attach to customer
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tenant
                  </InputLabel>
                  <Select
                    style={{ width: "80%", }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="CustomerAssetType"
                    label="Tenant"
                    onChange={changeHandler}
                    value={assetState?.CustomerAssetType}
                  >
                    {transporterData.map((option: any) => {
                           return (
                             <MenuItem key={option.value} value={option.value} style={{color:"#000"}}>{option.value}</MenuItem>
                           )
                         })}
                          <br />
                  </Select>
                </FormControl>
                <br />
                <FormControl fullWidth style={{marginTop:"20px"}}>
                  <InputLabel id="demo-simple-select-label">
                  Backup
                  </InputLabel>
                  <Select
                    style={{ width: "80%"}}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="backup"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.backup}
                  >
                   
                   <MenuItem value="Yes" style={{color:"#000"}}>Yes</MenuItem>
                    <MenuItem value="No" style={{color:"#000"}}>No</MenuItem>
                          <br />
                  </Select>
                </FormControl>
                <br />
                <FormControl fullWidth style={{marginTop:"20px"}}>
                  <InputLabel id="demo-simple-select-label">
                  Maintenance
                  </InputLabel>
                  <Select
                    style={{ width: "80%"}}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="underMaintenance"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.underMaintenance}
                  >
                   
                   <MenuItem value="Yes" style={{color:"#000"}}>Yes</MenuItem>
                    <MenuItem value="No" style={{color:"#000"}}>No</MenuItem>
                          <br />
                  </Select>
                </FormControl>
                <br />
              </Grid>
              <Grid item xs={12} lg={6}>
                <p style={{ fontSize: "14px" }} className="cardTitle">
                  Asset Details
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Asset type
                  </InputLabel>
                  <Select
                    style={{ width: "80%", }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="AssetType"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.AssetType}
                  >
                    {assetData?.filter((option) =>  option.name !== "Reefer Trucks").map((option: any) => {
                      return (
                        <MenuItem key={option.id} value={option.name} style={{color:"#000"}}>
                          {option.name}
                        </MenuItem>
                      );  
                    })}
                  </Select>
                </FormControl>
                <br />
              </Grid>
              <br />
              {/* <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                    Asset Number
                  </InputLabel>
                  <Select
                    style={{ width: "80%", }}
                    labelId="demo-simple-select-label"
                    data-testid="assetNumber"
                    id="demo-simple-select"
                    name="AssetNumber"
                    label="AssetNUmber"
                    onChange={changeHandler}
                    value={assetState.AssetNumber}
                  >
                    {assetName.map((option: any) => {
                      return (
                        <MenuItem key={option.id} value={option.number}>
                          {option.number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
              </Grid> */}
              <Grid item xs={12} lg={6} >
                <FormControl fullWidth>
                    {/* <InputField id="demo-simple-select-label"
                style={{width:"30px"}}
                      name="AssetID"
                      label="Asset Number"
                      onChange={changeHandler}
                      value={assetState.AssetNumber}
                      
                    /> */}
                       <TextField id="outlined-basic"  name="AssetNumber" label="Asset Number" variant="outlined" onChange={changeHandler}
                      value={assetState.AssetNumber} style={{width:"80%"}}/>
                </FormControl>
                <br />
              </Grid>
              <br />
             
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    style={{ width: "80%" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="status"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.status}
                  >
                    <MenuItem value="Yes" style={{color:"#000"}}>Yes</MenuItem>
                    <MenuItem value="No" style={{color:"#000"}}>No</MenuItem>
                    
                  </Select>
                </FormControl>
                <br />
              </Grid>
              <br />
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Served Customer
                  </InputLabel>
                  <Select
                    style={{ width: "80%" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="customerName"
                    label="Served Customer"
                    onChange={changeHandler}
                    value={assetState.customerName}
                    disabled={!assetState.CustomerAssetType}
                  >
                    {/* <MenuItem key="999" value="" style={{ color: "#000" }}>
                      {"Clear Selection"}
                    </MenuItem>
                    {servedCustomerMenuItems &&
                      servedCustomerMenuItems.map((item: any) => {
                        return (
                          <MenuItem value={item.id} style={{ color: "#000" }}>
                            {item.customerDesc}
                          </MenuItem>
                        );
                      })} */}
                    {/* <MenuItem value="Active"style={{color:"#000"}}>Active</MenuItem>
                     
                          <MenuItem value="Undermantance"style={{color:"#000"}}>UnderMaintenance</MenuItem> */}
                  </Select>
                </FormControl>
                <br />
                {/* <span className={classes.span}>
                  {
                    customerNameFlag? "Served Customer is required":""
                  }
                </span> */}
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <FormControl fullWidth  >
                <TextField id="outlined-basic"  name="customerName" label="Served Customer"
                className={customerNameFlag?classes.input : classes.input1}
                 variant="outlined" onChange={changeHandler}
                     value={assetState.customerName} style={{color:"#000"}}/>
                   
                </FormControl>
                <br />
                <span className={classes.span}>
                  {
                    customerNameFlag? "Customer Name is required":""
                  }
                </span>
              </Grid> */}
              <br />
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    style={{width:"80%"}}
                    id="outlined-basic"
                    name="driverName"
                    label="Driver Name"
                    variant="outlined"
                    onChange={changeHandler}
                    value={assetState.driverName}
                    // style={{ color: "#000" }}
                  />
                </FormControl>
                {/* <br />
                <span className={classes.span}>
                  {driverNameFlag ? "Driver Name is required" : ""}
                </span> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
}