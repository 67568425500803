import { Box, Container, Grid } from "@material-ui/core";
import {Dialog,  DialogContent,  DialogContentText, DialogTitle, Button, Stack } from '@mui/material';
import {  useHistory } from "react-router-dom";
import UserService, { ROLE } from "../../../services/userService";
import * as React from "react";



const PrivacyAndPolicy = () => {
   
    const history = useHistory();
    const userApi = new UserService();
    const data : any = history.location.state;

    const handleAgree = async(data: any) => {
      //console.log('agree', data);
      let response = await userApi.privacyPolicy("", data.token);
    //   console.log(response);
      if(response.data.status === 'Success') {
        if (data?.roles) {
            let isRoleFound: boolean = false;
            data.roles.forEach((role: string) => {
              if (role === ROLE.denso) {
                isRoleFound = true;
                history.push("/asset");
                return;
              } else if (role === ROLE.shipper) {
                isRoleFound = true;
                history.push("/shipper");
                return;
              } else if (role === ROLE.transporter) {
                isRoleFound = true;
                history.push("/transporter");
                return;
              }
              else if (role === ROLE.closedMarketplace) {
                isRoleFound = true;
                history.push("/asset");
                return;
              }
            });
        }
      }
    }

    const [bottom, setBottom] = React.useState(true);

    const handleDisagree = () => { 
      userApi.logout(history);
    }
    const handleScroll = (e: any) => {
        const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed()) === e.target.clientHeight;
        //console.log(bottom, e.target.scrollHeight, e.target.scrollTop, e.target.clientHeight, (e.target.scrollHeight - e.target.scrollTop).toFixed())
        setBottom(!bottom)
    };
    return (
        <Dialog maxWidth={"lg"} open={true} style={{color: 'black'}}>
            <DialogTitle style={{color: 'black'}}>Privacy Policy</DialogTitle>
            <DialogContent onScroll={handleScroll}>
            <Box style={{color: 'black'}}>
                <Box sx={{ pt: 4 }}>
                    DENSO International India Private Limited, having its registered office at B-1/D-4, Ground Floor Mohan Co-operative Industrial Estate, Mathura Road, New Delhi-110044 (<b>“DENSO”, “our”, “we”,</b> and <b>“us”</b>) and its Affiliates (defined below) respect the privacy of a natural person, and are committed to protecting it in accordance with the terms of this privacy policy (<b>“Policy”</b>) when you (defined below) access, browse or use our Platform (defined below) in relation to the features, products or services offered on the Platform (<b>“Services”</b>).
                </Box>
                <Box sx={{ pt: 4 }}>
                    For the purposes of this Policy,
                </Box>
                <Box sx={{ pt: 4 }}>
                    (a) <b>“Affiliate”</b> means any other Person that, directly or indirectly, Controls, is Controlled by or is under common Control with such Person;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (b) <b>“Control”</b> means the possession, directly or indirectly, of the power to direct the management or policies of any Person, whether through the ownership of over 50% of voting securities, or through the power to appoint over half of the members of the board of directors or similar governing body of such Person or by contract or otherwise and the terms <b>“Controls”</b> and <b>“Controlled”</b> shall have like meaning;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (c) <b>“Cookies”</b> means a small file placed on your computer by this Platform when you either visit or use certain features of the Platform that would further allow the Platform to ‘remember’ your actions or preference for a certain period of time;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (d) <b>“Data Protection Laws”</b> means any applicable law relating to the collection, processing, handling and storage of personal information/ data, including the Information Technology Act, 2000 and rules framed thereof, and the GDPR, as amended or substituted;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (e) <b>“GDPR”</b> means the General Data Protection Regulation (EU) 2016/679;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (f) <b>“Person(s)”</b> means any natural person, limited or unlimited liability company, corporation, partnership (whether limited or unlimited), proprietorship, Hindu Undivided Family, trust, union, or any association;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (g) <b>“Personal Information”</b> or <b>“Sensitive Personal Data or Information”</b> shall have the same meaning as set out under applicable Data Protection Laws;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (h) <b>“Platform”</b> means <a href="https://marketplace-coldchain.maas-diin.com">https://marketplace-coldchain.maas-diin.com</a>, and include its corresponding mobile application/website or any other digital mode and/or operating system as provided by DENSO;
                </Box>
                <Box sx={{ pt: 4 }}>
                    (i) <b>“User”</b> or <b>“you”</b> means the individual(s) (including registered or unregistered users) who access the Platform or whose information we collect and process in connection with the Services and shall include individuals who are legal/ authorized representatives of entities who have agreed to DENSO’s “E-Commerce Entities Onboarding Terms and Conditions” and/or “Logistics Partners Business Terms and Conditions”
                </Box>
                <Box sx={{ pt: 4 }}>
                    Your privacy is important to us. We believe it is important for you to know how we treat your Personal Information. This Policy explains what information we collect from you, through our interactions with you on the Platform, and how we use such information.
                </Box>
                <Box sx={{ pt: 4 }}>
                    The Platform is not directed towards minors. If a parent or guardian becomes aware that his/ her child has provided his/ her information without their consent, then the parent or guardian of such child may contact us for the deletion of such Information. In the event, where in contravention to the Policy, a person below the age of 18 years uses our Platform, we shall not be held liable or responsible for any damage or injury suffered by such person.
                </Box>
                <Box sx={{ pt: 4 }}>
                    When you access, use, browse, or register on the Platform for the first time, you will be asked to accept the terms of the Policy (via a pop-up or through any other mode), and by accepting the same, you give your express consent to the collection, storage, possession, dealing, handling, sharing, disclosure or transfer of your Personal Information (as provided to DENSO) in accordance with the terms of this Policy. In the event you choose to not provide us with such consent, we will be able to provide you with limited access to Platform only. You may at any time withdraw your consent for collection, storage, possession, dealing, handling, sharing, disclosure or transfer of your Personal Information by contacting our grievance officer at <a href="mailto:ap_diin_msdsupport@ap.denso.com">ap_diin_msdsupport@ap.denso.com</a>. However, please note that if you withdraw your consent, we may no longer be able to provide you with the corresponding service for which you have withdrawn your consent. It is hereby clarified that your decision to withdraw your consent will not affect the processing of Personal Information based on your previous consent prior to the withdrawal.
                </Box>
                <Box sx={{ pt: 4 }}>
                    This Policy constitutes an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures. If you do not agree with any provisions of the Policy, we advise you to not use or access the Platform.
                </Box>
                {/* Our Policy Explains */}
                <Box sx={{ pt: 4 }}>
                    <h4>1) Our Policy Explains:</h4>
                    <Box sx={{ p: 4 }}>
                        (a) What information we collect and why we collect it.
                    </Box><Box sx={{ p: 4 }}>
                        (b) How we use the collected information.
                    </Box><Box sx={{ p: 4 }}>
                        (c) The choices we offer, including how to access and update Personal Information.
                    </Box><Box sx={{ p: 4 }}>
                        Whether you are new to the Platform or a long-time user, please do take the time to read the Policy to get to know our practices. In case of any questions please contact us. While we have tried to keep this document simple, there may be terms you are unfamiliar with. Please read about them before accessing our Platform.
                    </Box>
                </Box>
                {/* Information we collect */}
                <Box sx={{ pt: 4 }}>
                    <h4>2) Information we collect</h4>
                    <Box>
                        We collect information in the following ways to provide Services to our Users.
                    </Box>
                    <Box sx={{ p: 4 }}>
                        (a) Information you give us:
                        <Box sx={{ p: 4 }}>
                            (i) Personal Identifiers: When you sign up on the Platform and/or avail Services, we may ask for information which personally identifies you including your full name, email address, contact number, address (includes state, city, pin code).
                        </Box>
                        <Box sx={{ p: 4 }}>
                            (ii) KYC and Financial Information: We may collect KYC data and documents including PAN, GST certificates, or incorporation documents which are required to unlock various features or actions on the Platform.
                        </Box><Box sx={{ p: 4 }}>
                            (iii) Feedback: We may collect feedback from you, such as your review or rating of the services offered to you by the e-commerce and logistics entities that use our Services.
                        </Box>
                    </Box>
                    <Box sx={{ p: 4 }}>
                        (b) Information we collect by automated means:
                        <Box sx={{ p: 4 }}>
                            (i) Information we get from your use of our Services: We collect information about the Services that you use and how you use them. Pursuant to collection of such information, we will be in a position to enhance your profile and in resolving any problem which you may encounter while using the Platform.
                        </Box>
                        <Box sx={{ p: 4 }}>
                            (ii) Device Information: We may collect device specific information (such as your hardware model, operating system version, unique device identifiers, and mobile network information including phone number). The Platform may associate your device identifiers or phone number with your account on the Platform.
                        </Box><Box sx={{ p: 4 }}>
                            (iii) Log Information: When you use our Services or view content provided by us, we automatically collect and store certain information in server logs. This includes details of how you used our services, such as your search queries, Internet protocol address, device event information such as crashes, system activity, hardware settings, browser type and browser language, the date and time of your request and referral URL.
                        </Box>
                        <Box sx={{ p: 4 }}>
                            (iv) Location Information: When you use our Services, we may collect and process information about your actual location.
                        </Box>
                        <Box sx={{ p: 4 }}>
                            (v) Local storage: We may collect and store information locally on your device using mechanisms such as browser web storage and application data caches.
                        </Box>
                        <Box sx={{ p: 4 }}>
                            (vi) Cookies and similar technologies: Our Platform may use Cookies such as HTTP cookies. Your browser may tell you how to be notified when you receive certain types of Cookies and how to restrict or disable certain Cookies. Please note, however, that without cookies you may not be able to use all the features of our Platform.
                        </Box>
                        <Box sx={{ p: 4 }}>
                            (vii) Others: We may collect further information from you in order to operate the Platform and provide Services, from time to time.
                        </Box>
                    </Box>
                    <Box sx={{ p: 4 }}>
                        (c) Information we receive from third parties:
                        <Box sx={{ p: 4 }}>
                            We may also receive information about you from third parties availing our Services that you may interact and engage with. These third parties may collect certain data about your visits to and activity on their websites or services, and access their own tracking technologies on your device (including cookies and web beacons). Some of these parties may share the data so collected with us so as to enable us to provide the Services to you. For instance, we facilitate deliveries to you on behalf of the e-commerce companies that use our Services. They may share details, such as your name, delivery address, phone number, delivery instructions, order ID and payment mode, with us to facilitate delivery of goods to you.
                        </Box>
                    </Box>
                </Box>
                {/* How we use the information we collect: */}
                <Box sx={{ pt: 4 }}>
                    <h4>3) How we use the information we collect:</h4>
                </Box>
                <Box sx={{ pt: 4 }}>
                    We use the information in the following manner:
                </Box>
                <Box sx={{ pt: 4 }}>
                    (a) To provide you with access to Services and our Platform.
                </Box>
                <Box sx={{ pt: 4 }}>
                    (b) To provide you with information that you may request from time to time.
                </Box><Box sx={{ pt: 4 }}>
                    (c) To notify you about changes to our Platform, or Services, or any additional services that we may offer.
                </Box><Box sx={{ pt: 4 }}>
                    (d) To improve our Services or your experience of the Platform, including troubleshooting, data analysis, testing, system maintenance, support, reporting, and hosting of data.
                </Box><Box sx={{ pt: 4 }}>
                    (e) For internal recordkeeping and analytical purposes including accounting, data analysis, auditing and research.
                </Box><Box sx={{ pt: 4 }}>
                    (f) To detect, monitor, investigate, deter, and protect against fraudulent, unauthorized or illegal activity on the Platform.
                </Box><Box sx={{ pt: 4 }}>
                    (g) To inform you about promotions, events, updates, offers and news related to the Platform or Services.
                </Box><Box sx={{ pt: 4 }}>
                    (h) To comply with any legal or regulatory requirement or comply with a request from any governmental or judicial authority.
                </Box><Box sx={{ pt: 4 }}>
                    (i) To provide customer support service or resolve any request, dispute, grievance or complaint raised by you in relation to your use of the Platform or Services.
                </Box>
                {/* Legal Bases */}
                <Box sx={{ pt: 4 }}>
                    <h4>4) Legal Bases</h4>
                </Box>
                <Box sx={{ pt: 4 }}>
                    If you are located in the European Economic Area (EEA) region, and the GDPR applies to our processing of your Personal Information, we rely on the following legal bases in accordance with the GDPR, to collect and process your information:
                    <Box sx={{ pt: 4 }}>
                        4.1 Contract: We may process your data if doing so is necessary to enter into or perform our contract with you – this is the agreement between us and you about the use of our Platform and the Services offered by us.
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        4.2 Legitimate Interests: We may process your data in our legitimate interests (where we have considered that these are not overridden by your rights), which we set out in Clause 3 of the policy. Our legitimate interests include improving our Platform or troubleshooting or providing customer support.
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        4.3 Consent: We may process your data with your consent, for example where we would like to share news about exciting features, new options, or other promotional and marketing content. Where we are processing your data based on your consent, you can withdraw your consent to that processing at any time by contacting us at [•].
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        4.4 Legal Requirement: We may process your data to comply with applicable laws and in cooperation with local law enforcement.
                    </Box>
                </Box>
                {/* Information we share: */}
                <Box sx={{ pt: 4 }}>
                    <h4>5) Information we share:</h4>
                </Box>
                <Box sx={{ pt: 4 }}>
                    We only share your Personal Information with other Persons for the purposes explained below:
                    <Box sx={{ pt: 4 }}>
                        a) Service providers and partners:
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        We may share your Personal Information with our service providers, partners or other Persons to process it for us, based on our instructions and in compliance with our Policy and any other appropriate confidentiality and security measures while ensuring the same level of data protection that is adhered to by us under the Data Protection Laws. For example, we share data with marketing partners to reach or better understand our users and measuring advertising effectiveness.
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        b) Affiliates and Business transfers:
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        We may disclose your Personal Information with our Affiliates when it is necessary to provide the Services. We may disclose Personal Information as part of a corporate transaction such as a merger, acquisition or asset sale. In such cases, we would endeavour to ensure that the party to whom information is being transferred also maintains the same level of confidentiality and privacy as maintained by us.
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        c) For legal reasons:
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        We may share your Personal Information  if we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to meet any applicable law, legal process, or enforceable governmental request, enforce applicable ‘terms of use’, investigation of potential violations, detect, prevent, or otherwise address fraud, security or technical issues, protect against harm to the rights, property or safety of DENSO, our users or the public as required or permitted by law. We may also share non-personally identifiable information publicly and with our partners – like distributors or connected sites. For example, we may share information publicly to show trends about the general use of our Services.
                    </Box>
                </Box>
                {/* Third party links:  */}
                <Box sx={{ pt: 4 }}>
                    <h4>6) Third party links: </h4>
                </Box>
                <Box sx={{ pt: 4 }}>
                    4.3 Consent: We may process your data with your consent, for example where we would like to share news about exciting features, new options, or other promotional and marketing content. Where we are processing your data based on your consent, you can withdraw your consent to that processing at any time by contacting us at [•].
                </Box>
                <Box sx={{ pt: 4 }}>
                We work hard to protect DENSO and our Users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold.
                </Box>
                <Box sx={{ pt: 4 }}>
                In particular, we encrypt all our Services and process and store information in terms of Data Protection Laws. We use appropriate technical and organizational security measures to ensure a level of protection for Personal Information appropriate to the risk:
                <Box sx={{ pt: 4 }}>
                    &#x2022; We encrypt data at rest to protect unauthorised use of your data;
                </Box>
                <Box sx={{ pt: 4 }}>
                    &#x2022; We also maintain strict access controls to prevent unauthorized access to your Personal Information; 
                </Box>
                <Box sx={{ pt: 4 }}>
                    &#x2022; The transfer of Personal Information between your end device and us is generally carried out in an encrypted form over ​​​​​​​​secured HTTPS connection. You can identify an encrypted connection for example by the lock symbol in the address line of your browser;  
                </Box>
            
                </Box>
                <Box sx={{ pt: 4 }}>
                We review our information collection, storage and processing practices, including physical security measures, from time to time, to guard against unauthorized access to systems. We restrict access to your Personal Information to our employees, contractors and agents who need to know that information in order to process it for us, and who are subject to contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
                </Box>
                <Box sx={{ pt: 4 }}>
                Although we make best possible efforts to transmit and store all the information provided by you in a secure operating environment that is not open to public, you understand and acknowledge that there is no such thing as complete security and we do not guarantee that there will be no unintended disclosures of any information or potential security breaches. You agree not to hold us responsible for any breach of security or for any action of any third parties that receive your information or events that are beyond our reasonable control including, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, etc. However, if you have reason to believe that your interaction with us is no longer secure, please immediately notify us at the “Contact Us” section below.
                </Box>
            </Box>
            {/* Third party links:  */}
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>6) Third party links: </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                The Platform may include links to websites of third parties whose privacy practices differ from those of DENSO. If you provide personal data to any of those websites, the privacy policies of such third-party websites would govern your data.
            </Box>
             {/* Information Security:  */}
             <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>7) Information Security: </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            We work hard to protect DENSO and our Users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold.
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            In particular, we encrypt all our Services and process and store information in terms of Data Protection Laws. We use appropriate technical and organizational security measures to ensure a level of protection for Personal Information appropriate to the risk:
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; We encrypt data at rest to protect unauthorised use of your data;
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; We also maintain strict access controls to prevent unauthorized access to your Personal Information; 
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; The transfer of Personal Information between your end device and us is generally carried out in an encrypted form over ​​​​​​​​secured HTTPS connection. You can identify an encrypted connection for example by the lock symbol in the address line of your browser;  
            </Box>
           
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            We review our information collection, storage and processing practices, including physical security measures, from time to time, to guard against unauthorized access to systems. We restrict access to your Personal Information to our employees, contractors and agents who need to know that information in order to process it for us, and who are subject to contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            Although we make best possible efforts to transmit and store all the information provided by you in a secure operating environment that is not open to public, you understand and acknowledge that there is no such thing as complete security and we do not guarantee that there will be no unintended disclosures of any information or potential security breaches. You agree not to hold us responsible for any breach of security or for any action of any third parties that receive your information or events that are beyond our reasonable control including, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, etc. However, if you have reason to believe that your interaction with us is no longer secure, please immediately notify us at the “Contact Us” section below.
            </Box>
            {/* Where we Store and Process Personal Information:  */}
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>8) Where we Store and Process Personal Information: </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            Your Personal Information which is collected by DENSO may be stored and processed in or outside your region. The storage location(s) are chosen in order to operate efficiently, to improve performance, and to create redundancies in order to protect your Personal Information in the event of an outage or other problems. We take steps to ensure that the information we collect under this Policy is processed according to the provisions of this Policy and the requirements of Data Protection Laws wherever the information is located.
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            Your Personal Information which is collected by DENSO may also be transferred to, and stored at, a destination outside your home country, with and by third parties, when this is necessary for us to provide our products or run our services. If we do transfer your personal data outside your home country, we will adopt measures to secure an equivalent level of protection for the information as in your home country.
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            If you reside in the EEA region, and we transfer your Personal Information outside the EEA region, we will take steps reasonably necessary to ensure that this data is subject to appropriate and required safeguards. Some non-EEA countries are recognized by the European Commission as having an “adequate” level of data protection according to the standards set by the European Commission. If we transfer your Personal Information to non-EEA countries that do not have “adequate” levels of protection, we will use the standard contractual clauses approved by the EU authorities.
            </Box>
            {/* When this Policy applies:  */}
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>9) When this Policy applies: </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            Our Policy does not apply to services or products offered by other Persons, including products or sites that may be displayed to you in search results or other sites linked to our Services. Our Policy does not cover the information practices of other companies who advertise our services, and who may use Cookies, pixel tags, and other technologies to serve and offer relevant ads.
            </Box>
             {/* Compliance and Cooperation with Regulatory Authorities:  */}
             <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>10) Compliance and Cooperation with Regulatory Authorities: </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            We regularly review our compliance with our Policy. When we receive formal written complaints, we shall contact the Person who made the complaint to follow up. 
            </Box>
            {/* Changes to This Policy:  */}
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>11) Changes to This Policy: </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            Our Policy may change from time to time. We will post any changes in the Policy on this page and, if the changes are significant, we shall provide a more prominent notice (including, for certain services, email notification of changes in the Policy). 
            </Box>
            {/* Retention Policy:  */}
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>12) Retention Policy: </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            We will retain your Personal Information for the period necessary to fulfil the purposes outlined in this Policy unless a longer retention period is required or permitted under the Data Protection Laws or to comply with our legal obligations, to maintain accurate accounting, financial and other operational records, resolve disputes, or enforce our agreements.
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            For example, we may retain data:
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; for the duration that you continue to use our Services (e.g. your personally identifiable information so that we can continue to provide our Services to you);
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; for 8 years if necessary to comply with tax requirements (e.g. your financial information to ensure compliance with the applicable taxation laws);
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; after requests for account deletion if necessary for: 
                 <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 O      purposes of safety, security, fraud prevention; 
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 O      to establish, exercise or defend our legal claims;   
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 O      to comply with legal rules that apply to us about keeping records or information. 
            </Box>
            </Box>
           
            </Box>
            {/* Your Rights   */}
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>13) Your Rights  </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            If you wish to access, verify, correct or update any of your Personal Information with us, you may write to us at the email address provided in the Contact Us section. Do note that we are not responsible for the authenticity of the data that you provide us.
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            For users in the EEA
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            We may collect Information under inter-group company arrangements as a data processor where GDPR may become applicable to us.
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            If you reside in the EEA regions and the GDPR applies to our processing of your Personal Information, you have the right under certain circumstances: 
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; to be provided with a copy of your Personal Information held by us;
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; to request the rectification or erasure of your Personal Information held by us;
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; to request that we restrict the processing of your Personal Information (while we verify or investigate your concerns with this information, for example);
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; to object to the further processing of your Personal Information in certain circumstances;
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                 &#x2022; to object to decisions being taken by automated means which produce legal effects concerning you or which similarly may significantly affect you to request that your provided Personal Information be moved to a third party.
            </Box>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            If you want to exercise any of the above rights, please contact us as specified under Clause 13 (refer below).
            </Box>
            {/* How to Contact Us/ Our Grievance Officer:   */}
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
                <h4>14) How to Contact Us/ Our Grievance Officer:  </h4>
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            If you have a question, concern, or complaint regarding our use of your Personal Information or about this Policy, you can write to our Grievance Officer Mr. Tsuyoshi Morimoto at  <a href="mailto:ap_diin_msdsupport@ap.denso.com">ap_diin_msdsupport@ap.denso.com</a>. The Grievance Officer will serve as the designated Grievance Officer under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011. 
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            For the purposes of the GDPR, our Grievance Officer is also our Data Protection Officer (DPO).
            </Box>
            <Box sx={{ pt: 4 }}  style={{color: 'black'}}>
            If you are in the EEA region, you can choose to instead lodge a complaint with your local Data Protection Authority (DPA). The list of DPAs is at http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.
            </Box>
            </DialogContent>
            <Stack spacing={2} direction={"row"} sx={{padding: 2, alignSelf: "center"}}>
                <Button variant="contained" onClick = {()=>handleAgree(data)} disabled={bottom}>Agree</Button>
                <Button variant="contained" color="secondary" onClick = {()=>handleDisagree()} disabled={bottom}>Disagree</Button>
            </Stack>
        </Dialog>
    )

}

export default PrivacyAndPolicy;

