import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { Stepper, Step, StepLabel, Button, Grid, Paper, Box } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import { IconButton } from "@material-ui/core";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import checkoutFormModel from "../../../CheckoutPage/FormModel/checkoutFormModel";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { setCurrentUser } from "../../../../../redux/user/user.action";
import { selectCurrentUser } from "../../../../../redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { ResponsiveDrawer } from "../../../common/Drawer";
import "./style.css";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {  useParams, useLocation } from "react-router-dom";
import ShipperHelper from "../../../../../services/_helpers";
import { assetBaseURL } from "../../../../../services/_url";
import DoneIcon from "@material-ui/icons/Done";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import axios from "axios";
import AssetDetails from "./assetDetails";
import LocationDetails from "./LocationDetails";
import InfoIcon from "@material-ui/icons/Info";
import { toast } from "react-toastify";
import formInitialValues from "../../../Denso/CreateShipment/FormModel/formInitialValues";
import back from "../../../../../assets/images/back.svg";
import done from "../../../../../assets/images/done.svg";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { connect } from "react-redux";
import moment from "moment-timezone"
const steps = [" Basic Details", " Location Details"];
// eslint-disable-next-line react-hooks/rules-of-hooks


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginRight: "48px",
    },
    backBtn: {
      color: "#1A0DAB",
    },
  })
);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    color: "#5A5A5A",
  },
  active: {
    "& $line": {
      borderColor: "#0078A5",
      backgroundColor: "#0078A5",
    },
    color: "#5A5A5A",
  },
  paper: {
    textAlign: "center",
    color: "#000",
    minHeight: "15rem",
  },

  completed: {
    "& $line": {
      borderColor: "#007AA6",
    },
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: "#D8EEF3",
  },
})(StepConnector);

const { formId, formField } = checkoutFormModel;

let dateHelper = new ShipperHelper();


export function AddHardwareAsset(props: any) {
  let {   currentUser, setCurrentUser } = props;
  const id: any = useParams();
  const location = useLocation();
  const item = {
    city:"",
    locality:"",
    zone:"",
    region:""
  }
  const { activeStepProp } = props;
  const [activeStep, setActiveStep] = useState(
    activeStepProp ? activeStepProp : 0
  );
  let history = useHistory();
  const [btnFlag, setBtnFlag] = useState(false);
  const [getrequestId, setgetrequestId] = useState("");
  const [searchReqId, setSearchReqId] = useState<string>("");
  const [tentant, SetTentant] = React.useState([]);
  const [assert, SetAssert] = useState([]);
  const isLastStep = activeStep === steps.length - 1;
  const classes = useStyles();
  const [locationDataHandler, setLocationDataHandler] = useState({locality:"", City:"", Zone:"", Region:""});
  const [assetDataHandler, setAssetDataHandler] = useState({AssetType:"",AssetName:"",AssetID:"",Status:"", id:"", CustomerAssetType: "", customerName: "", driverName:"", tenantId: "", active: "", description: ""});
  const [addedAssetData, setAddedAssetData] = useState({AssetType:"",AssetName:"",AssetID:"",Status:"", id:"", CustomerAssetType: "", customerName: "", driverName:"", tenantId: "", active: "", description: ""});
  const [validate, setValidate] = useState(false);
  const [locationState, setLocationState] = useState({locality: "", 
    City: "", Zone: "", Region: ""});
    const [errFlag,setErrFlag] = useState(0);
    function _renderStepContent(
      assetFormHandler : (value:any) => void,
      locationFormHandler : (name:string, value:string) => void,
      step: number,
      setFieldValue?: any,
      values?: any,
      getrequestId?: any,  
      locationData?:any,
      validate?:any,
      nextGroup?:any,
      storedData?:any,
      storeLocationData?:any
    ) {
      switch (step) {
        case 0:
          return <AssetDetails validationFlag={nextGroup} validate={validate}
          storedData={storedData} formData={assetFormHandler} location={locationData} errstatus={errFlag}/>;
        case 1:
          return (
            <LocationDetails  setFieldValue={setFieldValue} 
            formData={locationFormHandler} location={locationData}  storedData={storeLocationData}/>
          );
        default:
          return <div>Internal Error.</div>;
      }
    }
  var updateInitalValues = {    
  };

  const assetFormHandler = (value: any) => {
      setAssetDataHandler(value);
  }
  // console.log(assetDataHandler,"assetDatahandler")
  
  const locationFormHandler = (name: string, value:string) => {
    // setLocationDataHandler(value);
    setLocationDataHandler(prevState => ({
      ...prevState, [name]:value
    }))
  }

  const isUpdateMode = !!id["id"];
  const getTransporterId: any = (name: any) =>
    tentant.filter((val: any) => val.name === name);

  async function _submitForm(values: any, actions: any) {
    let Ship = new ShipperHelper();
    setBtnFlag(true);
    try {
      let postData = {
        assetType: {
          description: assetDataHandler.description,
          id: assetDataHandler.id,
          name: assetDataHandler.AssetType,
        },
        number: assetDataHandler.AssetID,
        locality: locationDataHandler.locality,
        zone: locationDataHandler.Zone,
        city: locationDataHandler.City,
        region: locationDataHandler.Region,
      };
      await newAPI(postData);
    } catch (e) {
      return e;
    }
  }

  /**
   * 
   * @param id => asset id
   * @param active => asset active status
   * @returns payload for asset assignment
   */
  const assetAssignmentPayload = (id: any, active: any)=> {
    const date = moment().add(5, "minutes");
    const endDate = dateHelper.addOneYearToDate(date);
    const payload = {
      tenantId: assetDataHandler.tenantId,
      asset: {
        // active: active,
        id: id,
        assetType:{
          description:assetDataHandler.AssetType,
          id:assetDataHandler.id,
          name:assetDataHandler.AssetType,
          // active: assetDataHandler.active
        },
        number:assetDataHandler.AssetID,
      },
      startDate: dateHelper.parseDateToGmt(date),
      endDate: dateHelper.parseDateToGmt(endDate)
    }
    return payload;
  }

  /**
   * 
   * @param id id - asset id
   * @param active asset active status
   * generates payload and calls asset assignment api and assigns the customer info to newly created asset
   */
  const assetAssignment = async(id: any, active: any)=> {
    const payload = assetAssignmentPayload(id, active);
    let response = await axios.post(`${assetBaseURL}/assetAssignments`,payload).then((res)=>{
      if (res.status === 201) {
        success("Asset Assignment done successfully");
        setTimeout(() => {
          navigateToAssetOverview()
        }, 2000)
      }
      if (res.status === 500) {
        error(res.data.data.response);
      }
     // return res.data;
    })
    .catch((err: any) => {});
  }

  /**
   * navigates to the asset overview screen upon asset assignment success
   */
  const navigateToAssetOverview = ()=> {
    // window.location.reload();
    history.push("/assetoverview");
  }

  /**
   * 
   * @param postData payload for create asset api
   * once create asset is success, will call the asset assignment api
   */
  const newAPI = async(postData:any) => {
    let resultData = await axios.post(`${assetBaseURL}/assets?page=0&size=50`,postData).then((res)=>{
      if (res.status === 201) {
        success("New Asset added successfully");
        assetDataHandler.customerName && servedCustomerAssignment(res.data.id);
        assetDataHandler.tenantId && assetAssignment(res.data.id, res.data.active);
        (!assetDataHandler.customerName && !assetDataHandler.tenantId) && setTimeout(() => {
              navigateToAssetOverview()
            }, 2000)
        // window.location.reload();
      }
      if (res.status === 500) {
        error(res.data.data.response);
      }
     // return res.data;
    })
    .catch((err: any) => {});
  }

  /**
   * 
   * @param id asset id from assets post api
   * once create asset is success, will call the servedCustomer Mapping api
   */
  const servedCustomerAssignment = async (id: any) => {
    if (assetDataHandler.customerName) {
      const payload = {
        assetId: id,
        servedCustomerId: assetDataHandler.customerName,
      };
      let response = await axios
        .post(`${assetBaseURL}/assets/assetServedCustomerMap`, payload)
        .then((res) => {
          if (res.status === 201) {
            success("Served Customer Mapped successfully");
          }
          if (res.status === 500) {
            error(res.data.data.response);
          }
          // return res.data;
        })
        .catch((err: any) => {});
    }
  };
  const handleNext =() =>{
    // console.log("form data",assetDataHandler)
    
  }
  function _handleSubmit(values: any, actions: any) {
    if (activeStep >= 1) {
      actions.setSubmitting(true);   
      _submitForm(values, actions);
      // history.push("/assetoverview");
    } else if (activeStep === 0) {
      validate ? setValidate(false) : setValidate(true);
      if (getrequestId === "") {
        let Ship = new ShipperHelper();
        let requestId =
          values.shipper.substring(0, 3).toUpperCase() + Ship.requestNumber();
        setgetrequestId(requestId);        
        //actions.setSubmitting(false);
        actions.setTouched({});
      } else {
        setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
      actions.setTouched({});
    }
  }
  function _handleBack() {
    setActiveStep(activeStep - 1);
    setAddedAssetData(assetDataHandler);
    setLocationState(locationDataHandler);
  }

  const nextGroup = (flag:boolean) => {
    if(flag){
      setActiveStep(activeStep + 1);
    }
  }


  const success = (message: any) => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>{message}</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };
  const error = (msg: any) => {
    toast.warning(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#FF4F14",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>{msg}.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };


  return (
    <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:"-10px" }}>
    <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
    <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <Fragment>
      <div className={classes.root}>
        <Formik
          initialValues={isUpdateMode ? updateInitalValues : formInitialValues}
          enableReinitialize={true}
          data-testid="checkoutFormikInitialisation"
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue }) => (
            <Form id={formId}>
              <Grid container spacing={3} style={{ marginBottom: '1rem' }}>
                <Grid item xs={5} >
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    data-testid="backToDenso"
                    onClick={() => {
                      window.history.back();
                    }}
                    style={{ color: "#5A5A5A", cursor: "pointer",marginTop:"-23px" }}
                  >
                    {/* <ArrowBackIcon /> */}
                  </IconButton>
                  <span style={{ fontSize: "16px", fontWeight: "normal" ,marginLeft:"15px"}}>
                    {isUpdateMode ? "Update" : ""}Add New Asset
                  </span>
                </Grid>
                {/* <Grid item xs={7}>
                  <div >
                  {activeStep >= 1 ?
                    <Button
                      variant="outlined"
                      onClick={_handleBack}
                      disabled={activeStep === 0}
                      style={{textDecoration:"none",width:"65px",
                      marginLeft:(isLastStep ? "550px": "500px"),
                      
                    
                      height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"1px",textTransform: 'capitalize'
                      ,backgroundColor:"#fff",color:"grey"}}
                      className={activeStep === 0 ? "" : classes.backBtn}
                    >
                        <ArrowBackIcon  style={{marginLeft:"-3px",fontSize:"15px"}}/>
                      <span style={{marginLeft:"3px"}}>Back</span> 
                    </Button>: 
                    <Link to={{ pathname: '/asset'}}><button style={{textDecoration:"none",width:"65px",marginLeft:"475px",
             
                    
                    height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"1px",textTransform: 'capitalize'
                    ,backgroundColor:"#fff",color:"grey"}}>
                    
                       <ArrowBackIcon  style={{marginLeft:"-2px",fontSize:"15px"}}/>
                     <span style={{marginRight:"2px"}}>    Back</span>
                
                   </button></Link>
                      
                    }
                    </div>       
                    </Grid>
                    <Button
                      disabled={btnFlag}
                      type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      style={{
                   
                        width: (isLastStep ? '150px' : '65px'),
                        marginLeft:(isLastStep ? "76.5rem" : "71rem"),
                        color:'#fff',
                        backgroundColor: "#007AA6",
                        border: 0,
                        marginTop:"-35px",
                        height: '32px',
                        fontSize:"13px",
                        textTransform:'none'
                      }}
                      onClick={handleNext}
                    >
                      {isLastStep ? (
                        <>
                        <CheckCircleOutlineIcon style={{marginRight:"7px",fontSize:'18px',marginTop:"-1px"}}/>
                          {isUpdateMode ? "Update" : "Finish and Add"}
                        </>
                      )  : (
                        <>
                          Next
                          <ArrowForward style={{marginLeft:"6.5px",fontSize:'15px'}}/>
                        </>
                      )}
                    </Button> */}
                     <Grid item xs={7} style={{marginTop:"-8px"}}>
                  <div style={{ float: "right" }}>
                    {activeStep >= 1 ?
                    <Button
                      variant="outlined"
                      onClick={_handleBack}
                      disabled={activeStep === 0}
                      style={{  marginLeft:(isLastStep ? "445px": "-60px"), marginTop:"10px",
                      textDecoration:"none",width:"65px",
                    
                      
                    
                      height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",textTransform: 'capitalize'
                      ,backgroundColor:"#fff",color:"grey" }}
                      className={activeStep === 0 ? "" : classes.backBtn}
                    >
                     <ArrowBackIcon  style={{marginLeft:"-3px",fontSize:"15px"}}/>
                      Back
                    </Button>: 
                    <Link to={{ pathname: '/assetoverview'}}><button  style={{ marginLeft: "535px", marginTop:"10px",
                    textDecoration:"none",width:"65px",
                  
                    
                  
                    height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",textTransform: 'capitalize'
                    ,backgroundColor:"#fff",color:"grey" }}>
                       <span >
                       <ArrowBackIcon  style={{marginLeft:"-3px",fontSize:"15px"}}/>
                      </span>
                   <span style={{marginLeft:"4px"}}>Back</span> 
                   </button></Link>
                      
                    }

                    <Button
                      disabled={btnFlag}
                      type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      style={{marginRight:"20px",
                      textDecoration:"none",marginTop:"-32px",
                      marginLeft:(isLastStep ? "520px" : "610px"),
                         
                      backgroundColor:"#007AA6",
                      width: (isLastStep ? '160px' : '65px'),
                      
                    
                      height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",textTransform: 'capitalize'
                      ,color:"#fff" }}
                     
                      onClick={handleNext}
                    >
                       {isLastStep ? (
                        <>
                        <CheckCircleOutlineIcon style={{marginRight:"7px",fontSize:'18px',marginTop:"-1px"}}/>
                          {isUpdateMode ? "Update" : "Finish and Add"}
                        </>
                      )  : (
                        <>
                          Next
                          <ArrowForward style={{marginLeft:"6.5px",fontSize:'15px'}}/>
                        </>
                      )}
                    </Button>
                  </div>
                </Grid>
                </Grid>

                <Paper style={{border:"1px solid #fff",boxShadow:'none'}}>
              <Stepper
               style={{ borderRadius:"3px",height:"90px",marginLeft:"50px",marginRight:"50px"}}
            
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label} style={{marginTop:"-17px"}}>
                    <StepLabel >{label}</StepLabel>
                    
                  </Step>
                ))}
                
              </Stepper>
              </Paper>
            
            
              <Paper className="Paper" style={{boxShadow:"none",marginTop:"-7px"}}>
                {_renderStepContent(
                   assetFormHandler,
                   locationFormHandler,
                  activeStep,
                  setFieldValue,
                  values,
                  getrequestId,
                  item,
                  validate,
                  nextGroup   ,
                  addedAssetData,
                  locationState,      
                )}
              </Paper>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
    </Box>
    </Box>
  )
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)( AddHardwareAsset);