import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableSortLabel } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Row from "../../Utils/Row";
import "../../common/Alert/style.css";
import "react-toastify/dist/ReactToastify.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MarketPlaceAPI from "../../../../services/marketPlaceService";
import Helper, { ShippingRequestStatus } from "../../../../services/_helpers";
import { TableManageColumnsFormData } from "../../Dashboard/Common/Interfaces/custom";
import TablePaginationCustom from "../../Utils/TablePagination";
import ShipperHelper from "../../../../services/_helpers";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

type Order = "ASC" | "DESC";

const useStyles = makeStyles({
  table: {
  
    "@media (max-width:700px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      width: "50%",
    },
  },
  tableCell: {
    "@media (max-width:700px)": {
      paddingLeft: "20px",
      paddingRight: "20px",
      
    },
  },
  root: {
    borderBottom: "none"
  }
});

export default function DensoActive(props: any) {
  const classes = useStyles();
  const { searchReqId, manageColumnsOptions, filterOptions } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowData, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [orderDirection, setOrderDirection] = React.useState<Order>("DESC");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("timestamp");
  const [fetchTableFlag, setFetchTableFlag] = React.useState(
    JSON.parse(sessionStorage.fetchTableFlag)
  );
  const [loadFlag, setLoadFlag] = React.useState(true);
  const helper = new Helper();
  const [filterQuery, setFilterQuery] = useState(null);

  useEffect(() => {
    if (fetchTableFlag) {
      // setFetchTableFlag(false);
      setFetchTableFlag(sessionStorage.setItem("fetchTableFlag", "false"));
      fetchTableData();
    }
  }, [fetchTableFlag]);

  const onUpdateTable = () => {
    //console.log('called update');
    fetchTableData();
  }

  useEffect(() => {
    if (!searchReqId) {
      if (filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if (
            ShippingRequestStatus[opt.value] !==
              ShippingRequestStatus.Completed &&
            ShippingRequestStatus[opt.value] !== ShippingRequestStatus.Cancelled
          ) {
            tempFilter.push(opt.value);
          }
        });
        if (tempFilter.length > 0) {
          setFilterQuery({ status: tempFilter });
          fetchTableData(0, rowsPerPage, orderDirection, {
            status: tempFilter,
          });
        } else {
          // setFilterQuery(null);
          // fetchTableData(0, rowsPerPage, orderDirection, null, true);
          setRows([]);
          setLoadFlag(false);
          props.handleChangeKey("pastRequest");
        }
      } else {
        setFilterQuery(null);
        fetchTableData(0, rowsPerPage, orderDirection, null, true);
      }
    }
  }, [filterOptions, searchReqId]);

  useEffect(() => {
    if (searchReqId) {
      if (filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if (
            ShippingRequestStatus[opt.value] !==
              ShippingRequestStatus.Completed &&
            ShippingRequestStatus[opt.value] !== ShippingRequestStatus.Cancelled
          ) {
            tempFilter.push(opt.value);
          }
        });
        if (tempFilter.length > 0) {
          setFilterQuery({ status: tempFilter });
          fetchTableDataByReqId(0, rowsPerPage, orderDirection, {
            status: tempFilter,
          });
        } else {
          // setFilterQuery(null);
          // fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
          setRows([]);
          setLoadFlag(false);
          props.handleChangeKey("pastRequest");
        }
      } else {
        setFilterQuery(null);
        fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
        if (rowData.length === 0) {
          props.handleChangeKey("pastRequest");
        }
      }
      // fetchTableDataByReqId();
    }
  }, [searchReqId, filterOptions, rowData.length]);

  const fetchTableDataByReqId = (
    page: number = 0,
    size: number = 10,
    orderBy: Order = "DESC",
    query?: any,
    shouldForceDefaultQuery?: boolean
  ) => {
    const marketApi = new MarketPlaceAPI();
    const defaultQuery = {
      status: [
        helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
        helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
        helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
      ],
    };
    setPage(page);
    setRowsPerPage(size);
    marketApi
      .searchByRequestNumber(
        searchReqId,
        page,
        size,
        orderBy,
        query
          ? query
          : filterQuery && !shouldForceDefaultQuery
          ? filterQuery
          : defaultQuery
      )
      .then((resp: any) => {
        let result:any = [];
        resp.data.map((item:any) => {
          let obj = item;
          obj.cbFlag = false;
          result.push(obj);
        })
        setRows(result);
        setTotalRows(resp.total);
        setLoadFlag(false);
      })
      .catch((err: any) => {
        console.error("No requests found with that request number.", err);
        setRows([]);
        setTotalRows(0);
        setLoadFlag(false);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  };

  const fetchTableData = (
    page: number = 0,
    size: number = 10,
    orderBy: Order = "DESC",
    query?: any,
    shouldForceDefaultQuery?: boolean
  ) => {
    const marketApi = new MarketPlaceAPI();
    const defaultQuery = {
      status: [
        helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
        helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
        helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
      ],
    };
    setPage(page);
    setRowsPerPage(size);
    marketApi
      .getRequestsByQuery(
        page,
        size,
        orderBy,
        query
          ? query
          : filterQuery && !shouldForceDefaultQuery
          ? filterQuery
          : defaultQuery
      )
      .then((resp: any) => {
        let result:any = [];
        resp.data.map((item:any) => {
          let obj = item;
          obj.cbFlag = false;
          result.push(obj);
        })
        setRows(result);
        setTotalRows(resp.total);
        setLoadFlag(false);
      })
      .catch((err: any) => {
        console.error("Failded to load active Denso requests.", err);
        setRows([]);
        setTotalRows(0);
        setLoadFlag(false);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  };

  useEffect(() => {
    fetchTableData();
}, [props.setFetchTableFlag]);


  const handleChangePage = (event: unknown, newPage: number) => {
    fetchTableData(newPage, rowsPerPage, orderDirection);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    fetchTableData(0, +event.target.value);
  };

  const handleRequestSort = (event: any) => {
    let isAscending: boolean;
    if (valueToOrderBy === "timestamp" && orderDirection === "ASC") {
      isAscending = true;
    } else {
      isAscending = false;
    }
    setValueToOrderBy("timestamp");
    setOrderDirection(isAscending ? "DESC" : "ASC");
    fetchTableData(0, rowsPerPage, isAscending ? "DESC" : "ASC");
  };

  console.log(rowData)

  return (
    <Paper>
      <TableContainer component={Paper} style={{ boxShadow: "none"}}>
        <Table aria-label="collapsible table" className={classes.table}
       style={{width:"100%",marginLeft: "15px"}}
        >
          <TableHead 
         >
            <TableRow  >
              <TableCell />
             
              <TableCell
                style={{ fontSize: "12px", minWidth: "1px" ,marginRight:"-10px"}}
                align="center"
              >
                {/* <hr /> */}
              </TableCell>
              {manageColumnsOptions
                ?.sort(
                  (
                    a: TableManageColumnsFormData,
                    b: TableManageColumnsFormData
                  ) => {
                    return a.order - b.order;
                  }
                )
                .map((reorder: TableManageColumnsFormData) => {
                  if (reorder.defaultOrder === 8) {
                    return (
                      <TableCell
                        style={{ fontSize: "14px"}}
                        align="center"
                        key="timestamp"
                      >
                        <TableSortLabel
                          active={valueToOrderBy === "timestamp"}
                          data-testid="createdOnSort"
                          direction={
                            valueToOrderBy === "timestamp"
                              ? (orderDirection.toLocaleLowerCase() as
                                  | "asc"
                                  | "desc")
                              : "asc"
                          }
                          onClick={handleRequestSort}
                          IconComponent={ArrowDropDownIcon}
                          
                        >
                          {reorder.title}
                        </TableSortLabel>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        style={{ fontSize: "14px"}}
                        align={reorder.defaultOrder === 7 ? "right" : "left"}
                      >
                        {reorder.title}
                      </TableCell>
                    );
                  }
                })}
              <TableCell
                style={{ fontSize: "14px", minWidth: "5rem" }}
                align="left"
              >
                Action
              </TableCell>
              
            </TableRow>
          </TableHead>

          <TableBody>
            {loadFlag ? (
              ""
            ) : (
              <>
                {rowData.length > 0 ? (
                  rowData.map((row: any) => {
                    return (
                      <>
                        <Row 
                          key={row.id}
                          row={row}
                          showCheckBox
                          validateButton={(e:any, row1:any)=> props.validateRouteButton(e, row1, rowData)}
                          setFetchTableFlag={setFetchTableFlag}
                          manageColumnsOptions={manageColumnsOptions}
                          onUpdateTable={()=> onUpdateTable()}
                        />
                      </>
                    );
                  })
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 100,
                          paddingTop: 50,
                          margin: "5rem",
                        }}
                        className={classes.tableCell}
                        colSpan={9}
                      >
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontSize: "20px", fontWeight: 500 }}>
                            No trip requests available
                          </span>
                          <p style={{ fontSize: "16px", color: "#5A5A5A" }}>
                            Create one by clicking on "+CREATE NEW" button.
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        style={{ fontSize: "12px" }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{ variant: "outlined" }}
        labelRowsPerPage={"Rows per page"}
        labelDisplayedRows={({ from, to, count }) => ""}
        ActionsComponent={TablePaginationCustom}
      /> */}
    </Paper>
  );
}
