import  React from 'react';
import { Field, useField, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { at } from 'lodash';
import DateRangeIcon from "@material-ui/icons/DateRange";

export default function DatePickerField(props:any) {
 const [field, meta, helper] = useField(props);

  const { value } = field;
  const { setValue } = helper;

  const { setFieldValue} = useFormikContext();

  const onChange = React.useCallback((e: any) => {
    // props.handleChange(e.target.value);
    setFieldValue(field.name, e.target.value);
  }, [field.name, props.value, value, field.value]);
  
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  function _onChange(e:any){
       setValue(e.target.value)
      // coldchainLog("INISDE FORMIK",field.value,props)
  }
  //const [selectedDate, setSelectedDate] = useState(null);
  //coldchainLog("formik Date",field,props)
//   useEffect(() => {
//     if (value) {
//       const date:any = new Date(value);
//       setSelectedDate(date);
//     }
//   }, [value]);
// coldchainLog(selectedDate)
  // function _onChange(date:any) {
  //   if (date) {
  //     setSelectedDate(date);
  //     try {
  //       const ISODateString = date.ISODateString();
  //       const momentDate = moment(date).format("DD MMM YY")
  //        setValue(ISODateString);
  //       //setValue(momentDate);
  //     } catch (error) {
  //       setValue(date);
  //     }
  //   } else {
  //     setValue(date);
  //   }

  // }

  return (
    <Grid container>
      <Field
        component={TextField}
        name={field.name}
        onChange={onChange} 
       {...props}
        type="date"
        variant="outlined"
        value={field.value || props.value || value}
        error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
     
        //defaultValue="2017-05-24"
        //className={classes.textField}
        InputLabelProps={{
          shrink: true,
            
        }}
      />
      {/* <TextField
        variant="outlined"
        id="date"
        onChange={_onChange}
        {...field}
        //{...props}
       {...props}
      {...rest}
        type="date"
        value={props.value || value}
        error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
        //defaultValue="2017-05-24"
        //className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker 
          {...field}
          {...props}
          value={selectedDate}
          onChange={_onChange}
          style={{width:400}}
          //error={isError}
          //invalidDateMessage={isError && error}
          //helperText={isError && error}
          //className={classes.dateField}
          
        />
      </MuiPickersUtilsProvider> */}
    </Grid>
  );
}