import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Skeleton } from "@material-ui/lab";

export default function Slots(props: any) {

    const rowData = props.top5RowData
    const totalRequest = props.top5TotalRequest

    const Headingstyle: any = {
        textAlign: 'left',
        marginLeft: '1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
       
        fontSize: '1rem',
        color: "#000000DE"
    }
    const TableHeadLeftStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "0.7rem",
        fontWeight:"normal",
        color: "#000000DE",
       
    }
    const TableHeadRightStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "0.7rem",
        fontWeight:"normal",
        color: "#000000DE",
        textAlign: "right"
    }
    const TableRowLeftStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "1rem",
        fontWeight: 400,
        color: "#000000DE"
    }
    const TableRowRightStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "1rem",
        fontWeight: 400,
        color: "#000000DE",
        textAlign: "right"
    }

    return (
        <div style={{ overflow: 'auto', minHeight: "356px" }}>
            <div style={Headingstyle} data-testid='custom-element'>Slots</div> 
            {props.noData !== '' ? <div >{props.noData}</div> :
            <TableContainer>
                <Table role="simple table"  style={{width:"95%",marginLeft: "15px"}}>
                    <TableHead style={{ backgroundColor: '#eff2f6'}}>
                        <TableRow>
                        <TableCell style={TableHeadLeftStyle} align="left">
                            <span style={{marginLeft:"35px"}}>#</span></TableCell>
                            <TableCell style={TableHeadLeftStyle} align="left">
                            <p style={{display:"table-header-group"}}>
                                                <span style={{marginLeft:"80px"}}>Shipper</span></p> </TableCell>
                            <TableCell style={TableHeadRightStyle} align="left">
                            <span style={{marginLeft:"-120px"}}>Slots</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ fontSize: "20px" }}>
                        {
                            rowData.length !== 0 ? rowData.map((data: any, index: any) => {
                                return (
                                    // <TableRow
                                    //     key={data.index} data-testid='row' className={"brBottom"} 
                                    // >
                                        <TableRow
                                        key={data.index} data-testid='row' 
                                    >
                                         <TableCell style={TableRowLeftStyle} align="justify">
                                            <span style={{marginLeft:"35px"}}>{index+1}</span></TableCell>
                                        <TableCell style={TableRowLeftStyle}>
                                            <p style={{display:"table-header-group"}}>
                                                <span style={{marginLeft:"80px"}}>{data.name}</span></p></TableCell>
                                        <TableCell style={TableRowRightStyle} align="justify">
                                            <span style={{marginLeft:"-120px"}}>{data.value}</span></TableCell>
                                    </TableRow>
                                )
                            }) :
                                [0, 1, 2, 3, 4].map(() => {
                                    return (
                                        <TableRow>
                                            <TableCell style={TableRowLeftStyle} align="justify"><Skeleton variant="text" /></TableCell>
                                            <TableCell style={TableRowRightStyle} align="justify"><Skeleton variant="text" /></TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}