import React, { Fragment,useState } from "react";
import { Grid,Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { InputField } from "../../../common/FormFields";




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export default function HardwareDetails(props: any) {

  const [hardwareState,setHardwareState] = useState({tagId:"",gatewayId:"",HardwareKitName:""});

  const classes = useStyles();

  const changeHandler = (e:any) => {
    const {name, value} = e.target;
    setHardwareState(prevState => ({
      ...prevState, [name]:value
    }))
    props.formData(name, value);
  }
  
  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement" style={{marginTop:"20px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p style={{ fontSize: "16px",marginTop:"-10px",color:"#000"}} >
                    Hardware Information
                  </p>
                  <br />
                  {/* <InputField
                    name="HardwarKitName"
                    label="Hardware Kit Name"
                    fullWidth
                    onChange={changeHandler}
                  // disabled={props.values.assetData ? false : true}
                  /> */}
                </Grid>
                <Grid item xs={12} style={{marginTop:"-14px"}}>
                  <InputField
                    name="HardwareKitName"
                    label="Hardware Kit Name"
                    fullWidth
                    onChange={changeHandler}
              
                  // disabled={props.values.assetData ? false : true}
                  />
                </Grid>
                <Grid item xs={12} style={{marginTop:"15px"}}>
                  <InputField
                    name="tagId"
                    label="tagId"
                    fullWidth
                    onChange={changeHandler}
                    // value={props.location[1]}
                  // disabled={props.values.assetData ? false : true}
                  />
                </Grid>
              </Grid></Grid>
            <Grid item xs={12} lg={6} md={6} style={{ marginTop: "50px" }}>
              <Grid item xs={12}>
                <InputField
                  name="gatewayId"
                  label="gatewayId"
                  fullWidth
                  onChange={changeHandler}
                  // value={props.location[2]}
                  // value={props.location[2]}
                // disabled={props.values.assetData ? false : true}
                />
              </Grid>
            </Grid>
            <Button variant="outlined" style={{backgroundColor: "#0078A5", color: "#FFFFFF", opacity: 1, 
                width:"165px",borderRadius: "4px",marginTop:"17px", border: "1px soid #0078A5" ,textTransform:"none",
                marginLeft:"9px",fontSize:"14px",height:"39px"}}>
                  <AddIcon style={{marginLeft:"-4px",marginRight:"3px",width:"20px"}}/>
                  <span style={{marginLeft:"3.4px"}}>Add New Field</span>
                </Button>
          </Grid>
          {/* <Button className="btn" variant="outlined" style={{marginTop:"3rem",backgroundColor:"#0078A5", color:"#fff",borderRadius:"5px"}}>Add New Field</Button> */}
        </div>
      </div>
    </Fragment>
  );
}