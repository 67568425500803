import React, { Fragment, useState } from "react";
import { Stepper, Step, StepLabel, Button, Grid } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import LocationForm from "./Forms/LocationForm";
import AsetForm from "./Forms/AsetForm";
import HardwareForm from "./Forms/HardwareForm";
import initialForm from "./initialForm";
import axios from "axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useEffect } from "react";
import { toast } from "react-toastify";
import InfoIcon from "@material-ui/icons/Info";
import "../../../../web/components/common/Alert/style.css";
import { IconButton } from "@material-ui/core";
import assetForm from "./FormAsset/assetForm";
import validationSchema from "./Forms/validationSchema";
import { assetBaseURL } from "../../../../services/_url";
import moment from "moment";
import AssetBaseAPI from "../../../../services/assetService";
import { data } from "../../../../test/Denso/data";
import UserService from '../../../../services/userService';
import { connect } from 'react-redux';
import ShipperHelper, {
  ShippingRequestStatus,
} from "../../../../services/_helpers";
import { result } from "lodash";
import ShipperAPI from "../../../../services/shippingLanding";


const steps = ["Basic Details", "Location Details", "Hardware Details"];

const { formId, formField } = assetForm;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginRight: "48px",
    },
    backBtn: {
      color: "#1A0DAB",
    },
  })
);
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    color: "#5A5A5A",
  },
  active: {
    "& $line": {
      borderColor: "#159A40",
      backgroundColor: "159A40",
    },
    color: "#5A5A5A",
  },

  completed: {
    "& $line": {
      borderColor: "#159A40",
    },
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function _renderStepContent(
  step: number,
  setFieldValue?: any,
  values?: any,
  getrequestNumber?: any
) {
  switch (step) {
    case 0:
      return (<AsetForm formField={formField}/>);
    case 1:
      return (
        <LocationForm formField={formField} setFieldValue={setFieldValue}  getrequestNumber={getrequestNumber}/>
      );
    case 2:
      return (
        <HardwareForm
          formField={formField}
          setFieldValue={setFieldValue}
          values={values}
         
        />
      );
   
    default:
      return <div>Values Successfully Added</div>;
  }
}
export interface IAssetProps {
  user: any
}

function CreateAsset(props:IAssetProps,prop:any) {
  const { activeStepProp } = prop;
  const id: any = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [assetIdData, SetAssetIdData] = useState([]);
  const [tenant, SetTenant] = useState([]);
  const [assId,SetAssId] = useState([]);
  const [activeStep, setActiveStep] = useState(
    activeStepProp ? activeStepProp : 0
  );
  const currentValidationSchema = validationSchema[activeStep];

  const isLastStep = activeStep === steps.length - 1;
  const [btnFlag, setBtnFlag] = useState(false);
  
  const [getrequestNumber, setgetrequestNumber] = useState("");
   const assetApi = new AssetBaseAPI();
   const user=new  UserService();
   const ship=new ShipperAPI();
    //intial values
 var initialValues={
  tenantId:"",
  pickupStartDate: "",
    pickupEndDate: "",
 }
  // create shipment
  const success = () => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Successfully created Asset request</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };
  // update shipment
  const CustomSuccessPatch = () => {
    return (
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Successfully updated Asset request.</span>
        </div>
      </div>
    );
  };
  const fetchTenant = async() => {
    assetApi.getAssetID().then((resu) => {
      SetAssetIdData(resu);
      ship.getUserId()
        .then((res:any)=>{
            
            // console.log(res);
            // console.log(props.user);
            let result = res.filter((data: any)=>{return data.email === props.user.email});
            // console.log(result);
            SetTenant(result);
            // console.log(tenant);
            // console.log(tenant[0]?.tenantId);
      })
    })
  }
  useEffect(()=>{
      fetchTenant();
  },[]);
    
     
    let tennantIdData:any=[];
    const getAssetId: any = (name: any) =>
    assetIdData.filter((val: any) => val.name === name);
    // const getTenantId: any = (tenantId: any) =>
    // tenant.filter((val: any) => val.tenantId === tenantId);
    let userResult: any = tenant.map((val: any) => {
     
      let test = { value: "" }
     
      test.value = val.tenantId[0];
      tennantIdData = [...tennantIdData, test];
    
  });
  function _submitForm(values: any, actions: any) {
   let Ship = new ShipperHelper();
    setBtnFlag(true);
    var pickFrom = "2022-07-18T11:33:06.056+0000";
    var pickTo = "2022-08-18T11:33:06.056+0000";
  //  console.log()
  //   console.log(values);
    
    
    let postData = {
      "assetType": {
        "description": values.assettype,
        "name": values.assetname,
         "id":"",
       },
      "number": "8090876",
      "locality": values.locality,
      "city": values.city,
      "zone": values.zone,
      "region":values.region,

      }
      let AsetID = getAssetId(values.assettype)[0]?.id;
      //update transporter id for postData
      postData.assetType.id= AsetID;
     
      //update transporter id for postData
     
      const headers = {
        'Authorization': `Bearer ${props.user.token}`,
        'X-Frame-Options':'DENY'
      }
    var postDetails={
      "asset":  {
        "id":"",
         "assetType":  {
          "id":"",
          "name": values.assetname,
          "description": values.assettype,
           "number":"9995566",
     },
  
    },
     "tenantId": tenant[0]?.tenantId,
     "startDate":  moment().add(1, 'days').format("YYYY-MM-DD")+"T11:33:06.056+0000",
     "endDate": moment().add(3, 'days').format("YYYY-MM-DD")+"T11:33:06.056+0000",
    }
    postDetails.asset.assetType.id=AsetID;
   // postDetails.tenantId=tennantIdData;
    // assetApi
    // .getAssetID()
    // .then((res:any) =>{
    //   postData.assetType.id=res.id;
    //   postDetails.asset.assetType.id=res.id;

    // console.log(props.user);
    // console.log(postData);
    // console.log(postDetails);
    var sensorData={
      "tagId": values.hid,
      "gatewayId": values.gid,
      "description": values.hname,
      "asset": {
        "id": "",
        "assetType": {
            "id": "",
            "name": values.assetname,
            "description": values.assettype,
        "number": "998996655",
        "locality": values.locality,
        "zone": values.zone,
        "city": values.city,
        "region": values.region,
        "underMaintenance": values.status,
    }

  }
}
// console.log(props);
sensorData.asset.assetType.id=AsetID;
//sensorData.asset.id= AseID;
    assetApi
     .postAsset(postData)
     .then((res:any)=>
     {
      sensorData.asset.id=res.id;
     postDetails.asset.id=res.id;
      // console.log(res.id);
       SetAssId(res.id);
       
      //  console.log("success");
       if(res.id){
        assetApi.postReq(postDetails).then((resp) => {
          // console.log("success1");
          
        });
         assetApi.sensorReq(sensorData).then((resp) => {
        //  console.log("success2")

        history.push("/");
        success();
       });
       
     }
    }
    )
  
      .catch((err:any)=>
      {
        console.error(err);
      });
      
     
  // console.log(sensorData);
  assetApi
  .sensorReq(sensorData)
  .then((res:any)=>
  {
    // console.log("success2");
  })
  .catch((err:any)=>
  {
    console.error(err);
  });
      
}
    
  
 function _handleSubmit(values: any, actions: any) {
    if (activeStep >= 2) {
      actions.setSubmitting(true);
     _submitForm(values, actions);
    } else if (activeStep === 0) {
         setActiveStep(activeStep + 1);
        //  console.log(activeStep);
        actions.setSubmitting(false);
        actions.setTouched({});
        
    }
     
    else  {
      // console.log(activeStep);
      setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
      actions.setTouched({});
    }
    
    
    // console.log(values)
 }
   function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Formik
          initialValues={initialForm}
          enableReinitialize={true}
          data-testid="assetFormikInitialisation"
             validationSchema={
          currentValidationSchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue }) => (
            <Form id={formId}>
              <Grid container spacing={3}>
                <Grid item xs={5} style={{ position: "static" }}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    data-testid="backToDenso"
                    onClick={() => {
                      window.history.back();
                    }}
                    style={{ color: "#5A5A5A", cursor: "pointer" }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <span style={{ fontSize: "24px", fontWeight: 300 }}>
                   Attach New Asset
                  </span>
                </Grid>
                <Grid item xs={7}>
                  <div style={{ float: "right" }}>
                    <Button
                      variant="outlined"
                      onClick={_handleBack}
                      disabled={activeStep === 0}
                      style={{ marginLeft: "1rem", border: "#828282" }}
                      className={activeStep === 0 ? "" : classes.backBtn}
                    >
                      <span>
                        <NavigateBeforeIcon />
                      </span>
                      Back
                    </Button>

                    <Button
                      disabled={btnFlag}
                      type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      id="next"
                      name="next"
                      style={{
                        marginLeft: "2rem",
                        border: "2px solid #1A0DAB",
                        color: "#1A0DAB",
                      }}
                    >
                      {isLastStep ? (
                        <>
                          <DoneIcon />
                          {"Finish and Add"}
                        </>
                      ) : (
                        <>
                          Next
                          <NavigateNextIcon />
                        </>
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <hr />

              <Stepper
                style={{ color: "#5A5A5A" }}
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {_renderStepContent(
                activeStep,
                setFieldValue,
                values,
                getrequestNumber
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
}
function mapStateToProps(state: any, ownProps: {}) {
  // console.log(state);
  return {
      user: state.user.currentUser
    
  };
}export default connect(mapStateToProps, null)(CreateAsset);