import React from 'react';
import Plot from 'react-plotly.js';
import { useState, useEffect } from 'react';


const Shipmentstrend = (props:any) => {

    const Headingstyle: any = {
        textAlign:'left',
        marginLeft:'1.5rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight:"normal",
        fontSize: '1rem',
        color: "#000000DE"
    }


    const [total, setTotal]: any = useState({
        x: [],
        y: [],
        type: 'bar',
        name: 'Total',
        marker: {
            color: '#0E6BA6'
        }
    })

    const [served, setServed]: any = useState({
        x: [],
        y: [],
        type: 'bar',
        name: 'Served',
        marker: {
            color: '#1692BD'
        }
    })

    const [dropped, setDropped]: any = useState({
        x: [],
        y: [],
        type: 'bar',
        name: 'Dropped',
        marker: {
            color: '#5BC2E7'
        }
    })
    const trace1:any = {
        x: props.xaxis,
        y: props.total,
      
        name: 'Total',
        type: 'bar',
        marker: {
            color: '#0D2A37',
            width: 0.5
        }
      };
      
      const trace2:any = {
        x: props.xaxis,
        y: props.served,
       
        name: 'Served',
        type: 'bar',
        marker: {
            color: '#76C2E2',
         
        },
      
      };
      const trace3:any = {
        x: props.xaxis,   
        y: props.dropped,
      
        name: 'Dropped',
       
        type: 'bar',
        
        marker: {
            color: '#BFD6EA',
            width: 1,
        }
      };
    useEffect(() => {
      
        let totaly: any = []
        let servedy: any = []
        let droppedy: any = []


      Object.keys(props.data).forEach(function(key) {

        //coldchainLog(key, props.data[key].served);
        servedy = [...servedy, props.data[key].served]
        totaly = [...totaly, props.data[key].total]
        droppedy = [...droppedy, props.data[key].dropped]
      
      });
      

                setTotal({ ...total, x: props.xaxis, y: totaly })
                setServed({ ...served, x: props.xaxis, y: servedy })
                setDropped({ ...dropped, x: props.xaxis, y: droppedy })
       
    }, [])
    
    return (
        <div >
            <div style={Headingstyle} >
                {/* {props.title || 'Shipments Trend'}</div> */}
                CPU Usage / Limit Per Image</div>
            
            {props.noDataShipmentTrends !== '' ? <div style={{minHeight:'330px'}}>{props.noDataShipmentTrends}</div> :
            
            <Plot
                data={[
                    trace1,trace2,trace3
                    // total,served,dropped
                ]}
                layout={{
                    margin: { l: 38, r: 30, b: 40, t: 10 },
                    xaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 14,
                        },
                        tick0: 0,
                        rangemode: 'tozero',
                      
                    },
                    yaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 14,
                        },
                        tick0: 0,
                        rangemode: 'nonnegative',
                        zerolinewidth: 1,
                    },
                    barmode: 'group',
                    autosize: false,
                    width:794,
                    height: 403,
                    
                    legend: {
                        x: 0.35,
                        y: -0.4,
                        orientation: 'h'
                    },
                  
                   

                }}
               style={{marginTop:"60px"}}
                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
            />}
        </div>
    )
}

export default Shipmentstrend