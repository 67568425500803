import axios from 'axios';
import {marketBaseURL} from './_url';
export default class TransporterAPI{

getRequestByRequestNumber = (id:string) => {
  return axios.get(`${marketBaseURL}/marketplace/shippingRequests/reqId/${id}`)
    .then((response: any) => response.data);
}
// getRequestByID=(id:string) =>{
//   return axios.get(`${marketBaseURL}/marketplace/transporter/shippingRequests/${id}`)
//     .then((response: any) => response.data);
// }
// getTrips=() =>{
//   return axios.get(`${marketBaseURL}/marketplace/trips`)
//     .then(res => {
//       return res.data;
       
//     });

//}
getByQuery = (query: any) => {
  query.page = query?.page ? query.page : 0;
  query.rowsPerPage = query?.rowsPerPage ? query.rowsPerPage : 10;
  return axios.get(`${marketBaseURL}/marketplace/shippingRequests/?page=${query.page}&size=${query.rowsPerPage}`)
    .then((response: any) => response);
}
// paginated_fetch= (page:any=0,previousResponse:any =[]) :any=> {
//   let response=[...previousResponse];
//   return axios.post(`${marketBaseURL}/marketplace/shippingRequests/?page=${page}`) // Append the page number to the base URL
    
//     .then((newResponse:any) => {
    
//        response = [...previousResponse,...newResponse.data.data]; // Combine the two arrays
 
//       if (newResponse.data.length === 20) {
//         page++;
 
//          return   this.paginated_fetch(page, response);
//       }
//       return response;
      
     
//     });
   
   
//  }
 postRequest=(postData:any) =>{
  return axios.post(`${marketBaseURL}/marketplace/shippingRequest`,postData)
    .then(res => {
      return res.data
       
    });
  }
  createTrip=(data:any) =>{
    return axios.post(`${marketBaseURL}/marketplace/trips`,data)
        .then(res => {
          return res.data
           
        });
    
    }


}
// import axios from 'axios';


// export function async resolve(promise) {
//   const resolved = {
//     data: null,
//     error: null
//   };

//   try {
//     resolved.data = await promise;
//   } catch(e) {
//     resolved.error = e;
//   }

//   return resolved;
// }
// export async function login(user, pass) {
//   return await resolve(axios.post('http://some-api.com/auth', { user, pass }).then(res => res.data));
// }

// export async function getRequest() {
//   return await resolve(axios.get('http://localhost:3005/data' ).then(res => res.data));
// }




// import axios from 'axios';
// function api(cb){
//   return fetch('http://localhost:3005/data' )
//       .then(res => {
//         return res.json();
         
//       }).then(cb);
// }
// const ShipperAPI={api}
// export default ShipperAPI;