import { createStyles, Dialog, DialogContent, DialogProps, DialogTitle, Grid, makeStyles, Theme} from '@material-ui/core';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Graph } from './graph';
import { useEffect } from 'react';
import TelemeteryAPI from '../../../../services/telemeteryService';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@mui/material/Paper';
import './style.css';
import ShipperHelper from '../../../../services/_helpers';
import axios from 'axios';
import moment from 'moment';

export interface ISensoreDataProps {
  tripId: any;
  assetId?: any;
  dataId?: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    // table: {
    //   minWidth: 750,
    // },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    imageBox: {

      marginTop: '1rem'

    }

  }),
);
export function SensoreData(props: ISensoreDataProps) {
  const tripId = props.tripId
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');
  const [dialogPopup, setDialogPopup] = React.useState('');

  //temperature
  const [temperatureSencorDataX, settemperatureSencorDataX] = React.useState([])
  const [temperatureSencorDataY, settemperatureSencorDataY] = React.useState([])

  //Light
  const [lightSencorDataX, setlightSencorDataX] = React.useState([])
  const [lightSencorDataY, setlightSencorDataY] = React.useState([])

  //Humidity
  const [humiditySencorDataX, sethumiditySencorDataX] = React.useState([])
  const [humiditySencorDataY, sethumiditySencorDataY] = React.useState([])

  //Shock
  const [shockSencorDataX, setshockSencorDataX] = React.useState([])
  const [shockSencorDataY, setshockSencorDataY] = React.useState([])

  //Pressure
  const [pressureSencorDataX, setpressureSencorDataX] = React.useState([])
  const [pressureSencorDataY, setpressureSencorDataY] = React.useState([])

  const [xValues, setxValues] = React.useState([])
  const [yValues, setyValues] = React.useState([])
  const [xlabels] = React.useState(true)
  const [ylabels] = React.useState(true);

  const [mode,setMode]= React.useState("");
  const [yTitle,setYTitle] = React.useState("");
  const [legendName,setLegendName]= React.useState("");

  let dateHelper =new ShipperHelper();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClickOpen = (title: string, xVal: any, yVal: any, mode:any,yaxisTitle:string,xaxisTitle:string,legendName:string) => {
    setOpen(true);
    setDialogPopup(title)
    setxValues(xVal)
    setyValues(yVal)
    setMode(mode);
    setYTitle(yaxisTitle);
    setLegendName(legendName);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    (async () =>{
      if (tripId !== "") {

        //Temperature
        let temperatureX: any = []
        let temperatureY: any = []
              //Light
              let LightX: any = []
              let LightY: any = []
                    //Shock
        let shockX: any = []
        let shockY: any = []
        if(props.assetId){
          let fromDate: any = moment().subtract(1, 'days');
          let toDate: any = moment();
          let parsedFromDate = moment(fromDate).utc();
          fromDate = parsedFromDate.format("YYYYMMDDHHmm")
          let parsedToDate = moment(toDate).utc()
          toDate =  parsedToDate.format("YYYYMMDDHHmm")
          if(props.dataId){
            await axios.get(`${process.env.REACT_APP_API_MARKET}/businessObjects/shipments/shippingReq/${props.dataId}`).then(response => {
              if(response?.data && response?.data?.actualPickUpTime && response?.data?.actualDeliveryTime){
                fromDate = moment.utc(response.data.actualPickUpTime).format("YYYYMMDDHHmm");
                toDate = moment.utc(response.data.actualDeliveryTime).format("YYYYMMDDHHmm");
              } else if(response?.data && response?.data?.actualPickUpTime && !response?.data?.actualDeliveryTime) {
                fromDate = moment.utc(response.data.actualPickUpTime).format("YYYYMMDDHHmm");
                toDate = moment();
                parsedToDate = moment(toDate).utc();
                toDate =  parsedToDate.format("YYYYMMDDHHmm");
              }
            })
            .catch(error => {
              // Handle the error
              console.error("Error fetching data:", error);
              // You can also perform additional actions based on the error, such as showing a user-friendly message
            });
          }
          axios.get(`${process.env.REACT_APP_API_TELEMETRY}/telemetry/gatewayanddevice?from=${fromDate}&to=${toDate}&assetId=${props.assetId}&tripId=${tripId}`)
          .then(res => {
            if(res.data && res.data.length){
              res.data.sort((a: any, b: any) => a.ts - b.ts).forEach((ele: any) => {
                if(+ele.temp > 0){
                  temperatureX.push(dateHelper.parseDateToLocal(ele.ts, false)) 
                  temperatureY.push(ele.temp)
                }
                if(+ele.lo >= 1){
                  LightX.push(dateHelper.parseDateToLocal(ele.ts, false))
                  LightY.push(1)
                }
                if(ele.se){
                  shockX.push(dateHelper.parseDateToLocal(ele.ts, false))
                  shockY.push(ele.se)
                }
              })
              settemperatureSencorDataX(temperatureX)
            settemperatureSencorDataY(temperatureY)
            setshockSencorDataX(shockX)
            setshockSencorDataY(shockY)
            setlightSencorDataX(LightX)
            setlightSencorDataY(LightY)
            }
          })
          .catch((err:any)=>{
            const UserToken = new ShipperHelper()
            UserToken.UserTokenExpiration()
          })
        }
        // telemeteryApi.getSensorDataTemperature(tripId)
        //   .then((resp: any) => {
        //     resp.map((values: any) => {
        //         return temperatureSorting = [...temperatureSorting,{ "date":values.timestamp, "data": values.temperature }]
        //     })
        //     // temperatureSorting.sort((a: any, b: any) => a.date.toLowerCase().localeCompare(b.date.toLowerCase()))
        //     temperatureSorting.map((result: any) => (
        //       temperatureX = [...temperatureX, dateHelper.dateConversion(result.date)],
        //       temperatureY = [...temperatureY, result.data]
        //     ))
        //     settemperatureSencorDataX(temperatureX)
        //     settemperatureSencorDataY(temperatureY)
        //   })
        //   .catch((err: any) => {
        //     console.error(err)
        //   });
  
  
        // telemeteryApi.getSensorDataLight(tripId)
        //   .then((resp: any) => {         
        //     resp.map((values: any) => (
        //         LightSorting = [...LightSorting, { "date":values.timestamp, "data":  values.lidOpen }]            
        //     ))
        //     // LightSorting.sort((a: any, b: any) => a.date.toLowerCase().localeCompare(b.date.toLowerCase()))
        //     LightSorting.map((result: any) => (
        //       LightX = [...LightX, dateHelper.dateConversion(result.date)],
        //       LightY = [...LightY, result.data]
        //     ))
        //     setlightSencorDataX(LightX)
        //     setlightSencorDataY(LightY)
        //   })
        //   .catch((err: any) => {
        //     console.error(err)
        //   });
  
        //Humidity
        let HumidityX: any = []
        let HumidityY: any = []
        let HumiditySort: any = []
        // telemeteryApi.getSensorDataHumidity(tripId)
        //   .then((resp: any) => {
        //       resp.map((values: any) => (
        //         HumiditySort = [...HumiditySort, {"date":values.timestamp, "data":  values.humidity }]
        //     ))
        //     // HumiditySort.sort((a: any, b: any) => a.date.toLowerCase().localeCompare(b.date.toLowerCase()))
        //     HumiditySort.map((result: any) => (
        //       HumidityX = [...HumidityX, dateHelper.dateConversion(result.date)],
        //       HumidityY = [...HumidityY, result.data]
        //     ))
        //     sethumiditySencorDataX(HumidityX)
        //     sethumiditySencorDataY(HumidityY)
        //   })
        //   .catch((err: any) => {
        //     console.error(err)
        //   });
  
  
        // telemeteryApi.getSensorDataShock(tripId)
        //   .then((resp: any) => {
        //       resp.map((values: any) => (
        //         shockSorting = [...shockSorting, { "date":values.timestamp, "data": values.shock }]
        //     ))
        //     // shockSorting.sort((a: any, b: any) => a.date.toLowerCase().localeCompare(b.date.toLowerCase()))
        //     shockSorting.map((result: any) => (
             
        //       shockX = [...shockX, dateHelper.dateConversion(result.date)],
        //       shockY = [...shockY, result.data]
        //     ))
        //     setshockSencorDataX(shockX)
        //     setshockSencorDataY(shockY)
        //   })
        //   .catch((err: any) => {
        //     console.error(err)
        //   });
  
        //Pressure
        let pressureX: any = []
        let pressureY: any = []
        let pressureSorting: any = []
        // telemeteryApi.getSensorDataPressure(tripId)
        //   .then((resp: any) => {
        //       resp.map((values: any) => (
        //         pressureSorting = [...pressureSorting, { "date":values.timestamp, "data": values.pressure }]
        //     ))
        //     // pressureSorting.sort((a: any, b: any) => a.date.toLowerCase().localeCompare(b.date.toLowerCase()))
        //     pressureSorting.map((result: any) => (
        //       pressureX = [...pressureX, dateHelper.dateConversion(result.date)],
        //       pressureY = [...pressureY, result.data]
        //     ))
        //     setpressureSencorDataX(pressureX)
        //     setpressureSencorDataY(pressureY)
        //   })
        //   .catch((err: any) => {
        //     console.error(err)
        //   });
      }
    })()
  
  }, [tripId])

  return (
    <div>
      <Grid container spacing={3} className={classes.imageBox}>
        <Grid item xs={12} style={{marginRight:".8%"}}>
        <Paper variant="outlined" square style={{border:"1px solid #fff",marginLeft:"7px",marginTop:"-5px"}} >
          <Row style={{ fontSize: '14px'}}>

            <Col style={{ marginTop:'15px' }} data-testid='graphPopup'onClick={() => handleClickOpen("Temperature", temperatureSencorDataX, temperatureSencorDataY,"lines+markers","Celsius","Date" ,"Degree")}>

              <p style={{ fontSize:'14px' }}>
                <span style={{marginLeft:"40px",color:"#000"}}>Temperature</span></p>
              <Graph title={""} width={"250"} height={"200"} dataX={temperatureSencorDataX} dataY={temperatureSencorDataY} xlabels={xlabels} ylabels={ylabels} mode="lines+markers" yaxisTitle={""} xaxisTitle={""} titleName={"Temperature"} showlegend={false} legendName={"Degree"}/>

            </Col>
            <Col style={{ marginTop:'15px',marginLeft:"-40px" }}  data-testid='graphPopup1' onClick={() => handleClickOpen("Lid Open", lightSencorDataX, lightSencorDataY,"markers","times","Date","Events")}>

              <p style={{ fontSize:'14px'  }}>
              <span style={{marginLeft:"47px",color:"#000"}}>Lid Open</span></p>
              <Graph title={""} width={"250"} height={"200"} dataX={lightSencorDataX} dataY={lightSencorDataY} xlabels={xlabels} ylabels={ylabels}  mode="markers" yaxisTitle={""} xaxisTitle={""} titleName={"Lid Open"} showlegend={false} legendName={"Events"}/>

            </Col>
            {/* <Col style={{ marginTop:'15px',marginLeft:"-40px" }} data-testid='graphPopup2' onClick={() => handleClickOpen("Humidity", humiditySencorDataX, humiditySencorDataY,"lines+markers","gm/g\xB3","Date","gm/g\xB3")}>

              <p style={{ fontSize:'14px'  }}>
              <span style={{marginLeft:"40px",color:"#000"}}>Humidity</span></p>
              <Graph title={""} width={"250"} height={"200"} dataX={humiditySencorDataX} dataY={humiditySencorDataY} xlabels={xlabels} ylabels={ylabels} mode="lines+markers" yaxisTitle={""} xaxisTitle={""} titleName={"Humidity"} showlegend={false} legendName={"gm/m3"}/>

            </Col> */}
            <Col style={{ marginTop:'15px',marginLeft:"-40px" }} data-testid='graphPopup3' onClick={() => handleClickOpen("Shock", shockSencorDataX, shockSencorDataY,"markers","times","Date","Events")}>

              <p style={{ fontSize:'14px'  }}>
              <span style={{marginLeft:"40px",color:"#000"}}>Shock</span></p>
              <Graph title={""} width={"250"} height={"200"} dataX={shockSencorDataX} dataY={shockSencorDataY} xlabels={xlabels} ylabels={ylabels} mode="markers" yaxisTitle={""} xaxisTitle={""} titleName={"Shock"} showlegend={false} legendName={"Events"}/>

            </Col>
            {/* <Col style={{ marginTop:'15px',marginLeft:"-40px" }} data-testid='graphPopup4' onClick={() => handleClickOpen("Pressure", pressureSencorDataX, pressureSencorDataY,"lines+markers","pa","Date","pa")}>

              <p style={{ fontSize:'14px'  }}>
              <span style={{marginLeft:"40px",color:"#000"}}>Pressure</span></p>
              <Graph title={""} width={"250"} height={"200"} dataX={pressureSencorDataX} dataY={pressureSencorDataY} xlabels={xlabels} ylabels={ylabels} mode="lines+markers" yaxisTitle={""} xaxisTitle={""} titleName={"Pressure"} showlegend={false} legendName={"pa"}/>

            </Col> */}
          </Row>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        aria-labelledby="customized-dialog-title"
        id="sensorGraph"
      >
        <DialogTitle id="sensorGraph">

          <span style={{ float: 'right' }}><IconButton data-testid="cancel-button" onClick={handleClose}><CloseIcon /></IconButton></span>
        </DialogTitle >

        <DialogContent dividers>
          <Graph title={dialogPopup} width={"800"} height={"400"} dataX={xValues} dataY={yValues} xlabels={xlabels} ylabels={ylabels} mode={mode} yaxisTitle={yTitle} xaxisTitle={"Dates"} titleName={dialogPopup} showlegend={true} legendName={legendName}/>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}
