import axios from 'axios';
import { marketBaseURL } from './_url';

export default class MarketPlaceAPI {
 getRequestsByQuery = (page:number = 0, size:number = 5, orderBy: 'ASC'|'DESC' = 'DESC', query?: any) : any => {
    return axios.post(`${marketBaseURL}/marketplace/shippingRequests/?page=${page}&size=${size}&orderBy=${orderBy}`, query) // Append the page number to the base URL
      .then((response:any) => {
          return response.data;
    }).catch((err: any) => {
      console.error('Failded to load active transporter requests.', err.response );
      // setLoadFlag(false);
    });
 }
 getRequestsByRequestNumber = (reqNum: number): any => {
   ///marketplace/shippingRequests/searchByreqId/11
    return axios.get(`${marketBaseURL}/marketplace/shippingRequests/reqId/${reqNum}`)
      .then((response: any) => response.data);
 }

 getPendingTransferRequests = (): any => {
  return axios
    .get(`${marketBaseURL}/driver/trip/transfer/v2/pending`)
    .then((response: any) => response.data);
};

approvePendingRequest = (transferRequestPayload: any): any => {
  return axios
    .put(
      `${marketBaseURL}/driver/trip/transfer/status/v2`,
      transferRequestPayload
    )
    .then((response: any) => response.data);
};


 searchByRequestNumber = (reqNum: number, page:number = 0, size:number = 5, orderBy: 'ASC'|'DESC' = 'DESC', query?: any): any => {
    return axios.post(`${marketBaseURL}/marketplace/shippingRequests/searchByreqId/${reqNum}/?page=${page}&size=${size}&orderBy=${orderBy}`, query)
      .then((response: any) => response.data);
 }
 cancelRequest=(data:any):any =>{
  return axios.patch(`${marketBaseURL}/marketplace/shippingRequests/${data['id']}`, data)
  .then((response: any) => response.data);

 }
 bellNotificationShipper = (data:any) => {
  return axios.get(`${marketBaseURL}/alerts/shipper/${data}`)
      .then((response: any) => response.data);
 }
 bellNotificationTranspoter = (data:any) => {
  return axios.get(`${marketBaseURL}/alerts/transporter/${data}`)
      .then((response: any) => response.data);
 }
 bellNotificationDenso = () => {
  return axios.get(`${marketBaseURL}/alerts`)
      .then((response: any) => response.data);
 }
 bellNotificationUpdate = (data:any) => {
  return axios.put(`${marketBaseURL}/alerts`, data)
      .then((response: any) => response.data);
 }
 getTrip = (id:any)=>{
  return axios.get(`${marketBaseURL}/marketplace/trips/${id}`)
  .then((response: any) => response.data);
}
uploadShipmentFile = (formData: any) => {
  return axios.post(`${marketBaseURL}/assets/shippingRequestFile`, formData, {
    headers: {
      'Content-Type': 'multipart/mixed'
    }
  })
  .then((response: any) => response.data);
//   try{
//     const res = await axios.post('http://k8s-qa-7841ae2356-1085021364.ap-south-1.elb.amazonaws.com:8888', formData, {
//       headers: {
//         'Content-Type': 'multipart/mixed'
//     }
//     });
//     console.log(res);
// } catch(err) {
//     console.log(err);
// }
}
getUploadedDocs = () =>{
  return axios.get(`${marketBaseURL}/assets/shippingRequestFile/filesummary`)
  .then((response: any) => response.data);
}
getUploadedDocsSummary = (id: any) => {
  return axios.post(`${marketBaseURL}/assets/shippingRequestFile/status?fileId=${id}`)
  .then((response: any) => response.data);
}
}