import moment from 'moment';
import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    shipdoorBuilding,
    shipaddress,
    shipcity,
    shippincode,
    shipmobile,
    shipper,
    deldoorBuilding,
    delstreetaddress,
    delcity,
    delpincode,
    delmobile,
    delname,
    pickupStartDate,
    pickupEndDate,
    dropStartDate,
    dropEndDate,
    pickupTimeStart,
    pickupTimeEnd,
    dropTimeStart,
    dropTimeEnd,
    volume,
    weight,
  }
} = checkoutFormModel;


// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const isPhone = RegExp(/^\D?(\+\d{1,4}[ - ]?)?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/) // us
// var phoneno = RegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);
var phoneno=RegExp(/^(\+\d?)?\d{10}$/)
const today = new Date();
today.setHours(0,0,0,0);
const isSameOrAfter = (startTime:any, endTime:any) => {
  return moment(startTime, 'HH:mm').isSameOrAfter(moment(endTime, 'HH:mm'));
}
export default [
  Yup.object().shape({
    [shipdoorBuilding.name]: Yup.string()
      .required(`${shipdoorBuilding.requiredErrorMsg}`),
    [shipaddress.name]: Yup.string()
      .required(`${shipaddress.requiredErrorMsg}`),
    [shippincode.name]: Yup.string()
      .required(`${shippincode.requiredErrorMsg}`)
      .test(
        'len',
        `${shippincode.invalidErrorMsg}`,
        (val:any) => val && (val.length === 5 || val.length === 6)
      ),
    [shipmobile.name] :Yup.string()
    .required(`${shipmobile.requiredErrorMsg}`)
    .matches(phoneno, shipmobile.invalidErrorMsg),
    [shipcity.name]: Yup.string()
    .typeError("City must be a String")
    .required(`${shipcity.requiredErrorMsg}`),

    [shipper.name]: Yup.string()
    .required(`${shipper.requiredErrorMsg}`),

    [deldoorBuilding.name]: Yup.string()
      .required(`${deldoorBuilding.requiredErrorMsg}`),
    [delstreetaddress.name]: Yup.string()
      .required(`${delstreetaddress.requiredErrorMsg}`),
    [delpincode.name]: Yup.string()
      .required(`${shippincode.requiredErrorMsg}`)
      .test(
        'len',
        `${shippincode.invalidErrorMsg}`,
        (val:any) => val && (val.length === 5 || val.length === 6)
      ),
    [delmobile.name] :Yup.string()
    .required(`${shipmobile.requiredErrorMsg}`)
    .matches(phoneno, shipmobile.invalidErrorMsg),
    

    [delcity.name]: Yup.string()
    .typeError("City must be a String")
    .required(`${delcity.requiredErrorMsg}`),
    [delname.name]: Yup.string()
    .required(`${delname.requiredErrorMsg}`),
   
  }),


  
  Yup.object().shape({
    [pickupStartDate.name]:Yup.date().min(today,`${pickupStartDate.invalidErrorMsg}`),
    // [pickupStartDate.name]:Yup.date().test('len',
    // `${pickupStartDate.invalidErrorMsg}`,(val:any)=>val && (new Date(val) > new Date((new Date().setDate((new Date().getDate()) - 1))))   ),
    // [dropStartDate.name]:Yup.string().test('len',
    // `${dropStartDate.invalidErrorMsg}`,(val:any)=>val && (new Date(val) > new Date((new Date().setDate((new Date().getDate()) -1)))) ),

    [dropStartDate.name]:Yup.date().min(Yup.ref('pickupStartDate'),`${dropStartDate.invalidErrorMsg}`),
    [pickupEndDate.name]: Yup.date().required('PickUp EndDate required').min(
      Yup.ref('pickupStartDate'),
      `${pickupEndDate.invalidErrorMsg}`
    ),



    // [pickupEndDate.name]:Yup.string().test('len',
    // `${pickupEndDate.invalidErrorMsg}`,(val:any)=>val && (new Date(val) > new Date((new Date().setDate((new Date().getDate()) -1))))  ),
    // [dropEndDate.name]:Yup.string().test('len',
    // `${dropEndDate.invalidErrorMsg}`,(val:any)=>val && (new Date(val) > new Date((new Date().setDate((new Date().getDate()) -1))))  ),

    [dropEndDate.name]:Yup.date().required('Delivery EndDate required').min(Yup.ref('dropStartDate'),`${dropEndDate.invalidErrorMsg}`),


/* if startdate is greater than current date no validation and */

[pickupTimeStart.name]:Yup.string().test(
  "start_time_test",
  `${pickupTimeStart.invalidErrorMsg}`,
  function(value) {
    const { pickupStartDate } = this.parent;
    if (pickupTimeStart && moment(pickupStartDate).isSame(today) ) {
      const currentTimeStamp = new Date();
      return isSameOrAfter(value, `${currentTimeStamp.getHours()}:${currentTimeStamp.getMinutes()}`);
    } else {
      return true; // any start time is valid if not today
    }
  }
),
[dropTimeStart.name]:Yup.string().test(
  "start_time_test",
  `${pickupTimeStart.invalidErrorMsg}`,
  function(value) {
    const { dropStartDate,pickupStartDate,pickupTimeStart } = this.parent;
    if(pickupTimeStart && pickupStartDate && moment(dropStartDate).isSame(pickupStartDate)){
      
      return isSameOrAfter(value,pickupTimeStart,);
    } 
   else if (pickupTimeStart && moment(dropStartDate).isSame(today) ) {
      const currentTimeStamp = new Date();
      return isSameOrAfter(value, `${currentTimeStamp.getHours()}:${currentTimeStamp.getMinutes()}`);
    } else {
      return true; // any start time is valid if not today
    }
  }
),
    //[pickupTimeStart.name]:Yup.string().test('len',`${pickupTimeStart.invalidErrorMsg}`,(val: any)=>val ),

    [pickupTimeEnd.name]:Yup.string().required('PickUp EndTime required').test(
      "End_time_test",
      `${pickupTimeEnd.invalidErrorMsg}`,
      function(value) {
       
        const { pickupTimeStart } = this.parent;
        if(value){
          return isSameOrAfter(value,pickupTimeStart)
        }else{
          return true
        }


       
      }
    ),
    [dropTimeEnd.name]:Yup.string().required('Delivery EndTime required').test(
      "End_time_test",
      `${dropTimeEnd.invalidErrorMsg}`,
      function(value) {
        const { dropTimeStart } = this.parent;
        if(value){
          return isSameOrAfter(value,dropTimeStart)
        }else{
          return true
        }
      }
    )
    .test(
      "End_time_test",
      "Delivery time should be greater than Pickup end time",
      function(value) {
        //debugger
        const { dropEndDate,pickupEndDate,pickupTimeEnd } = this.parent;
        if(pickupTimeEnd && pickupEndDate && moment(dropEndDate).isSame(pickupEndDate)){
      
          return isSameOrAfter(value,pickupTimeEnd,);
      } 
       else if (pickupTimeEnd && moment(dropEndDate).isSame(today) ) {
          const currentTimeStamp = new Date();
          return isSameOrAfter(value, `${currentTimeStamp.getHours()}:${currentTimeStamp.getMinutes()}`);
        } else {
          return true; // any start time is valid if not today
        }
      }
    ),
    

        


     [weight.name] : Yup.number().typeError("weight must be a number").nullable(),
     [volume.name] : Yup.number().typeError("volume must be a number").nullable(),
    // [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
    // [cardNumber.name]: Yup.string()
    //   .required(`${cardNumber.requiredErrorMsg}`)
    //   .matches(visaRegEx, cardNumber.invalidErrorMsg),
    // [expiryDate.name]: Yup.string()
    //   .nullable()
    //   .required(`${expiryDate.requiredErrorMsg}`)
    //   .test('expDate', expiryDate.invalidErrorMsg, val => {
    //     if (val) {
    //       const startDate = new Date();
    //       const endDate = new Date(2050, 12, 31);
    //       if (moment(val, moment.ISO_8601).isValid()) {
    //         return moment(val).isBetween(startDate, endDate);
    //       }
    //       return false;
    //     }
    //     return false;
    //   }),
    // [cvv.name]: Yup.string()
    //   .required(`${cvv.requiredErrorMsg}`)
    //   .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3)
  }),
  Yup.object().shape({
    [volume.name]: Yup.string()
      .required(`${volume.requiredErrorMsg}`),
      
    
  }),
  Yup.object().shape({

    // [driverName.name]:Yup.string().test(
    //   "driverName",
    //   `${driverName.requiredErrorMsg}`,
    //   function(value) {
    //     const { transporter } = this.parent;
    //     if(transporter && value){
          
    //       return false;
    //     } else{
    //       return true
    //     }
      
    //   }
    // ),
    // [driverNumber.name]: Yup.string().test( "driverNumber",
    // `${driverNumber.requiredErrorMsg}`,
    // function(value) {
    //   const { transporter } = this.parent;
    //   if(transporter && value){
        
    //     return false;
    //   } 
    //   else{
    //     return true
    //   }
   
    // }),
    // [shipper.name]: Yup.string().test( "shipper",
    // `${shipper.requiredErrorMsg}`,
    // function(value) {
    //   const { transporter } = this.parent;
    //   if(transporter&& value){
        
    //     return false;
    //   } 
    //   else{
    //     return true
    //   }
    
    // }),
    
      
    
  }),
 

];
