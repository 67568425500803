import axios from "axios";
import { driverBaseURL, tenantandUser } from "./_url";
import jwt_decode from "jwt-decode";

export default class DriverService {
  getDrivers = (headers:any) => {
    return axios.get(`${driverBaseURL}/driver/getAllDrivers`,{headers}).then((res:any)=>{
      return res.data
    }).catch((err: any) => {
      console.error('Fail', err.response );
      // setLoadFlag(false);
    });
  };
  deleteDriver = (headers:any, id:string) => {
    return axios.delete(`${driverBaseURL}/driver/deleteDriverDetails/${id}`,{headers}).then((res:any)=>{
      return res.data
    }).catch((err: any) => {
      console.error('Fail', err.response );
      // setLoadFlag(false);
    });
  };
  updateDriver = (headers:any, driver:any) => {
    return axios.post(`${driverBaseURL}/driver/updateDriverDetails`, driver, {headers}).then((res:any)=>{
      return res.data
    }).catch((err: any) => {
      console.error('Fail', err.response );
      // setLoadFlag(false);
    });
  };
  getDriver = (headers:any, mobile:string) => {
    return axios.get(`${driverBaseURL}/driver/getDriver/${mobile}`, {headers}).then((res:any)=>{
      return res.data
    }).catch((err: any) => {
      console.error('Fail', err.response );
      // setLoadFlag(false);
    });
  };
  addDriver = (headers:any, driver:any) => {
    return axios.post(`${driverBaseURL}/driver/newDriver`, driver, {headers}).then((res:any)=>{
      return res.data
    }).catch((err: any) => {
      console.error('Fail', err.response );
      // setLoadFlag(false);
    });
  };
  
}