import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from "@material-ui/icons/Add";
import IconButton from '@mui/material/IconButton';
import EditIcon from "@material-ui/icons/Edit";
import { data } from '../../../../../test/Denso/data';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EEF2F6",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const DriverInformation = (props: any) => {

    const Headingstyle: any = {
        textAlign: 'left',
        marginLeft: '1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight:"normal",
        fontSize: '14px',
        color: "#000000DE"
    }
    const topFiveStoppagesDatas = props.datas;
    // console.log(topFiveStoppagesDatas);
    return (
        <div>
            <div style={Headingstyle}>Driver Information</div>
            <TableContainer component={Paper} style={{boxShadow:"none"}}>
            <Table aria-label="customized table" >
              <TableHead>
                <TableRow>
                  <TableCell data-testid="Transporter" style={{fontWeight:"normal"}}>Driver ID</TableCell>
                  <TableCell align="right" data-testid="Type of Assets" style={{fontWeight:"normal"}}>Attendance</TableCell>
                  <TableCell align="right" data-testid="Geo-coordinates" style={{fontWeight:"normal"}}>No. of Shipments</TableCell>
                  <TableCell align="right" data-testid="Type of stoppages" style={{fontWeight:"normal"}}>No. of Delivery</TableCell>
                  <TableCell align="right" data-testid="Time / Duration" style={{fontWeight:"normal"}}>Total Dist. Travelled</TableCell>
                  <TableCell align="right" aria-label="Reason1" style={{fontWeight:"normal"}}>Violation</TableCell>
                  <TableCell align="right" aria-label="Reason2" style={{fontWeight:"normal"}}>Feedback</TableCell>
                  <TableCell align="right" aria-label="Reason2" style={{fontWeight:"normal"}}>Driver Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid="TableContent">
                {topFiveStoppagesDatas?.map((row: any, index: number) => (
                  <StyledTableRow key={index} data-testid='row'>
                    <StyledTableCell component="th" scope="row">
                        {row.transporter.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.asset ? row.asset.assetType.name: ""}</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right">
                      {/* <IconButton aria-label="edit" onClick={()=>handleEdit(row)}>
                        <EditIcon />key={row.name}
                      </IconButton>
                      <IconButton aria-label="delete" onClick={()=>handleDelete(row.mobileNumber)}>
                        <DeleteIcon />
                      </IconButton> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    )
}

export default DriverInformation;