import {
  createStyles,
  // InputAdornment,
  InputBase,
  makeStyles,
  // MenuItem,
  // Select,
  Theme,
  withStyles,
} from "@material-ui/core";
import SideTab from "../common/sideTab";
// import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
// import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
// import Autocomplete from "@mui/material/Autocomplete";
import { setAssetToken } from "../../../../services/tokens";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ShipperHelper from "../../../../services/_helpers";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
// import coldchainLog from "../../../../services/consoleHelper";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(0.1),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
    },
    divId: {
      "@media (max-width:500px)": {
        marginBottom: "2rem",
      },
    },
    topIconWrapper: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "0.75rem",
      color: "#5A5A5A",
      padding: "6px",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
    topIconsWrapper: {
      marginRight: "0.75rem",
      position: "absolute",
      top: 0,
      right: 0,
    },
  })
);

export default function AssetReeferTruck(props: any) {
  let dateHelper = new ShipperHelper();
  const classes = useStyles();
  var itemOption: any[] = [];
  const [period, setPeriod] = React.useState("daily");
  const [value, setValue] = useState(null);
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [fromAndTo, setfromAndTo] = useState("");
  const [endNotGreater, setendNotGreater] = useState(false);
  const [loadingCircle, setloadingCircle] = useState(false);
  const [overviewFlag, setOverviewFlag] = useState(false);

  const loadingCircleData = (value: any) => {
    setloadingCircle(value);
  };
  const handleLeftBarChange = (value: any) => {
    props.handleLeftBarChange(value);
  };

  useEffect(() => {
    if (props.value === 0) {
      setOverviewFlag(false);
    }
  }, [props.value])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "custom") {
      setcustomDialog(true);
    } else {
      setPeriod(event.target.value as string);
      setAssetToken("true");
      setstartDate("");
      setendDate("");
      setfromAndTo("");
    }
  };
  const handleChangeCustom = (event: any) => {
    /* istanbul ignore next */
    if (period === "custom" && event.target.value === 0) {
      setcustomDialog(true);
    }
  };
  const handleDates = (e: any) => {
    let fromAndToDate =
      "from=" + startDate + "T00:00:00Z&to=" + endDate + "T23:59:59Z";
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    seterrorText(false);
    setValidationStart(false);
    setValidationEnd(false);
    setendNotGreater(false);
    if (startDate === "") {
      setValidationStart(true);
    } else if (endDate === "") {
      setValidationEnd(true);
    } else if (startDate > endDate) {
      seterrorText(true);
    } else if (endDate > todaysdate) {
      setendNotGreater(true);
    } else {
      setfromAndTo(fromAndToDate);
      setPeriod("custom");
      setcustomDialog(false);
    }
  };
  const handleCancel = () => {
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    if (startDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (startDate > endDate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate > todaysdate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    }
    setcustomDialog(false);
  };
  const [tabName, setTabName] = useState("");

  useEffect(() => {
    if (props.pdfFlag == true) {
      props.pdfGen();
      GeneratorPdf();
    }
  }, [props.pdfFlag]);
  /* istanbul ignore next */

  const GeneratorPdf = () => {
    const ReeferTruckPDF = document.getElementById("ReeferTruck");
    html2canvas(ReeferTruckPDF, { useCORS: true }).then(
      (canvasReeferTruckPDF) => {
        const imgReeferTruckPDF = canvasReeferTruckPDF.toDataURL("image/png");
        const doc = new jsPDF();
        const imgProps = doc.getImageProperties(imgReeferTruckPDF);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(
          imgReeferTruckPDF,
          "PNG",
          5,
          10,
          pdfWidth - 5,
          pdfHeight - 5
        );
        doc.save(
          "ReeferTruck" + dateHelper.dateConversion(new Date()) + ".pdf"
        );
      }
    );
  };
  // useEffect(()=>{
  //   axios.get(`${assetBaseURL}/assets/type?type=${type}`).then ((res:any)=>{
  //     if(res.status === 200){
  //       setAssetData(res.data)
  //       res.data.map((x:any)=>itemOption.push(x.number))
  //       coldchainLog("resindex",res.data)
  //     }else if(res.status === 500){
  //       coldchainLog("res",res.status)
  //     }

  //   }).catch((err:any)=>{
  //     if(err.response.status === 500){
  //       setErr(err.response.data.errors[0])
  //     }
  //     console.error("err",err)
  //   })

  // },[])
  // assetData.map((x:any) =>itemOption.push(x.number))

  return (
    <div className={classes.root}>
      <div>
        <div>
          {/* <Autocomplete
            options={itemOption.map((option) => option)}
            id="auto-complete"
            value={value}
            autoComplete
            clearIcon
            includeInputInList
            style={{ width: '10%', }}
            // onChange={(event, value) => {coldchainLog(itemOption.indexOf(value));setValue(value);
            // }}

            popupIcon={<p></p>}
            renderInput={(params) => (
              <TextField {...params} placeholder='Search' variant="standard"
                InputProps={{
                  ...params.InputProps,
                  type: 'Search...',
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  )
                }} />
            )}
          /> */}

          {/* <div
            className={classes.divId}
            style={{ float: "right", display: "inline-block" }}
          >
            <Select
              labelId="demo-customized-select-label"
              data-testid="period"
              id="demo-customized-select"
              value={period}
              onChange={handleChange}
              onClick={handleChangeCustom}
              input={<BootstrapInput />}
            >
              <MenuItem value={"daily"}>Last 24 Hours</MenuItem>
              <MenuItem value={"weekly"}>Last 7 Days</MenuItem>
              <MenuItem value={"monthly"}>Last 30 Days</MenuItem>
              <MenuItem value={"custom"}>Custom Date</MenuItem>
            </Select>

            <span id="filterIcon">
              <DeleteOutlineOutlinedIcon style={{ color: "#b5b5b5" }} />{" "}
            </span>
            <span id="filterIcon">
              {" "}
              <EditOutlinedIcon style={{ color: "#b5b5b5" }} />{" "}
            </span>
        </div>*/}
        </div>
        <div id="ReeferTruck">
          {/* <Tabs defaultActiveKey="DETAILS" id="uncontrolled-tab-example" style={{ marginLeft: '12%', marginTop: '-2.3rem' }} data-testid="DetailsTab">

            <Tab eventKey="DETAILS" title="DETAILS" > */}
          <SideTab
            filter={props.period}
            selectedCloseMarket={props.selectedCloseMarket}
            type="Reefer Truck"
            fromAndTo={props.fromAndTo}
            handleLeftBarChange={handleLeftBarChange}
            loadingCircleData={loadingCircleData}
            value={props.value}
            overviewFlag={overviewFlag}
            setSelectedAssetId={props.setSelectedAssetId}
          />
          {/* </Tab>

            <Tab eventKey="HARDWARE INFO" title="HARDWARE INFO">
              <HardwareSide filter={period}  type="Reefer Truck" />
            </Tab>

          </Tabs> */}
        </div>
      </div>
      <div>
        {loadingCircle && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingCircle}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Dialog
          open={customDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">{"Custom Date"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <br />
              <TextField
                id="date"
                data-testid="startDate"
                label="Start date"
                type="date"
                sx={{ width: 220, marginBottom: "30px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errorText === true ? errorText : validationStart}
                helperText={
                  errorText === true
                    ? "start date cannot be greater than end date"
                    : validationStart === true
                      ? "Select start date"
                      : ""
                }
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
              />
              &nbsp;&nbsp;
              <TextField
                id="date"
                data-testid="endDate"
                label="End date"
                type="date"
                // defaultValue="2017-05-24"
                sx={{ width: 220, marginBottom: "30px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                error={validationEnd === true ? validationEnd : endNotGreater}
                helperText={
                  validationEnd === true
                    ? "Select end date"
                    : endNotGreater === true
                      ? "End date cannot be greater than todays date"
                      : ""
                }
                onChange={(e) => setendDate(e.target.value)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="okButton" onClick={handleDates}>
              Ok
            </Button>
            <Button data-testid="cancelButton" onClick={handleCancel}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
