import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Dialog, DialogActions, DialogContent, DialogTitle,Typography} from '@material-ui/core';
import CollapseTable from '../../Utils/collapse';
import { useRowStyles } from './style';
import '../style.css';
import ShipperHelper, { timeDiff, setCompleteToken, ShippingRequestStatus } from '../../../../services/_helpers';
import { useHistory } from 'react-router-dom';
import ShipperAPI from '../../../../services/shippingLanding';
import { TableManageColumnsFormData } from '../../Dashboard/Common/Interfaces/custom';
import ChallanGenerator from '../../common/PdfChallan/ChallanGenerator';

function Row(props: { row: any;  setFetchTableFlag: any; manageColumnsOptions: TableManageColumnsFormData[] }) {
    const { row , setFetchTableFlag, manageColumnsOptions} = props;
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteOpenActive, setDeleteOpen] = React.useState(false);
  const [fullWidth,] = React.useState(true);
  
const ShipperLandingPage = (requestNumber:any) => {
  history.push(`/shipper/${requestNumber}`)
}
const ShipperUpdatePage = (requestNumber:any) => {
  history.push(`/updateshipment/${requestNumber}`)
}
  let dateHelper =new ShipperHelper();
  const formatPrice = (price: number) => {
    if(!isNaN(price)) {
      return price.toFixed(2);
    }
    return price ? price : '---';
  }
  const cells = [
    { defaultOrder: 1, render: (
      <TableCell align="left">
          <span id={
            ((ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ) ? 'statusRed' :
             
              (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Accepted ? 'statusAccepted' :
                (ShippingRequestStatus[row?.status] === ShippingRequestStatus.InTransit ? 'statusOrange' : 'statusRed')))
          } className="capitalise-case">
            { ShippingRequestStatus[row?.status] }
          </span>
  
            </TableCell>
    )},
    { defaultOrder: 2, render: (
      <TableCell component="th" scope="row">
        <Button className={classes.requestId} data-testid='reqID' onClick={() => ShipperLandingPage(row?.requestNumber)} >
          {row?.requestNumber}
        </Button>
      </TableCell>
    )},
    { defaultOrder: 3, render: (
      <TableCell >{(row?.transporter?.name)?(row?.transporter?.name):"----"}</TableCell>
    )},
    { defaultOrder: 4, render: (
      <TableCell align="right">{formatPrice(row?.paymentDetails?.actualAmount )}</TableCell>
    )},
    { defaultOrder: 5, render: (
      <TableCell align="left" >{dateHelper.dateConversion(row?.createdAt)}</TableCell>
    )}
  ]
  let ship=new ShipperAPI();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClosemenu = () => {
      setAnchorEl(null);
    };
  const handleClickOpenDelete = () => {
    setDeleteOpen(!deleteOpenActive);
    handleClosemenu();
  };

  const [openPDF, setOpenPDF] = React.useState(false);
  const handleOpenPDF = () => setOpenPDF(true);
  const handleClosePDF = () => setOpenPDF(false);

  const handleCloseDelete = () => {
    setDeleteOpen(!deleteOpenActive);

  };

  const handleCancel = () => {
    row.status = "Cancelled";
    ship.putRequest(row, row.id).then((res) => {
      setFetchTableFlag(true);
      setCompleteToken("true")
      setDeleteOpen(false)
    })
  }
      let date  =new Date(row?.expectedPickUpTime?.to);
    
      let today=new Date();
      let timeToPickup= date.getTime() - today.getTime()

    return (
      <>
        { (ShippingRequestStatus[row.status] !== ShippingRequestStatus.Completed && ShippingRequestStatus[row.status] !== ShippingRequestStatus.Cancelled) ?
        <>
        <TableRow className={open?classes.rowTop:''} >
          <TableCell>
            
          <IconButton aria-label="expand row" size="small" data-testid='collapseicon' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          { 
            manageColumnsOptions.sort((a: TableManageColumnsFormData, b: TableManageColumnsFormData) => {
              return a.order - b.order;
            }).map((reorder: TableManageColumnsFormData) => {
              return cells.find((cell: any) => cell.defaultOrder === reorder.defaultOrder).render;
            })
          }
         
         
          
        
          
          
          <TableCell align="center">


          {ShippingRequestStatus[row?.status] !== ShippingRequestStatus.PendingAcceptance?
          <>
            <Button disabled >UPDATE</Button> 
         
            <IconButton onClick={handleClick} component="span">
          <MoreVertIcon />
          </IconButton></>:
            
            <>
            {
              (timeToPickup>timeDiff && ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance) ?
                <>
                  <Button ><div style={{color:'#1A0DAB',textDecorationLine:'none'}} data-testid='updateResquest' onClick={() => ShipperUpdatePage(row?.requestNumber)}>UPDATE </div></Button> 
                  <IconButton onClick={handleClick} component="span">
                    <MoreVertIcon />
                  </IconButton>
                </> :
                <>                  
                  <Button  disabled>UPDATE</Button> 
                  <IconButton onClick={handleClick} component="span">
                  <MoreVertIcon />
                  </IconButton>
                </>
            }
          </>
        }


</TableCell>

        </TableRow>
      
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9} className={open?classes.rowBottom:''}>
            <Collapse in={open} timeout="auto" unmountOnExit >
             <CollapseTable status={row?.status} feedback={["FRESHNESS INDEX","CUSTOMER FEEDBACK","DISTANCE TRAVELLED"]}  row={row}/>
            </Collapse >
          </TableCell>
         
        </TableRow>
        
      <div>
        <ChallanGenerator row={row} openPDF={openPDF} handleClosePDF={handleClosePDF}/>
      </div>
       
        <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClosemenu}
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    transformOrigin={{vertical: 'top', horizontal: 'right'}}
    getContentAnchorEl={null}
    data-testid='menuType'
    
  >
    
          <>
            {
              (ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance) ?
                <>
                  <MenuItem onClick={handleClickOpenDelete} data-testid='cancel'>Cancel</MenuItem>
                </> :
                <>                  
                  <MenuItem  data-testid='cancel' disabled>Cancel</MenuItem>
                </>
            }
          </>
 
   
    <MenuItem onClick={handleOpenPDF} data-testid='download' disabled={ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ? true : null}>Download Challan</MenuItem>
   
  </Menu>
  <Dialog onClose={handleCloseDelete} aria-labelledby="customized-dialog-title" open={deleteOpenActive} 
  maxWidth="sm" fullWidth={fullWidth}>
          <DialogTitle id="customized-dialog-title" >
           CANCEL
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
             Are you sure to cancel the record?
            </Typography>
           
          </DialogContent>
          <DialogActions>
            <Button  onClick={handleCloseDelete} color="primary">
            CLOSE
            </Button>
            <Button  onClick={handleCancel} color="primary" data-testid="cancel">
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
        </>
        :<></>}
     </>
  
    );
  }
export default Row;