
// import moment from "moment";
import moment from "moment-timezone"
import UserService from './userService';

export enum ShippingRequestStatus {
  // Key is as per architecture and Value is a conversion to user-readable format
  PendingAcceptance = 'Pending Acceptance',
  Accepted = 'Accepted',
  InTransit = 'In-Transit',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  InProgress = 'InProgress'
}

export enum FeedbackStatus {
  HAPPY = 'HAPPY',
  NEUTRAL = 'NEUTRAL',
  NOT_HAPPY = 'NOT_HAPPY'
}

export enum AvgIconsColor {
  ABOVERANGE = "#B00020",
  BELOWRANGE = "#B00020",
  INLINE = "#5FC0E1",
  INLINEGREEN = "#159A40"
}


export enum AvgIcons {
  ABOVERANGE = "above range",
  BELOWRANGE = "below range",
  INLINE = "inline"
}

// number of Asset in Reports and heatmap(Transporter tab)
export const maxAssetList = 1000;

//Set MapMyIndia zoom level 
export const defaultZoomLevel = 8;


export const timeDiff = 0;
// export const  timeDiff=900000; need it for to get 15min time difference
export const AppPrimaryColor = '#3f51b5';
export const setCompleteToken = (token: any) => {
  sessionStorage.setItem("complete", token);
};

export const getCompleteToken = () => {
  return sessionStorage.getItem("complete") || "true";
};


export const setUpdateToken = (token: any) => {
  sessionStorage.setItem("update", token);
};

export const getUpdateToken = () => {
  return sessionStorage.getItem("update") || "";
};


export default class ShipperHelper {
  getStatusKeyByValue = (value: any) => {
    return Object.keys(ShippingRequestStatus).find(key => ShippingRequestStatus[key] === value);
  }

  getDateParamByDuration = (duration: string, fromParamKey: string = 'startDate', toParamKey: string = 'endDate') => {
    const today = new Date();
    let startDate = today;
    let groupBy: string = 'daily';
    switch (duration) {
      case 'daily':
        // no change in date as start date and end date will be same for daily
        groupBy = 'hourly';
        break;
      case 'weekly':
        startDate.setDate(startDate.getDate() - 7);
        groupBy = 'daily';
        break;
      case 'monthly':
        startDate.setFullYear(startDate.getMonth() - 1);
        startDate.setDate(startDate.getDate() + 1);
        groupBy = 'weekly';
        break;
      case 'yearly':
        startDate.setFullYear(startDate.getFullYear() - 1);
        groupBy = 'monthly';
        break;
      case 'overall':
        startDate.setFullYear(2021);
        startDate.setMonth(0);
        startDate.setDate(1);
        break;
      default:
        startDate.setFullYear(2021);
        startDate.setMonth(0);
        startDate.setDate(1);
        console.error('Error in timelog selection');
    }
    return `${fromParamKey}=${startDate.toISOString().split('.')[0]}Z&${toParamKey}=${today.toISOString().split('.')[0]}Z&duration=${duration}`;
  }

  dateConversion = (rowDate: any) => {
    if (rowDate) {
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let date = new Date(rowDate);
      let year = String(date.getFullYear());
      let month = date.getMonth();
      let day = date.getDate();
      let hrs = (date.getHours() % 12) || 12;

      //coldchainLog("datea",hrs)
      let finalDate = day + "-" + monthNames[month] + "-" + year.substr(2, 2) + " " + (hrs < 10 ? '0' : '') + hrs + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + `${date.getHours() >= 12 ? 'pm' : 'am'}`
      return finalDate
    }
    else {
      return "----"
    }

  }

 /**
 *
 * @param rowDate contains date in YYYYMMDDHHmm format
 * @returns parsed the date and returns in "DD-MMM-YY hh:mm a" format
 */
parseDate = (rowDate: any) => {
  const date = moment(rowDate, "YYYYMMDDHHmm");
  const parsedDate = date.format("DD-MMM-YY hh:mm a");
  return parsedDate;
};

/**
 *
 * @param rowDate contains date in YYYYMMDDHHmm format
 * @returns parsed the date to IST and returns in "DD-MMM-YY hh:mm a" format
 */
parseDateToIst = (rowDate: any, isMobile: boolean = false) => {
  let formattedDate;
  if(isMobile){
    formattedDate = moment(rowDate, "YYYYMMDDHHmm").tz("Asia/Kolkata").format("DD-MMM-YY hh:mm a");
  } else {
    formattedDate = moment.utc(rowDate, "YYYYMMDDHHmm").tz("Asia/Kolkata").format("DD-MMM-YY hh:mm a");
  }
  return formattedDate;
};

/**
 *
 * @param rowDate contains date in YYYYMMDDHHmm format
 * @returns parsed the date to IST and returns in "DD-MMM-YY hh:mm a" format
 */
parseDateToLocal = (rowDate: any, isMobile: boolean = false) => {
  let formattedDate;
  if(isMobile){
    formattedDate = moment.utc(rowDate).local().format("DD-MMM-YY hh:mm a");
  } else {
    formattedDate = moment.utc(rowDate, "YYYYMMDDHHmm").local().format("DD-MMM-YY hh:mm a");
  }
  return formattedDate;
};

/**
 *
 * @param filter contains date period like last 24 hrs or custom date
 * @param fromAndTo contains the from and to date selected in IST format
 * @returns parsed the date to GMT and returns in "YYYYMMDDHHmm" format
 */
parseFromAndToDateToGmt = (filter: any, fromAndTo: any) => {
  let fromValue;
    let toValue;
    if(filter === "custom") {
      let date = fromAndTo.split('=')
      fromValue = date[1].split('&')[0]
      toValue = date[2]
    }
    let fromDate: any = filter === 'daily' ? moment().subtract(1, 'days'): filter === 'weekly' ? moment().subtract(7, 'days'): filter === 'monthly' ? moment().subtract(30, 'days'): filter === 'custom' ? moment(fromValue) : moment().subtract(1, 'days');
    let toDate: any = filter === 'custom' ? moment(toValue) : moment();
    let parsedFromDate = moment(fromDate).utc();
    fromDate = parsedFromDate.format("YYYYMMDDHHmm")
    let parsedToDate = moment(toDate).utc()
    toDate =  parsedToDate.format("YYYYMMDDHHmm");
    return {
      fromDate,
      toDate
    }
};

/**
 *
 * @param date contains date
 * @returns parsed the date to GMT and returns in "YYYY-MM-DDTHH:mm:ss.SSS+0000" format
 */
parseDateToGmt = (date: any) => {
  const gmtDateTime = moment(date).tz("GMT");
  const gmtIso8601Str = gmtDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS+0000");
  return gmtIso8601Str;
}

/**
 *
 * @param date contains date
 * @returns one year added to the date
 */
addOneYearToDate = (date: any) => {
  const dateMoment = moment(date);
  const oneYearLater = dateMoment.add(1, "years");
  return oneYearLater;
}



  dateToUTC = (date: any) => {
    var utcDate = new Date(date).toISOString().split('Z')[0] + '+0000'
    return utcDate;
  }

  putAPIDate = (date: any) => {
    var pickerYear = date.split("-")[0];
    var pickermonth = date.split("-")[1];
    var pickerDay = date.split("-")[2].split('T')[0];
    var pickerDate = pickerYear + "-" + pickermonth + "-" + pickerDay
    return pickerDate;
  }
  putTimeConversion = (date: any) => {
    var hrs = (new Date(date).getHours() < 10 ? '0' : "") + new Date(date).getHours()
    var min = (new Date(date).getMinutes() < 10 ? '0' : "") + new Date(date).getMinutes()
    return hrs + ":" + min
  }
  requestNumber = () => {

    var requestNumberDate: string =
      String((new Date().getDate() < 10 ? '0' : '') + new Date().getDate()) +
      String((new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)) +
      String(new Date().getFullYear()) +
      String((new Date().getHours() < 10 ? '0' : '') + new Date().getHours()) +
      String((new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes()) +
      String((new Date().getSeconds() < 10 ? '0' : '') + new Date().getSeconds())
    //coldchainLog("requestNumberDate",requestNumberDate)
    return requestNumberDate
  }
  telemetryDate = (period: any) => {
    if (period === 'monthly') {

      return (("from=" + moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    } else if (period === 'weekly') {
      return (("from=" + moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    } else if (period === 'daily') {
      moment().startOf('day')
      return (("from=" + moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    }
  }
  DashboardDate = (period: any) => {
    if (period === 'monthly') {

      return (("startDate=" + moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&endDate=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    } else if (period === 'weekly') {
      return (("startDate=" + moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&endDate=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    } else if (period === 'daily') {
      moment().startOf('day')
      return (("startDate=" + moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&endDate=" + moment(new Date()).format('YYYY-MM-DD') + "T00:00:00Z")
    } else {
      return (("startDate=" + moment(new Date()).subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ssZ').slice(0, -6) + "Z") +
        "&endDate=" + moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ').slice(0, -6) + "Z")
    }
  }
  DashboardDates = (period: any) => {
    if (period === 'monthly') {

      return (("from=" + moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    } else if (period === 'weekly') {
      moment().startOf('week')
      return (("from=" + moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD') + "T"+moment().format('HH:mm:ss')+'Z') +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T"+moment().format('HH:mm:ss')+'Z')
    } else if (period === 'daily') {
      // console.log("dashboarddate")
      moment().startOf('day')
      return (("from=" + moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD') +"T"+moment().format('HH:mm:ss')+'Z') +
        "&to=" + moment(new Date()).format('YYYY-MM-DD')+"T"+moment().format('HH:mm:ss')+'Z')
    } else {
      return (("startDate=" + moment(new Date()).subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ssZ').slice(0, -6) + "Z") +
        "&endDate=" + moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ').slice(0, -6) + "Z")
    }
  }
  DashboardDateWithFrom = (period: any) => {
    if (period === 'monthly') {

      return (("from=" + moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    } else if (period === 'weekly') {
      return (("from=" + moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T23:59:59Z")
    } else if (period === 'daily') {
      moment().startOf('day')
      return (("from=" + moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD') + "T00:00:00Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DD') + "T00:00:00Z")
    } else {
      return (("from=" + moment(new Date()).subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ssZ').slice(0, -6) + "Z") +
        "&to=" + moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ').slice(0, -6) + "Z")
    }
  }
  LineColor = (flag: any) => {
    if (flag === AvgIcons.ABOVERANGE) {
      return AvgIconsColor.ABOVERANGE
    } else if (flag === AvgIcons.BELOWRANGE) {
      return AvgIconsColor.BELOWRANGE
    } else {
      return AvgIconsColor.INLINE
    }
  }
  LineColorLidAndShock = (flag: any) => {
    if (flag === AvgIcons.ABOVERANGE) {
      return AvgIconsColor.ABOVERANGE
    } else if (flag === AvgIcons.BELOWRANGE) {
      return AvgIconsColor.BELOWRANGE
    } else {
      return AvgIconsColor.INLINEGREEN
    }
  }

  UserTokenExpiration = () => {
    const userApi = new UserService();
    const userDetails = userApi.getUserDetails();
    if (userDetails !== null) {
      const isExpired = userApi.checkTokenExpiration(userDetails.token);
      if (userDetails?.token) {
        if (isExpired) {
          // expired user
          window.location.reload()
        }
      }
    }
  }

}
