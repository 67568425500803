import React, { useState } from "react";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {
  DatePickerField,
  TimePickerField,
  StartDatePickerField,
} from "../../common/FormFields";
import moment from "moment";

const useStyles = makeStyles((theme) =>
  createStyles({
    chipCSSPick: {
      color: "#0078a5",
      
     
      backgroundColor: "#ddedf3",
      "&:hover": {
        color: "#0078a5",
        backgroundColor: "#ddedf3",
      },
    },
    chipCSSDrop: {
    
      color: "#0078a5",
      backgroundColor: "#ddedf3",
      "&:hover": {
        color: "#0078a5",
        backgroundColor: "#ddedf3",
      },
    },
  })
);

export default function TimeSlotForm(props: any) {
  const {
    formField: {
      pickupStartDate,
      pickupEndDate,
      dropStartDate,
      dropEndDate,
      dropTimeStart,
      dropTimeEnd,
      pickupTimeEnd,
      pickupTimeStart,
    },
    setFieldValue,
  } = props;

  const [chipDrop, setChipDrop] = useState("");
  const [chipPick, setChipPick] = useState("");
  const [chipDropTime, setChipDropTime] = useState("");
  const [chipPickTime, setChipPickTime] = useState("");

  const date: string =
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1 < 10 ? "0" : "") +
    (new Date().getMonth() + 1) +
    "-" +
    (new Date().getDate() < 10 ? "0" : "") +
    new Date().getDate();
  let ddate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const date2: string =
    ddate.getFullYear() +
    "-" +
    (ddate.getMonth() + 1 < 10 ? "0" : "") +
    (ddate.getMonth() + 1) +
    "-" +
    (ddate.getDate() < 10 ? "0" : "") +
    ddate.getDate();

  var today = new Date();
  var time =
    (today.getHours() < 10 ? "0" : "") +
    today.getHours() +
    ":" +
    ((today.getMinutes() < 10 ? "0" : "") + today.getMinutes());

  const classes = useStyles();
  const pickUpDate = (item: any) => () => {
    if (item === "Today") {
      setFieldValue(pickupStartDate.name, date);
      setFieldValue(pickupEndDate.name, date);
    } else if (item === "Tomorrow") {
      setFieldValue(pickupStartDate.name, date2);
      setFieldValue(pickupEndDate.name, date2);
    } else if (item === "Custom") {
      setFieldValue(pickupStartDate.name, "");
      setFieldValue(pickupEndDate.name, "");
    }
    setChipPick(item);
  };
  const dropDate = (item: any) => () => {
    if (item === "Today") {
      setFieldValue(dropStartDate.name, date);
      setFieldValue(dropEndDate.name, date);
    } else if (item === "Tomorrow") {
      setFieldValue(dropStartDate.name, date2);
      setFieldValue(dropEndDate.name, date2);
    } else if (item === "Custom") {
      setFieldValue(dropStartDate.name, "");
      setFieldValue(dropEndDate.name, "");
    }
    setChipDrop(item);
  };

  const setPickTime = (item: any) => () => {
    if (item === "one") {
      setFieldValue(pickupTimeStart.name, time);
      setFieldValue(
        pickupTimeEnd.name,
        moment.utc(time, "HH:mm").add(1, "hour").format("HH:mm")
      );
    } else if (item === "two") {
      setFieldValue(pickupTimeStart.name, time);
      setFieldValue(
        pickupTimeEnd.name,
        moment.utc(time, "HH:mm").add(2, "hour").format("HH:mm")
      );
    } else if (item === "anyTime") {
      setFieldValue(pickupTimeStart.name, "00:00");
      setFieldValue(pickupTimeEnd.name, "23:59");
    } else if (item === "Custom") {
      setFieldValue(pickupTimeStart.name, "");
      setFieldValue(pickupTimeEnd.name, "");
    }
    setChipPickTime(item);
  };
  const setDropTime = (item: any) => () => {
    if (item === "one") {
      setFieldValue(dropTimeStart.name, time);
      setFieldValue(
        dropTimeEnd.name,
        moment.utc(time, "HH:mm").add(1, "hour").format("HH:mm")
      );
    } else if (item === "two") {
      setFieldValue(dropTimeStart.name, time);
      setFieldValue(
        dropTimeEnd.name,
        moment.utc(time, "HH:mm").add(2, "hour").format("HH:mm")
      );
    } else if (item === "anyTime") {
      setFieldValue(dropTimeStart.name, "00:00");
      setFieldValue(dropTimeEnd.name, "23:59");
    } else if (item === "Custom") {
      setFieldValue(dropTimeStart.name, "");
      setFieldValue(dropTimeEnd.name, "");
    }
    setChipDropTime(item);
  };

  return (
    <React.Fragment>
      <div className="layout-border-time">
        <Grid container spacing={2} style={{height:'510px'}}>
          <Grid item xs={11} md={11} lg={5} style={{marginTop:"-16px"}}>
            <p className="cardTitle">Expected Pick-up Date</p>
           
            <Grid>
              <Chip
                size="medium"
                label="Today"
                style={{width:"80px",marginLeft:"-9px"}}
                data-testid="pickUpDateToday"
                onClick={pickUpDate("Today")}
                className={chipPick === "Today" ? classes.chipCSSPick : ""}
              />
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Tomorrow"
                style={{width:"100px",marginTop:"-52px", marginLeft:"90px"}}
                data-testid="pickUpDateTomorrow"
                onClick={pickUpDate("Tomorrow")}
                className={chipPick === "Tomorrow" ? classes.chipCSSPick : ""}
              />{" "}
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Custom"
                data-testid="pickUpDateCustom"
                onClick={pickUpDate("Custom")}
                style={{width:"90px",marginTop:"-52px", marginLeft:"210px"}}
                className={chipPick === "Custom" ? classes.chipCSSPick : ""}
              />
              <br />
          
             
                <Grid  item xs={12} md={6} style={{marginTop:'2%'}}>
                  <StartDatePickerField
                    id="date"
                    style={{marginLeft:'-10px',width:"94%"}}
            
                    variant="outlined"
                    label="StartDate *"
                    type="date"
                    name={pickupStartDate.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                      </Grid>
                      <div style={{color:"#000",marginLeft:"47%",marginTop:"-40px"}}>-</div>
                <Grid  item xs={12} md={6} style={{ marginLeft:"50%",marginRight:"2%"}}>
                <DatePickerField
                    id="date"
                    variant="outlined"
                    label="EndDate *"
                    type="date"
                    style={{width:"94%",marginTop:"-36px"}}
                    name={pickupEndDate.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                      </Grid>
                      <p className="cardTitle" >Expected Delivery Date</p>
                      <Chip
                size="medium"
                label="Today"
                style={{width:"80px",marginLeft:"-9px"}}
                data-testid="dropDateToday"
                onClick={dropDate("Today")}
                className={chipDrop === "Today" ? classes.chipCSSDrop : ""}
              />
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Tomorrow"
                style={{width:"100px",marginTop:"-52px", marginLeft:"95px"}}
                data-testid="dropDateTomorrow"
                onClick={dropDate("Tomorrow")}
                className={chipDrop === "Tomorrow" ? classes.chipCSSDrop : ""}
              />{" "}
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Custom"
                data-testid="dropDateCustom"
                style={{width:"90px",marginTop:"-52px", marginLeft:"217px"}}
                onClick={dropDate("Custom")}
                className={chipDrop === "Custom" ? classes.chipCSSDrop : ""}
              />
                            <Grid  item xs={12} md={6} style={{ marginLeft:"1%",marginRight:"2%",marginTop:'7%'}}>
                            <StartDatePickerField
                   style={{marginLeft:'-16px', width:"94%"}}
                    id="date"
                    variant="outlined"
                    label="StartDate *"
                    type="date"
                    name={dropStartDate.name}
                    InputLabelProps={{ shrink: true }}
                  />
                            </Grid>
                            <div style={{color:"#000",marginLeft:"47%",marginTop:"-40px"}}>-</div>
                          <Grid  item xs={12} md={6} style={{ marginLeft:"50%",marginRight:"2%"}}>
                          <DatePickerField
                    id="date"
                    style={{width:"94%",marginTop:"-36px"}}
                    variant="outlined"
                    label="EndDate *"
                    type="date"
                    name={dropEndDate.name}
                    InputLabelProps={{ shrink: true }}
                  />  
                          </Grid>
                     
              </Grid>
            </Grid>
              <div  className="verticalLine1" >
            <p >
            <hr style={{border:"1px solid lightgrey"}}/>
            </p>
            </div>
       
            <Grid item xs={11} md={11} lg={5} style={{marginLeft:"7%",marginTop:"-12px"}}>
            <p className="cardTitle">Expected Pickup Time</p>
           
            <Grid>
             <Chip
                size="medium"
                label="Next 1 hour"
                style={{width:"21%",marginLeft:"-11px"}}
                data-testid="setPickTimeOne"
                onClick={setPickTime("one")}
                className={chipPickTime === "one" ? classes.chipCSSPick : ""}
              />
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Next 2 hour"
                data-testid="setPickTimeTwo"
                style={{width:"21%",marginTop:"-52px", marginLeft:"22%"}} 
                onClick={setPickTime("two")}
                className={chipPickTime === "two" ? classes.chipCSSPick : ""}
              />{" "}
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Any time today"
                data-testid="setPickTimeAny"
                style={{width:"26%",marginTop:"-52px", marginLeft:"46%"}}
                onClick={setPickTime("anyTime")}
                className={
                  chipPickTime === "anyTime" ? classes.chipCSSPick : ""
                }
              />{" "}
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Custom"
                style={{width:"18%",marginTop:"-52px", marginLeft:"74%"}}
                data-testid="setPickTimeCustom"
                onClick={setPickTime("Custom")}
                className={chipPickTime === "Custom" ? classes.chipCSSPick : ""}
              />
           
              <br />
          
             
                {/* <Grid  item xs={12} md={6} style={{marginTop:"1.8%"}}>
                <TimePickerField  name={pickupTimeStart.name} style={{width:"90%"}}/>{" "}
                      </Grid>
                
                <Grid  item xs={12} md={6} style={{ marginLeft:"50%",marginRight:"2%",marginTop:"6.5%"}}>
                <TimePickerField name={pickupTimeEnd.name}  style={{marginTop:"-39%",width:"90%"}}/>{" "}
                      </Grid> */}

<Grid  item xs={12} md={6} style={{marginTop:'2%'}}>
<TimePickerField  name={pickupTimeStart.name}     style={{marginLeft:'-10px',width:"98%"}}
            />{" "}
                 
                      </Grid>
                      <div style={{color:"#000",marginLeft:"49.7%",marginTop:"-40px"}}>-</div>
                <Grid  item xs={12} md={6} style={{ marginLeft:"51%",marginRight:"-6%"}}>
                <TimePickerField name={pickupTimeEnd.name}    style={{width:"98%",marginTop:"-56px"}} />
              
                      </Grid>
                      <p className="cardTitle1" >Expected Delivery Time</p>
                    
                <Chip
                size="medium"
                label="Next 1 hour"
                style={{width:"21%",marginLeft:"-11px"}}
                data-testid="setDropTimeOne"
                onClick={setDropTime("one")}
                className={chipDropTime === "one" ? classes.chipCSSDrop : ""}
              />
              &nbsp;&nbsp;
            
                <Chip
                size="medium"
                style={{width:"21%",marginTop:"-52px", marginLeft:"22%"}} 
                label="Next 2 hour"
                data-testid="setDropTimeTwo"
                onClick={setDropTime("two")}
                className={chipDropTime === "two" ? classes.chipCSSDrop : ""}
              />{" "}
              &nbsp;&nbsp;
              <Chip
                size="medium"
                style={{width:"26%",marginTop:"-52px", marginLeft:"46%"}}
                label="Any time today"
                data-testid="setDropTimeAny"
                onClick={setDropTime("anyTime")}
                className={
                  chipDropTime === "anyTime" ? classes.chipCSSDrop : ""
                }
              />{" "}
            
              &nbsp;&nbsp;
              <Chip
                size="medium"
                label="Custom"
                style={{width:"18%",marginTop:"-52px", marginLeft:"74%"}}
                data-testid="setPickTimeCustom"
                onClick={setDropTime("Custom")}
                className={chipDropTime === "Custom" ? classes.chipCSSDrop : ""}
              />{" "}
                     
                 
<Grid  item xs={12} md={6} style={{marginTop:'3%'}}>
<TimePickerField  name={dropTimeStart.name}     style={{marginLeft:'-10px',width:"98%",marginTop:"20px"}}
            />{" "}
                 
                      </Grid>
                          <div style={{color:"#000",marginLeft:"49.5%",marginTop:"-40px"}}>-</div>
             
                <Grid  item xs={12} md={6} style={{ marginLeft:"51%",marginRight:"-6%"}}>
                <TimePickerField name={dropTimeEnd.name}    style={{width:"98%",marginTop:"-56px"}} />
            
                      </Grid>
              </Grid>
            </Grid>
           
       
       
          
        </Grid>
      </div>
    </React.Fragment>
  );
}
