import axios from "axios";
import { authBaseURL, tenantandUser } from "./_url";
import jwt_decode from "jwt-decode";
import coldchainLog from './consoleHelper';
import { getToken } from "./tokens";

 // todo: API integration

let ManageColumnDenso = [
  {title: 'Status', defaultOrder: 1, order: 1},
  {title: 'Request ID', defaultOrder: 2, order: 2},
  {title: 'Provider', defaultOrder: 3, order: 3},
  {title: 'Shipper', defaultOrder: 4, order: 4},
  {title: 'Asset', defaultOrder: 5, order: 5},
  {title: 'Driver', defaultOrder: 6, order: 6},
  {title: 'Price (INR)', defaultOrder: 7, order: 7},
  {title: 'Created ON', defaultOrder: 8, order: 8}
]
let ManageColumnTransporter = [
  {title: 'Status', defaultOrder: 1, order: 1},
  {title: 'Request ID', defaultOrder: 2, order: 2},
  {title: 'Shipper', defaultOrder: 3, order: 3},
  {title: 'Asset', defaultOrder: 4, order: 4},
  {title: 'Driver', defaultOrder: 5, order: 5},
  {title: 'Price (INR)', defaultOrder: 6, order: 6},
  {title: 'Created ON', defaultOrder: 7, order: 7}
]
let ManageColumnShipper = [
  {title: 'Status', defaultOrder: 1, order: 1},
  {title: 'Request ID', defaultOrder: 2, order: 2},
  {title: 'Provider', defaultOrder: 3, order: 3},
  {title: 'Price (INR)', defaultOrder: 4, order: 4},
  {title: 'Created ON', defaultOrder: 5, order: 5},
]

export const ROLE: {transporter: string, shipper:string, denso: string, closedMarketplace: string } = {
  transporter: 'Transporter',
  shipper: 'Shipper',
  denso: 'ADMIN',
  closedMarketplace: 'ClosedMarketplace'
}

export default class    UserService {
  loginApi = (values: any) => {
    const uninterceptedAxiosInstance = axios.create();
    //return uninterceptedAxiosInstance.post(`${authBaseURL}/auth/login`, { email: values.email, password: values.password})
    return uninterceptedAxiosInstance.post(`${authBaseURL}/auth/login/encrypted`, { email: values.email, password: values.password})
      .then((resp: any) => {
        // gets user detail
        return this.getUserById(values.email, resp?.data?.token)
          .then((userResp: any) => {
            // gets tenant detail
            return this.getTenantById(userResp?.tenantId, resp?.data?.token)
            .then((tenantResp: any) => {
              this.setUserDetails({
                token: resp?.data?.token,
                firstName: userResp?.firstName ? userResp.firstName : '',
                lastName: userResp?.lastName ? userResp.lastName : '',
                email: userResp?.email,
                tenantId: userResp?.tenantId,
                tenantName : tenantResp?.name,
                userId: userResp?.id,
                active: userResp?.active,
                roles: tenantResp?.roles?.map((role: any) => role.name),
                roleName: tenantResp.name,
                privacyPolicyAck: userResp.privacyPolicyAck
              });
              return {
                token: resp?.data?.token,
                firstName: userResp?.firstName ? userResp.firstName : '',
                lastName: userResp?.lastName ? userResp.lastName : '',
                email: userResp?.email,
                tenantId: userResp?.tenantId,
                tenantName : tenantResp?.name,
                userId: userResp?.id,
                active: userResp?.active,
                roles: tenantResp?.roles?.map((role: any) => role.name),
                roleName: "",
                privacyPolicyAck: userResp.privacyPolicyAck
              }
            })
          })
      })
      .catch((err: any) => {
        console.error("Error in authentication. Maybe invalid credentials.", err);
      })
    /* 
    below code is left-out by sarath on 05.10.2021
    that code might be used in unit-test cases.
    */
    return new Promise( (resolve, reject) => { // todo: remove this during API integration
      const user: {email: string, password: string, role: string, token: string} = {
        email: values.email,
        password: values.password,
        role: '',
        token: ''
      }
      if(values.email === 'trans@denso.com' && values.password === 'Trans@123') {
        coldchainLog('valid credentials');
        user.role = ROLE.transporter; // will be received from API
        user.token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBzYXNrZW4uY29tIiwiUk9MRVMiOiJBRE1JTiIsIlVTRVJJRCI6IjM1M2RhZWJlLTFiNDEtNDg0Yi1iNzJlLTEwNjk0MzcwNjAxZiIsImV4cCI6MTYzMzU0MTgwNywiVEVOQU5UIjoiMTc3MzRhY2EtZWNmZi00NDNjLTkyNjctMGVmMTQ1ZmZlMWJmIiwiaWF0IjoxNjMzNTIzODA3fQ.Dsyt97VMp3-Pz9Ilf6d35pcagVJ3-DiIYqrqWxTdRFcool2_IpB3utKSE7RpxmjPnpvRg-xh610CkmXjoNIpsw'; // will be received from API
        this.setUserDetails(user);
        resolve(user);
      } else if (values.email === 'ship@denso.com' && values.password === 'Ship@123') {
        coldchainLog('valid credentials');
        user.role = ROLE.shipper; // will be received from API
        user.token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBzYXNrZW4uY29tIiwiUk9MRVMiOiJBRE1JTiIsIlVTRVJJRCI6IjM1M2RhZWJlLTFiNDEtNDg0Yi1iNzJlLTEwNjk0MzcwNjAxZiIsImV4cCI6MTYzMzU0MTgwNywiVEVOQU5UIjoiMTc3MzRhY2EtZWNmZi00NDNjLTkyNjctMGVmMTQ1ZmZlMWJmIiwiaWF0IjoxNjMzNTIzODA3fQ.Dsyt97VMp3-Pz9Ilf6d35pcagVJ3-DiIYqrqWxTdRFcool2_IpB3utKSE7RpxmjPnpvRg-xh610CkmXjoNIpsw'; // will be received from API
        this.setUserDetails(user);
        resolve(user);
      } else if (values.email === 'denso@denso.com' && values.password === 'Denso@123') {
        coldchainLog('valid credentials');
        user.role = ROLE.denso; // will be received from API
        user.token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBzYXNrZW4uY29tIiwiUk9MRVMiOiJBRE1JTiIsIlVTRVJJRCI6IjM1M2RhZWJlLTFiNDEtNDg0Yi1iNzJlLTEwNjk0MzcwNjAxZiIsImV4cCI6MTYzMzU0MTgwNywiVEVOQU5UIjoiMTc3MzRhY2EtZWNmZi00NDNjLTkyNjctMGVmMTQ1ZmZlMWJmIiwiaWF0IjoxNjMzNTIzODA3fQ.Dsyt97VMp3-Pz9Ilf6d35pcagVJ3-DiIYqrqWxTdRFcool2_IpB3utKSE7RpxmjPnpvRg-xh610CkmXjoNIpsw'; // will be received from API
         this.setUserDetails(user);
        resolve(user);
      }else {
        reject(Error("Email or password is incorrect. Kindly provide correct credentials."))
      }
    });
  }
  privacyPolicy = async(id: string, token: string) => {
    const uninterceptedAxiosInstance = axios.create();
    return await uninterceptedAxiosInstance.post(`${tenantandUser}/users/postLogin`,{"privacyPolicyAck": true}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
  getUserById = (id: string, token: string) => {
    const uninterceptedAxiosInstance = axios.create();
    return uninterceptedAxiosInstance.get(`${tenantandUser}/users/detail`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((resp: any) => {
        return resp.data;
      })
      .catch((err: any) => {
        console.error("Error in getting User Data.", err);
        return err;
      });
  }

  getTenantById = (id: string, token: string) => {
    const uninterceptedAxiosInstance = axios.create();
    return uninterceptedAxiosInstance.get(`${tenantandUser}/tenants/${encodeURIComponent(id)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((resp: any) => {
        return resp.data;
      })
      .catch((err: any) => {
        console.error("Error in getting Tenant Data.", err);
        return err;
      });
  }

  getTenants = () => {
    const uninterceptedAxiosInstance = axios.create();
    return uninterceptedAxiosInstance.get(`${tenantandUser}/tenant/rolename/ClosedMarketplace`, {
      headers: {
        'Authorization': `Bearer ${getToken().token}`
      }
    })
      .then((resp: any) => {
        return resp.data;
      })
      .catch((err: any) => {
        console.error("Error in getting Tenant Data.", err);
        return err;
      });
  }

  setUserDetails = (user: any) => {
      const userToken = {
          email: user.email,
          roles: user.roles,
          token: user.token,
          tenantId: user.tenantId,
          tenantName: user.tenantName,
          userId: user.userId,
          firstName: user?.firstName,
          lastName: user?.lastName,
          roleName: user?.roleName,
      }
      sessionStorage.setItem('token', JSON.stringify(userToken));
      sessionStorage.setItem('ApplyFilter', null);
      sessionStorage.setItem('roleName', userToken.roleName);
      if(user.roles[0] === "ADMIN"){
        sessionStorage.setItem('ManageColumn', JSON.stringify(ManageColumnDenso))
      }
      else if(user.roles[0] === "Transporter"){
        sessionStorage.setItem('ManageColumn', JSON.stringify(ManageColumnTransporter))
      }
      else{
        sessionStorage.setItem('ManageColumn', JSON.stringify(ManageColumnShipper))
      }
      sessionStorage.setItem('ManageColumnOrder', "false");
      sessionStorage.setItem('fetchTableFlag', "true");
  }

  getUserDetails = () => {
      const tokenString = sessionStorage.getItem('token');
      const userToken = tokenString ? JSON.parse(tokenString) : null;
      return userToken;
  }

  logout = (history: any) => {
      sessionStorage.removeItem('ApplyFilter')
      sessionStorage.removeItem('ManageColumn')
      sessionStorage.removeItem('ManageColumnOrder')
      sessionStorage.removeItem('fetchTableFlag')
      sessionStorage.removeItem('token');
      history.push('/');
  }

  checkTokenExpiration = (token: string) => {
    var exp:any = jwt_decode(token)
    if(exp.exp * 1000 < Date.now()){
      return true
    }
    else{
      return false
    }
  }
  getTenantss = (Dates: any) => {

    return axios.get(tenantandUser + '/tenants?' + Dates).then(res => {
        return res.data;
    }).catch((err: any) => {
      console.error('Fail', err.response )
    
    })
}
getTenantsx = (Dates: any) => {

  return axios.get(tenantandUser + '/tenantRoles?' + Dates).then(res => {
      return res.data;
  }).catch((err: any) => {
    console.error('Fail', err.response )
  
  })
}
  
  updatePassword =(headers:any,data:any)=>{
    return axios.post(`${authBaseURL}/auth/updatePassword`,data,{headers}).then((res:any)=>{
          return res.data
    }).catch((err: any) => {
      console.error('Fail', err.response )
    
    })
  }

  changePassword = (headers:any,data:any) => {
    return axios.post(`${authBaseURL}/auth/changePassword`,data,{headers}).then((res:any)=>{
      return res.data
    }).catch((err: any) => {
      console.error('Fail', err.response )
    
    })
  }

  update =(token:any)=>{
    return token
  }
}