import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

const ShippersTransporterRequest = (props: any) => {

    const Headingstyle: any = {
        textAlign:'left',
        marginLeft:'1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: '14px',
        color: "#000000DE"
    }
    const TableHeadStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "0.7rem",
        color: "#00000",
        paddingLeft:'10px',
        fontWeight: "normal"
    }
    const TableRowStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "1rem",
        fontWeight: 400,
        color: "#000000DE",
        paddingLeft:'10px',
    }
    

    return (
        <div style={{ height: '470px', overflowY: 'auto' }}>
            <div style={Headingstyle}>Shippers - Transporter and Requests</div>
             {props.noData !== '' ? <div style={{paddingTop:'30px'}}>{props.noData}</div> :
             <div >
            
            <TableContainer>
                <Table aria-label="simple table"  style={{width:"94%",marginLeft: "15px"}}>
                    <TableHead style={{ backgroundColor: '#eff2f6'}}>
                        <TableRow>
                            <TableCell style={TableHeadStyle} align="justify" >Shipper</TableCell>
                            <TableCell style={TableHeadStyle} align="justify">Transporter</TableCell>
                            <TableCell style={TableHeadStyle} align="right">Requests({props.totalRequestCount})</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{fontSize:"20px",padding:'15px'}}>
                        {props.requestCountList.map((data: any, index: any) => (
                             <TableRow
                                key={data.index} className={"brBottom"}  data-testid='row'                       
                            >
                                <TableCell style={TableRowStyle} align="justify">{props.shipperList[index]}</TableCell>
                                <TableCell style={TableRowStyle} align="justify">{props.transporterList[index]}</TableCell>
                                <TableCell style={TableRowStyle} align="right">{data}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>}
        </div>
    )
}

export default ShippersTransporterRequest