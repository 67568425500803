import React from 'react';
import Plot from 'react-plotly.js';

const AcceptedVsNotAccepted = (props: any) => {

    const Headingstyle: any = {
        textAlign: 'left',
        marginLeft: '1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight: "normal",
        fontSize: '14px',
        color: "#000000DE"
    }

    const state = props.acceptedVsNotAccepted

    const total = props.totalAcceptedVsNotAccepted
    const noData = props.noDataTransporter

    return (
        <div>
            <div style={Headingstyle}>Requests Accepted Vs Not Accepted</div>
            {noData !== '' ? <div style={{height:'330px'}}>{noData}</div> :
                <Plot
                    data={[
                        state,
                    ]}

                    layout={{
                        margin: { l: 50, r: 30, b: 40, t: 40 },
                        annotations: [
                            {
                                showarrow: false,
                                text: (total + '').bold() + '<br>Total Requests',
                                font: {
                                    family: 'Roboto Medium',
                                    size: 14
                                }
                            },
                        ],
                        showlegend: false,
                        height: 330
                    }}
                    style={{ maxWidth: '500px', maxHeight: '330px' }}
                    config={{ displayModeBar: false, responsive: true, displaylogo: false }}
                />
            }
        </div>
    )
}

export default AcceptedVsNotAccepted