import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Skeleton } from "@material-ui/lab";


export default function DashboardTopFiveTranporter(props: any) {

    const rowData = props.top5RowData
    const totalRequest = props.top5TotalRequest

    const Headingstyle: any = {
        textAlign: 'left',
        marginLeft: '1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        
        fontSize: '14px',
        color: "#000000DE"
    }
    const TableHeadLeftStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "0.7rem",
        fontWeight:"normal",
        color: "#000000DE"
    }
    const TableHeadRightStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "0.7rem",
        fontWeight:"normal",
        color: "#000000DE",
        textAlign: "right"
    }
    const TableRowLeftStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "1rem",
        fontWeight: 400,
        color: "#000000DE"
    }
    const TableRowRightStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "1rem",
        fontWeight: 400,
        color: "#000000DE",
        textAlign: "right"
    }

    return (
        <div style={{ overflow: 'auto', minHeight: "395px" }}>
            <div style={Headingstyle}>Top 5 Transporters</div>
            {props.noData !== '' ? <div  style={{color:"#000",fontSize:"14px"}}>{props.noData}</div> :
            <TableContainer>
                <Table aria-label="simple table"  style={{width:"94%",marginLeft: "15px"}}>
                    <TableHead style={{ backgroundColor: '#eff2f6'}}>
                        <TableRow>
                        <TableCell style={TableHeadLeftStyle} align="justify" >#</TableCell>
                            <TableCell style={TableHeadLeftStyle} align="justify" >Transporters</TableCell>
                            <TableCell style={TableHeadRightStyle} align="justify" aria-label='Request Served'>
                                Request Served ({totalRequest !== 0 ? totalRequest : "---"})
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ fontSize: "20px" }}>
                        {
                            rowData.length !== 0 ? rowData.map((data: any, index: any) => {
                                return (
                                    <TableRow
                                        key={data.index} data-testid='row' className={"brBottom"}
                                    >
                                        
                                        <TableCell style={TableRowLeftStyle} align="justify">{index+1}</TableCell>
                                        <TableCell style={TableRowLeftStyle} align="justify">{data.name}</TableCell>
                                        <TableCell style={TableRowRightStyle} align="justify">{data.value}</TableCell>
                                    </TableRow>
                                )
                            }) :
                                [0, 1, 2, 3, 4].map(() => {
                                    return (
                                        <TableRow className={"brBottom"}>
                                            <TableCell style={TableRowLeftStyle} align="justify"><Skeleton variant="text" /></TableCell>
                                            <TableCell style={TableRowRightStyle} align="justify"><Skeleton variant="text" /></TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}