import { useEffect, useState } from "react";
import { createStructuredSelector } from "reselect";
import { setCurrentUser } from "../../../../redux/user/user.action";
import { selectCurrentUser } from "../../../../redux/user/user.selector";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import { ResponsiveDrawer } from "../../common/Drawer";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TableManageColumnsFormData } from "../../Dashboard/Common/Interfaces/custom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Paper,
  Typography,
} from "@mui/material";
import MarketPlaceAPI from "../../../../services/marketPlaceService";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  table: {
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "100%",
    "@media (max-width:700px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      width: "100%",
    },
  },
  tableCell: {
    "@media (max-width:700px)": {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
});

export function ViewPendingTransfers(props: any) {
  const Headingstyle1: any = {
    textAlign: "left",
    marginLeft: "1.2rem",
    marginBottom: "2rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "1rem",
    color: "#000000DE",
  };
  const headingStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "1.5rem",
    color: "#000000DE",
  };

  const [searchReqId, setSearchReqId] = useState<string>("");
  const classes = useStyles();
  let { currentUser, setCurrentUser } = props;
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [rows, setRowData] = useState([]);
  const [dataLoaderFlag, setDataLoaderFlag] = useState("Loading");
  const marketApi = new MarketPlaceAPI();

  const CustomTablePagination: any = withStyles((theme: any) => ({
    root: {
      //   '& .MuiTablePagination-spacer': {
      //     flex: 'none', // Ensure the spacer doesn't grow and push the arrows
      //   },
      "& .MuiTablePagination-actions": {
        // Add any other styles as needed
        display: "flex",
        marginTop: "-7px",
      },
      "& .MuiTablePagination-input": {
        top: -6,
      },
      "& .MuiMenu-list": {
        color: "#000000",
        opacity: 0.8,
      },
      "& .MuiMenuItem-root": {
        "& .MuiTablePagination-menuItem": {
          color: "black",
          opacity: 0.8,
          "&:hover": {
            background: "#000000", // Background color on hover
          },
        },
      },
      select: {
        background: "#fff",
        color: "#000",
        "&:hover": {
          background: "#ddd",
        },
      },

      //   '& input': {
      //     background: '#fff', // Background color
      //     color: 'black', // Text color
      //     padding: theme.spacing(0.5), // Adjust padding as needed
      //     borderRadius: theme.shape.borderRadius, // Apply border radius from theme
      //   },
      //   '& .MuiTablePagination-icon': {
      //     // Add your styles for the arrows
      //     color: 'red', // Example: Change arrow color to red
      //   },
    },
  }))(TablePagination);

  const success = (msg: any) => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>{msg}</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const showError = (msg: any) => {
    toast.warning(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#FF4F14",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>{msg}</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  useEffect(() => {
    getPendingTransferRequests();
  }, []);

  async function getPendingTransferRequests() {
    try {
      const response = await marketApi.getPendingTransferRequests();
      if (response.length > 0) {
        setRowData(response);
      } else {
        setRowData([]);
        setDataLoaderFlag("No Data");
      }
      // const data = await response.json();
      console.log(response); // Handle the data as needed
    } catch (error) {
      showError("Error Fetching Pending Transfer Requests");
      console.error("Error fetching data:", error);
    }
  }

  // required to handle multiple selection - dont remove

  //   const handleSelectAllClick = (event: any) => {
  //     const newSelected = event.target.checked
  //       ? rows
  //           .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //           .map((row) => row.id)
  //       : [];

  //     setSelected(newSelected);
  //   };

  const handleClick = (event: any, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = [id];
    } else {
      newSelected = [];
    }

    setSelected(newSelected);
  };

  // used to handle multiple selections - dont remove

  //   const handleClick = (event: any, id: any) => {
  //     const selectedIndex = selected.indexOf(id);
  //     let newSelected: any[] = [];

  //     if (selectedIndex === -1) {
  //       newSelected = newSelected.concat(selected, id);
  //     } else if (selectedIndex === 0) {
  //       newSelected = newSelected.concat(selected.slice(1));
  //     } else if (selectedIndex === selected.length - 1) {
  //       newSelected = newSelected.concat(selected.slice(0, -1));
  //     } else if (selectedIndex > 0) {
  //       newSelected = newSelected.concat(
  //         selected.slice(0, selectedIndex),
  //         selected.slice(selectedIndex + 1)
  //       );
  //     }

  //     setSelected(newSelected);
  //   };
  // }
  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const onApproveClick = async () => {
    const payload = {
      transferId: selected[0],
      transferStatus: "Approved",
    };
    try {
      const response = await marketApi.approvePendingRequest(payload);
      success("Transfer Request Approved Successfully for " + selected[0]);
      setSelected([]);
      getPendingTransferRequests();
    } catch (error) {
      showError("Error Approving Transfer Request");
    }
  };

  return (
    <Box sx={{ display: "flex", marginLeft: "-110px", marginTop: "-10px" }}>
      <ResponsiveDrawer
        setSearchReqId={setSearchReqId}
        disableSearch={true}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
      />
      <Box component={"main"} sx={{ flexGrow: 1, p: 3 }}>
        <div style={headingStyle}>
          <div>Trip Management</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link
              to={{ pathname: "/denso" }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="outlined"
                style={{
                  width: "90px",
                  height: "40px",
                  backgroundColor: "rgb(0, 122, 166)",
                  textTransform: "capitalize",
                  marginRight: "1rem", // Add margin for spacing
                }}
              >
                <span style={{ color: "white", fontSize: "15px" }}>Back</span>
              </Button>
            </Link>
            <Button
              variant="outlined"
              style={{
                width: "90px",
                height: "40px",
                backgroundColor: "rgb(0, 122, 166)",
                textTransform: "capitalize",
              }}
              onClick={onApproveClick}
              disabled={selected && selected.length === 0}
            >
              <span style={{ color: "white", fontSize: "15px" }}>Approve</span>
            </Button>
          </div>
        </div>
        <div style={Headingstyle1}>View Pending Transfers</div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <Checkbox
                  checked={selected.length === 1}
                  onChange={() => {}}
                  inputProps={{ "aria-label": "disabled checkbox" }}
                  disabled={selected.length !== 0}
                />
                {/* for multiple selections - dont remove */}
                {/* <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all items" }}
                /> */}
                <TableCell>Shipping Request Number</TableCell>
                <TableCell align="right">From Phone Number</TableCell>
                <TableCell align="right">To Phone Number</TableCell>
                <TableCell align="right">Approval Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="subtitle1">
                      {dataLoaderFlag}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.transferId}
                      hover
                      onClick={(event) => handleClick(event, row.transferId)}
                      role="checkbox"
                      aria-checked={isSelected(row.transferId)}
                      tabIndex={-1}
                      selected={isSelected(row.transferId)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected(row.transferId)} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.shippingRequestNumber}
                      </TableCell>
                      <TableCell align="right">{row.fromPhNo}</TableCell>
                      <TableCell align="right">{row.transferToPhNo}</TableCell>
                      <TableCell align="right">
                        {row.transferApprovalStatus}
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            alignSelf: "flex-end",
            marginRight: "16px", // Adjust the margin as needed
          }}
        />
      </Box>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ViewPendingTransfers);
