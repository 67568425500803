import React from 'react';
import Plot from 'react-plotly.js';

const Transporters = (props: any) => {

    const Headingstyle: any = {
        textAlign:'left',
        marginLeft:'1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '14px',
        color: "#000000DE"
    }

    var Onboarded: any = {
        x: props.xaxis,
        y: props.onBroad,
        width: 0.5,
        type: 'bar',
        name: 'Dropped',
        marker: {
            color: '#7B1521'
        }
    }

    var Active: any = {
        x: props.xaxis,
        y: props.active,
        width: 0.5,
        type: 'bar',
        name: 'Active',
        marker: {
            color: '#E20B27'
        }
    }

    var Inactive: any = {
        x: props.xaxis,
        y: props.inactive,
        width: 0.5,
        type: 'bar',
        name: 'Inactive',
        marker: {
            color: '#D6B4C5'
        }
    }

    return (
        <div style={{height:'370px'}}>
     
            <div style={Headingstyle}>Transporters</div>
            {props.noData !== '' ? <div style={{paddingTop:'30px'}}>{props.noData}</div> :
            <Plot
                data={[Inactive, Active, Onboarded]}
                layout={
                    {
                        margin: { l: 30, r: 30, b: 40, t: 20 },
                        xaxis: {
                            tickfont: {
                                family: 'Roboto',
                                size: 14
                            }
                        },
                        yaxis: {
                            tickfont: {
                                family: 'Roboto',
                                size: 14
                            }
                        },
                        barmode: 'relative',
                        height: 300,
                        legend: {
                            x: 0.2,
                            y: -0.8,
                            orientation: 'h'
                        }
                    }}
                style={{ maxWidth: '36rem',marginLeft:"10px" }}
                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
            />
                }
       
        </div>
    )
}

export default Transporters