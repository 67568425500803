import { CardContent ,Card} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {  Col, Container, Row } from 'react-bootstrap';
import ProgressBar from './progressbar';
import './style.css';
import { FeedbackStatus } from '../../../../services/_helpers';
import ShipperAPI from '../../../../services/shippingLanding';

export function ConsumerFeedback  (props: any) {
 
  const [feedback,setFeedback]=useState([]);
  const [ratings,setRatings]=useState([]);
  useEffect(()=>{
   
    const shippingLanding = new ShipperAPI();
    shippingLanding.getFeedbackByID(props.id).then((res:any)=>{
      setFeedback(res.data.feedback);
      setRatings(res.data.ratings);
    }).catch((err:any)=>{
      console.error(err)
    })
  },[])

  return (
    <div>
      <div className="wrapper">
        <div className="item1">
        <Container style={{marginLeft:0, marginRight: 0, maxWidth: "1400px"}}>
        <Row>
          <Col>
          <Card  variant="outlined" style={{border:"1px solid white"}}>
            <CardContent>
              <div className='container1'>
                <div className='row'>
                  <div className='col'>
                  <p style={{color:"#000"}}>Comments</p>
                  <textarea id="floatingTextarea2" rows={4} value={props?.comments?.[0]?.comment || ""} disabled 
style={{height:"60px",width:"200px",backgroundColor:"#fff",border:"1px solid #d1cfcf",borderRadius:"4px"}}/>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Col>
         
        </Row>
        </Container>
      
          </div>
        {/* <div className="item2">
        <Card style={{height:"14.8rem",border:"1px solid #fff"}} variant="outlined">
            <CardContent style={{textAlign:'center'}}>
            <p style={{fontSize:"14px",textAlign:'center',color:'#000'}}>Freshness Index</p>
            <ProgressBar avgScore={props.avgScore}/> */}
            {/*<br/><br/><br/><br/><br/> */}
      {/* <p style={{fontWeight:700,textAlign:'center',}}>Fresh</p> */}
      {/* */}


        
            {/* </CardContent>
            
          </Card>
      
        </div> */}
     

</div>
    </div>
  );
}
