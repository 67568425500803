import img from '../../../../../../assets/images/FreshnessScore.png'
import './freshnessScore.css'
import StarRatings from 'react-svg-star-rating'
import Plot from 'react-plotly.js'
// import GaugeChart from 'react-gauge-chart'
// import GaugeChart from 'react-gauge-chart'
const TripsVsAvgFreshnessScore = (props: any) => {

    const Headingstyle: any = {
        textAlign: 'left',
        marginLeft: '1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',

        fontSize: '1rem',
        color: "#000000DE"
    }

    const Totaltrip: any = {
        textAlign: 'center',
        fontFamily: 'Roboto',

        fontSize: '1rem',
        color: "#000000DE",
        paddingBottom: "10px"
    }

    const tripcount = props.tripcount
    const avgScore = Math.trunc(props.avgScore)

    return (
        <div>
            <div style={Headingstyle}>Trips Vs. Avg. Freshness Score</div>
            {/* <GaugeChart id="gauge-chart5"
        style={{marginTop:"12%"}}
        textColor={" #000"}
  nrOfLevels={420}
  arcsLength={[0.2, 0.5, 0.5]}
  colors={['#EA4228', '#F5CD19','#5BE12C']}
  percent={0.37}
  arcPadding={0.02}
  
  needleColor={"grey"}
  needleBaseColor={"grey"}
  
/> */}
<p > Total Trips <span >{tripcount}</span>  </p> 
{/*           
            <Plot
                data={[
                    {
                        type: "indicator",
                        mode: "gauge+number+delta",
                        value: 420,
                        title: { text: "", font: { size: 24 } },
                        delta: {
                            reference: 400,
                            increasing: { 
                                symbol:"",
                                color: "#fff" }
                        },
                        gauge: {
                            axis: { range: [null, 500], tickwidth: 1, tickcolor: "darkblue" },
                            bar: { color: "rgb(190 200 224)" },
                            bgcolor: "white",
                            borderwidth: 2,
                            bordercolor: "gray",
                            steps: [
                                { range: [0, 250], color: "#fff" },
                                { range: [250, 400], color: "#fff" }
                            ],
                            threshold: {
                                line: { color: "#fff", width: 4 },
                                thickness: 0.75,
                                value: 490
                            }
                        }
                    }
                ]}
                layout={{
                    margin: { l: 30, r: 40, b: -10, t: 40 },
                    xaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 14,
                        },
                        tick0: 0,
                        rangemode: 'tozero',

                    },
                    yaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 14,
                        },
                        tick0: 0,
                        rangemode: 'nonnegative',
                        zerolinewidth: 1,
                    },
                    barmode: 'group',
                    height: 300,

                    legend: {
                        x: 0.35,
                        y: -0.7,
                        orientation: 'h'
                    },



                }}
                style={{ width: '400px', height: '100px', marginTop: "-3px" }}
                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
            /> */}

            {/* <div id="logo">
                <div className="donutcontainer" >
                    <div className="donutinner" >
                        <img className='pnglogo' src={img} />
                        <div className='needlecircle' ></div>
                        <span className='needle' style={{ ["--r" as any]:  avgScore }} ></span>                        
                        <div style={{paddingTop:'15px'}}>
               
                            <StarRatings 
                                isReadOnly 
                                unit="float" 
                                count={5} 
                                initialRating={avgScore/20} 
                                size={25} 
                                activeColor="#FFC000" 
                                emptyColor="gray"
                            />
                        </div>
                        <div style={{paddingTop:'10px', fontWeight:'bold', fontSize:'20px', color:'black'}}>{avgScore}</div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default TripsVsAvgFreshnessScore