import {
  Box,
  Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles, Table, TableBody, TableRow, Theme,
  Typography, withStyles
} from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams, } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Download from "../../../assets/images/Download.svg";
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-circular-progressbar/dist/styles.css';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MuiTableCell from "@material-ui/core/TableCell";
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SensoreData } from '../Utils/SensorData/index';
import { ConsumerFeedback } from '../Utils/ConsumerFeedback/consumerFeedback';
import { Images } from '../../components/Utils/ImageUploaded/index';
import HomeWorkOutlined from "@material-ui/icons/HomeWorkOutlined";
import { useEffect, useState } from 'react';
import { RouteMap } from "../Utils/RouteMap/index";
import ShipperHelper, { ShippingRequestStatus, timeDiff } from '../../../services/_helpers';
import MarketPlaceAPI from '../../../services/marketPlaceService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Paper from '@mui/material/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import UserService from '../../../services/userService';
import { ROLE } from '../../../../src/services/userService'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ResponsiveDrawer } from '../common/Drawer';
import { selectCurrentUser } from '../../../redux/user/user.selector';
import { setCurrentUser } from '../../../redux/user/user.action';

const TableCell = withStyles({
  root: {
    borderBottom: "none",

  }
})(MuiTableCell);

export interface IRequestDetailsProps {
  location: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

      marginLeft: '20px',
      marginTop: '1rem',
      marginRight: '48px',

    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },

    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    imageBox: {
      marginTop: '1rem'
    },
    topIconWrapper: {
      display: 'inline-block',
      backgroundColor: '#E6E6E6',
      border: '1px solid  #E6E6E6',
      transition: 'border 0.3s ease-in-out',
      borderRadius: '4px',
      marginRight: '0.55rem',
      color: '#5A5A5A',
      padding: '5px',
      '&:hover': {
        cursor: 'pointer',
        borderColor: '#c7c7c7'
      }
    }

  }),
);
export function RequestDetails(props: any) {
  const id: any = useParams();
  const classes = useStyles();
  let {   currentUser, setCurrentUser } = props;
  // console.log(props,"propsrequest")
  const [searchReqId, setSearchReqId] = useState<string>("");
  const [data, setData] = React.useState<any>(null);
  const [fullWidth,] = React.useState(true);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [tripId, setTripID] = React.useState('')
  const [Updatedisabling, setUpdatedisabling] = React.useState(false)
  const [CancelDisabling, setCancelDisabling] = React.useState(false)
  const [Roles, setRoles] = React.useState('')
  const marketApi = new MarketPlaceAPI();
  const userApi = new UserService();
  const history = useHistory();
  useEffect(() => {
    getRequestsByQuery()//row data from landing page
    const userDetails = userApi.getUserDetails();
    if (userDetails?.roles?.includes(ROLE.transporter)) {
      setRoles("transporter")
    }
    if (userDetails?.roles?.includes(ROLE.shipper)) {
      setRoles("shipper")
    }
    if (userDetails?.roles?.includes(ROLE.denso)) {
      setRoles("denso")
    }

  }, []);

  const getRequestsByQuery = () => {
    const marketApi = new MarketPlaceAPI();
    marketApi.getRequestsByRequestNumber(id['id'])
      .then((resp: any) => {
        setData(resp);
        setTripID(resp.tripId)
        let picktime = new Date(resp.expectedPickUpTime.to)
        let currentTime = new Date()
        let timelefttopickup = picktime.getTime() - currentTime.getTime()
        if (timelefttopickup > timeDiff && ShippingRequestStatus[resp.status] === ShippingRequestStatus.PendingAcceptance) {
          setUpdatedisabling(true)
        }
        else {
          setUpdatedisabling(false)
        }
        if (ShippingRequestStatus[resp.status] === ShippingRequestStatus.PendingAcceptance) {
          setCancelDisabling(false)
        }
        else {
          setCancelDisabling(true)
        }
      })
      .catch((err: any) => {
        console.error(err)
        const UserToken = new ShipperHelper()
        UserToken.UserTokenExpiration()
      });
  }
  let dateHelper = new ShipperHelper();
  const handleDelete = () => {
    data.status = ShippingRequestStatus.Cancelled;
    marketApi.cancelRequest(data)
      .then((res: any) => {

        window.history.back();
        setDeleteOpen(!deleteOpen)
      })

  }
  const handleCloseDelete = () => {
    setDeleteOpen(!deleteOpen);

  };

  const pdfGenerator = () => {

    const ShipmentDetailsPage = document.getElementById("ShipmentDetailsPage")
    const TripRoute = document.getElementById("TripRoute")

    /* istanbul ignore next*/
    html2canvas(ShipmentDetailsPage)
      .then((ShipmentDetailsPagecanvas) => {
        html2canvas(TripRoute, { useCORS: true })
          .then((TripRoutecanvas) => {
            const ShipmentDetailsPageimgData = ShipmentDetailsPagecanvas.toDataURL('image/png')
            const pdf = new jsPDF()
            const imgProps = pdf.getImageProperties(ShipmentDetailsPageimgData);
            const pdfWidthShipmentDetailsPage = pdf.internal.pageSize.getWidth();
            const pdfHeightShipmentDetailsPage = (imgProps.height * pdfWidthShipmentDetailsPage) / imgProps.width;
            pdf.addImage(ShipmentDetailsPageimgData, "PNG", 5, 10, pdfWidthShipmentDetailsPage - 10, pdfHeightShipmentDetailsPage)

            pdf.addPage()
            const TripRouteimgData = TripRoutecanvas.toDataURL('image/png')
            const pdfWidthTripRoute = pdf.internal.pageSize.getWidth();
            pdf.addImage(TripRouteimgData, "PNG", 5, 10, pdfWidthTripRoute - 10, 100)

            pdf.save(id.id + "ShipmentDetails.pdf")

          })
      })
  }

  const DensoUpdatePage = (requestNumber: any) => {
    history.push(`/densoupdateshipment/${requestNumber}`)
  }
  const ShipperUpdatePage = (requestNumber: any) => {
    history.push(`/updateshipment/${requestNumber}`)
  }


  return (
    <Box sx={{ display: 'flex',width:"98.5rem",marginTop:"-13px",marginLeft:"-115px" }}>
     <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
    <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <div className={classes.root}>
      <Dialog onClose={handleCloseDelete} aria-labelledby="customized-dialog-title" open={deleteOpen}
        maxWidth="sm" fullWidth={fullWidth}>
        <DialogTitle id="customized-dialog-title" >
          CANCEL
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure to cancel the record ?
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button data-testid='close' onClick={handleCloseDelete} color="primary">
            CLOSE
          </Button>
          <Button onClick={handleDelete} data-testid='cancelRequest' color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={5}>


          <ArrowBackIcon onClick={() => { history.goBack(); }} style={{ color: '#5A5A5A', marginTop: "2px" }} />
          <p style={{ fontSize: '14px', fontWeight: 300, marginLeft: "31px", marginTop: "-20px" }}>{id.id}</p>

        </Grid>
        <Grid item xs={7}>
          <div style={{ float: 'right' }}>
            <span>
              <div className={classes.topIconWrapper}
                style={{
                  padding: "3px", border: "1px solid #CED4D9", backgroundColor: "#fff", width: "37px", marginLeft: "-205px",
                  marginTop: "-10px", borderRadius: "4px", height: "37px", paddingTop: "0"
                }}
                data-testid='pdf' onClick={pdfGenerator}>
                <img src={Download} style={{ width: "24px", marginLeft: "2.3px", marginTop: "7px", height: "20px" }} />

              </div>
            </span>
            {
              (Updatedisabling === false) ?
                <>
                  {CancelDisabling === false ?
                    <Button variant="outlined" data-testid='cancel'
                      style={{
                        color: 'grey', border: '1px solid #CED4D9 ', backgroundColor: "#fff",
                        marginTop: "-37px", marginLeft: "-147px", textTransform: 'capitalize', height: "37px", width: "68px"
                      }} onClick={() => { setDeleteOpen(!deleteOpen) }}>
                      Cancel
                    </Button> :
                    <Button style={{
                      color: 'grey', border: '1px solid #CED4D9 ', backgroundColor: "#fff",
                      marginTop: "-37px", marginLeft: "-147px", textTransform: 'capitalize', height: "37px", width: "68px"
                    }} variant="outlined" disabled>
                      Cancel
                    </Button>
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="outlined"
                    style={{
                      color: '#fff', border: '1px solid  #007AA6', backgroundColor: "#007AA6",
                      marginTop: "-56.5px", marginLeft: "-60px", textTransform: 'capitalize', width: "68px"
                    }} disabled>
                    Update
                  </Button>
                </>
                :
                <>

                  <Button variant="outlined" style={{
                    color: 'grey', border: '1px solid #CED4D9 ', backgroundColor: "#fff",
                    marginTop: "-37px", marginLeft: "-147px", textTransform: 'capitalize', height: "37px", width: "68px"
                  }}
                    onClick={() => { setDeleteOpen(!deleteOpen) }}>
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  {(Roles === "shipper") ?
                    <>
                      <Button variant="outlined" style={{
                        color: '#fff', border: '1px solid  #007AA6', backgroundColor: "#007AA6",
                        marginTop: "-56px", marginLeft: "-60px", textTransform: 'capitalize', width: "68px"
                      }}
                        data-testid='shipperUpdate' onClick={() => ShipperUpdatePage(id['id'])}>

                        <div style={{ color: "#fff", textDecorationLine: 'none'}} >Update</div>

                      </Button>
                    </>
                    :
                    <>
                      <Button variant="outlined" data-testid='densoUpdate' style={{
                        color: '#fff', border: '1px solid  #007AA6', backgroundColor: "#007AA6",
                        marginTop: "-56px", marginLeft: "-60px", textTransform: 'capitalize', width: "68px"
                      }} onClick={() => DensoUpdatePage(id['id'])} >

                        <div style={{ color: "#fff", textDecorationLine: 'none' }} >Update</div>

                      </Button>
                    </>
                  }
                </>
            }
          </div>

        </Grid>

      </Grid>
      <Divider style={{ marginTop: "21px" }} />
      {data ?
        <>
          <div id="ShipmentDetailsPage">
            <p style={{ fontSize: '16px', marginTop: "9px" }} id="detaisTitle">Shipment Request Details</p>
            {/* {ShippingRequestStatus[data.status] === ShippingRequestStatus.PendingAcceptance ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusRed' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
            {ShippingRequestStatus[data.status] === ShippingRequestStatus.InTransit ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusOrange' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
            {ShippingRequestStatus[data.status] === ShippingRequestStatus.Accepted ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusAccepted' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
            {ShippingRequestStatus[data.status] === ShippingRequestStatus.Completed ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusGreen' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""}
            {ShippingRequestStatus[data.status] === ShippingRequestStatus.Cancelled ? <p style={{ float: 'right', marginTop: "-2rem" }} id='statusGrey' className="capitalise-case" >{ShippingRequestStatus[data.status]}</p> : ""} */}
            <Grid item xs style={{ backgroundColor: "#fff" }}>
              <Grid container spacing={1} >


                <Grid item xs>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    {/* <div className="p-2 bd-highlight">
                    <DirectionsBoatOutlinedIcon style={{  marginRight: '-1rem', color: '#828282' }} />
                  </div> */}
                    <div className="p-2 bd-highlight">
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }} >Shipper</TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">

                              {data?.shipper?.name ? data?.shipper?.name :
                                <span style={{ marginLeft: "-120px" }}>---</span>}

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>

                </Grid>

                <Grid item xs>
                  <div className="d-flex flex-row bd-highlight mb-3" style={{ marginLeft: "-140px" }}>
                    {/* <div className="p-2 bd-highlight">
                    <DateRangeIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                  </div> */}

                    <div className="p-2 bd-highlight" >
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>
                            <span style={{ marginLeft: "-20px" }}>Expected Pick-Up Time</span></TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">

                              {dateHelper.dateConversion(data?.expectedPickUpTime?.from) + "-"
                                + dateHelper.dateConversion(data?.expectedPickUpTime?.to)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                  </div>

                </Grid>
                <Grid item xs>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    {/* <div className="p-2 bd-highlight">
                    <EventAvailableIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                  </div> */}
                    <div className="p-2 bd-highlight" style={{ marginLeft: '-40px' }}>
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>Actual Pick-Up Time</TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">
                              {data?.actualPickUpTime ? dateHelper.dateConversion(data?.actualPickUpTime) :
                                <span style={{ marginLeft: "-10px" }}>---</span>}
                              {/* 07-Jul-21 04:40pm */}

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                  </div>

                </Grid>
                <Grid item xs>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    {/* <div className="p-2 bd-highlight">
                    <DateRangeIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                  </div> */}
                    <div className="p-2 bd-highlight" style={{ marginLeft: "-77px" }}>
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>
                            <span style={{ marginLeft: "-5px" }}>Expected Delivery Time</span></TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">
                              {dateHelper.dateConversion(data?.expectedDeliveryTime?.from) + "" + "-" + ""
                                + dateHelper.dateConversion(data?.expectedDeliveryTime?.to)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                  </div>

                </Grid>
               
                <Grid item xs>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    {/* <div className="p-2 bd-highlight">
                    <EventAvailableIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                  </div> */}
                    <div className="p-2 bd-highlight">
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>Actual Delivery Time</TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">
                              {/* 07-Jul-21 05:26pm */}
                              {data?.actualDeliveryTime ? dateHelper.dateConversion(data?.actualDeliveryTime) :
                                <span style={{ marginLeft: "-14px" }}>---</span>}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>

                </Grid>
                <Grid item xs>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    {/* <div className="p-2 bd-highlight">
                    <FontAwesomeIcon icon={faTachometerAlt} style={{  marginRight: '-4rem', color: '#828282' }} />
                  </div> */}
                    <div className="p-2 bd-highlight">
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>
                            <span style={{ marginLeft: '-20px' }}>Capacity</span></TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">
                              <span style={{ color: '#00000099', display: 'inline-block', marginLeft: '-10px' }}>
                                {data?.indicativeCapacity?.volume} &nbsp;Ltr&nbsp;&nbsp;&nbsp;
                                ~ &nbsp;&nbsp;
                                <li style={{ color: '#00000099', display: 'inline-block' }}>
                                  {data?.indicativeCapacity?.weight} &nbsp;Kg
                                </li>

                              </span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>

                </Grid>

              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    {/* <div className="p-2 bd-highlight">
                    <LocalShippingOutlinedIcon style={{  marginRight: '-1rem', color: '#828282' }} />
                  </div> */}
                    <div className="p-2 bd-highlight">
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }} >Provider</TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell>

                              {data?.transporter?.name ? data?.transporter?.name : <span style={{ marginLeft: "-18px" }}>---</span>}

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </Grid>
                <Grid item xs md={2}>
                  <div className="d-flex flex-row bd-highlight mb-3" >
                    {/* <div className="p-2 bd-highlight" style={{ color: "grey", fontSize: '1.3rem' }}>
                    < HomeWorkOutlined style={{   marginRight: '-5rem', color: '#828282' }} />
                  </div> */}

                    <div className="p-2 bd-highlight" style={{ marginLeft: '-149px' }}>
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }} >
                            <span style={{ marginLeft: "-66px" }} >Source Details</span></TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell >
                              {data?.fromLocation?.doorNoAndBuildingName},
                              {data?.fromLocation?.streetAddress}                  {data?.fromLocation?.city}-
                              {data?.fromLocation?.pincode}

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>

                </Grid>
                <Grid item xs md={2}>
                  <div className="d-flex flex-row bd-highlight mb-3" style={{ paddingLeft: '5px' }}>
                    {/* <div className="p-2 bd-highlight">
                    <i className="fa fa-money" aria-hidden="true" style={{  marginRight: '-5rem', color: '#828282' }} ></i>


                  </div> */}
                    <div className="p-2 bd-highlight" style={{ marginLeft: '3px' }}>
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>Price</TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row" align="left">
                              {data?.paymentDetails?.actualAmount ? "₹" + data?.paymentDetails?.actualAmount :
                                <span style={{ marginLeft: "-4px" }}>---</span>}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                  </div>

                </Grid> 
                <Grid item xs md={2}>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    {/* <div className="p-2 bd-highlight">
                    <HomeOutlinedIcon style={{  marginRight: '-5rem', color: '#828282' }} />
                  </div> */}

                    <div className="p-2 bd-highlight" style={{ marginLeft: "-68px" }}>
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>
                            <span style={{ marginLeft: '-10px' }}>Destination Details</span></TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">


                              {data['toLocation']['doorNoAndBuildingName']}, 
                              {data['toLocation']['streetAddress']}  {data['toLocation']['city']}-{data['toLocation']['pincode']}

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                  </div>

                </Grid>
              
                <Grid item xs md={2}>
                  <div className="d-flex flex-row bd-highlight mb-3">

                    <div className="p-2 bd-highlight" style={{marginLeft:"14px"}}>
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500}}>Payment Status</TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">

                              {data?.paymentDetails?.paidOn ?
                                <>
                                  <span style={{ marginLeft: '-1px' }}>Paid</span> &nbsp;
                                  < CheckCircleIcon style={{ fontSize: '15px', color: 'limegreen' }} />
                                </>
                                : <span style={{ marginLeft: '-1px' }}>Pending</span>}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                  </div>

                </Grid>
                <Grid item xs>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                      <Table size="small" >
                        <TableRow>
                          <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>
                            <span style={{ marginLeft: '12px' }}>Paid On</span></TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row" >
                              {dateHelper.dateConversion(data?.paymentDetails?.paidOn)}

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>

                </Grid>
              </Grid>

            </Grid>



            <p style={{ fontSize: '16px', marginTop: '17px' }}>
              Delivery Info</p>


            <ConsumerFeedback id={data.id} avgScore={data?.freshnessIndex} comments={data?.comments} />


            <Grid container spacing={3} className={classes.imageBox}>
              <Grid item xs={12}>

                <Paper variant="outlined" square style={{ height: '170px',  marginLeft: "7px", border: "1px solid #fff", marginTop: "-5px",marginRight:"10px" }}>
                  <p style={{ fontSize: '16px', marginTop: '8px', marginLeft: '14px',color:"#000" }}>Images Uploaded</p>
                  <Images id={data.id} imageUploads={data.imageUploads} />
                </Paper>
              </Grid>
            </Grid>

            <p style={{ fontSize: '16px', marginTop: '18px', marginLeft: '-4px'}}>Sensor Data</p>
            <SensoreData tripId={tripId} assetId={id['assetId']} dataId={data.id} />

          </div>
          <Grid item xs={12} md={12} lg={12} >
            <div id="TripsVsAvgFreshnessScorePDF"  style={{color:"#000",fontSize:"14px"}}>
            <p style={{ fontSize: '16px', marginTop: '25px', marginLeft: '-4px'}}>Trip Route</p>
            <RouteMap tripId={data.tripId} assetId={id['assetId']} actualPickUpTime={data.actualPickUpTime} />
            </div>
        </Grid>
        </> : ""}
    </div>
    </Box>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(RequestDetails  );