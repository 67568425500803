import moment from 'moment';

export function dateCalculator(filter: any, fromAndTo: any) {
    let fromValue;
    let toValue;
    if(filter === "custom") {
      let date = fromAndTo.split('=')
      fromValue = date[1].split('&')[0]
      toValue = date[2]
    }
    let fromDate: any = filter === 'daily' ? moment().subtract(1, 'days'): filter === 'weekly' ? moment().subtract(7, 'days'): filter === 'monthly' ? moment().subtract(30, 'days'): filter === 'custom' ? moment(fromValue) : moment().subtract(1, 'days');
    let toDate: any = filter === 'custom' ? moment(toValue) : moment();
    let parsedFromDate = moment(fromDate).utc();
    fromDate = parsedFromDate.format("YYYYMMDDHHmm")
    let parsedToDate = moment(toDate).utc()
    toDate =  parsedToDate.format("YYYYMMDDHHmm")
    return {
        fromDate,
        toDate
    }
}