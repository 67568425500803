
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import FilterListIcon from '@material-ui/icons/FilterList';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import {  Tab, Tabs } from 'react-bootstrap';

import TransportRequestCompleted from './CompletedRequest/index';
import './style.css';
import TransporterRequestactive from './TransporterRequestActive';
import Badge from '@mui/material/Badge';
import React from 'react';
import { Box, Popover, Tooltip } from '@material-ui/core';
import TableFilter from '../common/TableFilter';
import { useEffect, useRef, useState } from 'react';
import {CSVLink} from "react-csv";
import TableManageColumns from '../common/TableManageColumn/columnReorder';
import MarketPlaceAPI from '../../../services/marketPlaceService';
import UserService from '../../../services/userService';
import moment from 'moment';
import Helper, { ShippingRequestStatus } from '../../../services/_helpers';
import { TableManageColumnsFormData } from '../Dashboard/Common/Interfaces/custom';
import ShipperHelper from '../../../services/_helpers';
import { createStructuredSelector } from 'reselect';
import { setCurrentUser } from '../../../redux/user/user.action';
import { selectCurrentUser } from '../../../redux/user/user.selector';
import { connect } from 'react-redux';
import { ResponsiveDrawer } from '../common/Drawer';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginRight:'50px',
      marginLeft:'20px'
    },
    paper: {
      padding: theme.spacing(2),
      
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:'24px',
        float:'left',
        
    },
    disableExportIcon: {
      color: '#999999',
      cursor: 'progress'
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor:'#6222EE',
        color:'#fff',
        '&:hover':{
            backgroundColor:'#6222EE',
            color:'#fff',
        }
      },
      tableWidth:{
          width:'100%',
         
      }
  }),
);
export interface IShipperLandingProps {
  searchReqId: string
}

export  function ShipperLanding (props: any) {
    const { searchReqId } = props;
    let {   currentUser, setCurrentUser } = props;
    const [searchReqId1, setSearchReqId1] = useState<string>("");
    const classes = useStyles();
    const [select] = React.useState("active");
    const [anchorElFilterPopOver, setAnchorElFilterPopOver] = React.useState(null);
    const [filterOptions, setFilterOptions] = React.useState(JSON.parse(sessionStorage.ApplyFilter));
    const [userName, setUserName] = useState<string>('user-error');
    const [dataToDownload, setDataToDownload] = useState<any>([
      ["firstname", "lastname", "email"],
      ["Ahmed", "Tomi", "ah@smthing.co.com"],
      ["Raed", "Labes", "rl@smthing.co.com"],
      ["Yezzi", "Min  l3b", "ymin@cocococo.com"]
    ]);
    const [clickedExport, setClickedExport] = useState<boolean>(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [anchorElManageColumnsPopOver] = useState(null);
    // const [manageColumnsOptions, setManageColumnsOptions] = useState<TableManageColumnsFormData[]>([
    //   {title: 'STATUS', defaultOrder: 1, order: 1},
    //   {title: 'REQUEST ID', defaultOrder: 2, order: 2},
    //   {title: 'SHIPPER', defaultOrder: 3, order: 3},
    //   {title: 'ASSET', defaultOrder: 4, order: 4},
    //   {title: 'DRIVER', defaultOrder: 5, order: 5},
    //   {title: 'PRICE (INR)', defaultOrder: 6, order: 6},
    //   {title: 'CREATED ON', defaultOrder: 7, order: 7}
    // ]);
    const [manageColumnsOptions, setManageColumnsOptions] = useState<TableManageColumnsFormData[]>(JSON.parse(sessionStorage.ManageColumn));
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>();
    const helper = new Helper();

    const [keys, setKeys] = useState('activeRequest')
    const handleChangeKey = (k:any) => {
      setKeys(k)
    }
    
    const handleExportIconClick = (event?: any) => {
      // let isActive = true; // if required get from tab selection commented by sarath on 27.09.2021
      if(!exportLoading) {
        setExportLoading(true);
        let marketApi = new MarketPlaceAPI();
        /* const reqStatus = isActive ? [
          helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
          helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
          helper.getStatusKeyByValue(ShippingRequestStatus.InTransit)
        ] : [
          helper.getStatusKeyByValue(ShippingRequestStatus.Completed)
        ];
        commented by sarath on 27.09.2021 - if export should be dependent on tab selection, this will be used.
        */
        const allStatuses = [
          helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
          helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
          helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
          helper.getStatusKeyByValue(ShippingRequestStatus.Completed),
          helper.getStatusKeyByValue(ShippingRequestStatus.Cancelled)
        ];
        marketApi.getRequestsByQuery(0, 1000, 'DESC', { status: allStatuses})
          .then((data: any) => {
            let columnsData:any = []
            manageColumnsOptions.forEach((item:any) => {
              columnsData = [...columnsData, item.title]
            })
            setUserName((new UserService()).getUserDetails().email.split('@')[0]);
            // let mappedData = [["Status", "Request ID", "Provider", "Asset", "Driver", "Price (INR)", "Created On"]];
            let mappedData = [columnsData];
            data.data.forEach((item: any) => {
              let DataToPush:any = []
                /* istanbul ignore next */
              for (let index = 0; index < columnsData.length; index++) {                
                if(columnsData[index] === "STATUS"){
                  DataToPush = [...DataToPush, (item?.status) ? ShippingRequestStatus[item.status] : '!Error']
                }
                else if(columnsData[index] === "REQUEST ID"){
                  DataToPush = [...DataToPush, (item?.requestNumber) ? String(item.requestNumber) : '!Error']
                }
                else if(columnsData[index] === "SHIPPER"){
                  DataToPush = [...DataToPush, (item?.shipper?.name) ? item.shipper.name : 'N/A']
                }
                else if(columnsData[index] === "ASSET"){
                  DataToPush = [...DataToPush, (item?.asset?.number) ? item.asset.number : 'N/A']
                }
                else if(columnsData[index] === "DRIVER"){
                  DataToPush = [...DataToPush, (item?.driverDetails?.name) ? item.driverDetails.name : 'N/A']
                }
                else if(columnsData[index] === "PRICE (INR)"){
                  DataToPush = [...DataToPush, (item?.paymentDetails?.actualAmount || item?.paymentDetails?.actualAmount === 0) ? item.paymentDetails?.actualAmount : 'N/A']
                }
                else if(columnsData[index] === "CREATED ON"){
                  DataToPush = [...DataToPush, (item?.createdAt) ? moment(new Date(item.createdAt)).format('DD-MMM-YY hh:mma') : 'Date Error']
                }
              }
              mappedData.push(
                DataToPush
              );
             
            });
            setDataToDownload(mappedData);
            setClickedExport(true);
            setExportLoading(false);
          })
          .catch((err: any) => {
            // todo: toast error
            console.error(err);
            setExportLoading(false);
            const UserToken = new ShipperHelper()
            UserToken.UserTokenExpiration()
          })
      }
    }

    useEffect(() => {
      if(clickedExport && csvLinkRef?.current && dataToDownload) {
        csvLinkRef.current.link.click();
        setClickedExport(false);
        setDataToDownload('');
      }
    }, [clickedExport])
    const handleFilterPopOverClick = (event: any) => {
      setAnchorElFilterPopOver(event.currentTarget);
    };
  
    const handleFilterPopOverClose = () => {
      setAnchorElFilterPopOver(null);
    };
  
    
  
    const openFilterPopOver = Boolean(anchorElFilterPopOver);
    const filterPopOverId = openFilterPopOver ? 'transporter-filter-popover' : undefined;
    const openManageColumnsPopOver = Boolean(anchorElManageColumnsPopOver);
    const manageColumnsPopOverId = openManageColumnsPopOver ? 'transporter-manage-columns-popover' : undefined;
  
  return (
    <Box sx={{ display: 'flex',width:"98.5rem",marginTop:"-13px",marginLeft:"-115px" }}>
    <ResponsiveDrawer setSearchReqId={setSearchReqId1} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
   <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <div className={classes.root}>
       
<br />
<div>
<div style={{width:'100%',float:'left',marginRight:'48px'}}>
<span id='filterIcon' aria-describedby={filterPopOverId} data-testid="filterIcon" onClick={handleFilterPopOverClick}>
  <Badge color="secondary" variant="dot" invisible={!filterOptions}>
    <Tooltip title="Apply Filters" placement="bottom">
      <FilterListIcon  />
    </Tooltip>
  </Badge>
</span>
<Popover
  id={filterPopOverId}
  open={openFilterPopOver}
  anchorEl={anchorElFilterPopOver}
  onClose={handleFilterPopOverClose}
  getContentAnchorEl={null}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right'
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right'
  }}
  PaperProps={{
    style: { width: '35rem' }
  }}

>
  <TableFilter 
    setFilterOptions={setFilterOptions}
    filterOptions={filterOptions}
    closeTableFilter={handleFilterPopOverClose}
  />
</Popover>
<span
    id='columnIcon'
    >
      <TableManageColumns 
       setManageColumnsOptions={setManageColumnsOptions} 
      manageColumnsOptions={manageColumnsOptions} 
      //closeTableManageColumns={handleManageColumnsPopOverClose} 
      />
    </span>

  <span id='exportIcon' onClick={handleExportIconClick} data-testid="exportCsvIcon">
    <Tooltip title="Export All to CSV" placement="bottom">
      <ScreenShareOutlinedIcon
        data-testid="exportIcon"
        className={exportLoading ? `rotating ${classes.disableExportIcon}` : ``}
      />
    </Tooltip>
    <CSVLink
      data={dataToDownload}
      filename={`export_data_by_${userName}_${moment().format('YYYY-MM-DD_hh-mm-ssa')}.csv`}
      className="hidden"
      id="transportExportCsvLink"
      ref={csvLinkRef}
      target="_blank"
    >download</CSVLink>
  </span>
<p style={{fontSize:"24px"}}> Trip Management </p>
<Tabs activeKey={keys} data-testid="requestTabs" id="uncontrolled-tab-example" style={{fontSize:'14px',color:'grey',width:'100%'}} onSelect={handleChangeKey}>

<Tab eventKey="activeRequest" data-testid="activeRequest" title="ACTIVE" >
<TransporterRequestactive searchReqId={searchReqId} filterOptions={filterOptions} manageColumnsOptions={manageColumnsOptions} handleChangeKey={handleChangeKey} /> 

</Tab>
<Tab eventKey="pastRequest" data-testid="completedRequest" title="COMPLETED">
<TransportRequestCompleted searchReqId={searchReqId} select={select} filterOptions={filterOptions} manageColumnsOptions={manageColumnsOptions} handleChangeKey={handleChangeKey} /> 
</Tab>
<hr/>

</Tabs>
</div>
</div>

    </div>
    </Box>
    </Box>
 
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(ShipperLanding);