


import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Tab as Taab,
  Tabs as Taabs,
  Box,
  Typography,
  Button,
  InputBase,
  withStyles,
  Grid,

} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InputLabel from '@mui/material/InputLabel';
import { assetBaseURL } from "../../../services/_url";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from "react-router-dom";
//import FormControl from '@mui/material/FormControl';
//import ListItemText from '@mui/material/ListItemText';
import moment from "moment";
import AssetCoolerBox from "./CoolerBox";
import { telemetry } from "../../../services/_url";
import axios from "axios";
import AssetReeferTruck from "./ReeferTruck";
//import AssetOverView from "./common/overview";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import calendar from "../../../assets/images/calendar.svg";
import viewBy from "../../../assets/images/viewBy.svg";
import down from "../../../assets/images/down.svg";
import Download from "../../../assets/images/Download.svg";
import ShareIcon from '@mui/icons-material/Share';

import UserService from "../../../services/userService";
import { Link } from "react-router-dom";
import EVOverview from "./common/overview/evOverview";
import Details from "../../components/AssetManagement/common/overview/details"
import { ResponsiveDrawer } from "../common/Drawer";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../redux/user/user.action";
import { selectCurrentUser } from "../../../redux/user/user.selector";
import { capitalize } from "lodash";
const BootstrapInput = withStyles((theme: Theme) =>
  //css of input and select box
  createStyles({
    root: {

    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "null",
      fontSize: "13px",
      // width: "150px",

      textAlign: "right",

      // transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.

    },
  })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

      flexGrow: 1,
      fontSize: "12pt",
      boxShadow: "none",
    },
    border: {
      border: '1px solid white',
    },
    pageHeader: {
      // fontSize: "1.5rem",
      // marginBottom: "2rem",
      font: "normal normal normal 17px/18px Roboto Medium",
      color: "#000000",
      opacity: "1",
    },
    pageTopBar: {
      backgroundColor: "#F4F6F8",
    },
    tabsWrapper: {
      marginTop: "50px",
      marginBottom: "1.5rem",
      position: "relative",
      "@media (max-width:500px)": {
        marginTop: "3rem",
      },

    },
    tabs: {
    },
    tab: {
      "&.Mui-selected": {
        outline: "none",
      },
      outline: "none",
    },
    tabPanel: {
      backgroundColor: "#fafafa",
    },
    addNewAsset: {
      // width: "45%",
      float: "right",
      // marginRight: "1rem",
    },
    addAsset: {
      float: "left",
      marginLeft: "-86px"
    },
    select: {
      "&:focus-within ": {
        border: "1px solid #000000",

      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #000000"
      },
    },
    topIconsWrapper: {
      marginRight: "0.75rem",
      position: "absolute",
      top: 0,
      right: 0,
    },
    tabsLeft: {
      width: "13rem",
      "& .MuiTabs-flexContainer": {
        backgroundColor: "#E5E5E5",
        borderRadius: "40px",
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "0.6rem",
        border: "5px solid #E5E5E5",
        color: "#E5E5E5",
      }
    },
    tabTruck: {
      "&.Mui-selected": {
        outline: "none",
        color: "#0078A5",
        backgroundColor: "#ffffff",
        borderRadius: "40px",

        "@media only screen and (max-width:750px) and (min-width:1030px)": {
          width: "50%",
          fontSize: "18px",
        }
      },
      outline: "none",
      color: "#737373",
      borderRadius: "40px",
      width: "50%",
    },
    topIconWrapper: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "10px",
      color: "#5A5A5A",
      marginLeft: "20px",
      width: "40px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
    topIconWrapper1: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "7.5rem",
      color: "#5A5A5A",
      width: "40px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
  })
);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      data-testid="simplepanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography data-testid="simplepanels">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export interface IAssetProps { }
const NewIcon = (props: any) => (
  <svg
    {...props}
    width="0"
    height="0"
    viewBox="0 0 38 38"
    fill="none"

  >

    <svg
      width="0"
      height="0"
      x="10"
      y="13"
      viewBox="0 0 18 12"
      fill="none"

    >
      <path
        d="M0.66732 0.999999L8.72964 10.8681C8.76363 10.9095 8.80536 10.9428 8.85208 10.9655C8.89879 10.9882 8.94943 11 9.00065 11C9.05187 11 9.10251 10.9882 9.14922 10.9655C9.19594 10.9428 9.23767 10.9095 9.27167 10.8681L17.334 1"
        stroke="#222426"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </svg>
);
export function AssetComponent(props: any) {
  const classes = useStyles();
  const params = useParams();
  // console.log("params--> " + params);
  const [value, setValue] = useState(0);
  const [period, setPeriod] = React.useState("daily");
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [fromAndTo, setfromAndTo] = useState("");
  const [endNotGreater, setendNotGreater] = useState(false);
  const [loadingCircle, setloadingCircle] = useState(false);
  const [viewByValue, setViewByValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState('ALL');
  const [selectedOption1, setSelectedOption1] = useState('ALL')
  const [selectedTenant, setSelectedTenant] = useState('ALL')
  const [viewByOptions, setViewByOptions] = useState([]);
  const [asset, setAsset] = useState("Asset");
  const [searchReqId, setSearchReqId] = useState<string>("");
  const [topBarValue, setTopBarValue] = useState(1);
  const [customerValue, setCustomerValue] = useState();
  const userApi = new UserService();
  const history = useHistory();
  const userDetails = userApi.getUserDetails();
  const [shouldApplySearch, setShouldApplySearch] = useState<boolean>(false);
  const [messageOpen, setmessageOpen] = useState(false)
  const [dDialog, setDDialog] = useState(false);
  const userService = new UserService()
  const [vehAttached, setVehAttached] = useState(null)
  const [vehOperational, setVehOperational] = useState(null)
  const [vehUtilized, setVehUtilized] = useState(null)
  const filterNames = ['test', 'test1'];
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const customers = ['ALL', 'Big Basket', 'Amazon', 'Gourmet Garden'];

  let { currentUser, setCurrentUser } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = React.useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const tenantName = userDetails.tenantName;
        if (tenantName === 'ISE' || tenantName === "ISE foods") {
          setTopBarValue(0)
          history.push("/assetoverview");
        }
        const result = await userService.getTenants();
        let tenant = result;
        let filterdata = tenant.filter(function (el: { name: string; }) {
          return el.name === "LithPwr" || el.name === 'ISE' || el.name === 'ISE foods' || el.name === 'Blowhorn'
        });

        setCustomerOptions(filterdata)

      } catch (error) {

      }
    })()
  }, [])
  // console.log(userDetails.roleName,"custom");

  useEffect(() => {
    (async () => {
      try {
        let url = `${assetBaseURL}/assets/type/all?page=0&size=100&tenantId=${selectedOption1}`;
        let url1 = `${assetBaseURL}/assets/type/all?page=0&size=100`;
        let url2 = `${telemetry}/telemetry/intellicar/assetlocation?assetId=ALL&srvCst=1&drv=1&is4wice=1`
        let result;
        result = userDetails.roleName === "DENSO" || userDetails.roleName === "Denso" ? (selectedTenant === "ALL" ? await axios.get(url1) : await axios.get(url)) : await axios.get(url2)
        let total = +0;
        let count = +0;
        let assets = result.data.data || result.data.response
        // console.log(result.data.data,total,count,userDetails.roleName,"all data");
        assets.map((ele: any) => {

          if (ele.active === true) {
            total = total + 1;
            if (ele.backup === true || ele.maintenance === true) {
              count = count + 1;
            }
          }

        })

        let operationalVeh = total - count;
        let utilization = ((operationalVeh / total) * 100).toFixed(2);
        setVehAttached(total);
        setVehOperational(operationalVeh)
        setVehUtilized(utilization)

      } catch (error) {

      }
    })()
  }, [selectedTenant])

  const onChange = (event: SelectChangeEvent<typeof viewByValue>) => {
    const {
      target: { value },
    } = event;
    setViewByValue(
      typeof value === 'string' ? value.split(',') : value,
    );

  };
  const handleChange1 = (event: any) => {
    setAsset(event.target.value);
  };
  const handleDropdownChange = (
    event: SelectChangeEvent<typeof period>
  ) => {
    if (event.target.value === "custom") {
      setcustomDialog(true);
      setPeriod(event.target.value as string);
      // setAssetToken("true");
      setstartDate("");
      setendDate("");
      setfromAndTo("");
    } else {
      setPeriod(event.target.value as string);
      // setAssetToken("true");
      setstartDate("");
      setendDate("");
      setfromAndTo("");
    }
  };
  const handleAddNewDriver = () => {
    history.push('/AddNewDriver');
  }
  const handleAddNewAsset = () => {
    history.push('/addnewasset');
  }
  const handleDates = (e: any) => {
    let fromAndToDate =
      "from=" + startDate + "T00:00:00Z&to=" + endDate + "T23:59:59Z";
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    seterrorText(false);
    setValidationStart(false);
    setValidationEnd(false);
    setendNotGreater(false);
    if (startDate === "") {
      setValidationStart(true);
    } else if (endDate === "") {
      setValidationEnd(true);
    } else if (startDate > endDate) {
      seterrorText(true);
    } else if (endDate > todaysdate) {
      setendNotGreater(true);
    } else {
      setfromAndTo(fromAndToDate);
      setPeriod("custom");
      setcustomDialog(false);
    }
  };
  const handleCancel = () => {
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    if (startDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (startDate > endDate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate > todaysdate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    }
    setcustomDialog(false);
  };
  // const handleChange1 = (e:any) => {
  //   setAsset(e.target.value);
  // };
  const [pdfFlag, setPdfFlag] = useState(false);
  const pdfGen = () => {
    setPdfFlag(!pdfFlag);
  };

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleChangeCustom = (event: any) => {
    /* istanbul ignore next */
    if (period === "custom" && event.target.value === 0) {
      setcustomDialog(true);
    }
  };

  const showDropdown = (e: React.MouseEvent<HTMLElement>) => {
    //Fixed css issue
    if (dDialog) {
      setDDialog(false);
    } else {
      setDDialog(true);
      setTimeout(() => {
        let el = document.getElementById("popupDiv");
        if (!el) {
          return showDropdown(e)
        }
        el.style.position = "absolute";
        el.style.left = (e.pageX - 100) + 'px';
        el.style.top = (e.pageY + 25) + 'px';
      }, 0);
    }
  }
  // const handleTopBarChange = (event:any) => {
  //   setTopBarValue(event.target.newValue);
  // }
  const handleTopBarChange = (event: any) => {
    setTopBarValue(event.target.value);

  }
  const handleCustomerChange = (event: any) => {
    setCustomerValue(event.target.value);

  }

  const handleShare = async () => {
    const shareInfo = await axios.post(`${assetBaseURL}/assets/shareURL`, { assetId: selectedAssetId })
    console.log(shareInfo)
  }
  const handleChangeView = (event: any, id: string) => {
    setSelectedOption(id);
    setSelectedOption1(event.target.value);
    setSelectedTenant(event.target.name)
  }
  // console.log(selectedOption1,"selectedOption1");

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDDialog(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Box sx={{ display: 'flex', marginLeft: "-110px", marginTop: "-10px" }}>
      <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser} />
      <Box component={"main"} sx={{ flexGrow: 1, p: 3 }}>
        <div className={classes.root}>
          <div className={classes.pageTopBar}>

            <div style={{ height: "4rem", paddingTop: "13px", fontSize: "16px", fontWeight: 500 }}>
              <div style={{ float: "left", width: "20%", marginTop: "-8px", fontSize: "17px" }}>Operation Monitoring
                <div className={classes.addAsset}>

                  <Select
                    // disabled={userDetails.tenantName=== "ISE" || userDetails.tenantName=== "ISE foods"}
                    className={classes.select}
                    IconComponent={ExpandMore}
                    sx={{
                      height: "30px",
                      "& .MuiSelect-select": {
                        padding: "0.2rem"
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9fa0a1 !important",
                        borderWidth: "0px !important"
                      }
                    }}
                    style={{
                      fontSize: "13.3px", textAlign: "center", float: "left", height: '37px', border: "1px solid lightgrey",
                      backgroundColor: "white", width: '5.4rem', marginTop: '-26px', marginLeft: "260px", paddingLeft: "8px", color: "#000"
                    }}
                    value={topBarValue} onChange={handleTopBarChange}>

                    <Link to={{ pathname: '/assetoverview' }} style={{ textDecoration: 'none' }}> <MenuItem style={{ fontSize: "14px", color: "black" }} value={0} >ASSET </MenuItem></Link>
                    {(userDetails.tenantName !== "ISE" && userDetails.tenantName !== "ISE foods") &&
                      <MenuItem style={{ fontSize: "14px", color: "black" }} value={1} >FLEET </MenuItem>
                    }
                  </Select>
                </div>
                <div>
                  {/* {userDetails.roleName === "LithPwr" && */}
                  <Button onClick={(e) => showDropdown(e)} id="multiple-formcontrol1"
                    style={{
                      textAlign: "left", float: "left", height: '2.3rem', width: '7.5rem',
                      marginTop: "-37px", paddingLeft: "10px", marginLeft: '280px', border: "1px solid #CED4D9", textTransform: 'none',
                      backgroundColor: "#ffffff", color: "#000000", fontWeight: "lighter"
                    }}>
                    Customers <img src={down} style={{ width: "120px", marginLeft: "2px", marginTop: "4px", height: "22px" }} />
                  </Button>
                  {/* } */}
                </div>

              </div>

              <div className={classes.addNewAsset}>
                {topBarValue === 0 ?
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-10px" }}>

                    {/* {userDetails.roles.includes('ADMIN') && <Button onClick={(e) => showDropdown(e)} id="multiple-formcontrol1"
                style={{ textAlign: "left", float: "left", height: '2.5rem', width: '8rem', 
                marginTop: "-2px", paddingLeft:"6px", marginRight: '15px', border: "1px solid #CED4D9", textTransform: 'none', 
                backgroundColor: "#CED4D9", color: "#000000",fontWeight:"lighter" }}>
                View by<img src={viewBy} style={{paddingLeft:"20px"}}/>
              </Button>} */}

                    {userDetails.roles.includes('ADMIN') &&
                      <input type="text"
                        disabled value={selectedOption1} style={{
                          border: "1px solid rgb(206, 212, 217)", borderRadius: "4px",
                          height: '2.5rem', width: "12rem", backgroundColor: "white", float: "left", paddingLeft: "14px",
                          marginTop: "-2px",
                          marginRight: "1rem", fontSize: "initial"
                        }} />}

                    <div style={{
                      border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", float: "left", backgroundColor: "white",
                      width: "160px", marginTop: "-2px",
                      paddingRight: "-10px", marginRight: "1rem", height: '2.5rem', marginLeft: "3px"
                    }}>

                      <img src={calendar} style={{ width: "24px", marginLeft: "10px", marginTop: "1px" }} />
                      <Select
                        labelId="demo-customized-select-label"
                        data-testid="period"
                        id="demo-customized-select"
                        value={period}
                        onChange={handleDropdownChange}

                        inputProps={{ IconComponent: "null" }}
                        input={<BootstrapInput />}
                        style={{ marginTop: "2.9px", marginLeft: "7px" }}

                      >
                        <MenuItem
                          style={{ color: "#000" }}
                          onClick={() => handleChangeCustom('')}
                          value={"daily"}> Last  24 Hours</MenuItem>
                        <MenuItem
                          style={{ color: "#000" }}
                          onClick={() => handleChangeCustom('')}

                          value={"weekly"}>Last 7 Days</MenuItem>
                        <MenuItem
                          style={{ color: "#000" }}
                          onClick={() => handleChangeCustom('')}

                          value={"monthly"}>Last 30 Days</MenuItem>
                        <MenuItem
                          style={{ color: "#000" }}
                          onClick={() => handleChangeCustom('custom')}
                          value={"custom"}>Custom Date</MenuItem>

                      </Select>
                    </div>




                    <Link to={{ pathname: '/Addnewasset' }} style={{ color: "#fff", textDecorationLine: 'none', marginRight: "1rem", }}>
                      <Button variant="outlined" style={{
                        backgroundColor: "#0078A5", color: "#FFFFFF", opacity: 1,
                        width: "165px", borderRadius: "4px", marginTop: "-2px", border: "1px soid #0078A5", textTransform: "none", marginLeft: "2px", fontSize: "14px", height: "39px"
                      }}>
                        <AddIcon style={{ marginLeft: "-4px", marginRight: "3px", width: "20px" }} />
                        <span style={{ marginLeft: "3.4px" }}>Add New Asset</span>
                      </Button>
                    </Link>

                    <div style={{
                      border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", backgroundColor: "#fff",
                      width: "40px", marginLeft: "2.6px", marginRight: "24px", marginTop: "-4px", height: "41px"
                    }} onClick={pdfGen}
                    >
                      <img src={Download} style={{ width: "97px", marginLeft: "-30px", marginTop: "8px", height: "20px" }} />
                    </div>

                    <div style={{
                      border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", backgroundColor: "#fff",
                      width: "40px", marginLeft: "2.6px", marginRight: "24px", marginTop: "-4px", height: "41px"
                    }} onClick={() => handleShare()}>
                      <ShareIcon style={{ width: "97px", marginLeft: "-30px", marginTop: "8px", height: "20px" }} />
                    </div>
                  </div>
                  : " "}
                {<> <div style={{ padding: "18px", color: "#000", marginTop: '-28px', alignItems: "center", backgroundColor: '#fff', height: '5rem', width: '10.8rem' }} >
                  <p style={{ fontSize: "14px", fontWeight: "normal", color: "#707070", marginTop: '-15px' }}>Number of Vehicles Attached</p>
                  {/* <div style={{ marginTop: "-76px", display: "flex", alignItems: "center", marginLeft: "105px" }}> */}
                  <p style={{ fontSize: "22px", fontWeight: "normal", color: "#707070", marginTop: '-10px' }}>{vehAttached}</p>
                  {/* </div> */}
                </div>
                  <div style={{ padding: "18px", color: "#000", marginTop: '-80px', alignItems: "center", backgroundColor: '#fff', height: '5rem', marginLeft: '180px', width: '10.8rem' }} >
                    <p style={{ fontSize: "14px", fontWeight: "normal", color: "#707070", marginTop: '-15px' }}>Number of Vehicles Operational</p>
                    {/* <div style={{ marginTop: "-76px", display: "flex", alignItems: "center", marginLeft: "105px" }}> */}
                    <p style={{ fontSize: "22px", fontWeight: "normal", color: "#707070", marginTop: '-10px' }}>{vehOperational}</p>
                    {/* </div> */}
                  </div>
                  <div style={{ padding: "18px", color: "#000", marginTop: '-80px', alignItems: "center", backgroundColor: '#fff', height: '5rem', marginLeft: '360px', width: '10.8rem' }} >
                    <p style={{ fontSize: "14px", fontWeight: "normal", color: "#707070", marginTop: '-15px' }}>Number of Vehicles Utilized</p>
                    {/* <div style={{ marginTop: "-76px", display: "flex", alignItems: "center", marginLeft: "105px" }}> */}
                    <p style={{ fontSize: "22px", fontWeight: "normal", color: "#707070", marginTop: '-10px' }}>{vehUtilized}%</p>
                    {/* </div> */}
                  </div></>}
              </div>
              <div className={classes.tabsWrapper}>
                {topBarValue === 0 ?
                  <Paper className={classes.root} style={{ marginLeft: "-7px" }} >
                    <TabPanel className={classes.tabPanel} value={value} index={0} >
                      <AssetReeferTruck
                        pdfFlag={pdfFlag}
                        pdfGen={pdfGen}
                        fromAndTo={fromAndTo}
                        selectedCloseMarket={selectedOption}
                        period={period}
                        value={value}
                        handleLeftBarChange={handleChange}
                        setSelectedAssetId={setSelectedAssetId}
                      />
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={1} >
                      <AssetCoolerBox
                        pdfFlag={pdfFlag}
                        pdfGen={pdfGen}
                        filter={period}
                        value={value}
                        selectedCloseMarket={selectedOption}
                        handleLeftBarChange={handleChange}
                        setSelectedAssetId={setSelectedAssetId}
                      />
                    </TabPanel>
                  </Paper>
                  :
                  <Paper className={classes.root} style={{ marginTop: "4.2rem", boxShadow: "none" }}>
                    {/* <TabPanel className={classes.tabPanel} value={0} index={0} > */}
                    <EVOverview
                      pdfFlag={pdfFlag}
                      pdfGen={pdfGen}
                      fromAndTo={fromAndTo}
                      selectedCloseMarket={selectedOption1}
                      customer={selectedTenant}
                      period={period}
                      value={value}
                      handleLeftBarChange={handleChange}
                      topBarValue={topBarValue}
                    />
                    {/* </TabPanel> */}
                  </Paper>

                }
              </div>

            </div>
            <div>
              <Dialog
                open={customDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
              >
                <DialogTitle id="alert-dialog-title" style={{ color: '#000' }}>{"Custom Date"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <br />
                    <TextField
                      id="date"
                      data-testid="startDate"
                      label="Start date"
                      type="date"
                      sx={{ width: 220, marginBottom: "30px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errorText === true ? errorText : validationStart}
                      helperText={
                        errorText === true
                          ? "start date cannot be greater than end date"
                          : validationStart === true
                            ? "Select start date"
                            : ""
                      }
                      value={startDate}
                      onChange={(e) => setstartDate(e.target.value)}
                    />
                    &nbsp;&nbsp;
                    <TextField
                      id="date"
                      data-testid="endDate"
                      label="End date"
                      type="date"
                      // defaultValue="2017-05-24"
                      sx={{ width: 220, marginBottom: "30px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={endDate}
                      error={validationEnd === true ? validationEnd : endNotGreater}
                      helperText={
                        validationEnd === true
                          ? "Select end date"
                          : endNotGreater === true
                            ? "End date cannot be greater than todays date"
                            : ""
                      }
                      onChange={(e) => setendDate(e.target.value)}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button data-testid="okButton" onClick={handleDates}>
                    Ok
                  </Button>
                  <Button data-testid="cancelButton" onClick={handleCancel}>
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              {dDialog ? (customerOptions.length && userDetails.roles.includes('ADMIN') ? (<div id="popupDiv" ref={wrapperRef}
                style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #CED4D9', width: '12rem', overflowY: "auto", height: "11.5rem", color: "#000000" }}>
                <div style={{ width: '100%' }}>
                  <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }}
                    onChange={(e) => handleChangeView(e, "ALL")} checked={selectedOption1 === 'ALL'} value="ALL" type="checkbox" name="ALL" />
                  <label htmlFor="all">All</label>
                </div>
                {
                  customerOptions && customerOptions.map(ele => {
                    return (
                      <>
                        <hr style={{ marginTop: '0.1px' }} />
                        <div style={{ width: '100%' }} >
                          <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px', marginTop: "-10px" }}
                            checked={selectedOption1 === ele.id} onChange={(e) => handleChangeView(e, ele)}
                            type="checkbox" name={ele.name} value={ele.id} />
                          <label htmlFor={ele.id}>{ele.name}</label>
                        </div>
                      </>
                    )
                  })
                }
              </div>) : (
                <div id="popupDiv" ref={wrapperRef}
                  style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #CED4D9', width: '12rem', overflowY: "auto", height: "11rem", color: "#000000" }}>

                  {
                    customers && customers.map(ele => {
                      return (
                        <>
                          <hr style={{ marginTop: '0.1px' }} />
                          <div style={{ width: '100%' }} >
                            <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }}
                              checked={selectedOption1 === ele} onChange={(e) => handleChangeView(e, ele)}
                              type="checkbox" name={ele} value={ele} />
                            <label htmlFor={ele}>{ele}</label>
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              )) : null}
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(AssetComponent);