import React, { useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Divider, InputAdornment, MenuItem } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import calendar from "../../../../assets/images/calendar.svg"
import { TextField } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SelectField } from "../../common/FormFields";
import { marketBaseURL } from "../../../../services/_url";
import axios from "axios";
import ShipperHelper from "../../../../services/_helpers";
import { DatePickerField } from "../../common/FormFields";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
export interface IUpdatePaymentProps {}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {/* <CloseIcon /> */}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UpdatePayment(props: any) {
  const [open, setOpen] = React.useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [data] = useState(props.data);
  // useEffect(() => {
  //   if (open) {
  //     getRequestsByQuery();
  //   }
  // }, [])
  // const getRequestsByQuery = () => {
  //   const marketApi = new MarketPlaceAPI();
  //   marketApi.getRequestsByRequestNumber(props.data.requestNumber)
  //     .then((resp: any) => {
  //       setData(resp);

  //     })
  //     .catch((err: any) => {
  //       console.error(err)
  //       const UserToken = new ShipperHelper()
  //       UserToken.UserTokenExpiration()
  //     });
  // }
  const handleClickOpen = () => {
    setSubmitionCompleted(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem
        data-testid="MenuOpen"
        onClick={handleClickOpen}
        style={{ marginLeft: "-1rem",marginTop:"-2px",fontSize:"14px"}}
        
      >
        Update Payment Details
        
      </MenuItem>
      <Divider  style={{width:"152px",marginLeft:"-1px",backgroundColor:"#626363",height:"1.7px"}} />
      <Dialog data-testid="handleclose"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        style={{width:"500px",marginLeft:'500px',height:"600px",marginTop:"80px"}}
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle     id="customized-dialog-title" onClose={handleClose} >
               <span  style={{fontSize:"16px",marginLeft:"8px",color:"#000"}}>Update Payment Details</span> 
               <Divider style={{marginTop:"10px",width:"550px",marginLeft:"-25px",backgroundColor:"#626363",height:"1.7px"}} />
            </DialogTitle>
            
            <DialogContent >
          
              <Formik
                data-testid="updatePayment"
                initialValues={{
                  indicativePrice: data?.indicativePrice.price,
                  actualAmount: data?.paymentDetails?.actualAmount,
                  paymentMadeBy: data?.paymentDetails?.paidBy,

                  paidOn:
                    new Date(data?.paymentDetails?.paidOn).getFullYear() +
                    "-" +
                    (new Date(data?.paymentDetails?.paidOn).getMonth() + 1 < 10
                      ? "0"
                      : "") +
                    (new Date(data?.paymentDetails?.paidOn).getMonth() + 1) +
                    "-" +
                    (new Date(data?.paymentDetails?.paidOn).getDate() < 10
                      ? "0"
                      : "") +
                    new Date(data?.paymentDetails?.paidOn).getDate(),
                  paymentMode: data?.paymentDetails?.paymentMode,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let Ship = new ShipperHelper();

                  data.paymentDetails.actualAmount = values.actualAmount;
                  data.paymentDetails.paidBy = values.paymentMadeBy;
                  data.paymentDetails.paymentMode = values.paymentMode;
                  var hrs = new Date().getHours();
                  var min = new Date().getMinutes();
                  data.paymentDetails.paidOn = Ship.dateToUTC(
                    new Date(
                      new Date(values.paidOn).getFullYear(),
                      new Date(values.paidOn).getMonth(),
                      new Date(values.paidOn).getDate(),
                      hrs,
                      min,
                      new Date().getSeconds()
                    )
                  );

                  axios
                    .patch(
                      `${marketBaseURL}/marketplace/shippingRequests/${data["id"]}`,
                      data
                    )
                    .then((res) => {
                      setOpen(!open);
                    })
                    .catch((err: any) => {
                      console.error(err);
                      const UserToken = new ShipperHelper();
                      UserToken.UserTokenExpiration();
                    });
                  setSubmitting(true);
                }}
                validationSchema={Yup.object().shape({
                  actualAmount: Yup.string().required("Required"),
                  paymentMadeBy: Yup.string().required("Required"),
                  paidOn: Yup.date()
                    .max(
                      new Date(),
                      "Paid On date cannot be greater than current date"
                    )
                    .required("Required"),
                  paymentMode: Yup.string().required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    setFieldTouched,
                  } = props;
                  /* istanbul ignore next */
                  const change = (name: any, e: any) => {
                    e.persist();
                    handleChange(e);
                    setFieldTouched(name, true, false);
                  };
                  return (
                    <Form>
                      <div
                        style={{ marginLeft: "10px", marginRight: "1.3rem",marginTop:"-17px" }}
                      >
                        <span style={{fontWeight:500,color:"#000"}}>Indicative Price</span>
                        <span>
                        
                          <InfoOutlinedIcon style={{ color: "#707070",marginLeft:'3px',width:"18px" }} />
                        </span>
                        <p style={{marginTop:'6px'}}>
                          <span style={{marginLeft:'3px',color:"#000"}}> ₹ {values.indicativePrice}{" "}</span>
                        </p>
                        <p style={{marginTop:"-4px",fontWeight:500,color:"#000"}}>Payment Details</p>

                        <TextField
                          id="outlined-basic"
                          label="Actual Amount"
                          type="number"
                          role="actual amount payment made by"
                          data-testid="actualAmount"
                          helperText={
                            touched.actualAmount ? errors.actualAmount : ""
                          }
                          error={
                            touched.actualAmount && Boolean(errors.actualAmount)
                          }
                          onChange={change.bind(null, "actualAmount")}
                          onFocus={change.bind(null, "actualAmount")}
                          onBlur={handleBlur}
                          name="actualAmount"
                          value={values.actualAmount}
                          variant="outlined"
                          style={{ width: "100%",marginTop:"8px"}}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>₹</Typography>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <br />
                        <br />

                        <TextField
                          id="outlined-basic"
                          label="Payment Made By"
                          data-testid="paymentMadeBy"
                          onChange={change.bind(null, "paymentMadeBy")}
                          onFocus={change.bind(null, "paymentMadeBy")}
                          //onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.paymentMadeBy ? errors.paymentMadeBy : ""
                          }
                          error={
                            touched.paymentMadeBy &&
                            Boolean(errors.paymentMadeBy)
                          }
                          name="paymentMadeBy"
                          value={values.paymentMadeBy}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />

                        <br />
                        <br />

                        <DatePickerField
                        
                          id="date"
                          type="date"
                          label="Paid On"
                          data-testid="date"
                          variant="outlined"
                          name="paidOn"
                       
                  
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%" }}
                       
                        >
                         
                        </DatePickerField>
                        {/* <img src={calendar}  style={{marginTop:"-80px",marginLeft:"341px",width:"16px"}}/> */}
                        
                        <br />
                  
                        <SelectField
                          name="paymentMode"
                          label="Payment Mode"
                          data-testid="paymentMode"
                          options={[
                            { key: "cash", value: "Cash" },
                            { key: "digital", value: "Digital" },
                          ]}
                          helperText={
                            touched.paymentMode ? errors.paymentMode : ""
                          }
                          error={
                            touched.paymentMode && Boolean(errors.paymentMode)
                          }
                          fullWidth
                          // onChange={change.bind(null, "actualAmount")}
                          onFocus={change.bind(null, "paymentMode")}
                        />
                      </div>
                      <DialogActions style={{ marginTop: "21px" }}>
                        <Button
                          data-testid="MenuClose"
                          variant="outlined"
                          style={{
                            color: "#737373",
                            width:"20px",
                            marginRight: "10px",
                            textTransform:"capitalize",
                            border: "1px solid rgb(202 198 198)",
                            marginTop:"-16px"
                          }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          data-testid="updateBtn"
                          variant="outlined"
                          style={{
                            border: "2px solid #007AA6",
                            color: "#fff",
                            backgroundColor: "#007AA6",
                            textTransform:"capitalize",
                            borderRadius: "5px",
                            marginTop:"-15px",
                            marginRight:"12px"
                          }}
                        >
                          Update and Save
                        </Button>
                      </DialogActions>
                    </Form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </div>
  );
}
