import React from 'react';
import Plot from 'react-plotly.js';
import { useState, useEffect } from 'react';


const ShockEvents = (props:any) => {

    const Headingstyle: any = {
        textAlign:'left',
        marginLeft:'1rem',
        marginTop: '-38px',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight: "normal",
        fontSize: '14px',
        color: "#000000DE",
  
    }


    const [total, setTotal]: any = useState({
        x: [],
        y: [],
        type: 'bar',
        name: 'Total',
        marker: {
            color: '#0E6BA6'
        }
    })

    
    const trace1:any = {
        x: props.total,
        y: props.xaxis,
      
        name: 'Total',
        type: 'bar',
    
        marker: {
            color: '#a32428',
            width: [0.1]
        }
      };
      
        useEffect(() => {
      
        let totaly: any = []
       


      Object.keys(props.data).forEach(function(key) {
         totaly = [...totaly, props.data[key].total]
 
      });
           setTotal({ ...total, x: props.xaxis, y: totaly})
               
    }, [])

    return (
        <div style={{marginRight:'10px'}}>
            {/* <div style={Headingstyle} >Shock Events</div> */}
            {props.noDataShockEvents !== '' ? <div style={{minHeight:'330px'}}>{props.noDataShockEvents}</div> :
            
            <Plot
                data={[
                    trace1
                ]}
                layout={{
                    margin:  { l: 30, r: 35, b: 60, t: 20 },
                    xaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 14,
                        },
                        tick0: 0,
                        rangemode: 'tozero',
                      
                    },
                    yaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 14,
                        },
                        tick0: 0,
                        rangemode: 'nonnegative',
                        zerolinewidth: 1,
                    },
                    barmode: 'group',
                    height: 300,
                    
                    legend: {
                        x: 0.35,
                        y: -0.7,
                        orientation: 'h'
                    },
                  
                   

                }}
                style={{ maxWidth: '1500px', maxHeight: '300px',marginLeft:"7px" }}
                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
            />}
        </div>
    )
}

export default ShockEvents
