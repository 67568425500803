import * as React from 'react';
import { useEffect } from 'react';
import {Dialog, DialogActions ,DialogContent, DialogContentText, DialogTitle, Button, CircularProgress, Box, Grid } from '@mui/material';
import TelemeteryAPI from '../../../../services/telemeteryService';
import source from '../../../../assets/images/source.svg';
import destination from '../../../../assets/images/destination.svg'
import { defaultZoomLevel } from '../../../../services/_helpers';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


declare var mappls: any; // Declaring Mapmyindia
declare var L: any;

export  function PlanRoute (props: any) {
  var map: any = null;
  var poly:any[] =[];
  // console.log(props);
  const [open, setOpen] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.closePopup(false)
    
  };
  const handleSubmit = () => {
    props.closePopup(true);
  }

  useEffect(()=>{
    setOpen(props.open);
  },[props.open])

   const makePolyLine = async () => {
    
    //poly.push(["12.909923","77.651883"],["12.916872","77.621716"],["13.000293","77.679365"],["12.928338","77.682932"]);
    
    poly = props.data;
    //poly.push(props.data);
    /* istanbul ignore next */
    var center = poly[0];
    
    /* istanbul ignore next */
    if(document.getElementById('weathermap')){
      document.getElementById('weathermap').innerHTML = "<div id='map'></div>";
      setLoading(false)
    } else {
      setLoading(true)
    }
    map = await new mappls.Map('map', {center: center, zoomControl: true, hybrid: true});
    
    //map.invalidateSize()
    
    if(map.addListener) {
      new mappls.Polyline({
          map: map,
          paths: poly,
          strokeColor: '#3475e0',
          strokeOpacity: 1.0,
          strokeWeight: 5,
          fitbounds: true
      });

      for (let index = 0; index < poly.length; index++) {
        // console.log(index);
        const element = poly[index];
        const element1 = poly[index-1];
        // console.log(element,element1,"element")
        if(index === 0) {
          new mappls.Marker({
            map: map,
            position: {
              "lat": element[0],
              "lng": element[1]
            },
            title: 'Start trip',
            //html: "<img class='map_marker' style='height: 10px, width: 10px' src=" + "'https://maps.mapmyindia.com/images/2.png'>" + '<span class="my-div-span">' + 'M' + '</span>',
            fitbounds: true,
            icon_url: source
          });
        } else {
          
          if((element[0] === element1[0]) )
          {
            let req = props.requestArray[index-1];
            let req1 = props.requestArray[index-2];
            new mappls.Marker({
              map: map,
              position: {
                "lat": element[0],
                "lng": element[1]
              },
              title: `Request No: ${req},${req1}`,
              //html: "<img class='map_marker' style='height: 10px, width: 10px' src=" + "'https://maps.mapmyindia.com/images/2.png'>" + '<span class="my-div-span">' + 'M' + '</span>',
              fitbounds: true,
            // icon_url: source
            });
          }
     else{
          let req = props.requestArray[index-1];
          new mappls.Marker({
            map: map,
            position: {
              "lat": element[0],
              "lng": element[1]
            },
            title: `Request No: ${req}`,
            //html: "<img class='map_marker' style='height: 10px, width: 10px' src=" + "'https://maps.mapmyindia.com/images/2.png'>" + '<span class="my-div-span">' + 'M' + '</span>',
            fitbounds: true,
          // icon_url: source
          });
        }
        }
        
      }
    }
   }
   useEffect(()=>{
    //console.log(props.open, props.data, props.isLoad, props.requestArray, "doctorAllow");
    if(props.open){
      makePolyLine()
      if(!map) {
        poly = [];
        
        setTimeout(() => makePolyLine(), 1000)
      }
      
    }
   
   },[props.open, props.data, props.isLoad, props.requestArray])
  return (
    <div>
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      style={{width:"841px",marginLeft:"420px",marginTop:"30px",marginBottom:"30px"}}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle style={{fontSize:'17px',marginTop:"5px",color:"#000"}}>
        Route Optimisation
      </DialogTitle>
      
      <button style={{  backgroundColor: "white", marginLeft: "93.7%",marginTop:"-40px", width:"40px",border:"1px solid #ffffff",color:"grey"}} 
    onClick={handleClose}><span style={{marginLeft:"-1px"}}> <CloseIcon /></span></button>
      {/* <Button
        aria-label="close"
        onClick={handleClose}
        style={{marginLeft:"95%",width:"60px",marginTop:"-50px",backgroundColor:"ffffff",color:"fff"}}
        // sx={{
        //   position: 'absolute',
        //   right: 5,
        //   //top: 2,
        //   color: (theme) => theme.palette.grey[500],
        // }}
      >
          <CloseIcon />
      </Button> */}
     
      <DialogContent style={{ alignItems:"center", justifyContent: "center"}}>
        {(isLoading && props.isLoad) ? <CircularProgress /> : ""}
        <div id='weathermap'></div>
      </DialogContent>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
       <Grid container spacing={2} sx={{padding : 3}}>
           <Grid xs={8} padding={2}>
              <Grid container spacing={2} sx={{pl:2}}>
                <Grid sm={4}>
                  <Box sx={{padding: 2,width:"500px",color:"#000"}}>Estimated Distance Travelled</Box>
                  <Box sx={{padding: 0, color: '#707070',marginTop:"-13px"}}>{props.distance} Km</Box>
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginLeft:"70px",height:"50px",marginTop:"10px",width:"1.2px",backgroundColor:"#626363"}}/>
                <Grid sm={4} sx={{pl:3}}>
                  <Box sx={{padding: 2, width:"300px",color:"#000"}}>Estimate Duration</Box>
                  <Box sx={{padding: 0, color: '#707070',marginTop:"-13px"}}>{props.duration} Hr</Box>
                </Grid>
              </Grid>
           </Grid>
           <Grid xs={4}>
            <DialogActions> 
                <Button variant="contained" onClick={handleSubmit} style={{marginTop:"15px",textTransform:"capitalize",width:"100px",backgroundColor:"#0078a5"}} autoFocus>
                  Accept
                </Button>
              </DialogActions>
           </Grid>
       </Grid>
        
      </Stack>
      
    </Dialog>
    </div>
  );
}
