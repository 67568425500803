import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {
  Grid,
  createStyles,
  makeStyles,
  Button,
  Divider,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import RemoveIcon from "@material-ui/icons/Remove";
import asset from "../../../../assets/images/asset.svg";
import edit from "../../../../assets/images/edit.svg";
import detach from "../../../../assets/images/detach.svg";
import KitInfo from "./hardwareKitInfo2";
import axios from "axios";
import { assetBaseURL } from "../../../../services/_url";
//import Link from '@mui/material/Link';
import Backdrop from "@mui/material/Backdrop";
import { Stack } from "@mui/material";
import ShipperHelper from "../../../../services/_helpers";
import { Link } from "react-router-dom";
import TelemeteryAPI from "../../../../services/telemeteryService";
import down from "../../../../assets/images/down.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import InfoIcon from "@material-ui/icons/Info";

// Show Devices Functionality related changes
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import UserService from "../../../../services/userService";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const detachStyle = makeStyles(() =>
  createStyles({
    root: {
      width: "11rem",
      "@media (max-width:700px)": {
        width: "8rem",
      },
    },
  })
);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions(props: any) {
  const [expanded, setExpanded] = useState<string | false>("1");
  const [hardwareKits, setHardwareKits] = useState([]);
  const [errorMsg] = useState("");
  const [openAccordion, setopenAccordion] = useState(false);
  const [loadingCircle] = useState(false);
  const [hardwarekitData, sethardwarekitData] = useState(false);
  const [tagId, setTagId] = useState("");
  const [tagId1, setTagId1] = useState("");
  const [gatewayId1, setGatewayId1] = useState("");
  const [props1, setProps1] = useState([]);
  const [storetagIds, setStoreTagIds] = useState([]);
  const classes = detachStyle();
  const userApi = new UserService();
  const userDetails = userApi.getUserDetails();
  /** show devices Variables */
  const [showDevice, setShowDevice] = useState(false);
  const [filteredHardwareKits, setFilteredHardwareKits] = useState([]);

/**
 * triggered on switch button change
 * toggle showDevice state variable between true and false
 */
  const onShowDeviceChange = () => {
    setShowDevice(!showDevice);
  }

  const handleChange =
    (panel: string, tagIds: any) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      if (newExpanded === true) {
        setTagId(tagIds);
        if (tagId !== tagIds) {
          if (storetagIds.length >= 0) {
            let indexvalue: any = false;
            for (let index = 0; index < storetagIds.length; index++) {
              if (storetagIds[index]?.id === tagIds) {
                indexvalue = true;
              }
            }
            if (indexvalue) {
              sethardwarekitData(false);
            } else {
              setStoreTagIds([...storetagIds, { id: tagIds }]);
              sethardwarekitData(true);
            }
          }
        }
        // changeAccordion()
        // loadingCircleData()
      }
    };
  const changeAccordion = () => {
    setopenAccordion(!openAccordion);
  };
  const ChangehardwarekitData = () => {
    sethardwarekitData(false);
  };

  useEffect(() => {
    if (props.assetID) {
      axios
        .get(`${assetBaseURL}/sensors/assetId?assetId=${props.assetID}`)
        .then((res: any) => {
          // console.log(res,'called')
          setHardwareKits(res.data);

          // setHardwareKits(res.data);
          setExpanded("1");
          setopenAccordion(false);
          sethardwarekitData(true);
          setTagId("");
          setStoreTagIds([]);
          // setProps1([res])
          // setProps1(props.data)
          setTagId1(res.data[0].tagId);
          setGatewayId1(res.data[0].gatewayId);
          // console.log(tagId1,gatewayId1,res.data[0].tagId,res.data[0].gatewayId,"gateway");
        })
        .catch((err: any) => {
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    }
  }, [props.filter, props.fromAndTo]);

  /**
   * filteredHardwareKits conatins the filtered data based on switch value
   * type==99 is vehicle type
   * type!==removeHardwarhw9 is sensor type
   */
  useEffect(() => {
    if(!showDevice) {
     const filteredData =  hardwareKits.filter(kit => kit.type === 99);
     setFilteredHardwareKits(filteredData);
    } else {
      const filteredData =  hardwareKits.filter(kit => kit.type !== 99);
      const concatArr = [...filteredHardwareKits, ...filteredData]
      setFilteredHardwareKits(concatArr);
    }

  },  [showDevice, hardwareKits])

  // console.log(props1,"tag");
  // console.log(props.data,"tag1");
  const data: any = [props.data, tagId1, gatewayId1,"hardware"];
  const telemetryService = new TelemeteryAPI();
  // console.log(props.data,data,"hard");
  const success = () => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Hardware Removed successfully</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const removeHardware = async (event: any, id: any) => {
    let postData = {
      active: false,
      tagId: id.tagId,
      id: id.id,
      asset:{
        active: id.asset.active,
        assetType:{
          active: id.asset.assetType.active,
          createdAt : id.asset.assetType.createdAt,
          description : id.asset.assetType.description,
          id : id.asset.assetType.id,
          lastUpdatedAt : id.asset.assetType.lastUpdatedAt,
          name:id.asset.assetType.name,
        },
        backup :  id.asset.backup,
        city : id.asset.city,
        createdAt :id.asset.createdAt,
        id : id.asset.id,
        lastUpdatedAt : id.asset.lastUpdatedAt,
        locality : id.asset.locality,
        monitoringRequired : id.asset.monitoringRequired,
        number : id.asset.number,
        onTrip : id.asset.onTrip,
        region : id.asset.region,
        underMaintenance : id.asset.underMaintenance,
        zone : id.asset.zone,
      },
      gatewayId: id.gatewayId,
      description: id.description,
    };
   

    let results = await axios
      .patch(`${assetBaseURL}/sensors/${id.id}`, postData)
      .then((res) => {
        if (res.status === 200) {
          success();
        }
      })
      .catch((err: any) => {});
    let temp = filteredHardwareKits;
    let filtereddata = temp.filter(function (el: { id: string }) {
      return el.id !== id.id;
    });

    setFilteredHardwareKits(filtereddata);
    setExpanded("panel1k");
    setopenAccordion(false);
    event.stopPropagation();
  };

  return (
    <div>
      <div>
        {loadingCircle && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingCircle}
          >
            {/* <CircularProgress color="inherit" /> */}
          </Backdrop>
        )}
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", marginLeft: "1rem", marginBottom: "7px" }}
      >
        <Grid>
          <p id="hardwareTitle" style={{ fontSize: "16px", marginTop: "-7px" }}>
            Hardware Kits Attached
          </p>
        </Grid>
        {userDetails.roles.includes('ADMIN') &&
        <Grid>
          <p
            id="hardwareAttachNo"
            style={{ color: "#0078A5", marginLeft: "1rem" }}
          >
            <Link
              to={{ pathname: "/hardwareasset", state: data }}
              style={{ color: "#fff", textDecorationLine: "none" }}
            >
              <Button
                variant="outlined"
                style={{
                  border: "1px solid #0078A5",
                  borderRadius: "4px",
                  opacity: 1,
                  color: "#0078A5",
                  textTransform: "none",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "-26px",
                  width: "145px",
                }}
              >
                <img
                  src={asset}
                  style={{ marginLeft: "-10px", height: "19px", width: "19px" }}
                />
                <span style={{ marginLeft: "8px", fontSize: "12px" }}>
                  Attach Hardware
                </span>
              </Button>
            </Link>
            {/* <Link href="" id="hardwareAttach">
           <AddIcon/>ATTACH HARDWARE
           </Link> */}
          </p>
        </Grid>
            }
        {/* Switch implemetation */}
        <Grid>
          <FormControl component="fieldset" sx={{
                  top: "-14px"
                }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                // value="start"
                control={
                  <Switch
                    checked={showDevice}
                    onChange={onShowDeviceChange}
                    sx={{
                      color: "#006699",
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: "#006699 !important"
                      },
                      '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                        backgroundColor: "#006699 !important",
                        opacity: "0.5 !important"
                      }
                    }}
                  />
                }
                label="Show Devices"
                labelPlacement="start"
                sx={{
                  color: "#000000DE",
                }}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      {filteredHardwareKits.length > 0 ? (
        filteredHardwareKits.map((id, index) => {
          if (id) {
            return (
              <Accordion
                square
                expanded={expanded === `${index + 1}`}
                onChange={handleChange(`${index + 1}`, "")}
                data-testid="accordion"
                style={{
                  border: "none",
                  borderBottom: "2px solid rgba(0, 0, 0, .125)",
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<ExpandMoreIcon />}
                  style={{ backgroundColor: "#fff" }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={8}
                      xs={2}
                      lg={3}
                      style={{ marginLeft: "-3rem", marginRight: "-3rem" }}
                    >
                      {/* <span>{id}</span> */}
                      <span>
                        {" "}
                        {/* <KeyboardArrowDownIcon /> */}
                        <span style={{ color: "#000", fontSize: "14px" }}>
                          Telematics Unit {index + 1}
                        </span>
                        <div
                          style={{
                            marginLeft: "-8px",
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {id.tagId}
                        </div>
                      </span>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        marginLeft: "10px",
                        height: "50px",
                        marginTop: "6px",
                        width: "1.6px",
                        backgroundColor: "#626363",
                      }}
                    />

                    <Grid item xs={2} style={{ marginLeft: "-1rem" }}>
                      <Stack
                        direction="column"
                        spacing={1}
                        className={classes.root}
                      >
                        <span style={{ fontSize: "14px", color: "#000" }}>
                          Sensor Status
                        </span>
                        {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> */}
                      </Stack>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        marginLeft: "10px",
                        height: "50px",
                        marginTop: "6px",
                        width: "1.6px",
                        backgroundColor: "#626363",
                      }}
                    />

                    <Grid
                      item
                      md={8}
                      xs={2}
                      lg={3}
                      style={{ marginLeft: "-3rem", marginRight: "-3rem" }}
                    >
                      {/* <span>{id}</span> */}
                      <span>
                        {" "}
                        {/* <KeyboardArrowDownIcon /> */}
                        <span style={{ color: "#000", fontSize: "14px" }}>
                          Sensor Type
                        </span>
                        <div
                          style={{
                            marginLeft: "-8px",
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {id.type}
                        </div>
                      </span>
                    </Grid>

                    {userDetails.roles.includes('ADMIN') &&
                    <Grid item xs={2} style={{ marginLeft: "0.5rem" }}>
                      <Stack
                        direction="column"
                        spacing={1}
                        className={classes.root}
                      >
                        <span>
                          <Link
                            to={{ pathname: "/Edithardwareasset", state: id }}
                            style={{
                              color: "#fff",
                              textDecorationLine: "none",
                            }}
                          >
                            <Button
                              variant="outlined"
                              style={{
                                border: "1px solid #0078A5",
                                borderRadius: "4px",
                                opacity: 1,
                                marginLeft: "0.5rem",
                                fontSize: "12px",
                                color: "#0078A5",
                                textTransform: "none",
                                width: "145px",
                                marginTop: "-1px",
                              }}
                            >
                              <img
                                src={edit}
                                style={{
                                  marginLeft: "-10px",
                                  height: "19px",
                                  width: "19px",
                                }}
                              />
                              <span
                                style={{ marginLeft: "10px", fontSize: "12px" }}
                              >
                                Edit Hardware
                              </span>
                            </Button>
                          </Link>
                        </span>
                        {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> */}
                      </Stack>
                    </Grid>
          }
                    <Grid item xs={2} style={{ marginLeft: "0.5rem" }}>
                      <Stack
                        direction="column"
                        spacing={1}
                        className={classes.root}
                      >
                        <span>
                          <Button
                            variant="outlined"
                            style={{
                              border: "1px solid #737373",
                              borderRadius: "4px",
                              opacity: 1,
                              marginLeft: "0.3rem",
                              fontSize: "12px",
                              color: "#737373",
                              textTransform: "none",
                              width: "149px",
                              marginTop: "-1px",
                            }}
                            onClick={(e) => removeHardware(e, id)}
                          >
                            <img
                              src={detach}
                              style={{
                                marginLeft: "-10px",
                                height: "19px",
                                width: "19px",
                              }}
                            />
                            <span
                              style={{ marginLeft: "10px", fontSize: "12px" }}
                            >
                              {" "}
                              Detach Hardware
                            </span>
                          </Button>
                        </span>
                        {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> */}
                      </Stack>
                    </Grid>
                    {/* <Grid item xs={2} style={{marginLeft:"3rem"}}>
                    <Stack
                      direction="column"
                      spacing={1}
                      className={classes.root}
                    >
                     
                        <img src={down} style={{width:'20px',height:'20px'}}/>
                        
                        </Stack>
                  </Grid>   */}

                    {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> */}
                  </Grid>
                </AccordionSummary>

                <KitInfo
                  // gatewayId={id?.gatewayId}
                  gatewayId={id?.gatewayId}
                  singleTagId={id?.tagId}
                  tagId={id?.tagId}
                  filter={props.filter}
                  dates={props.dates}
                  assetId={props.assetID}
                  fromAndTo={props.fromAndTo}
                  openAccordion={openAccordion}
                  changeAccordion={changeAccordion}
                  selectedVehicle={props.data}
                  loadingCircle={loadingCircle}
                  loadingCircleData={props.loadingCircleData}
                  hardwarekitData={filteredHardwareKits}
                  ChangehardwarekitData={ChangehardwarekitData}
                  type={id.type || ""}
                />
              </Accordion>
            );
          }
        })
      ) : (
        <p>{errorMsg}</p>
      )}
    </div>
  );
}
