
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Field, useField, useFormikContext } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import { at } from "lodash";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      // width:200
    }
  })
);



export default function InputField(props:any) {
    const classes = useStyles();
   
const { errorText, ...rest } = props;
const [field, meta] = useField(props);
const startTime=(((new Date().getHours()+1)<10?'0':"")+(new Date().getHours()+1))+":"+((new Date().getMinutes() <10?'0':"")+new Date().getMinutes())
const { setFieldValue } = useFormikContext();
const { value } = field;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  const onChange = React.useCallback((e: any) => {
    //props.handleChange(e.target.value);
    setFieldValue(field.name, e.target.value);
  }, [field.name, props.value, value, field.value]);

   function _onChange(e:any){
   }
  return (
    <Grid container>
      <br/>
      
   
   
    <Field
      component={TextField}
      name={field.name}
     // onChange={props.handleChange ? onChange : null}
     onChange={ onChange}
      type="time"
      label="Select Time *"
       value={field.value || props.value || value}
      // defaultValue="07:30"
      variant="outlined"
      className={classes.textField}
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...props}
      InputLabelProps={{
        shrink: true
      }}
    />
     </Grid>
    // {/* <TextField
    //   id="time"
    //   type="time"
    //   label="Select Time"
    //   onChange={_onChange}
    //    value={props.value || value || field.value}
    //   // defaultValue="07:30"
    //   variant="outlined"
    //   className={classes.textField}
    //   error={meta.touched && meta.error && true}
    //  helperText={_renderHelperText()}
    //   {...field}
    //   {...rest} 
    //   {...props}
    //   InputLabelProps={{
    //     shrink: true
    //   }}
    //   // inputProps={{
    //   //   step: 300 // 5 min
    //   // }}
    // /> */}
    // </form>
   // </div>
  );
}


// 
{/* <FormControl {...rest}>
      <FormControlLabel
        value={field.checked}
        checked={field.checked}
        control={<Switch {...field} onChange={_onChange} />}
        label={label}
      />
      {_renderHelperText()}
    </FormControl> */}
// 