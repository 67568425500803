import React, { useEffect, useState } from "react";
import MarketPlaceAPI from "../../../services/marketPlaceService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { TableSortLabel } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import "./style.css";
import Row from "../Utils/Row";
import noData from "../../../assets/images/No_data.svg";
import Helper, { ShippingRequestStatus } from "../../../services/_helpers";
import { TableManageColumnsFormData } from "../Dashboard/Common/Interfaces/custom";
import TablePaginationCustom from "../Utils/TablePagination";
import ShipperHelper from "../../../services/_helpers";

type Order = "ASC" | "DESC";
//moved to Util folder
//  interface TablePaginationActionsProps {
//    count: number;
//    page: number;
//    rowsPerPage: number;
//    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
//  }
//  const useStyles1 = makeStyles((theme: Theme) =>
//    createStyles({
//      root: {
//        flexShrink: 0,
//        marginLeft: theme.spacing(45),
//      },
//    }),
//  );
//  function TablePaginationActions(props: TablePaginationActionsProps) {
//    const classes = useStyles1();
//   const theme = useTheme();
//   const { count, page, rowsPerPage, onPageChange } = props;

//   const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     onPageChange(event, 0);
//   };

//   const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     onPageChange(event, page - 1);
//   };

//   const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     onPageChange(event, page + 1);
//   };

//   const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//   };

//   return (
//     <div className={classes.root}>
//       <IconButton
//         onClick={handleFirstPageButtonClick}
//         disabled={page === 0}
//         aria-label="first page"
//       >
//         {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
//       </IconButton>
//       <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-

//         {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
//       </IconButton>
//       {count>=1 ?  <span>{((rowsPerPage*(page+1))-rowsPerPage)+1}-{page >= Math.ceil(count / rowsPerPage) - 1 ?count:(rowsPerPage*(page+1))} of {count}</span>
//  : <span>0-{page >= Math.ceil(count / rowsPerPage) - 1 ?count:rowsPerPage} of {count}</span> }
//       <IconButton
//         onClick={handleNextButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="next page"
//       >
//         {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
//       </IconButton>
//       <IconButton
//         onClick={handleLastPageButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="last page"
//       >
//         {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
//       </IconButton>
//     </div>
//   );
//  }
export default function TransporterRequestActive(props: any) {
  const { searchReqId, filterOptions, manageColumnsOptions } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowData, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [orderDirection, setOrderDirection] = React.useState<Order>("DESC");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("timestamp");
  const [fetchTableFlag, setFetchTableFlag] = React.useState(
    JSON.parse(sessionStorage.fetchTableFlag)
  );
  const [loadFlag, setLoadFlag] = React.useState(true);
  const [filterQuery, setFilterQuery] = useState(null);

  const helper = new Helper();

  //not used
  // const fetchTableData2 = (page: number, size: number) => {
  //   let ship =new TransporterAPI();
  //   ship.getByQuery({page: 0}).then((x: any[]) => {
  //     // todo: assign and use the data as table data and update pagination and size values
  //     setLoadFlag(false);
  //   }).catch((err: Error) => {
  //     setLoadFlag(false);
  //     console.error("Failed to fetch shipments, details: ", err);
  //     toast.error(
  //       <div className="d-flex flex-row bd-highlight mb-1">
  //         <span className="p-2 bd-highlight align-self-center" style={{backgroundColor:'#C10018',padding:0,height:'50px',width:'54px'}}>
  //           <ErrorIcon style={{color:'#fff'}}/>
  //         </span>
  //         <div className="p-1 bd-highlight align-self-center">
  //           <span>Failed to fetch shipments. Kindly try again.</span>
  //         </div>
  //       </div>, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         closeButton:false
  //       });
  //   });
  // }
  useEffect(() => {
    if (fetchTableFlag) {
      // setFetchTableFlag(false);
      setFetchTableFlag(sessionStorage.setItem("fetchTableFlag", "false"));
      fetchTableData();
    }
  }, [fetchTableFlag]);

  useEffect(() => {
    if (searchReqId) {
      if (filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if (
            ShippingRequestStatus[opt.value] !==
              ShippingRequestStatus.Completed &&
            ShippingRequestStatus[opt.value] !== ShippingRequestStatus.Cancelled
          ) {
            tempFilter.push(opt.value);
          }
        });
        if (tempFilter.length > 0) {
          setFilterQuery({ status: tempFilter });
          fetchTableDataByReqId(0, rowsPerPage, orderDirection, {
            status: tempFilter,
          });
        } else {
          // setFilterQuery(null);
          // fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
          setRows([]);
          setLoadFlag(false);
          props.handleChangeKey("pastRequest");
        }
      } else {
        setFilterQuery(null);
        fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
        if (rowData.length === 0) {
          props.handleChangeKey("pastRequest");
        }
      }
    }
  }, [searchReqId, filterOptions, rowData.length]);

  useEffect(() => {
    if (!searchReqId) {
      if (filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if (
            ShippingRequestStatus[opt.value] !==
              ShippingRequestStatus.Completed &&
            ShippingRequestStatus[opt.value] !== ShippingRequestStatus.Cancelled
          ) {
            tempFilter.push(opt.value);
          }
        });
        if (tempFilter.length > 0) {
          setFilterQuery({ status: tempFilter });
          fetchTableData(0, rowsPerPage, orderDirection, {
            status: tempFilter,
          });
        } else {
          // setFilterQuery(null);
          // fetchTableData(0, rowsPerPage, orderDirection, null, true);
          setRows([]);
          setLoadFlag(false);
          props.handleChangeKey("pastRequest");
        }
      } else {
        setFilterQuery(null);
        fetchTableData(0, rowsPerPage, orderDirection, null, true);
      }
    }
    // setFetchTableFlag(true);
  }, [filterOptions, searchReqId]);

  const fetchTableDataByReqId = (
    page: number = 0,
    size: number = 10,
    orderBy: Order = "DESC",
    query?: any,
    shouldForceDefaultQuery?: boolean
  ) => {
    const marketApi = new MarketPlaceAPI();
    const defaultQuery = {
      status: [
        helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
        helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
        helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
      ],
    };
    setPage(page);
    setRowsPerPage(size);
    marketApi
      .searchByRequestNumber(
        searchReqId,
        page,
        size,
        orderBy,
        query
          ? query
          : filterQuery && !shouldForceDefaultQuery
          ? filterQuery
          : defaultQuery
      )
      .then((resp: any) => {
        setRows(resp.data);
        setTotalRows(resp.total);
        setLoadFlag(false);
      })
      .catch((err: any) => {
        console.error("No requests found with that request number.", err);
        setRows([]);
        setTotalRows(0);
        setLoadFlag(false);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  };

  const fetchTableData = (
    page: number = 0,
    size: number = 10,
    orderBy: Order = "DESC",
    query?: any,
    shouldForceDefaultQuery?: boolean
  ) => {
    const marketApi = new MarketPlaceAPI();

    const defaultQuery = {
      status: [
        helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
        helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
        helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
      ],
    };
    setPage(page);
    setRowsPerPage(size);

    marketApi
      .getRequestsByQuery(
        page,
        size,
        orderBy,
        query
          ? query
          : filterQuery && !shouldForceDefaultQuery
          ? filterQuery
          : defaultQuery
      )
      .then((resp: any) => {
        setRows(resp.data);
        setTotalRows(resp.total);
        setLoadFlag(false);
      })
      .catch((err: any) => {
        console.error("Failded to load active transporter requests.", err);
        setRows([]);
        setTotalRows(0);
        setLoadFlag(false);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchTableData(newPage, rowsPerPage, orderDirection);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    fetchTableData(0, +event.target.value);
  };
  const handleRequestSort = (event: any) => {
    let isAscending: boolean;
    if (valueToOrderBy === "timestamp" && orderDirection === "ASC") {
      isAscending = true;
    } else {
      isAscending = false;
    }
    setValueToOrderBy("timestamp");
    setOrderDirection(isAscending ? "DESC" : "ASC");
    fetchTableData(0, rowsPerPage, isAscending ? "DESC" : "ASC");
  };

  return (
    <Paper>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table aria-label="collapsible table" className="sortable">
          <TableHead >
            <TableRow>
              <TableCell />
              {manageColumnsOptions
                .sort(
                  (
                    a: TableManageColumnsFormData,
                    b: TableManageColumnsFormData
                  ) => {
                    return a.order - b.order;
                  }
                )
                .map((reorder: TableManageColumnsFormData) => {
                  if (reorder.defaultOrder === 7) {
                    return (
                      <TableCell
                        style={{ fontSize: "12px" }}
                        align="left"
                        key="timestamp"
                      >
                        <TableSortLabel
                          active={valueToOrderBy === "timestamp"}
                          data-testid="createdOnSort"
                          direction={
                            valueToOrderBy === "timestamp"
                              ? (orderDirection.toLocaleLowerCase() as
                                  | "asc"
                                  | "desc")
                              : "asc"
                          }
                          onClick={handleRequestSort}
                          IconComponent={ArrowDropDownIcon}
                        >
                          {reorder.title}
                        </TableSortLabel>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        style={{ fontSize: "12px" }}
                        align={reorder.defaultOrder === 6 ? "right" : "left"}
                      >
                        {reorder.title}
                      </TableCell>
                    );
                  }
                })}
              <TableCell style={{ fontSize: "12px" }} align="center">
                ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadFlag ? null : (
              <>
                {rowData.length > 0 ? (
                  rowData.map((row: any) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        activate="open"
                        manageColumnsOptions={manageColumnsOptions}
                        setFetchTableFlag={setFetchTableFlag}
                      />
                    );
                  })
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 50,
                          paddingTop: 50,
                          margin: "5rem",
                        }}
                        colSpan={9}
                      >
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                          }}
                          data-testid="emptyShipperData"
                        >
                          <span style={{ fontSize: "20px", fontWeight: 500 }}>
                            Currently no trip requests.
                          </span>
                          <p style={{ fontSize: "14px", color: "#5A5A5A" }}>
                            You will see the requests here once the shippers
                            start creating.
                          </p>
                          <img src={noData} />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        data-testid="tablePagination"
        style={{ fontSize: "12px" }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{ variant: "outlined" }}
        labelRowsPerPage={"Rows per page"}
        labelDisplayedRows={({ from, to, count }) => ""}
        ActionsComponent={TablePaginationCustom}
      />
    </Paper>
  );
}
