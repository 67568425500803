import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import {SelectField} from "../../../common/FormFields";
import MenuItem from "@mui/material/MenuItem";
import { InputField } from "../../../common/FormFields";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { assetBaseURL } from "../../../../../services/_url";
import axios from "axios";
import ShipperAPI from "../../../../../services/shippingLanding";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export default function AssetDetails(props: any) {
  // console.log(props.location);
  const [assetName, setAssetName] = useState([]);
  
  const [assetState, setAssetState] = useState({ AssetType: props.location[0].assetType.name, 
    AssetName: props.location[0].assetType.name, AssetID: props.location[0].number, 
    Status: props.location[0].assetType.active ? "Active" : "Under Maintenance", id: props.location[0].id,  CustomerAssetType: ""})
  const classes = useStyles();
  const [assetData, setAssetData] = useState([]);
  
  const [transporterData, setTransporterData] = useState([]);

  const [type, setType] = useState(props.location[0].assetType.name);

  const changeHandler = (e: any) => {
    const { name, value } = e.target;
   /* if(name === 'AssetName') {
      setAssetState(prevState => ({
        ...prevState, [name]: value.number
      }))
      props.formData(assetState);
      return;
    }
    if (name === 'AssetType') {
      setType(value)
    }
    setAssetState(prevState => ({
      ...prevState, [name]: value
    }))*/

    if (name === 'AssetType') {
      setType(value)
    }
    setAssetState(prevState => ({
      ...prevState, [name]: value
    }))
    
    props.formData(name, value);
  }
  const [age, setAge] = React.useState('');

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };
  useEffect(() => {
    const type = "assets";
    axios
      .get(`${assetBaseURL}/assetTypes`)
      .then((res: any) => {
        setAssetData(res.data);
      })
      .catch((err: any) => {
        setAssetData([]);
      });

      //
      const shipperService = new ShipperAPI();
    shipperService.getShipperID()
      //axios.get(`${tenantandUser}/tenants`)  
      .then((res: any) => {
        let transporterOptions: any = []
        res.map((val: any) => {
          if ((val.roles[0]?.name === "Transporter")) {
            let test = { key: "", value: "" }
            test.key = val.id;
            test.value = val.name
            transporterOptions = [...transporterOptions, test]
          }
        })
        setTransporterData(transporterOptions);
      })

  }, []);

  useEffect(() => {
    if (type) {
      axios
        .get(`${assetBaseURL}/assets/type?type=${type}&page=0&size=100`)
        .then((res: any) => {
          // console.log(res.data.data)
          setAssetName(res.data.data);
        })
        .catch((err: any) => {
          setAssetName([]);
        });
    }

  }, [type]);

 

  // console.log(assetData, "assetData")
  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement" style={{marginTop:'20px'}}>
          <Grid container spacing={3} style={{ marginLeft: "2rem" }}>
            <Grid item xs={12} >
              <Grid item xs={12} lg={6} style={{float:"right",width:'460px',height:'56px',marginTop:"79px"}}>
                <br /><br />
                <Grid item xs={12} lg={6} style={{marginTop:"-130px",marginLeft:"-120px"}}>
                <p style={{ fontSize: "16px",marginLeft:'1px',color:"#000"}} >
                  Attach to Customer
                </p>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" style={{marginTop:"7px",marginLeft:"-3px"}}>
                    Transporter
                  </InputLabel>
                  <Select
                    style={{ width:'514px',height:'48px',marginTop:"6px",color:"#000" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="AssetType"
                    label="AssetType"
                    onChange={changeHandler}
                    value={assetState.AssetName}
                  >
                    {assetData.map((option: any) => {
                      return (
                        <MenuItem key={option.id} style={{color:"#000"}} value={option.name}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
              </Grid>
                
              </Grid>
              <Grid item xs={12} lg={6} >
                <p style={{ fontSize: "16px",marginLeft:'-2px',marginTop:"-10px",color:"#000"}} >
                  Asset Details
                </p>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" style={{marginTop:"10px",marginLeft:"-2px"}}>
                    Asset type
                  </InputLabel>
                  <Select
                    style={{ width:'514px',height:'48px',marginTop:"8px" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="AssetType"
                    label="AssetType"
                    onChange={changeHandler}
                    value={assetState.AssetName}
                  >
                    {assetData.map((option: any) => {
                      return (
                        <MenuItem key={option.id} style={{color:"#000"}} value={option.name}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
          
                <br />
              </Grid>
              <br />
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" style={{marginTop:"16px",marginLeft:"-3px"}}>
                    Asset Number
                  </InputLabel>
                 <Select
                    style={{ width:'514px',height:'48px',marginTop:"13px" }}
                    labelId="demo-simple-select-label"
                    data-testid="assetNumber"
                    id="demo-simple-select"
                    name="AssetNumber"
                    label="AssetID"
                    onChange={changeHandler}
                    value={assetState.AssetID}
                  >
                    {assetName.map((option: any) => {
                      return (
                        <MenuItem key={option.id} value={option.number} style={{color:"#000"}}>
                          {option.number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
              </Grid>
              <br />
             
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" style={{marginTop:"14px",marginLeft:"-3px"}}>
                    Status
                  </InputLabel>
                  <Select
                    style={{ width:'514px',height:'48px',marginTop:"13px" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="Status"
                    label="status"
                    onChange={changeHandler}
                    value={assetState.Status}
                  >
                    <MenuItem value="Active" style={{color:"#000"}}>Active</MenuItem>
                    <MenuItem value="Inactive" style={{color:"#000"}}>Inactive</MenuItem>
                    <MenuItem value="Undermantance" style={{color:"#000"}}>Undermaintanance</MenuItem>
                  </Select>
                </FormControl>
                <br />
              </Grid>
              <FormControl fullWidth style={{marginLeft:'1px',marginTop:"36px"}}>
                  <InputLabel id="demo-simple-select-label" style={{marginTop:"1px",marginLeft:"-3px"}}>
                    Asset ID
                  </InputLabel>
                  <Select
                    style={{ width: "514px",height:"48px"}}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="CustomerAssetType"
                    label="Transporter"
                   
                    onChange={changeHandler}
                  >
                    {transporterData.map((option: any) => {
                           return (
                             <MenuItem key={option.value} value={option.value} style={{color:"#000"}}>{option.value}</MenuItem>
                           )
                         })}
                          <br />
                  </Select>
                </FormControl>
                <br />
              <br/>
             
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
}