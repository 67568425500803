
import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { GradientCircularProgress } from "react-circular-gradient-progress";
import Stack from "@mui/material/Stack";
import { setAssetToken } from "../../../../services/tokens";
import ShipperHelper, { AvgIcons } from "../../../../services/_helpers";
import TelemeteryAPI from "../../../../services/telemeteryService";
import Plot from "react-plotly.js";
import axios from "axios";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { telemetry } from "../../../../services/_url";
import { Console } from "console";
import { dateCalculator } from '../../Utils/dateUtil';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    

  })
);

export default function KitInfo(props: any) {
  const classes = useStyles();
  const [batteryLevel, setBatteryLevel] = useState<number>();
  const [Charging, setCharging] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [LastSyncTime, setLastSyncTime] = useState("");
  const [number, setNumber] = useState('');
  const [source, setSource] = useState({
    lat: "",
    long: "",
    location: "",
  });
  const [destination, setDestination] = useState({
    lat: "",
    long: "",
    location: "",
    lastRecordedAt: "",
  });
  const [legents, setLegents] = useState<any>({live: true});
  const [tagboxData, setTagboxData] = useState({
    Temperature: {
      avg: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
    Humidity: {
      avg: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
      xAxis: {},
    },
    Pressure: {
      avg: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
    LidOpenEvents: {
      total: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
    ShockEvents: {
      total: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
  });

  const [tagMMIData, setTagMMIData] = useState({
    Temperature: {
      avg: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
    Humidity: {
      avg: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
      xAxis: {},
    },
    Pressure: {
      avg: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
    LidOpenEvents: {
      total: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
    ShockEvents: {
      total: "",
      variation: "",
      flag: "",
      graphData: [],
      graph: {},
      line: {},
    },
  });
  let dateHelper = new ShipperHelper();
  const periodDates = new ShipperHelper();


  const telemeteryService = new TelemeteryAPI();

  const getGraphData1 = async(value :any) => {
    try {
      props.loadingCircleData(true);
      setIsLoader(false)
      let subDay = props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30;
      // const to = moment().format("YYYYMMDDHHMM");
      // const from = moment().subtract(subDay, 'days').format("YYYYMMDDHHMM");
      let number = props?.selectedVehicle?.assetType?.name === 'Reefer Truck' ? `KA52A${props.selectedVehicle.number}` : props.selectedVehicle.number;
      // const result = await telemeteryService.getMapAndTelemetryData(props.filter, props.fromAndTo, props.gatewayId, props.tagId)
      let result: any = []
      // if(props?.selectedVehicle?.assetType?.name === 'Active Cooler Box') {
        // let data =  await axios.get(`https://2wdata-puller.maas-diin.com/tel/api/v1/location?no_of_days=${props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30}`)
        // // console.log(data.data.data,"device");
        
        // data.data?.data?.map((ele: any) => {
          
        //   if(props.tagId.includes(ele.deviceId)) {
        //     result.push({
        //       humi1: ele.humidity,
        //       lux1: ele.light,
        //       temp1: ele.temperature,
        //       time: ele.timestamp,
        //       mov_detected1: ele.movement_detection 
        //     })
        //   }
          
        // });
      // } else {
        const date = dateHelper.parseFromAndToDateToGmt(props.filter, props.fromAndTo);
        if(value === "MMI"){
          result = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${date.fromDate}&to=${date.toDate}&assetId=${props.assetId}&sid=102`)
      } else if( value === "Intellicar"){
        result = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${date.fromDate}&to=${date.toDate}&assetId=${props.assetId}&sid=101`)
      }
      // }
      // const result: any = await telemeteryService

      setIsLoader(true)
      props.loadingCircleData(false);
      // console.log(result,"hwresult");
      //   .getEpData(type, props.tagId, props.filter, props.fromAndTo, props.assetId, typeP)
      if (result) {
        result.data = result.data.reverse();
        var tempX: any[] = [];
        var tempY: any[] = [];
        var tempYLine: any[] = [];
        var lightsX: any[] = [];
        var lightsY: any[] = [];
        var lightsYLine: any[] = [];
        var shocksX: any[] = [];
        var shocksY: any[] = [];
        var shocksYLine: any[] = [];
        /*istanbul ignore next */

        result.data?.map((x1: any) => {
          
            if (x1["temp"] !== 0) {
              tempX.push(dateHelper.parseDateToLocal(x1.ts));
              let x = x1["temp"];
                let y =Number(x);
                tempY.push(y);
              tempYLine.push(y);
            }
            
            if (+x1.lo) {
              lightsX.push(dateHelper.parseDateToLocal(x1.ts));
              lightsY.push(+x1.lo === 0 ? 0 : 1 );  
              lightsYLine.push(+x1.lo === 0 ? 0 : 1);
            }
            if (x1.se ) {
              shocksX.push(dateHelper.parseDateToLocal(x1.ts));
              shocksY.push(x1.lo ? 1 : 0);
              shocksYLine.push(+x1.lo === 0 ? 0 : 1);
              
            }
            
          });
          
          setTagboxData((prevState) => ({
          ...prevState,
          Temperature: {
            avg: "21.75",
            variation: "6.75",
            flag: "above range",
            graphData: [result, tempY],
            graph: {
              x: tempX,
              y: tempY,
              yaxis: "y3",
              mode: "lines",
              type: "lines",
              connectgaps: 'true',
              uirevision: 'true',
              line: { shape: "lines", color: "#CB2B39", width: 2 },
              // name: `${value} Temperature`,
              name: "Temperature",

              marker: {
                Symbol: "square",
                //      orientation:'h',
                color: "#CB2B39",
                size: 0,

              },
            },
            line: {
              x: tempX,
              y: tempYLine,
              mode: "lines",
              type: "lines",
              showlegend: true,

              line: {
                color: periodDates.LineColor([result?.flag, tempYLine]),
                dash: "line",
                width: 2,
              }
            },
          },
        }));
        setAssetToken("false");
        props.loadingCircleData(false);
      

    
        setTagboxData((prevState) => ({
          ...prevState,
          LidOpenEvents: {
            total: result?.length,
            variation: "69",
            flag: 'above range',
            graphData: result,
            graph: {
              x: lightsX,
              y: lightsY,
              yaxis: 'y2',
              mode: "markers",
              type: "scater",
              line: { shape: "lines", color: "#3278A0", width: 2 },
              // name: `${value} Lid Open Events`,
              name: 'Lid Open Events',
              marker: {
                Symbol: "square",
                color: "#3278A0",
                size: 0,
                //         orientation: "h"
              },
            },
            line: {
              x: lightsX,
              y: lightsYLine,
              mode: "lines",
              type: "lines",
              showlegend: false,

              line: {
                shape: "lines", color: periodDates.LineColor(result?.flag),

                width: 2,
              },
            },
          },
        }));

        setAssetToken("false");
        props.loadingCircleData(false);
      

      // if (result != null && typeP === 'shocks') {
    
      setTagboxData((prevState) => ({
          ...prevState,
          ShockEvents: {
            total: result?.length,
            variation: '69',
            flag: 'above range',
            graphData: result,
            graph: {
              x: shocksX,
              y: shocksY,
              mode: "markers",
              type: "scater",
              line: { shape: "lines", color: "#696764", width: 2 },
             
              name: "Shock Events",
              legendtext:{
                fill:"#E0E0E0" 
              },
             
              marker: {
                Symbol: "square",
                color: "#696764",
                size: 0,
              },
            },
            line: {
              x: shocksX,
              y: shocksYLine,
              mode: "lines",
              type: "lines",
              showlegend: false,

              line: {
                shape: "lines",
                color: periodDates.LineColor(result?.flag),
                dash: "lines",
                width: 2,
              },
            },
          },
        }));

        setAssetToken("false");
        props.loadingCircleData(false);
      }
    }
    catch (error) {
      // console.log(error, 'error')
      props.loadingCircleData(false);
    }

  }
  const getGraphData = async () => {
    try {
      props.loadingCircleData(true);
      setIsLoader(false)
      let number = props?.selectedVehicle?.assetType?.name === 'Reefer Truck' ? `KA52A${props.selectedVehicle.number}` : props.selectedVehicle.number;
      // const result = await telemeteryService.getMapAndTelemetryData(props.filter, props.fromAndTo, props.gatewayId, props.tagId)
      let result: any = []
      let isMobile = false
      if(props?.selectedVehicle?.assetType?.name === 'Active Cooler Box') {
        const date = dateCalculator(props.filter, props.fromAndTo);
        let data = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${date.fromDate}&to=${date.toDate}&assetId=${props.selectedVehicle.id}&fetchtype=cb&tId=${props.tagId}`)
        // let data = await axios.get(`https://2wdata-puller.maas-diin.com/tel/api/v1/location?no_of_days=${props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30}`)
        data.data.data = data.data.data ? data.data.data : data.data;
        data.data.data.sort((a: any, b: any) => new Date(a.dts).valueOf() - new Date(b.dts).valueOf())
        isMobile = true
        data.data?.data?.map((ele: any) => {
          
          if(props.tagId.includes(ele.deviceId || ele.tid)) {
            if(ele.temp && ele.ts){
              result.push({
                humi1: ele.li,
                lux1: ele.lo >= 1 ? 1 : 0,
                temp1: ele.temp,
                time: ele.dts,
                mov_detected1: ele.se 
              })
            }
           
          }
          
        });

        // let data =  await axios.get(`https://2wdata-puller.maas-diin.com/tel/api/v1/location?no_of_days=${props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30}`)
        // console.log(data, 'hello 1')
        // data.data?.data?.map((ele: any) => {
          
        //   if(props.tagId.includes(ele.deviceId)) {
        //     result.push({
        //       humi1: ele.humidity,
        //       lux1: ele.light,
        //       temp1: ele.temperature,
        //       time: ele.timestamp,
        //       mov_detected1: ele.movement_detection 
        //     })
        //   }
          
        // });
      } else {
        const data: any =  await telemeteryService.getMapAndTelemetryData(props.filter, props.fromAndTo, props.gatewayId, props.tagId, number, true, props.assetId, props.type);
        result = data.data?.data?.data || data.data || null;
      }
      // const result: any = await telemeteryService

      setIsLoader(true)
      props.loadingCircleData(false);
      // console.log(props,"location?no_of_days");
      //   .getEpData(type, props.tagId, props.filter, props.fromAndTo, props.assetId, typeP)
      if (result) {
        result = result.reverse();
        var tempX: any[] = [];
        var tempY: any[] = [];
        var tempYLine: any[] = [];
        var lightsX: any[] = [];
        var lightsY: any[] = [];
        var lightsYLine: any[] = [];
        var shocksX: any[] = [];
        var shocksY: any[] = [];
        var shocksYLine: any[] = [];
        // Sorting the temperature date data by oldest to newest
        result.sort((a: any, b: any) => new Date(a.time).getTime() - new Date(b.time).getTime());
        /*istanbul ignore next */
        result.map((x1: any) => {
          const time = x1.time || x1.ts;

          if ((+x1.tempvalue || +x1.temp || +x1.temp1) && time) {
            tempX.push(dateHelper.parseDateToLocal(time, isMobile));
            // tempX.push(time);
            let x = x1["tempvalue"] || x1["temp"] ||  x1["temp1"];
            let y;
            if (x) {
              y =
                props?.selectedVehicle?.assetType?.name === "Reefer Truck"
                  ? x
                  : x;
              tempY.push(y);
              // (Math.floor(x1["temp"]),x,"if");
            }

            tempYLine.push(y);
          }
          if ((+x1.lux1 || +x1.lo || +x1.lux) && time) {
            // const time = x1.time || x1.ts;
            lightsX.push(dateHelper.parseDateToLocal(time, isMobile));
            const yAxis = x1.lux1 || x1.lo || x1.lux;
            lightsY.push(yAxis === 0 ? 0 : 1);
            lightsYLine.push(yAxis === 0 ? 0 : 1);
          }
          if ((x1.mov_detected1 || x1.se || x1.mov_detected) && time) {
            // const time = x1.time || x1.ts;
            shocksX.push(dateHelper.parseDateToLocal(time, isMobile));
            const yAxis = x1.mov_detected1 || x1.se || x1.mov_detected;
            shocksY.push(yAxis ? 1 : 0);
            shocksYLine.push(yAxis ? 1 : 0);
            // console.log(x1.shock1,"if");
          }
        });
          // console.log(shocksY,"temp");
        setTagboxData((prevState) => ({
          ...prevState,
          Temperature: {
            avg: "21.75",
            variation: "6.75",
            flag: "above range",
            graphData: [result, tempY],
            graph: {
              x: tempX,
              y: tempY,
              yaxis: "y3",
              mode: "lines",
              type: "lines",
              connectgaps: 'true',
              uirevision: 'true',
              line: { shape: "lines", color: "#CB2B39", width: 2 },
              name: "Temperature",
              marker: {
                Symbol: "square",
                //      orientation:'h',
                color: "#CB2B39",
                size: 10,

              },
            },
            line: {
              x: tempX,
              y: tempYLine,
              mode: "lines",
              type: "lines",
              showlegend: true,

              line: {
                color: periodDates.LineColor([result?.flag, tempYLine]),
                dash: "line",
                width: 2,
              }
            },
          },
        }));
        setAssetToken("false");
        props.loadingCircleData(false);
      

    
        setTagboxData((prevState) => ({
          ...prevState,
          LidOpenEvents: {
            total: result?.length,
            variation: "69",
            flag: 'above range',
            graphData: result,
            graph: {
              x: lightsX || [],
              y: lightsY || [],
              yaxis: 'y2',
              mode: "markers",
              type: "scater",
              line: { shape: "lines", color: "#3278A0", width: 2 },
              name: "Lid Open Events",
              marker: {
                Symbol: "square",
                color: "#3278A0",
                size: 10,
                //         orientation: "h"
              },
            },
            line: {
              x: lightsX,
              y: lightsYLine,
              mode: "lines",
              type: "lines",
              showlegend: false,

              line: {
                shape: "lines", color: periodDates.LineColor(result?.flag),

                width: 2,
              },
            },
          },
        }));

        setAssetToken("false");
        props.loadingCircleData(false);
      

      // if (result != null && typeP === 'shocks') {
    
        setTagboxData((prevState) => ({
          ...prevState,
          ShockEvents: {
            total: result?.length,
            variation: '69',
            flag: 'above range',
            graphData: result,
            graph: {
              x: shocksX || [],
              y: shocksY || [],
              mode: "markers",
              type: "scater",
              line: { shape: "lines", color: "#696764", width: 2 },
             
              name: "Shock Events",
              legendtext:{
                fill:"#E0E0E0" 
              },
             
              marker: {
                Symbol: "square",
                color: "#696764",
                size: 10,
              },
            },
            line: {
              x: shocksX,
              y: shocksYLine,
              mode: "lines",
              type: "lines",
              showlegend: false,

              line: {
                shape: "lines",
                color: periodDates.LineColor(result?.flag),
                dash: "lines",
                width: 2,
              },
            },
          },
        }));
        if(legents.MMI){
          getGraphData1("MMI")
        }
        if(legents.Intellicar){
          getGraphData1("Intellicar")
        }
        setAssetToken("false");
        props.loadingCircleData(false);
      }
    }
    catch (error) {
      // console.log(error, 'error')
      props.loadingCircleData(false);
    }

  }

  useEffect(() => {
    if(legents.live) {
      getGraphData()
    }
    if(legents.MMI && !legents.live){
      getGraphData1("MMI")
    }
    if(legents.Intellicar && !legents.live){
      getGraphData1("Intellicar")
    }
  }, [legents])

  const handleLegents = (value:any) => {
    const orginalValue = { ...legents };
    orginalValue[value] = !orginalValue[value];
    setLegents(orginalValue)
  }
  const getLocationData = async () => {
    if(props?.selectedVehicle?.assetType?.name === 'Reefer Truck' || props?.selectedVehicle?.assetType?.name === '3W_EV' || props?.selectedVehicle?.assetType?.name === 'Active Cooler Box'){
      props.loadingCircleData(true);
      let result: any = {data: { data: { data: {} }}}
      let isMobile = false
       if(props?.selectedVehicle?.assetType?.name === 'Active Cooler Box'){
        const date = dateCalculator(props.filter, props.fromAndTo);
        let data = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${date.fromDate}&to=${date.toDate}&assetId=${props.selectedVehicle.id}&fetchtype=cb&tId=${props.tagId}`)
        // let data = await axios.get(`https://2wdata-puller.maas-diin.com/tel/api/v1/location?no_of_days=${props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30}`)
        data.data.data = data.data.data ? data.data.data : data.data;
        isMobile = true;
        // data.data.data.sort((a: any, b: any) => b.ts - a.ts)
        data.data.data.sort((a: any, b: any) => new Date(b.ts).valueOf() - new Date(a.ts).valueOf())
        setLastSyncTime(data.data.data[0].dts)
        result.data.data.data = {
          commtime: data.data.data[0] ? (data?.data?.data[0].timestamp) : "",
          lat: data.data.data[0] ? data?.data?.data[0].lat : "",
          lng: data.data.data[0] ? data?.data?.data[0].lng : ""
        }
      }
      else {
        result = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck?vehicle_number=${props?.selectedVehicle?.assetType?.name === '2W_EV' || props?.selectedVehicle?.assetType?.name === '3W_EV' ? props?.selectedVehicle?.number : `KA52A${props?.selectedVehicle?.number}`}`)
      }
    let num = props?.selectedVehicle?.assetType?.name === '2W_EV' || props?.selectedVehicle?.assetType?.name === '3W_EV' || props?.selectedVehicle?.assetType?.name === 'Active Cooler Box' ? props?.selectedVehicle?.number : `KA52A${props?.selectedVehicle?.number}`
    setNumber(num)
    if(result?.data?.data?.data?.commtime){
      setLastSyncTime(result?.data?.data?.data?.commtime)
    }
    setDestination({
      lat: "",
      long: "",
      location: "",
      lastRecordedAt: result?.data?.data?.data?.commtime,
    });
    const getSourceData: any = await telemeteryService
      .getLocationMapMyIndia(
        result?.data?.data?.data?.lat,
        result?.data?.data?.data?.lng,
      )
    setSource((pre) => ({
      ...pre,
      lat: result?.data?.data?.data?.lat,
      long: result?.data?.data?.data?.lng,
      location:
        getSourceData.data.results[0]["locality"] +
        " ," +
        getSourceData.data.results[0]["city"],
    }));
    // const getDestination: any = await telemeteryService
    //   .getLocationMapMyIndia(
    //     result?.data?.data?.data?.lat,
    //     result?.data?.data?.data?.lng,
    //   )
    setDestination((pre) => ({
      ...pre,
      lat: result?.data?.data?.data?.lat,
      long: result?.data?.data?.data?.lng,
      location:
        getSourceData.data.results[0]["locality"] +
        " ," +
        getSourceData.data.results[0]["city"],

    }));
    props.loadingCircleData(false);
  }
  }

  const getBatterData = async () => {
    const batteryData: any = await telemeteryService
      .getGatewayLogBattery(props.gatewayId, props.filter, props.fromAndTo)
    setBatteryLevel(batteryData.data.batteryLevel);
  }

  useEffect(() => {

    if (props?.selectedVehicle?.assetType?.name === 'Reefer Truck' || props?.selectedVehicle?.assetType?.name === '3W_EV' || props?.selectedVehicle?.assetType?.name === 'Active Cooler Box') {
      props.ChangehardwarekitData();
      getGraphData();
      // getGraphData1();
      // getGraphData("assetLights", "lights")
      // getGraphData("assetShocks", "shocks")
      getLocationData()
      // getBatterData()
    } else {
      setIsLoader(true)
    }
    // console.log(new Date(),"HardwareInfo GraphEnd")
  }, [props.hardwarekitData, props.selectedVehicle.number, props.filter, props.fromAndTo]);

  return (
    <div className={classes.root}>
      <Grid
        item
        lg={12}
        xs={12}
        md={6}
        sm={12}
        // className="vl"
        style={{ marginLeft: "1rem", marginBottom: "1rem" }}
      >
        <p className="gateway">Gateway</p>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ paddingLeft: "1rem", justifyContent: 'space-between' }}
        >
          <Grid item>
            <p id="gatewayTitle">Gateway ID</p>
            <p id="gatewayContent">{props.gatewayId}</p>
          </Grid>
          <Grid item>
            <p id="gatewayTitle">LAST SYNC AT</p>
            <p id="gatewayContent">
              {LastSyncTime ? dateHelper.dateConversion(LastSyncTime) : "---"}
            </p>
          </Grid>
          <Grid item>
            <p id="gatewayTitle">GPS SOURCE</p>
            <p id="gatewayContent">
              {source.location ? source.location : "---"}
            </p>
          </Grid>
          <Grid item>
            <p id="gatewayTitle">GPS DESTINATION</p>
            <p id="gatewayContent">
              {destination.location ? destination.location : "---"}
            </p>
          </Grid>
          <p style={{ borderRight: "2px solid rgba(0, 0, 0, .125)", height: "3.5rem" }}>
          </p>



          <Grid item style={{ marginRight: "3.3rem" }}>
            <p style={{color:"#000",fontSize:"13px"}}>Battery Status</p>
            {batteryLevel >= 0 ? (
              <>
                <GradientCircularProgress
                  size={100}
                  progress={batteryLevel}
                  withSnail={true}
                  startColor="#1A0DAB"
                  middleColor="#5A51BCDB"
                  endColor="#827CC94F"
                ></GradientCircularProgress>
                <span style={{ display: "flex", justifyContent: "center" }}>
                  {Charging}
                </span>
              </>
            ) : (
              <p style={{ color:"#000" }}>---</p>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:"-40px"}}>
        <Grid item xs={9} lg={9}>
          <Stack direction="column" spacing={2} className="v1" style={{ height: "380px" }}>
            <div>
              <Stack direction="row">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    {tagboxData.Temperature.graphData.length || tagboxData.ShockEvents.graphData.length || tagboxData.LidOpenEvents.graphData.length ? (
                      <Grid
                        item>
                        <Plot
                          data={[
                            tagboxData.Temperature.graph,
                            tagboxData.ShockEvents.graph,
                            tagboxData.LidOpenEvents.graph
                          ]}
                          layout={{
                            margin: { l: 70, r: 70, b: 90, t: 0 },
                            width: 800,
                            height:380,
                            legend: { xanchor: "center", x: 0.5, y: 1.15, orientation: 'h' },

                            //      legend: {"orientation": "h"},
                            hovermode: "x unified",

                            xaxis: { domain: [0.1, 6.7], autorange: true, nticks: 10, linewidth: 0.25, rangemode: 'nonnegative' },
                            yaxis: 
                           { 
                            // showticklabels: false
                            title: "Shock Events (0-No and 1-Yes)", 
                           //linewidth: 0.25, position: -0.5, 
                           //showtickprefix: "all", 
                           //ticks: 'outside', 
                           //linecolor: '#5B5B5B'
                          },
                            yaxis2: {
                              ticks: 'outside',
                              title: "Lid Open Events(0-NO  and 1-Yes)",
                              showtickprefix: 'all',
                              linecolor: '#3278A0',
                              linewidth: 0.25,
                              position: 2.5,
                              overlaying: 'y',
                              anchor: "x",
                              side: 'right',
                              rangemode: 'tozero',
                              titlefont: { color: '#3278A0' },
                              tickfont: { color: '#3278A0' },
                            },
                            yaxis3: {
                              ticks: 'inside',
                              title: "Temperature °C",
                              linecolor: '#CB2B39',
                              linewidth: 0.75,
                              showline: true,
                              overlaying: "y",
                              anchor: "free",
                              showtickprefix: "all",
                              position: 2.5,
                              rangemode: 'tozero',
                              titlefont: { color: '#CB2B39' },
                              tickfont: { color: '#CB2B39' },
                              side: 'left'
                            },
                            showlegend: true,

                          }}
                          style={{ width: "1000" }}
                          config={{
                            displayModeBar: false,
                            responsive: true,
                            displaylogo: false,
                          }}
                        />
                      </Grid>
                    ) : (
                      <h2>{isLoader ? 'Check Hardware KIT' : '' }</h2>
                    )}
                  </Grid>
                </Grid>
              </Stack>
              
              {
                legents.Intellicar || legents.MMI && <Stack direction="row">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    {(tagMMIData.Temperature.graphData.length || tagMMIData.ShockEvents.graphData.length || tagMMIData.LidOpenEvents.graphData.length)  ? (
                      <Grid
                        item>
                        <Plot
                          data={[
                            tagMMIData.Temperature.graph,
                            tagMMIData.LidOpenEvents.graph,
                            tagMMIData.ShockEvents.graph,
                          ]}
                          layout={{
                            margin: { l: 70, r: 70, b: 90, t: 0 },
                            width: 800,
                            height:380,
                            title: `${legents.Intellicar ? 'Intellicar' : 'MMI'}`,
                            legend: { xanchor: "center", x: 0.5, y: 1.15, orientation: 'h' },

                            //      legend: {"orientation": "h"},
                            hovermode: "x unified",
                            
                            xaxis: { domain: [0.1, 6.7], autorange: true, nticks: 10, linewidth: 0.25, rangemode: 'nonnegative' },
                            yaxis: 
                           { 
                            showticklabels: false
                            // title: "Shock Events (0-No and 1-Yes)", 
                           //linewidth: 0.25, position: -0.5, 
                           //showtickprefix: "all", 
                           //ticks: 'outside', 
                           //linecolor: '#5B5B5B'
                          },
                            yaxis2: {
                              ticks: 'outside',
                              title: "Lid Open Events(0-NO  and 1-Yes)",
                              showtickprefix: 'all',
                              linecolor: '#3278A0',
                              linewidth: 0.25,
                              position: 2.5,
                              overlaying: 'y',
                              anchor: "x",
                              side: 'right',
                              rangemode: 'tozero',
                              titlefont: { color: '#3278A0' },
                              tickfont: { color: '#3278A0' },
                            },
                            yaxis3: {
                              ticks: 'inside',
                              title: "Temperature °C",
                              linecolor: '#CB2B39',
                              linewidth: 0.75,
                              showline: true,
                              overlaying: "y",
                              anchor: "free",
                              showtickprefix: "all",
                              position: 0.098,
                              rangemode: 'tozero',
                              titlefont: { color: '#CB2B39' },
                              tickfont: { color: '#CB2B39' },
                              side: 'left'
                            },
                            showlegend: true,

                          }}
                          style={{ width: "1000" }}
                          config={{
                            displayModeBar: false,
                            responsive: true,
                            displaylogo: false,
                          }}
                        />
                      </Grid>
                    ) : (
                      <h2>{isLoader ? 'Check Hardware KIT' : '' }</h2>
                    )}
                  </Grid>
                </Grid>
              </Stack>
                
              }
              
            </div>
            {props.type === 99 && ( 
            <div className="hoverDiv" style={{ marginTop: "2rem", marginRight: "-2.6rem", height: "6rem" ,width:"5rem"}}>
            <span style={{ marginRight: "-10rem" }}>
              <p className="hoverP1" style={{ fontWeight: legents && legents?.MMI ? 'bold' : 'normal',marginTop:"-7px",color:"#360f75" }} onClick={() => handleLegents("MMI")}>MMI</p>
              <p className="hoverP1" style={{ fontWeight: legents && legents?.Intellicar ? 'bold' : 'normal',marginTop:"-7px",color:"#f56a07" }} onClick={() => handleLegents("Intellicar")}>Intellicar</p>
              <p className="hoverP1" style={{ fontWeight: legents && legents?.live ? 'bold' : 'normal',marginTop:"-7px", color:"#360f75" }} onClick={() => handleLegents("live")}>Live</p>
            </span>
          </div>
          )} 
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}