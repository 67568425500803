import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CollapseTable from '../../Utils/collapse';
import {  useRowStyles } from './styles';
import './style.css';
import '../style.css';
import ShipperHelper, { ShippingRequestStatus } from '../../../../services/_helpers';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TableManageColumnsFormData } from '../../Dashboard/Common/Interfaces/custom';
import ChallanGenerator from '../../common/PdfChallan/ChallanGenerator';
import { useHistory } from 'react-router-dom';

function Row(props: { row: ReturnType<any>; setFetchTableFlag: ReturnType<any>, manageColumnsOptions: TableManageColumnsFormData[] }) {
  const { row, manageColumnsOptions } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();


  const ShipperLandingPage = (requestNumber:any) => {
    history.push(`/shipper/${requestNumber}`)
  }

  const formatPrice = (price: number) => {
    if (!isNaN(price)) {
      return price.toFixed(2);
    }
    return price ? price : '---';
  }
  
let dateHelper =new ShipperHelper();
const cells = [
  { defaultOrder: 1, render: (
    <TableCell align="left" aria-label="statusred">
        <span aria-label="statusspan" id={
          (ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance  ? 'statusRed' :
            (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Completed ? 'statusGreen' : 
            (ShippingRequestStatus[row?.status] ===ShippingRequestStatus.Cancelled ? 'statusGrey' : 
            (ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ? 'statusRed' : 
            (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Accepted ? 'statusAccepted' :
              (ShippingRequestStatus[row?.status] === ShippingRequestStatus.InTransit ? 'statusOrange' : 'statusRed'))))))
        } className="capitalise-case">
          { ShippingRequestStatus[row?.status] }
        </span>
        {/* <span id={statusCode} className="capitalise-case">{row?.status}</span> */}

          </TableCell>
  )},
  { defaultOrder: 2, render: (
    <TableCell component="th" scope="row" aria-label="shipperrequest">      
      <Button className={classes.requestId} data-testid="landpage" onClick={() => ShipperLandingPage(row?.requestNumber)} >
          {row?.requestNumber}
      </Button>
    </TableCell>
  )},
  { defaultOrder: 3, render: (
    <TableCell >{(row?.transporter?.name)?(row?.transporter?.name):"----"}</TableCell>
  )},
  { defaultOrder: 4, render: (
    <TableCell align="right">{formatPrice(row?.paymentDetails?.actualAmount )}</TableCell>
  )},
  { defaultOrder: 5, render: (
    <TableCell align="left" >{dateHelper.dateConversion(row?.createdAt)}</TableCell>
  )}
]



  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosemenu = () => {
    setAnchorEl(null);
  }; 

  const [openPDF, setOpenPDF] = React.useState(false);
  const handleOpenPDF = () => setOpenPDF(true);
  const handleClosePDF = () => setOpenPDF(false);
  
  // const UpdateRequest=()=>{
  //   return(
  //   <div className="d-flex flex-row bd-highlight mb-1 " >
  //       <span className="p-2 bd-highlight align-self-center " style={{backgroundColor:'#1383AF',padding:0,margin:0,height:'72px',width:'54px'}}><InfoIcon style={{color:'#fff',marginLeft:'8px'}}/></span>
  //       <span className="p-1 bd-highlight align-self-center"> 
  //       <span className="p-1 bd-highlight align-self-center">Request already serviced. Could not update the shipment request.</span>
       
  //        <p className="p-1 bd-highlight align-self-center">Please try creating a new request.</p></span>

  //       </div>
  //   )}
//   const updateRequestAlert = () => {
// toast.info(<UpdateRequest/> , {
//         position: "bottom-right",
//         toastId: customId,
//         // autoClose: false,
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         closeButton:false
//         });
//       }

  return (
    <React.Fragment>
      <TableRow className={open ? classes.rowTop : ''} data-testid="pasts">
        <TableCell data-testid="pastcell" >
          
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </TableCell>
        { 
            manageColumnsOptions.sort((a: TableManageColumnsFormData, b: TableManageColumnsFormData) => {
              return a.order - b.order;
            }).map((reorder: TableManageColumnsFormData) => {
              return cells.find((cell: any) => cell.defaultOrder === reorder.defaultOrder).render;
            })
          }
        <TableCell align="center" data-testid="updatecell" >
          <Button  style={{color:'#5A5A5A',opacity:'0.6', visibility:'hidden'}} data-testid="updatebtn">UPDATE</Button>
        <IconButton onClick={handleClick} component="span" aria-label="updateicon">
        <MoreVertIcon />
        </IconButton>
        <Menu
            id="simple-menu"
            data-testid="simplemenu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClosemenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
          >
            <MenuItem  data-testid="cancelmenu" onClick={handleClosemenu} disabled>Cancel</MenuItem>
            <MenuItem  data-testid="downloadchallan" onClick={handleOpenPDF} disabled={ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ? true : null}>Download Challan</MenuItem>
          </Menu>

        </TableCell>
      </TableRow>
      <TableRow data-testid="distance">
        <TableCell data-testid="feedback" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8} className={open ? classes.rowBottom : ''}>
          <Collapse in={open} timeout="auto" unmountOnExit data-testid="customer" >
           <CollapseTable data-testid="fresh" status={row?.status} row={row} feedback={["FRESHNESS INDEX","CUSTOMER FEEDBACK","DISTANCE TRAVELLED"]} />
          </Collapse>
        </TableCell>
      </TableRow>
      <div >
        <ChallanGenerator data-testid="challan" row={row} openPDF={openPDF} handleClosePDF={handleClosePDF} />
      </div>
    </React.Fragment>
  );
}

export default Row;