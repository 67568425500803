import axios from 'axios';
import { resolve4 } from 'dns';
import moment from 'moment';
import ShipperHelper from './_helpers';
import { mapMyIndiaKey, roadmap, telemetry } from './_url';
const periodDates = new ShipperHelper();

export default class TelemeteryAPI {
  getTelemetryDataByID = (id: string) => { // ${id}`)
    return axios.get(`${telemetry}/telemetry/aggregation/${id}`)
      .then(res => res.data);
  }

  getTempData = async (tagId: string, filter: string, fromAndTo: any) => {
    try {
      // console.log("second N");
      return await axios.get(`${telemetry}/telemetry/temparatures/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
  }

  getShocksData = async (tagId: string, filter: string, fromAndTo: any) => {
    try {
      return await axios.get(`${telemetry}/telemetry/shocks/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
  }
  getShocks = async () => {
    try {
      return await axios.get(`${telemetry}/telemetry/shocks`);
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
  }
  getLigtsData = async (tagId: string, filter: string, fromAndTo: any) => {
    try {
      // console.log("second N");
      return await axios.get(`${telemetry}/telemetry/lights/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
  }

  getHumidityData = async (tagId: string, filter: string, fromAndTo: any) => {
    try {
      // console.log("second N");
      return await axios.get(`${telemetry}/telemetry/humidities/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
  }

  getPresureData = async (tagId: string, filter: string, fromAndTo: any) => {
    try {
      // console.log("second N");
      return await axios.get(`${telemetry}/telemetry/pressures/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
  }
  getShock = (tagId: string, timeDuration: any, datesForTransporter: any) => {
    
    if (timeDuration === "custom") {
      return axios.get(`${telemetry}/telemetry/shocks/tagId?tagId=${tagId}&${datesForTransporter}&duration=${timeDuration}`)
        .then(res => { return res });
    }
    else {

      return axios.get(`${telemetry}/telemetry/shocks/tagId?tagId=${tagId}&${datesForTransporter}&duration=${timeDuration}`)
        .then(res => { return res });
    }
    
   
  }
  getLights = (tagId: string, timeDuration: string, datesForTransporter: any) => {
    if (timeDuration === "custom") {
    return axios.get(`${telemetry}/telemetry/lights/tagId?tagId=${tagId}&${datesForTransporter}&duration=${timeDuration}`).then(res => { return res })
  }
  else {
    return axios.get(`${telemetry}/telemetry/lights/tagId?tagId=${tagId}&${datesForTransporter}&duration=${timeDuration}`)
      .then(res => { return res });
  }
}

  getAllData = async (type: string, tagId: string, filter: string, fromAndTo: any) => {
    try {
      const responses = await Promise.all([this.getTempData(tagId, filter, fromAndTo)]);

    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
  }

  getEpData = (type: string, tagId: string, filter: string, fromAndTo: any, assetId:string, typeP:string) => {

    if (filter === "custom") {
      return axios.get(`${telemetry}/telemetry/${type}/assetId?assetId=${assetId}&${fromAndTo}&duration=${filter}`)
        .then(res => { return res }).catch((err: any) => {
          return axios.get(`${telemetry}/telemetry/${typeP}/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`)
          .then(res => { return res })  .catch((err: any) => {
            console.error('Fail', err.response );
            // setLoadFlag(false);
          });
        })
    }    
    else {
      return axios.get(`${telemetry}/telemetry/${type}/assetId?assetId=${assetId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
        .then(res => { return res }).catch((err: any) => {
          
           return axios.get(`${telemetry}/telemetry/${typeP}/tagId?tagId=${tagId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
              .then(res => { return res }).catch((err: any) => {
                console.error('Fail', err.response );
                // setLoadFlag(false);
              });
        });   
    }  
  }
  getTempDat = (tagId: string, filter: string, fromAndTo: any) => {

    if (filter === "custom") {
      return axios.get(`${telemetry}/telemetry/temparatures/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`)
        .then(res => { return res });
    }
    else {
      return axios.get(`${telemetry}/telemetry/temparatures/tagId?tagId=${tagId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
        .then(res => { return res });
    }
    // return axios.get(`${telemetry}/telemetry/${type}/tagId?tagId=${tagId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
    //   .then(res => { return res })
    //   .catch((err: any) => {
    //     console.error('Something Went Wrong while getting data', err);
    //   })
  }
  getLightDat = (tagId: string, filter: string, fromAndTo: any) => {

    if (filter === "custom") {
      return axios.get(`${telemetry}/telemetry/lights/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`)
        .then(res => { return res });
    }
    else {
      return axios.get(`${telemetry}/telemetry/lights/tagId?tagId=${tagId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
        .then(res => { return res });
    }
    // return axios.get(`${telemetry}/telemetry/${type}/tagId?tagId=${tagId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
    //   .then(res => { return res })
    //   .catch((err: any) => {
    //     console.error('Something Went Wrong while getting data', err);
    //   })
  }

  getShockDat = (tagId: string, filter: string, fromAndTo: any) => {

    if (filter === "custom") {
      return axios.get(`${telemetry}/telemetry/shocks/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`)
        .then(res => { return res });
    }
    else {
      return axios.get(`${telemetry}/telemetry/shocks/tagId?tagId=${tagId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
        .then(res => { return res });
    }

  }
  getGraphDataNew = async (tagId: string, filter: string, fromAndTo: any, assetId: string) => {
    let res = [];

    try {
      const responses1 = await this.getEpData("temparatures", tagId, filter, fromAndTo, assetId, "temperatures");
      if (responses1 != null) {
        res.push(responses1);
      }
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }

    try {
      const responses2 = await this.getEpData("lights", tagId, filter, fromAndTo, assetId, "lights");
      if (responses2 != null) {
        res.push(responses2);
      }
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }
    try {
      const responses3 = await this.getEpData("shocks", tagId, filter, fromAndTo, assetId, "shocks");
      if (responses3 != null) {
        res.push(responses3);
      }
    } catch (error) {
      console.error('Something Went Wrong while getting temparature data', error);
    }

    // console.log(res);

    return res;
  }

  getGraphData = (type: string, tagId: string, filter: string, fromAndTo: any, assetId: string) => {
    //   console.log('type', type)
    //   const tempCall = axios.get(`${telemetry}/telemetry/temparatures/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    //   const humidCall =   axios.get(`${telemetry}/telemetry/humidities/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    //   const pressureCall =  axios.get(`${telemetry}/telemetry/pressures/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    //   const lightsCall =   axios.get(`${telemetry}/telemetry/lights/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);
    //   const shocksCall =  axios.get(`${telemetry}/telemetry/shocks/tagId?tagId=${tagId}&${fromAndTo}&duration=${filter}`);

    //   axios.all([tempCall, humidCall, pressureCall, pressureCall, lightsCall, shocksCall]).then(
    //     axios.spread((...data) => {
    //       console.log("Result1", data[0])
    //       console.log("Result2", data[1])
    //       console.log("Result3", data[2])
    //       console.log("Result4", data[3])
    //       console.log("Result5", data[4])

    //     })
    //   )

    if (filter === "custom") {
      return axios.get(`${telemetry}/telemetry/${type}/assetId?assetId=${assetId}&${fromAndTo}&duration=${filter}`)
        .then(res => { return res });
    }
    else {
      return axios.get(`${telemetry}/telemetry/${type}/tagId?tagId=${tagId}&${periodDates.telemetryDate(filter)}&duration=${filter}`)
        .then(res => { return res });
    }
  }
  
  getGPSLocationDetailPage = (assetId: string, gatewayId:string) => {
    // console.log(new Date(), "gpaslocation start")
    return axios.get(`${telemetry}/telemetry/assetgpsLocation/${gatewayId}`)
    .then(res => { return res })
    .catch((err: any) => {
      // console.log(new Date(), "gpaslocation end")
      return axios.get(`${telemetry}/telemetry/gpslocation/${gatewayId}`)
      .then(res => { return res })
      .catch((err: any) => {
       // console.log(new Date(), "gpaslocation with gateway End")
  
      })
    })
  }

  getGPSLocation = (assetId: string, filter: any, fromAndTo: any, gatewayId:string) => {
    if (filter === "custom") {
      return axios.get(`${telemetry}/telemetry/assetgpsLocation/${assetId}?${fromAndTo}`).then(res => { return res })
      .catch((err: any) => {
        return axios.get(`${telemetry}/telemetry/gpslocation/${gatewayId}?${fromAndTo}`).then(res => { return res })
          
    })
    }
    else {
      return axios.get(`${telemetry}/telemetry/assetgpsLocation/${assetId}?${periodDates.telemetryDate(filter)}`)
      .then(res => { return res })
      .catch((err: any) => {
        return axios.get(`${telemetry}/telemetry/gpslocation/${gatewayId}?${periodDates.telemetryDate(filter)}`).then(res => { return res })
          
          })
  }
}
deleteHardware = (deleteId:string) => {
  return axios.delete(`${telemetry}/sensors/${deleteId}`).then(res => {return res})
}

//TODO: Commented code can be removed after the functionities are tested and works as expected
getMapAndTelemetryData = async (filter: any, fromAndTo: any,gatewayId: any, tagId: any, vehicleNumber: any = '', isHistory = false, assetId: any, type: any) => {
    let fromValue;
    let toValue;
    if(filter === "custom") {
      let date = fromAndTo.split('=')
      fromValue = date[1].split('&')[0]
      toValue = date[2]
    }
    let fromDate: any = filter === 'daily' ? moment().subtract(1, 'days'): filter === 'weekly' ? moment().subtract(7, 'days'): filter === 'monthly' ? moment().subtract(30, 'days'): filter === 'custom' ? moment(fromValue) : moment().subtract(1, 'days');
    let toDate: any = filter === 'custom' ? moment(toValue) : moment();
    let parsedFromDate = moment(fromDate).utc();
    fromDate = parsedFromDate.format("YYYYMMDDHHmm")
    let parsedToDate = moment(toDate).utc()
    toDate =  parsedToDate.format("YYYYMMDDHHmm")
    // fromDate = new Date(fromDate).valueOf()
    // toDate = new Date(toDate).valueOf();
    // const to =  filter === 'custom' ? moment(toValue).format('YYYYMMDDHHMM') : moment().add(1, 'days').format('YYYYMMDDHHMM');
    // const from = filter === 'daily' ? moment().subtract(1, 'days').format('YYYYMMDDHHMM') : filter === 'weekly' ? moment().subtract(7, 'days').format('YYYYMMDDHHMM') : filter === 'monthly' ? moment().subtract(30, 'days').format('YYYYMMDDHHMM') : filter === 'custom' ? moment(fromValue).format('YYYYMMDDHHMM') : moment().subtract(1, 'days').format('YYYYMMDDHHMM');
    
    // console.log(toDate, fromDate, 'toDate')
    // if(tagId && gatewayId && !vehicleNumber) {
    //   return await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${from}&to=${to}&tId=${tagId}&gId=${gatewayId}`)
    // }
    // if(assetId && !vehicleNumber) {
    //   return await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${from}&to=${to}&assetId=${assetId}`)
    // }
    // if(!vehicleNumber){
    // return await axios.get(`${telemetry}/telemetry/gatewayanddevice?tId=${tagId}&gId=${gatewayId}&from=${fromDate}&to=${toDate}`)
    // } else if(vehicleNumber && isHistory) {
    //    return await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/bletagshistory?vehicle_number=${vehicleNumber}&from=${fromDate}&to=${toDate}`)
    // }
    // else {
    //    return await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/history?vehicle_number=${vehicleNumber}&from=${fromDate}&to=${toDate}`)
    // }
/**
 * type 99 => Vehicle
 * type 1/2 => Sensor
 */
try{
    if(type===99) {
      return await axios.get(`${roadmap}/refer-truck/history?vehicle_number=${vehicleNumber}&from=${fromDate}&to=${toDate}`).catch(this.handleError)
    }else {
      return await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${fromDate}&to=${toDate}&assetId=${assetId}&tId=${tagId}`).catch(this.handleError)
    }
  } catch(error){
    // props.loadingCircleData(false)
  }
 


}
 handleError =(err: any): void => {
  console.log(err)
}


  getMapData = async (assetId: any, filter: any, fromAndTo: any,gatewayId: any) => {
  
    //  return axios.get("http://k8s-dev-73086b9fbd-490139425.ap-south-1.elb.amazonaws.com:8888/telemetry/assetPath/tbx-fx-A03200041?from=2021-10-10T00:25:35Z&to=2021-10-26T17:25:35Z").      
    if (filter === "custom") {
      // console.log("Start APi-->"+new Date())
      return axios.get(`${telemetry}/telemetry/assetPathLocation/${assetId}?${fromAndTo}`)
        .then(res => { 
          // console.log("end API-->" + new Date())
          return res 
        })
        .catch((err: any) => {
          // console.log(new Date(),"assetPath end")
          return axios.get(`${telemetry}/telemetry/assetPath/${gatewayId}?${fromAndTo}`)
          .then(res => { return res })
        })
    }
    else {
      return axios.get(`${telemetry}/telemetry/assetPathLocation/${assetId}?${periodDates.telemetryDate(filter)}`)
        .then(res => { return res })
        .catch((err: any) => {
          // console.log(new Date(),"assetPath End")
      return axios.get(`${telemetry}/telemetry/assetPath/${gatewayId}?${periodDates.telemetryDate(filter)}`)
        .then(res => { return res })
        })
    }
  }

  getElevationData = (latitude: any, longitude: any) => {
    return axios.get(`https://apis.mapmyindia.com/advancedmaps/v1/${mapMyIndiaKey}/elevation?locations=${latitude},${longitude}`)
      .then(res => { return res })
  }
  getLocationMapMyIndia = (latitude: any, longitude: any) => {
   // console.log(mapMyIndiaKey);
    
    return axios.get(`https://apis.mapmyindia.com/advancedmaps/v1/${mapMyIndiaKey}/rev_geocode?lat=${latitude}&lng=${longitude}`)
      .then(loc => { return loc })
      


  }
  getGatewayLogBattery = (gatewayId: string, filter: any, fromAndTo: any) => {
    if (filter === "custom") {
      return axios.get(`${telemetry}/telemetry/gatewayLog/${gatewayId}?${fromAndTo}`)
        .then(res => { return res })
    }
    else {
      return axios.get(`${telemetry}/telemetry/gatewayLog/${gatewayId}?${periodDates.telemetryDate(filter)}`)
        .then(res => { return res })
    }



    // return  axios.get(`${telemetry}/telemetry/gatewayLog/${gatewayId}?${filter}`)
    // .then(res=>{ return res})
  }
  getRouteMap = (id: string) => {
    return axios.get(`${telemetry}/telemetry/locations/${id}`)
      .then(res => { return res })

  }

  getSensorDataTemperature = (tripId: any): any => {
    return axios.get(`${telemetry}/telemetry/temparatures/${tripId}`).then((response: any) => response.data);
  }
  getSensorDataLight = (tripId: any): any => {
    return axios.get(`${telemetry}/telemetry/lights/${tripId}`).then((response: any) => response.data);
  }
  getSensorDataHumidity = (tripId: any): any => {
    return axios.get(`${telemetry}/telemetry/humidities/${tripId}`).then((response: any) => response.data);
  }
  getSensorDataShock = (tripId: any): any => {
    return axios.get(`${telemetry}/telemetry/shocks/${tripId}`).then((response: any) => response.data);
  }
  getSensorDataPressure = (tripId: any): any => {
    return axios.get(`${telemetry}/telemetry/pressures/${tripId}`).then((response: any) => response.data);
  }
  getAssetReportsData = (assetIds: any, from: any, to: any): any => {
    return axios.post(`${telemetry}/telemetry/report/reeferTruck`, { "assetIds": assetIds, "from": from, "to": to, "duration": "5" }).then((response: any) => response.data);
  }
  getAssetReportCoolerBoxDialyData = (assetIds: any, from: any, to: any): any => {
    return axios.post(`${telemetry}/telemetry/report/coolerBox`, { "assetIds": assetIds, "from": from, "to": to, "duration": "5" }).then((response: any) => response.data);
   
  }
  getAssetReportCoolerBoxTripData = (assetIds: any, from: any, to: any): any => {
    return axios.post(`${telemetry}/telemetry/report/trip/coolerBox`, { "assetIds": assetIds, "from": from, "to": to, "duration": "5" }).then((response: any) => response.data);
  }

  getBatterySocData = (assetIds: any, from: any, to: any): any => {
      //console.log(assetIds,from, to)
      if(assetIds) {
        return axios.get(`${telemetry}/telemetry/batteryData?assetId=${assetIds}&from=${from}T02:06:21.939Z&to=${to}T23:59:21.939Z`).then((response)=> response.data);
      } else {
        console.log('asset not allocate');
        return '';
      }
      
  }
}