import React, { useEffect, useState } from "react";
import { Divider, Grid, InputAdornment, NativeSelect, Select, Typography } from "@material-ui/core";
import UserService, { ROLE } from "../../../../services/userService";

import { InputField, SelectField } from "../../common/FormFields";
import "./styles.css";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ShipperAPI from "../../../../services/shippingLanding";
import { MenuItem } from "@mui/material";
export default function LocationForm(props: any) {
  const {
    formField: {
      shipdoorBuilding,
      shipaddress,
      shipcity,
      shippincode,
      shiplandmark,
      shipmobile,
      shipmobilePrefix,
      shipper,
      shipEmail,
      deldoorBuilding,
      delcity,
      delpincode,
      dellandmark,
      delmobile,
      delmobilePrefix,
      delname,
      delstreetaddress,
      delEmail,
    },
  } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        "@media (max-width:500px)": {
          width: "20rem",
        },
      },
    })
  );
  const classes = useStyles();
  const [isDenso, setIsDenso] = useState(false);
  const [transporterData, setTransporterData] = useState([]);

  useEffect(() => {
    const userApi = new UserService();
    const shipperAPI = new ShipperAPI();
    const userDetails = userApi.getUserDetails();
    /* istanbul ignore next */
    if (userDetails?.token) {
      if (userDetails.roles.includes(ROLE.denso)) {
        setIsDenso(true);
      }
    }
    shipperAPI
      .getShipperID()
      // axios.get(`${tenantandUser}/tenants`)
      .then((res: any) => {
        setTransporterData(res);
      });
  }, []);
  let transporterOptions: any = [];
  let transporterResult: any = transporterData.map((val: any) => {
    if (val.roles[0]?.name === "Shipper") {
      //test
      let test = { key: "", value: "" };
      test.key = val.id;
      test.value = val.name;
      transporterOptions = [...transporterOptions, test];
    }
  });
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className="layout-border">
          <Grid container spacing={2} >
            <Grid item xs={12} lg={6} md={6} style={{ marginTop: '-0.3%' }}>
              <Grid container spacing={2}  >

                <Grid item xs={12} lg={6} md={6} >
                  <p className="cardTitle3" >Source Details</p>
                </Grid>
                <Grid item xs={12}  >
                  <InputField
                    style={{ marginTop: "-11px", marginLeft: "-20px" }}
                    name={shipdoorBuilding.name}
                    label={shipdoorBuilding.label}
                    fullWidth

                  />
                </Grid>
                <Grid item xs={12}  >
                  <InputField
                    style={{ marginLeft: "-20px" }}
                    name={shipaddress.name}
                    label={shipaddress.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}  >
                  <InputField
                    style={{ marginLeft: "-20px" }}
                    name={shipcity.name}
                    label={shipcity.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}  >
                  <InputField
                    style={{ marginLeft: "-20px" }}
                    name={shippincode.name}
                    label={shippincode.label}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    fullWidth
                    type="number"

                  />
                </Grid>
                <Grid item xs={12}  >
                  <InputField
                    style={{ marginLeft: "-20px" }}
                    name={shiplandmark.name}
                    label={shiplandmark.label}
                    fullWidth
                  />
                </Grid>
                {isDenso ? (
                  <>
                    <Grid item xs={12}  >
                      <InputField
                        style={{ marginLeft: "-20px" }}
                        name={shipmobile.name}
                        label="Shipper's Mobile Number"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: (
                            // <InputAdornment position="start">
                            
                            <SelectField
                            defaultValue="+91"
                            style={{width: "104%"}}
                        name={shipmobilePrefix.name}
                        options={[{key: "+91", value: "+91"}, {key: "+62", value: "+62"}]}

                      />
                            // <Select
                            //   labelId="demo-simple-select-standard-label"
                            //   id="demo-simple-select-standard"
                            //   defaultValue={"+91"}
                            //   name={`${shipmobilePrefix.name}`}
                            // >

                            //   <MenuItem value={"+91"}>+91</MenuItem>
                            //   <MenuItem value={"+62"}>+81</MenuItem>
                            // </Select>
                            // </InputAdornment>
                          ),
                        }}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginLeft: "-20px" }}>
                      <SelectField

                        name={shipper.name}
                        label={shipper.label}
                        labelWidth={62}
                        options={transporterOptions}

                        fullWidth

                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} >
                    <InputField
                      name={shipmobile.name}
                      label={shipmobile.label}
                      fullWidth
                      style={{ marginLeft: "-20px" }}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <SelectField
                            defaultValue="+91"
                            style={{width: "104%"}}
                        name={shipmobilePrefix.name}
                        options={[{key: "+91", value: "+91"}, {key: "+62", value: "+62"}]}

                      />
                          // <Select
                          //   labelId="demo-simple-select-standard-label"
                          //   id="demo-simple-select-standard"
                          //   defaultValue={"+91"}
                          //   name={`${shipmobilePrefix.name}`}
                          // >

                          //   <MenuItem value={"+91"}>+91</MenuItem>
                          //   <MenuItem value={"+62"}>+81</MenuItem>
                          // </Select>
                        ),
                      }}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                    />
                  </Grid>
                )}
               <Grid item xs={12}  >
                  <InputField
                    style={{ marginLeft: "-20px" }}
                    name={shipEmail.name}
                    label={shipEmail.label}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className="verticalLine" style={{ marginLeft: "-10px" }}>

              <hr />

            </div>

            {/* <hr style={{border:"1px solid lightgrey", width:'100%', paddingTop:'1.8rem', borderTop:'0', borderLeft:'0', borderRight:'0'}}/> */}
            <Grid item xs={12} lg={6} md={6} style={{ marginTop: "4" }}>
              <Grid container spacing={2} style={{ marginLeft: "3rem" }}>

                <div style={{ marginLeft: "-26px", marginTop: "-10px", color: "#000" }} data-testid="destinationDetails" >
                  Destination Details
                </div>

                <Grid item xs={12} >
                  <InputField
                    style={{ marginTop: "12px", marginLeft: "-36px" }}
                    name={deldoorBuilding.name}
                    data-testid="deldoorBuilding"
                    label={deldoorBuilding.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} >
                  <InputField
                    name={delstreetaddress.name}
                    style={{ marginLeft: "-36px" }}
                    data-testid="delstreetaddress"
                    label={delstreetaddress.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} >
                  <InputField
                    style={{ marginLeft: "-36px" }}
                    name={delcity.name}
                    label={delcity.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} >
                  <InputField
                    style={{ marginLeft: "-36px" }}
                    name={delpincode.name}
                    label={delpincode.label}

                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    fullWidth
                    type="number"

                  />
                </Grid>
                <Grid item xs={12} >
                  <InputField
                    style={{ marginLeft: "-36px" }}
                    name={dellandmark.name}
                    label={dellandmark.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} >
                  <InputField
                    name={delmobile.name}
                    style={{ marginLeft: "-36px" }}
                    label={delmobile.label}
                    fullWidth
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <SelectField
                        defaultValue="+91"
                        style={{width: "104%"}}
                        name={delmobilePrefix.name}
                        options={[{key: "+91", value: "+91"}, {key: "+62", value: "+62"}]}

                      />
                        // <Select
                        //   labelId="demo-simple-select-standard-label"
                        //   id="demo-simple-select-standard"
                        //   defaultValue={"+91"}
                        //   name={`${delmobilePrefix.name}`}
                        // >

                        //   <MenuItem value={"+91"}>+91</MenuItem>
                        //   <MenuItem value={"+62"}>+81</MenuItem>
                        // </Select>
                      ),
                    }}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                  />
                </Grid>
                <Grid item xs={12} >
                  <InputField
                    style={{ marginLeft: "-36px" }}
                    name={delname.name}
                    label={delname.label}
                    fullWidth
                  />
                </Grid>
                {/* <Grid item xs={12}  >
                  <InputField
                    style={{ marginLeft: "-36px" }}
                    name={delEmail.name}
                    label={delEmail.label}
                    fullWidth
                  /> 
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
