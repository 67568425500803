import axios from 'axios';
import { assetBaseURL } from './_url';

export default class AssetBaseAPI {
    postAsset=(postData:any) =>{
        return axios.post(`${assetBaseURL}/assets??page=0&size=50`,postData)
          .then(res => {
            return res.data
           
          });
        }

        
        postReq=(postDetails:any) =>{
            return axios.post(`${assetBaseURL}/assetAssignments`,postDetails)
              .then(res => {
                return res.data
                 
              });
            }
            sensorReq=(sensorData:any) =>{
                return axios.post(`${assetBaseURL}/sensors`,sensorData)
                  .then(res => {
                    return res.data
                     
                  });
                }
        getAssetID=() =>{
          return axios.get(`${assetBaseURL}/assetTypes`).then(res => {return res.data});
     }

     getS3UploadURL = async (fileName: string) => {
        try {
          return await axios.get(`${assetBaseURL}/assets/shippingRequestFile/s3URL?origFileName=${fileName}`)
        } catch (error) {
          console.log(error);
        }
     }

     /**
      * @returns response containing customr reports file name
      */
     getAssetCustomReport= async () =>{
      return await axios.get(`${assetBaseURL}/assets/customReports/list`)
        .then(res => {
          return res.data
        });
      }
        
}