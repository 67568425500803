import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { RouteAssign } from "./routeAssign";
import Button from "@material-ui/core/Button";
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Tabs,
  Tab,
  Box,
  Typography,
  // FormControl,
  // Select,
  // MenuItem,
  // IconButton,
} from "@material-ui/core";
import { toast } from "react-toastify";
toast.configure();

interface RouteMapProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginLeft: "-8px",
      boxShadow: "none",
    },
    title: {
      fontSize: "18px",
      float: "left",
      fontWeight: 599,
    },
    waypointLabel: {
      color: "white",
      fontWeight: "bold",
      fontSize: "14px",
      backgroundColor: "blue",
      padding: "4px",
      borderRadius: "50%",
      display: "inline-block",
    },
    legendItem: {
      marginBottom: "10px" /* Add margin between legend items */,
      cursor: "pointer" /* Show pointer cursor on hover */,
    },
    legendIcon: {
      display: "inline-block",
      width: "20px",
      height: "20px",
      backgroundColor: "blue",
      borderRadius: "50%",
      marginRight: "5px",
    },
    legendContainer: {
      position: "absolute",
      top: "50px" /* Adjust as needed */,
      right: "10px" /* Adjust as needed */,
    },
  })
);

export const RoutePlanning = ({ props }: any) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [selectedShipmentDetails, setSelectedShipmentDetails] = useState(
    location.state
  );
  const classes = useStyles();

  useEffect(() => {
    const initializeMap = async (
      sourceLoc: any,
      destinationLoc: any,
      wayLoc: any
    ) => {
      // Clear previous map instance if it exists
      if (mapRef.current) {
        mapRef.current.innerHTML = ''; // Remove all child elements
      }

      // Initiate and authenticate your connection to the HERE platform:
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_MAP_KEY,
      });

      // Obtain the default map types from the platform object:
      const defaultLayers: any = platform.createDefaultLayers();

      // Instantiate (and display) a map:
      // Zoom and center parameters are overridden by the bounding box
      // that contains the route and marker objects
      const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        zoom: 7,
        center: sourceLoc,
        // Add space around the map edges to ensure markers are not cut off:
        padding: { top: 50, right: 50, bottom: 50, left: 50 },
      });

      const origin: H.geo.IPoint = sourceLoc;
      const destination: H.geo.IPoint = destinationLoc;

      const waypoints = wayLoc;
      const waypointMarkers: any = [];
      const routeLines: any = [];
      // Create the parameters for the routing request:
      const routingParameters: any = {
        routingMode: "fast",
        transportMode: "car",
        // The start point of the route:
        origin: `${origin.lat},${origin.lng}`,
        via: new H.service.Url.MultiValueQueryParameter(
          waypoints.map((wp: any) => `${wp.lat},${wp.lng}`)
        ),
        // via: new H.service.Url.MultiValueQueryParameter([
        //     55.23, 23.45
        //   ]),
        // The end point of the route:
        destination: `${destination.lat},${destination.lng}`,
        // Include the route shape in the response
        return: "polyline",
      };

      // Define a callback function to process the routing response:
      const onResult = function (result: any) {
        // Ensure that at least one route was found
        if (result.routes.length) {
          const lineStrings: H.geo.LineString[] = [];
          const colors = ["blue", "red", "green", "orange", "purple"];
          // Create an instance of H.geo.MultiLineString
          const multiLineString = new H.geo.MultiLineString(lineStrings);

          result.routes[0].sections.forEach((section: any, index: number) => {
            // Create a linestring to use as a point source for the route line
            const lineString = H.geo.LineString.fromFlexiblePolyline(
              section.polyline
            );
            multiLineString.push(lineString);

            // Create a polyline to display the route with a unique color:
            const routeLine = new H.map.Polyline(lineString, {
              style: {
                strokeColor: colors[index % colors.length],
                lineWidth: 4,
              },
              data: {},
            });

            routeLines.push(routeLine);
          });

          waypoints.forEach((waypoint: any, index: number) => {
            const labelElement = document.createElement("div");
            labelElement.innerHTML = `<div class=${classes.waypointLabel}>${
              index + 1
            }</div>`;
            const waypointMarker = new H.map.Marker({
              lat: waypoint.lat,
              lng: waypoint.lng,
            });
            const waypointMarkerDom = new H.map.DomMarker({
              lat: waypoint.lat,
              lng: waypoint.lng,
            });

            waypointMarkerDom.setIcon(new H.map.DomIcon(labelElement));
            // Populate the waypointMarkers array:
            waypointMarkers.push(waypointMarkerDom);
            waypointMarkers.push(waypointMarker);
          });

          // const startMarker = new H.map.Marker(origin);
          // // Create a marker for the end point:
          // const endMarker = new H.map.Marker(destination);
          // Create a H.map.Group to hold all the map objects and enable us to obtain
          // the bounding box that contains all its objects within
          const group = new H.map.Group();
          group.addObjects([...routeLines, ...waypointMarkers]);
          // Add the group to the map
          map.addObject(group);

          // Set the map viewport to make the entire route visible:
          map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
          });
        }
      };

      // Get an instance of the routing service version 8:
      const router = platform.getRoutingService(null, 8);

      // Call the calculateRoute() method with the routing parameters,
      // the callback, and an error callback function (called if a
      // communication error occurs):
      router.calculateRoute(routingParameters, onResult, function (error) {
        alert(error.message);
      });

      // MapEvents enables the event system.
      // The behavior variable implements default interactions for pan/zoom (also on mobile touch environments).
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // Enable dynamic resizing of the map, based on the current size of the enclosing container
      window.addEventListener("resize", () => map.getViewPort().resize());
    };

    // Function to plot map for a specific agent ID and vehicle list
    const plotMap = (agentId: string, vehicleList: any[]) => {
      try {
        console.log("Initiating Plot Map");
        // Filter vehicle locations for the selected agent ID
        const selectedVehicles = vehicleList.filter(
          (vehicle) => vehicle.agentId === agentId
        );

        // Extract source, destination, and waypoints from selected vehicles
        const sourceLoc = {
          lat: selectedVehicles[0].lat,
          lng: selectedVehicles[0].lon,
        };
        const lastIndex = selectedVehicles.length - 1;
        const destinationLoc = {
          lat: selectedVehicles[lastIndex].lat,
          lng: selectedVehicles[lastIndex].lon,
        };

        const wayLoc = selectedVehicles.reduce(
          (uniqueLocations: any[], element: any, index: number) => {
            if (
              index === 0 ||
              element.lat !== selectedVehicles[index - 1].lat ||
              element.lon !== selectedVehicles[index - 1].lon
            ) {
              uniqueLocations.push({
                lat: element.lat,
                lng: element.lon,
              });
            }
            return uniqueLocations;
          },
          []
        );

        // Initialize map with the selected locations
        initializeMap(sourceLoc, destinationLoc, wayLoc);
      } catch (error) {
        console.error("Error plotting map:", error);
      }
    };

    const swatMobilityApi = async () => {
      //   console.log(location);
      let payload = {
        srList: selectedShipmentDetails,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_FEEDBACK}/marketplace/shippingRequest/routePlan`,
          payload
        );

        // Handle the response as needed
        console.log(response.data);
        const vehicleList = response.data.result.vehicles.vehicleList;
        const agentIdList = response.data.result.vehicles.agentIdList;
        // Dynamically generate legends
        const lastIndex = vehicleList.length - 1;

        const sourceLoc = { lat: vehicleList[0].lat, lng: vehicleList[0].lon };
        const destinationLoc = {
          lat: vehicleList[lastIndex].lat,
          lng: vehicleList[lastIndex].lon,
        };

        // Only keep unique origins
        const wayLoc = vehicleList.reduce(
          (uniqueLocations: any[], element: any, index: number) => {
            if (
              index === 0 ||
              element.lat !== vehicleList[index - 1].lat ||
              element.lon !== vehicleList[index - 1].lon
            ) {
              uniqueLocations.push({
                lat: element.lat,
                lng: element.lon,
              });
            }
            return uniqueLocations;
          },
          []
        );

        // Assuming `initializeMap` is a function defined elsewhere
        initializeMap(sourceLoc, destinationLoc, wayLoc);
        const legend = document.createElement("div");
        legend.style.zIndex = "1000";
        legend.classList.add("legendContainer");
        // Generate legend HTML and add it to the legend container
        // Iterate over agentIdList to create legend items
        agentIdList.forEach((agentId: string, index: number) => {
          const legendItem = document.createElement("div");
          legendItem.classList.add(classes.legendItem);
          legendItem.innerHTML = `<span class=${
            classes.legendIcon
          }></span> Vehicle ${index + 1}`;
          legendItem.addEventListener("click", () =>
            plotMap(agentId, vehicleList)
          );
          legend.appendChild(legendItem);
        });

        // Append legends to the map container element
        mapRef.current?.appendChild(legend);
      } catch (error) {
        // Handle errors
        console.error("Error making API request:", error);
      }
    };

    swatMobilityApi();
  }, [mapRef]);

  const onCancel = () => {
    history.push("/denso");
  };

  const assignDriver = () => {
    setShowAssignPopup(true);
  };

  const onPopupClose = (showAssign: any) => {
    if (showAssign === "disable") {
      toast.success("Driver and Asset Assigned Successfully");
    }
    setShowAssignPopup(false);
  };

  return (
    <>
      <div>
        <p className={classes.title}>Route Planning</p>
      </div>
      <div style={{ width: "100%", height: "500px" }} ref={mapRef} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "50px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          // disabled={isSubmitting}
          data-testid="assignSubmit"
          style={{
            backgroundColor: "#0078a5",
            color: "#fff",
            textTransform: "capitalize",
            marginTop: "11px",
            marginRight: "20px",
          }}
          onClick={assignDriver}
        >
          Assign
        </Button>
        <Button
          type="submit"
          variant="outlined"
          // disabled={isSubmitting}
          data-testid="assignSubmit"
          style={{
            backgroundColor: "#0078a5",
            color: "#fff",
            textTransform: "capitalize",
            marginTop: "11px",
            marginRight: "20px",
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>

      {/* <button onClick={assignDriver}>Assign</button>
      <button onClick={onCancel}>Cancel</button> */}
      <RouteAssign
        open={showAssignPopup}
        onAssignPopupClose={onPopupClose}
        shippingRequests={selectedShipmentDetails}
      />
    </>
  );
};
