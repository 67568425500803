import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
//import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import captchaImg from './captcha.jpg';
import { Card } from "react-bootstrap";
import {
  Button,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import "./style.css";
import { toast } from "react-toastify";
// import login_bg from "../../../assets/images/loginblue.png";
// import login_bg from "../../../assets/images/Login_bg(2).svg";

import { Link, useHistory } from "react-router-dom";
import UserService, { ROLE } from "../../../services/userService";
import { setCurrentUser } from "../../../redux/user/user.action";
import { selectCurrentUser } from "../../../redux/user/user.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";
import { authBaseURL } from "../../../services/_url";
//import captcha from "./Captcha";
import { TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CryptoJs from "crypto-js";

interface State {
  email: string;
  password: string;
  showPassword: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      height: "100%",
      top: 0,
      marginTop: "-5rem",
      marginLeft: "-100px",
      objectPosition: "bottom",
    },
    card: {
      width: "450px",
      height: "425px",
      marginLeft: "auto",
      marginRight: "auto",
      position: "absolute",
      top: "50%",
      left: " 50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    title: {
      textAlign: "center",
      color: "#fff",
      letterSpacing: "-0.48px",
      fontSize: "36px",
      font: " Roboto Medium, Regular",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "left",
      paddingTop: "20px",
      paddingLeft: "27px",
      backgroundColor: "#002E3C",
      borderRadius: "5px 5px 0 0",
      borderColor: "#002E3C",
      height: "95px",
      width: '450px',
      [theme.breakpoints.down("sm")]: {
        fontSize: " 36px",
      },
    },
    cardSpacing: {
      marginLeft: "3%",
      marginRight: "3%",
    },
    privacyPolicy: {
      float: "left",
      color: "#1A0DAB",
      letterSpacing: "1.25px",
      fontSize: "14px",
    },
    forgotPassword: {
      float: "right",
      color: "#1A0DAB",
      letterSpacing: "0px",
      textalign: "left"
    },
    loginBtn: {
      color: "#1A0DAB",
      marginBottom: "15px",
      border: "1px solid #1A0DAB",
      width: "100%",
      "&:hover": {
        color: "0078a5",
        border: "1px solid #1A0DAB",
      },
    },
    email: {
      " .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0078A5 !important"
      },

      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#808080 !important",
        borderWidth: "10px !important"
      }
    },

    img: {
      backgroundSize: "contain",
      border: "1px solid #e9385a",
    },
  })
);

export function Login(props: any) {
  const [values, setValues] = React.useState<State>({
    email: "",
    password: "",
    showPassword: false,
  });
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [emailMsg, setEmailMsg] = React.useState("");
  const [isValidPwd, setIsValidPwd] = React.useState(false);
  const [pswMsg, setPswMsg] = React.useState("");
  const [invalidMsg, setInvalidMsg] = React.useState("");
  const [validCaptcha, setValidCaptcha] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isPrivacy, setPrivacy] = React.useState(false);
  const [captchaMsg, setCaptchaMsg] = React.useState("");
  const [captcha, setCaptcha] = React.useState("");
  const [user, setUser] = React.useState({
    username: " "
  });
  const classes = useStyles();
  const history = useHistory();
  const { setCurrentUser } = props;

  const characters = 'abc1234';

  function generateString(length: number) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  React.useEffect(() => {

    setCaptcha(generateString(6));
  }, [])


  //  const captchRefresh = () => {
  //   setCaptcha(generateString(6));
  //  }
  //  setTimeout(captchRefresh, 30000);
  //const captcha = generateString(6) // Function called here and save in captcha variable


  let handleChangeCaptcha = (event: any) => {
    setCaptchaMsg("");
    let name = event.target.name;
    let value = event.target.value;
    user[name] = value;
    setUser(user);
    if (captcha === value) {
      setValidCaptcha(true);
    } else {
      setValidCaptcha(false);
    }
  }


  const info = (msg: any) => {
    toast.info(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#1383AF",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>{msg}.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const success = (msg: any) => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>{msg}.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const error = (msg: any) => {
    toast.warning(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#FF4F14",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>{msg}.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setInvalidMsg("");
      setValues({ ...values, [prop]: event.target.value });
      validatePassword(event.target.value);
    };

  const validatePassword = (password: string) => {
    if (!password) {
      setIsValidPwd(false);
      setPswMsg("Please enter password");
    } else {
      setIsValidPwd(true);
      setPswMsg("");
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleEmail =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setInvalidMsg("");
      if (localStorage.getItem(`${event.target.value}`) === 'true') {
        setChecked(true)
      } else {
        setChecked(false)
      }
      setValues({ ...values, [prop]: event.target.value });
      validateEmail(event.target.value);
    };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setIsValidEmail(false);
      setEmailMsg("Please enter Email ID");
    } else if (!re.test(String(email).toLowerCase())) {
      setIsValidEmail(false);
      setEmailMsg("Please enter vaild Email");
    } else {
      setIsValidEmail(true);
      setEmailMsg("");
    }
  };

  // const handleCallback = (flag: boolean) => {
  //   setValidCaptcha(flag)
  //   console.log('called', flag);
  // }

  const handleChangeChecked = () => {
    setChecked(!checked)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    //localStorage.setItem('checked', !checked ? 'true' : 'false')
    // if(!checked){
    //   return;
    // }
    if (isValidEmail && isValidPwd && validCaptcha) {
      _submitForm(values);
    } else {
      if (!values.email) {
        validateEmail(values.email);
      }
      if (!values.password) {
        validatePassword(values.password);
      }
      if (!validCaptcha) {
        setCaptchaMsg("Invalid Captcha");

      }
    }
  };

  const _submitForm = async (values: any) => {
    let userApi = new UserService();
	
	
	let encryptedAESEmail = CryptoJs.AES.encrypt(values.email, process.env.REACT_APP_AES_ENCRYPTION_KEY).toString();
    let encryptedAESPassword = CryptoJs.AES.encrypt(values.password, process.env.REACT_APP_AES_ENCRYPTION_KEY).toString();
    userApi
      .loginApi({email:encryptedAESEmail,password:encryptedAESPassword})
	  .then((res: any) => {

    //userApi
      //.loginApi(values)
      //.then((res: any) => {
	  
        // valid
        if (!res?.token) {
          setInvalidMsg(
            "Email or password is incorrect. Kindly provide correct credentials."
          );
        }

        if (res?.privacyPolicyAck) {
          setCurrentUser({
            token: res?.token,
            firstName: res?.firstName,
            lastName: res?.lastName,
            email: res?.email,
            tenantId: res?.tenantId,
            userId: res?.id,
            active: res?.active,
            roles: res?.roles,
          });
          if (res?.roles) {
            let isRoleFound: boolean = false;
            res.roles.forEach((role: string) => {
              if (role === ROLE.denso) {
                isRoleFound = true;
                history.push("/asset");
                return;
              } else if (role === ROLE.shipper) {
                isRoleFound = true;
                history.push("/shipper");
                return;
              } else if (role === ROLE.transporter) {
                isRoleFound = true;
                history.push("/transporter");
                return;
              }
              else if (role === ROLE.closedMarketplace) {
                isRoleFound = true;
                history.push("/asset");
                return;
              }
            });
            if (!isRoleFound) {
              setInvalidMsg("Error in finding role. Kindly try again.");
            }
          }
        } else {
          if (res?.token) {
            history.push("/privacy-policy",
              {
                token: res?.token,
                firstName: res?.firstName,
                lastName: res?.lastName,
                email: res?.email,
                tenantId: res?.tenantId,
                userId: res?.id,
                active: res?.active,
                roles: res?.roles,
              });
          }
        }

      })
      .catch((error) => {
        // invalid
        console.error(error);
        setInvalidMsg(
          "Email or password is incorrect. Kindly provide correct credentials."
        );
      });
  };

  const handleForgotPasword = () => {
    if (values.email && isValidEmail) {
      axios
        .post(`${authBaseURL}/auth/resetPassword`, {
          email: values.email,
        })
        .then((res: any) => {
          if (res.data.statusCode === 202) {
            error(res.data.data.response);
          }
          if (res.data.statusCode === 201) {
            info(res.data.data.response);
          }
          if (res.data.statusCode === 200) {
            success(res.data.data.response);
          }
          if (res.data.statusCode === 500) {
            error(res.data.data.response);
          }
        })
        .catch((err: any) => { });
    } else {
      setIsValidEmail(true);
      validateEmail(values.email);
    }
  };

  return (
    <div className={classes.root}>
      {/* <img
        src={login_bg}
        style={{
          width: "calc(100% + 100px)",
          maxWidth: "calc(100% + 100px)",
          backgroundSize: "cover",
        }}
        height="747"
      /> */}
      <div className={classes.card}>
        <Card>
          <CardContent style={{ padding: 0 }}>
            <p className={classes.title}>Login</p>
            <div className={classes.cardSpacing}>
              <form onSubmit={handleSubmit} action="">
                <TextField

                  sx={{
                    height: "30px",

                    " .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#0078A5 !important",

                    },

                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#808080 !important",
                      borderWidth: "1px !important"
                    }
                  }}

                  type='text'
                  name="username"
                  label="Email ID"
                  variant="outlined"
                  style={{ width: "100%", marginLeft: "2px", marginBottom: '20px' }}
                  data-testid="loginEmailField"
                  onChange={handleEmail("email")}
                />
                <p className="errorMsg" data-testid="emailMsg">
                  {emailMsg}
                </p>
                <br />
                <br />

                <TextField

                  sx={{
                    height: "30px",
                    " .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#0078A5 !important"
                    },

                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#808080 !important",
                      borderWidth: "1px !important"
                    }
                  }}

                  value={values.password}
                  label="Password"
                  variant="outlined"

                  className={classes.email}
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}

                  style={{ marginTop: "-42px", width: "100%",marginLeft:"2px" }}
                  onChange={handleChange("password")}
                  data-testid="loginPwdField"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          data-testid="pwdToggle"
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility style={{ marginRight: "15px" }} />
                          ) : (
                            <VisibilityOff style={{ marginRight: "15px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                >
                  {/* <InputLabel htmlFor="outlined-adornment-password" style={{ marginTop: "-20px" }}>
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    style={{ marginTop: "-20px" }}
                    onChange={handleChange("password")}
                    data-testid="loginPwdField"
                    startAdornment={
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "#828282" }} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          data-testid="pwdToggle"
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  /> */}
                </TextField>
                <p className="errorMsg" data-testid="pwdMsg">
                  {pswMsg}
                </p>
                <p className="errorMsg">{invalidMsg}</p>
                {/* { isPrivacy &&
                  <FormGroup className={classes.privacyPolicy}
                    data-testid="privacyPolicy"
                    //onClick={handleForgotPasword}
                    style={{ color: "#828282" }}>
                    <FormControlLabel control={<Checkbox onChange={handleChangeChecked} checked={checked} />} label={<Link to="/privacy-policy">I read and Understood the Privacy Policy</Link>} />
                  </FormGroup>
                } */}


                <Button
                  className={classes.forgotPassword}
                  data-testid="forgotPassword"
                  onClick={handleForgotPasword}
                  style={{
                    color: "#828282", height: "10px", width: "130px", fontFamily: "Roboto",
                    fontSize: "11px", marginTop: "-18px", textTransform: "capitalize",marginRight: "-10px" 
                  }}
                >
                  <InputAdornment position="start" >
                    <LockIcon style={{ height: "20px", width: "20px", marginTop: "-8px" }} />
                  </InputAdornment>
                  <span style={{ marginTop: "-4px", marginLeft: "-2px" }}> Forgot Password</span>
                </Button>


                <a href="/shipper" style={{ color: 'white', textDecoration: 'none' }}></a>
                <TextField

                  sx={{
                    height: "30px",
                    " .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#0078A5 !important"
                    },

                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#808080 !important",
                      borderWidth: "1px !important"
                    }
                  }}
                  type='text'
                  name="captcha"
                  label="Enter the Captcha here"
                  variant="outlined"
                  style={{ width: "100%", marginBottom: '20px' }}
                  placeholder="Enter the captcha here"
                  data-testid="loginEmailField"
                  onChange={handleChangeCaptcha}
                />
                <p className="errorMsg" >
                  {captchaMsg}
                </p>
                <h5 id="captcha" style={{ textAlign: 'center', fontSize: "25px", backgroundImage: `url(${captchaImg})`, width: '100%' }}>{captcha}</h5>

                {/* <Captcha handleLoginButton={handleCallback}></Captcha>
                 */}
                <Button
                  style={{
                    backgroundColor: "#007AA6",
                    color: "#fff",
                    height: "36px",
                    width: "100%",
                    border: "#fff",
                    borderRadius: "5px",
                    marginLeft: "1px",
                    textTransform: "capitalize",
                    marginTop: "19px",
                    marginBottom: "19px"
                  }}
                  variant="outlined"
                  className={classes.loginBtn}
                  data-testid="loginBtn"
                  type="submit"
                >
                  Login
                </Button>
              </form >
            </div >
          </CardContent >
        </Card >
      </div >
    </div >
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, matchDispatchToProps)(Login);