import { createStyles, makeStyles} from '@material-ui/core/styles';
const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        backgroundColor:'#fff'
      },
    },
   
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
    paper: {
      // padding: theme.spacing(2),
      textAlign: 'center',
      // color: theme.palette.text.secondary,
    },
    rowTop:{
      // backgroundColor: "#F2F2F2",
       border: '2px solid black',
       borderBottom:'none'
     },
     rowBottom:{
     //  backgroundColor: "#F2F2F2",
       border: '2px solid black',
       borderTop:'none'
     },
      requestId:{
        fontSize:'14px',
        fontWeight:400,
        color:'#1A0DAB ',
        letterSpacing:'0.25px'
    }

  });
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: '90%',
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      leftIconButton: {
        color: "blue !important",
        "&:before": {
          content: "hadkj !important",
          color: "black !important"
        }
      },
      
    }),
  );

  export{useStyles,useRowStyles}
  