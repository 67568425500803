import axios from 'axios';
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import MarketPlaceAPI from '../../../../services/marketPlaceService';
import AssetBaseAPI from '../../../../services/assetService';
import { useHistory, useParams ,useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import { setCurrentUser } from '../../../../redux/user/user.action';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../../../../redux/user/user.selector';
import { createStructuredSelector } from 'reselect';
import { Box } from '@mui/material';
import { ResponsiveDrawer } from '../../common/Drawer';
toast.configure();

export function BulkUploadPage(props: any) {
    const [searchReqId, setSearchReqId] = useState<string>("");
    let {   currentUser, setCurrentUser } = props;
    const upRef = useRef();
    const textRef = useRef();
    const [selectedFile, SetSelectedFile] = useState("");
    const [fileFlag, setFileFlag] = useState(false);
    const [cncFlag, setCncFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [lblFlag, setLblFlag] = useState(false);
    const marketApi = new MarketPlaceAPI();
    const assetApi = new AssetBaseAPI();
    const history = useHistory();

    const loadUpload = () => {
        const current: any = upRef.current;
        if (current) {
            current.click();
        }
    }

    const fileSelected = async (event: any, data: any) => {
        //var fs = require('fs').promises;

        const current: any = upRef.current;
        if (current) {
            SetSelectedFile(current.files[0].name);
            setCncFlag(false);
        }
        var file = current.files[0];
        const formData = new FormData();
        formData.append('file', current.files[0])
        
        //let uploaded = await marketApi.uploadShipmentFile(formData);
        let uploadURL = await assetApi.getS3UploadURL(current.files[0].name);
        // const resultData = await fetch(`${uploadURL.data.fileUploadURL}`, {
        //         method: 'PUT',
        //         body: formData,
        //         headers: {
        //             'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //         }
        // });
        var xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadURL.data.fileUploadURL, true);
        //xhr.setRequestHeader('Content-Type', 'image/jpeg');
        xhr.onload = () => {
            if (xhr.status === 200) {
            // console.log('Uploaded data successfully');
            toast.success("uploaded successfully!");
            history.push('/denso')
            }
        };
        xhr.onerror = () => {
            console.log('Nope')
        };
        xhr.send(file); // `file` is a File object here
       
    }
    const handleCancel = () => {
        SetSelectedFile("");
        

    }
    const upload = async () => {

        if (selectedFile.length > 0) {
            const current: any = upRef.current;
            const formData = new FormData();
            formData.append('file', current.files[0])

            let uploaded = await marketApi.uploadShipmentFile(formData);
            if (uploaded.status === "Success") {
                toast.success("uploaded successfully!");
                history.push('/denso')
            }
            setFileFlag(true);
            setLblFlag(true);

            setTimeout(() => {
                setLblFlag(false);
                setFileFlag(false);
            }, 5000);
        } else {
            setCncFlag(true);
            setErrorMessage('File is not selected');
        }
    }

    return (
        <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:'-10px' }}>
        <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
        <Box component={"main"} sx={{flexGrow:1,p:3}}>
          
        <div>
            <div style={{ fontSize: "1.5rem", fontWeight: 500, marginRight: "10px" }}>    <p >Trip Management</p>
            
            </div>
            <div style={{ fontSize: "1rem", fontWeight: 300 }}>    <p >Upload File</p></div>
            <div>
            <Link to={{ pathname: '/denso'}} style={{textDecoration:"none" }}> <Button variant="outlined" style={{marginLeft:"1300px",color: "#fff",marginTop:"-70px", width:"100px",backgroundColor: "#007AA6"}}>Back</Button></Link>
            </div>
          
           

            <div style={{ marginTop: "5rem", marginLeft: "auto", marginRight: "auto", width: "50%", border: "2.5px solid white" }}>

                <div style={{ padding: "7rem", marginLeft: "7rem" }}>
                    <label htmlFor="myFile" style={{ marginLeft: "-1rem", fontWeight: "bolder", fontSize: "15px" }}>Choose File : </label>
                    <input ref={textRef} id="textF" type="text" value={selectedFile}
                        onClick={loadUpload} style={{ marginLeft: '1rem' }} />
                    <input id="myFile" ref={upRef} type="file" style={{ visibility: "hidden" }}
                        name="img"
                        onChange={(e) => fileSelected(e, document.getElementById("textF"))} />
                    <div style={{ marginTop: "2rem" }}>
                        {/* {cncFlag && 
                   <p style={{ marginTop: "2rem", color: "red" }}> File is not selected.</p>
} */}
                        {errorMessage && cncFlag && (
                            <p style={{ color: "red", marginLeft: "100px", marginTop: "-40px" }}> {errorMessage} </p>
                        )}
                        <Button onClick={upload} variant="outlined" style={{ backgroundColor: "#007AA6", color: "#fff", width: "100px", marginLeft: "60px" }}>Upload</Button>
                        <Button onClick={handleCancel} variant="outlined" style={{ backgroundColor: "#007AA6", color: "#fff", marginLeft: "190px ", marginTop: "-35px", width: "100px" }}>Cancel</Button>
                    </div>
                    {lblFlag &&
                        <p style={{ marginTop: "2rem", color: "blue" }}>File uploaded. Please check the status after some time.</p>
                    }

                </div>

            </div>
        </div>
            </Box>
            </Box>
    )
}
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser
  });
  
  const matchDispatchToProps = (dispatch: any) => ({
    setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
  });
  
  export default connect(mapStateToProps, matchDispatchToProps)(BulkUploadPage);