

// import React, { useEffect, useState } from "react";
// import AppBar from "@material-ui/core/AppBar";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Drawer from "@material-ui/core/Drawer";
// import Hidden from "@material-ui/core/Hidden";
// import IconButton from "@material-ui/core/IconButton";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import MenuIcon from "@material-ui/icons/Menu";
// import Toolbar from "@material-ui/core/Toolbar";
// //import Logout from "@material-ui/material-icons/Logout";
// import {
//   Avatar,
//   Divider,
//   FormControl,
//   InputAdornment,
//   Menu,
//   MenuItem,
//   TextField,
// } from "@material-ui/core";
// import {
//   makeStyles,
//   useTheme,
//   Theme,
//   createStyles,
// } from "@material-ui/core/styles";
// import { Link, useHistory, useLocation } from "react-router-dom";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import MoreIcon from "@material-ui/icons/MoreVert";
// import SearchIcon from "@material-ui/icons/Search";
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// // import densoLogo from "../../../../assets/images/Denso-Logo.wine.svg";
// import SolwerLogo from "../../../../assets/images/Solwer.png";
// import lithpwrLogo from "../../../../assets/images/lithpwr.png";

// import dtAnalytics from "../../../../assets/images/Data_Analytics.svg";
// import activateDT from "../../../../assets/images/activateDA.svg";
// import tripManage from "../../../../assets/images/Trip_Management.svg";
// import activateTM from "../../../../assets/images/activateTM.svg";
// import optMonitor from "../../../../assets/images/Operation_Monitoring.svg";
// import activateOM from "../../../../assets/images/activateOM.svg";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import sidebar1 from "../../../../assets/images/SR_Icon.svg";
// import UserService, { ROLE } from "../../../../services/userService";
// import { selectCurrentUser } from "../../../../redux/user/user.selector";
// import { createStructuredSelector } from "reselect";
// import { connect } from "react-redux";
// import { setCurrentUser } from "../../../../redux/user/user.action";
// import Notification from "../BellNotification/Notification";
// import CloseIcon from "@mui/icons-material/Close";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
// import Tooltip from "@mui/material/Tooltip";
// import Logout from "@mui/icons-material/Logout";

// const drawerWidth = 75;
// const drawerWidth1 = 300;

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: "flex",
//     },
//     drawer: {
//       [theme.breakpoints.up("sm")]: {
//         width: drawerWidth,
//         flexShrink: 0,
//       },
//     },
//     appBar: {
//       boxShadow: "0px 1px 5px #00000029",
//       [theme.breakpoints.up("sm")]: {
//         width: `95%`,
//         // marginLeft: "30px",
//         backgroundColor: "#FFFFFF",
        
//         color: "black",
//       },
//       [theme.breakpoints.down("sm")]: {
//         backgroundColor: "#FFFFFF",
        
//         color: "black",
//       },
//     },
//     menuButton: {
//       marginLeft:"30px",
//       color: "#FFFFFF",
//       [theme.breakpoints.up("sm")]: {
//         display: "block", //'none'
//       },
//     },
//     // necessary for content to be below app bar
//     toolbar: theme.mixins.toolbar,
//     drawerPaper: {
//       width: drawerWidth,
//       backgroundColor: "#002E3C",
//       color: "#fff",
//       // marginTop: '64px',
//       zIndex: 1,
//     },
//     drawerPaper1: {
//       width: drawerWidth1,
//       backgroundColor: "#002E3C",
//       color: "#fff",
//       // marginTop: '64px',
//       zIndex: 1,
//     },
//     content: {
//       flexGrow: 1,
//       padding: theme.spacing(3),
//     },
//     searchContainer: {
//       float: "right",
//       backgroundColor: "#F4F6F8",
//       borderRadius: "19px",
//       marginRight:"35px"
//     },
//     iconsColor: {
//       "&:hover": {
//         color: "#5A5A5A",
//       },
//       "&:active": {
//         color: "#1A0DAB",
//       },
//       height: "88px",
//       fontSize: "30px",
//       // borderLeft: "5px solid transparent",
//       opacity: "25%",
//     },

//     iconsColorToggle: {
//       "&:hover": {
//         color: "#5A5A5A",
//       },
//       "&:active": {
//         color: "#1A0DAB",
//       },
//       height: "65px",
//       fontSize: "30px",
//       // borderLeft: "5px solid transparent",
//       opacity: "25%",
//     },
//     activeColor: {
//       // borderLeft: "5px solid #fff",
//       // backgroundColor:"rgba(26, 13, 171,.1)",
//       height: "88px",
//       fontSize: "30px",
//       opacity: "87%",
//     },
//     activeColorToggle: {
//       // borderLeft: "5px solid #1A0DAB",
//       // backgroundColor:"rgba(26, 13, 171,.1)",
//       height: "65px",
//       fontSize: "30px",
//       opacity: "87%",
//     },
//     remove: {
//       listStyleType: "none",
//       textAlign: "center",
//       margin: "10 10 40 10",
//       padding: "0 0 0 0",
//     },
//     removeBullets: {
//       listStyleType: "none",
//       textAlign: "left",
//       // margin: "10 10 40 10",
//       marginLeft: "24px",
//       marginTop: "21px",
//       padding: "0 0 0 0",
//       height: "89%",
//     },
//     activeColoricon: {
//       color: "#1A0DAB",
//       fontSize: "28px",
//       height: "35px",
//       fontWeight: "bold",
//     },
//     Coloricon: {
//       color: "#5A5A5A",
//       fontSize: "28px",
//       height: "35px",
//     },
//     grow: {
//       flexGrow: 1,
//     },
//     search: {
//       position: "relative",
//       borderRadius: theme.shape.borderRadius,
//       backgroundColor: " #E4E5E8",
//       // backgroundColor: alpha(theme.palette.common.white, 0.15),
//       "&:hover": {
//         // backgroundColor: alpha(theme.palette.common.white, 0.25),
//       },
//       marginRight: theme.spacing(2),
//       marginLeft: 0,
//       width: "100%",
//       [theme.breakpoints.up("sm")]: {
//         marginLeft: theme.spacing(3),
//         width: "auto",
//       },
//     },
//     searchIcon: {
//       padding: theme.spacing(0, 2),
//       height: "100%",
//       position: "absolute",
//       pointerEvents: "none",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       color: "#5A5A5A",
//       width: "17px",
//     },
//     inputRoot: {
//       color: "inherit",
//     },
//     inputInput: {
//       padding: theme.spacing(1, 1, 1, 0),
//       color: "grey",
//       // vertical padding + font size from searchIcon
//       paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//       transition: theme.transitions.create("width"),
//       width: "100%",
//       [theme.breakpoints.up("md")]: {
//         width: "20ch",
//       },
//       "&:focus": {
//         color: "#778899",
//       },
//     },
//     sideNavIcon: {
//       fontSize: "2.5rem",
//       marginTop: "1.5rem",
//     },
//     sectionMobile: {
//       display: "flex",
//       [theme.breakpoints.up("md")]: {
//         display: "none",
//         color: "black",
//       },
//     },
//     sectionDesktop: {
//       display: "none",
//       [theme.breakpoints.up("md")]: {
//         display: "flex",
//       },
//     },
//   })
// );

// export function ResponsiveDrawer(props: any) {
//   const { currentUser, setCurrentUser, window, setSearchReqId, disableSearch } =
//     props;
//   const userApi = new UserService();
//   let location = useLocation();
//   let history = useHistory();
//   let path = location.pathname;

//   const classes = useStyles();
//   const theme = useTheme();
//   const [mobileOpen, setMobileOpen] = React.useState(false);
//   const [textFieldValue, setTextFieldValue] = useState<string>("");
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   const isMenuOpen = Boolean(anchorEl);
//   const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
//     React.useState<null | HTMLElement>(null);
//   const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
//   const [isTransporter, setIsTransporter] = React.useState(false);
//   const [isShipper, setIsShipper] = React.useState(false);
//   const [isDenso, setIsDenso] = React.useState(false);
//   const [userInitials, setUserInitials] = React.useState("…");
//   const [open, setOpen] = React.useState(false);
//   const [isClosedMarketplace, setIsClosedMarketplace] = React.useState(false);
//   const [omFlag, setOmFlag] = useState(true);
//   const userService = new UserService();
//   const userDetails = userService.getUserDetails();
//   const roleName =  sessionStorage.getItem('roleName');

//   const handleDrawerClose = () => {
//     setOpen(false);
//     setMobileOpen(!mobileOpen);
//   };

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//     setOpen(true);
//   };

//   useEffect(() => {
//     const userDetails = userApi.getUserDetails();
//     if (userDetails?.token) {
//       const isExpired = userApi.checkTokenExpiration(userDetails.token);
//       if (isExpired) {
//         userApi.logout(history);
//       } else {
//         let userInitials: string = "";
//         if (userDetails?.firstName) {
//           userInitials += userDetails.firstName[0];
//         }
//         if (userDetails?.lastName) {
//           userInitials += userDetails.lastName[0];
//         }
//         if (userInitials) {
//           setUserInitials(userInitials.toUpperCase());
//         }
//         // todo: change below code during authentication implementation
//         let isRoleFound = true;
//         if (userDetails?.roles) {
//           userDetails.roles.forEach((role: string) => {
//             if (role === ROLE.shipper) {
//               setIsShipper(true);
//               isRoleFound = true;
//             } else if (role === ROLE.transporter) {
//               setIsTransporter(true);
//               isRoleFound = true;
//             } else if (role === ROLE.denso) {
//               setIsDenso(true);
//               isRoleFound = true;
//             }
//             else if (role === ROLE.closedMarketplace) {
//               setIsClosedMarketplace(true);
//               isRoleFound = true;
//             }
//           });
//         }
//         if (!isRoleFound) {
//           // todo: error page
//         }
//       }
//     } else {
//       userApi.logout(history);
//     }
//   }, []);

//   useEffect(() => {
//     setTextFieldValue(textFieldValue);
//   }, [textFieldValue]);

//   const logout = () => {
//     // todo: integrate with dropdown
//     const userApi = new UserService();
//     userApi.logout(history);
//     setCurrentUser(null);
//   };

//   const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMobileMenuClose = () => {
//     setMobileMoreAnchorEl(null);
//   };

//   const handleLogout = () => {
//     handleMenuClose();
//     logout();
//   };

  
//   const handleAddNewDriver = () => {
//     handleMenuClose();
//     history.push('/AddNewDriver');
//   }

//   const handleAsset = () => {
//     handleMenuClose();
//     history.push('/AssetType');
//   }

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     if (mobileMoreAnchorEl) {
//       handleMobileMenuClose();
//     }
//   };

//   const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
//     setMobileMoreAnchorEl(event.currentTarget);
//   };

//   const handleChangePassword = () => {
//     handleMenuClose();
//     history.push('/changePassword');
//   }
//   const handleSearchByReqChange = (event: any) => {
//     setTextFieldValue(event.target.value);
//     const text = event.target.value.replace(/\s/g, "");
//     if (
//       (text.length >= 3 && (text.length - 3) % 2 === 0) ||
//       text.length === 0
//     ) {
//       setSearchReqId(text);
//     }
//   };

//   const handleSearchByReq = (event: any) => {
//     if (event.key === "Enter" || event.keyCode === 13) {
//       const text = event.target.value;
//       setSearchReqId(text.replace(/\s/g, ""));
//     }
//   };

//   const changeHSHandler = () => {
//     setOmFlag(false)
//   }

//   const changeOMHandler = () => {
//     setOmFlag(true);
//   }

//   const handleClearSearch = (event: any) => {
//     //document.getElementById("search-input-with-icon-adornment").
//     setSearchReqId("");
//     setTextFieldValue("");
//   };

//   const drawerToggle = (
//     <div style={{ backgroundColor: "#002E3C", height: "75%" }}>
//       <div style={{ backgroundColor: "white", height: "3.9rem" }}>
//         <IconButton style={{ color: "red",marginTop:"-3rem" }} onClick={handleDrawerClose}>
//           {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
//         </IconButton>
//         <span>
//           <img
//             src={userDetails.roleName !== "LithPwr" ? SolwerLogo : lithpwrLogo}
//             style={{
//               // marginLeft: "3rem",
//               // marginTop: "-5rem",
//               // height: "3.75rem",
//               // zIndex: 4,
//               // minHeight: "20.8vh",
//               // fontSize: "calc(10px + 2vmin)",
//           marginLeft: "1rem",
//           marginTop: "-1.3rem",
//           height: "6.75rem",
//           // Z-index: "4",
//           fontSize:"calc(10px + 21vmin)",
//             }}
//           alt="logo"
//           />
//         </span>
//       </div>
//       <Divider />
//       <ul className={classes.removeBullets}>
//         {(isDenso || isClosedMarketplace) ? (
//           <li style={{marginTop:"3rem"}}>
//             <div
//             style={{marginBottom: (!userDetails.roles.includes('ADMIN') ? "0rem" : userDetails.roleName === "LithPwr" ? "6rem"  : "0rem")}}
//               className={
//                 path.includes("/asset")
//                   ? classes.activeColorToggle
//                   : classes.iconsColorToggle
//               }
//             >
//               <Link to="/asset" style={{ textDecoration: "none" }}>
//               <span
//                   style={{
//                     fontSize: "20px",
//                     color: "#fff",
                    
//                     marginBottom:"-20rem",
//                     marginLeft: "10px",
//                   }}
//                   onClick={changeOMHandler}
//                 >
//                 {/* <AcUnitIcon className={classes.sideNavIcon} style={{marginTop:'3%'}} fontSize="large"/>*/}
//                 {
//                   path.includes("/asset") ? <img
//                     src={activateOM}
//                     style={{ width: "40px",marginLeft:"-10px" }}
//                     alt="Operation Monitoring"
//                   /> : <img
//                     src={optMonitor}
//                     style={{ width: "40px" ,marginLeft:"-10px"}}
//                     alt="Operation Monitoring"
//                   />}
                
//                   {" "}
//                   Operation Monitoring
//                 </span>
//               </Link>
//               {/* {omFlag ?
//               (<>
//               <br/>
//               {userDetails.roles.includes('ADMIN') ||  userDetails.roleName === 'LithPwr' ?  <p style={{marginLeft:"3.5rem"}}>
//                 <Link to="/asset" style={{fontSize:"16px", textDecoration: "none"}}>Operation Monitoring(ICE)</Link><br/>
//                 <Link to="/assetOverview" style={{fontSize:"16px", textDecoration: "none"}}>Operation Monitoring(EV)</Link>
//                 <br/>
//               </p>:""}
//               <br/>
//               </>)
//               : null
// } */}
//               {/* <span style={{fontSize:'20px',color:'black',marginTop:'4%',marginLeft:'10px'}}> Operation Monitoring</span> */}
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {isDenso ? (
//           <li >
//             <div
//               className={
//                 path.includes("/dashboard")
//                   ? classes.activeColorToggle
//                   : classes.iconsColorToggle
//               }
//             >
//               <Link to="/dashboard" style={{ textDecoration: "none" }}>
//                 {/* <DashboardIcon className={classes.sideNavIcon} style={{marginTop:'2%'}} fontSize="large" /> */}
//                 {path.includes("/dashboard")
//                   ? <img
//                     src={activateDT}
//                     style={{ fontSize: "2.5rem", width: "40px" }}
//                     alt="Data Analytics"
//                   /> : <img
//                     src={dtAnalytics}
//                     style={{ fontSize: "2.5rem", width: "40px" }}
//                     alt="Data Analytics"
//                   />}
//                 <span
//                   style={{
//                     fontSize: "20px",
//                     color: "#fff",
//                     marginLeft: "15px",
//                     // marginLeft: "10px",
//                   }}
//                   onClick={changeHSHandler}
//                 >
//                   Data Analytics
//                 </span>
//               </Link>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {isTransporter ? (
//           <li>
//             <div
//               className={
//                 path.includes("/transporter")
//                   ? classes.activeColorToggle
//                   : classes.iconsColor
//               }
//             >
//               <div style={{ marginTop: "1rem" }}>
//                 <Link to="/transporter" style={{ textDecoration: "none" }}>
//                   {/* <img src={sidebar1} style={{marginTop:'2%'}} alt="sidebaricon"/>*/}
//                   {
//                     path.includes("/transporter") ? <img
//                       src={activateTM}
//                       style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     /> : <img
//                       src={tripManage}
//                       style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     />}
//                   <span
//                     style={{
//                       fontSize: "20px",
//                       color: "#fff",
//                       marginLeft: "10px",
//                       textDecoration: "none",
//                     }}
//                     onClick={changeHSHandler}
//                   >
//                     {" "}
//                     Trip Management{" "}
//                   </span>
//                 </Link>
//               </div>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {(isShipper) ? (
//           <li>
//             <div
//               className={
//                 path.includes("/shipper") ||
//                   path.includes("/createshipment") ||
//                   path.includes("/updateshipment")
//                   ? classes.activeColorToggle
//                   : classes.iconsColor
//               }
//             >
//               <div style={{ marginTop: "1rem" }}>
//                 <Link to="/shipper" style={{ textDecoration: "none" }}>
//                   {/* <img src={sidebar1} style={{marginTop:'2%'}} alt="sidebaricon"/> */}
//                   {
//                     path.includes("/shipper") ||
//                       path.includes("/createshipment") ||
//                       path.includes("/updateshipment")
//                       ? <img
//                         src={activateTM}
//                         style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                         alt="Trip Management"
//                       /> : <img
//                         src={tripManage}
//                         style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                         alt="Trip Management"
//                       />}
//                   <span
//                     style={{
//                       fontSize: "20px",
//                       color: "#fff",
//                       marginLeft: "10px",
//                     }}
//                   >
//                     {" "}
//                     Trip Management{" "}
//                   </span>
//                 </Link>
//               </div>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {(isDenso || isClosedMarketplace) ? (
//           <li>
//             <div
//               className={
//                 path.includes("/denso")
//                   ? classes.activeColorToggle
//                   : classes.iconsColorToggle
//               }
//               style={{marginTop : (userDetails.firstName === "LithPwrCMP" || userDetails.roleName  === "LithPwr") ? "6rem" :  "1%" }}
//             >
//               <Link to="/denso" style={{ textDecoration: "none" }}>
//                 {/* <img src={sidebar1} style={{marginTop:'1%',marginLeft:'-3.5px'}} alt="sidebaricon"/> */}
//                 {
//                   path.includes("/denso")
//                     ? <img
//                       src={activateTM}
//                       style={{ marginTop: "2%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     /> : <img
//                       src={tripManage}
//                       style={{ marginTop: "2%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     />}
//                 <span
//                   style={{
//                     fontSize: "20px",
//                     color: "#fff",
//                     marginLeft: "10px",
//                   }}
//                 >
//                   Trip Management{" "}
//                 </span>
//               </Link>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//       </ul>{" "}
//       <></>
//       <div
//         style={{
//           backgroundColor: "#004350",
//           // height: "4rem",
//           // border: "1px solid #000",
//           marginTop: "11.4rem",
//           position: "relative",
//         }}
//       >
//         <MenuItem
//           style={{ color: "#fff", padding: "1rem 4rem", fontSize: "15px" }}
//           onClick={handleLogout}
//         >
//           Logout
//         </MenuItem>
//       </div>
//     </div>
//   );

//   const drawer = (
//     <div>
//       <div style={{ marginBottom: "2rem", marginTop: "6rem" }}></div>
//       {
//         <ul className={classes.remove}>
//           {(isDenso || isClosedMarketplace) ? (
//             <li>
//               <div
//                 className={
//                   path.includes("/asset")
//                     ? classes.activeColor
//                     : classes.iconsColor
//                 }
//               >
//                 <Link to="/asset">
//                   <Tooltip title="Operation Monitoring">
//                     {/* <AcUnitIcon className={classes.sideNavIcon} fontSize="large"/>*/}
//                     {
//                       path.includes("/asset")
//                         ? <img
//                           src={activateOM}
//                           style={{ marginTop: "30%", width: "24px",height:"24px" }}
//                           alt="Opertion Monitoring"
//                         /> : <img
//                           src={optMonitor}
//                           style={{ marginTop: "30%", width: "24px",height:"24px" }}
//                           alt="Opertion Monitoring"
//                         />}
//                   </Tooltip>

//                   {/* <i className={"fa fa-snowflake-o "+classes.sideNavIcon} aria-hidden={true} /> */}
//                 </Link>
//               </div>
//             </li>
//           ) : (
//             <></>
//           )}
//           {(isDenso) ? (
//             <li>
//               <div
//                 className={
//                   path.includes("/dashboard")
//                     ? classes.activeColor
//                     : classes.iconsColor
//                 }
//               >
//                 <Link to="/dashboard">
//                   <Tooltip title="Data Analytics">
//                     {/* <DashboardIcon className={classes.sideNavIcon} fontSize="large"/>*/}
//                     {
//                       path.includes("/dashboard")
//                         ? <img
//                           src={activateDT}
//                           style={{ marginTop: "22%", width: "24px",height:"24px" }}
//                           alt="Data Analytics"
//                         /> : <img
//                           src={dtAnalytics}
//                           style={{ marginTop: "22%", width: "24px",height:"24px" }}
//                           alt="Data Analytics"
//                         />}
//                   </Tooltip>
//                 </Link>
//               </div>
//             </li>
//           ) : (
//             <></>
//           )}
//           {isTransporter ? (
//             <li>
//               <div
//                 className={
//                   path.includes("/transporter")
//                     ? classes.activeColor
//                     : classes.iconsColor
//                 }
//               >
//                 <Link to="/transporter">
//                   <Tooltip title="Trip Management">
//                     {/* <img src={sidebar1} style={{marginTop:'30%'}} alt="sidebaricon"/>*/}
//                     {
//                       path.includes("/transporter")
//                         ? <img
//                           src={activateTM}
//                           style={{ marginTop: "20%", width: "24px",height:"24px" }}
//                           alt="Trip Management"
//                         /> : <img
//                           src={tripManage}
//                           style={{ marginTop: "20%", width: "24px",height:"24px" }}
//                           alt="Trip Management"
//                         />}
//                   </Tooltip>
//                 </Link>
//               </div>
//             </li>
//           ) : (
//             <></>
//           )}
//           {(isShipper) ? (
//             <li>
//               <div
//                 className={
//                   path.includes("/shipper") ||
//                     path.includes("/createshipment") ||
//                     path.includes("/updateshipment")
//                     ? classes.activeColor
//                     : classes.iconsColor
//                 }
//               >
//                 <Link to="/shipper">
//                   <Tooltip title="Trip Management">
//                     {/* <img src={sidebar1} style={{marginTop:'30%'}} alt="sidebaricon"/>*/}
//                     {
//                       path.includes("/shipper") ||
//                         path.includes("/createshipment") ||
//                         path.includes("/updateshipment")
//                         ? <img
//                           src={activateTM}
//                           style={{ marginTop: "20%", width: "40px" }}
//                           alt="Trip Management"
//                         /> : <img
//                           src={tripManage}
//                           style={{ marginTop: "20%", width: "40px" }}
//                           alt="Trip Management"
//                         />}
//                   </Tooltip>
//                 </Link>
//               </div>
//             </li>
//           ) : (
//             <></>
//           )}
//           {(isDenso || isClosedMarketplace) ? (
//             <li>
//               <div
//                 className={
//                   path.includes("/denso")
//                     ? classes.activeColor
//                     : classes.iconsColor
//                 }
//               >
//                 <Link to="/denso">
//                   <Tooltip title="Trip Management">
//                     {/* <img src={sidebar1} style={{marginTop:'30%'}} alt="sidebaricon"/>*/}
//                     {
//                       path.includes("/denso")
//                         ? <img
//                           src={activateTM}
//                           style={{ marginTop: "20%", width: "24px",height:"24px" }}
//                           alt="Trip Management"
//                         /> : <img
//                           src={tripManage}
//                           style={{ marginTop: "20%", width: "24px",height:"24px" }}
//                           alt="Trip Management"
//                         />}
//                   </Tooltip>
//                 </Link>
//               </div>
//             </li>
//           ) : (
//             <></>
//           )}
//         </ul>
//       }
//       <MenuItem onClick={handleLogout}><Logout  style={{marginLeft:"10px"}}/></MenuItem>
//     </div>
//   );

//   const menuId = "primary-search-account-menu";
//   const renderMenu = (
//     <Menu
//       anchorEl={anchorEl}
//       anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//       id={menuId}
//       keepMounted
//       transformOrigin={{ vertical: "top", horizontal: "right" }}
//       getContentAnchorEl={null}
//       open={isMenuOpen}
//       onClose={handleMenuClose}
//     >
//       <MenuItem onClick={handleMenuClose} style={{color:"#000"}}>
//         {currentUser ? currentUser.email : "Kindly Login!"}
//       </MenuItem>
//       <MenuItem onClick={handleChangePassword} style={{color:"#000"}}>Change Password</MenuItem>
//       {/* <MenuItem onClick={handleAsset}>Asset</MenuItem> */}
//       {/* <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
//       {/* <MenuItem onClick={handleAddNewDriver}>Add New Driver</MenuItem> */}
//     </Menu>
//   );

//   const mobileMenuId = "primary-search-account-menu-mobile";
//   const renderMobileMenu = (
//     <Menu
//       anchorEl={mobileMoreAnchorEl}
//       anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//       id={mobileMenuId}
//       keepMounted
//       transformOrigin={{ vertical: "top", horizontal: "right" }}
//       getContentAnchorEl={null}
//       open={isMobileMenuOpen}
//       onClose={handleMobileMenuClose}
//     >
//       <MenuItem onClick={handleProfileMenuOpen}>
//         <IconButton
//           aria-label="account of current user"
//           aria-controls="primary-search-account-menu"
//           aria-haspopup="true"
//           color="inherit"
//         >
//           <AccountCircle />
//         </IconButton>
//         <p>Profile</p>
//       </MenuItem>
//     </Menu>
//   );

//   const container =
//     window !== undefined ? () => window().document.body : undefined;

//   return (
//     <div className={classes.root}>
//       {path === "/" ? (
//         ""
//       ) : (
//         <div>
//           <CssBaseline />
//           <AppBar  className={classes.appBar}>
//             <Toolbar>
             

//               <div style={{marginLeft:"-10px"}}>
//                 <img
//                   src={userDetails.roleName !== "LithPwr" ? SolwerLogo : lithpwrLogo}
//                   style={{
//                     marginRight:"-1rem",
//                     marginTop: "3px",
//                     height: "3.75rem",
//                   }}
//                   alt="logo"
//                 />
//               </div>
//                <div className={classes.grow}>
//                 <div className={classes.searchContainer} >
//                   <FormControl>
//                    <TextField
//                       id="search-input-with-icon-adornment"
//                       style={{textDecoration:"none"}}
//                       value={textFieldValue}
//                       autoComplete="off"
//                       onChange={handleSearchByReqChange}
//                       disabled={disableSearch ? disableSearch : null}
//                       variant="standard"
//                       InputProps={{disableUnderline:true,
//                         startAdornment: (
//                           <InputAdornment position="start">
//                             <SearchIcon  style={{marginLeft:"10px",width:"14px",height:"14px"}}/>
//                           </InputAdornment>
//                         ),
//                         endAdornment: textFieldValue ? (
//                           <InputAdornment position="end">
//                             <IconButton onClick={handleClearSearch}>
//                               <HighlightOffIcon />
//                             </IconButton>
//                           </InputAdornment>
//                         )
//                          : (
                          
//                             <div
//                               style={{ minWidth: "45px", fontSize: "14px", borderRadius: "19px", backgroundColor: "#F4F6F8",textDecoration:"none", }}
//                             ></div>
                        
//                         ),
//                       }}
//                       onKeyDown={handleSearchByReq}
//                       placeholder="Search…"
//                     />
//                     </FormControl>
//                 </div> 
//               </div> 
//               <div className={classes.sectionDesktop}>
//                 {/* <IconButton aria-label="show 0 new notifications" color="inherit" style={{color:'#5A5A5A'}} size="medium">
// <StyledBadge badgeContent={0} color="secondary">
// <NotificationsNoneOutlinedIcon fontSize="large" />
// </StyledBadge>
// </IconButton> */}
//                 <Notification userDetails={userApi.getUserDetails()} />
//                 <Avatar alt="Remy Sharp" style={{ marginTop: "0.5rem", backgroundColor: "#0078A5", width:"40px",height:"40px",marginRight:"0.5rem",fontSize:"12px" }}>
//                   {userInitials}
//                 </Avatar>
//                 <div
//                   style={{
//                     paddingTop: "6px",
//                     cursor: "pointer",
//                     marginTop: "0.5rem",
//                     color: "#74788D",
//                     marginLeft:"0.5rem"
                   
//                   }}
//                   onClick={handleProfileMenuOpen}
//                 >
//                   <ExpandMoreIcon />
//                 </div>
//               </div>
//               <div className={classes.sectionMobile}>
//                 <IconButton
//                   aria-label="show more"
//                   aria-controls={mobileMenuId}
//                   aria-haspopup="true"
//                   onClick={handleMobileMenuOpen}
//                   color="inherit"
//                 >
//                   <MoreIcon />
//                 </IconButton>
//               </div>
//             </Toolbar>
//           </AppBar>

//           <nav className={classes.drawer} aria-label="mailbox folders">
//             {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//             <Hidden smUp implementation="css">
//               <Drawer
//                 container={container}
//                 variant="temporary"
//                 anchor={theme.direction === "rtl" ? "right" : "left"}
//                 open={mobileOpen}
//                 onClose={handleDrawerToggle}
//                 classes={{
//                   paper: classes.drawerPaper1,
//                 }}
//                 ModalProps={{
//                   keepMounted: true, // Better open performance on mobile.
//                 }}
//               >
//                 {drawerToggle}
//               </Drawer>
//             </Hidden>

//             <Drawer
//               classes={{
//                 paper: classes.drawerPaper,
//               }}
//               variant="permanent"
//               open
//             >
//                <IconButton
//                 color="inherit"
//                 aria-label="open drawer"
//                 edge="end"
                
//                 onClick={handleDrawerToggle}
//                 className={classes.menuButton}
//               >
//                 <MenuIcon />
//               </IconButton>
//               {drawer}
//             </Drawer>
//           </nav>
//           {renderMobileMenu}
//           {renderMenu}
//         </div>
//       )}
//     </div>
//   );
// }

// const mapStateToProps = createStructuredSelector({
//   currentUser: selectCurrentUser,
// });

// const matchDispatchToProps = (dispatch: any) => ({
//   setCurrentUser: (user: any) => dispatch(setCurrentUser(user)),
// });

// export default connect(mapStateToProps, matchDispatchToProps)(ResponsiveDrawer);


import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import List from '@mui/material/List';

import Typography from '@mui/material/Typography';


import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import  { useEffect, useState } from "react";

import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
//import Logout from "@material-ui/material-icons/Logout";
import {
  Avatar,
  CssBaseline,
  Divider,
  FormControl,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  makeStyles,
  
  createStyles,
} from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import densoLogo from "../../../../assets/images/Denso-Logo.wine.svg";
import SolwerLogo from "../../../../assets/images/Solwer.png";
import lithpwrLogo from "../../../../assets/images/lithpwr.png";

import dtAnalytics from "../../../../assets/images/Data_Analytics.svg";
import activateDT from "../../../../assets/images/activateDA.svg";
import tripManage from "../../../../assets/images/Trip_Management.svg";
import activateTM from "../../../../assets/images/activateTM.svg";
import optMonitor from "../../../../assets/images/Operation_Monitoring.svg";
import activateOM from "../../../../assets/images/activateOM.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import sidebar1 from "../../../../assets/images/SR_Icon.svg";
import UserService, { ROLE } from "../../../../services/userService";
import { selectCurrentUser } from "../../../../redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import { setCurrentUser } from "../../../../redux/user/user.action";
import Notification from "../BellNotification/Notification";
import CloseIcon from "@mui/icons-material/Close";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import './style.css'
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      boxShadow: "0px 1px 5px #00000029",
      [theme.breakpoints.up("sm")]: {
        width: `95%`,
        // marginLeft: "30px",
        backgroundColor: "#FFFFFF",
        
        color: "black",
      },
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#FFFFFF",
        
        color: "black",
      },
    },
    menuButton: {
      marginLeft:"30px",
      color: "#FFFFFF",
      [theme.breakpoints.up("sm")]: {
        display: "block", //'none'
      },
    },
    // necessary for content to be below app bar
 
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    searchContainer: {
      float: "right",
      backgroundColor: "#F4F6F8",
      borderRadius: "19px",
      marginRight:"35px"
    },
    iconsColor: {
      "&:hover": {
        color: "#5A5A5A",
      },
      "&:active": {
        color: "#1A0DAB",
      },
      height: "88px",
      fontSize: "30px",
      // borderLeft: "5px solid transparent",
      opacity: "25%",
    },

    iconsColorToggle: {
      "&:hover": {
        color: "#5A5A5A",
      },
      "&:active": {
        color: "#1A0DAB",
      },
      height: "65px",
      fontSize: "30px",
      // borderLeft: "5px solid transparent",
      opacity: "25%",
    },
    activeColor: {
      // borderLeft: "5px solid #fff",
      // backgroundColor:"rgba(26, 13, 171,.1)",
      height: "88px",
      fontSize: "30px",
      opacity: "87%",
    },
    activeColorToggle: {
      // borderLeft: "5px solid #1A0DAB",
      // backgroundColor:"rgba(26, 13, 171,.1)",
      height: "65px",
      fontSize: "30px",
      opacity: "87%",
    },
    remove: {
      listStyleType: "none",
      textAlign: "center",
      margin: "10 10 40 10",
      padding: "0 0 0 0",
    },
    removeBullets: {
      listStyleType: "none",
      textAlign: "left",
      // margin: "10 10 40 10",
      marginLeft: "24px",
      marginTop: "21px",
      padding: "0 0 0 0",
      height: "89%",
    },
    activeColoricon: {
      color: "#1A0DAB",
      fontSize: "28px",
      height: "35px",
      fontWeight: "bold",
    },
    Coloricon: {
      color: "#5A5A5A",
      fontSize: "28px",
      height: "35px",
    },
    grow: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: " #E4E5E8",
      // backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        // backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#5A5A5A",
      width: "17px",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      color: "grey",
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
      "&:focus": {
        color: "#778899",
      },
    },
    sideNavIcon: {
      fontSize: "2.5rem",
      marginTop: "1.5rem",
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
        color: "black",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
  })
);
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function ResponsiveDrawer(props:any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { currentUser, setCurrentUser, window, setSearchReqId, disableSearch } =
    props;
  const userApi = new UserService();
  let location = useLocation();
  let history = useHistory();
  let path = location.pathname;

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [isTransporter, setIsTransporter] = React.useState(false);
  const [isShipper, setIsShipper] = React.useState(false);
  const [isDenso, setIsDenso] = React.useState(false);
  const [userInitials, setUserInitials] = React.useState("…");  

  const [isClosedMarketplace, setIsClosedMarketplace] = React.useState(false);
  const [omFlag, setOmFlag] = useState(true);
  const userService = new UserService();
  const userDetails = userService.getUserDetails();
  const roleName =  sessionStorage.getItem('roleName');

  //  console.log(props,'props');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setOpen(true);
  };

  useEffect(() => {
    const userDetails = userApi.getUserDetails();
    if (userDetails?.token) {
      const isExpired = userApi.checkTokenExpiration(userDetails.token);
      if (isExpired) {
        userApi.logout(history);
      } else {
        let userInitials: string = "";
        if (userDetails?.firstName) {
          userInitials += userDetails.firstName[0];
        }
        if (userDetails?.lastName) {
          userInitials += userDetails.lastName[0];
        }
        if (userInitials) {
          setUserInitials(userInitials.toUpperCase());
        }
        // todo: change below code during authentication implementation
        let isRoleFound = true;
        if (userDetails?.roles) {
          userDetails.roles.forEach((role: string) => {
            if (role === ROLE.shipper) {
              setIsShipper(true);
              isRoleFound = true;
            } else if (role === ROLE.transporter) {
              setIsTransporter(true);
              isRoleFound = true;
            } else if (role === ROLE.denso) {
              setIsDenso(true);
              isRoleFound = true;
            }
            else if (role === ROLE.closedMarketplace) {
              setIsClosedMarketplace(true);
              isRoleFound = true;
            }
          });
        }
        if (!isRoleFound) {
          // todo: error page
        }
      }
    } else {
      userApi.logout(history);
    }
  }, []);

  useEffect(() => {
    setTextFieldValue(textFieldValue);
  }, [textFieldValue]);

  const logout = () => {
    // todo: integrate with dropdown
    const userApi = new UserService();
    userApi.logout(history);
    setCurrentUser(null);
    // console.log(logout,   "logoutdrwaerindex")
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  
  const handleAddNewDriver = () => {
    handleMenuClose();
    history.push('/AddNewDriver');
  }

  const handleAsset = () => {
    handleMenuClose();
    history.push('/AssetType');
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    if (mobileMoreAnchorEl) {
      handleMobileMenuClose();
    }
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChangePassword = () => {
    handleMenuClose();
    history.push('/changePassword');
  }
  const handleSearchByReqChange = (event: any) => {
    setTextFieldValue(event.target.value);
    const text = event.target.value.replace(/\s/g, "");
    if (
      (text.length >= 3 && (text.length - 3) % 2 === 0) ||
      text.length === 0
    ) {
      setSearchReqId(text);
    }
  };

  const handleSearchByReq = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      const text = event.target.value;
      setSearchReqId(text.replace(/\s/g, ""));
    }
  };

  const changeHSHandler = () => {
    setOmFlag(false)
  }
  //   const drawerToggle = (
//     <div style={{ backgroundColor: "#002E3C", height: "75%" }}>
//       <div style={{ backgroundColor: "white", height: "3.9rem" }}>
//         <IconButton style={{ color: "red",marginTop:"-3rem" }} onClick={handleDrawerClose}>
//           {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
//         </IconButton>
//         <span>
//           <img
//             src={userDetails.roleName !== "LithPwr" ? SolwerLogo : lithpwrLogo}
//             style={{
//               // marginLeft: "3rem",
//               // marginTop: "-5rem",
//               // height: "3.75rem",
//               // zIndex: 4,
//               // minHeight: "20.8vh",
//               // fontSize: "calc(10px + 2vmin)",
//           marginLeft: "1rem",
//           marginTop: "-1.3rem",
//           height: "6.75rem",
//           // Z-index: "4",
//           fontSize:"calc(10px + 21vmin)",
//             }}
//           alt="logo"
//           />
//         </span>
//       </div>
//       <Divider />
//       <ul className={classes.removeBullets}>
//         {(isDenso || isClosedMarketplace) ? (
//           <li style={{marginTop:"3rem"}}>
//             <div
//             style={{marginBottom: (!userDetails.roles.includes('ADMIN') ? "0rem" : userDetails.roleName === "LithPwr" ? "6rem"  : "0rem")}}
//               className={
//                 path.includes("/asset")
//                   ? classes.activeColorToggle
//                   : classes.iconsColorToggle
//               }
//             >
//               <Link to="/asset" style={{ textDecoration: "none" }}>
//               <span
//                   style={{
//                     fontSize: "20px",
//                     color: "#fff",
                    
//                     marginBottom:"-20rem",
//                     marginLeft: "10px",
//                   }}
//                   onClick={changeOMHandler}
//                 >
//                 {/* <AcUnitIcon className={classes.sideNavIcon} style={{marginTop:'3%'}} fontSize="large"/>*/}
//                 {
//                   path.includes("/asset") ? <img
//                     src={activateOM}
//                     style={{ width: "40px",marginLeft:"-10px" }}
//                     alt="Operation Monitoring"
//                   /> : <img
//                     src={optMonitor}
//                     style={{ width: "40px" ,marginLeft:"-10px"}}
//                     alt="Operation Monitoring"
//                   />}
                
//                   {" "}
//                   Operation Monitoring
//                 </span>
//               </Link>
//               {/* {omFlag ?
//               (<>
//               <br/>
//               {userDetails.roles.includes('ADMIN') ||  userDetails.roleName === 'LithPwr' ?  <p style={{marginLeft:"3.5rem"}}>
//                 <Link to="/asset" style={{fontSize:"16px", textDecoration: "none"}}>Operation Monitoring(ICE)</Link><br/>
//                 <Link to="/assetOverview" style={{fontSize:"16px", textDecoration: "none"}}>Operation Monitoring(EV)</Link>
//                 <br/>
//               </p>:""}
//               <br/>
//               </>)
//               : null
// } */}
//               {/* <span style={{fontSize:'20px',color:'black',marginTop:'4%',marginLeft:'10px'}}> Operation Monitoring</span> */}
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {isDenso ? (
//           <li >
//             <div
//               className={
//                 path.includes("/dashboard")
//                   ? classes.activeColorToggle
//                   : classes.iconsColorToggle
//               }
//             >
//               <Link to="/dashboard" style={{ textDecoration: "none" }}>
//                 {/* <DashboardIcon className={classes.sideNavIcon} style={{marginTop:'2%'}} fontSize="large" /> */}
//                 {path.includes("/dashboard")
//                   ? <img
//                     src={activateDT}
//                     style={{ fontSize: "2.5rem", width: "40px" }}
//                     alt="Data Analytics"
//                   /> : <img
//                     src={dtAnalytics}
//                     style={{ fontSize: "2.5rem", width: "40px" }}
//                     alt="Data Analytics"
//                   />}
//                 <span
//                   style={{
//                     fontSize: "20px",
//                     color: "#fff",
//                     marginLeft: "15px",
//                     // marginLeft: "10px",
//                   }}
//                   onClick={changeHSHandler}
//                 >
//                   Data Analytics
//                 </span>
//               </Link>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {isTransporter ? (
//           <li>
//             <div
//               className={
//                 path.includes("/transporter")
//                   ? classes.activeColorToggle
//                   : classes.iconsColor
//               }
//             >
//               <div style={{ marginTop: "1rem" }}>
//                 <Link to="/transporter" style={{ textDecoration: "none" }}>
//                   {/* <img src={sidebar1} style={{marginTop:'2%'}} alt="sidebaricon"/>*/}
//                   {
//                     path.includes("/transporter") ? <img
//                       src={activateTM}
//                       style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     /> : <img
//                       src={tripManage}
//                       style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     />}
//                   <span
//                     style={{
//                       fontSize: "20px",
//                       color: "#fff",
//                       marginLeft: "10px",
//                       textDecoration: "none",
//                     }}
//                     onClick={changeHSHandler}
//                   >
//                     {" "}
//                     Trip Management{" "}
//                   </span>
//                 </Link>
//               </div>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {(isShipper) ? (
//           <li>
//             <div
//               className={
//                 path.includes("/shipper") ||
//                   path.includes("/createshipment") ||
//                   path.includes("/updateshipment")
//                   ? classes.activeColorToggle
//                   : classes.iconsColor
//               }
//             >
//               <div style={{ marginTop: "1rem" }}>
//                 <Link to="/shipper" style={{ textDecoration: "none" }}>
//                   {/* <img src={sidebar1} style={{marginTop:'2%'}} alt="sidebaricon"/> */}
//                   {
//                     path.includes("/shipper") ||
//                       path.includes("/createshipment") ||
//                       path.includes("/updateshipment")
//                       ? <img
//                         src={activateTM}
//                         style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                         alt="Trip Management"
//                       /> : <img
//                         src={tripManage}
//                         style={{ marginTop: "4%", marginLeft: "3.5px", width: "40px" }}
//                         alt="Trip Management"
//                       />}
//                   <span
//                     style={{
//                       fontSize: "20px",
//                       color: "#fff",
//                       marginLeft: "10px",
//                     }}
//                   >
//                     {" "}
//                     Trip Management{" "}
//                   </span>
//                 </Link>
//               </div>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//         {(isDenso || isClosedMarketplace) ? (
//           <li>
//             <div
//               className={
//                 path.includes("/denso")
//                   ? classes.activeColorToggle
//                   : classes.iconsColorToggle
//               }
//               style={{marginTop : (userDetails.firstName === "LithPwrCMP" || userDetails.roleName  === "LithPwr") ? "6rem" :  "1%" }}
//             >
//               <Link to="/denso" style={{ textDecoration: "none" }}>
//                 {/* <img src={sidebar1} style={{marginTop:'1%',marginLeft:'-3.5px'}} alt="sidebaricon"/> */}
//                 {
//                   path.includes("/denso")
//                     ? <img
//                       src={activateTM}
//                       style={{ marginTop: "2%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     /> : <img
//                       src={tripManage}
//                       style={{ marginTop: "2%", marginLeft: "3.5px", width: "40px" }}
//                       alt="Trip Management"
//                     />}
//                 <span
//                   style={{
//                     fontSize: "20px",
//                     color: "#fff",
//                     marginLeft: "10px",
//                   }}
//                 >
//                   Trip Management{" "}
//                 </span>
//               </Link>
//             </div>
//           </li>
//         ) : (
//           <></>
//         )}
//       </ul>{" "}
//       <></>
//       <div
//         style={{
//           backgroundColor: "#004350",
//           // height: "4rem",
//           // border: "1px solid #000",
//           marginTop: "11.4rem",
//           position: "relative",
//         }}
//       >
//         <MenuItem
//           style={{ color: "#fff", padding: "1rem 4rem", fontSize: "15px" }}
//           onClick={handleLogout}
//         >
//           Logout
//         </MenuItem>
//       </div>
//     </div>
//   );

  const changeOMHandler = () => {
    setOmFlag(true);
  }

  const handleClearSearch = (event: any) => {
    //document.getElementById("search-input-with-icon-adornment").
    setSearchReqId("");
    setTextFieldValue("");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      getContentAnchorEl={null}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} style={{color:"#000"}}>
        {currentUser ? currentUser.email : "Kindly Login!"}
      </MenuItem>
      <MenuItem onClick={handleChangePassword} style={{color:"#000"}}>Change Password</MenuItem>
      {/* <MenuItem onClick={handleAsset}>Asset</MenuItem> */}
      {/* <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
      {/* <MenuItem onClick={handleAddNewDriver}>Add New Driver</MenuItem> */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      getContentAnchorEl={null}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
       {path === "/" ? (
        ""
      ) : (
        <div>
      <CssBaseline />
      <AppBar position="fixed" open={open}  style={{ background: '#fff' }}>
        <Toolbar>
          {!open ?
          <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  style={{color:"#000",marginLeft:"-3px"
                 }}
                 
          >
            <MenuIcon style={{color:"rgba(0, 0, 0, 0.54)"}}/>
          </IconButton> :  "" } 

          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
          {userDetails.roleName !== "LithPwr" &&
           <div style={{marginLeft:"-10px"}}>
                 <img
                  src={SolwerLogo }
                  style={{
                    marginRight:"-1rem",
                    marginTop: "-1px",
                    height: "3.75rem",
                  }}
                  alt="logo"
                />
              </div>
}
{userDetails.roleName === "LithPwr" &&
           <div style={{marginLeft:"10px"}}>
                 <img
                  src={ lithpwrLogo}
                  style={{
                    marginRight:"-1rem",
                    marginTop: "-1px",
                    height: "3.75rem",
                  }}
                  alt="logo"
                />
              </div>
}
              <div className={classes.grow}>
                 <div className={classes.searchContainer} >
                  <FormControl>
                    <TextField
                      id="search-input-with-icon-adornment"
                      style={{textDecoration:"none"}}
                      value={textFieldValue}
                      autoComplete="off"
                      onChange={handleSearchByReqChange}
                      disabled={disableSearch ? disableSearch : null}
                      variant="standard"
                      InputProps={{disableUnderline:true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon  style={{marginLeft:"10px",width:"14px",height:"14px"}}/>
                          </InputAdornment>
                        ),
                        endAdornment: textFieldValue ? (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearSearch}>
                              <HighlightOffIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                         : (
                          
                            <div
                              style={{ minWidth: "45px", fontSize: "14px", borderRadius: "19px", backgroundColor: "#F4F6F8",textDecoration:"none", }}
                            ></div>
                        
                        ),
                      }}
                      onKeyDown={handleSearchByReq}
                      placeholder="Search…"
                    />
                    </FormControl>
                </div> 
              </div> 
              <div className={classes.sectionDesktop}>
                {/* <IconButton aria-label="show 0 new notifications" color="inherit" style={{color:'#5A5A5A'}} size="medium">
// <StyledBadge badgeContent={0} color="secondary">
// <NotificationsNoneOutlinedIcon fontSize="large" />
// </StyledBadge>
// </IconButton> */}
               <Notification userDetails={userApi.getUserDetails()} />
               <Avatar alt="Remy Sharp" style={{ marginTop: "0.5rem", backgroundColor: "#0078A5", width:"40px",height:"40px",marginRight:"0.5rem",fontSize:"12px" }}>
                 {userInitials}
                </Avatar>
                <div
                  style={{
                    paddingTop: "6px",
                    cursor: "pointer",
                    marginTop: "0.5rem",
                    color: "#74788D",
                    marginLeft:"0.5rem"
                   
                  }}
                  onClick={handleProfileMenuOpen}
                >
                  <ExpandMoreIcon />
                </div>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
        </Toolbar>
      </AppBar>
      
      <Drawer variant="permanent" open={open}     >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
       
      
        <div style={{ backgroundColor: "#002E3C", height: "87.5%" }}>
     <div >     
         <span>
         {userDetails.roleName !== "LithPwr" ? 
           <img
          //  src={userDetails.roleName !== "LithPwr" ? SolwerLogo : lithpwrLogo}
          src={SolwerLogo}
           style={{
             // marginLeft: "3rem",
             // marginTop: "-5rem",
             // height: "3.75rem",
             // zIndex: 4,
             // minHeight: "20.8vh",
             // fontSize: "calc(10px + 2vmin)",
         marginLeft: "1rem",
         marginTop: "-95px",
         height: "6.75rem",
         // Z-index: "4",
         fontSize:"calc(10px + 21vmin)",
           }}
         alt="logo"
         />
         : 
         <img
       
         src={lithpwrLogo}
          style={{
            // marginLeft: "3rem",
            // marginTop: "-5rem",
            // height: "3.75rem",
            // zIndex: 4,
            // minHeight: "20.8vh",
            // fontSize: "calc(10px + 2vmin)",
        marginLeft: "4.5rem",
        marginTop: "-90px",
        height: "3.5rem",
        width:"6rem",
        // Z-index: "4",
        fontSize:"calc(10px + 21vmin)",
          }}
        alt="logo"
        /> }
      
        </span>
      </div>
      <Divider />
      <ul className={classes.removeBullets}>
        {(isDenso || isClosedMarketplace) ? (
          <li style={{marginTop:"3rem"}}>
            <div
            style={{marginBottom: (!userDetails.roles.includes('ADMIN') ? "0rem" : userDetails.roleName === "LithPwr" ? "6rem"  : "0rem")}}
              className={
                path.includes("/asset")
                  ? classes.activeColorToggle
                  : classes.iconsColorToggle
              }
            >
              <Link to="/asset" style={{ textDecoration: "none" }} >
              <p
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    marginTop:"-25px",
                   
                    marginLeft: "12px",
                  }}
                  onClick={changeOMHandler}
                >
                {/* <AcUnitIcon className={classes.sideNavIcon} style={{marginTop:'3%'}} fontSize="large"/>*/}
                {
                  path.includes("/asset") ? <img
                    src={activateOM}
                    
                    style={{ width: "30px",marginLeft:"-20px",height:'30px' ,marginTop:"-5px",
                    justifyContent: open ? 'initial' : 'center' }}
                    alt="Operation Monitoring"
                  /> : <img
                    src={optMonitor}
                    style={{ width: "30px",height:'30px' ,marginLeft:"-20px",marginTop:"-5px"}}
                    alt="Operation Monitoring"
                  />}
                
                  {" "}
                 <span style={{marginLeft:"20px"}}>Operation Monitoring</span> 
                </p>
              </Link>
              {/* {omFlag ?
              (<>
              <br/>
              {userDetails.roles.includes('ADMIN') ||  userDetails.roleName === 'LithPwr' ?  <p style={{marginLeft:"3.5rem"}}>
                <Link to="/asset" style={{fontSize:"16px", textDecoration: "none"}}>Operation Monitoring(ICE)</Link><br/>
                <Link to="/assetOverview" style={{fontSize:"16px", textDecoration: "none"}}>Operation Monitoring(EV)</Link>
                <br/>
              </p>:""}
              <br/>
              </>)
              : null
} */}
              {/* <span style={{fontSize:'20px',color:'black',marginTop:'4%',marginLeft:'10px'}}> Operation Monitoring</span> */}
            </div>
          </li>
        ) : (
          <></>
        )}
        {isDenso ? (
          <li >
            <div
              className={
                path.includes("/dashboard")
                  ? classes.activeColorToggle
                  : classes.iconsColorToggle
              }
            >
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                {/* <DashboardIcon className={classes.sideNavIcon} style={{marginTop:'2%'}} fontSize="large" /> */}
                {path.includes("/dashboard")
                  ? <img
                    src={activateDT}
                    style={{ fontSize: "2.5rem",width: "30px",marginLeft:"-8px",height:'30px' ,marginTop:"-30px" }}
                    alt="Data Analytics"
                  /> : <img
                    src={dtAnalytics}
                    style={{ fontSize: "2.5rem",width: "30px",marginLeft:"-8px",height:'30px' ,marginTop:"-30px" }}
                    alt="Data Analytics"
                  />}
                <p
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                   
                    marginTop:"-44px"
                    // marginLeft: "10px",
                  }}
                  onClick={changeHSHandler}
                >
                 <span style={{ marginLeft: "47px"}}>Data Analytics</span> 
                </p>
              </Link>
            </div>
          </li>
        ) : (
          <></>
        )}
        {isTransporter ? (
          <li>
            <div
              className={
                path.includes("/transporter")
                  ? classes.activeColorToggle
                  : classes.iconsColor
              }
            >
              <div style={{ marginTop: "1rem" }}>
                <Link to="/transporter" style={{ textDecoration: "none" }}>
                  {/* <img src={sidebar1} style={{marginTop:'2%'}} alt="sidebaricon"/>*/}
                  {
                    path.includes("/transporter") ? <img
                      src={activateTM}
                      style={{width: "30px",marginLeft:"-10px",height:'30px' ,marginTop:"-30px"}}
                      alt="Trip Management"
                    /> : <img
                      src={tripManage}
                      style={{ width: "30px",marginLeft:"-10px",height:'30px' ,marginTop:"-30px" }}
                      alt="Trip Management"
                    />}
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      marginLeft: "10px",
                      textDecoration: "none",
                    }}
                    onClick={changeHSHandler}
                  >
                    {" "}
                    Trip Management{" "}
                  </span>
                </Link>
              </div>
            </div>
          </li>
        ) : (
          <></>
        )}
        {(isShipper) ? (
          <li>
            <div
              className={
                path.includes("/shipper") ||
                  path.includes("/createshipment") ||
                  path.includes("/updateshipment")
                  ? classes.activeColorToggle
                  : classes.iconsColor
              }
            >
              <div style={{ marginTop: "1rem" }}>
                <Link to="/shipper" style={{ textDecoration: "none" }}>
                  {/* <img src={sidebar1} style={{marginTop:'2%'}} alt="sidebaricon"/> */}
                  {
                    path.includes("/shipper") ||
                      path.includes("/createshipment") ||
                      path.includes("/updateshipment")
                      ? <img
                        src={activateTM}
                        style={{ width: "30px",marginLeft:"-10px",height:'30px' ,marginTop:"-70px" }}
                        alt="Trip Management"
                      /> : <img
                        src={tripManage}
                        style={{ width: "30px",marginLeft:"-10px",height:'30px' ,marginTop:"-70px" }}
                        alt="Trip Management"
                      />}
                      <p
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    marginLeft: "47px",
                      marginTop:"-44px"
                  }}
                >
                 <span style={{marginTop:"-10px"}}>   {" "}Trip Management{" "}</span> 
                </p>
                
                </Link>
              </div>
            </div>
          </li>
        ) : (
          <></>
        )}
        {((isDenso || isClosedMarketplace) && (userDetails.tenantName !== "ISE" && userDetails.tenantName !== "ISE foods" )) ? (
          <li>
            <div
              className={
                path.includes("/denso")
                  ? classes.activeColorToggle
                  : classes.iconsColorToggle
              }
              style={{marginTop : (userDetails.firstName === "LithPwrCMP" || userDetails.roleName  === "LithPwr") ? "0.2rem" :  "1%" }}
            >
              <Link to="/denso" style={{ textDecoration: "none" }}>
                {/* <img src={sidebar1} style={{marginTop:'1%',marginLeft:'-3.5px'}} alt="sidebaricon"/> */}
                {
                  path.includes("/denso")
                    ? <img
                      src={activateTM}
                      style={{ width: "30px",marginLeft:"-6px",height:'30px' ,marginTop:"-30px"}}
                      alt="Trip Management"
                    /> : <img
                      src={tripManage}
                      style={{ width: "30px",marginLeft:"-6px",height:'30px' ,marginTop:"-30px" }}
                      alt="Trip Management"
                    />}
                <p
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    marginLeft: "47px",
                      marginTop:"-44px"
                  }}
                >
                 <span style={{marginTop:"-10px"}}>Trip Management{" "}</span> 
                </p>
              </Link>
            </div>
          </li>
        ) : (
          <></>
        )}
      </ul>{" "}
      <></>
      <div
        style={{
          backgroundColor:'#002E3C',
          // height: "4rem",
          // border: "1px solid #000",
          marginTop: "-3rem",
          position: "relative",
        }}
      >
        <MenuItem
          style={{ color: "#fff", padding: "1rem 4rem", fontSize: "16px" }}
          onClick={handleLogout}
          className={classes.iconsColor}
        >
        <PowerSettingsNewRoundedIcon style={{marginLeft:"-44px",marginTop:"25px"}}/>
         <span style={{marginLeft:"30px",marginTop:"25px"}}>Logout</span> 
        </MenuItem>
      </div>
    </div>
        <Divider />
   
      </Drawer>
      {renderMobileMenu}
          {renderMenu}
      
          </div>
      )}
  
    </Box>
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, matchDispatchToProps)(ResponsiveDrawer);