import React, { useState } from "react";
import { Stepper, Step, StepLabel, Button, Grid, Box, Paper } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { Link, useHistory, useParams } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import LocationForm from "./Forms/LocationForm";
import TimeSlotForm from "./Forms/TimeSlotForm";
import RequirementForm from "./Forms/RequirementForm";
import validationSchema from "./FormModel/validationSchema";
import UpdatevalidationSchema from "./FormModel/updateValidationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";
import axios from "axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { marketBaseURL } from "../../../services/_url";
import ShipperAPI from "../../../services/shippingLanding";
import { useEffect } from "react";
import { toast } from "react-toastify";
import InfoIcon from "@material-ui/icons/Info";
import ShipperHelper, {
  ShippingRequestStatus,
} from "../../../services/_helpers";
import { IconButton } from "@material-ui/core";
import MarketPlaceAPI from "../../../services/marketPlaceService";
import UserService from "../../../services/userService";
import "./style.css";
import "../../../web/components/common/Alert/style.css";
import { selectCurrentUser } from "../../../redux/user/user.selector";
import { setCurrentUser } from "../../../redux/user/user.action";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { ResponsiveDrawer } from "../common/Drawer";
import ArrowForward from "@mui/icons-material/ArrowForward";
// import jsonp from 'jsonp';


const steps = ["Location", "Time Slot", "Requirement Criteria"];
const { formId, formField } = checkoutFormModel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginLeft: "20px",
      marginRight: "40px",
    },
    backBtn: {
      color: "#1A0DAB",
    },
  })
);
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    color: "#5A5A5A",
  },
  active: {
    "& $line": {
      borderColor: "#0078A5",
      backgroundColor: "#0078A5",
    },
    color: "#5A5A5A",
  },
  completed: {
    "& $line": {
      borderColor: "#007AA6",
    },
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: "#D8EEF3",
  },
})(StepConnector);

function _renderStepContent(
  step: number,
  setFieldValue?: any,
  values?: any,
  getrequestId?: any
) {
  switch (step) {
    case 0:
      return <LocationForm formField={formField} />;
    case 1:
      return (
        <TimeSlotForm formField={formField} setFieldValue={setFieldValue} />
      );
    case 2:
      return (
        <RequirementForm
          formField={formField}
          setFieldValue={setFieldValue}
          values={values}
          getrequestId={getrequestId}
        />
      );
    default:
      return <div>Internal Error.</div>;
  }
}

export  function CheckoutPage(props: any) {
  const { activeStepProp } = props;
  const id: any = useParams();
  const userDetails = new UserService().getUserDetails();
  const classes = useStyles();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(
    activeStepProp ? activeStepProp : 0
  );
  const [updateData, setUpdateData] = useState([]);
  const [tentant, SetTentant] = useState([]);
  const [stmt, setStmt] = useState("");
  const [btnFlag, setBtnFlag] = useState(false);
  const [getrequestId, setgetrequestId] = useState("");
  let {   currentUser, setCurrentUser } = props;
  const [searchReqId, setSearchReqId] = useState<string>("");
  // const [activeStep, setActiveStep] = useState(0);
  // const[updateData,setUpdateData] =useState([]);
  const [fromLocation, setFromLocation] = useState([]);

  const currentValidationSchema = validationSchema[activeStep];
  const updatevalidationSchema = UpdatevalidationSchema[activeStep];
  const apiKey = process.env.REACT_APP_HERE_MAP_KEY;

  const isLastStep = activeStep === steps.length - 1;
  const isUpdateMode = !!id["id"];
  const updateTemp: any[] = [];
  var updateInitalValues = {
    instructions: "",
    delcity: "",
    delcustomername: "",
    deldoorBuilding: "",
    dellandmark: "",
    delmobilenumber: "",
    delpincode: "",
    delstreetaddress: "",
    destination: "",
    fragileItem: false,
    pickupStartDate: "",
    pickupEndDate: "",
    dropStartDate: "",
    dropEndDate: "",
    pickupTimeStart: "",
    pickupTimeEnd: "",
    dropTimeStart: "",
    dropTimeEnd: "",
    shipaddress: "",
    shipcity: "",
    shipdoorBuilding: "",
    shiplandmark: "",
    shipmobile: "",
    shippincode: "",
    source: "",
    temperature: updateTemp,
    volume: "",
    weight: "",
    indicativePrice: "",
  };

  const errorAlert = () => {
    toast.error(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Unable to Create Shipment.</span>
        </div>
      </div>,
      {
        autoClose: 2000,
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const success = (id: any) => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Successfully created shipment request {id}.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const CustomSuccessPatch = () => {
    return (
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Successfully updated shipment request.</span>
        </div>
      </div>
    );
  };
  const successPatch = () => {
    toast.success(<CustomSuccessPatch />, {
      position: "bottom-right",

      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  };

  useEffect(() => {
    let ship = new ShipperAPI();

    //  debugger

    if (isUpdateMode === true) {
      const marketApi = new MarketPlaceAPI();
      marketApi
        .getRequestsByRequestNumber(id["id"])
        .then((resp: any) => {
          setUpdateData(resp);
        })
        .catch((err: any) => {
          console.error(err);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    }

    ship.getShipperID().then((res) => {
      SetTentant(res);
    });
    //},[]);
    /* istanbul ignore next */
  }, []);

  if (updateData.length !== 0) {
    let ship = new ShipperHelper();
    if (getrequestId === "") {
      setgetrequestId(updateData["requestNumber"]);
    }
    updateInitalValues["delcity"] = updateData["toLocation"]["city"];
    updateInitalValues["delcustomername"] =
      updateData["consumerDetails"]["name"];
    updateInitalValues["deldoorBuilding"] =
      updateData["toLocation"]["doorNoAndBuildingName"];
    updateInitalValues["dellandmark"] = updateData["toLocation"]["landmark"];
    updateInitalValues["delstreetaddress"] =
      updateData["toLocation"]["streetAddress"];
    updateInitalValues["delpincode"] = updateData["toLocation"]["pincode"];
    updateInitalValues["delmobilenumber"] = `${
      updateData["toLocation"]["mobileNo"].startsWith("+91")
        ? updateData["toLocation"]["mobileNo"].slice(3, 13)
        : updateData["toLocation"]["mobileNo"]
    }`;

    updateInitalValues["shipcity"] = updateData["fromLocation"]["city"];
    updateInitalValues["shipdoorBuilding"] =
      updateData["fromLocation"]["doorNoAndBuildingName"];
    updateInitalValues["shiplandmark"] = updateData["fromLocation"]["landmark"];
    updateInitalValues["shipaddress"] =
      updateData["fromLocation"]["streetAddress"];
    updateInitalValues["shippincode"] = updateData["fromLocation"]["pincode"];
    updateInitalValues["shipmobile"] = `${
      updateData["fromLocation"]["mobileNo"].startsWith("+91")
        ? updateData["fromLocation"]["mobileNo"].slice(3, 13)
        : updateData["fromLocation"]["mobileNo"]
    }`;

    updateInitalValues["pickupStartDate"] = ship.putAPIDate(
      updateData["expectedPickUpTime"]["from"]
    );
    updateInitalValues["pickupEndDate"] = ship.putAPIDate(
      updateData["expectedPickUpTime"]["to"]
    );
    updateInitalValues["dropStartDate"] = ship.putAPIDate(
      updateData["expectedDeliveryTime"]["from"]
    );
    updateInitalValues["dropEndDate"] = ship.putAPIDate(
      updateData["expectedDeliveryTime"]["to"]
    );

    updateInitalValues["pickupTimeStart"] = ship.putTimeConversion(
      updateData["expectedPickUpTime"]["from"]
    );
    updateInitalValues["pickupTimeEnd"] = ship.putTimeConversion(
      updateData["expectedPickUpTime"]["to"]
    );
    updateInitalValues["dropTimeStart"] = ship.putTimeConversion(
      updateData["expectedDeliveryTime"]["from"]
    );
    updateInitalValues["dropTimeEnd"] = ship.putTimeConversion(
      updateData["expectedDeliveryTime"]["to"]
    );

    updateInitalValues["volume"] = updateData["indicativeCapacity"]["volume"];
    updateInitalValues["weight"] = updateData["indicativeCapacity"]["weight"];
    updateInitalValues["temperature"] = [
      updateData["temperatureRange"]["minTemperature"],
      updateData["temperatureRange"]["maxTemperature"],
    ];
    updateInitalValues["fragileItem"] =
      updateData["fragile"] === 0 ? false : true;
    updateInitalValues["instructions"] = updateData["instructions"];
    updateInitalValues["indicativePrice"] =
      updateData["indicativePrice"]["price"];
  }

  async function _submitForm(values: any, actions: any) {
    let Ship = new ShipperHelper();

    var pickFrom = Ship.dateToUTC(
      values.pickupStartDate + " " + values.pickupTimeStart
    );
    var pickTo = Ship.dateToUTC(
      values.pickupEndDate + " " + values.pickupTimeEnd
    );
    var dropFrom = Ship.dateToUTC(
      values.dropStartDate + " " + values.dropTimeStart
    );
    var dropTo = Ship.dateToUTC(values.dropEndDate + " " + values.dropTimeEnd);

    // let locationELocFrom = await mapApi.getLatitudeAndLongitude(`${String(values.shipdoorBuilding)}, ${values.shipaddress}, ${values.shipcity}, ${values.shippincode}`);
    // let locationELocTo = await mapApi.getLatitudeAndLongitude(`${values.deldoorBuilding}, ${values.delstreetaddress}, ${values.delcity}, ${values.delpincode}`);


    const fromAddress = `https://geocode.search.hereapi.com/v1/geocode?q=${values.shipdoorBuilding}, ${values.shipaddress}, ${values.shipcity}, ${values.shippincode}&apiKey=${apiKey}`;
    const toAddress = `https://geocode.search.hereapi.com/v1/geocode?q=${values.deldoorBuilding}, ${values.delstreetaddress}, ${values.delcity}, ${values.delpincode}&apiKey=${apiKey}`;
    

    const getlatlng = async () => {
      try {
        const [fromResponse, toResponse] = await Promise.all([
          fetch(fromAddress),
          fetch(toAddress)
        ]);
    
        if (!fromResponse.ok) {
          throw new Error(`HTTP error! Status: ${fromResponse.status}`);
        }
    
        if (!toResponse.ok) {
          throw new Error(`HTTP error! Status: ${toResponse.status}`);
        }
    
        const [fromData, toData] = await Promise.all([fromResponse.json(), toResponse.json()]);
    
        return {
          locationELocFrom: {
            latitude: fromData?.items?.[0]?.position?.lat || "",
            longitude: fromData?.items?.[0]?.position?.lng || ""
          },
          locationELocTo: {
            latitude: toData?.items?.[0]?.position?.lat || "",
            longitude: toData?.items?.[0]?.position?.lng || ""
          }
        };
      } catch (error) {
        console.error('Fetch error:', error);
        throw error; // Re-throw the error to handle it further if needed
      }
    };
    
    const { locationELocFrom, locationELocTo } = await getlatlng();
  // let locationELocFrom;
  // let locationELocTo;
  // ({ locationELocFrom, locationELocTo } = getlatlng());
    // const { locationELocFrom, locationELocTo } = await getlatlng();

    
    // let locationELocFrom;
    // let locationELocTo;
    // if (values.shipdoorBuilding.includes("Fresh Factory")) {
    //   locationELocFrom = {"latitude": -6.22400196, "longitude": 106.7785459}
    // } else {
    //   ({ locationELocFrom, locationELocTo } = getlatlng());
    // }

    // if (values.deldoorBuilding.includes("Gandaria Heights")) {
    //   locationELocTo = {"latitude": -6.244078634, "longitude": 106.7845138}
    // } else if (
    //   values.deldoorBuilding.includes("Apartemen The Pakubuwono spring towe")
    // ) {
    //   locationELocTo = {"latitude": -6.232805607, "longitude": 106.7872725}
    // } else {
    //   ({ locationELocFrom, locationELocTo } = getlatlng());
    // }
    

    

    console.log(locationELocFrom, locationELocTo)

    var postData = {
      requestNumber: getrequestId,
      fromLocation: {
        doorNoAndBuildingName: String(values.shipdoorBuilding),
        streetAddress: values.shipaddress,
        city: values.shipcity,
        pincode: values.shippincode,
        landmark: values.shiplandmark,
        mobileNo:`${values.shipmobilePrefix || '+91'}${values.shipmobile}`,
        lat: locationELocFrom.latitude, 
        lng : locationELocFrom.longitude
      },
      toLocation: {
        doorNoAndBuildingName: values.deldoorBuilding,
        streetAddress: values.delstreetaddress,
        city: values.delcity,
        pincode: values.delpincode,
        landmark: values.dellandmark,
        mobileNo: `${values.delmobilePrefix || '+91'}${values.delmobilenumber}`,
        lat: locationELocTo.latitude, 
        lng : locationELocTo.longitude
      },
      expectedPickUpTime: {
        from: pickFrom,
        to: pickTo,
      },
      expectedDeliveryTime: {
        from: dropFrom,
        to: dropTo,
      },
      temperatureRange: {
        minTemperature: values.temperature[0],
        maxTemperature: values.temperature[1],

        temperatureUOM: "F",
      },
      indicativeCapacity: {
        weight: values.weight,
        weightUOM: "KG",
        volume: values.volume,
        volumeUOM: "L",
      },
      indicativePrice: {
        price: values.indicativePrice ? values.indicativePrice : null, //price not known
        currency: "Rupee",
      },
      consumerDetails: {
        name: values.delcustomername,
        phoneNumber: `${values.delmobilePrefix || '+91'}${values.delmobilenumber}`,
        email: "", // we dint have data from email
      },
      paymentDetails: {
        paid: "",
        paidBy: "",
        paidOn: "",
        actualAmount: "",
        paymentMode: "",
      },
      calculatedDistance: {
        distance: 0.0,
        distanceUOM: "KM",
      },
      fragile: Number(`${values.fragileItem ? 1.0 : 0.0}`),
      instructions: values.instructions,
      status: Ship.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
      shipperId: userDetails?.tenantId,
      transporterId: "",
      audit: [
        {
          action: "Created",
          userId: userDetails?.userId,
          timestamp: Ship.dateToUTC(new Date()),
        },
      ],
    };
    /* istanbul ignore else */
    if (!isUpdateMode) {
      axios
        .post(`${marketBaseURL}/marketplace/shippingRequest`, postData)
        .then((res) => {
          setStmt(res.data.requestNumber);
          history.goBack();
          // history.push("/shipper");
          success(res.data.requestNumber);
        })
        .catch((err: any) => {
          errorAlert();
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });

      //actions.setSubmitting(false);
    } else {
      var testPatchData = updateData;
      var auditUpdate = { action: "", timestamp: "", userId: "" };

      auditUpdate.action = "Updated";
      auditUpdate.timestamp = Ship.dateToUTC(new Date());
      auditUpdate.userId = userDetails?.userId;

      testPatchData["audit"].push(auditUpdate);

      testPatchData["fromLocation"].doorNoAndBuildingName = String(
        values.shipdoorBuilding
      );
      testPatchData["fromLocation"].streetAddress = values.shipaddress;
      testPatchData["fromLocation"].city = values.shipcity;
      testPatchData["fromLocation"].pincode = values.shippincode;
      testPatchData["fromLocation"].landmark = values.shiplandmark;
      testPatchData["fromLocation"].mobileNo = "+91" + values.shipmobile;

      testPatchData["toLocation"].doorNoAndBuildingName = String(
        values.deldoorBuilding
      );
      testPatchData["toLocation"].streetAddress = values.delstreetaddress;
      testPatchData["toLocation"].city = values.delcity;
      testPatchData["toLocation"].pincode = values.delpincode;
      testPatchData["toLocation"].landmark = values.dellandmark;
      testPatchData["toLocation"].mobileNo = "+91" + values.delmobilenumber;
      testPatchData["expectedPickUpTime"].from = pickFrom;
      testPatchData["expectedPickUpTime"].to = pickTo;

      testPatchData["expectedDeliveryTime"].from = dropFrom;
      testPatchData["expectedDeliveryTime"].to = dropTo;

      testPatchData["temperatureRange"].minTemperature = values.temperature[0];
      testPatchData["temperatureRange"].maxTemperature = values.temperature[1];

      testPatchData["indicativeCapacity"].weight = values.weight;
      testPatchData["indicativeCapacity"].volume = values.volume;

      testPatchData["indicativePrice"].price = values.indicativePrice;

      testPatchData["consumerDetails"].name = values.delcustomername;
      testPatchData["consumerDetails"].phoneNumber =
        "+91" + values.delmobilenumber;

      testPatchData["fragile"] = `${values.fragileItem ? 1 : 0}`;
      testPatchData["instructions"] = values.instructions;

      // var patchData = {
      //   createdAt:updateData['createdAt'],
      //   id:updateData['id'],//from API
      //   requestNumber : updateData['requestNumber'],//from API
      //   fromLocation: {
      //     doorNoAndBuildingName: String(values.shipdoorBuilding),
      //     streetAddress: values.shipaddress,
      //     city: values.shipcity,
      //     pincode: values.shippincode,
      //     landmark: values.shiplandmark,
      //     mobileNo: "+91"+values.shipmobile
      //   },
      //   toLocation: {
      //     doorNoAndBuildingName: values.deldoorBuilding,
      //     streetAddress: values.delstreetaddress,
      //     city: values.delcity,
      //     pincode: values.delpincode,
      //     landmark: values.dellandmark,
      //     mobileNo: "+91"+values.delmobile
      //   },
      //   expectedPickUpTime: {
      //     from:pickFrom,
      //     to:pickTo

      //   },
      //   expectedDeliveryTime: {
      //     from:dropFrom,
      //     to:dropTo

      //   },
      //   temperatureRange: {
      //     minTemperature: values.temperature[0],
      //     maxTemperature: values.temperature[1],
      //     temperatureUOM: "F"
      //   },
      //   indicativeCapacity: {
      //     weight: values.weight,
      //     weightUOM: "KG",
      //     volume: values.volume,
      //     volumeUOM: "L"
      //   },
      //   indicativePrice: {
      //     price: values.indicativePrice ? values.indicativePrice : null,//price not known
      //     currency: "Rupee"
      //   },
      //   consumerDetails: {
      //     name: values.delcustomername,
      //     phoneNumber:"+91"+values.delmobilenumber,
      //     email: ""// we didnt have data from email
      //   },
      //   paymentDetails: {
      //     paid: "",
      //     paidBy: "",
      //     paidOn: "",
      //     actualAmount:"",
      //     paymentMode: ""
      //   },
      //   calculatedDistance: {
      //     distance: 0.0,
      //     distanceUOM: "KM"
      // },
      //   fragile: `${values.fragileItem ?1:0}`,
      //   instructions: values.instructions,
      //   status: Ship.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
      //   shipperId: updateData['shipperId'],//from API
      //   transporterId: "",
      //   audit: [
      //     {
      //       action: "Updated",
      //       userId: updateData['audit']["userId"],//from API
      //       timestamp: Ship.dateToUTC(new Date())
      //     }
      //   ]
      // }
      axios
        .patch(
          `${marketBaseURL}/marketplace/shippingRequests/${updateData["id"]}`,
          testPatchData
        )
        .then((res) => {
          successPatch();
          history.goBack();
          // history.push("/shipper");
        });
      //actions.setSubmitting(false);
    }
  }

  const _handleSubmit = async (values: any, actions: any) => {
    //console.log("values from create shipper form", values);
    if (activeStep >= 2) {
      setBtnFlag(true);
      actions.setSubmitting(true);
      await _submitForm(values, actions);
    } else if (activeStep === 0) {
      if (getrequestId === "") {
        let Ship = new ShipperHelper();
        const getShipperName: any = (id: any) =>
          tentant.filter((val: any) => val.id === id);
        var tenantName = getShipperName(userDetails?.tenantId)[0].name;
        let requestId =
          tenantName.substring(0, 3).toUpperCase() + Ship.requestNumber();
        setgetrequestId(requestId);

        await setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      } else {
        await setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      }
    } else {
      await setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
      actions.setTouched({});
    }
  };

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:"-10px" }}>
    <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
    <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <React.Fragment>
      <div className={classes.root}>
        <Formik
          initialValues={isUpdateMode ? updateInitalValues : formInitialValues}
          enableReinitialize={true}
          data-testid="checkoutFormikInitialisation"
          validationSchema={
            isUpdateMode ? updatevalidationSchema : currentValidationSchema
          }
          onSubmit={_handleSubmit}
          // style={{marginLeft:'6rem'}}
        >
          {({
            isSubmitting,
            values,

            handleChange,

            setFieldValue,
          }) => (
            <Form id={formId}>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  {/* <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => {
                      window.history.back();
                    }}
                    style={{ color: "#5A5A5A", cursor: "pointer" }}
                  >
                    <ArrowBackIcon />
                  </IconButton> */}

                  <span
                    id="title"
                    style={{ fontSize: "16px", fontWeight: 400,marginLeft:"7px" }}
                  >
                    {isUpdateMode ? "Update" : "Create"} Shipment Request
                  </span>
                </Grid>
                <Grid item xs={7}>
                  <div style={{ float: "right",marginRight:"22px",marginTop:"-10px" }}>
                    {activeStep >= 1 ?
                    <Button
                      variant="outlined"
                      onClick={_handleBack}
                      disabled={activeStep === 0}
                      style={{  marginLeft:(isLastStep ? "445px": "-30px"), marginTop:"10px",
                      textDecoration:"none",width:"65px",
                    
                      
                    
                      height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",textTransform: 'capitalize'
                      ,backgroundColor:"#fff",color:"grey" }}
                      className={activeStep === 0 ? "" : classes.backBtn} 
                    >
                       <ArrowBackIcon  style={{marginLeft:"-3px",fontSize:"15px"}}/>
                      <span style={{marginLeft:"3px"}}>Back</span> 
                    
                    </Button>: 
                    <Link to={{ pathname: '/denso'}}><button style={{textDecoration:"none",width:"65px",marginLeft:"-30px",
             
                    
                    height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"10px",textTransform: 'capitalize'
                    ,backgroundColor:"#fff",color:"grey"}}>
                      <ArrowBackIcon  style={{marginLeft:"-2px",fontSize:"15px"}}/>
                     <span style={{marginRight:"2px"}}>    Back</span>
                   </button></Link>
                      
                    }

                    <Button
                      disabled={btnFlag}
                      type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      style={{
                        marginLeft:(isLastStep ? "520px" : "45px"),
                         
                        backgroundColor:"#007AA6",
                        width: (isLastStep ? '160px' : '65px'),
                        height:"32px",
                        color: "#fff",
                        border:0,
                        marginTop:"-32px",
                        fontSize:"13px",
                        textTransform:'capitalize'
                       

                      }}
                    >
                      {isLastStep ? (
                        <>
                          {isUpdateMode ? "Update" :  <span style={{marginLeft:"-6px"}}>Finish And Publish</span>}
                          <DoneIcon  style={{marginTop:"-3px",marginRight:"-6px",fontSize:"17px",marginLeft:"3px"}}/>
                        </>
                      ) : (
                        <>
                          Next
                          <ArrowForward style={{marginLeft:"6.5px",fontSize:'15px'}}/>
                        </>
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Paper style={{border:"1px solid #fff",boxShadow:'none',marginTop:"25px"}}>

              <Stepper
                style={{ borderRadius:"3px",height:"90px",marginLeft:"95px",marginRight:'50px'}}
            
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label} style={{marginTop:"-19px"}}>
                    <StepLabel >
                      <span style={{fontSize:"14px"}}>{label}</span></StepLabel>
                    
                  </Step>
                ))}
                
              </Stepper>
              </Paper>
              <Paper className="Paper" style={{marginTop:"2px",boxShadow:"none"}}>
              {_renderStepContent(
                activeStep,
                setFieldValue,
                values,
                getrequestId
              )}
              </Paper>
              
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
     </Box>
     </Box>
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(CheckoutPage);