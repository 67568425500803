import Popover from '@mui/material/Popover';
import Plot from 'react-plotly.js';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useEffect, useState } from 'react';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Box from "@mui/material/Box";

const TemperatureExcursion = (props: any) => {
    // const Headingstyle: any = {
    //     textAlign:'left',
    //     marginLeft:'-28rem',
    //     paddingTop: '1.5rem',
    //     marginBottom: '1rem',
    //     fontFamily: 'Roboto',
    //     fontWeight: 500,
    //     fontSize: '1rem',
    //     color: "#000000DE",
    //     float:'left'
    // }

    
      const [anchorEl, setAnchorEl] = useState(null);
      const [openPopup,setOpenPopup] = useState<boolean>(false)
      const [left,setLeft] = useState(0);
      const [top,setTop] = useState(0);
      const [popupData,setpopupData] = useState("");
      const [popupDataFlag,setpopupDataFlag] = useState("");
      const [popupDataVar,setpopupDataVar] = useState("");
      const [zaxis,setZAxis] = useState([]);
      const [xaxis,setXAxis] = useState([]);
      
      useEffect(()=>{
        setZAxis(props.zaxis);
        setXAxis(props.xaxis);
      },[props.zaxis, props.xaxis])

        return (
        <div >  
          {/* {props.noData !== '' ? <div style={{height:'330px'}}>{props.noData}</div> : */}
          <div >
            
       
        <Popover
        style={{marginLeft:left-(170/2),marginTop:top,width:'170px'}}
        id="mouse-over-popover"
        open={openPopup}
        anchorEl={anchorEl}
        onClose={()=>{setOpenPopup(false);setAnchorEl(null)}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0
          }
        }}
       
        disableAutoFocus
      >
         <Box
          sx={{
            position: "relative",
            mt: "10px",
            "&::before": {
              backgroundColor: "white",
              content: '""',
              display: "block",
              position: "absolute",
              width: 12,
              height: 12,
              top: -3,
              transform: "rotate(45deg)",
              left: "calc(50% - 6px)"
            }
          }}
        /> 
        <div style={{background:'#fff',padding:'8px'}}><span style={{fontWeight:500}}>{popupData}</span>
        {popupDataFlag==="above range"?<span style={{color:'#B00020',fontSize:10}}><TrendingUpIcon style={{color:'#B00020'}}/> {popupDataVar}</span>:""}
        {popupDataFlag==="above range-warning"?<span style={{color:'#E6232A',fontSize:10}}><TrendingUpIcon style={{color:'#E6232A'}}/> {popupDataVar}</span>:""}
        {popupDataFlag==="inline range-warning"?<span style={{color:'#96DDF7',fontSize:10}}><TrendingFlatIcon style={{color:'#96DDF7'}}/> {popupDataVar}</span>:""}
        {popupDataFlag==="inline range"?<span style={{color:'#1692BD',fontSize:10}}><TrendingFlatIcon style={{color:'#1692BD'}}/> {popupDataVar}</span>:""}</div>
      </Popover>
     
            <Plot
            data-testid='heatmap'
                data={[{
                    // x: ['Mar 21st 2023','Mar 22st 2023','Mar 23st 2023','Mar 24st 2023','Mar 25st 2023','Mar 26st 2023','Mar 27st 2023','Mar 28st 2023','Mar 29st 2023','Mar 30st 2023','Mar 24st 2023'],
                    x : xaxis,
                    y: ['06:00', '12:00', '18:00','23:59'],
                    z: zaxis,
                    type: 'heatmap',
                    showscale: false,
                  
                    text: [props.tooltip1,props.tooltip2,props.tooltip3,props.tooltip4],
                    colorscale: [
                        [0, '#F8CA8D'],
                        [1, '#DD5E7C'],
                    ],
                    // customdata:[props.f1,props.f2,props.f3,props.f4],
                   // hovertemplate:'<b style=color:red></b>',
                    // hoverinfo:"none",
                   // hovertemplate: '<b style=color:red>%{text}</b> '+'<extra>%{customdata}</extra>',
                    name:'',
                    hoverlabel: { bgcolor: "#FFF", bordercolor: '#FFF', 
                     font: {
                      color: 'black',
                     
                      size: 12
                }
              },
                }]}
                layout={
                    {
                      
                        margin: { l: 55, r: 30, b: 115, t: 20 },
                        xaxis:{
                            tickfont:{
                                family:'Roboto',
                                size:14
                            }
                        },
                  
                        yaxis:{
                            tickfont:{
                                family:'Roboto',
                                size:14
                            },
                            showticklabels :false
                        },
                        legend: {
                            x: 0.25,
                            y: -0.2,
                            orientation: 'h'

                        },
                        annotations: [  
                         
                          {
                              showarrow: false,
                              text:"23:59",    // #layout-annotations-text
                              x: -0.06,                        // #layout-annotations-x
                              xref: 'paper',                // #layout-annotations-xref
                              y: 1.02,                         // #layout-annotations-y
                              yref: 'paper',
                              yanchor: 'top',
                             
                            },
                            {
                              showarrow: false,
                              text:"18:00",    // #layout-annotations-text
                              x: -0.06,                        // #layout-annotations-x
                              xref: 'paper',                // #layout-annotations-xref
                              y: 0.79,                         // #layout-annotations-y
                              yref: 'paper',
                              yanchor: 'top',
                             
                            },
                            {
                              showarrow: false,
                              text:"12:00",    // #layout-annotations-text
                              x: -0.06,                        // #layout-annotations-x
                              xref: 'paper',                // #layout-annotations-xref
                              y: 0.54,                         // #layout-annotations-y
                              yref: 'paper',
                              yanchor: 'top',
                             
                            },
                            {
                              showarrow: false,
                              text:"06:00",    // #layout-annotations-text
                              x: -0.06,                        // #layout-annotations-x
                              xref: 'paper',                // #layout-annotations-xref
                              y: 0.29,                         // #layout-annotations-y
                              yref: 'paper',
                              yanchor: 'top',
                             
                            },
                            {
                              showarrow: false,
                              text:"00:00",    // #layout-annotations-text
                              x: -0.06,                        // #layout-annotations-x
                              xref: 'paper',                // #layout-annotations-xref
                              y: 0.05,                         // #layout-annotations-y
                              yref: 'paper',
                              yanchor: 'top',
                             
                            }
                          ],
                        height:330,
                       
                        
                    }
                }
                style={{ maxWidth: '1300px', maxHeight: '330px' }}
                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
                onClick={(data:any)=>{
                  // setOpenPopup(true);
                  // setLeft(data.event.clientX);
                  // setpopupData(data.points[0].text);
                  // setTop(data.event.clientY)
                  // var data1=data.points[0].customdata
                  // setpopupDataVar(data1.substring(0,data.points[0].customdata.indexOf(":")))
                  // setpopupDataFlag(data1.substring(data.points[0].customdata.indexOf(":")+1))
                  
                  
                }}
             
            />
            
            </div>
           
              
        </div>

    )
}

export default TemperatureExcursion
