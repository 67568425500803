import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
//import Slider from "@material-ui/core/Slider";
import { useField } from "formik";
 import { Slider } from '@mui/material';

import './style.css'
const useStyles = makeStyles({
  root: {
    width: "100%%",
    // color:'white'
  },
  slider :{
    width:"85%",
    color:'#1A0DAB',
    height:"24",
  }

});


function valuetext(value:any) {
  
  return `${value}°C`;
  
}

export default function RangeSlider(props:any) {
  const classes = useStyles();
  const [value1, setValue1] = useState([-10, 10]);
  const {  ...rest } = props;
  const [field, meta, helper] = useField(props);

   const { setValue } = helper;

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue1(newValue as number[]);
    setValue(newValue as number[]);
  };
  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
       <p  style={{fontWeight: 700,
    color: "#000000DE",fontSize:'14px'}}> Temperature Required  </p> 
       
      </Typography>
    <br/>

    <Slider
  getAriaLabel={() => 'Temperature range'}
  {...field}
        {...rest}
        className={classes.slider}
        min={-10}
        max={10}
       size="small"
      
  onChange={handleChange}
  valueLabelDisplay="on"
  valueLabelFormat={value => <div>{`${value}°C`}</div>}

  getAriaValueText={valuetext}
/>
     
    </div>
  );
}
