import { Box, Grid } from '@material-ui/core';
import './style.css';
import { useEffect, useState } from 'react';
import UserService, { ROLE } from '../../../services/userService';
import ShipperAPI from '../../../services/shippingLanding';
import TelemeteryAPI from '../../../services/telemeteryService';
import trendUp from '../../../assets/images/trending_up_red.svg';
import trendFlat from '../../../assets/images/trending_flat_blue.svg';
import trendDown from '../../../assets/images/trending_down_green.svg';
import { Skeleton } from "@material-ui/lab";
import ShipperHelper from '../../../services/_helpers';
import moment from 'moment';
import axios from 'axios';
import { telemetry } from '../../../services/_url';

export interface ICollapseTableProps {
    status?:String;
    feedback?:[any,any,any];
    isPendingRow ?: boolean;
    row?:any
}

export default function CollapseTable (props: any ) {
  const [roles,setRoles]=useState([])
    let [isTelemetryLoading, setIsTelemetryLoading] = useState<boolean>(true);
    // var pendingTelemetricData=["---","---","---","---","---"];
    let [telemetricData, setTelemetricData] = useState<(string | number)[]>(["---","---","---","---","---","---"]);
    let [variationData, setVariationData] = useState<(string | number)[]>(["","","","","",""]);
    var [flags, setFlags]= useState<string[]>(["","","","",""]);
    const [batterySoc, setBatterySoc] = useState<string>("");
    var units =['°C','gm/m3','pa','times','times'];
    const [feedback,setFeedback]=useState('');
    const [telemetery,setTelemetry]=useState([]);
    const [assetData, setAssetData]= useState([]);
    const [temp,setTemp] = useState(null);
    const [lidopen,setLidopen] = useState(null);
    const [shock,setShock] = useState(null);
    var telmeteryData=[{"Temperature":{"flag":"---","value":"---","variation":"---","name":"Temperature"}},
    {"Humidity":{"flag":"---","value":"---","variation":"---","name":"Humidity"}},
    {"Pressure":{"flag":"---","value":"---","variation":"---","name":"Pressure"}},
    {"TimesLidOpened":{"flag":"---","value":"---","variation":"---","name":"TimesLidOpened"}},
    {"NoOfShockEvents":{"flag":"---","value":"---","variation":"---","name":"NoOfShockEvents8*/"}}]
   
    
    var resTel=[{"flag":"","value":"","variation":"","metricName":"Temperature"}]
    
    const metricNames = ["Temperature", "Humidity", "Pressure", "LidOpen", "ShockEvents"];
    resTel.map((a,i)=>{
      telmeteryData[0][a.metricName]["flag"]=a.flag
      telmeteryData[0][a.metricName]["value"]=a.value
      telmeteryData[0][a.metricName]["variation"]=a.variation
      
    })
    useEffect(()=>{
      
      const shippingLanding = new ShipperAPI();
      const telemeteryApi = new TelemeteryAPI();
      if(props.row?.asset?.id){
      let fromDate: any = moment().subtract(3, 'days');
      let toDate: any = moment();
      let parsedFromDate = moment(fromDate).utc();
      fromDate = parsedFromDate.format("YYYYMMDDHHmm")
      let parsedToDate = moment(toDate).utc()
      toDate =  parsedToDate.format("YYYYMMDDHHmm")
      axios.get(`${process.env.REACT_APP_API_TELEMETRY}/telemetry/gatewayanddevice?from=${fromDate}&to=${toDate}&assetId=${props.row?.asset?.id}`)
      .then(res => {
        if(res.data && res.data.length){
          let lidcount = 0
          let shockcount = 0;
          let temp = 0
          res.data.forEach((ele: any) => {
            lidcount += ele.lo >= 1 ? 1 : 0;
            shockcount += +ele.se
            temp = +ele.temp <= 0 ? temp : ele.temp
          })
          setTemp(temp);
          setLidopen(lidcount);
          setShock(shockcount);
        }
      })
      .catch((err:any)=>{
        const UserToken = new ShipperHelper()
        UserToken.UserTokenExpiration()
      })
    }
      shippingLanding.getFeedbackByID(props.row.id).then((res:any)=>{
        setFeedback(res.data.feedback)
      }).catch((err:any)=>{
        const UserToken = new ShipperHelper()
        UserToken.UserTokenExpiration()
      })
      setIsTelemetryLoading(true);
      //Battery Soc api implemenmented
      if(props.row?.asset) {
        telemeteryApi.getBatterySocData(props.row?.asset?.id, moment(props.row?.expectedPickUpTime.from).format("YYYY-MM-DD"), moment(props.row?.expectedPickUpTime.to).format("YYYY-MM-DD")).then((res:any)=> {
          
          if(res.length) {
            setBatterySoc(res[0].soc);
          }
        }).catch((err:any)=>{
          console.log(err,"battery soc err")
        })
        getTemprature(props.row?.asset?.id)
        getLidopen()
      }

      telemeteryApi.getTelemetryDataByID(props.row.tripId).then((res:any)=>{
        setTelemetry(res)
        const tempTelemetricData: any = [];
        const tempVariationData: any = [];
        const tempFlags: any = [];
        metricNames.forEach((metric: string) => {
          let item = res.find((r: any) => r.metricName === metric);
          if(!item) { // if not found for that particular metric
            item = {
              value: '---',
              variation: '',
              flag: ''
            }
          }
          tempTelemetricData.push(item.value);
          tempVariationData.push(item.variation);
          let flag = '';
          /* istanbul ignore next*/
          if(item.flag.includes('above')) {
            flag = 'above range';
          } else if (item.flag.includes('below')) {
            flag = 'below range';
          }else if (item.flag.includes('inlineCount')) {
            flag = 'inlineCount';
          }else if (item.flag.includes('inline')) {
            flag = 'inline';
          }
          tempFlags.push(flag);
        });
        setTelemetricData(tempTelemetricData);
        setVariationData(tempVariationData);
        setFlags(tempFlags);
        setIsTelemetryLoading(false);
      }).catch((err:any)=>{
        setIsTelemetryLoading(false);
        const UserToken = new ShipperHelper()
        UserToken.UserTokenExpiration()
      })
      const userApi = new UserService();
      const userDetails = userApi.getUserDetails();
      if(userDetails?.token) {
        setRoles(userDetails.roles)
      
   }

    },[]);
  const getTemprature = async(assetId: any) => {
    const to = moment().format('MM-DD-YYYY');
    const from = moment().subtract(2, 'days').format('MM-DD-YYYY');
    const fromDate = new Date(from).valueOf()
    const toDate = new Date(to).valueOf();
    let number = props.row.asset.assetType.name === 'Reefer Truck' ? `KA52A${props.row?.asset?.number}` : props.row?.asset?.number;
    // let response = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${from}&to=${to}&assetId=${assetId}`)
    // let response = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/history?vehicle_number=${number}&from=${fromDate}&to=${toDate}`)
   
    // if(response.data) {
    //   const result  = response.data?.data?.data;
    //   const length = result.length;
    //   let lastRecord = result[length - 1];
    //   //const max = Math.max(...result.ts);
    //   if(result.length) {
    //     setTemp(lastRecord?.tempvalue)
    //   }
   
    //   // const index = result.indexOf(max);
    //   // console.log(index, max, "telemetry");
    // }
    
  }

  const getLidopen = async() => {
    const to = moment().format('MM-DD-YYYY');
    const from = moment().subtract(1, 'days').format('MM-DD-YYYY');
    const fromDate = new Date(from).valueOf()
    const toDate = new Date(to).valueOf();
    let number = props.row.asset.assetType.name === 'Reefer Truck' ? `KA52A${props.row?.asset?.number}` : props.row?.asset?.number;
    // let response = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${from}&to=${to}&assetId=${assetId}`)
    // let response = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/bletagshistory?vehicle_number=${number}&from=${fromDate}&to=${toDate}`)
   
    // if(response.data) {
    //   const result  = response.data?.data?.data;
    //   let lidcount=+0;
    //   let shockcount=+0;
      
    //   result.map((x1: any) => {
        
    //     if (+x1.lux1){
    //       lidcount=lidcount + 1;
    //     }
    //     if(+x1.humi1){
    //       shockcount = shockcount + 1;
    //     }
    //   });
    //   setLidopen(lidcount);
    //   setShock(shockcount);
    // }
    
  }
  return (
    <div>
       <Box margin={1}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                        <Grid container justifyContent="flex-start" alignItems="center">

                            <Grid item xs={6}>
                                 <table className="table table-borderless">
                                   <tr className='collapseHeader tagboxHeader'>
                                   <td scope="col">{props.feedback[0]}</td></tr>
                                   <tbody>
                                    <tr className='collapseCell'>
                                    <td>{(roles?.includes(ROLE.transporter) || roles?.includes(ROLE.denso))?<>
                                    {props.row.indicativePrice.price>0?props.row.indicativePrice.price:"---"}</>:<>
                                    {props.row?.freshnessIndex>0?props.row?.freshnessIndex:'---'}</>}
                                     </td>
                                   
                                    </tr>
                                    </tbody>
                                    </table>
                            </Grid>
                            <Grid item xs={6}>
                                <table className="table table-borderless">
                                <tr className='collapseHeader tagboxHeader'>
                                <td scope="col">{props.feedback[1]}</td>
                                </tr>
                                <tbody>
                                <tr className='collapseCell'>
                                <td scope="col" >
                                {(roles.includes(ROLE.transporter) || roles.includes(ROLE.denso))?<span>
                                {props.row.paymentDetails.actualAmount>0?props.row.paymentDetails.actualAmount:"---"}</span>:
                                <span >
                                {feedback?feedback:"---"}</span>}
                                 </td>
                                </tr>
                                </tbody>
                                </table>
                            </Grid>
                        </Grid>
                <Grid item xs={6}>
                    <table className="table table-borderless">
  
                        <tr className='collapseHeader tagboxHeader'>
    
                        <td scope="col">{props.feedback[2]}</td>
   
                        </tr>

                        <tbody>
                        <tr className='collapseCell'>
    
                        <td>{props.row?.calculatedDistance?.distance>0 ? <div>{props.row?.calculatedDistance?.distance}<span style={{paddingLeft:'5px', fontSize:'12px'}}>{props.row?.calculatedDistance?.distanceUOM}</span></div>:"---"}</td>
    
    
                        </tr>
  
                        </tbody>
                    </table>
                </Grid>

         
                </Grid>
                <Grid item xs={9}>
        <table className="table table-borderless">
  <thead>
    
    <tr id='tagboxHeader'>
      <td scope="col" className='tagboxCell' style={{textTransform:"capitalize",fontWeight:500,letterSpacing:"0px"}}>Battery SOC</td>
      <td scope="col" className='tagboxCell' style={{textTransform:"capitalize",fontWeight:500,letterSpacing:"0px"}}>Temperature</td>
      {/* <td scope="col" className='tagboxCell' style={{textTransform:"capitalize",fontWeight:500,letterSpacing:"0px"}}>Humidity</td> */}
      {/* <td scope="col" className='tagboxCell' style={{textTransform:"capitalize",fontWeight:500,letterSpacing:"0px"}}>Pressure</td> */}
      <td scope="col" className='tagboxCell' style={{textTransform:"capitalize",fontWeight:500,letterSpacing:"0px"}}>Times LID Opened</td>
      <td scope="col" className='tagboxCell' style={{textTransform:"capitalize",fontWeight:500,letterSpacing:"0px"}}>No. of. Shock Events</td>
    </tr>
  </thead>
  <tbody>
    <tr className='collapseHeader1'>
    <td className='tagboxCell'><span className='telemetryValues' style={{fontSize:"18px"}}>{batterySoc ? batterySoc : '---' }</span></td>
    <td className='tagboxCell '><span className='telemetryValues' style={{fontSize:"18px"}}>{temp ? temp : '---'}</span></td>
    {/* <td className='tagboxCell '><span className='telemetryValues' style={{fontSize:"18px"}}>---</span></td> */}
    {/* <td className='tagboxCell '><span className='telemetryValues' style={{fontSize:"18px"}}>---</span></td> */}
    <td className='tagboxCell '><span className='telemetryValues' style={{fontSize:"18px"}}>{lidopen ? lidopen: '---'}</span></td>
    <td className='tagboxCell '><span className='telemetryValues' style={{fontSize:"18px"}}>{shock ? shock : '---'}</span></td>
   {/* {
      telemetricData.map((x,i)=>{
        return(
            <td className='tagboxCell '>
              <span className='telemetryValues'>
                { isTelemetryLoading ? <Skeleton variant="text" /> : x }
                { (!isTelemetryLoading && x !=='---') ? <span className="telemetry-units"> {units[i]}</span> : "" }
              </span>
            </td>
        )
      })} */}

    </tr>
    <tr className='collapseHeader1'>
    {variationData.map((x,i)=>{return(
          <td className='tagboxCell telemetryVariation'>
          <span style={{position:'relative', marginRight: '2px'}}>
            { isTelemetryLoading ? (
              <>
              <img src={trendUp} id="trend-up-loading" style={{height:'1rem', position:'absolute', right:'0'}} alt="trending-up"/>
              <img src={trendDown} id="trend-down-loading" style={{height:'1rem', position:'absolute', right:'0'}} alt="trending-down"/>
              <img src={trendFlat} id="trend-flat-loading" style={{height:'1rem', position:'absolute', right:'0'}} alt="trending-flat"/>
              </>
            ) :
              (flags[i] === 'above range') ? (
                <span className='above'>
                  <img src={trendUp} style={{height:'1rem'}} alt="trending-up"/>
                  <span> {x || x === 0 ? x + ' ' + units[i] : ''}</span>
                </span>
              ) : ((flags[i] === 'below range') ? (
                <span className='below'>
                  <img src={trendDown} style={{height:'1rem'}} alt="trending-down"/>
                  <span> {x || x === 0 ? x + ' ' + units[i] : ''}</span>
                </span>
              ) : ((flags[i] === 'inline') ? (
                <span className='inline'>
                  <img src={trendFlat} style={{height:'1rem'}} alt="trending-flat"/>
                  <span> {x || x === 0 ? x + ' ' + units[i] : ''}</span>
                </span>
              ) :
              ((flags[i] === 'inlineCount') ? (
                <span className='inlineCount'>
                  <img src={trendDown} style={{height:'1rem'}} alt="trending-down"/>
                  <span> {x || x === 0 ? x + ' ' + units[i] : ''}</span>
                </span>
              ): (
                <span className='inline'>
                  <span>- - -</span>
                </span>
              ))))
            }
          </span>
            {/* <span className='above'>
              {flags[i]==='above range'?<span>{x}<TrendingUpIcon></TrendingUpIcon></span>:""}
            </span>
            <span className='below'>
              {flags[i]==='below range'?<span>{x}<TrendingDownIcon></TrendingDownIcon></span>:""}
            </span>
            <span className='inline'>
              {flags[i]==='inline'?<span>{x}<TrendingFlatIcon></TrendingFlatIcon></span>:""}
            </span> */}
          </td>
      )})}
    
    </tr>
    
  </tbody>
</table>
      
        </Grid>
            </Grid>

       
            </Box>
    </div>
  );
}


