import {
    createStyles,
    makeStyles,
    Theme,
    Button,
    Grid, 
    TextField
  } from "@material-ui/core";
  import Alert from '@mui/material/Alert';
  import { useState } from "react";
  import { useLocation, useHistory } from 'react-router-dom';
  import "../../AssetManagement/style.css";
  import { connect } from 'react-redux';
  import UserService from '../../../../services/userService';
  import {toast} from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  toast.configure();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        fontSize: "12pt",
        boxShadow: "none",
      },
      pageHeader: {
        fontSize: "1.5rem",
      },
      pageTopBar: {
        backgroundColor: "#fff",
      },
      tabsWrapper: {
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
        position: "relative",
        "@media (max-width:500px)": {
          marginTop: "3rem",
        },
      },
      
    })
  );
 
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  export interface IAssetProps {
    user: any
  }
  
  function ChangePassword(props: IAssetProps) {
    const classes = useStyles();
    const { search } = useLocation();
    const history = useHistory();
    const [value, setValue] = useState(0);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validPwd, setIsValidPwd] = useState(true);
    const [pswMsg, setPswMsg] = useState("");
    const [isValidCPass, setIsValidCPass] = useState(false);
    const strongRegex = new RegExp("^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$");
    const userApi = new UserService();
    // var userDetails = queryString.parse(search);
    
   //console.log(props.user);
    const validatePassword = (password: string) => {
      if (!password) {
        setIsValidPwd(false);
        setPswMsg("Please enter password");
        return false;
      } else {
        //console.log(password.length);
        //setIsValidPwd(strongRegex.test(password))
        //password.length
        if (password.length) {
          setPswMsg("");
          return true;
        } else {
          setPswMsg("Password not Valid format");
          setIsValidPwd(false);
          return false;
        }
      }
    }
  
    const comparePassword = () => {
      if (confirmPassword === newPassword) {
        setIsValidPwd(true)
        setPswMsg("")
        return true;
      } else {
        setIsValidPwd(false);
        setPswMsg("Password not matching");
        return false;
      }
    }
    const handleChangePassword = (event: any) => {
      //setValue(newValue);
      // console.log(event);
      // console.log(oldPassword, newPassword,confirmPassword);
      let compare = comparePassword();
      if(compare) {
        let validate = validatePassword(newPassword);
        if(validate) {
          //  console.log(props);
           const headers = {
            'Authorization': `Bearer ${props.user.token}`,
            'X-Frame-Options':'DENY'
          }
          var data = {
            "email": props.user.email,
            "password": newPassword,
            "oldPassword": oldPassword
          }
    
          userApi.changePassword(headers, data).then((res: any) => {
            if (res.response === "Password updated successfully") {    
              toast('Password updated successfully')
              history.push('/');
            } else {
              // console.log(res);
              toast(res.response);
              setPswMsg("error");
            }
    
          })
        }
      }
    };
  
    return (
      <div className={classes.root}>
        <div className={classes.pageTopBar}>
          <div className={classes.pageHeader} data-testid="passwords">
            Change Password
            {/* <div className={classes.addNewAsset}>
              <Button variant="outlined" color="primary" disabled>
                <AddIcon style={{ color: "#b5b5b5" }} /> ADD NEW ASSET
              </Button>
            </div> */}
          </div>
          <hr></hr>
          <div className="layout-border">
            <Grid container spacing={2}>
                {validPwd ? "" :
                  <Grid item xs={12}>
                    <Alert severity="error" data-testid="errors">{pswMsg}</Alert>
                  </Grid>
                }
                <Grid item xs={12}>
                   <TextField fullWidth  id="outlined-basic" data-testid="OldPasswords" type="password" label="Old Password" variant="outlined" onChange={event => setOldPassword(event.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth  id="outlined-basic"  data-testid="NewPasswords" type="password" label="New password" variant="outlined" onChange={event => setNewPassword(event.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth  id="outlined-basic"  data-testid="ConfirmPasswords" type="password" label="Confirm Password" variant="outlined" onChange={event => setConfirmPassword(event.target.value)} />
                </Grid>
                <Grid item xs={12} >
                    <Button variant="contained"  data-testid="ChangePasswords" color="primary" fullWidth onClick={handleChangePassword} disableElevation>
                       Change Password
                    </Button>
                </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  function mapStateToProps(state: any, ownProps: {}) {
    //console.log(state);
    return {
        user: state.user.currentUser
    };
}
  export default connect(mapStateToProps, null)(ChangePassword);