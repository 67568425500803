import React, { Fragment, useEffect, useState } from "react";
import { Grid, InputAdornment, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../../../common/FormFields";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import axios from "axios";
import { assetBaseURL } from "../../../../../services/_url";
import ShipperAPI from "../../../../../services/shippingLanding";
import UserService from "../../../../../services/userService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    chipCSS: {
      color: "#007AA6",
      backgroundColor: "rgba(26, 13, 171,.1)",
      marginLeft: "10px",
      "&:hover": {
        // color: "#1A0DAB",
        backgroundColor: "rgba(26, 13, 171,.1)",
      },
    },
    chip: {
      marginLeft: "10px",
    },
  })
);
export default function Assign(props: any) {
  const {
    formField: { assetName, driverName, driverNumber, driverNumberPrefix },
    setFieldValue,
  } = props;
  const classes = useStyles();
  const userDetails = (new UserService()).getUserDetails();

  const [transporterData, setTransporterData] = useState([]);
  const [chipStyle, setChipStyle] = useState("Both");
  const [assert, SetAssert] = useState([]);
  const [filterAssets, setFinalAssert] = useState([]);
  const [assetNumber, setAssetNumber] = useState("");

  useEffect(() => {
    if(userDetails.roles[0] !== 'ClosedMarketplace'){
    const shipperService = new ShipperAPI();
    shipperService
      .getShipperID()
      //axios.get(`${tenantandUser}/tenants`)
      .then((res: any) => {
        let transporterOptions: any = [];
        res.map((val: any) => {
          if (val.roles[0]?.name === "Transporter") {
            let test = { key: "", value: "" };
            test.key = val.id;
            test.value = val.name;
            transporterOptions = [...transporterOptions, test];
          }
        });
        setTransporterData(transporterOptions);
      });

    axios.get(`${assetBaseURL}/assets?page=0&size=50`).then((res: any) => {
      SetAssert(res.data);
      setFinalAssert(res.data);
    });
  }
  else {
    getAssets(userDetails.tenantId)
    setFieldValue(props.formField.transporter.name, `${userDetails.firstName} ${userDetails.lastName}`);
  }
  }, []);

  const handleClick = (event: SelectChangeEvent) => {
    let item = event.target.value;
    let options: any = [];
    assert.map((val: any) => {
      if (item === "Both") {
        let test = { key: "", value: "" };
        test.key = val.id;
        test.value = val.number;
        options = [...options, test];
      } else if (item === val.assetType.name) {
        let test = { key: "", value: "" };
        test.key = val.id;
        test.value = val.number;
        options = [...options, test];
      }
    });
    setFinalAssert(options);
    setChipStyle(item);
  };
  const getTransporterId: any = (name: any) =>
    transporterData.filter((val: any) => val.value === name);

    const getAssets = (id: any) => {
      axios
      .get(
        assetBaseURL +
          `/assets?tenantId=${id}&page=0&size=200`)
      .then((res: any) => {
        let options: any = [];
        res.data?.map((val: any) => {
          if (chipStyle === "Both") {
            let test = { key: "", value: "" };
            test.key = val.id;
            test.value = val.number;
            options = [...options, test];
          } else if (chipStyle === val.assetType.name) {
            let test = { key: "", value: "" };
            test.key = val.id;
            test.value = val.number;
            options = [...options, test];
          }
        });
        SetAssert(res.data);
        setFinalAssert(options);
      });
    }

  const handleChange = (event: SelectChangeEvent) => {
    const id = getTransporterId(event.target.value as string)[0]?.key;
    getAssets(id)
    setFieldValue(props.formField.transporter.name, event.target.value);
  };
  const handleChangeAsset = (event: SelectChangeEvent) => {
    setAssetNumber(event.target.value as string);
    setFieldValue(props.formField.assetName.name, event.target.value);
    const getAssetId: any = (assetNumber: string) =>
      filterAssets.filter((val: any) => val.value === assetNumber);

    setFieldValue(
      props.formField.assetId.name,
      getAssetId(event.target.value)[0]?.key
    );
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement" style={{marginTop:"3px"}}>
          <Grid container spacing={3} style={{ marginLeft: "0.2rem" }}>
            <Grid item xs={12} >
            {userDetails.roles[0] !== 'ClosedMarketplace' && 
            // <Grid item xs={12} lg={6} >
            //     <FormControl fullWidth>
            //       <InputLabel id="demo-simple-select-label"  >
            //         Transporter
            //       </InputLabel>
            //       <Select
            //         style={{ width: "85%" }}
            //         labelId="demo-simple-select-label"
            //         data-testid="transporter"
            //         id="demo-simple-select"
            //         name="transporter"
            //         label="Transporter"
            //         onChange={handleChange}
            //       >
            //         {transporterData.map((option: any) => {
            //           return (
            //             <MenuItem key={option.value} value={option.value}>
            //               {option.value}
            //             </MenuItem>
            //           );
            //         })}
            //       </Select>
            //     </FormControl>
            //   </Grid>
            <Grid item xs={12} lg={6}>
            <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label">
                Transporter
                </InputLabel>
                <Select
                  style={{ width: "91%" }}
                  name="assetType"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  data-testid="transporter"
                  label="Transporter"
                  // disabled={props.values.transporter ? false : true}
                  onChange={handleChange}
                >
                  {transporterData.map((option: any) => {
                      return (
                        <MenuItem  style={{color:"#000"}}key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              </Grid>
            }
                <br />
                <p style={{color:"#000"}}>Asset Details</p>
              <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" >
                  Asset Details
                  </InputLabel>
                  <Select
                    style={{ width: "91%" }}
                    name="assetType"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid="assetType"
                    label="Asset Details"
                    // disabled={props.values.transporter ? false : true}
                    onChange={handleClick}
                  >
                     {[{value: 'Reefer Truck', title: 'Vehicles'}, {value: 'Cooler Box', title: 'Cooler Box'}, { value: 'Both', title: 'Both'}].map((option: any) => {
                      return (
                        <MenuItem key={option.value} style={{color:"#000"}} value={option.value}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                </Grid>
                
              <Grid item xs={12} lg={6}>
                {/* <p style={{ fontSize: "14px" }} className="cardTitle">
                  Asset Details
                </p>
                <Stack direction="row" spacing={1}>
                  <Chip
                    size="medium"
                    label="Reefer Trucks"
                    data-testid="ReeferTrucks"
                    onClick={() => handleClick("Reefer Truck")}
                    style={{width:"100px"}}
                    className={
                      chipStyle === "Reefer Truck"
                        ? classes.chipCSS
                        : classes.chip
                    }
                  />
                   &nbsp;&nbsp;
                  <Chip
                    size="medium"
                    label="Cooler Boxes"
                    data-testid="CoolerBoxes"
                    onClick={() => handleClick("Active Cooler Box")}
                    className={
                      chipStyle === "Active Cooler Box"
                        ? classes.chipCSS
                        : classes.chip
                    }
                    style={{width:"100px",marginTop:"-50px", marginLeft:"115px"}}
                  />
                   &nbsp;&nbsp;
                  <Chip
                    size="medium"
                    label="Both"
                    data-testid="both"
                    onClick={() => handleClick("Both")}
                    className={
                      chipStyle === "Both" ? classes.chipCSS : classes.chip
                    }
                    style={{width:"100px",marginTop:"-50px", marginLeft:"230px"}}
                  />
                  </Stack> */}
                {/* </div> */}
                <br />
               
                {/* <SelectField 
                        name={assetName.name}
                        label="Asset Details"
                        options={result}
                        style={{width:'80%'}}
                        fullWidth 
                        disabled={props.values.transporter ?false:true}
                        />  */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Asset Name
                  </InputLabel>
                  <Select
                    style={{ width: "91%" }}
                    name={assetName.name}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid="assetName"
                    label="Asset Name"
                    disabled={props.values.transporter ? false : true}
                    onChange={handleChangeAsset}
                  >
                    {filterAssets.map((option: any) => {
                      return (
                        <MenuItem key={option.value} style={{color:"#000"}}value={option.value}>
                          {option.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <br/>
            <br/>

            <Grid item xs={12} lg={6} style={{marginLeft:"7px"}}>
              <p style={{marginTop:"-10px",color:"#000"}}>Driver Details</p>
              <InputField
                name={driverName.name}
                style={{width:"94%"}}
                label={driverName.label}
                fullWidth
                disabled={props.values.transporter ? false : true}
              />
          
            </Grid>
            <Grid item xs={12}lg={6} style={{marginTop:"-54px",marginLeft:"50%"}}>
           
              <InputField
                name={driverNumber.name}
                label={driverNumber.label}
                type="number"
                style={{width:"94%"}}
                disabled={props.values.transporter ? false : true}
                InputProps={{
                  startAdornment: (
                    <SelectField
                    style={{width: "104%"}}
                        name={driverNumberPrefix.name}
                        defaultValue="+91"
                        options={[{key: "+91", value: "+91"}, {key: "+62", value: "+62"}]}
                      />
                  ),
                }}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                fullWidth
              />
            </Grid>
          
        </div>
      </div>
    </Fragment>
  );
}
