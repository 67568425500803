import { createStyles, InputBase, makeStyles, MenuItem, Paper, Select, TextField, withStyles, Theme } from "@material-ui/core";
import { useEffect, useState } from "react";
import "./style.css";
import Grid from "@material-ui/core/Grid";
import Shipmentstrend from "../Common/Charts/BarCharts/Shipmentstrend";
import ShipmentsOverview from "../Common/Charts/SunburstCharts/ShipmentsOverview";
import TripsVsAvgFreshnessScore from "../Common/Charts/GaugeChart/TripsVsAvgFreshnessScore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DashboardTopFiveShipper from "../Common/TopFive/TopFiveShipper";
import DashboardChartAPI from "../../../../services/ChartServices";
import ShipperHelper from "../../../../services/_helpers";
import coldchainLog from "../../../../services/consoleHelper";
import { marketBaseURL } from "../../../../services/_url";
import axios from "axios";
import { PropertyDescriptorParsingType } from "html2canvas/dist/types/css/IPropertyDescriptor";
import HubLocation from "./hubLocation";
import LightEvents from "../Common/Charts/BarCharts/LightEvents";
import { Autocomplete, Stack } from "@mui/material";
import OrderFrequency from "../Common/Charts/BarCharts/OrderFrequency";
import Slots from "../Common/TopFive/Slots";

import calendar from "../../../../assets/images/calendar.svg"
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {},
    },
    input: {
      borderRadius: 2,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "15rem",
   
  
  },
  placeHolder: {
    transform: "rotate(-15deg)",
    display: "block",
    paddingTop: "2rem",
  },
}));

export interface IDashboardShipmentsProps {
  timeDuration: string;
  data: any;
  pdfStatechange: any;
  startAndEnd: any;
  selectedClosedMarket:any;
}

export default function DashboardShipments(props: IDashboardShipmentsProps) {
  const flagvalue = props.data;
  const { timeDuration } = props;
 // const {selectedClosedMarket } =props;
  const classes = useStyles();

  const dateselect = new ShipperHelper();
  const getShipmentAPI = new DashboardChartAPI();

  //Top5Shipper
  const [top5RowData, settop5RowData] = useState([]);
  const [top5TotalRequest, settop5TotalRequest] = useState(0);
  const [noDataTop5, setNoDataTop5] = useState("");
  const [tenantData, setTenantData] = useState([]);

  //ShipmentsOverview
  const [shipmentOverview, setshipmentOverview]: any = useState({
    type: "sunburst",
    labels: [],
    parents: [],
    values: [],
    branchvalues: "total",
    textinfo: "none",
    marker: {
      line: {
        width: 0,
      },
      colors: [],
    },
  });
  const [legendNames, setLegendNames] = useState([]);
  const [noDataShipment, setNoDataShipment] = useState("");

  //Avg FreshnessScore
  const [tripcount, setTripcount] = useState(0);
  const [avgScore, setAvgScore] = useState("");

  //Shipment Trend
  const [shipmentTrendData, setShipmentTrendData] = useState([]);
  const [shipmentTrendDataXaxis, setShipmentTrendDataXaxis] = useState([]);
  const [noDataShipmentTrend, setNoDataShipmentTrend] = useState("");
  const [served, setServed] = useState([]);
  const [total, setTotal] = useState([]);
  const [dropped, setDropped] = useState([]);
  const [customDialog, setcustomDialog] = useState(false);
    const [startDate, setstartDate] = useState("");
    const [timePeriod, setTimePeriod] = useState("daily");
    const [endDate, setendDate] = useState("");
    const [errorText, seterrorText] = useState(false);
    const [validationStart, setValidationStart] = useState(false);
    const [validationEnd, setValidationEnd] = useState(false);
    const [startAndEnd, setStartAndEnd] = useState("");
  useEffect(() => {
    // console.log(props.selectedClosedMarket)
    let datesForShipment = "";
    if (timeDuration === 'custom') {
      datesForShipment = props.startAndEnd;
  
    } else {
      datesForShipment = dateselect.DashboardDate(timeDuration);
      // console.log(datesForShipment,"dataeforshipemnt")
    }
  //  let url=marketBaseURL+'/metrics/shipments/topn?'+ datesForShipment ;
  //   //Top5Shipper
  //   if(props.selectedClosedMarket!=='All')
  //   {
  //     url=marketBaseURL+'/metrics/shipments/topn/dates?dates='+datesForShipment+'&tenantId='+props.selectedClosedMarket;
  //   }
    let topFiveTotal: any = 0;
    let name:any;
    // axios
    //   .get(url)
    
    getShipmentAPI
    .getTop5Shippers(datesForShipment,props.selectedClosedMarket)
      .then((values: any) => {
        // console.log("cbdhbcjd");
        // console.log(values,"5shippervalues");
        values
         .map((data: any) => (topFiveTotal += data.value));
        // console.log(topFiveTotal);
       // console.log(values.name);
       
        if (topFiveTotal > 0) {
    
          setNoDataTop5("");
          // console.log(noDataTop5);
        } else {
          setNoDataTop5("No  Tenant Data To Display");
        }
      
        // console.log(top5RowData);
        settop5RowData(values);
        settop5TotalRequest(topFiveTotal);
        
     
      })
      .catch((err: any) => {
        console.error(err);
        setNoDataTop5("No Data To Display");
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });

    //ShipmentsOverview
    let status: any = ["Total Request"];
    let totalReq: any = 0;
    let count: any = [];
    let parent: any = [""];
    let color: any = [];
    let legendName: any = [];
    getShipmentAPI
      .getShipmentsOverview(datesForShipment,props.selectedClosedMarket)
      .then((data: any) => {
        count = [data.total];
        totalReq = [data.total];

        data.status.map(
          (value: any, index: any) => (
            (status = [...status, value.status]),
            (count = [...count, value.count]),
            (parent = [...parent, "Total Request"])
          )
        );
        if (status.includes("Completed")) {
          status = [...status, "FeedbackRecieved", "FeedbackNotRecieved"];
          parent = [...parent, "Completed", "Completed"];
          count = [...count, data.feedbackRecieved, data.feedbackNotRecieved];
        }
        status.forEach((element: any, index: number) => {
          if (element === "Total Request") {
            return (color = [...color, "#FFFFFF"]);
          } else if (element === "FeedbackRecieved") {
            //need it for later use
            // return (status.splice(index + 1, 0, 'FeedbackRecieved', 'FeedbackNotRecieved'),
            //   count.splice(index + 1, 0, 90, 4),
            //   parent.splice(index + 1, 0, "Completed", "Completed")
            return (
              (color = [...color, "#34B78F"]),
              (legendName = [...legendName, element])
            );
          } else if (element === "FeedbackNotRecieved") {
            return (
              (color = [...color, "#bfbfbf"]),
              (legendName = [...legendName, element])
            );
          } else if (element === "Completed") {
            return (
              (color = [...color, "#757672"]),
              (legendName = [...legendName, element])
            );
          } else if (element === "Accepted") {
            return (
              (color = [...color, "#FDA606"]),
              (legendName = [...legendName, element])
            );
          } else if (element === "Cancelled") {
            return (
              (color = [...color, "#FF6600"]),
              (legendName = [...legendName, element])
            );
          } else if (element === "PendingAcceptance") {
            return (
              (color = [...color, "#000000"]),
              (legendName = [...legendName, element])
            );
          } else if (element === "InTransit") {
            return (
              (color = [...color, "#F2531D"]),
              (legendName = [...legendName, element])
            );
          }
        });
        coldchainLog("sun", legendName, count);
        setshipmentOverview({
          ...shipmentOverview,
          labels: status,
          values: count,
          parents: parent,
          marker: { line: { width: 10 }, colors: color },
        });
        setLegendNames(legendName);
        if (totalReq === 0) {
          setNoDataShipment("No Data To Display");
        } else {
          setNoDataShipment("");
        }
      })
      .catch((err: any) => {
        setNoDataShipment("No Data To Display");
        // console.log(err);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });

    //Avg FreshnessScore
    let trip: number = 0;
    let score: string = "";
    getShipmentAPI
      .getTripsVsAvgFreshnessScore(datesForShipment,props.selectedClosedMarket)
      .then((data: any) => {
        trip = data.tripCount;
        score = data.freshnessIndexAvg;
        setTripcount(trip);
        setAvgScore(score);
      })
      .catch((err: any) => {
        setTripcount(0);
        setAvgScore("");
        // console.log(err);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
    //shipment Trends
   getShipmentAPI
      .getShipmentsTrend(datesForShipment,props.selectedClosedMarket)
      .then((res: any) => {
      
        setShipmentTrendData(res);
        // console.log(res);
        let dates = Object.keys(res);
        setShipmentTrendDataXaxis(dates);
        let servedy: any = [];
        let totaly: any = [];
        let droppedy: any = [];
        Object.keys(res).forEach(function (key) {
          servedy = [...servedy, res[key].served];
          totaly = [...totaly, res[key].total];
          droppedy = [...droppedy, res[key].dropped];
          coldchainLog("ss", res[key].dropped);
        });
        setServed(servedy);
        setTotal(totaly);
        setDropped(droppedy);
        coldchainLog("resdrop", droppedy);
      })
      .catch((err: any) => {
        setNoDataShipmentTrend("No Data To Display.");
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  }, [timeDuration, props.startAndEnd,props.selectedClosedMarket,marketBaseURL]);

  useEffect(() => {
    if (flagvalue === true) {
      props.pdfStatechange();
      pdfGenerator();
    }
  }, [flagvalue]);
  const handleChangeCustom = (data: any) => {

  }
    const handleTimePeriodChange = (event: any) => {
        if (event.target.value === "custom") {
          setcustomDialog(true);
        } else {
          setTimePeriod(event.target.value);
          setstartDate("");
          setendDate("");
          setStartAndEnd("");
        }
      };
  const pdfGenerator = () => {
    const DashboardTopFivePDF = document.getElementById("DashboardTopFivePDF");
    const ShipmentsOverviewPDF = document.getElementById(
      "ShipmentsOverviewPDF"
    );
    const ShipmentstrendPDF = document.getElementById("ShipmentstrendPDF");
    const TripsVsAvgFreshnessScorePDF = document.getElementById(
      "TripsVsAvgFreshnessScorePDF"
    );
    /*istanbul ignore next*/
    html2canvas(DashboardTopFivePDF).then((canvasDashboardTopFivePDF) => {
      html2canvas(ShipmentsOverviewPDF).then((canvasShipmentsOverviewPDF) => {
        html2canvas(ShipmentstrendPDF).then((canvasShipmentstrendPDF) => {
          html2canvas(TripsVsAvgFreshnessScorePDF).then(
            (canvasTripsVsAvgFreshnessScorePDF) => {
              // html2canvas(placeHolderPDF)
              //   .then((canvasplaceHolderPDF) => {
              const imgDashboardTopFive =
                canvasDashboardTopFivePDF.toDataURL("image/png");
              const doc = new jsPDF();
              doc.addImage(imgDashboardTopFive, "PNG", 5, 5, 60, 70);

              const imgShipmentsOverview =
                canvasShipmentsOverviewPDF.toDataURL("image/png");
              doc.addImage(imgShipmentsOverview, "PNG", 70, 5, 150, 70);

              const imgShipmentstrend =
                canvasShipmentstrendPDF.toDataURL("image/png");
              doc.addImage(imgShipmentstrend, "PNG", 5, 70, 200, 80);

              const imgTripsVsAvgFreshnessScore =
                canvasTripsVsAvgFreshnessScorePDF.toDataURL("image/png");
              doc.addImage(imgTripsVsAvgFreshnessScore, "PNG", 5, 150, 70, 70);

              // const imgsplaceHolder = canvasplaceHolderPDF.toDataURL("image/png")
              // doc.addImage(imgsplaceHolder, 'PNG', 70, 190, 150, 70)

              doc.save("DashboardShipments.pdf");
              // })
            }
          );
        });
      });
    });
  };
return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={4}>
          <Paper className={classes.paper + " p-0" } style={{boxShadow:"none",marginTop:"-10px"}}>
            <div id="DashboardTopFivePDF" style={{color:"#000",fontSize:"14px"}}>
              <DashboardTopFiveShipper
                top5RowData={top5RowData}
                top5TotalRequest={top5TotalRequest}
                noData={noDataTop5}
                selectedClosedMarket={props.selectedClosedMarket}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <Paper className={classes.paper + " p-0"} style={{boxShadow:"none",marginLeft:"10px",height:"25rem",marginTop:"-10px"}}>
            <div id="ShipmentsOverviewPDF" style={{color:"#000",fontSize:'14px'}}>
              <ShipmentsOverview
                shipmentOverview={shipmentOverview}
                legendNames={legendNames}
                noDataShipment={noDataShipment}
                selectedClosedMarket={props.selectedClosedMarket}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <Paper className={classes.paper + " p-0" } style={{boxShadow:"none",marginTop:"10px",marginLeft:"-10px",marginRight:"10px"}}>
            <div id="ShipmentstrendPDF" style={{color:"#000",fontSize:"14px"}}>
            <Shipmentstrend
                
                data={shipmentTrendData}
                noDataShipmentTrends={noDataShipmentTrend}
                xaxis={shipmentTrendDataXaxis}
                served={served}
                total={total}
                dropped={dropped}
               
              />
            </div>
          </Paper>
        </Grid>

       
       

        <Grid item xs={12} md={12} lg={4} style={{marginTop:"3.5px"}}>
          <Paper className={classes.paper + " p-0"} style={{boxShadow:"none",marginTop:"8px",height:"98%"}}>
            <div id="TripsVsAvgFreshnessScorePDF"  style={{color:"#000",fontSize:"14px"}}>
              
          
              <TripsVsAvgFreshnessScore
                tripcount={tripcount}
                avgScore={avgScore}
              />
               
            </div>
            
          </Paper>
        
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper className={classes.paper + " p-0"} style={{boxShadow:"none",marginTop:"33px"}}>
            <div id="TripsVsAvgFreshnessScorePDF"  style={{color:"#000",fontSize:"14px"}}>
              <HubLocation />
            </div>
          </Paper>
        </Grid>

       

        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper + " p-0"} style={{boxShadow:"none",marginRight:"2px",marginTop:"12px",height:"24.1rem"}}>
            <div id="LightEventsPDF" >
              <br />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <p style= {{
  textAlign: "left",
  marginLeft: "1rem",
  // paddingTop: '1rem',
  marginBottom: "1rem",
  fontFamily: "Roboto",
  fontWeight:"normal",
  fontSize: "14px",
  color: "#000000DE",
}}>Order Frequency</p>
                </Grid>
                <Grid item xs={7}>
                  <Stack spacing={0} direction="row" style={{ float: "right" }}>
                  <div style={{ border: "1px solid rgb(206, 212, 217)",borderRadius:"4px", backgroundColor: "white",
              width:"168px", marginTop:"-6px",
               paddingRight: "-10px",marginLeft:"33.5rem",position:"sticky"}}>
                <img src={calendar} style={{ width: "24px", marginLeft:"10px",marginTop:"-1px" }} />
                <Select
                 style={{marginTop:"3.5px",marginLeft:"6px"}}
                  inputProps={{ IconComponent:"null" }}
                  labelId="demo-customized-select-label"
                  data-testid="period"
                  id="demo-customized-select"
                  value="daily"
                  onChange={handleTimePeriodChange}
                  
                >
                  <MenuItem
                  style={{color:"#000"}}
                    onClick={() => handleChangeCustom('')}
                    
                    value={"daily"}>Last 24 Hours</MenuItem>
                  <MenuItem
                    onClick={() => handleChangeCustom('')}
                    style={{color:"#000"}}
                    value={"weekly"}>Last 7 Days</MenuItem>
                  <MenuItem
                    onClick={() => handleChangeCustom('')}
                    style={{color:"#000"}}
                    value={"monthly"}>Last 30 Days</MenuItem>
                  <MenuItem
                    onClick={() => handleChangeCustom('custom')}
                    style={{color:"#000"}}
                    value={"custom"}>Custom Date
                    </MenuItem>
                   
                </Select>
               
              </div>
                  </Stack>
                </Grid>
              </Grid>
              <OrderFrequency
           
           data={shipmentTrendData}
           noDataShipmentTrends={noDataShipmentTrend}
           xaxis={shipmentTrendDataXaxis}
           served={served}
           total={total}
           dropped={dropped}
          
         />
             
              
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={10} lg={6} >
          <Paper className={classes.paper + " p-0"} style={{boxShadow:"none",marginTop:"13px",marginLeft:"10px"}}>
            <div id="ShipmentsOverviewPDF"  style={{color:"#000",fontSize:"14px"}}>
            <Slots
                top5RowData={top5RowData}
                top5TotalRequest={top5TotalRequest}
                noData={noDataTop5}
                selectedClosedMarket={props.selectedClosedMarket}
              />
            </div>
          </Paper>
        </Grid> 
        {/* <div style={{ display: "none" }}>
          <Grid item xs={8}>
            <Paper className={classes.paper + " p-0"}>
              <div
                className={classes.placeHolder}
                style={{ maxWidth: "800px", height: "398px" }}
              >
                Placeholder Card
              </div>
            </Paper>
          </Grid>
        </div> */}
      </Grid>
    </div>
  );
}
