import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Grid,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import "./style.css";
import Shippers from "../Common/Charts/BarCharts/Shippers";
import Transporters from "../Common/Charts/BarCharts/Transporters";
import AssetsOverview from "../Common/Charts/SunburstCharts/AssetsOverview";
import ShippersTransporterRequest from "../Common/ShippersTranporterRequests/ShippersTransporterRequest";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DashboardChartAPI from "../../../../services/ChartServices";
import ShipperHelper from "../../../../services/_helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      minHeight: "15rem",
    },
  })
);

export interface IDashboardMarketPlaceProps {
  timeDuration: string;
  data: any;
  pdfStatechange: any;
  startAndEnd: any;
  selectedClosedMarket:any;
}

export default function DashboardMarketPlace(
  props: IDashboardMarketPlaceProps
) {
  const flagvalue = props.data;

  const { timeDuration } = props;
  const classes = useStyles();
  const getMarketplaceAPI = new DashboardChartAPI();
  const dateselect = new ShipperHelper();

  //Shippers
  const [shipperXaxis, setShipperXaxis] = useState([]);
  const [shipperActive, setShipperActive] = useState([]);
  const [shipperInActive, setShipperInActive] = useState([]);
  const [shipperOnBoard, setShipperOnBoard] = useState([]);
  const [noDataShip, setNoDataShip] = useState("");

  //Transporter
  const [transporterXaxis, setTransporterXaxis] = useState([]);
  const [transporterActive, setTransporterActive] = useState([]);
  const [transporterInActive, setTransporterInActive] = useState([]);
  const [transporterOnBoard, setTransporterOnBoard] = useState([]);
  const [noDataTrans, setNoDataTrans] = useState("");

  //Shipper and Transporter Requests
  const [totalCount, setTotalCount] = useState(0);
  const [shipperName, setShipperName] = useState([]);
  const [transporterName, setTransporterName] = useState([]);
  const [requestCount, setRequestCount] = useState([]);
  const [noDataShiTrans, setNoDataShiTrans] = useState("");

  //Asset Overview
  const [total, setTotal] = useState([]);
  const [legendName, setLegendName] = useState([]);
  const [activeCoolerColor, setActiveCoolerColor] = useState([]);
  const [reeferColor, setReeferColor] = useState([]);
  const [noDataAsset, setNoDataAsset] = useState("");

  useEffect(() => {
    let datesForTranporter = "";
    if (timeDuration === "custom") {
      datesForTranporter = props.startAndEnd;
    } else {
      datesForTranporter = dateselect.DashboardDate(timeDuration);
    }

    getMarketplaceAPI
      .getShippers(datesForTranporter)
      .then((res: any) => {
        setShipperXaxis(Object.keys(res));
        let active: any = [];
        let inActive: any = [];
        let onBoard: any = [];
        Object.keys(res).forEach(function (key) {
          active = [...active, res[key].activeCount];
          inActive = [...inActive, res[key].inActiveCount];
          onBoard = [...onBoard, res[key].onBoardedCount];
        });
        setShipperActive(active);
        setShipperInActive(inActive);
        setShipperOnBoard(onBoard);
      })
      .catch((err: any) => {
        setNoDataShip("No Data To Display");
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });

    getMarketplaceAPI
      .getTransporters(datesForTranporter)
      .then((res: any) => {
        setTransporterXaxis(Object.keys(res));
        let active: any = [];
        let inActive: any = [];
        let onBoard: any = [];
        Object.keys(res).forEach(function (key) {
          active = [...active, res[key].activeCount];
          inActive = [...inActive, res[key].inActiveCount];
          onBoard = [...onBoard, res[key].onBoardedCount];
        });
        setTransporterActive(active);
        setTransporterInActive(inActive);
        setTransporterOnBoard(onBoard);
      })
      .catch((err: any) => {
        setNoDataTrans("No Data To Display");
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });

    getMarketplaceAPI
      .getAssetOverview(datesForTranporter)
      .then((res: any) => {
        let activeCoolerBox =
          Number(res["Active Cooler Box"].assigned) +
          Number(res["Active Cooler Box"].idle) +
          Number(res["Active Cooler Box"].maintenance);
        let reefer =
          Number(res["Reefer Truck"].assigned) +
          Number(res["Reefer Truck"].idle) +
          Number(res["Reefer Truck"].maintenance);
        let final: any = [];
        final = [
          reefer + activeCoolerBox,
          activeCoolerBox,
          Number(res["Active Cooler Box"].assigned),
          Number(res["Active Cooler Box"].idle),
          Number(res["Active Cooler Box"].maintenance),
          reefer,
          Number(res["Reefer Truck"].assigned),
          Number(res["Reefer Truck"].idle),
          Number(res["Reefer Truck"].maintenance),
        ];
        setTotal(final);
        let activeColor = ["#EF60A3", "#FF88BA", "#ECB3CB"];
        let reeferColor = ["#0092BD", "#5BC2E7", "#96DDF7"];
        let legend = ["Assigned", "Idle", "Maintenance"];
        setLegendName(legend);
        setActiveCoolerColor(activeColor);
        setReeferColor(reeferColor);
        let datacheck = final.every(
          (val: any, i: any, arr: any[]) => val === 0
        );
        if (datacheck) {
          setNoDataAsset("No Data To Display");
        } else {
          setNoDataAsset("");
        }
      })
      .catch((err: any) => {
        setNoDataAsset("No Data To Display");
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
    getMarketplaceAPI
      .getShipperAndTransport(datesForTranporter)
      .then((res: any) => {
        let reqCount: any = [];
        let shipper: any = [];
        let transporter: any = [];
        let count = 0;
        res.map((data: any, index: any) => {
          reqCount = [...reqCount, data["requestCount"]];
          shipper = [...shipper, data["shipperName"]];
          transporter = [...transporter, data["transporterName"]];
        });
        reqCount.map((item: any) => {
          count = count + item;
        });
        if (count === 0) {
          setNoDataShiTrans("No Data To Display");
        } else {
          setNoDataShiTrans("");
        }
        setRequestCount(reqCount);
        setShipperName(shipper);
        setTransporterName(transporter);
        setTotalCount(count);
      })
      .catch((err: any) => {
        setNoDataShiTrans("No Data To Display");
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  }, [timeDuration, props.startAndEnd]);

  useEffect(() => {
    if (flagvalue == true) {
      props.pdfStatechange();
      pdfGenerator();
    }
  }, [flagvalue]);

  const pdfGenerator = () => {
    const ShippersPDF = document.getElementById("ShippersPDF");
    const TransportersPDF = document.getElementById("TransportersPDF");
    const ShippersTransporterRequestPDF = document.getElementById(
      "ShippersTransporterRequestPDF"
    );
    const AssetsOverviewPDF = document.getElementById("AssetsOverviewPDF");
    /*istanbul ignore next*/
    html2canvas(ShippersPDF).then((canvasShippersPDF) => {
      html2canvas(TransportersPDF).then((canvasTransportersPDF) => {
        html2canvas(ShippersTransporterRequestPDF).then(
          (canvasShippersTransporterRequestPDF) => {
            html2canvas(AssetsOverviewPDF).then((canvasAssetsOverviewPDF) => {
              const imgShippers = canvasShippersPDF.toDataURL("image/png");
              const doc = new jsPDF();
              doc.addImage(imgShippers, "PNG", 5, 5, 100, 90);

              const imgTransporters =
                canvasTransportersPDF.toDataURL("image/png");
              doc.addImage(imgTransporters, "PNG", 110, 5, 100, 90);

              const imgShippersTransporterRequest =
                canvasShippersTransporterRequestPDF.toDataURL("image/png");
              doc.addImage(
                imgShippersTransporterRequest,
                "PNG",
                5,
                100,
                100,
                90
              );

              const imgAssetsOverview =
                canvasAssetsOverviewPDF.toDataURL("image/png");
              doc.addImage(imgAssetsOverview, "PNG", 110, 100, 100, 90);

              doc.save("DashboardMarketPlace.pdf");
            });
          }
        );
      });
    });
  };
  

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={6} >
          <Paper style={{marginTop:"-25px",marginLeft:"-2px",height:"28rem",boxShadow:"none",marginRight:"-6px"}} className={classes.paper + " p-0"}>
            <div id="ShippersPDF" style={{color:"#000",fontSize:"14px"}}>
              <Shippers
                xaxis={shipperXaxis}
                active={shipperActive}
                inactive={shipperInActive}
                onBroad={shipperOnBoard}
                noData={noDataShip}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper style={{marginTop:"-25px",marginLeft:"-2px",height:"28rem",boxShadow:"none",marginRight:"2px"}} className={classes.paper + " p-0"}>
            <div id="TransportersPDF" style={{color:"#000",fontSize:"14px"}}>
              <Transporters
                xaxis={transporterXaxis}
                active={transporterActive}
                inactive={transporterInActive}
                onBroad={transporterOnBoard}
                noData={noDataTrans}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6} >
          <Paper style={{marginTop:"-11px",height:"28.7rem",boxShadow:"none",marginRight:"-6px",marginLeft:"-3px"}} className={classes.paper + " p-0"}>
            <div id="ShippersTransporterRequestPDF"  style={{color:"#000",fontSize:"14px"}}>
              <ShippersTransporterRequest
                totalRequestCount={totalCount}
                shipperList={shipperName}
                transporterList={transporterName}
                noData={noDataShiTrans}
                requestCountList={requestCount}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper  style={{marginTop:"-11px",marginLeft:"-2px",height:"28.65 rem",boxShadow:"none"}} className={classes.paper + " p-0"}>
            <div id="AssetsOverviewPDF" style={{color:"#000",fontSize:"14px"}}>
              <AssetsOverview
                finalAsset={total}
                legend={legendName}
                activeColor={activeCoolerColor}
                reeferColor={reeferColor}
                noData={noDataAsset}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
