
import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Card } from 'react-bootstrap';
import { Button, CardContent, createStyles, makeStyles, Theme } from '@material-ui/core';
import queryString from 'query-string';
import LockIcon from '@material-ui/icons/Lock';
import './style.css';
import login_bg from "../../../assets/images/loginblue.png";
import InfoIcon from '@material-ui/icons/Info';
import { useLocation } from 'react-router-dom';
import UserService from '../../../services/userService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { setUpdateToken } from '../../../services/_helpers';

interface State {
  newPassword: string;
  conformPassword: string;
  showPassword: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      height: "100%",
      top: 0,
      marginTop: '-5rem',
      marginLeft: '-100px',
      objectPosition: "bottom"
    },
    card: {
      width: "35%",
      marginLeft: 'auto',
      marginRight: 'auto',
      position: "absolute",
      top: "50%",
      left: " 50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down('sm')]: {
        width: "80%"
      }
    },
    title: {
      textAlign: 'center', color: '#1A0DAB', letterSpacing: '-0.48px', fontSize: ' 30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: ' 30px'
      }
    },
    cardSpacing: {
      marginLeft: '3%',
      marginRight: '3%'
    },
    forgotPassword: {
      float: 'right',
      color: '#1A0DAB',
      letterSpacing: "1.25px",
      fontSize: '14px'
    },
    loginBtn: {
      color: '#1A0DAB',
      border: "2px solid #1A0DAB",
      width: '100%',
      '&:hover': {
        color: '#1A0DAB',
        border: "2px solid #1A0DAB",
      }
    },
    email: {
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#1A0DAB",
          borderWidth: "2px"
        }
      },
      '& .MuiFormControl-root': {
        "&.Mui-focused fieldset": {
          borderColor: "#1A0DAB",
          borderWidth: "2px"
        }
      }
    },
    img: {
      backgroundSize: "contain",
      border: "2px solid #e9385a",
    }
  })
);

export default function UpdatePassword(props: any) {
  const { search } = useLocation();
  const history = useHistory();
  var path = queryString.parse(search);
  const [values, setValues] = React.useState<State>({
    newPassword: '',
    conformPassword: '',
    showPassword: false
  });
  const [isValidCPass, setIsValidCPass] = React.useState(false);
  const [cPassMsg, setCPassMsg] = React.useState("");
  const [isValidPwd, setIsValidPwd] = React.useState(false);
  const [pswMsg, setPswMsg] = React.useState("");
  const [invalidMsg, setInvalidMsg] = React.useState("");
  const classes = useStyles();
  const userApi = new UserService();

  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#])(?=.{8,})");




  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidMsg("");
    setValues({ ...values, [prop]: event.target.value });
    validatePassword(event.target.value);
  };

  const handleChangeConformPassword = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidMsg("");
    setValues({ ...values, [prop]: event.target.value });
    comparePassword(event.target.value)
  };

  const validatePassword = (password: string) => {
    if (!password) {
      setIsValidPwd(false);
      setPswMsg("Please enter password")
    } else {
      setIsValidPwd(strongRegex.test(password))
      if (strongRegex.test(password)) {
        setPswMsg("");
      } else {
        setPswMsg("Password not Valid format");

      }
    }
  }

  const comparePassword = (cPass: any) => {
    if (cPass === values.newPassword) {
      setIsValidCPass(true)
      setCPassMsg("")
    } else {
      setIsValidCPass(false)
      setCPassMsg("Password not matching")
    }


  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const success = (msg: any) => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 " >
        <span className="p-2 bd-highlight align-self-center " style={{ backgroundColor: '#159A40', padding: 0, height: '50px', width: '54px' }}><InfoIcon style={{ color: '#fff' }} /></span>
        <div className="p-1 bd-highlight align-self-center"> <span>{msg}.</span></div>
      </div>, {
      position: "bottom-right",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false
    });
  }
  const warning = (msg: any) => {
    toast.warning(
      <div className="d-flex flex-row bd-highlight mb-1 " >
        <span className="p-2 bd-highlight align-self-center " style={{ backgroundColor: '#FF4F14', padding: 0, height: '50px', width: '54px' }}><InfoIcon style={{ color: '#fff' }} /></span>
        <div className="p-1 bd-highlight align-self-center"> <span>{msg}.</span></div>
      </div>, {
      position: "bottom-right",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false
    });
  }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isValidCPass && isValidPwd) 
    {                                                                                                                                                                                                                                                                                                     

      const headers = {
        'Authorization': `Bearer ${path.token}`,
        'X-Frame-Options':'DENY'
      }
      var data = {
        "email": path.email,
        "password": values.newPassword
      }

      setUpdateToken(path.token);
      userApi.update(path.token)
      userApi.updatePassword(headers, data).then((res: any) => {
        if (res.response === "Password updated successfully") {
          success(res.response)
          history.push('/');
        } else {
          warning(res.response)
        }

      })

    }
  }


  return (
    <div className={classes.root}>
      <img src={login_bg} style={{ width: 'calc(100% + 100px)', maxWidth: "calc(100% + 100px)", backgroundSize: "cover" }} height="747" />
      <div className={classes.card}>
        <Card>
          <CardContent>
            <p className={classes.title}>Update Password</p>
            <div className={classes.cardSpacing}>
              <form onSubmit={handleSubmit} action="">
                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.email}>
                  <InputLabel htmlFor="outlined-adornment-password" >New Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.newPassword}
                    onChange={handleChange('newPassword')}
                    data-testid="newpassword"
                    startAdornment={
                      <InputAdornment position="start">
                        <LockIcon style={{ color: '#828282' }} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          data-testid="pwdToggle"
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                </FormControl>
                <p className='errorMsg' data-testid="pwdMsg">{pswMsg}</p>
                <p className='errorMsg'>{invalidMsg}</p>
                <br /><br />
                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.email}>
                  <InputLabel htmlFor="outlined-adornment-password">Conform Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.conformPassword}
                    onChange={handleChangeConformPassword('conformPassword')}
                    data-testid="conformPassword"
                    startAdornment={
                      <InputAdornment position="start">
                        <LockIcon style={{ color: '#828282' }} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          data-testid="pwdToggle"
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Conform Password"
                  />
                </FormControl>
                <p className='errorMsg' data-testid="cPassMsg">{cPassMsg}</p>
                <p className='errorMsg'>{invalidMsg}</p>


                <br /><br /><br /><br /><br /><br />

                <Button variant="outlined" className={classes.loginBtn} data-testid="updatePasswordBtn" type="submit"
                  disabled={!isValidCPass}>UPDATE PASSWORD</Button>
              </form>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}


