import { at } from "lodash";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

export default function InputField(props: any) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props !== undefined ? props : {});
  const { value } = field;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  }

  return (
    <div>
      <TextField
        // type="text"
     
        value={value}
        error={meta.touched && meta.error && true}
        helperText={_renderHelperText()}
        data-testid="commonTextField"
        {...field}
        {...rest}
        // style={{ width: "600px"}}
        variant="outlined"
      />
    </div>
  );
}
