import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MarketPlaceAPI from '../../../../services/marketPlaceService';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { setCurrentUser } from '../../../../redux/user/user.action';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../../../../redux/user/user.selector';
import { createStructuredSelector } from 'reselect';
import { Box } from '@mui/material';
import { ResponsiveDrawer } from '../../common/Drawer';
// import { Skeleton } from "@material-ui/lab";
// import viewBy from "../../../../assets/images/viewBy.svg";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

type Order = "ASC" | "DESC";

const useStyles = makeStyles({
    table: {
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "100%",
        "@media (max-width:700px)": {
            paddingLeft: "0px",
            paddingRight: "0px",
            width: "100%",
        },
    },
    tableCell: {
        "@media (max-width:700px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
        },
    },
});

export  function ViewUploadPage(props: any) {
    const [searchReqId, setSearchReqId] = useState<string>("");
    let {   currentUser, setCurrentUser } = props;
    const [open, setOpen] = useState<number>()
    
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [fileStatus, setFileStatus] = useState([]);
    const marketApi = new MarketPlaceAPI();
    //const makeArray = [];

    useEffect(() => {
        getUploadedDocs();
        //getUploadedDocsSummary(1);

    },[]);

    const getUploadedDocs = async () => {
       let data = await marketApi.getUploadedDocs();
    //    console.log(data,"datagetupload")
       //console.log(data);
       let makeArray: string[] = [];
       data.forEach(async(element:any, index:any)=>{
        //   console.log(element);
          let stts = await getUploadedDocsSummary(element.fileId);
        //   console.log(stts,"datasettupload")
          //console.log(stts);
          element.records = stts.records ? stts.records : [];
          //console.log(element);
          makeArray.push(element);
        //   console.log(makeArray);
          if(data.length - 1 === index)
            setFiles(makeArray);
       })
       
    }
    files.sort(function(a,b){
        return new Date(b.uploadDateLocal).valueOf() - new Date(a.uploadDateLocal).valueOf();
      });

    const getUploadedDocsSummary = async (id: any) => {
      let data = await marketApi.getUploadedDocsSummary(id);
      return data;
      //console.log(data);
    }

    const Headingstyle1: any = {
        textAlign: 'left',
        marginLeft: '1.2rem',
        marginBottom: '2rem',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '1rem',
        color: "#000000DE"

    }
    const Headingstyle: any = {
        textAlign: 'left',
        marginLeft: '1rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '1.5rem',
        color: "#000000DE"

    }
    const TableHeadLeftStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "0.7rem",
        fontWeight: 500,
        color: "#000000DE"
    }
    const TableHeadRightStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "0.7rem",
        fontWeight: 500,
        color: "#000000DE",
        textAlign: "right"
    }
    const TableRowLeftStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "1rem",
        fontWeight: 400,
        color: "#000000DE"
    }
    const TableRowRightStyle: any = {
        fontFamily: 'Roboto',
        fontSize: "1rem",
        fontWeight: 400,
        color: "#000000DE",
        textAlign: "right"
    }

    const tableRowCells: any = {
        border: '1px solid #DCDCDC',
        textAlign: 'center'
    }

    const tableRow1: any = {
        border: '1px solid #DCDCDC',
        width: '80rem',
        height : "100px",
        fontSize: '12px',
     
        marginRight: '-75rem',
        marginTop: '1rem',
        textAlign: 'center',
       
    }
    const Headers: any = {
        display: "flex",
        alignItems: "center",
        justifyContent:"normal",
        // marginLeft: "-8rem",
        border:"1px solid white",
    }


    return (
        // <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:'-10px' }}>
        // <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
        // <Box component={"main"} sx={{flexGrow:1,p:3}}>
        // <div style={{ overflow: 'auto', minHeight: "400px" }}>
        //     <div style={Headingstyle}>Trip Management
        //     <Link to={{ pathname: '/denso'}} style={{textDecoration: 'none'}} >
        //     <Button  variant="outlined" style={{marginLeft:"90%",marginTop:"-30px",width:"90px",height:"40px",backgroundColor:"rgb(0, 122, 166)"
        //     ,textTransform:"capitalize"}}
        //     ><span style={{color:"white",fontSize:"15px"}}>Back</span></Button>
        //     </Link>
        //     </div>
        //     <div style={Headingstyle1}>File Upload Status</div>

        //     <div style={{ marginTop: '2.5rem' }}>
        //         <Table aria-label="collapsible table" className={classes.table}>
        //             <TableHead >
        //                 <TableRow style={{ width: '100%' }}>
                                
        //                         <TableCell >
        //                         Status</TableCell>
        //                             <TableCell  >
        //                             File Nam</TableCell>
        //                             <TableCell  >
        //                             Upload date
        //                              </TableCell>
                                 
                        
        //                 </TableRow>
        //             </TableHead>
        //             <TableBody style={{ fontSize: "25px" }}>
        //                 {files.map((file, index)=>
        //                 <TableRow key={index} style={{ width: '10%'}}>
        //                     <>
        //                         <div style={{width:"108%"}}>
        //                             <TableCell >
        //                                 <IconButton aria-label="expand row" size="small"
        //                                     data-testid="collapseToggle" onClick={() => setOpen(open === index ? -1 : index)} >
        //                                     {index === open? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        //                                 </IconButton>
        //                             </TableCell>
        //                             <TableCell  style={{marginTop:"-30rem",display:"inline-flex"}}>
        //                            {file.fileStatus}</TableCell>
        //                             <TableCell  style={{marginLeft:"20rem",display:"inline-flex",marginTop:"-20rem"}}>
        //                             {file.origFileName}</TableCell>
        //                             <TableCell   style={{marginTop:"-40rem"}} >
        //                <span style={{marginLeft:"50rem",marginTop:"-20rem"}}>    {moment(file.uploadDateLocal).format("DD-MM-YYYY HH:mm:ss")}</span>     
        //                             </TableCell>
        //                             <TableCell   style={{marginTop:"-40rem"}}>
        //                                 <Button onClick={() => setOpen(index)} style={{ color:"#585858",marginRight:"-20px",width:"100px",textTransform:"capitalize" }}>View Details</Button></TableCell>
        //                         </div>
        //                         {index === open ? <div >

        //                             <table id="newTable" style={tableRow1}>
        //                                 <thead>
        //                                     <tr >
        //                                         <th style={{ border: '1px solid #DCDCDC', width: '10%' }}>Row</th>
        //                                         <th style={{ border: '1px solid #DCDCDC', width: '15%' }}>Status</th>
        //                                         <th style={{ border: '1px solid #DCDCDC', width: '25%' }}>Failure reason</th>
        //                                         <th style={{ border: '1px solid #DCDCDC', width: '25%' }}>Request No</th>
        //                                         <th style={{ border: '1px solid #DCDCDC', width: '25%' }}>Remarks</th>
        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                    <>

        //                                     {file.records.map((record:any)=>
                                              
        //                                            <tr>
        //                                                 <td style={tableRowCells}>{record.rowId}</td>
        //                                                 <td style={tableRowCells}>{record.status}</td>
        //                                                 <td style={tableRowCells}>{record.status === 'failure' ? record.error : ""}</td>
        //                                                 <td style={tableRowCells}>{record.status === 'success' ? record.shippingReqNum : ""}</td>
        //                                                 <td style={tableRowCells}></td>
        //                                             </tr>
                                                
                                              
        //                                     )}
        //                                     {file.fileStatus === "processing" ? <tr>
        //                                                 <td style={tableRowCells} colSpan={4}>No record found.</td></tr>: ""}
        //                                     </>
        //                                     {/* <tr >
        //                                         <td style={tableRowCells}>2</td>
        //                                         <td style={tableRowCells}>Failure</td>
        //                                         <td style={tableRowCells}>Amount - 500 invalid</td>
        //                                         <td style={tableRowCells}></td>
        //                                     </tr>
        //                                     <tr >
        //                                         <td style={tableRowCells}>3</td>
        //                                         <td style={tableRowCells}>Success</td>
        //                                         <td style={tableRowCells}>Date 20 nov invalid</td>
        //                                         <td style={tableRowCells}></td>
        //                                     </tr> */}
        //                                 </tbody>
        //                             </table>


        //                         </div> : null}
        //                     </>
        //                 </TableRow>
        //                 )}
        //             </TableBody>

        //         </Table>
        //     </div>
        <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:'-10px' }}>
     <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
         <Box component={"main"} sx={{flexGrow:1,p:3}}>
         <div style={Headingstyle}>Trip Management
           <Link to={{ pathname: '/denso'}} style={{textDecoration: 'none'}} >
             <Button  variant="outlined" style={{marginLeft:"90%",marginTop:"-30px",width:"90px",height:"40px",backgroundColor:"rgb(0, 122, 166)"
            ,textTransform:"capitalize"}}
            ><span style={{color:"white",fontSize:"15px"}}>Back</span></Button>
            </Link>
            </div>
            <div style={Headingstyle1}>File Upload Status</div>
             <TableContainer style={{ boxShadow: "none" }}>
                <Table aria-label="collapsible table" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontSize: "12px", width:'20px' }} align="left" ></TableCell>
                            <TableCell style={{ fontSize: "12px" }} align="left" >Status</TableCell>
                            <TableCell style={{ fontSize: "12px" }} align="left">
                                File Name
                            </TableCell>
                            <TableCell style={{ fontSize: "12px" }} align="left" >Upload date</TableCell>
                            <TableCell style={{ fontSize: "12px" }} align="left" ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ fontSize: "20px" }}>
                    {files.map((file, index)=>
                                <>
                                <TableRow>
                                     <TableCell>
                                     <IconButton aria-label="expand row" size="small"
                                            data-testid="collapseToggle" onClick={() => setOpen(open === index ? -1 : index)} >
                                            {index === open? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                        
                                </TableCell>
                                    <TableCell style={{ fontSize: "12px" }} align="left">{file.fileStatus}</TableCell>
                                    <TableCell style={{ fontSize: "12px" }} align="left">   {file.origFileName}</TableCell>
                                    <TableCell style={{ fontSize: "12px" }} align="left"> {moment(file.uploadDateLocal).format("DD-MM-YYYY HH:mm:ss")}</TableCell>
                                    <TableCell style={{ fontSize: "12px" }} align="left">
                                    <Button onClick={() => setOpen(index)} style={{ color:"#585858",marginRight:"-20px",width:"100px",textTransform:"capitalize" }}>View Details</Button>
                                    </TableCell>
                                </TableRow>
                                {index === open ? <div >
                                     <table id="newTable" style={tableRow1} >
                                     <thead>
                                        
                                         <tr >
                                             <th style={{border:'1px solid #DCDCDC', width:'10%'}}>Row</th>
                                             <th style={{border:'1px solid #DCDCDC', width:'25%'}}>Status</th>
                                             <th style={{border:'1px solid #DCDCDC', width:'35%'}}>Failure reason</th>
                                             <th style={{ border: '1px solid #DCDCDC', width: '25%' }}>Request No</th>
                                             <th style={{border:'1px solid #DCDCDC', width:'30%'}}>Remarks</th>       
                                         </tr>
                                     </thead>
                                     <tbody>
                                        <>
                                     {file.records.map((record:any)=>
                                         <tr>
                                                         <td style={tableRowCells}>{record.rowId}</td>
                                                         <td style={tableRowCells}>{record.status}</td>
                                                        <td style={tableRowCells}>{record.status === 'failure' ? record.error : ""}</td>
                                                        <td style={tableRowCells}>{record.status === 'success' ? record.shippingReqNum : ""}</td>
                                                         <td style={tableRowCells}></td>
                                           
                                            
                                         </tr>
                                     )}
                                         {file.fileStatus === "processing" ? <tr>
                                                        <td style={tableRowCells} colSpan={4}>No record found.</td></tr>: ""}
                                         </>
                                     </tbody>
                                 </table>
                                 </div> : null}
                                </>
                            )

                        }
                    </TableBody>
                </Table>
                    </TableContainer>

     
         </Box>
            </Box>
    );
}
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser
  });
  
  const matchDispatchToProps = (dispatch: any) => ({
    setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
  });
  
  export default connect(mapStateToProps, matchDispatchToProps)(ViewUploadPage);