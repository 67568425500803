import React, { Fragment,useState } from "react";
import { Grid,Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { InputField } from "../../../common/FormFields";




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export default function HardwareDetails(props: any) {
  // console.log(props);
  const [hardwareState,setHardwareState] = useState({tagId:props.location.tagId,gatewayId:props.location.gatewayId,description:props.location.description});

  const classes = useStyles();

  const changeHandler = (e:any) => {
    const {name, value} = e.target;
    setHardwareState(prevState => ({
      ...prevState, [name]:value
    }))
    props.formData(name, value);
  }
  
  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement">
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p style={{ fontSize: "14px" }} className="cardTitle">
                    Hardware Information
                  </p>
                  <br />
                  {/* <InputField
                    name="HardwarKitName"
                    label="Hardware Kit Name"
                    fullWidth
                    onChange={changeHandler}
                  // disabled={props.values.assetData ? false : true}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    name="gatewayId"
                    label="gatewayId"
                    fullWidth
                    onChange={changeHandler}
                    value={hardwareState.gatewayId}
                  // disabled={props.values.assetData ? false : true}
                  />
                </Grid>
              </Grid></Grid>
            <Grid item xs={12} lg={6} md={6} style={{ marginTop: "6.3rem" }}>
              <Grid item xs={12}>
                <InputField
                  name="tagId"
                  label="tagId"
                  fullWidth
                  onChange={changeHandler}
                  value={hardwareState.tagId}
                // disabled={props.values.assetData ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Button className="btn" variant="outlined" style={{marginTop:"3rem",backgroundColor:"#0078A5", color:"#fff",borderRadius:"5px"}}>Add New Field</Button> */}
        </div>
      </div>
    </Fragment>
  );
}