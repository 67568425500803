import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Box,
  Typography,
  Button,
  InputBase,
  withStyles,
  Tab as Taab,
  Tabs as Taabs,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from "react-router-dom";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../AssetManagement/style.css";
import calendar from "../../../../../assets/images/calendar.svg";
import viewBy from "../../../assets/images/viewBy.svg";
import Download from "../../../../../assets/images/Download.svg";
import { Link } from "react-router-dom";
import UserService from "../../../../../services/userService";
import TwoTrucksOverview from "./twoTrucksOverview";
import ThreeTrucksOverview from "./threeTrucksOverview";
import AssetCookerBox from "../../CoolerBox";
import EVOverview from "./evOverview";
import ShareIcon from '@mui/icons-material/Share';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "null",
      fontSize: 16,
      // width: "150px",
      textAlign: "right",

      // transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        // borderColor: "#80bdff",
        // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
      boxShadow: "none",
    },
    pageHeader: {
      // fontSize: "1.5rem",
      // marginBottom: "2rem",
      font: "normal normal normal 17px/18px Roboto Medium",
      color: "#000000",
      opacity: "1",
    },
    pageTopBar: {
      backgroundColor: "#F4F6F8",
    },
    tabsWrapper: {
      marginTop: "10px",
      marginBottom: "1.5rem",
      position: "relative",
      "@media (max-width:500px)": {
        marginTop: "3rem",
      },
    },
    tabs: {},
    tab: {
      "&.Mui-selected": {
        outline: "none",
      },
      outline: "none",
    },
    tabPanel: {
      backgroundColor: "#fafafa",
    },
    addNewAsset: {
      // width: "55%",
      float: "right",
      // marginRight: "1rem",
    },
    topIconsWrapper: {
      marginRight: "0.75rem",
      position: "absolute",
      top: 0,
      right: 0,
    },
    tabsLeft: {
      width: "13rem",
      "& .MuiTabs-flexContainer": {
        backgroundColor: "#E5E5E5",
        borderRadius: "40px",
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "0.6rem",
        border: "5px solid #E5E5E5",
        color: "#E5E5E5",
      }
    },
    tabTruck: {
      "&.Mui-selected": {
        outline: "none",
        color: "#0078A5",
        backgroundColor: "#ffffff",
        borderRadius: "40px",

        "@media only screen and (max-width:750px) and (min-width:1030px)": {
          width: "50%",
          fontSize: "18px",
        }
      },
      outline: "none",
      color: "#737373",
      borderRadius: "40px",
      width: "50%",
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: "#000",
      minHeight: "7rem",
    },
    topIconWrapper: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "10px",
      color: "#5A5A5A",
      marginLeft: "20px",
      width: "40px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
    topIconWrapper1: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "7.5rem",
      color: "#5A5A5A",
      width: "40px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
  })
);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export interface IOverviewProps { }

export default function OverviewComponent(props: IOverviewProps) {
  const classes = useStyles();
  const params = useParams();
  // console.log("params--> " + params);
  const [value, setValue] = useState(0);
  const [period, setPeriod] = React.useState("daily");
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [fromAndTo, setfromAndTo] = useState("");
  const [endNotGreater, setendNotGreater] = useState(false);
  const [loadingCircle, setloadingCircle] = useState(false);
  const [viewByValue, setViewByValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState('All')
  const [selectedOption1, setSelectedOption1] = useState('All')
  const [viewByOptions, setViewByOptions] = useState([]);
  const [topBarValue, setTopBarValue] = useState(0);

  const [dDialog, setDDialog] = useState(false);
  const userService = new UserService();
  const userDetails = userService.getUserDetails();
  const filterNames = ['test', 'test1'];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await userService.getTenants();
        setViewByOptions(result)
        // setSelectedOption(result[2].id);
        // console.log(result,"getTenants")
      } catch (error) {
        console.log(error);
      }
    })()
  }, []);

  const onChange = (event: SelectChangeEvent<typeof viewByValue>) => {
    const {
      target: { value },
    } = event;
    setViewByValue(
      typeof value === 'string' ? value.split(',') : value,
    );

  };

  const handleDropdownChange = (
    event: SelectChangeEvent<typeof period>
  ) => {
    if (event.target.value === "custom") {
      setcustomDialog(true);
      setPeriod(event.target.value as string);
      setstartDate("");
      setendDate("");
      setfromAndTo("");
    } else {
      setPeriod(event.target.value as string);
      setstartDate("");
      setendDate("");
      setfromAndTo("");
    }
  };

  const handleDates = (e: any) => {
    let fromAndToDate =
      "from=" + startDate + "T00:00:00Z&to=" + endDate + "T23:59:59Z";
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    seterrorText(false);
    setValidationStart(false);
    setValidationEnd(false);
    setendNotGreater(false);
    if (startDate === "") {
      setValidationStart(true);
    } else if (endDate === "") {
      setValidationEnd(true);
    } else if (startDate > endDate) {
      seterrorText(true);
    } else if (endDate > todaysdate) {
      setendNotGreater(true);
    } else {
      setfromAndTo(fromAndToDate);
      setPeriod("custom");
      setcustomDialog(false);
    }
  };
  const handleCancel = () => {
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    if (startDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (startDate > endDate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate > todaysdate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    }
    setcustomDialog(false);
  };

  const [pdfFlag, setPdfFlag] = useState(false);
  const pdfGen = () => {
    setPdfFlag(!pdfFlag);
  };

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleChangeCustom = (event: string) => {
    if (event === "custom") {
      setcustomDialog(true);
    }
  };

  const showDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (dDialog) {
      setDDialog(false);
    } else {
      setDDialog(true);
      setTimeout(() => {
        let el = document.getElementById("popupDiv");
        if (!el) {
          return showDropdown(e)
        }
        el.style.position = "absolute";
        el.style.left = (e.pageX - 100) + 'px';
        el.style.top = (e.pageY + 25) + 'px';
      }, 0);
    }
  }

  // const handleChangeView = (event: any, name:string) => {
  //   if(name==="LithPwr"){
  //     setSelectedOption(event.target.value);
  //   }
  // }
  const handleChangeView = (event: any, id: string) => {
    // console.log(id, event.target.value);
    setSelectedOption(id);
    setSelectedOption1(event.target.value);
  }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {

      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDDialog(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleTopBarChange = (event: React.ChangeEvent<{}>,
    newValue: number) => {
    setTopBarValue(newValue);
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageTopBar}>

        <div style={{ fontSize: "20px", fontWeight: 500 }}>Overview</div>
        <div style={{ marginLeft: '2rem' }}><span style={{ width: "20rem" }}><Paper style={{ width: "20rem" }}>
          <Taabs
            textColor="inherit"
            className={classes.tabsLeft}
            value={topBarValue}
            onChange={handleTopBarChange}
          >
            <Taab
              className={classes.tabTruck}
              label="Asset"
            />
            <Taab
              className={classes.tabTruck}
              label="Fleet"
            />
          </Taabs>
        </Paper></span></div>
        <div style={{ height: "4rem", paddingTop: "14px", fontSize: "20px", fontWeight: 500 }}>
          <div className={classes.addNewAsset}>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {userDetails.roles.includes('ADMIN') && <Button
                onClick={(e) => showDropdown(e)} id="multiple-formcontrol1"
                style={{ textAlign: "left", float: "left", height: '2.5rem', width: '6rem', margin: '0.1px', marginRight: '5px', border: "1px solid #CED4D9", color: "#000000" }}>
                View by
              </Button>}

              {userDetails.roles.includes('ADMIN') &&
                <input type="text" disabled value={selectedOption1}
                  style={{ height: '2.5rem', width: "8rem", float: "left", marginRight: "1rem" }} />}

              <div style={{ border: "1px solid lightgrey", float: "left", backgroundColor: "white", margin: 0, padding: 0, marginRight: "1.2rem" }}>
                <img src={calendar} style={{ width: "24px", marginLeft: "1rem" }} />
                <Select
                  labelId="demo-customized-select-label"
                  data-testid="period"
                  id="demo-customized-select"
                  value={period}
                  onChange={handleDropdownChange}
                  input={<BootstrapInput />}
                >

                  <MenuItem
                    onClick={() => handleChangeCustom('')}
                    value={"daily"}>Last 24 Hours</MenuItem>
                  <MenuItem
                    onClick={() => handleChangeCustom('')}

                    value={"weekly"}>Last 7 Days</MenuItem>
                  <MenuItem
                    onClick={() => handleChangeCustom('')}

                    value={"monthly"}>Last 30 Days</MenuItem>
                  <MenuItem
                    onClick={() => handleChangeCustom('custom')}
                    value={"custom"}>Custom Date</MenuItem>

                </Select>
              </div>

              <Link to={{ pathname: '/Addnewasset' }} style={{ color: "#fff", textDecorationLine: 'none', marginRight: "0.3rem" }}>
                <Button variant="outlined" color="primary">
                  <AddIcon />Add New Asset
                </Button>
              </Link>

              <div style={{ padding: "3px", border: "1px solid #CED4D9", backgroundColor: "#fff", width: "40px", marginLeft: "4px", marginTop: "0.1rem", borderRadius: "4px", height: "2.3rem", paddingTop: "0" }}
                className="material-icons-outlined"
                onClick={pdfGen}
                data-testid="assetDownload"
              >
                <img src={Download} style={{ width: "24px", marginLeft: "5px" }} />
                {/* <span>file_download</span> */}
              </div>

              <div style={{
                border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", backgroundColor: "#fff",
                width: "40px", marginLeft: "2.6px", marginRight: "24px", marginTop: "-4px", height: "41px"
              }}>
                <ShareIcon style={{ width: "97px", marginLeft: "-30px", marginTop: "8px", height: "20px" }} />
              </div>
            </div>
          </div>

        </div>
        <div className={classes.tabsWrapper}>
          {topBarValue === 0 ?
            <Paper className={classes.root}>
              <TabPanel className={classes.tabPanel} value={value} index={0}>
                <ThreeTrucksOverview
                  pdfFlag={pdfFlag}
                  pdfGen={pdfGen}
                  value={value}
                  selectedCloseMarket={selectedOption}
                  handleLeftBarChange={handleChange}
                  topBarValue={topBarValue}
                />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value={value} index={1}>
                <TwoTrucksOverview
                  pdfFlag={pdfFlag}
                  pdfGen={pdfGen}
                  fromAndTo={fromAndTo}
                  selectedCloseMarket={selectedOption}
                  period={period}
                  value={value}
                  handleLeftBarChange={handleChange}
                  topBarValue={topBarValue}
                />
              </TabPanel>
            </Paper> :
            <Paper className={classes.root}>
              <TabPanel className={classes.tabPanel} value={0} index={0}>
                <EVOverview
                  pdfFlag={pdfFlag}
                  pdfGen={pdfGen}
                  fromAndTo={fromAndTo}
                  selectedCloseMarket={"ALL"}
                  period={period}
                  value={value}
                  handleLeftBarChange={handleChange}
                  topBarValue={topBarValue}
                />
              </TabPanel>
            </Paper>
          }
        </div>
      </div>
      <div>
        <Dialog
          open={customDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">{"Custom Date"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <br />
              <TextField
                id="date"
                data-testid="startDate"
                label="Start date"
                type="date"
                sx={{ width: 220, marginBottom: "30px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errorText === true ? errorText : validationStart}
                helperText={
                  errorText === true
                    ? "start date cannot be greater than end date"
                    : validationStart === true
                      ? "Select start date"
                      : ""
                }
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
              />
              &nbsp;&nbsp;
              <TextField
                id="date"
                data-testid="endDate"
                label="End date"
                type="date"
                // defaultValue="2017-05-24"
                sx={{ width: 220, marginBottom: "30px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                error={validationEnd === true ? validationEnd : endNotGreater}
                helperText={
                  validationEnd === true
                    ? "Select end date"
                    : endNotGreater === true
                      ? "End date cannot be greater than todays date"
                      : ""
                }
                onChange={(e) => setendDate(e.target.value)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="okButton" onClick={handleDates}>
              Ok
            </Button>
            <Button data-testid="cancelButton" onClick={handleCancel}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {dDialog && viewByOptions.length ? (<div id="popupDiv" ref={wrapperRef}
          style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #CED4D9', width: '17rem', overflowY: "auto", height: "13rem", color: "#000000" }}>
          <div style={{ width: '100%' }}>
            <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }}
              onChange={(e) => handleChangeView(e, "All")} checked={selectedOption === 'All'} value="All" type="checkbox" name="all" />
            <label htmlFor="all">All</label>
          </div>
          {
            viewByOptions && viewByOptions.map(ele => {
              return (
                <>
                  <hr style={{ marginTop: '0.1px' }} />
                  <div style={{ width: '100%' }} >
                    <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }}
                      checked={selectedOption1 === ele.name} onChange={(e) => handleChangeView(e, ele.id)}
                      type="checkbox" name={ele.name} value={ele.name} />
                    <label htmlFor={ele.id}>{ele.name}</label>
                  </div>
                </>
              )
            })
          }
        </div>) : null}
      </div>
    </div>
  );
}
