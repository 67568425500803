export const  baseURL="http://localhost:3005/data";
export const  assetBaseURL=process.env.REACT_APP_API_ASSET;
export const  marketBaseURL=process.env.REACT_APP_API_MARKET;
export const  businessObjectbaseURL=process.env.REACT_APP_API_BUSINESS;
export const  tenantandUser=process.env.REACT_APP_API_TENANT_USER;
// export const  marketTenantURL=process.env.REACT_APP_API_TENANT_USER;
// export const assetTenantURL=process.env.REACT_APP_API_TENANT_USER;
export const  feedbackBaseURL=process.env.REACT_APP_API_FEEDBACK;
export const  telemetry=process.env.REACT_APP_API_TELEMETRY;
export const  authBaseURL=process.env.REACT_APP_API_AUTH;
export const mapMyIndiaKey=process.env.REACT_APP_MAP_KEY;
export const mapMyIndiaClientId=process.env.REACT_APP_MAP_CLIENT_ID;
export const mapMyIndiaClientSecret=process.env.REACT_APP_MAP_CLIENT_SECRET;
export const indicativeKey = process.env.REACT_APP_API_INDICATIVE_KEY;
export const driverBaseURL = process.env.REACT_APP_API_DRIVER;
export const marketTenantURL = process.env.REACT_APP_API_TENANT_USER;
export const assetTenantURL = process.env.REACT_APP_API_ASSET;
export const  assertBaseURL="http://a0a6a96b3ed8f48d9aa06a3e782f1f0c-2085529333.ap-south-1.elb.amazonaws.com:8888";
export const roadmap = process.env.REACT_APP_API_ROADMAP