import axios from 'axios';
import { maxAssetList } from './_helpers';
import { assetBaseURL, marketBaseURL, telemetry,marketTenantURL ,assetTenantURL} from './_url';

export default class DashboardChartAPI {
    getAcceptedVsNotAccepted = (Dates: any,tenantId:any) => {
     
        
        if(tenantId=='All'){
            return axios.get(marketBaseURL + '/metrics/transporter/overview?'+Dates).then(res => {
                // console.log(res.data);
                return res.data;
            }); 
        }else
        {
            return axios.get(`${marketTenantURL}/metrics/transporter/tenant/overview?${Dates}&duratio=$dayOfWeek&tenantId=${tenantId}`).then(res=>{return res.data})
           
            
        }
    }

    getShipmentsOverview = (Dates: any,tenantId:any) => {

        if(tenantId=='All'){
            return axios.get(marketBaseURL + '/metrics/shipments/overview?'+Dates).then(res => {
                // console.log(res.data);
                return res.data;
            }); 
        }else
        {
            return axios.get(`${marketTenantURL}/metrics/shipments/tenant/overview?duration='5'&${Dates}&tenantId=${tenantId}`).then(res=>{return res.data})
           
            
        }
    }

    getTop5Shippers = (Dates: any,tenantId:any) => {
       
        if(tenantId=='All'){
            return axios.get(marketBaseURL + '/metrics/shipments/topn?'+Dates).then(res => {
                // console.log(res.data);
                return res.data;
            }); 
        }else
        {
            return axios.get(`${marketTenantURL}/metrics/shipments/tenant/topn?${Dates}&tenantId=${tenantId}`).then(res=>{return res.data})
           
            
        }
    }
    getTop5TenantShippers = (Dates: any, tenantId:any) => {
        
        return axios.get(`${marketBaseURL}/metrics/shipments/topn?${Dates}&tenantId=${tenantId}`).then(res=>{return res.data})
       
    
    
    }
    getLight = (tagId: string, filter: string, startAndEnd: any) => {
        
          
          return axios.get(`${telemetry}/telemetry/lights/tagId?tagId=${tagId}&${startAndEnd}&duration=${filter}`).then(res=>{return res.data})
    
          
    
      }
    getTop5Transporter = (Dates: any,tenantId:any) => {

        if(tenantId=='All'){
            return axios.get(marketBaseURL + '/metrics/transporter/topn?'+Dates).then(res => {
                // console.log(res.data);
                return res.data;
            }); 
        }else
        {
            return axios.get(`${marketTenantURL}/metrics/transporter/tenant/topn?${Dates}&tenantId=${tenantId}`).then(res=>{return res.data})
           
            
        }
    }

    getTripsVsAvgFreshnessScore = (Dates: any,tenantId:any) => {

      
        if(tenantId=='All'){
            return axios.get(marketBaseURL + '/metrics/shipments/trips-freshness-score?'+Dates).then(res => {
                // console.log(res.data);
                return res.data;
            }); 
        }else
        {
            return axios.get(`${marketTenantURL}/metrics/shipments/tenant/trips-freshness-score?duration='5'&${Dates}&tenantId=${tenantId}`).then(res=>{return res.data})
           
            
        }
    }
    getShipmentsTrend = (Dates: any,tenantId:any) => {
        if(tenantId=='All'){
        return axios.get(marketBaseURL + '/metrics/shipments/trends?duration=&'+Dates).then(res => {
            // console.log(res.data);
            return res.data;
        }); 
    }else
    {
        return axios.get(`${marketTenantURL}/metrics/shipments/tenant/trends?duration='5'&${Dates}&tenantId=${tenantId}`).then(res=>{return res.data})
       
        
    }
    }
    getPickupandDeliveryTime = (Dates: any,tenantId:any) => {
       
        if(tenantId=='All'){
            return axios.get(marketBaseURL + '/metrics/transporter/pickupdelivery?duration=$dayOfWeek&'+Dates).then(res => {
                // console.log(res.data);
                return res.data;
            }); 
        }else
        {
            return axios.get(`${marketTenantURL}/metrics/transporter/tenant/pickupdelivery?${Dates}&duration=$dayOfWeek&tenantId=${tenantId}`).then(res=>{return res.data})
           
            
        }
    }
    getTempartureEx = (fromDate: any,toDate:any,id:any) => {
       
        return axios.get(telemetry + '/metrics/transporter/tempexcursion?assetId='+id+'&duration=$dayOfWeek&from='+fromDate+'&to='+toDate).then(res => {
            // console.log(res.data);
            return res.data;
        });
    }
    getShippers = (Dates: any) => {
       
        return axios.get(marketBaseURL + '/metrics/marketplace/shippers?duration=$dayOfWeek&'+Dates).then(res => {
            // console.log(res.data);
            return res.data;
        });
    }
   
    getTransporters = (Dates: any) => {
       
        return axios.get(marketBaseURL + '/metrics/marketplace/transporters?duration=$dayOfWeek&'+Dates).then(res => {
            // console.log(res.data);
            return res.data;
        });
    }
    getShockList = (assetId:any ,startDate:any,endDate:any) => {
       
        return axios.get(telemetry+ '/telemetry/shocks?assetId='+assetId+'?duration=&from='+startDate+'&to='+endDate).then(res => {
            // console.log(res.data);
            return res.data;
        });
    }
    getAssetList = (assetType:any,tenatId:any) =>{
        
       return  axios.get(assetTenantURL+'/assets/type?type='+assetType+'&size='+maxAssetList+'&tenatId='+tenatId).then(res => { 
           return res.data;
        });

    }

    getAssetOverview = (Dates:any) =>{
        
        return  axios.get(marketBaseURL+'/assets/overview?'+Dates).then(res => { 
            return res.data;
         });
 
     }

     getShipperAndTransport = (Dates:any) =>{
        return  axios.get(marketBaseURL+'/metrics/marketplace/shipment/requests?'+Dates).then(res => { 
            return res.data;
         });

     }
}