
import assetForm from "./FormAsset/assetForm";

const {
  
  formField: {
    assettype,
    assetname,
    status,
    assettyp,
    locality,
    city,
    zone,
    region,
    hname,
    hid,
    gid,
    
    
  
    
  }
} = assetForm;

export default {
  [assettype.name]:'',
  [assetname.name]:'',
  [status.name]:'',
  [assettyp.name]:'',
  [locality.name]:'',
  [city.name]:'',
  [zone.name]:'',
  [region.name]:'',
  [hname.name]:'',
  [hid.name]:'',
  [gid.name]:'',
  
  
  };
