export default {
    formId: 'assetForm',
    formField: {
     assettype: {
        name: 'assettype',
        label: 'Asset Type',
        requiredErrorMsg: 'Asset Type required',
      },
      assetname: {
        name: 'assetname',
        label: 'Asset Name',
        requiredErrorMsg: 'Asset Name required'
      },
      status: {
        name: 'status',
        label: 'Status',
        requiredErrorMsg: 'status required'
        },
      // assetid: {
      //   name: 'assetid',
      //   label: 'Asset Id',
      //   requiredErrorMsg: 'Asset Id required',
      //   },
      assettyp: {
        name: 'assettyp',
        label: 'Customer Name',
        requiredErrorMsg: 'Customer Name required',
      },
     
      locality: {
        name: 'locality',
        label: 'locality',
        requiredErrorMsg: 'locality required',
        },
      //Delivery Form Fields
      city: {
        name: 'city',
        label: 'city',
        requiredErrorMsg: 'City required',
       },
      zone: {
        name: 'zone',
        label: 'zone',
        requiredErrorMsg: 'Zone required',
        
      },
      region: {
        name: 'region',
        label: 'region',
        requiredErrorMsg: 'Region required',
      },
      hname: {
        name: 'hname',
        label: 'Hardwarekit Name',
        requiredErrorMsg: 'Harwarekit Name required',
       },
      hid: {
        name: 'hid',
        label: 'Hardwarekit Id',
        requiredErrorMsg: 'Hardwarekit Id required'
        },
      gid: {
        name: 'gid',
        label: 'Gateway Id',
        requiredErrorMsg: 'Gateway Id required'
        },
     
      
    }
  };
  