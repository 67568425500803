import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button,  Dialog, DialogActions, DialogContent, DialogTitle, Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShipperHelper, { ShippingRequestStatus, setCompleteToken, timeDiff } from '../../../services/_helpers';
import AssetAllocationForm from '../Denso/Requests/Assign';
import CollapseTable from './collapse';
import { useRowStyles } from './styles';
import AssetAllocationFormTrans from '../TransporterLanding/AssetAllocationForm';
import './style.css'
import axios from 'axios';
import { marketBaseURL } from '../../../services/_url';
import { useEffect } from 'react';

import UserService, { ROLE } from '../../../services/userService';
import UpdatePayment from '../Denso/UpdatePayment';
import { TableManageColumnsFormData } from '../Dashboard/Common/Interfaces/custom';
import ChallanGenerator from '../common/PdfChallan/ChallanGenerator';
import MarketPlaceAPI from '../../../services/marketPlaceService';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import coldchainLog from '../../../services/consoleHelper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { blue } from '@mui/material/colors';

function Row(props: any) {
  const { row, setFetchTableFlag, manageColumnsOptions, showCheckBox } = props;
  const [open, setOpen] = React.useState(false);
  const classes: any = useRowStyles();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [tripData, setTripData] = React.useState([]);
  const [role, setRole] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  let trans = new ShipperHelper();
  const userApi = new UserService();
  const marketPlaceService = new MarketPlaceAPI();
  const userDetails = userApi.getUserDetails();
  const [messageOpen, setmessageOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const handleMessage = () => {
    setmessageOpen(!messageOpen)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [openPDF, setOpenPDF] = React.useState(false);
  const handleOpenPDF = () => setOpenPDF(true);
  const handleClosePDF = () => setOpenPDF(false);

  const handleClosemenu = () => {
    setAnchorEl(null);
  };
  const handleClickOpenDelete = () => {
    setDeleteOpen(true);
    handleClosemenu();
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);

  };
  useEffect(() => {


    if (userDetails?.token) {
      if (userDetails?.roles?.includes(ROLE.transporter)) {
        setRole(ROLE.transporter);
      }
      if (userDetails?.roles?.includes(ROLE.closedMarketplace)) {
        setRole( ROLE.closedMarketplace);
      }
      if (userDetails?.roles?.includes(ROLE.denso || ROLE.closedMarketplace)) {
        setRole(ROLE.denso || ROLE.closedMarketplace);
      }
    
    }



  }, []);



  const handleDelete = () => {
    row.status = trans.getStatusKeyByValue(ShippingRequestStatus.Cancelled);

    axios.patch(`${marketBaseURL}/marketplace/shippingRequests/${row.id}`, row)
      .then((res) => {
        setFetchTableFlag(true);
        setCompleteToken("true")
        setDeleteOpen(!deleteOpen);
      })
  }
  const formatPrice = (price: number) => {
    if (!isNaN(price)) {
      return price.toFixed(2);
    }
    return price ? price : '---';
  }

  const postAPIcall = (status: string = ShippingRequestStatus.Completed) => {
    var trip: any = {}
    var auditUpdate = { action: "", timestamp: "", userId: "" }
    setLoading(true);
    if(props.onUpdateTable) {
      props.onUpdateTable();
    }
    
    marketPlaceService.getTrip(row?.tripId).then((res: any) => {

      setTripData(res);
      trip = res;


      trip['status'] = status;
      auditUpdate.action = status;
      auditUpdate.timestamp = trans.dateToUTC(new Date());
      auditUpdate.userId = userDetails?.userId
      trip['audit'].push(auditUpdate)
      coldchainLog("trip",trip);
      axios.patch(`${marketBaseURL}/marketplace/trips/${row.tripId}/status`, {
        "id": row.tripId,
        "shippingRequestId": row.id,
        "assetId": row.asset.id,
        "status": status
    }
      // return axios.patch(`${marketBaseURL}/marketplace/trips/${row.tripId}`,trip
      //   })
      
      //   setTripData(x.filter((item:any) =>  
      //   (item:any) => item["shippingRequestId"] === row.id))
      // }).catch(err=>console.log(err))
      
      
      // console.log("tripid",tripData);
      // const tripID=tripData[0]?.id;
      
      //return axios.patch(`${marketBaseURL}/marketplace/trips/${tripID}`,
      // {
      
      //   "id":tripID,
      //   "shippingRequestId":row.id,
      
      
      //   "assetId": row?.asset?.id,
      //   "status": status,
      //   "driverDetails": {
      //     "name": row?.driverDetails?.name,
      //     "phoneNumber": row?.driverDetails?.phoneNumber
      //   },
      //   "audit": [
      //     {
      //       "action": status,
      //       "userId": null, // todo: rework during authenticaion API integration.
      //       "timestamp":trans.dateToUTC(new Date())
      //     }
      //   ]
      
      // }
      ).then(resp => {      
        setFetchTableFlag(true);
        setCompleteToken("true") // fetches table data from server
        setLoading(false);
        if(props.onUpdateTable) {
          props.onUpdateTable()
        }
        return resp.data;
      }).catch((err: any) => {
        console.error("err", err.response);
        setCompleteToken("true")
        setLoading(false);
        if(props.onUpdateTable) {
          props.onUpdateTable()
        }
        const UserToken = new ShipperHelper()
        UserToken.UserTokenExpiration()
      }).catch((err: any) => {
        setLoading(false);
        if(props.onUpdateTable) {
          props.onUpdateTable()
        }
      })
    })
  }

  const changeCBFlag = (e:any, row:any) => {
    if(e.target.checked){
      row.cbFlag = true;
    }
    else {
      row.cbFlag = false;
    }
    props.validateButton(e, row);
  }


  const DensoLandingPage = (requestNumber: any) => {
    history.push(`/denso/${requestNumber}/${row.asset.id}`)
  }
  const DensoUpdatePage = (requestNumber: any) => {
    history.push(`/densoupdateshipment/${requestNumber}`)
  }

  const TransporterLandingPage = (requestNumber: any) => {
    history.push(`/transporter/${requestNumber}`)
  }

  let picktime = new Date(row?.expectedPickUpTime?.to);

  let today = new Date();
  let timelefttopickup = picktime.getTime() - today.getTime();


  const cellsTrans = [
    {
      defaultOrder: 1, render: (
        <TableCell align="left">
          {
            <span id={
              ((ShippingRequestStatus[row?.status] === ShippingRequestStatus.Cancelled) ? 'statusGrey' :
                (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Completed ? 'statusGreen' :
                  (ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ? 'statusGreen' :
                    (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Accepted ? 'statusAccepted' :
                      (ShippingRequestStatus[row?.status] === ShippingRequestStatus.InTransit ? 'statusOrange' : 'statusOrange')))))
            } className="capitalise-case">
              {ShippingRequestStatus[row?.status]}
            </span>
          }
        </TableCell>
      )
    },
    {
      defaultOrder: 2, render: (
        <TableCell component="th" scope="row" align="center">
          <Button className={classes.requestId} onClick={() => TransporterLandingPage(row?.requestNumber)}>
            {row?.requestNumber}
          </Button>
        </TableCell>
      )
    },
    {
      defaultOrder: 3, render: (
        <TableCell align="left"> {row?.shipper?.name ? row?.shipper?.name : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 4, render: (
        <TableCell align="left">{row?.asset?.number ? row?.asset?.number : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 5, render: (
        <TableCell align="left">{row?.driverDetails?.name ? row?.driverDetails?.name : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 6, render: (
        <TableCell align="right" >{formatPrice(row?.paymentDetails?.actualAmount)}</TableCell>
      )
    },
    {
      defaultOrder: 7, render: (
        <TableCell align="left">{trans.dateConversion(row.createdAt)}</TableCell>
      )
    }
  ];

  const cellsDenso = [
    {
      defaultOrder: 1, render: (
        <TableCell align="left">
          {
            <span id={
              ((ShippingRequestStatus[row?.status] === ShippingRequestStatus.Cancelled) ? 'statusGrey' :
                (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Completed ? 'statusGreen' :
                  (ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ? 'statusGreen' :
                    (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Accepted ? 'statusAccepted' :
                      (ShippingRequestStatus[row?.status] === ShippingRequestStatus.InTransit ? 'statusOrange' : 'statusOrange')))))
            } className="capitalise-case">
              {ShippingRequestStatus[row?.status]}
            </span>
          }
        </TableCell>
      )
    },
    {
      defaultOrder: 2, render: (
        <TableCell component="th" scope="row" align="left">
          <Button className={classes.requestId} onClick={() => DensoLandingPage(row?.requestNumber)}>
            {row?.requestNumber}
          </Button>
        </TableCell>
      )
    },
    {
      defaultOrder: 3, render: (
        <TableCell align="left"> {row?.transporter?.name ? row?.transporter?.name : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 4, render: (
        <TableCell align="left"> {row?.shipper?.name ? row?.shipper?.name : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 5, render: (
        <TableCell align="left">{row?.asset?.number ? row?.asset?.number : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 6, render: (
        <TableCell align="left">{row?.driverDetails?.name ? row?.driverDetails?.name : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 7, render: (
        <TableCell align="right">{formatPrice(row?.paymentDetails?.actualAmount)}</TableCell>
      )
    },
    {
      defaultOrder: 8, render: (
        <TableCell align="left">{trans.dateConversion(row.createdAt)}</TableCell>
      )
    }
  ];
   const closedMarketplace = [
    {
      defaultOrder: 1, render: (
        <TableCell align="center">
          {
            <span id={
              ((ShippingRequestStatus[row?.status] === ShippingRequestStatus.Cancelled) ? 'statusGrey' :
                (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Completed ? 'statusGreen' :
                  (ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ? 'statusGreen' :
                    (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Accepted ? 'statusAccepted' :
                      (ShippingRequestStatus[row?.status] === ShippingRequestStatus.InTransit ? 'statusOrange' : 'statusOrange')))))
            } className="capitalise-case">
              {ShippingRequestStatus[row?.status]}
            </span>
          }
        </TableCell>
      )
    },
    {
      defaultOrder: 2, render: (
        <TableCell component="th" scope="row" align="left">
          <Button className={classes.requestId} onClick={() => DensoLandingPage(row?.requestNumber)}>
            {row?.requestNumber}
          </Button>
        </TableCell>
      )
    },
    {
      defaultOrder: 3, render: (
        <TableCell align="center"> {row?.transporter?.name ? row?.transporter?.name : "----"}</TableCell>
      )
    },
    {
      defaultOrder: 4, render: (
        <TableCell align="center">{formatPrice(row?.paymentDetails?.actualAmount)}</TableCell>
      )
    },
    {
      defaultOrder: 5, render: (
        <TableCell align="left">{trans.dateConversion(row.createdAt)}</TableCell>
      )
    }

   ]
  const cells = role && role === ROLE.denso  ? cellsDenso :  role === ROLE.closedMarketplace ? closedMarketplace : cellsTrans;
  return (
    <React.Fragment>

      <TableRow className={open ? classes.rowTop : ''} >
        <TableCell  >

          <IconButton aria-label="expand row" size="small" data-testid="collapseToggle" onClick={() => setOpen(!open)} style={{paddingLeft:"10px",marginTop:"10px"}}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        { showCheckBox !== "false" ?  <TableCell>
                                 <Checkbox
                                 checked={row.cbFlag}
                                 style={{color:"#2196f3"}}
                                 onChange={(e:any) => changeCBFlag(e, row)} sx={{ '&.Mui-checked':{
                                      color: "#2196f3",
                                   
                                    }
                                  }}></Checkbox>
                                 
                                </TableCell> : null
                              }
        {
          manageColumnsOptions.sort((a: TableManageColumnsFormData, b: TableManageColumnsFormData) => {
            return a.order - b.order;
          }).map((reorder: TableManageColumnsFormData) => {
            return cells?.find((cell: any) => cell.defaultOrder === reorder.defaultOrder)?.render;
          })
        }
        <TableCell  data-testid="actionsCell" >
          {ShippingRequestStatus[row?.status] === ShippingRequestStatus.Accepted ? <Button style={{textTransform:"capitalize",marginLeft:"5px",
          }}onClick={() => postAPIcall(trans.getStatusKeyByValue(ShippingRequestStatus.InProgress))} disabled={row?.asset?.number ? null : true}
            id={trans.getStatusKeyByValue(ShippingRequestStatus[row?.asset]) ? "statusBtn" : ""}>Start Transit</Button> :
            (ShippingRequestStatus[row?.status] === ShippingRequestStatus.InTransit ? <Button
              className='deliverbtn' style={{color:"#69B964",textTransform:"capitalize",marginLeft:"5px"}} onClick={() => postAPIcall(trans.getStatusKeyByValue(ShippingRequestStatus.Completed))} disabled={row?.asset?.number ? null : true}
              id={ShippingRequestStatus[row?.asset] ? "statusBtn" : ""}>Delivered</Button> :
              (ShippingRequestStatus[row?.status] === ShippingRequestStatus.Completed || ShippingRequestStatus[row?.status] === ShippingRequestStatus.Cancelled ? "" : <>
                {role === ROLE.denso || role === ROLE.closedMarketplace ? <AssetAllocationForm shippingId={row.id} status={row.status} setFetchTableFlag={setFetchTableFlag} expectedToPckUpTime={row?.expectedPickUpTime?.to} handleMessage={handleMessage} /> :
                  <AssetAllocationFormTrans shippingId={row.id} status={row.status} setFetchTableFlag={setFetchTableFlag} expectedToPckUpTime={row?.expectedPickUpTime?.to} handleMessage={handleMessage} />}

              </>
              ))}
          <Menu
         
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClosemenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
          >

            <>
              {
                // console.log(role, ROLE.closedMarketplace)
              }
              {role === ROLE.denso || role === ROLE.closedMarketplace ?
                (ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance) ?
                  <>
                    <MenuItem onClick={handleClickOpenDelete} data-testid="cancel" style={{fontSize:"14px",height:"40px",color:"#000"}}>Cancel</MenuItem>
                    <Divider style={{width:"152px",marginLeft:"15px",backgroundColor:"grey",height:"1.7px"}} />
                  </> :
                  <>
                    <MenuItem disabled data-testid="cancel" style={{fontSize:"14px",height:"40px",marginTop:"-2px",color:"#000"}}>Cancel</MenuItem>
                    <Divider style={{width:"152px",marginLeft:"15px",backgroundColor:"grey",height:"1.7px"}}/>
                  </>
                :
                <>
                  <MenuItem disabled data-testid="cancel" style={{fontSize:"14px",height:"40px",color:"#000"}}>Cancel</MenuItem>
                  <Divider style={{width:"152px",marginLeft:"15px",backgroundColor:"grey",height:"1.7px"}}/>
                  </>
              }
            </>
            {/* } */}
            <MenuItem
            style={{fontSize:"14px",height:"40px",color:"#000"}}
            onClick={handleOpenPDF} data-testid='pdf' disabled={ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance ? true : null}>Download Challan</MenuItem>
            <Divider  style={{width:"152px",marginLeft:"15px",backgroundColor:"grey",height:"1.3px"}}/>
            {role === ROLE.denso || role === ROLE.closedMarketplace ?

              <>
                {(ShippingRequestStatus[row?.status] === ShippingRequestStatus.Completed) ?
                  <MenuItem onClick={handleClosemenu} data-testid='menuClose' style={{color:"#000",
                 }}><UpdatePayment data={row} />
                  </MenuItem>
                   :
          <>
                  <MenuItem onClick={handleClosemenu} data-testid='menuClose' style={{fontSize:"14px",width:"180px",height:"40px",color:"#000"}} disabled>Update Payment Details
                  
                  </MenuItem>
                  <Divider style={{width:"152px",marginLeft:"15px",backgroundColor:"grey",height:"1.3px"}}/>
                  </>
                  }

                {row.status.toLowerCase().trim() !== 'pendingacceptance' ?
                  <>
                    <MenuItem  onClick={handleClosemenu} disabled   style={{marginTop:"-7px",fontSize:"14px",height:"40px",color:"#000"}}>Update Request</MenuItem>
                  </> :
                  <>
                    {
                      (timelefttopickup > timeDiff && ShippingRequestStatus[row?.status] === ShippingRequestStatus.PendingAcceptance) ?
                        <>
                          <MenuItem onClick={handleClosemenu} style={{fontSize:"14px"}}><div style={{ color: 'black', textDecorationLine: 'none' }} onClick={() => DensoUpdatePage(row?.requestNumber)}
                          >Update Request</div></MenuItem>
                        </> :
                        <>
                          {/* <MenuItem onClick={handleClosemenu} ><a href={`/densoupdateshipment/${row?.requestNumber}`} style={{color:'black',textDecorationLine:'none'}}>Update Request</a></MenuItem> */}
                          <MenuItem onClick={handleClosemenu} data-testid='menuClose' disabled
                          style={{marginTop:"9px",height:"2px",marginBottom:"9px",fontSize:"14px",color:"#000"}}
                          >
                          Update Request</MenuItem>
                        </>
                    }
                  </>
                }
              </>
              : ""}

          </Menu>
         
        </TableCell>
        <TableCell style={{width:"20px",border:"1px solid #fff"}} >
        <IconButton  data-testid="moreVertButton" className='actionButton' onClick={handleClick} component="span">
            <MoreVertIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
      <Dialog onClose={handleCloseDelete} aria-labelledby="customized-dialog-title" open={deleteOpen}
        maxWidth="sm" fullWidth={fullWidth}>
        <DialogTitle id="customized-dialog-title" style={{color:"#000000"}} >
          CANCEL
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{color:"#000000"}}>
            Are you sure to cancel the record?
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            CLOSE
          </Button>
          <Button onClick={handleDelete} color="primary" data-testid="cancel">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={role === ROLE.denso || role === ROLE.closedMarketplace ? 12 : 9} className={open ? classes.rowBottom : ''}>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <CollapseTable  feedback={["Estimated Cost", "Actual Cost", "Distance Travelled"]} row={row} isPendingRow={ShippingRequestStatus[row.status] === ShippingRequestStatus.PendingAcceptance} />
          </Collapse>
        </TableCell>
      </TableRow>
      <div>
        <ChallanGenerator row={row} openPDF={openPDF} handleClosePDF={handleClosePDF} />
      </div>
      <div>
        <Snackbar
          open={messageOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={5000}
          onClose={handleMessage}
        >
          <Alert variant="filled" severity="warning">Shipment Pickup time has expired. Cannot Accept and Assign this Shipment.</Alert>
        </Snackbar>
      </div>
      <div>
        {loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
    </React.Fragment>
  );
}

export default Row;
