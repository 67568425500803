import React, { Fragment, useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { InputField } from "../../../common/FormFields";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { assetBaseURL, tenantandUser } from "../../../../../services/_url";
import axios from "axios";
import ShipperAPI from "../../../../../services/shippingLanding";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    span:{
      color:"red",
    },
    select: {
      width:"80%",
      "&.MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "red"
        },
      }
    },
    select1: {
      width:"80%"
    },
    input1:{
width:"80%"
    },
    input:{
      width:"80%",
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
      }
          },
  })
);

export default function AssetDetails(props: any) {

  // console.log(props.location);
  const [assetState, setAssetState] = useState({ AssetType: (props.storedData.AssetType ? props.storedData.AssetType : ""), AssetName: (props.storedData.AssetName !=="" ? props.storedData.AssetName : ""), AssetID: (props.storedData.AssetID !== "" ? props.storedData.AssetID : ""), Status: (props.storedData.Status !== "" ? props.storedData.Status : ""), id: (props.storedData.id !== "" ? props.storedData.id : ""), CustomerAssetType: (props.storedData.CustomerAssetType !=="" ? props.storedData.CustomerAssetType : ""), customerName: (props.storedData.customerName !=="" ? props.storedData.customerName : ""), driverName: (props.storedData.driverName !=="" ? props.storedData.driverName : ""), tenantId: (props.storedData.tenantId !=="" ? props.storedData.tenantId : ""), active: (props.storedData.active !=="" ? props.storedData.active : ""), description: (props.storedData.description !=="" ? props.storedData.description : "") })
  const classes = useStyles();
  const [assetData, setAssetData] = useState([]);
  const [assetName, setAssetName] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [type, setType] = useState('');
  const [assetTypeFlag, setAssetTypeFlag] = useState(false);
  const [assetNumberFlag, setAssetNumberFlag] = useState(false);
  const [statusFlag, setStatusFlag] = useState(false);
  const [customerNameFlag, setCustomerNameFlag] = useState(false);  // not used
  const [driverNameFlag, setDriverNameFlag] = useState(false);
  const [customerFlag, setCustomerFlag] = useState(false);
  const [error,setError] =useState(0);
  const [servedCustomerMenuItems, setServedCustomerMenuItems] = useState(null);
  

  
  useEffect(() => {
    setError(props.errstatus)
    assetState.AssetType === "" ? setAssetTypeFlag(true) : setAssetTypeFlag(false);
    assetState.AssetID === "" ? setAssetNumberFlag(true) : setAssetNumberFlag(false);
    assetState.Status === "" ? setStatusFlag(true) : setStatusFlag(false);
    /** Commented as the validation for the below fields are not required at this point of time */
    // assetState.CustomerAssetType === "" ? setCustomerFlag(true) : setCustomerFlag(false);
    // assetState.customerName === "" ? setCustomerNameFlag(true) : setCustomerNameFlag(false);
    // assetState.driverName === "" ? setDriverNameFlag(true) : setDriverNameFlag(false);

    if (assetState.AssetType !== "" && assetState.AssetID !== "" && assetState.Status !== "") {
      props.validationFlag(true);
    }
    
  }, [customerFlag,statusFlag,assetTypeFlag,assetNumberFlag, customerNameFlag, driverNameFlag , props.validate]);

  useEffect(() => {
    setAssetTypeFlag(false);
    setAssetNumberFlag(false);
    setStatusFlag(false);
    // setCustomerFlag(false);
    // setCustomerNameFlag(false);
    // setDriverNameFlag(false)
  }, [])

  const changeHandler = (e: any) => {
    const { name, value } = e.target;
    if (name === 'AssetName') {      
      setAssetState(prevState => ({
        ...prevState, [name]: value.number, AssetID: value.id
      }))
      setError(0);
      props.formData(assetState);
      return;
    }
    if (name === 'AssetType') {
      setType(value)
      setAssetTypeFlag(false)
    }
    setAssetState(prevState => ({
      ...prevState, [name]: value
    }))
   
      if (name !== 'AssetType') {
        props.formData(assetState);
        }
        if (name === 'AssetID'){
          setAssetNumberFlag(false);
        }
        if (name === 'Status'){
          setStatusFlag(false);
        }
        // if (name === 'CustomerAssetType'){
        //   setCustomerFlag(false);
        // }
        // if(name === "customerName" && value !=="") {
        //   setCustomerNameFlag(false);
        // }
        // if(name === "driverName" && value !=="") {
        //   setDriverNameFlag(false);
        // }
  }
  
  const changeHandlerAsset = (e: any, name: string, value: any) => {
    // const { name, value } = e.target;  
    if (name === 'AssetType') {
      //setType(value);
      setAssetState(prevState => ({
        ...prevState, id: value.id, description: value.description,  active: value.active
      }))
    }

    props.formData(assetState);
  }

  const changeHandlerCustomerAssetType = (e: any, name: string, value: string) => {
    if (name === 'CustomerAssetType') {
      setAssetState(prevState => ({
        ...prevState, tenantId: value
      }))
    }

    props.formData(assetState);
  }


  props.formData(assetState);

  useEffect(() => {
    props.formData(assetState);
  }, [assetState]);

  useEffect(() => {
    const type = "assets";
    axios
      .get(`${assetBaseURL}/assetTypes`)
      .then((res: any) => {
        // console.log(res.data)
        setAssetData(res.data);  
    //  console.log(res.data,"res.data")
      })
      .catch((err: any) => {
        setAssetData([]);
      });

     
    const shipperService = new ShipperAPI();
    shipperService.getShipperID()
      //axios.get(`${tenantandUser}/tenants`) 
      .then((res: any) => {
        let transporterOptions: any = []
        res.map((val: any) => {
          if (val.roles[0]?.name === "ClosedMarketplace" && (val.name === "ISE foods" || val.name === "ISE" || val.name === "LithPwr" || val.name === "Blowhorn" )  ) {
            let test = { key: "", value: "" }
            test.key = val.id;
            test.value = val.name
            transporterOptions = [...transporterOptions, test]
          }
        })
        setTransporterData(transporterOptions);
      })

  }, []);

  useEffect(() => {
    if (type) {
      axios
        .get(`${assetBaseURL}/assets/type?type=${type}&page=0&size=100&srvCst=1&drv=1`)
        .then((res: any) => {
          setAssetName(res.data.data);
        })
        .catch((err: any) => {
          setAssetName([]);
        });
    }

  }, [type]);

  /**
   * Served Customer Api Call when tenant is selected or changed
   */
  useEffect(() => {
    if (assetState.CustomerAssetType) {
      axios
        .get(`${tenantandUser}/tenants/servedCustomer`)
        .then((res: any) => {
          if(res && res.data && res.data.length > 0) {
            const servedCustomerForTenant: any = res.data.filter((item: any) => item.tenantName === assetState.CustomerAssetType);
            setServedCustomerMenuItems(servedCustomerForTenant);
          }
          // setAssetName(res.data.data);
        })
        .catch((err: any) => {
          // setAssetName([]);
        });
    } else {
      setAssetState(prevState => ({
        ...prevState, customerName: ""
      }))
      setServedCustomerMenuItems(null);
    }

  }, [assetState.CustomerAssetType]);


  // console.log(assetData, "assetData")
  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement">
          <Grid container spacing={3} style={{ marginLeft: "2rem" }}>
            <Grid item xs={12}>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ float: "right", width: "100%", marginTop: "-2.7rem" }}
              >
                <br />
                <br />
                <p style={{ fontSize: "14px" }} className="cardTitle">
                  Attach to customer
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                  <Select
                    className={customerFlag ? classes.select : classes.select1}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="CustomerAssetType"
                    label="Transporter"
                    onChange={changeHandler}
                    // onChange={changeHandler; hello}
                    value={assetState.CustomerAssetType}
                  >
                    <MenuItem key="9999" value="" style={{ color: "#000" }}>
                      {"Clear Selection"}
                    </MenuItem>
                    {transporterData.map((option: any) => {
                      return (
                        <MenuItem
                          key={option.key}
                          value={option.value}
                          style={{ color: "#000" }}
                          onClick={(e) =>
                            changeHandlerCustomerAssetType(
                              e,
                              "CustomerAssetType",
                              option.key
                            )
                          }
                        >
                          {option.value}
                        </MenuItem>
                      );
                    })}
                    <br />
                  </Select>
                </FormControl>
                {/* <br />
                <span className={classes.span}>
                  {customerFlag ? "Transporter details are required" : ""}
                </span> */}
              </Grid>
              <Grid item xs={12} lg={6}>
                <p style={{ fontSize: "14px" }} className="cardTitle">
                  Asset Details
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Asset type
                  </InputLabel>
                  <Select
                    style={{ width: "80%" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="AssetType"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.AssetType}
                  >
                    {assetData
                      ?.filter((option) => option.name !== "Reefer Trucks")
                      .map((option: any) => {
                        return (
                          <MenuItem
                            key={option.id}
                            value={option.name}
                            style={{ color: "#000" }}
                            onClick={(e) =>
                              changeHandlerAsset(e, "AssetType", option)
                            }
                          >
                            {option.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <br />
                <span className={classes.span}>
                  {assetTypeFlag ? "Asset type is required" : ""}
                </span>
              </Grid>
              <br />
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    name="AssetID"
                    label="Asset Number"
                    className={assetNumberFlag ? classes.input : classes.input1}
                    variant="outlined"
                    onChange={changeHandler}
                    value={assetState.AssetID}
                    style={{ color: "#000" }}
                  />
                </FormControl>
                <br />
                <span className={classes.span}>
                  {assetNumberFlag ? "Asset Number is required" : ""}
                </span>
              </Grid>
              <br />

              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    className={statusFlag ? classes.select : classes.select1}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="Status"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.Status}
                  >
                    <MenuItem value="Active" style={{ color: "#000" }}>
                      Active
                    </MenuItem>

                    <MenuItem value="Undermantance" style={{ color: "#000" }}>
                      UnderMaintenance
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <span className={classes.span}>
                  {statusFlag ? "Asset status are required" : ""}
                </span>
              </Grid>
              <br />
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Served Customer
                  </InputLabel>
                  <Select
                    className={
                      customerNameFlag ? classes.select : classes.select1
                    }
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="customerName"
                    label="Served Customer"
                    onChange={changeHandler}
                    value={assetState.customerName}
                    disabled={!assetState.CustomerAssetType}
                  >
                    <MenuItem key="999" value="" style={{ color: "#000" }}>
                      {"Clear Selection"}
                    </MenuItem>
                    {servedCustomerMenuItems &&
                      servedCustomerMenuItems.map((item: any) => {
                        return (
                          <MenuItem value={item.id} style={{ color: "#000" }}>
                            {item.customerDesc}
                          </MenuItem>
                        );
                      })}
                    {/* <MenuItem value="Active"style={{color:"#000"}}>Active</MenuItem>
                     
                          <MenuItem value="Undermantance"style={{color:"#000"}}>UnderMaintenance</MenuItem> */}
                  </Select>
                </FormControl>
                <br />
                {/* <span className={classes.span}>
                  {
                    customerNameFlag? "Served Customer is required":""
                  }
                </span> */}
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <FormControl fullWidth  >
                <TextField id="outlined-basic"  name="customerName" label="Served Customer"
                className={customerNameFlag?classes.input : classes.input1}
                 variant="outlined" onChange={changeHandler}
                     value={assetState.customerName} style={{color:"#000"}}/>
                   
                </FormControl>
                <br />
                <span className={classes.span}>
                  {
                    customerNameFlag? "Customer Name is required":""
                  }
                </span>
              </Grid> */}
              <br />
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    name="driverName"
                    label="Driver Name"
                    className={driverNameFlag ? classes.input : classes.input1}
                    variant="outlined"
                    onChange={changeHandler}
                    value={assetState.driverName}
                    style={{ color: "#000" }}
                  />
                </FormControl>
                {/* <br />
                <span className={classes.span}>
                  {driverNameFlag ? "Driver Name is required" : ""}
                </span> */}
              </Grid>
              <br />
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
}