import { Grid } from '@material-ui/core';
import { Stack } from '@mui/material';
import React from 'react';
import Plot from 'react-plotly.js'

const AssetsOverview = (props: any) => {

    const Headingstyle: any = {
        textAlign:'left',
        marginLeft:'1rem',
        paddingTop: '1.5rem',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: '14px',
        color: "#000000DE"
    }

    return (
        <div style={{height:'470px'}}>
            <div style={Headingstyle}>Assets Overview</div>
            {props.noData !== '' ? <div style={{paddingTop:'30px'}}>{props.noData}</div> :
            <Grid container spacing={2}>
                    <Grid item xs={12}>
                        
           
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <div>
                        <Plot
                    data={[
                        {
                            type: "sunburst",
                            labels: ["Total Assets", "Cooler Boxes", "Cooler Boxes - Assigned", "Cooler Boxes - Idle", "Cooler Boxes - Maintenance", "Reefer Trucks", "Reefer Trucks - Assigned", "Reefer Trucks - Idle", "Reefer Trucks - Maintenance"],
                            parents: ["", "Total Assets", "Cooler Boxes", "Cooler Boxes", "Cooler Boxes", "Total Assets", "Reefer Trucks", "Reefer Trucks", "Reefer Trucks"],
                            values: props.finalAsset,
                            branchvalues: 'total',
                            textinfo: 'none',
                            rotation: 90,
                            showlegend: true,
                            marker: {
                               

                                line: {
                                    width: 20,
                                   


                                },
                              
                                colors: ["#FFFFFF", "#E31C79", "#EF60A3", "#FF88BA", "#ECB3CB", "#006BA6", "#0092BD", "#5BC2E7", "#96DDF7"]
                            }
                        }
                    ]}
                    layout={{
                        margin: { l: 20, r: 0, b: 0, t: 10 },
                        
                    }}
                    style={{ maxWidth: '800px', maxHeight: '400px' }}
                    config={{ displayModeBar: false, responsive: true, displaylogo: false }}
                />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                    <Stack direction="column" spacing={1} style={{marginLeft:'2.5rem'}}>
                        {props.legend.map((data: any, index: any) => {
                            return (
                                <div style={{width: '100px', display: 'inline-block'}}>
 
                                <Stack direction="row" spacing={1}>
                                <div style={{ backgroundColor: props.activeColor[index], width: '12px', height: '12px', marginTop:'5px' }} ></div>
                                <div style={{ backgroundColor: props.reeferColor[index], width: '12px', height: '12px',  marginTop:'5px' }} ></div> 
                                <div style={{  color: 'black' , width: '12px', height: '12px',}}>{data}</div>
                              </Stack>
                 
                              </div>
                               
                            )
                        })}
                          
                    </Stack>
                        <div style={{width: '300px',marginTop:'15rem',marginLeft:'3rem'}}>
                            <Stack direction="row" spacing={1}>
                                <div style={{ backgroundColor: '#E31C79', width: '30px', height: '15px', marginTop:'3px' }} ></div>  <span style={{color:'black'}}>Cooler Box</span>   
                              </Stack>
                              <Stack direction="row" spacing={1}>
                                <div style={{ backgroundColor: "#006BA6", width: '30px', height: '15px', marginTop:'5px' }} ></div>  <span style={{color:'black'}}>Reefer Truck</span>
                              </Stack>
                        </div>
                    </Grid>
                   
                </Grid>
          

                </Grid>
                        </Grid>
}

        </div>
    )
}

export default AssetsOverview