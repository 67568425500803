import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import axios from "axios";
import { Formik } from "formik";
import  SelectField from '../common/FormFields/SelectField';
import ShipperHelper, { ShippingRequestStatus } from '../../../services/_helpers';
import { assetBaseURL, marketBaseURL } from "../../../services/_url";
import UserService, { ROLE } from '../../../services/userService';
import ShipperAPI from "../../../services/shippingLanding";
import coldchainLog from "../../../services/consoleHelper";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      fontSize:'20px',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1.3),
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    chipCSS:{
      color: '#D4ECF2',
      backgroundColor: '#E5E5E5',
      marginLeft:'10px',
      "&:hover": {
      color: '#1A0DAB',
      backgroundColor: '#E5E5E5'  
      }
    },
    chip:{
      marginLeft:'10px',
    }
  }),
);

interface AllocationProps{
  shippingId : string,
  status ?: string,
  setFetchTableFlag ?: any
  expectedToPckUpTime:any
  handleMessage:any
}

export default function AssetAllocationForm(props:AllocationProps) {
  let {shippingId, status, setFetchTableFlag, expectedToPckUpTime, handleMessage} = props
  
  const[ filterAssets,setFinalAssert] =useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const[chipStyle,setChipStyle]=useState("Both");
  const [assert,SetAssert]=useState([]);
  const userDetails = (new UserService()).getUserDetails();
  const [role,setRole] = useState("");
  const [transporterData,setTransporterData]=useState([]);
  const [acceptDisable, setAcceptDisable] = useState(false)
  
  useEffect(()=>{
    const shipperService = new ShipperAPI();
    shipperService.getShipperID()
  //axios.get(`${tenantandUser}/tenants`)  
  .then((res:any) => {  
    setTransporterData(res);
  })
    axios.get(assetBaseURL + "/assets", 
    )  
    .then((res:any) => {  
      SetAssert(res.data);
      setFinalAssert(res.data);
    })
    if(userDetails?.token) {
      if(userDetails?.roles?.includes(ROLE.denso)) {
        setRole(ROLE.denso);
      }
    }
  },[])
  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen() {
    let picktime  =new Date(expectedToPckUpTime);    
    let todayTime=new Date();
    if(picktime > todayTime ) {
      if( ShippingRequestStatus[status] === ShippingRequestStatus.PendingAcceptance){
      setAcceptDisable(true)
      setSubmitionCompleted(false);
      setOpen(true);
    }
  }
    else{
      setAcceptDisable(false)
      handleMessage()
      // setSubmitionCompleted(false);
      // setOpen(true);
    }
  }
  useEffect(() => {
 
    let picktime  =new Date(expectedToPckUpTime);    
    let todayTime=new Date();
    if(picktime > todayTime && ShippingRequestStatus[status] === ShippingRequestStatus.PendingAcceptance){
      setAcceptDisable(true)
    
  }
    else{
      setAcceptDisable(false)
    }
  },[acceptDisable])
  
  const handleClick = (item: string) => {
   
    if(item !=="Both"){
      setFinalAssert(assert.filter( val => val.assetType.name === item));
    }else{
      setFinalAssert(assert);

    }
    setChipStyle(item);
   
  };
    
    let result = filterAssets.map((val:any) => {
     
      let test= {key:"",value:""}
      test.key = val.id;
      test.value = val.number
    });
    let transporterResult = transporterData.map((val:any) => {
      let test= {key:"",value:""}
      test.key = val.id;
      test.value = val.name
    });
    
    
   const getAssetId: any = (assertNumber:string ) => assert.filter((val: any) => val.number === assertNumber)
   const getTransporterId:any =(name:any) => transporterData.filter((val: any) => val.name === name);
   

coldchainLog("user",userDetails )
  return (
    <React.Fragment>
      {
        (ShippingRequestStatus[status] === ShippingRequestStatus.Completed || ShippingRequestStatus[status] === ShippingRequestStatus.Cancelled) ? " " :
        (acceptDisable === true && ShippingRequestStatus[status] === ShippingRequestStatus.PendingAcceptance) ?
        <Button  onClick={handleClickOpen} data-testid="acceptButton"  id="statusBtn">
          ACCEPT
        </Button>
        :
        <Button  data-testid="acceptButtonDis"  id="statusBtn" style={{color:'#DCDCDC'}} disabled>
          ACCEPT
        </Button>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="form-dialog-title"
        aria-labelledby="customized-dialog-title"
        fullWidth
        style={{height:'100%'}}
      >
        {/* {!isSubmitionCompleted && ( */}
          <React.Fragment>
           
            <DialogTitle >Asset and Driver Details</DialogTitle>  
            <DialogContent dividers>
           
             <p style={{fontSize:"14px",marginTop:"2rem"}} className="cardTitle">Asset Details</p>
             <Formik
                initialValues={{ assetDetails:"", drivername: "", drivermobile : classes.chip,transporter:""}}
                onSubmit={(values, { setSubmitting }) => {
                  
                setSubmitting(true);
                setSubmitionCompleted(true);
                  
                let apiAssetId =  getAssetId(values.assetDetails)[0].id;
                let allocatDate= new ShipperHelper();
                let TransporterID = getTransporterId(values.transporter)[0]?.id;
                let data = {
                    
                    "shippingRequestId": shippingId,
                    "assetId":apiAssetId,
                    "status": allocatDate.getStatusKeyByValue(ShippingRequestStatus.Accepted),
                    "driverDetails": {
                      "name": values.drivername,
                      "phoneNumber": "+91"+values.drivermobile
                    },
                    "transporterId":TransporterID,
                    "audit": [
                      {
                        "action": "Created",

                        "userId": userDetails?.userId,

                        "timestamp": allocatDate.dateToUTC(new Date())
                      }
                    ]
                  }

                  axios
                    .post(marketBaseURL+'/marketplace/trips', data)
                    .then(resp => {
                      setFetchTableFlag(true);
                      setOpen(false);
                      setSubmitionCompleted(true);
                    });
                
                }}
                validationSchema={Yup.object().shape({
                  assetDetails: Yup.string().required("Required"),
                  drivername: Yup.string().required("Required"),
                  drivermobile :Yup.string()
    .required("Mobile Number is required")
    .matches(RegExp(/^(\+\d?)?\d{10}$/), "Required"),
                  
                  
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
             {role? <SelectField 
                        name="transporter"
                        label="Transporter"
                        data-testid='transportername'
                       options={transporterResult}
                      
                        fullWidth
                        />
                        :""}
                        <br/>
           
            <div>
                <Chip size="small" 
                 label="Reefer Trucks"
                 data-testid="chipReefer"
                 onClick={() => handleClick("Reefer Truck")} 
                 className={ chipStyle ==="Reefer Truck"? classes.chipCSS :classes.chip}
              />
                <Chip size="small"  
                label="Cooler Boxes" 
                data-testid="chipCooler"
                onClick={() => handleClick("Active Cooler Box")} 
                className={ chipStyle ==="Active Cooler Box"? classes.chipCSS : classes.chip}
                />
                <Chip size="small" 
                 label="Both"
                 data-testid="chipBoth"
                 onClick={() => handleClick("Both")} 
                 className={ chipStyle ==="Both"? classes.chipCSS : classes.chip}
                 />
            </div>
            <br />    
            
                     
                    <SelectField 
                        name="assetDetails"
                        label="Asset Name"
                        options={result}
                        data-testid='assetdetails'
                        fullWidth
                        />      
                               {errors.assetDetails && touched.assetDetails && <div className={'validation-message'}>{errors.assetDetails}</div>}
<br />
                        <p className="cardTitle">Driver Details</p>
                      <TextField
                        
                        label=" Driver Name"
                        name="drivername"
                        className="drivername"
                        data-testid="driverName"
                        value={values.drivername}
                        onChange={handleChange}
                        onBlur={handleBlur}
                       
                        
                        variant="outlined"
                        
                        style={{width:"100%"}}
                        size="medium"
                      />
                      {errors.drivername && touched.drivername && <div className={'validation-message'}>{errors.drivername}</div>}<br/><br />
                      <TextField
                      type="number"

                        label="Mobile Number"
                        name="drivermobile"
                        className="drivermobile"
                        data-testid="driverNumber"
                        value={values.drivermobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+91-</InputAdornment>,
                        }}
                        onInput={(e:any)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}
                        variant="outlined"
                     
                        style={{width:"100%",}}
                        size="medium"
                      />
                      {errors.drivermobile && touched.drivermobile && <div className={'validation-message'}>{errors.drivermobile}</div>}
                      <br/>
                        
                      <DialogActions>
                        <Button
                          type="button"
                          className="outline"
                      
                          onClick={handleClose}
                         
                        >
                          CANCEL
                        </Button>
                        <Button 
                        type="submit" 
                        variant='outlined'
                        disabled={isSubmitting}
                        data-testid="assignSubmit"
                        style={{color:'#1A0DAB',border:'2px solid #1A0DAB'}}
                        >
                          ASSIGN AND ACCEPT
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        {/* )} */}
        
      </Dialog>
    </React.Fragment>
  );
}



