import * as React from 'react';
import {Popover , Tooltip} from '@mui/material';
import Button from '@material-ui/core/Button';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import { createStyles, makeStyles,  Theme,  IconButton } from "@material-ui/core";
import { TableManageColumnsFormData } from "../../Dashboard/Common/Interfaces/custom";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Badge from '@mui/material/Badge';
import coldchainLog from '../../../../services/consoleHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: {
          flexGrow: 1,
          fontSize: '12pt',
      },
      container: { },
      header: {
          fontSize: '1.25rem',
          padding: '0.75rem 1.5rem',
          borderBottom: '1px solid #c1c1c1'
      },
      content: {
          padding: '0.75rem 1.5rem',
          overflowX: 'hidden',
          maxHeight: '55vh',
          overflowY: 'auto'
      },
      footer: {
          padding: '0.75rem 1.5rem',
          boxShadow: '0px 3px 11px #c1c1c1'
      },
      filterLabel: {
          fontSize: '1rem',
          letterSpacing: '1px'
      },
      filterField: {},
      filterFieldFormControl: {
        margin: '8px 0px',
        width: '32rem',
        height: '4rem'
      },
      filterSelectFormControl: {
        margin: '8px 0px',
        width: '32rem',
        height: '4rem'
      },
      floatRight: {
          float: 'right'
      },
      clearFilterButtonMargin: {
          marginRight: '1rem'
      },
      columnName: {
          display: 'block',
          position: 'relative',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          height: '3rem',
          lineHeight: '3rem',
          paddingLeft: '0.5rem',
          borderBottom: '1px solid #eee',
          transition: 'color 0.5s ease-in',
          
      },
      moveUpButton: {
          position: 'absolute',
          right: 0,
          padding: '0.5rem'
      },
      moveDownButton: {
          position: 'absolute',
          right: '3rem',
          padding: '0.5rem'
      }
  })
)

export interface ITableManageColumnsProps {
  setManageColumnsOptions: any,
  manageColumnsOptions: TableManageColumnsFormData[],
}

export default function BasicPopover(props: ITableManageColumnsProps) {
  const { setManageColumnsOptions, manageColumnsOptions } = props;
  const [reOrder,setReOrder] = React.useState(JSON.parse(sessionStorage.ManageColumnOrder));
  const [lastSelect, setLastSelect] = React.useState('');
  const [lastSelectIndex, setLastSelectIndex] = React.useState(0);
  const [lastApply, setLastApply] = React.useState(manageColumnsOptions ? manageColumnsOptions : []);
  var lastCol: TableManageColumnsFormData[]=[];
  const [columns, setColumns] = React.useState(manageColumnsOptions ? manageColumnsOptions : []);  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const classes = useStyles();
  const reorderColumns = (cols: any) => {
    let tempCols = [...cols];
    return tempCols.sort((a: TableManageColumnsFormData, b: TableManageColumnsFormData) => a.order - b.order);
}



  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = (event: any) => {
   
    handleClose();
}
const handleResetToDefault = (event: any) => {
    let tempCols = [...columns].sort((a,b) => a.defaultOrder - b.defaultOrder).map((col, index) => {
        col.order = index + 1;
        return col;
    });
    sessionStorage.setItem("ManageColumn", JSON.stringify(tempCols))
    sessionStorage.setItem("ManageColumnOrder", "false")
    setManageColumnsOptions(tempCols);
    setReOrder(false);
    handleClose();
}
const handleApply = (event: any) => {
    const formData: TableManageColumnsFormData[] = [...columns];
    sessionStorage.setItem("ManageColumn", JSON.stringify(formData))
    sessionStorage.setItem("ManageColumnOrder", "true")
    setManageColumnsOptions(formData);
    setLastApply(formData);
    lastCol.concat(formData);
    coldchainLog("APPLY",formData,lastApply,lastCol);
    
    handleClose();
    setReOrder(true);
}
const reorderUp = (index: number) => {
    setLastSelectIndex(index)
    let tempCols = reorderColumns(columns);
    tempCols[index-1].order = tempCols[index-1].order + 1;
    // debugger
     tempCols[index].order = tempCols[index].order - 1;
    coldchainLog("indexUp",manageColumnsOptions);

         setColumns(tempCols);
        // setLastSelect("up");
        // coldchainLog('reorder up');
}
const reorderDown = (index: number) => {
    setLastSelectIndex(index);
    coldchainLog("indexDown",index,lastCol)
    let tempCols = reorderColumns(columns);
    // tempCols.sort((a: any, b: any) => a.order - b.order);
    tempCols[index+1].order = tempCols[index+1].order - 1;
    tempCols[index].order = tempCols[index].order + 1;
    setColumns(tempCols);
    setLastSelect("down")
    coldchainLog('reorder down');
}

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
     <span onClick={handleClick}>
     <Badge color="secondary" variant="dot" invisible={!reOrder}>
      <Tooltip title="Column Reorder" data-testid='column reorder' placement="bottom">
        <ViewColumnOutlinedIcon />
      </Tooltip>
    </Badge>
     
    </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: { width: '35rem' }
        }}
      >
       <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.header}>
                    Column Reorder
                </div>
                <div className={classes.content}>
                    {reorderColumns(columns).map((col: TableManageColumnsFormData, index: number) => {
                        return (
                            <div className={classes.columnName}>
                            {col.title}
                                <IconButton disabled={index === 0 ? true : null} data-testid={`reorder-column-up-${index}`} onClick={() => reorderUp(index)} className={classes.moveUpButton} color="primary" aria-label="Reorder Above" component="span">
                                    <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton disabled={index === columns.length - 1 ? true : null} data-testid={`reorder-column-down-${index}`} onClick={() => reorderDown(index)} className={classes.moveDownButton} color="primary" aria-label="Reorder Below" component="span">
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </div>
                        )
                    })}
                </div>
                <div className={classes.footer}>
                <Button data-testid="manage-column-cancel" onClick={handleCancel}>CANCEL</Button>
                    <div className={classes.floatRight}>
                        <Button 
                            color="primary"
                            data-testid="manage-column-reset"
                            className={classes.clearFilterButtonMargin}
                            onClick={handleResetToDefault}
                        >RESET TO DEFAULT</Button>
                        <Button
                            color="primary"
                            data-testid="manage-column-apply"
                            variant="outlined"
                          onClick={handleApply}
                        >APPLY</Button> 
                     </div>
                </div>
            </div>
        </div>
      </Popover>
    </div>
  );
}
