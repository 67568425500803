import moment from 'moment';
import * as Yup from 'yup';
import assetForm from '../FormAsset/assetForm';
const {
  
    formField: {
      assettype,
      assetname,
      status,
      assettyp,
      locality,
      city,
      zone,
      region,
      hname,
      hid,
      gid,
    }
  } = assetForm;
  export default [
    Yup.object().shape({
        [assettype.name]:Yup.string()
        .required(`${assettype.requiredErrorMsg}`),
        [assetname.name]:Yup.string()
        .required(`${assetname.requiredErrorMsg}`),
        [status.name]:Yup.string()
       
        .required(`${status.requiredErrorMsg}`),
        [assettyp.name]:Yup.string()
        .required(`${assettyp.requiredErrorMsg}`),
        // [assetid.name]:Yup.string()
        // .required(`${assetid.requiredErrorMsg}`),
    }),
    Yup.object().shape({
        [locality.name]:Yup.string()
        
        .required(`${locality.requiredErrorMsg}`),
        [city.name]:Yup.string()
        .required(`${city.requiredErrorMsg}`),
        [zone.name]:Yup.string()
        .required(`${zone.requiredErrorMsg}`),
        [region.name]:Yup.string()
        .required(`${region.requiredErrorMsg}`),
    }),
    Yup.object().shape({
        [hname.name]:Yup.string()
        .required(`${hname.requiredErrorMsg}`),
        [hid.name]:Yup.string()
        .required(`${hid.requiredErrorMsg}`),
        [gid.name]:Yup.string()
        .required(`${gid.requiredErrorMsg}`)
    }),


  ];