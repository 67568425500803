import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { Grid, MenuItem, Select } from "@material-ui/core";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import MuiTableCell from "@material-ui/core/TableCell";
import BubbleMap from "../../../Utils/map";
import Button from "@material-ui/core";
import { useEffect, useState } from "react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import axios from "axios";
import { assetBaseURL, telemetry } from "../../../../../services/_url";
import ShipperHelper from "../../../../../services/_helpers";
import coldchainLog from "../../../../../services/consoleHelper";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Auto from '../../../../../assets/images/Auto.svg'
import hublocation from '../../../../../assets/images/hublocation.svg';
import EV_Fill from '../../../../../assets/images/EV_Fill.svg';
import Vtype from "../../../../../assets/images/Vtype.svg"
import charge from "../../../../../assets/images/charge.svg"
import Fuel from "../../../../../assets/images/Fuel.svg"
import Power from "../../../../../assets/images/Power.svg"
import add from "../../../../../assets/images/add.svg"
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { promises } from "fs";
import TelemeteryAPI from "../../../../../services/telemeteryService";
import UserService from "../../../../../services/userService";
import { idText } from "typescript";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },

})(MuiTableCell);
const telemeteryService = new TelemeteryAPI();
const userApi = new UserService();

export default function Details(props: any) {

  const [hardwareKits, setHardwareKits] = useState([]);
  const [latLangData, setLatLangData] = useState([]);
  const [fleetLatLangData, setFleetLatLangData] = useState([]);
  const [legents, setLegents] = useState<any>({TwoWheeler: true, ThreeWheeler: true, FourWheeler: true, EV: false, ICE: false, ChargeStation: false,HubLocation: false});
  const [lastLocation, setLastLocation] = useState("");
  const [lastRecordedAt, setRecorded] = useState("");
  const [batterySoc, setBatterySoc] = useState("");
  let dateHelper = new ShipperHelper();
  const [latArr, setLatArr] = useState('');
  const [longArr, setLongArr] = useState('');
  const [nearestSwapStations, setNearestSwapStations] = useState([])
  const [latLngTruck, setLatLngTruck] = useState([])
  const [latLngTwoWheeler, setLatLngTTwoWheeler] = useState([])
  const [distanceSwapStation, setDistanceNearestSwap] = useState("")
  const [dDialog, setDDialog] = useState(false);
  const [powerDialog, setPowerDialog] = useState(false);
  const [statusFlag, setStatusFlag] = useState(false);
  const [fleetAllLocationsData, SetfleetAllLocationsData] = useState([])
  const [threeWheeler, SetThreeWheeler] = useState([])
  const userDetails = userApi.getUserDetails();
 const [text, setText] = useState(<p style={{color:"grey",fontSize:"15px",marginRight:"19px"}}>Vehicle Type</p>)
 const [textPower, setPower] = useState(<p style={{color:"grey",fontSize:"15px",marginRight:"21px",marginTop:"-23px"}}>Power Train</p>)
  const [icon, Seticon] = useState(<AddOutlinedIcon style={{color:"grey",height:"21px"}}/>)
  const [powerIcon, SetPowericon] = useState(<AddOutlinedIcon style={{color:"grey",height:"21px"}}/>)

  const hublocationarr = [{lat:12.951323197587199,lng :77.57658877002318,name:'Amazon flakes',address: 'More Supermarket, 45, Vasavi Temple St, Parvathipuram, Vishweshwarapura, Shankarapura, Bengaluru, Karnataka 560004'},{lat:12.915697181278926, lng:77.56774514317608,name:'Amazon flakes',address:'UBL6, 40th, Peenya, Yeswanthpur, Bengaluru, Karnataka 560022, India'},{lat:13.023426395575942,lng:77.54326553450292,name:'Gourmet Garden',address:'115/1, 5th Cross Rd, Sripathy Layout, Teachers Colony, Banashankari, Bengaluru, Karnataka 560070'},{lat:12.921575485413436, lng:77.61381406774797,name:'Amazon',address:'63/A, 13th Cross Rd, Dollar Layout, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560092'},{lat:12.907467591110981, lng:77.59795835780915,name:'Amazon',address:'#3, Maruthi Nagar Main Rd, Old Madiwala, Chikka Madivala, 1st Stage, BTM 1st Stage, Bengaluru, Karnataka 560068'}]
console.log(props.assetID,"id");

  useEffect(() => {
    if(props.assetID){
    axios
      .get(`${telemetry}/telemetry/intellicar/assetlocation?tenantId=${props.assetID}&assetId=ALL&srvCst=1&drv=1`)
      .then((res: any) => {
        if(props.propsValue === 0){
          setLatLangData(res.data.swapStations.threeWheeler);
          setNearestSwapStations(res.data.response);

        }
        else {
          setLatLangData(res.data.swapStations.twoWheeler);
          setNearestSwapStations(res.data.response);
        }
        let distanceSwapStation;
        if (props.assetID) {
          let filterSwapStation = res.data.response.filter((r: any) => r.assetId === props.assetID);
          if (filterSwapStation[0].nearestSwapStation) {
            //console.log(Number(filterSwapStation[0].nearestSwapStation.distance).toFixed(2));
            distanceSwapStation = `${Number(filterSwapStation[0].nearestSwapStation.distance).toFixed(2)} Mt`
          }
        } else {
          let filterSwapStation = res.data.response.filter((r: any) => r.assetId === "03a15a6b-d81a-4fcf-b470-409434b6d167");
          if (filterSwapStation[0].nearestSwapStation) {
            //console.log(Number(filterSwapStation[0].nearestSwapStation.distance).toFixed(2));
            distanceSwapStation = `${Number(filterSwapStation[0].nearestSwapStation.distance).toFixed(2)} Mt`
          }
        }
        setDistanceNearestSwap(distanceSwapStation);
      })
      .catch((err: any) => {
        //
      });
    axios.get(`${telemetry}/telemetry/batteryData?assetId=${props.assetID}&&from=${moment().format("YYYY-MM-DD")}T02:06:21.939Z&to=${moment().format("YYYY-MM-DD")}T23:59:21.939Z`)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.length) {
            setBatterySoc(res.data[0].soc);
            //console.log(res.data[0].soc, 'soc');
          }
        }
      })
      .catch((err: any) => {
        //
      });
    }
  }, [props.filter, props.fromAndTo, props.assetID, props.gatewayId]);

  // useEffect(() => {
  //   if (props.topBarValue === 1) {

  //     if (props.fleetData !== undefined && Object.keys(props.fleetData).length > 0) {
  //       const result = locationApi();
  //     }
  //   }
  // }, [props.topBarValue, props.fleetData]);
  useEffect(() => {
      if(props.fleetFlag && userDetails.roleName !== "LithPwr"){
        locationApi();
      }
  }, [props.fleetFlag, userDetails.roleName]);

  useEffect(() => {
    const tmp: any = [latLangData];
    const tmp1: any = [fleetLatLangData];
    const tmp2: any = [legents];
    // console.log(tmp1, "legents")
  })

/**
 * Currently fetching the 2w and 4w data from java api
 * 2w, 3w, 4w data's are stored in their respective state variables
 */
  const locationApi = async () => {
    // let fleetAllLocationsData = new Map();
    // const twoWheelerResult = await axios.get(
    //   `${assetBaseURL}/assets/type?type=2W_EV&page=0&size=100`
    // )
    // console.log(twoWheelerResult, 'twoWheelerResult')
    // const assetResult = await axios.get(
    //   `${assetBaseURL}/assets/type?type=Reefer Truck&page=0&size=100`
    // )

    const fourWheelerList = ["KA52A9791","KA52A9841","KA52A9792","KA52A9689","KA52A9690","KA52A9790"]
    const threeWheelerList = ["DEV1","DEV2","DEV3","DEV4","DEV5"]

    const twoWheelerList = [ "KA05LJ2325","KA05LJ2363","KA05LJ2337","KA05LJ2352","KA05LJ2342","KA05LJ2332","KA05LJ2366","KA05LJ2359","KA05LJ2330","KA05LJ2365",]
   
    const towlatAndLongResult = await Promise.all(twoWheelerList.map(async (ele: any) => {
      let result: any = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/location?vehicle_number=${ele}&no_of_days=1`)
     return {
      latitude: result?.data?.data[1]?.location?.lat,
      longitude: result?.data?.data[1]?.location?.lng,
      ...result?.data?.data[1]
     }
   }));
   setLatLngTTwoWheeler(towlatAndLongResult)
    // const latAndLongResult = await Promise.all(fourWheelerList.map(async (ele: any) => {
    //   let result = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck?vehicle_number=${ele}`)
    //   return {
    //     latitude: result?.data?.data?.data?.lat,
    //     longitude: result?.data?.data?.data?.lng,
    //     number: ele,
    //     ...result?.data?.data?.data
    //    }
    // }));
       const allAssets = await axios.get(`${telemetry}/telemetry/intellicar/assetlocation?assetId=ALL&srvCst=1&drv=1&is4wice=1`).then((res: any) => {
        return res.data.response || [];
      }).catch((err: any) => {
        console.log(err);
      })

      const fourWheelerAssets:any = allAssets.reduce((acc: any, asset: any) => {
        if (asset.assetTypeName === "Reefer Truck") {
          acc.push({
            latitude: asset?.data?.lat,
            longitude: asset?.data?.lng,
            number: asset?.vehicleNum,
            ...asset?.data
          });
        }
        return acc;
      }, []);

      const threeWheelerAssets:any = allAssets.reduce((acc: any, asset: any) => {
        if (asset.assetTypeName === "3W_EV") {
          acc.push({
            latitude: asset?.data?.lat,
            longitude: asset?.data?.lng,
            number: asset?.vehicleNum,
            ...asset?.data
          });
        }
        return acc;
      }, []);



   setLatLngTruck(fourWheelerAssets);
   SetThreeWheeler(threeWheelerAssets);

  //  3W_EV

//    const threeWheelerResult = await Promise.all(threeWheelerList.map(async (ele: any) => {
//     let result = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck?vehicle_number=${ele}`)
//    return {
//      latitude: result?.data?.data?.data?.lat,
//      longitude: result?.data?.data?.data?.lng,
//      vehicleNum: ele,
//      data: { ...result?.data?.data?.data }
//     }
//  }));
//    SetThreeWheeler(threeWheelerResult)
    // props.fleetData.map((element: any, index:number) => {
    // for (let i = 0; i < props.fleetData.length; i++) {
    //   let res = await axios.get(`${telemetry}/telemetry/intellicar/assetlocation?tenantId=${props.fleetData[i].assetId}&assetId=ALL`);
    //   console.log(res, "result")
    //   let threeWheeler = [...res.data.swapStations.threeWheeler];
    //   let twoWheeler = [...res.data.swapStations.twoWheeler];
    //   console.log(threeWheeler, "three")
    //   console.log(twoWheeler, "two")
    //   if (props.propsValue === 0) {
    //     fleetAllLocationsData.set(i, res.data.swapStations.threeWheeler);
    //     SetThreeWheeler(res.data.swapStations.threeWheeler)
    //   }
    //   else {
    //     fleetAllLocationsData.set(i, res.data.swapStations.twoWheeler);
    //     SetTwoWheeler(res.data.swapStations.twoWheeler)

    //   }
    // }
    // if (fleetAllLocationsData.size > 0) {
    //   const temp = [];
    //   temp.push(fleetAllLocationsData);
    //   setFleetLatLangData(temp);
    // }

  };
  
  const checkboxHandlerCity = async (e: any, val: string) => {

    // let fleetAllLocationsData = new Map();

  }

  const hadleClickLegent = (type: string) => {
    const orginalValue = { ...legents };
    orginalValue[type] = !orginalValue[type]
    setLegents(orginalValue)
  }
  const showDropdown = (icon: any) => {
    // Seticon((icon) => icon === <RemoveOutlinedIcon /> ? <AddOutlinedIcon /> : <RemoveOutlinedIcon />);

    // document.getElementById("myDropdown").classList.toggle("show");
    if (dDialog) {
      setDDialog(false);
      Seticon(<AddOutlinedIcon style={{color:"grey",height:"21px"}}/>)
      setText(<p style={{color:"grey",fontSize:"15px",marginRight:"19px"}}>Vehicle Type</p>)
      
    } else {
      setDDialog(true);
      Seticon(<RemoveOutlinedIcon style={{color:"grey",height:"21px"}} />)
      setText(<p style={{color:"black",marginRight:"19px",fontSize:"15px"}}>Vehicle Type</p>)
    }
  }

  const showPowerDropdown = (icon: any) => {
    // Seticon((icon) => icon === <RemoveOutlinedIcon /> ? <AddOutlinedIcon /> : <RemoveOutlinedIcon />);

    // document.getElementById("myDropdown").classList.toggle("show");
    if (powerDialog) {
      setPowerDialog(false);
      SetPowericon(<AddOutlinedIcon style={{color:"grey",height:"21px"}}/>)
      setPower(<p style={{color:"grey",fontSize:"15px",marginRight:"21px",marginTop:"-23px"}}>Power Train</p>)
      
    } else {
      setPowerDialog(true);
      SetPowericon(<RemoveOutlinedIcon style={{color:"grey",height:"21px"}}/>)
      setPower(<p style={{color:"black",marginRight:"21px",fontSize:"15px",marginTop:"-23px"}}>Power Train</p>)
    }
  }

  coldchainLog("assetparos", props);

  return (
    <div style={{ marginTop: "10px" }}>
      <Grid container spacing={1}>
        {!props.fleetFlag && <Grid item xs={12} sm={6} md={4} lg={2}>
          <Table size="small">
            <TableRow>
              <TableCell
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",
                  fontWeight: 500,
                }}
              >
                Locality
              </TableCell>
            </TableRow>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {props.data.locality ? props.data.locality : "---"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        }
        {!props.fleetFlag &&
          <Grid item xs={12} sm={6} md={2} lg={1}>
            <Table size="small">
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                  }}
                >
                  ZONE
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {props.data.zone ? props.data.zone : "----"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        }

        {!props.fleetFlag &&
          <Grid item xs={12} sm={6} md={2} lg={1}>
            <Table size="small">
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                  }}
                >
                  CITY
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {props.data.city ? props.data.city : "---"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        }
        {!props.fleetFlag &&
          <Grid item xs={12} sm={6} md={2} lg={1}>
            <Table size="small">
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                  }}
                >
                  REGION
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {props.data.region ? props.data.region : "---"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        }

        {!props.fleetFlag &&
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Table size="small">
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                  }}
                >
                  LAST KNOWN LOCATION
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {lastLocation ? lastLocation : "---"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        }

        {!props.fleetFlag &&
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Table size="small">
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                  }}
                >
                  LAST RECORDED AT
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {lastRecordedAt
                      ? moment(new Date(lastRecordedAt)).format('MMMM Do YYYY, h:mm a')
                      : "---"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        }

        {!props.fleetFlag &&
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Table size="small">
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <EditOutlinedIcon
                      style={{ color: "#b5b5b5", marginRight: "10px" }}
                    />
                    {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> */}
                    <DeleteOutlineOutlinedIcon style={{ color: "#b5b5b5" }} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        }

        {!props.fleetFlag &&
          <Grid item xs={12} sm={4} md={4} lg={1}>
            <span style={{ float: "left", marginLeft: "-3rem" }}>
              {props.data.underMaintenance ? (
                <span id="statusOrange">Maintenance</span>
              ) : props.data.active ? (
                <span id="statusGreen">Active</span>
              ) : (
                <span id="statusRed">In Active</span>
              )}
            </span>
            <br />
          </Grid>
        }
        {(hardwareKits && hardwareKits.length > 0) || (props.overviewData && props.overviewData.length > 0) ?
          <Grid item xs={12} sm={12} lg={12}>
            {props.topBarValue === 1 && <div className="hoverDiv1" style={{ marginTop: "3.7rem", marginRight:"-25px", height: "auto" }}>

              <div style={{ marginBottom: "1rem" }}>
                {/* <p  style={{fontWeight: legents && legents?.Auto ? 'bold' : 'normal'}} onClick={() => hadleClickLegent('Auto')}><img alt="img1" src={EV_Fill} className="hoverImg1" /> Swap stations</p> */}
                {/* <p  style={{fontWeight: legents && legents?.Auto ? 'bold' : 'normal'}}><img alt="img1" src={Vtype} className="hoverImg1" />Vehicle Type
             
                <img alt="img1" src={add} style={{marginRight:"-20px"}} /></p>
               <p  style={{fontWeight: legents && legents?.Auto ? 'bold' : 'normal'}} ><img alt="img1" src={Power} className="hoverImg1" />Power Train<img alt="img1" src={add} className="hoverImg2" /> </p>
               <p  style={{fontWeight: legents && legents?.Auto ? 'bold' : 'normal'}} ><img alt="img1" src={Fuel} className="hoverImg1" />Fuel Station </p>
               <p  style={{fontWeight: legents && legents?.Auto ? 'bold' : 'normal'}} onClick={() => hadleClickLegent('Auto')}><img alt="img1" src={charge} className="hoverImg1" />Charge Station</p>  */}

                {/* <ul style={{width:"200px"}}  > */}
                <p style={{ fontWeight: legents && legents?.Auto ? 'bold' : 'normal'}} >
                  <img alt="img1" src={Vtype} className="hoverImg1" style={{height:"27px",marginTop:"-2px"}} 
                />
                
                <span >{text}</span><button style={{ border: "none ",
                 backgroundColor: "white", marginRight: "-20px",marginTop:"-43px" }} 
                className="buttonvtype" onClick={() => showDropdown(icon)}>{icon}</button> </p>

                {dDialog ? <div style={{ marginLeft: "75px", width: "100px" }}>


                  <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "-12.6rem" }}>
                    {/* <input className="cbBox" type="checkbox" checked={twoWheeler} onChange={ updatetwoWheeler} 
                            style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "1rem", marginLeft: "5.2rem" }} /> */}
                    <input className="cbBox"  disabled={userDetails.roleName === "LithPwr"} type="checkbox"  checked={legents && legents?.TwoWheeler && userDetails.roleName !== "LithPwr"} onChange={() => hadleClickLegent('TwoWheeler')}
                      style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "1rem", marginLeft: "5.4rem" }} />
                    <span className="cbBox" style={{color:"grey",fontSize:"15px",marginLeft: "1.7px"}}>Two Wheeler</span></div>
                  <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    {/* <input className="cbBox" type="checkbox" checked={threeWheeler} onChange={ updatethreeWheeler}


                            style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "0.9rem", marginLeft: "-6.7rem" }} /> */}
                         
                    <input className="cbBox" type="checkbox" checked={legents && legents?.ThreeWheeler}
                     onChange={() => hadleClickLegent('ThreeWheeler')}
                      style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "0.9rem", marginLeft: "-6.7rem",}} />
                    <span className="cbBox" style={{ marginLeft: "4px",color:"grey",fontSize:"15px" }}>Three Wheeler</span>
                  </div>
                  <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "-12.4rem" }}>
                    {/* <input className="cbBox" type="checkbox" checked={twoWheeler} onChange={ updatetwoWheeler} 
                            style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "1rem", marginLeft: "5.2rem" }} /> */}
                    <input className="cbBox" disabled={userDetails.roleName === "LithPwr"} type="checkbox" checked={legents && legents?.FourWheeler && userDetails.roleName !== "LithPwr"} onChange={() => hadleClickLegent('FourWheeler')}
                      style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "1rem", marginLeft: "5.2rem" }} />
                    <span className="cbBox" style={{ marginLeft: "4px",color:"grey",fontSize:"15px" }}>Four Wheeler</span></div>
                </div> : null}
                <p style={{ fontWeight: legents && legents?.Auto ? 'bold' : 'normal' }} ><img alt="img1" src={Power} style={{height:"27px",
                marginTop:"-2px"}}  className="hoverImg1" />
                  <span style={{marginLeft:"20px"}}>
                    {textPower}</span>
                  <button style={{ border: "none ", backgroundColor: "white", marginRight: "-20px",marginTop:"-40px"}} className="buttonvtype" 
                  onClick={() => showPowerDropdown(powerIcon)}>{powerIcon}</button> </p>
                {powerDialog ? <div style={{ marginLeft: "75px", width: "100px" }}>


                  <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem"}}>
                    {/* <input className="cbBox" type="checkbox" checked={twoWheeler} onChange={ updatetwoWheeler} 
    style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "1rem", marginLeft: "5.2rem" }} /> */}
                    <input className="cbBox" type="checkbox" checked={legents && legents?.ICE} onChange={() => hadleClickLegent('ICE')}
                      style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "1rem",marginLeft:"-180px" }} />
                    <span className="cbBox"  style={{ marginLeft: "2px",color:"grey",fontSize:"15px" }}>ICE</span></div>
                  <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    {/* <input className="cbBox" type="checkbox" checked={threeWheeler} onChange={ updatethreeWheeler}


    style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "0.9rem", marginLeft: "-6.7rem" }} /> */}
                    <input className="cbBox" type="checkbox" checked={legents && legents?.EV} onChange={() => hadleClickLegent('EV')}
                      style={{ height: "1rem", cursor: 'pointer', width: "1rem", marginRight: "0.9rem", marginLeft: "-11.6rem" }} />
                    <span className="cbBox" style={{ marginLeft: "2.6px",color:"grey",fontSize:"15px" }} >EV</span>
                  </div>
                </div> : null}
                {/* onClick={() => hadleClickLegent('Fuel')} */}
                <p style={{ fontWeight: legents && legents?.Fuel ? 'bold' : 'normal'}}>
                  <img alt="img1" style={{height:"27px",marginTop:"-2px"}}src={Fuel} className="hoverImg1" />
                  <span style={{ marginLeft: "-22px",fontSize:"15px",color:"grey" }}>Fuel Station</span></p>

                <p style={{ fontWeight: legents && legents?.ChargeStation ? 'bold' : 'normal', cursor: 'pointer' }} 
                onClick={() => hadleClickLegent('ChargeStation')}><img alt="img1" src={charge} style={{height:"27px",marginTop:"-2px"}} className="hoverImg1" />
                <span style={{fontSize:"15px",color:"grey",marginLeft:"-5px"}}>Charge Station</span></p>

                <p style={{ fontWeight: legents && legents?.HubLocation ? 'bold' : 'normal', cursor: 'pointer' }}
                 onClick={() => hadleClickLegent('HubLocation')}><img alt="img1" src={hublocation} style={{height:"27px",marginTop:"-2px"}} className="hoverImg1" />
                <span style={{fontSize:"15px",color:"grey",marginLeft:"-5px"}}>Hub Location</span></p>

              </div>


            </div>
            }
            <BubbleMap
              latArr={latArr}
              longArr={longArr}
              legents={legents}
              hublocation ={hublocationarr}
              filter={props.filter}
              assetID={props.assetID}
               overviewData={props.overviewData.length ? props.overviewData : threeWheeler}
              // overviewData={threeWheeler}
              gatewayId={hardwareKits && hardwareKits[0]?.gatewayId}
              fromAndTo={props.fromAndTo}
              loadingCircleData={props.loadingCircleData}
              keys={props.keys}
              fleetFlag={props.fleetFlag}
              tagId={hardwareKits && hardwareKits[0]?.tagId}
              topBarValue={props.topBarValue}
              selectedIndex={props.selectedIndex}
              latLngTruck={props.customer === 'ALL' ? latLngTruck : []}
              latLngTwoWheeler={props.customer === 'ALL' ? latLngTwoWheeler : []}
              threeWheeler={props.customer === "ALL" ? threeWheeler : []}
              latLangData={latLangData}
              truckNoData={props.data}
              fleetData={props.fleetData}
              fleetLatLangData={fleetLatLangData}
              batterySoc={batterySoc ? batterySoc : ""}
              nrSwapStDistance={distanceSwapStation ? distanceSwapStation : ""}
              fromComponent={"FO"} // Fleet Overview
            />
          </Grid> : null}
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </div>
  );
}
