import * as React from 'react';
import { useEffect,useRef,useState } from 'react';
import {Dialog, DialogActions ,DialogContent, DialogContentText, DialogTitle, Button, CircularProgress, Divider } from '@mui/material';
import TelemeteryAPI from '../../../../services/telemeteryService';
import source from '../../../../assets/images/source.svg';
import destination from '../../../../assets/images/destination.svg'
import { defaultZoomLevel } from '../../../../services/_helpers';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import MarketPlaceAPI from '../../../../services/marketPlaceService';
import AssetBaseAPI from '../../../../services/assetService';
import filedrop  from '../../../../assets/images/filedrop.svg';
import './style.css';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  
  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 13,
              top: 13,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

export  function BulkUpload (props: any) {
    const history = useHistory();
    const upRef = useRef();
    const textRef = useRef();
    const [selectedFile, SetSelectedFile] = useState("");
  // console.log(props);
  const [open, setOpen] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [cncFlag, setCncFlag] = useState(false);
  const marketApi = new MarketPlaceAPI();
    const assetApi = new AssetBaseAPI();
    const [fileFlag, setFileFlag] = useState(false);
    const [lblFlag, setLblFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.closePopup();
    
  };
  const handleSubmit = () => {
    props.closePopup();
  }

  useEffect(()=>{
    setOpen(props.open);
  },[props.open])
  const loadUpload = () => {
    const current: any = upRef.current;
    if (current) {
        current.click();
    }
}

const fileSelected = async (event: any, data: any) => {
    //var fs = require('fs').promises;

    const current: any = upRef.current;
    if (current) {
        SetSelectedFile(current.files[0].name);
        setCncFlag(false);
    }
   
    // const formData = new FormData();
    // formData.append('file', current.files[0])
    
    //let uploaded = await marketApi.uploadShipmentFile(formData);
    
   
}
const handleCancel = () => {
    SetSelectedFile("");
    

}
const upload = async () => {

    if (selectedFile.length > 0) {
        const current: any = upRef.current;
        const formData = new FormData();
        var file = current.files[0];
        formData.append('file', current.files[0])

        // let uploaded = await marketApi.uploadShipmentFile(formData);
        let uploadURL = await assetApi.getS3UploadURL(current.files[0].name);
    // const resultData = await fetch(`${uploadURL.data.fileUploadURL}`, {
    //         method: 'PUT',
    //         body: formData,
    //         headers: {
    //             'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //         }
    // });
    var xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadURL.data.fileUploadURL, true);
    //xhr.setRequestHeader('Content-Type', 'image/jpeg');
    xhr.onload = () => {
        if (xhr.status === 200) {
        toast.success("uploaded successfully!");
        props.closePopup();
        history.push('/denso')
        setTimeout(() => {
                 setLblFlag(false);
                  setFileFlag(false);
              }, 5000);
        }
    };
    xhr.onerror = () => {
        console.log('Nope')
    };
    xhr.send(file); // `file` is a File object here
    //     if (uploaded.status === "Success") {
    //         toast.success("uploaded successfully!");
    //         history.push('/denso')
    //     }
    //     setFileFlag(true);
    //     setLblFlag(true);

    //     setTimeout(() => {
    //         setLblFlag(false);
    //         setFileFlag(false);
    //     }, 5000);
    // } else {
    //     setCncFlag(true);
    //     setErrorMessage('File is not selected');
    }
}
   
 
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{width:"480px",height:"640px",marginLeft:"550px",marginTop:'50px'}}
      >
        <DialogTitle style={{fontSize:'17px',marginTop:"5px",color:"#000"}}>
        Bulk Upload
      </DialogTitle>
      
      <button style={{  backgroundColor: "white", marginLeft: "88%",marginTop:"-40px", width:"40px",border:"1px solid #ffffff",color:"grey"}} 
    onClick={handleClose}><span style={{marginLeft:"-1px"}}> <CloseIcon /></span></button>
        <Divider />
        <DialogContent >
        <div style={{ padding: "7rem",  }}>
                    {/* <label htmlFor="myFile" style={{ marginLeft: "-1rem", fontWeight: "bolder", fontSize: "15px" }}>Choose File : </label> */}
                    <img src={filedrop} style={{marginLeft: "48px",marginTop:"-88px",width:"70px"}}/>
                    <p style={{marginLeft:"20px",marginTop:"10px",fontWeight:500,color:"#000"}}>Drag and drop file</p>
                    <p  style={{marginLeft:"70px",marginTop:"-13px",fontWeight:500,color:"#000"}}>or</p >
                    <input ref={upRef} id="textF" type="text" value={selectedFile}
                         style={{ marginLeft: '-10px',border:"1px solid #e8e9eb" }} />
                        <Button onClick={loadUpload} variant="outlined" style={{ border:"2px solid #007AA6 ", color: "#007AA6", width: "80px", marginLeft: "40px",marginTop:"1rem",textTransform:"capitalize" }}>Browse</Button>
                    <input id="myFile" ref={upRef} type="file" style={{ visibility: "hidden" }}
                        name="img"
                        onChange={(e) => fileSelected(e, document.getElementById("textF"))} />
                    <div style={{ marginTop: "2rem" }}>
                        {/* {cncFlag && 
                   <p style={{ marginTop: "2rem", color: "red" }}> File is not selected.</p>
} */}
                        {errorMessage && cncFlag && (
                            <p style={{ color: "red", marginLeft: "100px", marginTop: "-40px" }}> {errorMessage} </p>
                        )}
                        <Button onClick={upload} variant="outlined" style={{ backgroundColor: "#0078A5", color: "#fff", width: "80px", marginLeft: "42px",marginTop:"-18px",textTransform:"capitalize" }}>Upload</Button>
                        {/* <Button onClick={handleCancel} variant="outlined" style={{ backgroundColor: "#007AA6", color: "#fff", marginLeft: "190px ", marginTop: "-35px", width: "100px" }}>Cancel</Button> */}
                    </div>
                    {lblFlag &&
                        <p style={{ marginTop: "2rem", color: "blue" }}>File uploaded. Please check the status after some time.</p>
                    }

                </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
