import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Select,
  Grid,
  MenuItem,
  InputBase,
  withStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import "./style.css";
import PickupandDeliverytime from "../Common/Charts/BarCharts/PickupandDeliverytime";
import AcceptedVsNotAccepted from "../Common/Charts/DonutChart/AcceptedVsNotAccepted";
import TopFiveStoppage from "../Common/TopFive/TopFiveStoppage"
import TemperatureExcursion from "../Common/Charts/HeatmapChart/TemperatureExcursion";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DashboardTopFiveTranporter from "../Common/TopFive/TopFiveTransporter";
import DashboardChartAPI from "../../../../services/ChartServices";
import MarketPlaceAPI from '../../../../services/marketPlaceService';
import ShipperHelper from "../../../../services/_helpers";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShockEvents from "../Common/Charts/BarCharts/ShockEvents";
import LightEvents from "../Common/Charts/BarCharts/LightEvents";
import { assetBaseURL, telemetry } from "../../../../services/_url";
import axios from "axios";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import { CircularProgress, Stack } from "@mui/material";
import { createJsxClosingElement } from "typescript";
import TelemeteryAPI from '../../../../services/telemeteryService';
import moment from "moment";
import DriverInformation from "../Common/TopFive/driverInformation";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {},
    },
    input: {
      borderRadius: 2,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const Headingstyle: any = {
  textAlign: "left",
  marginLeft: "1rem",
  // paddingTop: '1rem',
  marginBottom: "1rem",
  fontFamily: "Roboto",
  fontWeight: "normal",
  fontSize: "14px",
  color: "#000000DE",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      minHeight: "15rem",
    },
    option: {

      color: "black !important"

    },
  })
);

export interface IDashboardTransportProps {
  timeDuration: string;
  data: any;
  pdfStatechange: any;
  startAndEnd: any;
  selectedClosedMarket: any;
}

export default function DashboardTransport(props: IDashboardTransportProps) {
  const styles = useStyles();
  const flagvalue = props.data;
  const { timeDuration } = props;
  const classes = useStyles();
  const dateselect = new ShipperHelper();
  const getTransporterAPI = new DashboardChartAPI();
  const [total, setTotal] = useState([]);
  //Top 5 Transporter
  const [top5RowData, settop5RowData] = useState([]);
  const [top5TotalRequest, settop5TotalRequest] = useState(0);
  const [noDataTop5, setNoDataTop5] = useState("");
  const [shockEventsData, setShockEventsData] = useState([]);
  const [shockEventsDataXaxis, setShockEventsDataXaxis] = useState([]);
  const [lightEventsData, setLightEventsData] = useState([]);
  const [lightEventsDataXaxis, setLightEventsDataXaxis] = useState([]);
  const [totals, setTotals] = useState([]);
  const [sid, setSid] = useState([]);

  const marketApi = new MarketPlaceAPI();

  //Accepted Vs NotAccepted
  const [acceptedVsNotAccepted, setAcceptedVsNotAccepted]: any = useState({
    values: [],
    labels: [],
    hoverinfo: "label+text+value",
    hole: 0.7,
    type: "pie",
    textinfo: "label+value",
    textposition: "outside",
    marker: {
      colors: ["#b4d3fa", "#4194fa"],
      line: {
        width: 5,
        color: "white",
      },
    },
  });
  const [totalAcceptedVsNotAccepted, setTotalAcceptedVsNotAccepted]: any =
    useState(0);
  const [noDataTransporter, setNoDataTransporter] = useState("");

  //Pickup and deliverytime
  const [shockDataXaxis, setShockDataXaxis] = useState([]);
  const telemeteryService = new TelemeteryAPI();

  const [avgDeliveryTime, setAvgDeliveryTime] = useState([]);
  const [avgPickupTime, setAvgPickupTime] = useState([]);
  const [pickup, setPickup] = useState([]);
  const [deliverytime, setDeliveryTime] = useState([]);
  const [pickupXaxis, setPickupXaxis] = useState([]);
  const [noDataPickupandDeliveryTime, setNoDataPickupandDeliveryTime] =
    useState("");

  // Temperature Erosion

  const [assetType, setAssetType] = useState("Reefer Truck");
  const [assetTypeLidOpen, setAssetTypeLidOpen] = useState("Reefer Truck");
  const [assetTypeShockEvent, setAssetTypeShockEvent] = useState("Reefer Truck");
  const [assetNumberList, setAssetNumberList] = useState([]);
  const [values, setValues] = useState([]);
  const [shckValues, setShckValues] = useState([]);
  const [lidValues, setLidValues] = useState([]);
  const [assetIds, setAssetIds] = useState("");
  const [tempyaxis, setTempyaxis] = useState([]);
  const [timedata, setTimedata] = useState([]);
  const [tempzaxis1, setTempzaxis1] = useState([]);
  const [tempzaxis2, setTempzaxis2] = useState([]);
  const [tempzaxis3, setTempzaxis3] = useState([]);
  const [tempzaxis4, setTempzaxis4] = useState([]);

  const [tooltip1, setTooltip1] = useState([]);
  const [tooltip2, setTooltip2] = useState([]);
  const [tooltip3, setTooltip3] = useState([]);
  const [tooltip4, setTooltip4] = useState([]);

  const [noDataShockEvent, setNoDataShockEvent] = useState("");
  const [noDataLightEvent, setNoDataLightEvent] = useState("");
  const [flag1, setFlag1] = useState([]);
  const [flag2, setFlag2] = useState([]);
  const [flag3, setFlag3] = useState([]);
  const [flag4, setFlag4] = useState([]);

  const [nodataTempEx, setNodataTempEx] = useState("");
  const [loadingCircle, setloadingCircle] = useState(false);
  const [topFiveStoppages, setTopFiveStoppages] = useState([]);

  const loadingCircleData = (value: any) => {
    setloadingCircle(value);
  };

  useEffect(() => {
    getTransporterAPI
      .getAssetList(assetType, props.selectedClosedMarket)
      .then((res: any) => {
        // console.log(assetType,"type");
        let AssetsNum: any = [];
        res.data.map(
          (x: any) =>
            (AssetsNum = [...AssetsNum, { title: x.id, value: x.number }])
        );

        let obj1 = AssetsNum.find(
          (o: { value: any }) => o.value === res.data[0].number
        );
        setAssetIds(obj1.title);
        setAssetNumberList(AssetsNum);
        setValues([res.data[0].number]);
        setShckValues([res.data[0].number]);
        setLidValues([res.data[0].number]);
        // console.log(assetNumberList,"id");
      })

      .catch((error: any) => {
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });

  }, [assetType, props.timeDuration, props.startAndEnd, props.selectedClosedMarket]);



  //Temprature Graph as per Telemetry Api
  useEffect(() => {
    getTemprature(assetIds, timeDuration);
    getshock(assetIds, timeDuration)
  }, [assetIds, timeDuration, shckValues, lidValues])

  const getTemprature = async (assetIds: any, timeDuration: any) => {
    let subDay = timeDuration === 'daily' ? 1 : timeDuration === 'weekly' ? 7 : 30;
    const to = moment().format("MM-DD-YYYY");
    const from = moment().subtract(subDay, 'days').format("MM-DD-YYYY");
    const fromDate = new Date(from).valueOf()
    const toDate = new Date(to).valueOf();
    let vehicleNumber: any;
    if (assetIds) {
      for (let index = 0; index < assetNumberList.length; index++) {
        const element = assetNumberList[index];
        if (element.title === assetIds) {
          vehicleNumber = element.value;
          // console.log(vehicleNumber,"vn");

        }
      }
      // let res = await axios.get(`${telemetry}/telemetry/gatewayanddevice?assetId=${assetIds}&from=${from}&to=${to}`);
      let number = assetType === 'Reefer Truck' ? `KA52A${vehicleNumber}` : vehicleNumber;
      const res = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/history?vehicle_number=${number}&from=${fromDate}&to=${toDate}`)

      // console.log(res.data.data.data, 'called');
      if (res.data.data.data.length) {
        setTempyaxis([]);
        setTimedata([]);
        let sum1 = + 0;
        let count1 = + 0;
        let sum2 = + 0;
        let count2 = + 0;
        let sum3 = + 0;
        let count3 = + 0;
        let sum4 = + 0;
        let count4 = + 0;
        let tempz1: any = [];
        let tempz2: any = [];
        let tempz3: any = [];
        let tempz4: any = [];
        let tempdata: any = [];
        let datearray: any = []
        for (let index = 0; index < res.data.data.data.length; index++) {
          const element = res.data.data.data[index];
          if (element.commtime) {
            var date = element.commtime;
            // var d = moment(date, "YYYYMMDD");
            // var d = moment(date).format("MMMM Do YYYY hh:mm a")
            var time = Number(moment(date).format("hh"));
            // var newdate = d.format("MMM Do YYYY");
            var newdate = moment(date).format("MMM Do YYYY")
            datearray.push(newdate);

            // console.log(time,"time");


            // const shocktime = d.format("HH:mm");
            // if(element.se != 0){
            //   shockEventsDataXaxis.push(shocktime)
            //   shockEventsData.push(element.se)
            // }

            // if(element.lo != 0){
            //   lightEventsDataXaxis.push(shocktime)
            //   lightEventsData.push(element.lo)
            // }
            //console.log(lightEventsData,"lid");
            if (time >= 0 && time <= 6) {
              if (element.tempvalue) {
                var t = element.tempvalue
                sum1 = sum1 + t;
                count1 = count1 + 1;
              }
            }

            if (time >= 6 && time <= 12) {
              if (element.tempvalue) {
                var t = element.tempvalue
                sum2 = sum2 + t;
                count2 = count2 + 1;
              }
            }

            if (time > 12 && time <= 18) {
              if (element.tempvalue) {
                var t = element.tempvalue
                sum3 = sum3 + t;
                count3 = count3 + 1;
              }
            }

            if (time > 18 && time <= 23) {
              if (element.tempvalue) {
                var t = element.tempvalue
                sum4 = sum4 + t;
                count4 = count4 + 1;
              }
            }
            // console.log(time,"time");
          }
          let avg1 = Number(sum1 / count1).toFixed(2);
          let avg2 = Number(sum2 / count2).toFixed(2);
          let avg3 = Number(sum3 / count3).toFixed(2);
          let avg4 = Number(sum4 / count4).toFixed(2);
          if(avg1 !== "NaN"){
            tempz1.push(avg1);
          }
          if(avg2 !== "NaN"){
            tempz2.push(avg2);
          }
          if(avg3 !== "NaN"){
            tempz3.push(avg3);
          }
          if(avg4 !== "NaN"){
            tempz4.push(avg4);
          }
          
         
          
        }
        
        tempdata.push(tempz1);
        tempdata.push(tempz2);
        tempdata.push(tempz3);
        tempdata.push(tempz4);
        setTempyaxis(tempdata);
        // const uniquetemp = tempz.filter((val: any, id: any, array: string | any[]) => {
        //   return array.indexOf(val) == id;
        // });
        
        const uniquedates = datearray.filter((val: any, id: any, array: string | any[]) => {
          return array.indexOf(val) == id;
        });
        setTimedata(uniquedates)
        // console.log(tempyaxis, 'called');
      }
    }
  }
  const getshock = async (assetIds: any, timeDuration: any) => {
    let subDay = timeDuration === 'daily' ? 1 : timeDuration === 'weekly' ? 7 : 30;
    const to = moment().format("MM-DD-YYYY");
    const from = moment().subtract(subDay, 'days').format("MM-DD-YYYY");
    const fromDate = new Date(from).valueOf()
    const toDate = new Date(to).valueOf();
    let vehicleNumber: any;
    if (assetIds) {
      for (let index = 0; index < assetNumberList.length; index++) {
        const element = assetNumberList[index];
        if (element.title === assetIds) {
          vehicleNumber = element.value;
          // console.log(vehicleNumber,"vn");

        }
      }
      // let res = await axios.get(`${telemetry}/telemetry/gatewayanddevice?assetId=${assetIds}&from=${from}&to=${to}`);
      let number = assetType === 'Reefer Truck' ? `KA52A${vehicleNumber}` : vehicleNumber;
      const res = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/bletagshistory?vehicle_number=${number}&from=${fromDate}&to=${toDate}`)
      // console.log(res,"resultlihhts")
      if (res.data.data.data.length) {
        let sum1 = + 0;
        let sum2 = + 0;
        let lidcount = + 0;
        let humcount = + 0;
        let shockx: any = [];
        let shocky: any = [];
        let lightx: any = [];
        let lighty: any = [];
        let datearray: any = []

        for (let index = 0; index < res.data.data.data.length; index++) {
          const element = res.data.data.data[index];
          if (element.time) {
            var date = element.time;
            var time = Number(moment(date).format("HH"));
            var newdate = moment(date).format("MMM Do YYYY")
            // console.log(time,"time");


            if (timeDuration === 'daily') {
              datearray.push(time);
            }
            else {
              datearray.push(newdate);
            }

            if (element.lux1) {
              var t = element.lux1
              shockx.push(t)
              // lidcount =lidcount + 1;
              // sum1  = sum1  + t;
            }
            if (element.humi1) {
              var t = element.humi1
              lightx.push(t)
              humcount = humcount + 1;
              sum2 = sum2 + t;
            }
          }
        }

        // let avg = Number(sum1/  lidcount).toFixed(2);
        // let avg1 = Number(sum2/  humcount).toFixed(2);
        // shockx.push( avg)
        // shocky.push( lidcount)
        // lightx.push( avg1)
        //  lighty.push( humcount)
        const uniquedates = datearray.filter((val: any, id: any, array: string | any[]) => {
          return array.indexOf(val) == id;
        });
        uniquedates.sort(function (x: number, y: number) {
          return x - y;
        })
        setShockEventsDataXaxis(shockx)
        setShockEventsData(uniquedates)
        // console.log(lightx,lighty[0],"lights")

        setLightEventsDataXaxis(lightx)
        setTotals(uniquedates)
        // console.log(shockEventsDataXaxis, shockEventsData,uniquedates, "lights11")

      }

    }
  }
  // useEffect(() => {
  //   let datesForTranporter = "";
  //   if (timeDuration === "custom") {
  //     datesForTranporter = props.startAndEnd;
  //   } else {
  //     datesForTranporter = dateselect.DashboardDate(timeDuration);
  //   }

  //   loadingCircleData(true);
  //   setloadingCircle(false);

  //   if (assetIds !== "" && assetNumberList.length > 0) {
  //     /* istanbul ignore next */
  //     getTransporterAPI
  //       .getTempartureEx(
  //         datesForTranporter.substring(10, 30),
  //         datesForTranporter.substring(39),
  //         assetIds
  //       )
  //       .then((res: any) => {
  //         console.log(res,'called3')
  //         setTempxaxis(Object.keys(res));

  //         let t1: any = new Array(Object.keys(res).length).fill("");
  //         let t2: any = new Array(Object.keys(res).length).fill("");
  //         let t3: any = new Array(Object.keys(res).length).fill("");
  //         let t4: any = new Array(Object.keys(res).length).fill("");

  //         let tool1: any = new Array(Object.keys(res).length).fill("");
  //         let tool2: any = new Array(Object.keys(res).length).fill("");
  //         let tool3: any = new Array(Object.keys(res).length).fill("");
  //         let tool4: any = new Array(Object.keys(res).length).fill("");

  //         let f1: any = new Array(Object.keys(res).length).fill("");
  //         let f2: any = new Array(Object.keys(res).length).fill("");
  //         let f3: any = new Array(Object.keys(res).length).fill("");
  //         let f4: any = new Array(Object.keys(res).length).fill("");

  //         if (Object.keys(res).length <= 0) {
  //           setNodataTempEx("No Data To Display");
  //         } else {
  //           setNodataTempEx("");
  //           /* istanbul ignore next */
  //           Object.keys(res).forEach(function (key, ind) {
  //             Object.keys(res[key]).forEach(function (key2, index) {
  //               if (index === 0) {
  //                 t1[ind] = res[key][key2].avg;
  //                 f1[ind] =
  //                   res[key][key2].variation + " C\xB0" + res[key][key2].flag;
  //                 tool1[ind] = res[key][key2].avg + " C\xB0";
  //               }
  //               if (index === 1) {
  //                 t2[ind] = res[key][key2].avg;
  //                 tool2[ind] = res[key][key2].avg + " C\xB0";
  //                 f2[ind] =
  //                   res[key][key2].variation + " C\xB0" + res[key][key2].flag;
  //               }
  //               if (index === 2) {
  //                 t3[ind] = res[key][key2].avg;
  //                 tool3[ind] = res[key][key2].avg + " C\xB0";
  //                 f3[ind] =
  //                   res[key][key2].variation + " C\xB0" + res[key][key2].flag;
  //               }
  //               if (index === 3) {
  //                 t4[ind] = res[key][key2].avg;
  //                 tool4[ind] = res[key][key2].avg + " C\xB0";
  //                 f4[ind] =
  //                   res[key][key2].variation + " C\xB0" + res[key][key2].flag;
  //               }
  //             });
  //             //console.log(t1,t2,t3,t4, tool1, tool2, tool3, f1, f2, 'called3')
  //             setTempzaxis1(t1);
  //             setTempzaxis2(t2);
  //             setTempzaxis3(t3);
  //             setTempzaxis4(t4);

  //             setTooltip1(tool1);
  //             setTooltip2(tool2);
  //             setTooltip3(tool3);
  //             setTooltip4(tool4);

  //             setFlag1(f1);
  //             setFlag2(f2);
  //             setFlag3(f3);
  //             setFlag4(f4);
  //           });
  //         }
  //         loadingCircleData(false);
  //       })

  //       .catch((err: any) => {
  //         loadingCircleData(false);
  //         setNodataTempEx("No Data To Display");
  //         const UserToken = new ShipperHelper();
  //         UserToken.UserTokenExpiration();
  //       });
  //   } else {
  //     loadingCircleData(false);
  //     setNodataTempEx("No Data To Display");
  //   }
  // }, [timeDuration, props.startAndEnd, values]);


  useEffect(() => {
    let datesForTranporter = "";
    if (timeDuration === "custom") {
      datesForTranporter = props.startAndEnd;
    } else {
      datesForTranporter = dateselect.DashboardDate(timeDuration);
    }

    //Top 5 Transporter
    let topFiveTotal: any = 0;
    getTransporterAPI
      .getTop5Transporter(datesForTranporter, props.selectedClosedMarket)
      .then((values: any) => {
        values.map((data: any) => (topFiveTotal += data.value));
        if (topFiveTotal > 0) {
          setNoDataTop5("");
        } else {
          setNoDataTop5("No Data To Display");
        }
        settop5RowData(values);
        settop5TotalRequest(topFiveTotal);
      })
      .catch((err: any) => {
        setNoDataTop5("No Data To Display");
        console.error(err);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });

    //Accepted Vs NotAccepted
    let status: any = [];
    let count: any = [];
    let totalReq: any = 0;

    getTransporterAPI
      .getAcceptedVsNotAccepted(datesForTranporter, props.selectedClosedMarket)
      .then((data: any) => {
        totalReq = [data.total];
        data.status.map(
          (data: any, index: any) => (
            (status = [...status, data.status]),
            (count = [...count, data.count])
          )
        );
        setAcceptedVsNotAccepted({
          ...acceptedVsNotAccepted,
          values: count,
          labels: status,
        });
        setTotalAcceptedVsNotAccepted(data.total);

        if (totalReq === 0) {
          setNoDataTransporter("No Data To Display");
        } else {
          setNoDataTransporter("");
        }
      })
      .catch((err: any) => {
        setNoDataTransporter("No Data To Display");
        console.error(err);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
    //pickup and deliverytime
    getTransporterAPI
      .getPickupandDeliveryTime(datesForTranporter, props.selectedClosedMarket)
      .then((res: any) => {
        // console.log(res);
        setPickupXaxis(Object.keys(res.pickUpDeliveryMap));
        let pickup: any = [];
        let deliveryTime: any = [];
        let avgPick: any = [];
        let avgDelivery: any = [];

        Object.keys(res.pickUpDeliveryMap).forEach(function (key) {
          pickup = [...pickup, res.pickUpDeliveryMap[key][0].pickupTime];
          deliveryTime = [
            ...deliveryTime,
            res.pickUpDeliveryMap[key][0].deliveryTime,
          ];
          avgPick = [...avgPick, res.avgPickupTime];
          avgDelivery = [...avgDelivery, res.avgDeliveryTime];
        });
        setAvgDeliveryTime(avgDelivery);
        setAvgPickupTime(avgPick);
        setDeliveryTime(deliveryTime);
        setPickup(pickup);
        setNoDataPickupandDeliveryTime("");
      })
      .catch((err: any) => {
        setNoDataPickupandDeliveryTime("No Data To Display");
        console.error(err);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  }, [timeDuration, props.startAndEnd, props.selectedClosedMarket]);
  const getTopFiveStoppages = () => {
    let query = { "status": ["Completed"] };
    marketApi.getRequestsByQuery(0, 5, "DESC", query)
      .then((resp: any) => {
        //  console.log('TopFive', resp);
        setTopFiveStoppages(resp.data);
      })
      .catch((err: any) => {
        // console.log('TopFive', err);
      });
  }
  useEffect(() => {
    if (flagvalue === true) {
      props.pdfStatechange();
      pdfGenerator();

    }
    getTopFiveStoppages();
  }, [flagvalue]);

  const pdfGenerator = () => {
    const PickupandDeliverytimePdf = document.getElementById(
      "PickupandDeliverytimePdf"
    );
    const DashboardTopFivePDF = document.getElementById("DashboardTopFivePDF");
    const ShockEventsPDF = document.getElementById("ShockEventsPDF");
    const LightEventsPDF = document.getElementById("LightEventsPDF");
    const AcceptedVsNotAcceptedPDF = document.getElementById(
      "AcceptedVsNotAcceptedPDF"
    );
    const TemperatureExcursionPDF = document.getElementById(
      "TemperatureExcursionPDF"
    );
    /*istanbul ignore next*/
    html2canvas(PickupandDeliverytimePdf).then(
      (canvasPickupandDeliverytimePdf) => {
        html2canvas(DashboardTopFivePDF).then((canvasDashboardTopFivePDF) => {
          html2canvas(ShockEventsPDF).then((canvasShockEventsPDF) => {
            html2canvas(LightEventsPDF).then((canvasLightEventsPDF) => {
              html2canvas(AcceptedVsNotAcceptedPDF).then(
                (canvasAcceptedVsNotAcceptedPDF) => {
                  html2canvas(TemperatureExcursionPDF).then(
                    (canvasTemperatureExcursionPDF) => {
                      const doc = new jsPDF();
                      const imgDashboardPickupandDeliverytime =
                        canvasPickupandDeliverytimePdf.toDataURL("image/png");
                      doc.addImage(
                        imgDashboardPickupandDeliverytime,
                        "PNG",
                        5,
                        5,
                        120,
                        70
                      );

                      const imgDashboardTopFive =
                        canvasDashboardTopFivePDF.toDataURL("image/png");
                      doc.addImage(imgDashboardTopFive, "PNG", 130, 5, 70, 70);
                      const imgShockEvents =
                        canvasShockEventsPDF.toDataURL("image/png");
                      doc.addImage(imgShockEvents, "PNG", 5, 70, 200, 80);
                      const imgLightEvents =
                        canvasLightEventsPDF.toDataURL("image/png");
                      doc.addImage(imgLightEvents, "PNG", 5, 70, 200, 80);

                      const imgAcceptedVsNotAccepted =
                        canvasAcceptedVsNotAcceptedPDF.toDataURL("image/png");
                      doc.addImage(imgAcceptedVsNotAccepted, "PNG", 5, 80, 70, 70);

                      const imgTemperatureExcursion =
                        canvasTemperatureExcursionPDF.toDataURL("image/png");
                      doc.addImage(imgTemperatureExcursion, "PNG", 80, 90, 130, 80);

                      doc.save("DashboardTransport.pdf");
                    }
                  );
                }
              );
            });
          });
        });

      }
    );
  };

  const handleChangeAsset = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAssetType(event.target.value as string);
    setValues([]);
    setAssetIds("");
  };

  const handleChangeLidOpen = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAssetTypeLidOpen(event.target.value as string);
    setLidValues([]);
    setAssetIds("");
  };

  const handleChangeShockEvent = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAssetTypeShockEvent(event.target.value as string);
    setShckValues([]);
    setAssetIds("");
  };

  const handleChangeValue = (
    event: React.SyntheticEvent<Element, Event>,
    value: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    setValues(value);
    let obj1 = assetNumberList.find((o) => o.value === value);
    setAssetIds(obj1 ? obj1.title : "");
  };
  // const handleShockValues = (
  //   event: React.SyntheticEvent<Element, Event>,
  //   value: any[],
  //   reason: AutocompleteChangeReason,
  //   details?: AutocompleteChangeDetails<any>
  // ) => {
  //   let datesForTranporter = "";
  //   if (timeDuration === "custom") {
  //     datesForTranporter = props.startAndEnd;
  //   } else {
  //     // console.log("dateselect.DashboardDate(timeDuration)")
  //     datesForTranporter = dateselect.DashboardDates(timeDuration);
  //     // console.log(datesForTranporter);
  //   }
  //   setShckValues(value);
  //   let obj1 = assetNumberList.find((o) => o.value === value);
  //   setAssetIds(obj1 ? obj1.title : "");

  //   // console.log(datesForTranporter);
  //   // console.log(timeDuration);
  //    axios
  //   .get(`${assetBaseURL}/sensors/assetId?assetId=${assetIds}`)
  //   .then((res1: any) => {
  //     // console.log(res1.data);
  //     // console.log(props.timeDuration);
  //     // console.log(res1.data[0].tagId);

  //              let ts:any=[];
  //              let totaly:any=[];
  //              let date:any=[];
  //             // console.log(res1.data);
  //             telemeteryService
  //             .getShock(res1.data[0].tagId,props.timeDuration ,datesForTranporter).then((res3:any)=>
  //              { 

  //                   let tst: any = [];
  //                   let tst1: any = [];
  //                   let sht=0;
  //                   let toast:any=[];
  //                   let toast1:any=[];
  //                   setShockEventsData(res3.data);
  //                   // console.log(res3.data.tagDatas.length);
  //                   if(res3.data.tagDatas.length>=0){
  //                   for(let i=0;i<res3.data.tagDatas.length;i++)
  //                   {
  //                    if(res3.data.tagDatas[i].shock!='0')
  //                    {
  //                        sht=sht+1;
  //                        tst=res3.data.tagDatas[i].shock;
  //                        tst1=res3.data.tagDatas[i].ts;
  //                       //  console.log(tst);
  //                        toast.push(tst);
  //                        toast1.push(tst1);
  //                       //  console.log(toast);
  //                        setNoDataShockEvent("");
  //                        setTotal(toast);
  //                        setShockEventsDataXaxis(toast1);
  //                    }                    
  //                    }
  //                 }
  //                 else
  //                 {
  //                   setNoDataShockEvent("No Data To Display");
  //                 }
  //             })
  //            .catch((err: any) => {
  //             console.error(err);
  //          setNoDataShockEvent("No Data To Display");
  //           });

  //         })


  // };
  //   const handleLightValues = (
  //     event: React.SyntheticEvent<Element, Event>,
  //     value: any[],
  //     reason: AutocompleteChangeReason,
  //     details?: AutocompleteChangeDetails<any>
  //   ) => {
  //     let datesForTranporter = "";
  //     if (timeDuration === "custom") {
  //       datesForTranporter = props.startAndEnd;
  //     } else {  
  //       datesForTranporter = dateselect.DashboardDates(timeDuration);
  //     }

  //     setLidValues(value);
  //     let obj1 = assetNumberList.find((o) => o.value === value);
  //     setAssetIds(obj1 ? obj1.title : "");
  //     axios
  //     .get(`${assetBaseURL}/sensors/assetId?assetId=${assetIds}`)
  //     .then((res1: any) => {
  //       // console.log(res1.data);
  //       // console.log(props.timeDuration);
  //       // console.log(res1.data[0].tagId);


  //         //setLightEventsDataXaxis(res2.data[0].ndtimestamp)
  //         let ts1: any=[];
  //         let tst:any=[];
  //         let tsv:any=[];
  //         let tsv1:any=[];
  //         let totas=0;
  //         telemeteryService
  //         .getLights(res1.data[0].tagId,props.timeDuration, datesForTranporter).then((res3: any)=>

  //          {  

  //           // console.log(res3);
  //           //  console.log(res3.data.total);
  //            setLightEventsData(res3.data);

  //            for(let i=0;i<res3.data.tagDatas.length;i++)
  //            {
  //             if(res3.data.tagDatas[i].lidOpen!='0')
  //             {
  //                 totas=totas+1;
  //                 ts1=res3.data.tagDatas[i].lidOpen;
  //                 tst=res3.data.tagDatas[i].ts;
  //                 // console.log(ts1);
  //                 tsv.push(ts1);
  //                 tsv1.push(tst)
  //                 // console.log(tsv);
  //               setNoDataLightEvent("");
  //                setTotals(tsv);
  //                 setLightEventsDataXaxis(tsv1);
  //             }
  //          }

  //         // else
  //         // {
  //         //   setNoDataLightEvent("No Data To Display");
  //         // }
  //         }) 
  //         .catch((err: any) => {
  //           console.error(err);
  //           setNoDataLightEvent("No Data To Display");
  //        });
  //        })

  // };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginLeft: "7px", marginTop: "-10px" }}>
            <div id="DashboardTopFivePDF">
              <DashboardTopFiveTranporter

                top5RowData={top5RowData}
                top5TotalRequest={top5TotalRequest}
                noData={noDataTop5}
                selectedClosedMarket={props.selectedClosedMarket}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginLeft: "13px", marginTop: "-10px", height: "24.6rem" }}>
            <div id="PickupandDeliverytimePdf" style={{ marginLeft: "20px", marginRight: "20px" }}>
              <PickupandDeliverytime
                avgPickupTime={avgPickupTime}
                avgDeliveryTime={avgDeliveryTime}
                pickup={pickup}
                delivery={deliverytime}
                xaxis={pickupXaxis}
                noData={noDataPickupandDeliveryTime}
              />
            </div>
          </Paper>
        </Grid>


        <Grid item xs={12} lg={8} >
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginRight: "20px", marginLeft: "6px", marginTop: "12px" }}>
            <div id="TemperatureExcursionPDF" >
              <br />
              <Grid container spacing={2} >
                <Grid item xs={4} >
                  <p style={Headingstyle}>Temperature Excursion</p>
                </Grid>
                <Grid item xs={7} >
                  <Stack spacing={0} direction="row" style={{ float: "right" }}>
                    <Select
                      style={{ height: "30px", marginTop: "-2px", marginRight: "9px" }}
                      id="assetType"
                      data-testid="AssetType"
                      labelId="demo-customized-select-label"
                      //id="demo-customized-select"

                      IconComponent={ExpandMoreIcon}
                      value={assetType}
                      onChange={handleChangeAsset}
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={"Reefer Truck"} style={{ color: "#000" }}>Reefer Truck</MenuItem>
                      <MenuItem value={"Active Cooler Box"} style={{ color: "#000" }}>
                        Cooler Box
                      </MenuItem>
                    </Select>
                    <Autocomplete
                      style={{
                        height: "3.3rem",
                        width: "11rem",
                        marginTop: "-10px",

                      }}
                      classes={{
                        option: styles.option
                      }}

                      id="multiple-limit-tags"
                      data-testid="AssetTypeValue"
                      freeSolo
                      //options={assetNumberList}
                      value={values}
                      //getOptionLabel={(assetNumberList) => assetNumberList.value}
                      onChange={handleChangeValue}
                      options={assetNumberList.map((option) => option.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select"
                          sx={{
                            "& label": {
                              top: "4px",

                            },
                            "& legend": {
                              textAlign: "left",

                            },

                          }}
                          style={{ height: "3rem" }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <TemperatureExcursion
                xaxis={timedata}
                zaxis={tempyaxis}
                t1={tempzaxis1}
                t2={tempzaxis2}
                t3={tempzaxis3}
                t4={tempzaxis4}
                id={assetIds}
                tooltip1={tooltip1}
                tooltip2={tooltip2}
                tooltip3={tooltip3}
                tooltip4={tooltip4}
                noData={nodataTempEx}
                f1={flag1}
                f2={flag2}
                f3={flag3}
                f4={flag4}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginTop: "12px", height: "24.8rem" }}>
            <div id="AcceptedVsNotAcceptedPDF" style={{ marginRight: '20px' }}>
              <AcceptedVsNotAccepted
                totalAcceptedVsNotAccepted={totalAcceptedVsNotAccepted}
                acceptedVsNotAccepted={acceptedVsNotAccepted}
                noDataTransporter={noDataTransporter}
              />
            </div>
          </Paper>
        </Grid>

        <br />



        <Grid item xs={12} lg={12} style={{ marginTop: "1rem" }}>
          <Paper className={classes.paper + " p-0"} style={{ marginLeft: "7px", marginTop: "-4px", boxShadow: "none" }}>
            <div id="StoppagesPDF" style={{ marginLeft: "14px", marginRight: "15px" }}>
              <TopFiveStoppage
                datas={topFiveStoppages}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginRight: "10px", marginTop: "12px", marginLeft: "7px" }}>
            <div id="ShockEventsPDF" >
              <br />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <p style={Headingstyle}>Shock Events</p>
                </Grid>
                <Grid item xs={7}>
                  <Stack spacing={0} direction="row" style={{ float: "right" }}>
                    <Select
                      style={{ height: "30px", marginTop: "-2px", marginRight: "9px" }}
                      id="assetType"
                      data-testid="AssetType"
                      labelId="demo-customized-select-label"
                      //id="demo-customized-select"
                      IconComponent={ExpandMoreIcon}
                      value={assetTypeShockEvent}
                      onChange={handleChangeShockEvent}
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={"Reefer Truck"} style={{ color: "#000" }}>Reefer Truck</MenuItem>
                      <MenuItem value={"Active Cooler Box"} style={{ color: "#000" }}>
                        Cooler Box
                      </MenuItem>
                    </Select>
                    <Autocomplete
                      style={{
                        height: "3.3rem",
                        width: "11rem",
                        marginTop: "-10px",

                      }}
                      classes={{
                        option: styles.option
                      }}

                      id="multiple-limit-tags"
                      data-testid="AssetTypeValue"
                      freeSolo
                      //options={assetNumberList}
                      value={shckValues}
                      //getOptionLabel={(assetNumberList) => assetNumberList.value}
                      // onChange={handleShockValues}

                      options={assetNumberList.map((option) => option.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select"
                          sx={{
                            "& label": {
                              top: "4px",
                            },
                            "& legend": {
                              textAlign: "left",
                            },

                          }}
                          style={{ height: "3rem" }}
                        />

                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <ShockEvents
                data={shockEventsData}
                noDataShockEvents={noDataShockEvent}
                xaxis={shockEventsDataXaxis}
                total={shockEventsData}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginRight: "2px", marginTop: "12px" }}>
            <div id="LightEventsPDF" >
              <br />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <p style={Headingstyle}>Lid Open Events</p>
                </Grid>
                <Grid item xs={7}>
                  <Stack spacing={0} direction="row" style={{ float: "right" }}>
                    <Select
                      style={{ height: "30px", marginTop: "-4px", marginRight: "9px" }}
                      id="assetType"
                      data-testid="AssetType"
                      labelId="demo-customized-select-label"
                      //id="demo-customized-select"
                      IconComponent={ExpandMoreIcon}
                      value={assetTypeLidOpen}
                      onChange={handleChangeLidOpen}
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={"Reefer Truck"} style={{ color: "#000" }}>Reefer Truck</MenuItem>
                      <MenuItem value={"Active Cooler Box"} style={{ color: "#000" }}>
                        Cooler Box
                      </MenuItem>
                    </Select>
                    <Autocomplete
                      style={{
                        height: "3.3rem",
                        width: "11rem",
                        marginTop: "-12px",
                      }}
                      id="multiple-limit-tags"
                      data-testid="AssetTypeValue"
                      freeSolo
                      //options={assetNumberList}
                      value={lidValues}
                      //getOptionLabel={(assetNumberList) => assetNumberList.value}
                      //  onChange={handleLightValues}
                      classes={{
                        option: styles.option
                      }}
                      // onChange={handleLightValues}

                      options={assetNumberList.map((option) => option.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select"
                          sx={{
                            "& label": {
                              top: "4px",
                            },
                            "& legend": {
                              textAlign: "left",
                            },
                          }}
                          style={{ height: "3rem" }}
                        />

                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <LightEvents
                data={lightEventsData}
                noDataLightEvents={noDataLightEvent}
                xaxis={lightEventsDataXaxis}
                totals={totals}
              />
            </div>
          </Paper>
        </Grid>


        <Grid item xs={12} lg={12} style={{ marginTop: "1rem" }}>
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginLeft: "2px", height: "23rem", marginBottom: "20px" }}>
            <div id="StoppagesPDF" style={{ marginLeft: "14px", marginRight: "15px" }}>
              <DriverInformation
                datas={[]}
              />
            </div>
          </Paper>
        </Grid>
        <div>
          {loadingCircle && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme: { zIndex: { drawer: number } }) =>
                  theme.zIndex.drawer + 1,
              }}
              open={loadingCircle}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
      </Grid>
    </div>
  );
}


