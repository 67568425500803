import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableSortLabel } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Row from "./Row";
import "../../common/Alert/style.css";
import "react-toastify/dist/ReactToastify.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Helper, { ShippingRequestStatus } from "../../../../services/_helpers";
import MarketPlaceAPI from "../../../../services/marketPlaceService";
import { TableManageColumnsFormData } from "../../Dashboard/Common/Interfaces/custom";
import TablePaginationCustom from "../../Utils/TablePagination";
import ShipperHelper from "../../../../services/_helpers";

type Order = "ASC" | "DESC";

export default function ShipmentRequestactive(props: any) {
  const { searchReqId, filterOptions, manageColumnsOptions } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowData, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const helper = new Helper();
  const [orderDirection, setOrderDirection] = React.useState<Order>("DESC");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("timestamp");

  const [fetchTableFlag, setFetchTableFlag] = React.useState(
    JSON.parse(sessionStorage.fetchTableFlag)
  );
  const [loadFlag, setLoadFlag] = React.useState(true);
  const [filterQuery, setFilterQuery] = useState(null);

  //const [fetchTableFlag, setFetchTableFlag] = React.useState(true);

  useEffect(() => {
    if (fetchTableFlag) {
      // setFetchTableFlag(false);
      setFetchTableFlag(sessionStorage.setItem("fetchTableFlag", "false"));
      fetchTableData();
    }
  }, [fetchTableFlag]);

  useEffect(() => {
    if (!searchReqId) {
      if (filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if (
            ShippingRequestStatus[opt.value] !==
              ShippingRequestStatus.Completed &&
            ShippingRequestStatus[opt.value] !== ShippingRequestStatus.Cancelled
          ) {
            tempFilter.push(opt.value);
          }
        });
        if (tempFilter.length > 0) {
          setFilterQuery({ status: tempFilter });
          fetchTableData(0, rowsPerPage, orderDirection, {
            status: tempFilter,
          });
        } else {
          // setFilterQuery(null);
          // fetchTableData(0, rowsPerPage, orderDirection, null, true);
          setRows([]);
          setLoadFlag(false);
          props.handleChangeKey("pastRequest");
        }
      } else {
        setFilterQuery(null);
        fetchTableData(0, rowsPerPage, orderDirection, null, true);
      }
    }
  }, [filterOptions, searchReqId]);

  useEffect(() => {
    if (searchReqId) {
      if (filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if (
            ShippingRequestStatus[opt.value] !==
              ShippingRequestStatus.Completed &&
            ShippingRequestStatus[opt.value] !== ShippingRequestStatus.Cancelled
          ) {
            tempFilter.push(opt.value);
          }
        });
        if (tempFilter.length > 0) {
          setFilterQuery({ status: tempFilter });
          fetchTableDataByReqId(0, rowsPerPage, orderDirection, {
            status: tempFilter,
          });
        } else {
          // setFilterQuery(null);
          // fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
          setRows([]);
          setLoadFlag(false);
          props.handleChangeKey("pastRequest");
        }
      } else {
        setFilterQuery(null);
        fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
        if (rowData.length === 0) {
          props.handleChangeKey("pastRequest");
        }
      }
    }
  }, [searchReqId, filterOptions, rowData.length]);

  const fetchTableDataByReqId = (
    page: number = 0,
    size: number = 10,
    orderBy: Order = "DESC",
    query?: any,
    shouldForceDefaultQuery?: boolean
  ) => {
    const marketApi = new MarketPlaceAPI();
    const defaultQuery = {
      status: [
        helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
        helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
        helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
      ],
    };
    setPage(page);
    setRowsPerPage(size);
    marketApi
      .searchByRequestNumber(
        searchReqId,
        page,
        size,
        orderBy,
        query
          ? query
          : filterQuery && !shouldForceDefaultQuery
          ? filterQuery
          : defaultQuery
      )
      .then((resp: any) => {
        setRows(resp.data);
        setTotalRows(resp.total);
        setLoadFlag(false);
      })
      .catch((err: any) => {
        console.error("No requests found with that request number.", err);
        setRows([]);
        setTotalRows(0);
        setLoadFlag(false);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  };

  const fetchTableData = (
    page: number = 0,
    size: number = 10,
    orderBy: Order = "DESC",
    query?: any,
    shouldForceDefaultQuery?: boolean
  ) => {
    const marketApi = new MarketPlaceAPI();
    const defaultQuery = {
      status: [
        helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
        helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
        helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
      ],
    };
    setPage(page);
    setRowsPerPage(size);
    marketApi
      .getRequestsByQuery(
        page,
        size,
        orderBy,
        query
          ? query
          : filterQuery && !shouldForceDefaultQuery
          ? filterQuery
          : defaultQuery
      )
      .then((resp: any) => {
        setRows(resp.data);
        setTotalRows(resp.total);
        setLoadFlag(false);
      })
      .catch((err: any) => {
        console.error("Failded to load active shipping requests.", err);
        setRows([]);
        setTotalRows(0);
        setLoadFlag(false);
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  };

  //});
  // const handleCallback = (deleteID: any) =>{
  //   setRows(rowData.filter((item:any) => item["id"] !== deleteID))
  // }

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchTableData(newPage, rowsPerPage, orderDirection);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    fetchTableData(0, +event.target.value);
  };

  const handleRequestSort = (event: any) => {
    let isAscending: boolean;
    if (valueToOrderBy === "timestamp" && orderDirection === "ASC") {
      isAscending = true;
    } else {
      isAscending = false;
    }
    setValueToOrderBy("timestamp");
    setOrderDirection(isAscending ? "DESC" : "ASC");
    fetchTableData(0, rowsPerPage, isAscending ? "DESC" : "ASC");
  };

  return (
    <Paper>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {manageColumnsOptions
                .sort(
                  (
                    a: TableManageColumnsFormData,
                    b: TableManageColumnsFormData
                  ) => {
                    return a.order - b.order;
                  }
                )
                .map((reorder: TableManageColumnsFormData) => {
                  if (reorder.defaultOrder === 5) {
                    return (
                      <TableCell
                        style={{ fontSize: "12px" }}
                        align="left"
                        key="timestamp"
                      >
                        <TableSortLabel
                          active={valueToOrderBy === "timestamp"}
                          data-testid="columnSort"
                          direction={
                            valueToOrderBy === "timestamp"
                              ? (orderDirection.toLocaleLowerCase() as
                                  | "asc"
                                  | "desc")
                              : "asc"
                          }
                          onClick={handleRequestSort}
                          IconComponent={ArrowDropDownIcon}
                        >
                          CREATED ON
                        </TableSortLabel>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        style={{ fontSize: "12px" }}
                        align={reorder.defaultOrder === 4 ? "right" : "left"}
                      >
                        {reorder.title}
                      </TableCell>
                    );
                  }
                })}

              <TableCell style={{ fontSize: "12px" }} align="center">
                ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadFlag ? (
              ""
            ) : (
              <>
                {rowData.length > 0 ? (
                  rowData.map((row: any) => {
                    return (
                      <>
                        <Row
                          key={row.id}
                          row={row}
                          manageColumnsOptions={manageColumnsOptions}
                          setFetchTableFlag={setFetchTableFlag}
                        />
                      </>
                    );
                  })
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 100,
                          paddingTop: 50,
                          margin: "5rem",
                        }}
                        colSpan={9}
                      >
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontSize: "20px", fontWeight: 500 }}>
                            No trip requests available
                          </span>
                          <p style={{ fontSize: "16px", color: "#5A5A5A" }}>
                            Create one by clicking on "+CREATE NEW" button.
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ fontSize: "12px" }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{ variant: "outlined" }}
        labelRowsPerPage={"Rows per page"}
        labelDisplayedRows={({ from, to, count }) => ""}
        ActionsComponent={TablePaginationCustom}
      />
    </Paper>
  );
}
