import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Tab as Taab,
  Tabs as Taabs,
  Box,
  Typography,
  Button,
  InputBase,
  withStyles,
  Grid,

} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InputLabel from '@mui/material/InputLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from "react-router-dom";
//import FormControl from '@mui/material/FormControl';
//import ListItemText from '@mui/material/ListItemText';
import moment from "moment";
import AssetCoolerBox from "./CoolerBox";
import axios from "axios";

import AssetReeferTruck from "./ReeferTruck";
//import AssetOverView from "./common/overview";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import calendar from "../../../assets/images/calendar.svg";
import viewBy from "../../../assets/images/viewBy.svg";
import asset from "../../../assets/images/asset.svg";
import Download from "../../../assets/images/Download.svg";
import { assetBaseURL } from "../../../services/_url";
import UserService from "../../../services/userService";
import { Link } from "react-router-dom";
import EVOverview from "./common/overview/evOverview";
import Details from "./common/overview/details"
import { ResponsiveDrawer } from "../common/Drawer";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../redux/user/user.action";
import { selectCurrentUser } from "../../../redux/user/user.selector";
import AssetBaseAPI from "../../../services/assetService";
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const assetApi = new AssetBaseAPI();

const BootstrapInput = withStyles((theme: Theme) =>
  //css of input and select box
  createStyles({
    root: {

    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "null",
      fontSize: "13px",
      // width: "150px",

      textAlign: "right",

      // transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.

    },
  })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

      flexGrow: 1,
      fontSize: "12pt",
      boxShadow: "none",
    },
    border: {
      border: '1px solid white',
    },
    pageHeader: {
      // fontSize: "1.5rem",
      // marginBottom: "2rem",
      font: "normal normal normal 17px/18px Roboto Medium",
      color: "#000000",
      opacity: "1",
    },
    pageTopBar: {
      backgroundColor: "#F4F6F8",
    },
    tabsWrapper: {
      marginTop: "50px",
      marginBottom: "1.5rem",
      position: "relative",
      "@media (max-width:500px)": {
        marginTop: "3rem",
      },

    },
    report: {
      "&.MuiButton-root": {
        minWidth: '20px'
      }
    },
    tabs: {
    },
    tab: {
      "&.Mui-selected": {
        outline: "none",
      },
      outline: "none",
    },
    tabPanel: {
      backgroundColor: "#fafafa",
    },
    addNewAsset: {
      // width: "45%",
      float: "right",
      // marginRight: "1rem",
    },
    addAsset: {
      float: "left",
      marginLeft: "-86px"
    },
    select: {
      "&:focus-within ": {
        border: "1px solid #000000",

      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #000000"
      },
    },
    topIconsWrapper: {
      marginRight: "0.75rem",
      position: "absolute",
      top: 0,
      right: 0,
    },
    tabsLeft: {
      width: "13rem",
      "& .MuiTabs-flexContainer": {
        backgroundColor: "#E5E5E5",
        borderRadius: "40px",
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "0.6rem",
        border: "5px solid #E5E5E5",
        color: "#E5E5E5",
      }
    },
    tabTruck: {
      "&.Mui-selected": {
        outline: "none",
        color: "#0078A5",
        backgroundColor: "#ffffff",
        borderRadius: "40px",

        "@media only screen and (max-width:750px) and (min-width:1030px)": {
          width: "50%",
          fontSize: "18px",
        }
      },
      outline: "none",
      color: "#737373",
      borderRadius: "40px",
      width: "50%",
    },
    topIconWrapper: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "10px",
      color: "#5A5A5A",
      marginLeft: "20px",
      width: "40px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
    topIconWrapper1: {
      display: "inline-block",
      backgroundColor: "#E6E6E6",
      border: "1px solid  #E6E6E6",
      transition: "border 0.3s ease-in-out",
      borderRadius: "4px",
      marginRight: "7.5rem",
      color: "#5A5A5A",
      width: "40px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#c7c7c7",
      },
    },
  })
);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      data-testid="simplepanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography data-testid="simplepanels">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export interface IAssetProps { }
const NewIcon = (props: any) => (
  <svg
    {...props}
    width="0"
    height="0"
    viewBox="0 0 38 38"
    fill="none"

  >

    <svg
      width="0"
      height="0"
      x="10"
      y="13"
      viewBox="0 0 18 12"
      fill="none"

    >
      <path
        d="M0.66732 0.999999L8.72964 10.8681C8.76363 10.9095 8.80536 10.9428 8.85208 10.9655C8.89879 10.9882 8.94943 11 9.00065 11C9.05187 11 9.10251 10.9882 9.14922 10.9655C9.19594 10.9428 9.23767 10.9095 9.27167 10.8681L17.334 1"
        stroke="#222426"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </svg>
);
export function AssetDetails(props: any) {
  const classes = useStyles();
  const params = useParams();
  // console.log("params--> " + params);
  const [value, setValue] = useState(0);
  const [period, setPeriod] = React.useState("daily");
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [fromAndTo, setfromAndTo] = useState("");
  const [endNotGreater, setendNotGreater] = useState(false);
  const [loadingCircle, setloadingCircle] = useState(false);
  const [viewByValue, setViewByValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState('All');
  const [selectedOption1, setSelectedOption1] = useState('All')
  const [selectedReport, setSelectedReport] = useState('All');
  const [selectedReport1, setSelectedReport1] = useState('All')
  const [viewByOptions, setViewByOptions] = useState([]);
  const [asset, setAsset] = useState("Asset");
  const [searchReqId, setSearchReqId] = useState<string>("");
  const [topBarValue, setTopBarValue] = useState(0);
  const [reportUrl, setReportUrl] = useState<string>("");
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const userApi = new UserService();
  const history = useHistory();
  const userDetails = userApi.getUserDetails();
  const [shouldApplySearch, setShouldApplySearch] = useState<boolean>(false);
  const [messageOpen, setmessageOpen] = useState(false)
  const [reportOptions, setReportOptions] = useState([])
  const [shareInfo, setShareInfo] = useState(null)
  const [showShareInfo, setShowShareInfo] = useState(false)
  const [isCopied, setIsCopied] = useState(false);
  const [dDialog, setDDialog] = useState(false);
  const [reportdial, setReportdial] = useState(false);
  const userService = new UserService()
  const filterNames = ['test', 'test1'];
  //const reportOptions = ['Week (29Apr23 - 5May23)', 'Week (6May23 - 12May23)','Week (13May23 - 19May23)','Week (20May23 - 26May23)','Week (27May23 - 2Jun23)'];
  // const reportOptions = ['Week1_1Jul23_7Jul23.pdf', 'Week4_24Jun23_30Jun23.pdf', 'Week3_17Jun23_23Jun23.pdf', 'Week2_10Jun23_16Jun23.pdf', 'Week1_3Jun23_9Jun23.pdf', ];
  let { currentUser, setCurrentUser } = props;


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = React.useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await userService.getTenants();
        // setViewByOptions(result)
        // console.log(result,"getTenants")
        let tenant = result;
        let filterdata = tenant.filter(function (el: { name: string; }) {
          return el.name === "ISE foods" || el.name === "LithPwr" || el.name === 'ISE' || el.name === 'Blowhorn'
        });
        setViewByOptions(filterdata)
        // console.log(viewByOptions,"getTenants")
      } catch (error) {

      }
    })()
  }, [])


  /**
   * calls the asset custom report get api
   * maps the file name to report options which renders in download dropdown
   */
  useEffect(() => {
    const reportsList = async () => {
      const customReports = await assetApi.getAssetCustomReport().catch(handleError);
      const mapFileNames = customReports.files.map((report: any) => {
        return report.fileName;
      });
      setReportOptions(mapFileNames);
    };
    reportsList();
  }, []);

  /**
   * api error handling
   * @param err 
   */
  const handleError = (err: any) => {
    console.log(err);
  }

  const onChange = (event: SelectChangeEvent<typeof viewByValue>) => {
    const {
      target: { value },
    } = event;
    setViewByValue(
      typeof value === 'string' ? value.split(',') : value,
    );

  };
  const handleChange1 = (event: any) => {
    setAsset(event.target.value);
  };
  const handleDropdownChange = (
    event: SelectChangeEvent<typeof period>
  ) => {
    if (event.target.value === "custom") {
      setcustomDialog(true);
      setPeriod(event.target.value as string);
      // setAssetToken("true");
      setstartDate("");
      setendDate("");
      setfromAndTo("");
    } else {
      setPeriod(event.target.value as string);
      // setAssetToken("true");
      setstartDate("");
      setendDate("");
      setfromAndTo("");
    }
  };
  const handleAddNewDriver = () => {
    history.push('/AddNewDriver');
  }
  const handleAddNewAsset = () => {
    history.push('/addnewasset');
  }
  const handleDates = (e: any) => {
    let fromAndToDate =
      "from=" + startDate + "T00:00:00Z&to=" + endDate + "T23:59:59Z";
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    seterrorText(false);
    setValidationStart(false);
    setValidationEnd(false);
    setendNotGreater(false);
    if (startDate === "") {
      setValidationStart(true);
    } else if (endDate === "") {
      setValidationEnd(true);
    } else if (startDate > endDate) {
      seterrorText(true);
    } else if (endDate > todaysdate) {
      setendNotGreater(true);
    } else {
      setfromAndTo(fromAndToDate);
      setPeriod("custom");
      setcustomDialog(false);
    }
  };
  const handleCancel = () => {
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    if (startDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate === "") {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (startDate > endDate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate > todaysdate) {
      setfromAndTo("");
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    }
    setcustomDialog(false);
  };
  // const handleChange1 = (e:any) => {
  //   setAsset(e.target.value);
  // };
  const [pdfFlag, setPdfFlag] = useState(false);
  const pdfGen = () => {
    setPdfFlag(!pdfFlag);
  };

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleChangeCustom = (event: any) => {
    /* istanbul ignore next */
    if (period === "custom") {
      setcustomDialog(true);
    }
  };

  const showDropdown = (e: React.MouseEvent<HTMLElement>) => {
    //Fixed css issue
    if (dDialog) {
      setDDialog(false);
    } else {
      setDDialog(true);
      setTimeout(() => {
        let el = document.getElementById("popupDiv");
        if (!el) {
          return showDropdown(e)
        }
        el.style.position = "absolute";
        el.style.left = (e.pageX - 100) + 'px';
        el.style.top = (e.pageY + 25) + 'px';
      }, 0);
    }
  }
  const showReports = (e: React.MouseEvent<HTMLElement>) => {
    //Fixed css issue
    if (reportdial) {
      setReportdial(false);
    } else {
      setReportdial(true);
      setTimeout(() => {
        let el = document.getElementById("reportDiv");
        // console.log(el,"reportDiv");

        if (!el) {
          return showReports(e)
        }
        el.style.position = "absolute";
        el.style.left = (e.pageX - 100) + 'px';
        el.style.top = (e.pageY + 25) + 'px';
      }, 0);
    }
  }

  // const handleTopBarChange = (event:any) => {
  //   setTopBarValue(event.target.newValue);
  // }

  const handleTopBarChange = (event: any) => {
    setTopBarValue(event.target.value);

  }

  const generateReport = async (value: any) => {
    // console.log(value,"generate");
    try {
      let response;

      // let key;

      /* if (value === "Week (29Apr23 - 5May23)") {
        key = "Week1_29Apr23_5May23.pdf";
      } else if (value === "Week (6May23 - 12May23)") {
        key = "Week2_6May23_12May23.pdf";
      } else if (value === "Week (13May23 - 19May23)") {
        key = "Week3_13May23_19May23.pdf";
      } else if (value === "Week (20May23 - 26May23)") {
        key = "Week4_20May23_26May23.pdf";
      } else {
        key = "Week5_27May23_2Jun23.pdf";
      } */

      // response = await axios.get(
      //   `${assetBaseURL}/assets/customReports/download?key=${key}`
      // );
      response = await axios.get(
        `${assetBaseURL}/assets/customReports/download?key=${value}`
      );
      window.open(response.data.downnloadURL, "_blank");
    } catch (error) {
      console.error(error, "hello");
    }
  };


  const handleChangeView = (event: any, id: string) => {
    setSelectedOption(id);
    setSelectedOption1(event.target.value);
  }


  const handleShare = async () => {
    const shareInfo = await axios.post(`${assetBaseURL}/assets/shareURL`, { assetId: selectedAssetId })
    if (shareInfo?.data?.url && shareInfo?.data?.password) {
      setShowShareInfo(true);
      setShareInfo(shareInfo.data)
    }
  }



  const handleCopyClick = (content: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = content;

    // Make sure to make the textarea outside of the visible area
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');

    document.body.removeChild(textarea);

    setIsCopied(true);
  };


  const handleChangeReport = (event: any, name: string) => {
    setSelectedReport(name);
    setSelectedReport1(event.target.value);

  }
  // console.log(selectedReport1,"selectedReport1");

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDDialog(false)
          setShowShareInfo(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  const shareRef = useRef(null)
  useOutsideAlerter(wrapperRef);
  useOutsideAlerter(shareRef);

  return (
    <Box sx={{ display: 'flex', marginLeft: "-110px", marginTop: "-10px" }}>
      <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser} />
      <Box component={"main"} sx={{ flexGrow: 1, p: 3 }}>
        <div className={classes.root}>
          <div className={classes.pageTopBar}>

            <div style={{ height: "4rem", paddingTop: "13px", fontSize: "16px", fontWeight: 500 }}>
              <div style={{ float: "left", width: "20%", marginTop: "-8px", fontSize: "17px" }}>Operation Monitoring
                <div className={classes.addAsset}>

                  <Select
                    className={classes.select}
                    IconComponent={ExpandMore}
                    sx={{
                      height: "30px",
                      "& .MuiSelect-select": {
                        padding: "0.2rem"
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9fa0a1 !important",
                        borderWidth: "0px !important"
                      }
                    }}
                    style={{
                      fontSize: "13.3px", textAlign: "center", float: "left", height: '37px', border: "1px solid lightgrey",
                      backgroundColor: "white", width: '5.4rem', marginTop: '-26px', marginLeft: "260px", paddingLeft: "8px", color: "#000"
                    }}
                    value={topBarValue} onChange={handleTopBarChange}>

                    <MenuItem style={{ fontSize: "14px", color: "black" }} value={0} >ASSET </MenuItem>
                    {(userDetails.tenantName !== "ISE" && userDetails.tenantName !== "ISE foods") &&
                      <Link to={{ pathname: '/asset' }} style={{ textDecoration: 'none' }}> <MenuItem style={{ fontSize: "14px", color: "black" }} value={1} >FLEET </MenuItem> </Link>
                    }
                  </Select>
                </div>

              </div>

              <div className={classes.addNewAsset}>
                {topBarValue === 0 ?
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-10px" }}>

                    {userDetails.roles.includes('ADMIN') && <Button onClick={(e) => showDropdown(e)} id="multiple-formcontrol1"
                      style={{
                        textAlign: "left", float: "left", height: '2.5rem', width: '8rem',
                        marginTop: "-2px", paddingLeft: "6px", marginRight: '15px', border: "1px solid #CED4D9", textTransform: 'none',
                        backgroundColor: "#CED4D9", color: "#000000", fontWeight: "lighter"
                      }}>
                      View by<img src={viewBy} style={{ paddingLeft: "20px" }} />
                    </Button>}

                    {userDetails.roles.includes('ADMIN') &&
                      <input type="text"
                        disabled value={selectedOption1} style={{
                          border: "1px solid rgb(206, 212, 217)", borderRadius: "4px",
                          height: '2.5rem', width: "12rem", backgroundColor: "white", float: "left", paddingLeft: "14px",
                          marginTop: "-2px",
                          marginRight: "1rem", fontSize: "initial"
                        }} />}

                    {userDetails.roleName === "LithPwr" &&
                      <>
                        <Button variant="outlined" style={{
                          backgroundColor: "#0078A5", color: "#FFFFFF", opacity: 1,
                          width: "75px", borderRadius: "4px", marginTop: "-2px", border: "1px soid #0078A5", textTransform: "none", marginLeft: "2px", fontSize: "14px", height: "39px"
                        }} onClick={() => generateReport(selectedReport1)}>
                          Download
                        </Button>


                        <Button className={classes.report} onClick={(e) => showReports(e)}
                          style={{ backgroundColor: "#0078A5", color: "#FFFFFF", height: "39px", width: "25px", marginRight: "15px", marginLeft: "5px" }}
                        >
                          <ArrowDropDownIcon />
                        </Button></>}

                    <div style={{
                      border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", float: "left", backgroundColor: "white",
                      width: "160px", marginTop: "-2px",
                      paddingRight: "-10px", marginRight: "1rem", height: '2.5rem', marginLeft: "3px"
                    }}>

                      <img src={calendar} style={{ width: "24px", marginLeft: "10px", marginTop: "1px" }} />
                      <Select
                        labelId="demo-customized-select-label"
                        data-testid="period"
                        id="demo-customized-select"
                        value={period}
                        onChange={handleDropdownChange}

                        inputProps={{ IconComponent: "null" }}
                        input={<BootstrapInput />}
                        style={{ marginTop: "2.9px", marginLeft: "7px" }}

                      >
                        <MenuItem
                          style={{ color: "#000" }}
                          // onClick={() => handleChangeCustom('')}
                          value={"daily"}> Last  24 Hours</MenuItem>
                        <MenuItem
                          style={{ color: "#000" }}
                          // onClick={() => handleChangeCustom('')}

                          value={"weekly"}>Last 7 Days</MenuItem>
                        <MenuItem
                          style={{ color: "#000" }}
                          // onClick={() => handleChangeCustom('')}

                          value={"monthly"}>Last 30 Days</MenuItem>
                        <MenuItem
                          style={{ color: "#000" }}
                          onClick={() => handleChangeCustom('custom')}
                          value={"custom"}>Custom Date</MenuItem>

                      </Select>
                    </div>

                    <Link to={{ pathname: '/Addnewasset' }} style={{ color: "#fff", textDecorationLine: 'none', marginRight: "1rem", }}>
                      <Button variant="outlined" style={{
                        backgroundColor: "#0078A5", color: "#FFFFFF", opacity: 1,
                        width: "165px", borderRadius: "4px", marginTop: "-2px", border: "1px soid #0078A5", textTransform: "none", marginLeft: "2px", fontSize: "14px", height: "39px"
                      }}>
                        <AddIcon style={{ marginLeft: "-4px", marginRight: "3px", width: "20px" }} />
                        <span style={{ marginLeft: "3.4px" }}>Add New Asset</span>
                      </Button>
                    </Link>

                    <div style={{
                      border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", backgroundColor: "#fff",
                      width: "40px", marginLeft: "2.6px", marginRight: "24px", marginTop: "-4px", height: "41px"
                    }} onClick={pdfGen}
                    >
                      <img src={Download} style={{ width: "97px", marginLeft: "-30px", marginTop: "8px", height: "20px" }} />
                    </div>


                    <div style={{
                      border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", backgroundColor: "#fff",
                      width: "40px", marginLeft: "2.6px", marginRight: "24px", marginTop: "-4px", height: "41px"
                    }} >
                      <ShareIcon style={{ width: "97px", marginLeft: "-30px", marginTop: "8px", height: "20px" }} onClick={() => handleShare()} />
                      {showShareInfo && shareInfo ?
                        <div className={"shareInfo"} ref={shareRef}>
                          <div> <span>Url</span> : <span>{`${shareInfo.url}&password=${shareInfo.password}`} </span>
                            <ContentCopyIcon style={{ color: isCopied ? "#1976d2" : "black" }} onClick={() => handleCopyClick(`${shareInfo.url}&password=${shareInfo.password}`)} />
                          </div>
                        </div> : null}
                    </div>
                  </div>
                  : " "}


              </div>
              <div className={classes.tabsWrapper}>
                {topBarValue === 0 ?
                  <Paper className={classes.root} style={{ marginLeft: "-7px" }} >
                    <TabPanel className={classes.tabPanel} value={value} index={0} >
                      <AssetReeferTruck
                        pdfFlag={pdfFlag}
                        pdfGen={pdfGen}
                        fromAndTo={fromAndTo}
                        selectedCloseMarket={selectedOption}
                        period={period}
                        value={value}
                        handleLeftBarChange={handleChange}
                        setSelectedAssetId={setSelectedAssetId}
                      />
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={1} >
                      <AssetCoolerBox
                        pdfFlag={pdfFlag}
                        pdfGen={pdfGen}
                        filter={period}
                        value={value}
                        selectedCloseMarket={selectedOption}
                        handleLeftBarChange={handleChange}
                        setSelectedAssetId={setSelectedAssetId}
                      />
                    </TabPanel>
                  </Paper>
                  :
                  <Paper className={classes.root} style={{ marginTop: "3.5rem", boxShadow: "none" }}>
                    {/* <TabPanel className={classes.tabPanel} value={0} index={0} > */}
                    <EVOverview
                      pdfFlag={pdfFlag}
                      pdfGen={pdfGen}
                      fromAndTo={fromAndTo}
                      selectedCloseMarket={"All"}
                      period={period}
                      value={value}
                      handleLeftBarChange={handleChange}
                      topBarValue={topBarValue}
                    />
                    {/* </TabPanel> */}
                  </Paper>

                }
              </div>

            </div>
            <div>
              <Dialog
                open={customDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
              >
                <DialogTitle id="alert-dialog-title" style={{ color: '#000' }}>{"Custom Date"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <br />
                    <TextField
                      id="date"
                      data-testid="startDate"
                      label="Start date"
                      type="date"
                      sx={{ width: 220, marginBottom: "30px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errorText === true ? errorText : validationStart}
                      helperText={
                        errorText === true
                          ? "start date cannot be greater than end date"
                          : validationStart === true
                            ? "Select start date"
                            : ""
                      }
                      value={startDate}
                      onChange={(e) => setstartDate(e.target.value)}
                    />
                    &nbsp;&nbsp;
                    <TextField
                      id="date"
                      data-testid="endDate"
                      label="End date"
                      type="date"
                      // defaultValue="2017-05-24"
                      sx={{ width: 220, marginBottom: "30px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={endDate}
                      error={validationEnd === true ? validationEnd : endNotGreater}
                      helperText={
                        validationEnd === true
                          ? "Select end date"
                          : endNotGreater === true
                            ? "End date cannot be greater than todays date"
                            : ""
                      }
                      onChange={(e) => setendDate(e.target.value)}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button data-testid="okButton" onClick={handleDates}>
                    Ok
                  </Button>
                  <Button data-testid="cancelButton" onClick={handleCancel}>
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              {dDialog && viewByOptions.length ? (<div id="popupDiv" ref={wrapperRef}
                style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #CED4D9', width: '11rem', overflowY: "auto", height: "13rem", color: "#000000" }}>
                <div style={{ width: '100%' }}>
                  <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }}
                    onChange={(e) => handleChangeView(e, "All")} checked={selectedOption === 'All'} value="All" type="checkbox" name="all" />
                  <label htmlFor="all">All</label>
                </div>
                {
                  viewByOptions && viewByOptions.map(ele => {
                    return (
                      <>
                        <hr style={{ marginTop: '0.1px' }} />
                        <div style={{ width: '100%' }} >
                          <input className="checkBox_popup" style={{ width: '20px', height: '20px', marginRight: '20px' }}
                            checked={selectedOption1 === ele.name} onChange={(e) => handleChangeView(e, ele.id)}
                            type="checkbox" name={ele.name} value={ele.name} />
                          <label htmlFor={ele.id}>{ele.name}</label>
                        </div>
                      </>
                    )
                  })
                }
              </div>) : null}

              {reportdial && reportOptions.length ? (<div id="reportDiv" ref={wrapperRef}
                style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #CED4D9', width: '17rem', overflowY: "auto", height: "13rem", color: "#000000" }}>
                <div style={{ width: '100%' }}>
                  <input className="checkBox_popup" style={{ width: '20px', height: '15px', marginRight: '20px', marginBottom: '-150px' }}
                    onChange={(e) => handleChangeReport(e, "All")} checked={selectedReport === 'All'} value="All" type="checkbox" name="all" />
                  <label htmlFor="all">All</label>
                </div>
                {
                  reportOptions && reportOptions.map(ele => {
                    return (
                      <>
                        <hr style={{ marginTop: '0.1px' }} />
                        <div style={{ width: '100%' }} >
                          <input className="checkBox_popup" style={{ width: '15px', height: '20px', marginRight: '20px', marginBottom: '-30px' }}
                            checked={selectedReport1 === ele} onChange={(e) => handleChangeReport(e, ele)}
                            type="checkbox" name={ele} value={ele} />
                          <label htmlFor={ele} style={{ fontSize: '14px', marginBottom: '-30px' }}>{ele}</label>
                        </div>
                      </>
                    )
                  })
                }
              </div>) : null}
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(AssetDetails);