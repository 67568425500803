import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { Stepper, Step, StepLabel, Button, Grid, Paper, Box } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import checkoutFormModel from "../../../CheckoutPage/FormModel/checkoutFormModel";
import {
  createStyles,
  makeStyles,
  Theme,  
  withStyles,
} from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {  useParams, useLocation,useHistory, Link } from "react-router-dom";
import ShipperHelper, {
  ShippingRequestStatus,
} from "../../../../../services/_helpers";
import { assetBaseURL, marketBaseURL } from "../../../../../services/_url";

import UserService from "../../../../../services/userService";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import AssetDetails from "./AssetDetails";
import LocationDetails from "./LocationDetails";
import HardwareDetails from "./HardwareDetails"
import { toast } from "react-toastify";
import back from "../../../../../assets/images/back.svg";
import formInitialValues from "../../../Denso/CreateShipment/FormModel/formInitialValues";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { setCurrentUser } from "../../../../../redux/user/user.action";
import { selectCurrentUser } from "../../../../../redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { ResponsiveDrawer } from "../../../common/Drawer";

const steps = [" Basic Details","Location Details","Hardware Details"]
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginRight: "48px",
    },
    backBtn: {
      color: "#1A0DAB",
    },
  })
);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    color: "#5A5A5A",
  },
  active: {
    "& $line": {
      borderColor: "#0078A5",
      backgroundColor: "#0078A5",
    },
    color: "#5A5A5A",
  },
  paper: {
    textAlign: "center",
    color: "#000",
    minHeight: "15rem",
  },

  completed: {
    "& $line": {
      borderColor: "#007AA6",
    },
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: "#D8EEF3",
  },
})(StepConnector);

const { formId, formField } = checkoutFormModel;

function _renderStepContent(
  assetFormHandler : (name:string, value:string) => void,
  hardwareFormHandler : (name:string, value:string) => void,
  locationFormHandler : (name:string, value:string) => void,
  step: number,
  setFieldValue?: any,
  values?: any,
  getrequestId?: any,  
  locationData?:any,
  storedData?:any,
) {
  switch (step) {
    case 0:
      return <AssetDetails  formData={assetFormHandler} location={locationData}/>;
    case 1:
      return (
        <LocationDetails   formData={locationFormHandler} location={locationData}/>
      );
    case 2:
      return (
        <HardwareDetails
          getrequestId={getrequestId}
          formData={hardwareFormHandler}
          storedData={storedData}
          location={locationData}
        />
      );
    default:
      return <div>Internal Error.</div>;
  }
}

export  function HardwareAsset(props: any) {
  const id: any = useParams();
  let {   currentUser, setCurrentUser } = props;
  const location = useLocation();
  let history = useHistory();
  const item:any = location.state;
  // console.log(item,"item");
  // console.log(item[1],"item1");
  
  const { activeStepProp } = props;
  const [searchReqId, setSearchReqId] = useState<string>("");
  const [activeStep, setActiveStep] = useState(
    activeStepProp ? activeStepProp : 0
  );
  const [btnFlag, setBtnFlag] = useState(false);
  const [getrequestId, setgetrequestId] = useState("");
  const [tentant, SetTentant] = React.useState([]);
  const [assert, SetAssert] = useState([]);
  const isLastStep = activeStep === steps.length - 1;
  const classes = useStyles();
  const [locationDataHandler, setLocationDataHandler] = useState({locality:"", City:"", Zone:"", Region:""});
  const [assetDataHandler, setAssetDataHandler] = useState({AssetType:"",AssetName:"",AssetID:"",Status:"",id:"", CustomerAssetType: ""});
  const [hardwareDataHandler, setHardwareDataHandler] = useState({tagId:"",gatewayId:"",HardwareKitName:""});

  var updateInitalValues = {
    
  };

  const assetFormHandler = (name:string, value:string) => {
    //setAssetDataHandler(value);
    setAssetDataHandler(prevState => ({
      ...prevState, [name]:value
    }))
  }
  
  const hardwareFormHandler = (name:string, value:string) => {
    //setHardwareDataHandler(value);
    setHardwareDataHandler(prevState => ({
      ...prevState, [name]:value
    }))
  }
  
  
  const locationFormHandler = (name:string, value:string) => {
   // setLocationDataHandler(value);
   setLocationDataHandler(prevState => ({
    ...prevState, [name]:value
  }))
  }

  const success = () => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>New Hardware added successfully.</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const isUpdateMode = !!id["id"];
  //   const currentValidationSchema = validationSchema[activeStep];
  //   const updatevalidationSchema = UpdatevalidationSchema[activeStep];
  //   const [activeStep, setActiveStep] = useState(
  //     activeStepProp ? activeStepProp : 0
  //   );

  const getTransporterId: any = (name: any) =>
    tentant.filter((val: any) => val.name === name);

  function _submitForm(values: any, actions: any) {
    let Ship = new ShipperHelper();
    setBtnFlag(true);
   
     
 try {
  let postData = {
tagId: hardwareDataHandler.tagId !== "" ?  hardwareDataHandler.tagId : item[1] ,
gatewayId: hardwareDataHandler.gatewayId !== "" ?  hardwareDataHandler.gatewayId : item[2],
name :  hardwareDataHandler.HardwareKitName !== "" ?  hardwareDataHandler.HardwareKitName : item[0].name,
description:item[0].assetType.description,
asset:{
  id:assetDataHandler.id !== "" ? assetDataHandler.id : item[0].id,
  assetType:{
    id:assetDataHandler.id !== "" ? assetDataHandler.id : item[0].id,
    name:assetDataHandler.AssetType !== "" ? assetDataHandler.AssetType : item[0].assetType.name,
    description:item[0].assetType.description,
  },
  number:assetDataHandler.AssetID !== "" ? assetDataHandler.AssetID :  item[0].number,
  locality:locationDataHandler.locality && locationDataHandler.locality !== "" ? locationDataHandler.locality :  item[0].locality,
  zone:locationDataHandler.Zone && locationDataHandler.Zone !== "" ? locationDataHandler.Zone :  item[0].zone,
  city:locationDataHandler.City && locationDataHandler.City !== "" ? locationDataHandler.City : item[0].city,
  region:locationDataHandler.Region && locationDataHandler.Region !== "" ? locationDataHandler.Region :  item[0].region,
  underMaintenance:false,
}
  };
  // console.log(postData)
  createAPI(postData);
} catch (e) {
  return e;
}  
  }

  const createAPI = async(postData:any) => {
    let resultData = await axios.post(`${assetBaseURL}/sensors`,postData).then((res)=>{
     // return res.data;
     success();
    }).catch((err: any) => {});
  }
  
  function _handleSubmit(values: any, actions: any) {
    if (activeStep >= 2) {
      actions.setSubmitting(true);
      _submitForm(values, actions);
       history.push("/assetoverview");
    } else if (activeStep === 0) {
      if (getrequestId === "") {
        let Ship = new ShipperHelper();
        let requestId =
          values.shipper.substring(0, 3).toUpperCase() + Ship.requestNumber();
        setgetrequestId(requestId);

        setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      } else {
        setActiveStep(activeStep + 1);
        actions.setSubmitting(false);
        actions.setTouched({});
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
      actions.setTouched({});
    }
  }
  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <Box sx={{ display: 'flex',marginLeft:"-110px",marginTop:"-10px" }}>
       <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
       <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <Fragment>
      <div className={classes.root}>
        <Formik
          initialValues={isUpdateMode ? updateInitalValues : formInitialValues}
          enableReinitialize={true}
          data-testid="checkoutFormikInitialisation"
          // validationSchema={
          //   isUpdateMode ? updatevalidationSchema : currentValidationSchema
          // }
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue }) => (
            <Form id={formId}>
              <Grid container spacing={3} style={{ marginBottom: '1rem' }}>
                <Grid item xs={5} style={{ marginTop:"-20px"}}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    data-testid="backToDenso"
                    onClick={() => {
                      window.history.back();
                    }}
                    style={{ color: "#5A5A5A", cursor: "pointer" }}
                  >
                    {/* <ArrowBackIcon /> */}
                  </IconButton>
                  <span style={{ fontSize: "16px", color:"#000000",marginLeft:"1rem"}}>
                    {isUpdateMode ? "Update" : ""}Attach New Hardware
                  </span>
                </Grid>
                <Grid item xs={7}>
                <div style={{ float: "right",marginRight:"22px",marginTop:"-10px" }}>
                    <Grid item xs={8}>   
                          <div >
                          {activeStep >= 1 ?
                          <Button
                      variant="outlined"
                      onClick={_handleBack}
                      disabled={activeStep === 0}
                      style={{textDecoration:"none",width:"65px",
                      marginLeft:(isLastStep ? "465px": "550px"),
                      
                    
                      height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"10px",textTransform: 'capitalize'
                      ,backgroundColor:"#fff",color:"grey"}}
                      className={activeStep === 0 ? "" : classes.backBtn}
                    >
                          <ArrowBackIcon  style={{marginLeft:"-3px",fontSize:"15px"}}/>
                      <span style={{marginLeft:"3px"}}>Back</span> 
                    </Button>: 
                    <Link to={{ pathname: '/assetoverview'}}><button style={{textDecoration:"none",width:"65px",marginLeft:"550px",
             
                    
                    height:"32px",borderRadius:"3px",border:"1px solid 	#D0D0D0",fontSize:"13px",marginTop:"10px",textTransform: 'capitalize'
                    ,backgroundColor:"#fff",color:"grey"}}>
                    
                       <ArrowBackIcon  style={{marginLeft:"-2px",fontSize:"15px"}}/>
                     <span style={{marginRight:"2px"}}>    Back</span>
                
                   </button></Link>
                      
                    }
                    
                   </div>
                   </Grid>
                    <Button
                      disabled={btnFlag}
                      type="submit"
                      data-testid="checkoutSubmit"
                      variant="outlined"
                      style={{
                        marginTop:"-32px",
                       
                        width: (isLastStep ? '150px' : '65px'),
                        marginLeft:(isLastStep ? "540px" : "630px"),
                        height: '32px',
                        color:'#fff',
                        backgroundColor: "#007AA6",
                        fontSize:"13px",
                        border: 0,
                        textTransform:'capitalize'
                      }}
                    >
                      {isLastStep ? (
                        <>
                        <CheckCircleOutlineIcon style={{marginRight:"7px",fontSize:'18px',marginTop:"-1px"}}/>
                          {isUpdateMode ? "Update" : "Finish and Add"}
                        </>
                      ) : (
                        <>
                          Next
                        <ArrowForward style={{marginLeft:"6.5px",fontSize:'15px'}}/>
                        </>
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <Paper style={{marginTop:"-2px",border:"1px solid #fff",boxShadow:'none'}}>
              <Stepper
                style={{ borderRadius:"3px",height:"90px",marginLeft:"95px",marginRight:'50px'}}
            
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label} style={{marginTop:"-19px"}}>
                    <StepLabel >
                      <span style={{fontSize:"14px"}}>{label}</span></StepLabel>
                    
                  </Step>
                ))}
                
              </Stepper>
              
              </Paper>
            
             {/* <div>
             <p style={{marginLeft:"9.5rem",color:"#707070",marginTop:"-36px"}}>
              Basic Details </p>
              <p  style={{marginLeft:"660px",color:"#707070",marginTop:"-37px"}}>Location Details</p>
         
              <p  style={{marginTop:"-37px",marginLeft:"72.4rem",color:"#707070"}}>Hardware Details</p>
             
             </div> */}
              <Paper className="Paper" style={{boxShadow:"none", marginTop:"-18px"}}>
                {_renderStepContent(
                   assetFormHandler,
                   hardwareFormHandler,
                   locationFormHandler,
                  activeStep,
                  setFieldValue,
                  values,
                  getrequestId,
                  item                 
                )}
              </Paper>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
    </Box>
    </Box>
  )
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)( HardwareAsset);