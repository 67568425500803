import React, { Fragment, useState } from "react";
import {  Grid} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { InputField } from "../../../common/FormFields";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export default function LocationDetails(props: any) {

  const [locationState, setLocationState] = useState({locality:"", City:"", Zone:"", Region:""});

  const classes = useStyles();

  const changeHandler = (e:any) => {
    const {name, value} = e.target;
    setLocationState(prevState => ({
      ...prevState, [name]:value
    }))
    props.formData(name, value);
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement" style={{marginTop:"21px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} md={6}>
                  <p style={{ fontSize: "16px",color:"#000" }} >
                    Location Information
                  </p>
                </Grid>
                <Grid item xs={12}>
                 
                  <InputField
                  name="locality"
                  label="Locality"
                  fullWidth
                  onChange={changeHandler}
                  value={props.location[0].locality}
                // disabled={props.values.assetData  ? false : true}
                />
                <br />
                 
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    name="City"
                    label="City"
                    fullWidth
                    onChange={changeHandler}
                    value={props.location[0].city}
                  // disabled={props.values.assetData  ? false : true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <Grid container spacing={2} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <InputField
                    name="Zone"
                    label="Zone"
                    fullWidth
                    onChange={changeHandler}
                    value={props.location[0].zone}
                  // disabled={props.values.assetData  ? false : true}
                  />
                  <br/>
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    name="Region"
                    
                    label="Region"
                    fullWidth
                    onChange={changeHandler}
                    value={props.location[0].region}
                  // disabled={props.values.assetData ? false : true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
}