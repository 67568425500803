import React from 'react';
import Plot from 'react-plotly.js';//is
import './style.css';


interface DialogTitle{
  title: string;
  width:string;
  height:string
  dataX:any;
  dataY:any;
  xlabels:boolean;
  ylabels:boolean;
  mode:any;
  yaxisTitle:string;
  xaxisTitle:string;
  titleName:string;
  showlegend:boolean;
  legendName:string;
}

export class Graph extends React.Component<DialogTitle> {	
  constructor(props:DialogTitle){
    super(props);
    this.state = {
        data: this.props.title,
        width:this.props.width,
        height:this.props.height
    }
}
    render() {
    let nticks:any = 6
    let range:any = []

    if((this.props.titleName === "Lid Open")){
      nticks = 2
    }
    else if((this.props.titleName === "Shock")){
      nticks = 2
      range =[0,1.3]
    }
     return (
        <div>
           <Plot
        data={[
          {
            mode:this.props.mode,
            line: {shape:'linear',color:'#A6CEE3'}, 
            type: 'scatter',
            connectgaps: true,
            name:this.props.legendName,
            x: this.props.dataX, y: this.props.dataY},
           
        ]}
        layout={ 
          {width: parseInt(this.props.width),
             height: parseInt(this.props.height),
              title: this.props.title,
              showlegend:this.props.showlegend,
          annotations: [  
            {
            xref: 'paper',
            yref: 'paper',
            x: 1.07,
            xanchor: 'right',
            y: 0.05,
            yanchor: 'bottom',
            text: this.props.xaxisTitle,
            font: {
                size: 12
              },
            showarrow: false
          },
            {
                showarrow: false,
                text: this.props.yaxisTitle,    // #layout-annotations-text
                x: -0.04,                        // #layout-annotations-x
                xref: 'paper',                // #layout-annotations-xref
                y: 1.1,                         // #layout-annotations-y
                yref: 'paper',
                yanchor: 'top',
               
              }
            ],
          xaxis:{autorange:true,nticks:3},
        yaxis: {
    zeroline: false,
    showline: false,
    nticks: nticks,
    showticklabels: this.props.ylabels,
     range:range
     
         
        },
       
      margin:{l:50,r:60,t:30,b:68}} }
        config={{displayModeBar: false,responsive: true,displaylogo: false}}
      />
        </div>
      );
    }
  }
 
