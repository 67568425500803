import { Route, Switch, BrowserRouter as Router, Redirect, useHistory } from "react-router-dom";
import './App.css';
import { createBrowserHistory } from 'history';
import ResponsiveDrawer from './web/components/common/Drawer/index';
import ShipperLanding from './web/components/ShipperLanding/index';
import  RequestDetails  from "./web/components/ShipperLanding/requestDetails";
import TransporterLanding from "./web/components/TransporterLanding/index";
import TransporterDetails from './web/components/Utils/requestDetails';
import CheckoutPage from './web/components/CheckoutPage';
import 'font-awesome/css/font-awesome.min.css';
import Login from "./web/components/Login";
import Dashboard from "./web/components/Dashboard";
import AssetOverView from "./web/components/AssetManagement/common/overview";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from "./redux/user/user.selector";
import { setCurrentUser } from "./redux/user/user.action";
import React, { useEffect, useState } from "react";
import UserService, { ROLE } from "./services/userService";
import { toast, ToastContainer } from "react-toastify";
import Denso from './web/components/Denso/index';
import DensoCreateShipment from './web/components/Denso/CreateShipment/index';
import AssetComponent from "./web/components/AssetManagement";
import { useLocation } from "react-router-dom";
import UpdatePassword from './web/components/Login/updatePassword';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import HardwareAsset from "./web/components/AssetManagement/common/Forms";
import EditHardwareAsset from "./web/components/AssetManagement/common/EditHardwareForm";
import AddHardwareAsset from "./web/components/AssetManagement/common/AddNewAssetForm";
import ChangePassword from './web/components/common/General/ChangePassword'
import CreateAsset from './web/components/AssetManagement/CreateAsset/index';
import DriverData from './web/components/common/General/DriverData';
import Asset from "./web/components/AssetManagement/asset";
import BulkUploadPage from "./web/components/Denso/Requests/BulkUploadPage";
import ViewUploadPage from "./web/components/Denso/Requests/Viewuploadindex"
import ViewPendingTransfers from "./web/components/Denso/Requests/ViewPendingTransfers"
import OverviewComponent from "./web/components/AssetManagement/common/overview";
import EditAsset from "./web/components/AssetManagement/common/AddNewAssetForm/indexedit";
import PrivacyAndPolicy from "./web/components/PrivacyAndPolicy";

import  AssetDetails  from "./web/components/AssetManagement/assetpath";
import { RoutePlanning } from "./web/components/Utils/PlanRoute/routePlanning";



toast.configure({
  autoClose: 5000,
});


export function App(props: any) {
  let { currentUser, setCurrentUser } = props;
  const location = useLocation();
  const [searchReqId, setSearchReqId] = useState<string>("");
  const userApi = new UserService();
  const history = useHistory();
  const userDetails = userApi.getUserDetails();
  const [shouldApplySearch, setShouldApplySearch] = useState<boolean>(false);
  const [messageOpen, setmessageOpen] = useState(false)
// console.log(props, "props")
  useEffect(() => {
    if (userDetails?.token) {
      const isExpired = userApi.checkTokenExpiration(userDetails.token);
      if (isExpired) {
        // expired user
        setmessageOpen(true)
        setCurrentUser(null);
        userApi.logout(history);
       
      } else {
        // current user
        setCurrentUser({
          token: userDetails.token,
          email: userDetails.email,
          roles: userDetails.roles
        });
        // console.log(currentUser,"currentuser")
      }
    } else {
      // not a user
      userApi.logout(history);
    }
  }, []);
  useEffect(() => {
    if (searchReqId && shouldApplySearch) {
      const path = location.pathname;
      if (path !== '/denso' && (path.includes('denso') || path.includes('dashboard') || path.includes('asset'))) {
        setShouldApplySearch(false);
        history.push('/denso');
      } else if (path !== 'shipper' && (path.includes('shipper') || path === '/createshipment' || path.includes('/updateshipment'))) {
        history.push('/shipper');
      } else if (path !== 'tranporter' && (path.includes('transporter'))) {
        history.push('/transporter');
      }
    }
  }, [searchReqId])
  return (
    <div className="App" >
      <ToastContainer />
      <Router>
        <div id='headerSpace'>
          <Switch>
            <Route exact path="/" render={() => userDetails?.token && currentUser?.roles?.includes(ROLE.denso) ?
              (<Redirect to="/asset"/>) : userDetails?.token && currentUser?.roles?.includes(ROLE.shipper) ?
                (<Redirect to="/shipper" />) : userDetails?.token && currentUser?.roles?.includes(ROLE.transporter) ? (<Redirect to="/transporter" />) : <Login />} />
            <Route exact path="/updatePassword">
              <UpdatePassword />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyAndPolicy />
            </Route>
            <Route exact path="/changePassword">
              <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
              <ChangePassword />
            </Route>
            <Route exact path="/AddNewDriver">
              <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
              <DriverData/>
            </Route>
            
            <Route exact path="/shipper">
          
              <ShipperLanding searchReqId={searchReqId} />
            </Route>
            <Route exact path="/shipper/:id">
             
              <RequestDetails />
            </Route>
            <Route exact path="/denso/:id/:assetId">
             
              <RequestDetails />
            </Route>
            <Route exact path="/transporter">
         
              <TransporterLanding searchReqId={searchReqId} />
            </Route>
            <Route exact path="/createshipment">
              
              <CheckoutPage />
            </Route>
            <Route exact path="/denso">
             
              <Denso searchReqId={searchReqId} />
            </Route>
            <Route exact path="/densocreateshipment">
             
              <DensoCreateShipment />
            </Route>
            <Route exact path="/Editasset">

              <EditAsset />
            </Route>
            {/* <Route exact path="/Editasset">
              <ResponsiveDrawer
                setSearchReqId={setSearchReqId}s
                disableSearch={true}
              />
              <EditAssets />
            </Route> */}
            <Route exact path="/createasset">
            <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
              <CreateAsset />
              </Route>
            <Route exact path="/Bulkupload">
             
             <BulkUploadPage />
            </Route>
            <Route exact path="/viewuploadstatus">
              
             <ViewUploadPage />
            </Route>
            <Route exact path="/viewPendingTransfers">
              
             <ViewPendingTransfers />
            </Route>
            <Route exact path="/transporter/:id">
              <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
              <TransporterDetails />
            </Route>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            <Route exact path="/overView">
              <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
              < AssetOverView />
            </Route>
            <Route exact path="/assetoverview">
              {/* <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} /> */}
              < AssetDetails/>
            </Route>
            <Route exact path="/asset/">
              {/* <Box sx={{ display: 'flex' }}>
              <Box component="main" sx={{flexGrow:1,p:3}}>  */}
            {/* <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} /> */}
              <AssetComponent />
              {/* </Box>
              </Box> */}
            </Route>
            <Route exact path="/AssetType">
              <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
              <Asset />
              </Route>
            <Route exact path="/assetOverview/">
              <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
              <OverviewComponent />
            </Route>
            {/* <Route exact path="/createshipment" component={CheckoutPage} /> */}
            <Route path="/updateshipment/:id" >
              <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true}/>
              <CheckoutPage />
            </Route>
            <Route path="/densoupdateshipment/:id" >
              {/* <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} /> */}
              <DensoCreateShipment />
            </Route>
            <Route exact path="/hardwareasset">
            
              <HardwareAsset />
            </Route>
            <Route exact path="/Edithardwareasset">
           
              <EditHardwareAsset />
            </Route>
            <Route exact path="/Addnewasset">
             
              <AddHardwareAsset />
            </Route>
            <Route exact path="/RoutePlanning">
            <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} />
             <RoutePlanning />
            </Route>
          </Switch>
        </div>
      </Router>
      <div>
        <Snackbar
          open={messageOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          onClose={() => setmessageOpen(false)}
        >
          <Alert variant="filled" severity="warning">Your session has expired, please login again.</Alert>
        </Snackbar>
      </div>
    </div>
  );
} 

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(App);

