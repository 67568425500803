import axios from 'axios';
import { mapMyIndiaClientId, mapMyIndiaClientSecret, telemetry } from './_url';

export default class mapMyIndiaApi {


    //20Sep23 - commented the below two functions, they are not required

    /*
    getAccessTokenMapMyIndia = async() => {
        let mapId = '33OkryzDZsJ1FJwtagLd4T7oiEvxK1jRThs8z3RO24eJNsixmKc2mZNHdqZ7qFSR3LQJ8QcJakRxw3bHPKPuNw==';
        let secret = 'lrFxI-iSEg9bpXxEmmny1wsooNZjEoXrtRDiJ2k96-748o2lWLoa6zFG1g4hMNPPQT7XDrHqmapdSk6B6DLFY2470IyqSIY2';
        try{
            const resultData = await fetch(`https://outpost.mapmyindia.com/api/security/oauth/token?grant_type=client_credentials&client_id=${mapId}&client_secret=${secret}`, {
                method: 'POST',
            });
            let tokenObj = await resultData.json();
            if(tokenObj.access_token){
                sessionStorage.setItem('mapAccToken', JSON.stringify(tokenObj.access_token));
                return tokenObj.access_token;
            }
        } catch(error) {
            console.log(error)
        }   
    }
    */

    /*
    getELocforAddress = async (address: any) => {
        try {
            let tokenStr = await this.getAccessTokenMapMyIndia();
            const resultData = await fetch(`https://atlas.mapmyindia.com/api/places/geocode?address=${address}`, {
                method: 'GET',
                headers: {"Authorization" : `Bearer ${tokenStr}`},
            });
            let res = resultData.json();
            return res;
        } catch(error) {
            console.log(error)
        }
    }
    */

    getLatitudeAndLongitude = async(address: any) => {
        try{
            // let tokenStr = await this.getAccessTokenMapMyIndia();
           
            // const resultData = await fetch(`https://explore.mappls.com/apis/O2O/entity/${eLoc}`, {
            //     method: 'GET',
            //     headers: {"Authorization" : `Bearer ${tokenStr}`},
            // });
            // let res = resultData.json();
            // if(res.response_code === 401) {
            //     let token = await this.getAccessTokenMapMyIndia();
            //     if(token) {
            //         return await this.getLatitudeAndLongitude(eLoc);
            //     }
            //     return '';
            // }
            // console.log(res, "depa");
            //console.log(resultData);
            //update api
            let res = await axios.get(`${telemetry}/telemetry/mmi/geocode?address=${address}`);
            
            
            return res.data;
        } catch(error) {
            console.log(error)
        }
    }
    getDrivingTripEtaDetails = async(locString: any) => {
        try{
            
            let res = await axios.get(`${telemetry}/telemetry/mmi/planRoute?address=${locString}`);
            return res.data;
        } catch(error) {
            console.log(error)
        }
    }
}