import { makeStyles, MenuItem, Paper, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import "./style.css";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Shipmentstrend from "../Common/Charts/BarCharts/Shipmentstrend";


import ShipmentsOverview from "../Common/Charts/SunburstCharts/ShipmentsOverview";
import TripsVsAvgFreshnessScore from "../Common/Charts/GaugeChart/TripsVsAvgFreshnessScore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DashboardTopFiveShipper from "../Common/TopFive/TopFiveShipper";
import DashboardChartAPI from "../../../../services/ChartServices";
import ShipperHelper from "../../../../services/_helpers";
import coldchainLog from "../../../../services/consoleHelper";
import { marketBaseURL } from "../../../../services/_url";
import axios from "axios";
import CPU from "../Common/Charts/BarCharts/CPU";
import Forecastedtraffic from "../Common/Charts/BarCharts/Forecastedtraffic"
import { PropertyDescriptorParsingType } from "html2canvas/dist/types/css/IPropertyDescriptor";
import { height } from "@mui/system";
import Users from "../Common/TopFive/Users";
import { data } from "../../../../test/Denso/data";
import { authBaseURL } from "../../../../services/_url";
import RequestPerSecond from '../../../../assets/images/RequestPerSecond.svg'
import ErrorRate from '../../../../assets/images/ErrorRate.svg'
import calendar from '../../../../assets/images/calendar.svg'
import AverageLatency from '../../../../assets/images/AverageLatency.svg'
import successcount from "../../../../assets/images/successcount.svg"
import failcount from "../../../../assets/images/failcount.svg"
import { Stack } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "justify",
    color: theme.palette.text.primary,
  },
  placeHolder: {
    transform: "rotate(-15deg)",
    display: "block",
    paddingTop: "2rem",
  },
}));

export interface IDashboardShipmentsProps {
  timeDuration: string;
  data: any;
  pdfStatechange: any;
  startAndEnd: any;
  selectedClosedMarket: any;
}

export default function DashboardInfrastructure(props: IDashboardShipmentsProps) {
  const flagvalue = props.data;
  const { timeDuration } = props;
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [timePeriod, setTimePeriod] = useState("daily");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [startAndEnd, setStartAndEnd] = useState("");
  // const {selectedClosedMarket } =props;
  const classes = useStyles();

  const dateselect = new ShipperHelper();
  const getShipmentAPI = new DashboardChartAPI();

  //Top5Shipper
  const [top5RowData, settop5RowData] = useState([]);
  const [top5TotalRequest, settop5TotalRequest] = useState(0);
  const [noDataTop5, setNoDataTop5] = useState("");
  const [tenantData, setTenantData] = useState([]);

  //ShipmentsOverview
  const [shipmentOverview, setshipmentOverview]: any = useState({
    type: "sunburst",
    labels: [],
    parents: [],
    values: [],
    branchvalues: "total",
    textinfo: "none",
    marker: {
      line: {
        width: 0,
      },
      colors: [],
    },
  });
  const [legendNames, setLegendNames] = useState([]);
  const [noDataShipment, setNoDataShipment] = useState("");

  //Avg FreshnessScore
  const [tripCount, setTripCount] = useState(0);
  const [avgScore, setAvgScore] = useState("");

  //Shipment Trend
  const [shipmentTrendData, setShipmentTrendData] = useState([]);
  const [shipmentTrendDataXaxis, setShipmentTrendDataXaxis] = useState([]);
  const [noDataShipmentTrend, setNoDataShipmentTrend] = useState("");
  const [served, setServed] = useState<any>([]);
  const [total, setTotal] = useState([]);
  const [dropped, setDropped] = useState<any>([]);
  const [success, setSuccess] = useState<any>([]);
  const [fail, setFail] = useState<any>([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let datesForShipment = "";
    if (timeDuration === 'custom') {
      datesForShipment = props.startAndEnd;

    } else {
      datesForShipment = dateselect.DashboardDate(timeDuration);
      // console.log(datesForShipment, "dataeforshipemnt")
    }
    let scount: any = [];
    let data: any = {
      "from": "2022-12-16",
      "to": "2022-12-21"
    };
    axios.get(`${authBaseURL}/login-attempt/count`).then((res: any) => {
      scount = res.data.sucessCount;
      // console.log(scount);

      setSuccess(res.data.sucessCount);
      setFail(res.data.failureCount);
      // console.log(served);
      success.map((item: any) => {
        item.sucessCount = scount;
      })
      // console.log(scount);
    }).catch((err: any) => {
      console.error("err", err)
    });

    axios.get(`${authBaseURL}/login-attempt/search?from=2022-12-16&to=2022-12-21`

    ).then((res1: any) => {
      // console.log(res1.data);
      setUsers(res1.data);
    }).catch((err: any) => {
      console.error("err", err)
    })
    getShipmentAPI
      .getShipmentsTrend(datesForShipment, props.selectedClosedMarket)
      .then((res: any) => {

        setShipmentTrendData(res);
        // console.log(res);
        let dates = Object.keys(res);
        setShipmentTrendDataXaxis(dates);
        let servedy: any = [];
        let totaly: any = [];
        let droppedy: any = [];
        Object.keys(res).forEach(function (key) {
          servedy = [...servedy, res[key].served];
          totaly = [...totaly, res[key].total];
          droppedy = [...droppedy, res[key].dropped];
          coldchainLog("ss", res[key].dropped);
        });
        setServed(servedy);
        setTotal(totaly);
        setDropped(droppedy);
        coldchainLog("resdrop", droppedy);
      })
      .catch((err: any) => {
        setNoDataShipmentTrend("No Data To Display.");
        const UserToken = new ShipperHelper();
        UserToken.UserTokenExpiration();
      });
  }, [timeDuration, props.startAndEnd, props.selectedClosedMarket, marketBaseURL]);

  useEffect(() => {
    if (flagvalue === true) {
      props.pdfStatechange();
      pdfGenerator();
    }
  }, [flagvalue]);

  const pdfGenerator = () => {
    const DashboardTopFivePDF = document.getElementById("DashboardTopFivePDF");
    const ShipmentsOverviewPDF = document.getElementById(
      "ShipmentsOverviewPDF"
    );
    const ShipmentstrendPDF = document.getElementById("ShipmentstrendPDF");
    const TripsVsAvgFreshnessScorePDF = document.getElementById(
      "TripsVsAvgFreshnessScorePDF"
    );
    /*istanbul ignore next*/
    html2canvas(DashboardTopFivePDF).then((canvasDashboardTopFivePDF) => {
      html2canvas(ShipmentsOverviewPDF).then((canvasShipmentsOverviewPDF) => {
        html2canvas(ShipmentstrendPDF).then((canvasShipmentstrendPDF) => {
          html2canvas(TripsVsAvgFreshnessScorePDF).then(
            (canvasTripsVsAvgFreshnessScorePDF) => {
              // html2canvas(placeHolderPDF)
              //   .then((canvasplaceHolderPDF) => {
              const imgDashboardTopFive =
                canvasDashboardTopFivePDF.toDataURL("image/png");
              const doc = new jsPDF();
              doc.addImage(imgDashboardTopFive, "PNG", 5, 5, 60, 70);

              const imgShipmentsOverview =
                canvasShipmentsOverviewPDF.toDataURL("image/png");
              doc.addImage(imgShipmentsOverview, "PNG", 70, 5, 150, 70);

              const imgShipmentstrend =
                canvasShipmentstrendPDF.toDataURL("image/png");
              doc.addImage(imgShipmentstrend, "PNG", 5, 70, 200, 80);

              const imgTripsVsAvgFreshnessScore =
                canvasTripsVsAvgFreshnessScorePDF.toDataURL("image/png");
              doc.addImage(imgTripsVsAvgFreshnessScore, "PNG", 5, 150, 70, 70);

              // const imgsplaceHolder = canvasplaceHolderPDF.toDataURL("image/png")
              // doc.addImage(imgsplaceHolder, 'PNG', 70, 190, 150, 70)

              doc.save("DashboardShipments.pdf");
              // })
            }
          );
        });
      });
    });
  };
  const handleChangeCustom = (data: any) => {

  }
  const handleTimePeriodChange = (event: any) => {
    if (event.target.value === "custom") {
      setcustomDialog(true);
    } else {
      setTimePeriod(event.target.value);
      setstartDate("");
      setendDate("");
      setStartAndEnd("");
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={8} >
          <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginTop: "-17px", height: "35.6rem" }}>
            <div id="DashboardTopFivePDF" style={{ height: '510px', padding: "20px", fontWeight: "normal", color: "#000", fontSize: "14px" }}>
              Hits per GEO
              {/* <DashboardTopFiveShipper
                top5RowData={top5RowData}
                top5TotalRequest={top5TotalRequest}
                noData={noDataTop5}
                selectedClosedMarket={props.selectedClosedMarket}
              /> */}
            </div>
          </Paper>
        </Grid>

        <Grid item xs={4} style={{ height: '510px', padding: "20px", fontWeight: 500, marginTop: "-12px" }}>
          <Grid container spacing={2} >
            <Grid item xs={12} style={{ marginRight: "-22px", marginLeft: "10px" }}>
              <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginTop: "-17px", marginLeft: "-10px" }} >
                <div id="DashboardTopFivePDF " style={{ padding: "20px", color: "#000" }} >
                  Request Per Second
                  <div style={{ paddingTop: "19px", display: "flex", alignItems: "center" }}>
                    <img src={RequestPerSecond} />
                  </div>
                  <div style={{ marginTop: "-76px", display: "flex", alignItems: "center", marginLeft: "105px" }}>
                    <p style={{ fontSize: "xxx-large", fontWeight: "normal", color: "#707070" }}>9.2 K</p>
                  </div>
                  {/* <ShipmentsOverview
                shipmentOverview={shipmentOverview}
                legendNames={legendNames}
                noDataShipment={noDataShipment}
                selectedClosedMarket={props.selectedClosedMarket}
              /> */}
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12}>
              <Paper className={classes.paper + " p-0"} style={{ marginRight: "-10px", marginLeft: "-1px", marginTop: '4px', boxShadow: "none" }}>
                <div id="ShipmentstrendPDF" style={{ padding: "20px", color: "#000" }}>
                  Error Rate
                  <div style={{ paddingTop: "19px", display: "flex", alignItems: "center" }}>
                    <img src={ErrorRate} />
                  </div>
                  <div style={{ marginTop: "-76px", display: "flex", alignItems: "center", marginLeft: "105px" }}>
                    <p style={{ fontSize: "xxx-large", fontWeight: "normal", color: "#707070" }}>46.6 %</p>
                  </div>
                  {/* <Shipmentstrend
                data={shipmentTrendData}
                noDataShipmentTrends={noDataShipmentTrend}
                xaxis={shipmentTrendDataXaxis}
                served={served}
                total={total}
                dropped={dropped}
               
              /> */}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper + " p-0"} style={{ marginRight: "-10px", marginLeft: "-1px", marginTop: '4px', boxShadow: "none" }}>
                <div id="ShipmentstrendPDF" style={{ padding: "20px", color: "#000" }}>
                  Average Latency
                  <div style={{ paddingTop: "19px", display: "flex", alignItems: "center" }}>
                    <img src={AverageLatency} />
                  </div>
                  <div style={{ marginTop: "-76px", display: "flex", alignItems: "center", marginLeft: "105px" }}>
                    <p style={{ fontSize: "xxx-large", fontWeight: "normal", color: "#707070" }}>6.6 s</p>
                  </div>
                  {/* <Shipmentstrend
                data={shipmentTrendData}
                noDataShipmentTrends={noDataShipmentTrend}
                xaxis={shipmentTrendDataXaxis}
                served={served}
                total={total}
                dropped={dropped}
               
              /> */}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ height: '510px', padding: "20px", fontWeight: 500, marginTop: "-11px", marginLeft: "-10px", color: "#000" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginTop: "3px" }}>
                <div id="ShipmentstrendPDF" style={{ padding: "20px", fontWeight: "normal", color: "#000" }} >
                  Uptime SLO
                  <Grid item xs={7} style={{marginLeft:'8.5rem',marginTop:"-22px"}}>
                      <Stack spacing={0} direction="row" style={{ float: "right" }}>
                        <div style={{
                          border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", backgroundColor: "white",
                          width: "168px", marginTop: "-6px",
                          paddingRight: "-10px", marginLeft: "20rem", position: "sticky"
                        }}>
                          <img src={calendar} style={{ width: "24px", marginLeft: "10px", marginTop: "-1px" }} />
                          <Select
                            style={{ marginTop: "3.5px", marginLeft: "6px" }}
                            inputProps={{ IconComponent: "null" }}
                            labelId="demo-customized-select-label"
                            data-testid="period"
                            id="demo-customized-select"
                            value="daily"
                            onChange={handleTimePeriodChange}

                          >
                            <MenuItem
                              style={{ color: "#000" }}
                              onClick={() => handleChangeCustom('')}

                              value={"daily"}>Last 24 Hours</MenuItem>
                            <MenuItem
                              onClick={() => handleChangeCustom('')}
                              style={{ color: "#000" }}
                              value={"weekly"}>Last 7 Days</MenuItem>
                            <MenuItem
                              onClick={() => handleChangeCustom('')}
                              style={{ color: "#000" }}
                              value={"monthly"}>Last 30 Days</MenuItem>
                            <MenuItem
                              onClick={() => handleChangeCustom('custom')}
                              style={{ color: "#000" }}
                              value={"custom"}>Custom Date
                            </MenuItem>

                          </Select>

                        </div>
                      </Stack>
                    </Grid>
                  <p style={{ fontSize: "xxx-large", fontWeight: "normal", color: "#707070", paddingTop: '10px' }}>98.2 %</p>
                  <p style={{ marginLeft: "2px", fontSize: "15px", color: "#969696", marginTop: "10px" }}>
                    91% target
                  </p>
                
                </div>
              </Paper>
            </Grid>


            <Grid item xs={12} md={12} >
              <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginTop: "6px", height: "24.2rem" }}>
                <div id="ShipmentstrendPDF" style={{ color: "#000", fontSize: "14px" }}>
                  {/* Forecasted Traffic */}
                  <Forecastedtraffic
                  

                    data={shipmentTrendData}
                    noDataShipmentTrends={noDataShipmentTrend}
                    xaxis={shipmentTrendDataXaxis}
                    served={served}
                    total={total}
                    dropped={dropped}

                  />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}  >
              <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginTop: "7px", height: "12.4rem" }}>

                <div style={{
                  fontSize: "14px", fontWeight: "normal", color: "#000",
                  marginLeft: '20px', paddingTop: "20px"
                }}>
                  Successful Login Attempts

                </div>
                {/* <CheckCircleOutlineIcon style={{
                      alignContent: "center",
                      color: "#00cc66",
                      background: "#e6f3ff",
                      display: "flex",
                      borderRadius: "50vh",
                      width: "25%",
                      height: "25%",
                      padding: "5%"
                    }} /> */}

                <img src={successcount} style={{ height: "90px", width: "90px", marginLeft: "8px", marginTop: "16px" }} />
                <div style={{ marginTop: "-90px" }}>
                  <span style={{
                    fontSize: "35px",
                    color: "#707070",

                    display: "flex",
                    fontWeight: "normal",
             
                    marginLeft: '120px'
                  }}>
                    {success}
                  </span>
                </div>
                <div style={{
                  fontSize: "14px", fontWeight: "normal", color: "#969696",
                  marginLeft: '120px'
                }}>
                  Successful Login Attempts into
                  Denso
                </div>
                <div style={{
                  fontSize: "14px", fontWeight: "normal", color: "#969696",
                  marginTop: "4px", marginLeft: '120px'
                }}>Cold Chain Application
                </div>




                {/* <Shipmentstrend
                data={shipmentTrendData}
                noDataShipmentTrends={noDataShipmentTrend}
                xaxis={shipmentTrendDataXaxis}
                served={served}
                total={total}
                dropped={dropped}
               
              /> */}

              </Paper>
            </Grid>
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginTop: "-14px", height: "12rem" }}>

                <div style={{
                  fontSize: "14px", fontWeight: "normal", color: "#000",
                  marginLeft: '20px', paddingTop: "20px"
                }}>
                  Unsuccessful Login Attempts

                </div>

                <img src={failcount} style={{ height: "90px", width: "90px", marginLeft: "8px", marginTop: "16px" }} />
                <div style={{ marginTop: "-95px" }}>
                  <span style={{
                    fontSize: "35px",
                    color: "#707070",

                    display: "flex",
                    fontWeight: "normal",

                    marginLeft: '120px'
                  }}>
                    {fail}
                  </span>
                </div>
                <div style={{
                  fontSize: "14px", fontWeight: "normal", color: "#969696",
                  marginLeft: '120px'
                }}>
                  Unsuccessful Login Attempts into

                </div>
                <div style={{
                  fontSize: "14px", fontWeight: "normal", color: "#969696",
                  marginTop: "4px", marginLeft: '120px'
                }}>  Denso Cold Chain Application
                </div>

              </Paper>


            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>

          <Grid container spacing={2} >
            <Grid item xs={12} md={12} lg={12} style={{ marginLeft: "7px",marginTop:"5px"}}>
              <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none",  height: "38rem", marginLeft: "-6px "}}>
                <div id="ShipmentstrendPDF" style={{ color: "#000", fontSize: "14px", width: "59.1rem" }}>
                  {/* CPU Usage/Limit Per Image */}
                  <CPU
                   
                    style={{ marginTop: "90px" }}
                    data={shipmentTrendData}
                    noDataShipmentTrends={noDataShipmentTrend}
                    xaxis={shipmentTrendDataXaxis}
                    served={served}
                    total={total}
                    dropped={dropped}

                  />
                </div>
              </Paper>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} style={{marginLeft:"8px"}} >
          <Paper className={classes.paper + " p-0"} style={{height:"394px",marginTop:"6px",marginLeft:"-40px",boxShadow:"none"}}>
            <div id="ShipmentstrendPDF" style={{ height: '500px', padding: "10px" }}>

              <Users
                datas={users}
              />
             
            </div>
          </Paper>
        </Grid> */}
            <Grid item xs={12} md={12} lg={12} style={{marginRight:'-40px',marginTop:"5.5px"}}>
              <Paper className={classes.paper + " p-0"} style={{ boxShadow: "none", marginLeft: "-28px", height: "24.5rem",marginTop:'2px' }}>
                <div id="ShipmentstrendPDF" >
                  <br />
                  <Grid container spacing={2} >
                    <Grid item xs={4} >
                      <p style={{
                        textAlign: "left",
                        marginLeft: "1.7rem",
                        // paddingTop: '1rem',
                        marginBottom: "1rem",
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        fontSize: "14px",
                        color: "#000000DE",
                      }}>User Login Status</p>
                    </Grid>
                    <Grid item xs={7} >
                      <Stack spacing={0} direction="row" style={{ float: "right" }}>
                        <div style={{
                          border: "1px solid rgb(206, 212, 217)", borderRadius: "4px", backgroundColor: "white",
                          width: "168px", 
                          paddingRight: "-10px", marginLeft: "33.5rem", position: "sticky"
                        }}>
                          <img src={calendar} style={{ width: "24px", marginLeft: "10px", marginTop: "-1px" }} />
                          <Select
                            style={{ marginTop: "3.5px", marginLeft: "6px" }}
                            inputProps={{ IconComponent: "null" }}
                            labelId="demo-customized-select-label"
                            data-testid="period"
                            id="demo-customized-select"
                            value="daily"
                            onChange={handleTimePeriodChange}

                          >
                            <MenuItem
                              style={{ color: "#000" }}
                              onClick={() => handleChangeCustom('')}

                              value={"daily"}>Last 24 Hours</MenuItem>
                            <MenuItem
                              onClick={() => handleChangeCustom('')}
                              style={{ color: "#000" }}
                              value={"weekly"}>Last 7 Days</MenuItem>
                            <MenuItem
                              onClick={() => handleChangeCustom('')}
                              style={{ color: "#000" }}
                              value={"monthly"}>Last 30 Days</MenuItem>
                            <MenuItem
                              onClick={() => handleChangeCustom('custom')}
                              style={{ color: "#000" }}
                              value={"custom"}>Custom Date
                            </MenuItem>

                          </Select>

                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Users
                    datas={users}
                  />


                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}
