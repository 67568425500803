import {
  createStyles,
 
  Theme,
  Select,
  MenuItem,
  withStyles,
  InputBase,
  Tooltip,
  FormControl,
} from "@material-ui/core";
//import SideTab from "../common/sideTab";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./style.css";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import axios from "axios";
import { assetBaseURL } from "../../../../services/_url";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ShipperHelper, { maxAssetList } from "../../../../services/_helpers";
import TableChartOutlined from "@material-ui/icons/TableChartOutlined";
import IconButton from "@material-ui/core/IconButton";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import TelemeteryAPI from "../../../../services/telemeteryService";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import moment from "moment";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import coldchainLog from "../../../../services/consoleHelper";
import ReportGraph from "./ReportsGraph";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {},
    },
    fieldset: {
      borderRadius: 4,
    },
    
      input: {
        borderRadius: "4px",
        position: "relative",
        backgroundColor: "#CED4D9",
        border: "1px solid #CED4D9",
        fontSize: 14,
      marginLeft:'-23px',
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      
        },
      },
  })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
    },
    divId: {
      "@media (max-width:500px)": {
        marginBottom: "2rem",
      },
    },
    option: {
      
        color: "black !important"
      
    },
     formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon:{
    right:11
  },
  iconOpen:{
    transform:'none'
  },
  formControlLabel:{
    left:24
  },
  selectSelect:{
    paddingLeft:'24px'
  }
  })
);

export interface IAssetReportsProps {
  pdfFlag: any;
  pdfGen: any;
  period:any;
}

export default function AssetReports(props: any) {
  const styles = useStyles();
  const workbook = new Excel.Workbook();
  const reportsAPI = new TelemeteryAPI();
  let dateHelper = new ShipperHelper();
 const { period } = props;
  const [assetType, setAssetType] = useState("Reefer Truck");
  const [assetNumberList, setAssetNumberList] = useState([]);
  const [flag, setFlag] = useState(true);
  const [defaultValue, setDefaultValue] = useState("");
  const [values, setValues] = useState([]);
  const [assetIds, setAssetIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [messageOpen, setmessageOpen] = useState(false);
  const [endNotGreater, setendNotGreater] = useState(false);
  const [loadingCircle, setloadingCircle] = useState(false);
  
  const loadingCircleData = (value: any) => {
    setloadingCircle(value);
  };
  const handleLeftBarChange = (value: any) => {
    props.handleLeftBarChange(value);
  };
  const handleMessage = () => {
    setmessageOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "custom") {
      setcustomDialog(true);
    } else {
     // setPeriod(event.target.value as string);
      setFlag(!flag);
      setstartDate("");
      setendDate("");
      setFromDate("");
      setToDate("");
    }
  };
 
  const handleDates = (e: any) => {
    let fromDate = startDate + "T00:00:00Z";
    let toDate = endDate + "T23:59:59Z";
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    seterrorText(false);
    setValidationStart(false);
    setValidationEnd(false);
    setendNotGreater(false);
    if (startDate === "") {
      setValidationStart(true);
    } else if (endDate === "") {
      setValidationEnd(true);
    } else if (startDate > endDate) {
      seterrorText(true);
    } else if (endDate > todaysdate) {
      setendNotGreater(true);
    } else {
      setFromDate(fromDate);
      setToDate(toDate);
      //setPeriod("custom");
      setcustomDialog(false);
    }
  };
  const handleChangeCustom = (event: any) => {
    if (period === "custom" && event.target.value === 0) {
      setcustomDialog(true);
    }
  };
  const handleCancel = () => {
    let todaysdate = moment(new Date()).format("YYYY-MM-DD");
    if (startDate === "") {
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate === "") {
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (startDate > endDate) {
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    } else if (endDate > todaysdate) {
      setstartDate("");
      setendDate("");
      seterrorText(false);
      setValidationStart(false);
      setValidationEnd(false);
      setendNotGreater(false);
    }
    setcustomDialog(false);
  };
  const handleChangeAsset = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFlag(!flag);
    setAssetIds([]);
    setAssetType(event.target.value as string);
    setValues([]);
    coldchainLog("reset", values);
  };
  const handleChangeValue = (
    event: React.SyntheticEvent<Element, Event>,
    value: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    setAssetIds(value);
    setValues(value);
  };

  useEffect(() => {
    if (flag) {
      axios
        .get(
          `${assetBaseURL}/assets/type?type=${assetType}&size=${maxAssetList}`
        )
        .then((res: any) => {
          setDefaultValue(res.data.data[0].number);
          let AssetsNum: any = [];
          res.data.data.map(
            (x: any) =>
              (AssetsNum = [...AssetsNum, { title: x.id, value: x.number }])
          );
          setAssetNumberList(AssetsNum);
          setFlag(!flag);
        });
    }
  }, [flag]);

  const classes = useStyles();

  useEffect(() => {
    if (props.pdfFlag === true) {
      props.pdfGen();
      GeneratorPdf();
    }
  }, [props.pdfFlag]);
  const GeneratorPdf = () => {
    const ReportsPDF = document.getElementById("Reports");
    const ReportsPDFHoursRun = document.getElementById("HoursRun");
    const ReportsPDFDistanceTravelled =
      document.getElementById("DistanceTravelled");
    /*istanbul ignore next */
    html2canvas(ReportsPDF).then((canvasReportsPDF) => {
      html2canvas(ReportsPDFHoursRun).then((canvasHoursRun) => {
        html2canvas(ReportsPDFDistanceTravelled).then(
          (canvasDistanceTravelled) => {
            const imgReportsPDF = canvasReportsPDF.toDataURL("image/png");
            const doc = new jsPDF();

            // const imgProps = doc.getImageProperties(imgReportsPDF);
            // const pdfWidth = doc.internal.pageSize.getWidth();
            // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(imgReportsPDF, "PNG", 5, 10, 120, 7);

            const imgReportsPDFHoursRun = canvasHoursRun.toDataURL("imge/png");
            doc.addImage(imgReportsPDFHoursRun, "PNG", 5, 30, 100, 70);

            const imgReportsPDFDistanceTravelled =
              canvasDistanceTravelled.toDataURL("imge/png");
            doc.addImage(
              imgReportsPDFDistanceTravelled,
              "PNG",
              100,
              30,
              100,
              70
            );

            const imgPropsHoursRun = doc.getImageProperties(imgReportsPDF);

            doc.save(
              "Reports" + dateHelper.dateConversion(new Date()) + ".pdf"
            );
          }
        );
      });
    });
  };
  // console.log(props.period);
  const ReportAPI = () => {
    setLoading(true);
   let from: any = "";
    let to: any = "";
    if (period === "custom") {
      from = fromDate;
      to = toDate;
    } else {
      let datesForShipment = dateHelper.telemetryDate(props.period);
      from = datesForShipment.substring(5, 25);
      to = datesForShipment.substring(29);
    }
    let assetId: any = [];
    assetIds.map((id: any) => (assetId = [...assetId, id.title]));
    if (assetType === "Reefer Truck") {
      let datas: any = [];
      let Emptylist: any = [];
      let datesValue: any = [];
      let dates: any = [];
      let datesValues: any = [];
      let VehiclesNum: any = [];
      let VehiclesData: any = [];
      let VehiclesDatas: any = [];
       reportsAPI
        .getAssetReportsData(assetId, from, to)
        .then((result: any) => {
          // console.log(result);
          datas = result;
          datas.map(
            (values: any, index: any) => (
              (dates = [...dates, values.date]),
              values.assets.map(
                (da: any) => (
                  (datesValue = [
                    ...datesValue,
                    [
                      da.assetID,
                      da.totalHrs,
                      da.reeferHrs,
                      da.dryHrs,
                      da.totalKms,
                      da.reeferKms,
                      da.dryKms,
                      da.totalLidOpen,
                      da.lidOpenThreshold,
                    ],
                  ]),
                  (VehiclesNum = [...VehiclesNum, da.assetID]),
                  (VehiclesData = [
                    ...VehiclesData,
                    [
                      da.assetID,
                      values.date,
                      da.totalKms,
                      da.totalLidOpen,
                      da.lidOpenThreshold,
                      da.totalHrs,
                      da.dryHrs,
                      da.reeferHrs,
                      da.gatewayPowerStatus,
                      da.gatewayLastSyncDate,
                      da.gatewayLastSyncTime,
                      da.tripStartTime,
                      da.tripEndTime,
                      da.tripStartLocation,
                      da.tripEndLocation,
                    ],
                  ])
                )
              ),
              datesValue.sort((a: any, b: any) =>
                a[0].toLowerCase().localeCompare(b[0].toLowerCase())
              ),
              (datesValues = [...datesValues, datesValue]),
              (datesValue = Emptylist)
            )
          );
          let uniquename = Array.from(new Set(VehiclesNum));
          uniquename = uniquename.sort((a: any, b: any) =>
            a.toLowerCase().localeCompare(b.toLowerCase())
          );
          VehiclesData.sort((a: any, b: any) =>
            a[0].toLowerCase().localeCompare(b[0].toLowerCase())
          );
          let WeeklyDatas: any = [];
          if (datas != 0) {
            let VehiclesNumber = VehiclesData[0][0];
            for (let index = 0; index < VehiclesData.length; index++) {
              if (VehiclesData[index][0] === VehiclesNumber) {
                VehiclesDatas = [...VehiclesDatas, VehiclesData[index]];
              } else {
                WeeklyDatas = [...WeeklyDatas, VehiclesDatas];
                VehiclesDatas = Emptylist;
                VehiclesNumber = VehiclesData[index][0];
                VehiclesDatas = [...VehiclesDatas, VehiclesData[index]];
              }
            }
            WeeklyDatas = [...WeeklyDatas, VehiclesDatas];
            VehiclesDatas = Emptylist;
            setLoading(false);
            saveAsExcelReeferTruck(dates, datesValues, uniquename, WeeklyDatas);
          } else {
            setLoading(false);
            setmessageOpen(true);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.error(err);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    } else {
      let CoolerDataAssetName: any = [];
      let CoolerDailyData: any = [];
       let emptyList: any = [];
       reportsAPI
        .getAssetReportCoolerBoxDialyData(assetId, from, to)
        .then((res: any) => {
          reportsAPI
            .getAssetReportCoolerBoxTripData(assetId, from, to)
            .then((data: any) => {
              if (res != 0) {
                res.map((result: any) => {
                  result.assets.map((data: any) => {
                    CoolerDailyData = [
                      ...CoolerDailyData,
                      [
                        data.assetID,
                        result.date,
                        data.tripsPerDay,
                        data.avgTemp,
                        data.totalOrdersDelivered,
                        data.maxOrdersDeliveredPerTrip,
                        data.minOrdersDeliveredPerTrip,
                        data.tripOverviewMins,
                        data.tripOverviewKms,
                        data.hubLidOpen,
                        data.tripLidOpen,
                        data.transitShocks,
                        "NA",
                        data.hubShocks,
                        data.transitTempExcursion,
                        "NA",
                        data.hubTempExcursion,
                        data.cbOverviewAttached,
                        data.cbOverviewActive,
                        data.cbOverviewUsed,
                        data.preCoolingEvents,
                        data.avgPreCoolingTime,
                        data.gatewayOnTime,
                        data.gatewayOffTime,
                        data.gatewayChargeStartTime,
                        data.gatewayChargeEndTime,
                        data.gatewayBatteryStartTime,
                        data.gatewayBatteryEndTime,
                        data.gatewaySignalStrength,
                      ],
                    ];
                    CoolerDataAssetName = [
                      ...CoolerDataAssetName,
                      data.assetID,
                    ];
                  });
                });
                let uniquename = Array.from(new Set(CoolerDataAssetName));
                let CoolerDialyAssetName = uniquename.sort((a: any, b: any) =>
                  a.toLowerCase().localeCompare(b.toLowerCase())
                );
                CoolerDailyData.sort((a: any, b: any) =>
                  a[0].toLowerCase().localeCompare(b[0].toLowerCase())
                );

                let VehiclesDatas: any = [];
                let CoolerBoxDialyValues: any = [];
                let AssetNumber = CoolerDailyData[0][0];

                for (let index = 0; index < CoolerDailyData.length; index++) {
                  if (CoolerDailyData[index][0] === AssetNumber) {
                    VehiclesDatas = [...VehiclesDatas, CoolerDailyData[index]];
                  } else {
                    CoolerBoxDialyValues = [
                      ...CoolerBoxDialyValues,
                      VehiclesDatas,
                    ];
                    VehiclesDatas = emptyList;
                    AssetNumber = CoolerDailyData[index][0];
                    VehiclesDatas = [...VehiclesDatas, CoolerDailyData[index]];
                  }
                }
                CoolerBoxDialyValues = [...CoolerBoxDialyValues, VehiclesDatas];
                VehiclesDatas = emptyList;

                let CoolerBoxTrip: any = [];
                let CoolerBoxTripAssetName: any = [];

                data.map((result: any) => {
                  result.assets.map((res: any) => {
                    res.assetCoolerBoxTripReports.map((value: any) => {
                      CoolerBoxTrip = [
                        ...CoolerBoxTrip,
                        [
                          res.assetID,
                          result.date,
                          value.tripName,
                          value.preCoolingStartTime,
                          value.preCoolingEndTime,
                          value.tripStartTime,
                          value.tripEndTime,
                          value.tripKms,
                          value.hubLidOpen,
                          value.tripLidOpen,
                          value.transitShocks,
                          "NA",
                          value.hubShocks,
                          value.avgTemp,
                          value.tripAmbientTemperature,
                          value.hubAmbientTemperature,
                          value.avgTempExcursion,
                          value.transitTempExcursion,
                          "NA",
                          "NA",
                        ],
                      ];
                      CoolerBoxTripAssetName = [
                        ...CoolerBoxTripAssetName,
                        res.assetID,
                      ];
                    });
                  });
                });
                let uniquenameTrip = Array.from(
                  new Set(CoolerBoxTripAssetName)
                );
                let CoolerBoxTripAssetNameTrip = uniquenameTrip.sort(
                  (a: any, b: any) =>
                    a.toLowerCase().localeCompare(b.toLowerCase())
                );
                CoolerBoxTrip.sort((a: any, b: any) =>
                  a[0].toLowerCase().localeCompare(b[0].toLowerCase())
                );

                let VehiclesTripDatas: any = [];
                let CoolerBoxTripValues: any = [];
                let AssetNumberTrip = CoolerBoxTrip[0][0];

                for (let index = 0; index < CoolerBoxTrip.length; index++) {
                  if (CoolerBoxTrip[index][0] === AssetNumberTrip) {
                    VehiclesTripDatas = [
                      ...VehiclesTripDatas,
                      CoolerBoxTrip[index],
                    ];
                  } else {
                    CoolerBoxTripValues = [
                      ...CoolerBoxTripValues,
                      VehiclesTripDatas,
                    ];
                    VehiclesTripDatas = emptyList;
                    AssetNumberTrip = CoolerBoxTrip[index][0];
                    VehiclesTripDatas = [
                      ...VehiclesTripDatas,
                      CoolerBoxTrip[index],
                    ];
                  }
                }
                CoolerBoxTripValues = [
                  ...CoolerBoxTripValues,
                  VehiclesTripDatas,
                ];
                VehiclesTripDatas = emptyList;

                saveAsExcelCoolerBox(
                  CoolerBoxDialyValues,
                  CoolerDialyAssetName,
                  CoolerBoxTripValues,
                  CoolerBoxTripAssetNameTrip
                );
                setLoading(false);
              } else {
                setLoading(false);
                setmessageOpen(true);
              }
            })
            .catch((err: any) => {
              setLoading(false);
              console.error(err);
              const UserToken = new ShipperHelper();
              UserToken.UserTokenExpiration();
            });
        })
        .catch((err: any) => {
          setLoading(false);
          console.error(err);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    }
  };

  const saveAsExcelReeferTruck = async (
    DialyDate: any,
    DialyValue: any,
    WeeklyVehicles: any,
    WeeklyValues: any
  ) => {
    try {
      const worksheet = workbook.addWorksheet("Day_Wise");

      const columnA = worksheet.getColumn("A");
      columnA.width = 15;
      const columnI = worksheet.getColumn("I");
      columnI.width = 20;

      let cellnum = 0;
      for (let index = 0; index < DialyDate.length; index++) {
        worksheet.getRow(cellnum + 1).font = { bold: true };
        worksheet.getRow(cellnum + 2).font = { bold: true };
        worksheet.getRow(cellnum + 3).font = { bold: true };

        const cellA1 = worksheet.getCell("A" + (cellnum + 1));
        worksheet.mergeCells("A" + (cellnum + 1) + ":A" + (cellnum + 3));
        cellA1.value = "Vehicle";

        const cellB1 = worksheet.getCell("B" + (cellnum + 1));
        worksheet.mergeCells("B" + (cellnum + 1) + ":I" + (cellnum + 1));
        cellB1.value = DialyDate[index];

        const cellB2 = worksheet.getCell("B" + (cellnum + 2));
        worksheet.mergeCells("B" + (cellnum + 2) + ":D" + (cellnum + 2));
        cellB2.value = "Trip Duration in hrs";

        const cellE2 = worksheet.getCell("E" + (cellnum + 2));
        worksheet.mergeCells("E" + (cellnum + 2) + ":G" + (cellnum + 2));
        cellE2.value = "Trip Distance in Kms";

        const cellH2 = worksheet.getCell("H" + (cellnum + 2));
        worksheet.mergeCells("H" + (cellnum + 2) + ":I" + (cellnum + 2));
        cellH2.value = "Lid Open Events";

        const cellB3 = worksheet.getCell("B" + (cellnum + 3));
        cellB3.value = "Total";
        const cellC3 = worksheet.getCell("C" + (cellnum + 3));
        cellC3.value = "Reefer";
        const cellD3 = worksheet.getCell("D" + (cellnum + 3));
        cellD3.value = "Dry";

        const cellE3 = worksheet.getCell("E" + (cellnum + 3));
        cellE3.value = "Total";
        const cellF3 = worksheet.getCell("F" + (cellnum + 3));
        cellF3.value = "Reefer";
        const cellG3 = worksheet.getCell("G" + (cellnum + 3));
        cellG3.value = "Dry";

        const cellH3 = worksheet.getCell("H" + (cellnum + 3));
        cellH3.value = "Total";
        const cellI3 = worksheet.getCell("I" + (cellnum + 3));
        cellI3.value = "Beyond Threshold";

        // worksheet.getRow(3)
        DialyValue[index].forEach((singleData: any) => {
          worksheet.addRow(singleData);
        });
        cellnum += DialyValue[index].length + 5;
      }
      //Apply alignment
      worksheet.columns.forEach((column) => {
        column.alignment = { horizontal: "center", vertical: "middle" };
      });
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row: any) => {
        // store each cell to currentCell
        const currentCell = row._cells;
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell: any) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      const worksheetWeekly = workbook.addWorksheet("Asset_Wise");
      const columnAworksheet = worksheetWeekly.getColumn("A");
      columnAworksheet.width = 40;
      const columnB = worksheetWeekly.getColumn("B");
      columnB.width = 25;

      let Weeklycellnum = 0;
      for (let index = 0; index < WeeklyVehicles.length; index++) {
        const cellWeeklyA8 = worksheetWeekly.getCell("A" + (Weeklycellnum + 8));
        worksheetWeekly.mergeCells(
          "A" + (Weeklycellnum + 8) + ":A" + (Weeklycellnum + 10)
        );
        cellWeeklyA8.value = "Gateway Status";
        cellWeeklyA8.alignment = { horizontal: "center", vertical: "middle" };

        const cellWeeklyA11 = worksheetWeekly.getCell(
          "A" + (Weeklycellnum + 11)
        );
        worksheetWeekly.mergeCells(
          "A" + (Weeklycellnum + 11) + ":A" + (Weeklycellnum + 14)
        );
        cellWeeklyA11.value = "Trip Overview";
        cellWeeklyA11.alignment = { horizontal: "center", vertical: "middle" };

        let colu = 3;

        WeeklyValues[index].forEach((singleData: any) => {
          const column = worksheetWeekly.getColumn(colu);
          column.width = 15;
          let colrow: any = Weeklycellnum + 1;

          const col0 = worksheetWeekly.getCell(colrow, colu);
          col0.value = singleData[1];
          col0.alignment = { horizontal: "center" };
          colrow += 1;
          const col1 = worksheetWeekly.getCell(colrow, colu);
          col1.value = singleData[2];
          col1.alignment = { horizontal: "center" };
          colrow += 1;
          const col2 = worksheetWeekly.getCell(colrow, colu);
          col2.value = singleData[3];
          col2.alignment = { horizontal: "center" };
          colrow += 1;
          const col3 = worksheetWeekly.getCell(colrow, colu);
          col3.value = singleData[4];
          col3.alignment = { horizontal: "center" };
          colrow += 1;
          const col4 = worksheetWeekly.getCell(colrow, colu);
          col4.value = singleData[5];
          col4.alignment = { horizontal: "center" };
          colrow += 1;
          const col5 = worksheetWeekly.getCell(colrow, colu);
          col5.value = singleData[6];
          col5.alignment = { horizontal: "center" };
          colrow += 1;
          const col6 = worksheetWeekly.getCell(colrow, colu);
          col6.value = singleData[7];
          col6.alignment = { horizontal: "center" };
          colrow += 1;
          const col7 = worksheetWeekly.getCell(colrow, colu);
          col7.value = singleData[8];
          col7.alignment = { horizontal: "center" };
          colrow += 1;
          const col8 = worksheetWeekly.getCell(colrow, colu);
          col8.value = singleData[9];
          col8.alignment = { horizontal: "center" };
          colrow += 1;
          const col9 = worksheetWeekly.getCell(colrow, colu);
          col9.value = singleData[10];
          col9.alignment = { horizontal: "center" };
          colrow += 1;
          const col10 = worksheetWeekly.getCell(colrow, colu);
          col10.value = singleData[11];
          col10.alignment = { horizontal: "center" };
          colrow += 1;
          const col11 = worksheetWeekly.getCell(colrow, colu);
          col11.value = singleData[12];
          col11.alignment = { horizontal: "center" };
          colrow += 1;
          const col12 = worksheetWeekly.getCell(colrow, colu);
          col12.value = singleData[13];
          col12.alignment = { horizontal: "center" };
          colrow += 1;
          const col13 = worksheetWeekly.getCell(colrow, colu);
          col13.value = singleData[14];
          col13.alignment = { horizontal: "center" };
          colrow += 1;

          colu += 1;
        });
        Weeklycellnum += 15;
      }
      // loop through all of the rows and set the outline style.
      worksheetWeekly.eachRow({ includeEmpty: false }, (row: any) => {
        // store each cell to currentCell
        const currentCell = row._cells;
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell: any) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          // apply border
          worksheetWeekly.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf]),
        "ReeferTruck " + dateHelper.dateConversion(new Date()) + ".xlsx"
      );
    } catch (error) {
      console.error("Something Went Wrong", error);
    }
  };

  const saveAsExcelCoolerBox = async (
    CoolerDailyData: any,
    CoolerDataAssetName: any,
    CoolerBoxTripValues: any,
    CoolerBoxTripAssetNameTrip: any
  ) => {
    try {
      const worksheetDailyReport = workbook.addWorksheet(
        "Daily Report Summary"
      );
      worksheetDailyReport.getColumn("A").width = 30;
      worksheetDailyReport.getColumn("B").width = 20;
      let count: any = 0;
      for (let index = 0; index < CoolerDataAssetName.length; index++) {
        const colA1Dialy = worksheetDailyReport.getCell("A" + (count + 1));
        colA1Dialy.value = CoolerDataAssetName[index];
        colA1Dialy.font = { bold: true };
        worksheetDailyReport.getCell("B" + (count + 1)).value = "";

        const colA2Dialy = worksheetDailyReport.getCell("A" + (count + 2));
        colA2Dialy.value = "Daily Report Summary";
        colA2Dialy.font = { bold: true };
        worksheetDailyReport.getCell("B" + (count + 2)).value = "";

        worksheetDailyReport.getCell("A" + (count + 3)).value =
          "No Trips per Day";
        worksheetDailyReport.getCell("B" + (count + 3)).value = "#";

        worksheetDailyReport.getCell("A" + (count + 4)).value =
          "Average Cooler Box Temperature";
        worksheetDailyReport.getCell("B" + (count + 5)).value = "In Trip";

        const colA4Dialy = worksheetDailyReport.getCell("A" + (count + 5));
        colA4Dialy.value = "Orders Delivered";
        worksheetDailyReport.mergeCells("A" + (count + 5) + ":A" + (count + 7));
        colA4Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 5)).value = "Total";
        worksheetDailyReport.getCell("B" + (count + 6)).value =
          "Max # Order per Trip";
        worksheetDailyReport.getCell("B" + (count + 7)).value =
          "Min # Order per Trip";

        const colA7Dialy = worksheetDailyReport.getCell("A" + (count + 8));
        colA7Dialy.value = "Trip Overview";
        worksheetDailyReport.mergeCells("A" + (count + 8) + ":A" + (count + 9));
        colA7Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 8)).value = "In Minutes";
        worksheetDailyReport.getCell("B" + (count + 9)).value = "In Kms";

        const colA9Dialy = worksheetDailyReport.getCell("A" + (count + 10));
        colA9Dialy.value = "No of Lid Open";
        worksheetDailyReport.mergeCells(
          "A" + (count + 10) + ":A" + (count + 11)
        );
        colA9Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 10)).value = "In Hub";
        worksheetDailyReport.getCell("B" + (count + 11)).value = "In Trip";

        const colA11Dialy = worksheetDailyReport.getCell("A" + (count + 12));
        colA11Dialy.value = "No of Shock Events";
        worksheetDailyReport.mergeCells(
          "A" + (count + 12) + ":A" + (count + 14)
        );
        colA11Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 12)).value = "In Trip";
        worksheetDailyReport.getCell("B" + (count + 13)).value = "At Stoppage";
        worksheetDailyReport.getCell("B" + (count + 14)).value = "In Hub";

        const colA14Dialy = worksheetDailyReport.getCell("A" + (count + 15));
        colA14Dialy.value = "Temperature Excursion";
        worksheetDailyReport.mergeCells(
          "A" + (count + 15) + ":A" + (count + 17)
        );
        colA14Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 15)).value = "In Trip";
        worksheetDailyReport.getCell("B" + (count + 16)).value = "At Stoppage";
        worksheetDailyReport.getCell("B" + (count + 17)).value = "In Hub";

        const colA17Dialy = worksheetDailyReport.getCell("A" + (count + 18));
        colA17Dialy.value = "Cooler Box Overview";
        worksheetDailyReport.mergeCells(
          "A" + (count + 18) + ":A" + (count + 20)
        );
        colA17Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 18)).value = "Attached";
        worksheetDailyReport.getCell("B" + (count + 19)).value = "Active";
        worksheetDailyReport.getCell("B" + (count + 20)).value = "Used";

        worksheetDailyReport.getCell("A" + (count + 21)).value =
          "No of Precooling Events";
        worksheetDailyReport.getCell("B" + (count + 21)).value = "#";

        worksheetDailyReport.getCell("A" + (count + 22)).value =
          "Average Precooling Time";
        worksheetDailyReport.getCell("B" + (count + 22)).value = "";

        const colA22Dialy = worksheetDailyReport.getCell("A" + (count + 23));
        colA22Dialy.value = "Gateway Status";
        worksheetDailyReport.mergeCells(
          "A" + (count + 23) + ":A" + (count + 24)
        );
        colA22Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 23)).value = "On";
        worksheetDailyReport.getCell("B" + (count + 24)).value = "Off";

        const colA24Dialy = worksheetDailyReport.getCell("A" + (count + 25));
        colA24Dialy.value = "Gateway Charging Mode";
        worksheetDailyReport.mergeCells(
          "A" + (count + 25) + ":A" + (count + 26)
        );
        colA24Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 25)).value = "Start Time";
        worksheetDailyReport.getCell("B" + (count + 26)).value = "End Time";

        const colA26Dialy = worksheetDailyReport.getCell("A" + (count + 27));
        colA26Dialy.value = "Gateway Battery Mode";
        worksheetDailyReport.mergeCells(
          "A" + (count + 27) + ":A" + (count + 28)
        );
        colA26Dialy.alignment = { horizontal: "left", vertical: "middle" };
        worksheetDailyReport.getCell("B" + (count + 27)).value = "Start Time";
        worksheetDailyReport.getCell("B" + (count + 28)).value = "End Time";

        worksheetDailyReport.getCell("A" + (count + 29)).value =
          "Gateway Signal Strength";
        worksheetDailyReport.getCell("B" + (count + 29)).value = "";

        let colu = 3;

        CoolerDailyData[index].forEach((singleData: any) => {
          let colrow: any = count + 1;
          worksheetDailyReport.getColumn(colu).width = 11;

          const col0 = worksheetDailyReport.getCell(colrow, colu);
          col0.value = singleData[1];
          col0.alignment = { horizontal: "left" };
          colrow += 1;
          const col1 = worksheetDailyReport.getCell(colrow, colu);
          col1.value = singleData[2];
          col1.alignment = { horizontal: "left" };
          colrow += 1;
          const col2 = worksheetDailyReport.getCell(colrow, colu);
          col2.value = singleData[3];
          col2.alignment = { horizontal: "left" };
          colrow += 1;
          const col3 = worksheetDailyReport.getCell(colrow, colu);
          col3.value = singleData[4];
          col3.alignment = { horizontal: "left" };
          colrow += 1;
          const col4 = worksheetDailyReport.getCell(colrow, colu);
          col4.value = singleData[5];
          col4.alignment = { horizontal: "left" };
          colrow += 1;
          const col5 = worksheetDailyReport.getCell(colrow, colu);
          col5.value = singleData[6];
          col5.alignment = { horizontal: "left" };
          colrow += 1;
          const col6 = worksheetDailyReport.getCell(colrow, colu);
          col6.value = singleData[7];
          col6.alignment = { horizontal: "left" };
          colrow += 1;
          const col7 = worksheetDailyReport.getCell(colrow, colu);
          col7.value = singleData[8];
          col7.alignment = { horizontal: "left" };
          colrow += 1;
          const col8 = worksheetDailyReport.getCell(colrow, colu);
          col8.value = singleData[9];
          col8.alignment = { horizontal: "left" };
          colrow += 1;
          const col9 = worksheetDailyReport.getCell(colrow, colu);
          col9.value = singleData[10];
          col9.alignment = { horizontal: "left" };
          colrow += 1;
          const col10 = worksheetDailyReport.getCell(colrow, colu);
          col10.value = singleData[11];
          col10.alignment = { horizontal: "left" };
          colrow += 1;
          const col11 = worksheetDailyReport.getCell(colrow, colu);
          col11.value = singleData[12];
          col11.alignment = { horizontal: "left" };
          colrow += 1;
          const col12 = worksheetDailyReport.getCell(colrow, colu);
          col12.value = singleData[13];
          col12.alignment = { horizontal: "left" };
          colrow += 1;
          const col13 = worksheetDailyReport.getCell(colrow, colu);
          col13.value = singleData[14];
          col13.alignment = { horizontal: "left" };
          colrow += 1;
          const col14 = worksheetDailyReport.getCell(colrow, colu);
          col14.value = singleData[15];
          col14.alignment = { horizontal: "left" };
          colrow += 1;
          const col15 = worksheetDailyReport.getCell(colrow, colu);
          col15.value = singleData[16];
          col15.alignment = { horizontal: "left" };
          colrow += 1;
          const col16 = worksheetDailyReport.getCell(colrow, colu);
          col16.value = singleData[17];
          col16.alignment = { horizontal: "left" };
          colrow += 1;
          const col17 = worksheetDailyReport.getCell(colrow, colu);
          col17.value = singleData[18];
          col17.alignment = { horizontal: "left" };
          colrow += 1;
          const col18 = worksheetDailyReport.getCell(colrow, colu);
          col18.value = singleData[19];
          col18.alignment = { horizontal: "left" };
          colrow += 1;
          const col19 = worksheetDailyReport.getCell(colrow, colu);
          col19.value = singleData[20];
          col19.alignment = { horizontal: "left" };
          colrow += 1;
          const col20 = worksheetDailyReport.getCell(colrow, colu);
          col20.value = singleData[21];
          col20.alignment = { horizontal: "left" };
          colrow += 1;
          const col21 = worksheetDailyReport.getCell(colrow, colu);
          col21.value = singleData[22];
          col21.alignment = { horizontal: "left" };
          colrow += 1;
          const col22 = worksheetDailyReport.getCell(colrow, colu);
          col22.value = singleData[23];
          col22.alignment = { horizontal: "left" };
          colrow += 1;
          const col23 = worksheetDailyReport.getCell(colrow, colu);
          col23.value = singleData[24];
          col23.alignment = { horizontal: "left" };
          colrow += 1;
          const col24 = worksheetDailyReport.getCell(colrow, colu);
          col24.value = singleData[25];
          col24.alignment = { horizontal: "left" };
          colrow += 1;
          const col25 = worksheetDailyReport.getCell(colrow, colu);
          col25.value = singleData[26];
          col25.alignment = { horizontal: "left" };
          colrow += 1;
          const col26 = worksheetDailyReport.getCell(colrow, colu);
          col26.value = singleData[27];
          col26.alignment = { horizontal: "left" };
          colrow += 1;
          const col27 = worksheetDailyReport.getCell(colrow, colu);
          col27.value = singleData[28];
          col27.alignment = { horizontal: "left" };
          colrow += 1;

          colu += 1;
        });

        count += 30;
      }

      // loop through all of the rows and set the outline style.
      worksheetDailyReport.eachRow({ includeEmpty: false }, (row: any) => {
        // store each cell to currentCell
        const currentCell = row._cells;
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell: any) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          // apply border
          worksheetDailyReport.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      const worksheetTripReport = workbook.addWorksheet("Trip Report");
      worksheetTripReport.getColumn("A").width = 40;
      worksheetTripReport.getColumn("B").width = 20;

      let Tripcount: any = 0;
      for (let index = 0; index < CoolerBoxTripAssetNameTrip.length; index++) {
        worksheetTripReport.getCell("A" + (Tripcount + 1)).value = "";
        worksheetTripReport.getCell("B" + (Tripcount + 1)).value = "Date";

        const colA1Trip = worksheetTripReport.getCell("A" + (Tripcount + 1));
        colA1Trip.value = CoolerBoxTripAssetNameTrip[index];
        colA1Trip.font = { bold: true };

        const colA2Trip = worksheetTripReport.getCell("A" + (Tripcount + 2));
        colA2Trip.value = "Per Trip Report";
        colA2Trip.font = { bold: true };
        worksheetTripReport.getCell("B" + (Tripcount + 2)).value = "";

        const col3Trip = worksheetTripReport.getCell("A" + (Tripcount + 3));
        col3Trip.value = "Precooling Time per Trip";
        worksheetTripReport.mergeCells(
          "A" + (Tripcount + 3) + ":A" + (Tripcount + 4)
        );
        col3Trip.alignment = { horizontal: "left", vertical: "middle" };
        worksheetTripReport.getCell("B" + (Tripcount + 3)).value = "Start time";
        worksheetTripReport.getCell("B" + (Tripcount + 4)).value = "End time";

        const col5Trip = worksheetTripReport.getCell("A" + (Tripcount + 5));
        col5Trip.value = "Trip Details";
        worksheetTripReport.mergeCells(
          "A" + (Tripcount + 5) + ":A" + (Tripcount + 7)
        );
        col5Trip.alignment = { horizontal: "left", vertical: "middle" };
        worksheetTripReport.getCell("B" + (Tripcount + 5)).value = "Start Time";
        worksheetTripReport.getCell("B" + (Tripcount + 6)).value = "End Time";
        worksheetTripReport.getCell("B" + (Tripcount + 7)).value = "In Kms";

        const col8Trip = worksheetTripReport.getCell("A" + (Tripcount + 8));
        col8Trip.value = "No of Lid Open per Trip";
        worksheetTripReport.mergeCells(
          "A" + (Tripcount + 8) + ":A" + (Tripcount + 9)
        );
        col8Trip.alignment = { horizontal: "left", vertical: "middle" };
        worksheetTripReport.getCell("B" + (Tripcount + 8)).value = "In Hub";
        worksheetTripReport.getCell("B" + (Tripcount + 9)).value = "In Trip";

        const col10Trip = worksheetTripReport.getCell("A" + (Tripcount + 10));
        col10Trip.value = "No of Shock Events";
        worksheetTripReport.mergeCells(
          "A" + (Tripcount + 10) + ":A" + (Tripcount + 12)
        );
        col10Trip.alignment = { horizontal: "left", vertical: "middle" };
        worksheetTripReport.getCell("B" + (Tripcount + 10)).value = "In Trip";
        worksheetTripReport.getCell("B" + (Tripcount + 11)).value =
          "At Stoppage";
        worksheetTripReport.getCell("B" + (Tripcount + 12)).value = "In Hub";

        worksheetTripReport.getCell("A" + (Tripcount + 13)).value =
          "Average Cooler Box Temperature";
        worksheetTripReport.getCell("B" + (Tripcount + 13)).value = "In Trip";

        const col14Trip = worksheetTripReport.getCell("A" + (Tripcount + 14));
        col14Trip.value = "Ambient Temperature";
        worksheetTripReport.mergeCells(
          "A" + (Tripcount + 14) + ":A" + (Tripcount + 15)
        );
        col14Trip.alignment = { horizontal: "left", vertical: "middle" };
        worksheetTripReport.getCell("B" + (Tripcount + 14)).value = "In Trip";
        worksheetTripReport.getCell("B" + (Tripcount + 15)).value = "In Hub";

        worksheetTripReport.getCell("A" + (Tripcount + 16)).value =
          "Average Time of Temperature Excursion";
        worksheetTripReport.getCell("B" + (Tripcount + 16)).value = "In Trip";

        worksheetTripReport.getCell("A" + (Tripcount + 17)).value =
          "Temperature Excursions (Count)";
        worksheetTripReport.getCell("B" + (Tripcount + 17)).value = "In Trip";

        worksheetTripReport.getCell("A" + (Tripcount + 18)).value =
          "No of Stoppages";
        worksheetTripReport.getCell("B" + (Tripcount + 18)).value = "In Trip";

        worksheetTripReport.getCell("A" + (Tripcount + 19)).value =
          "Average Stoppage Time";
        worksheetTripReport.getCell("B" + (Tripcount + 19)).value = "In Trip";

        let colu = 3;

        CoolerBoxTripValues[index].forEach((singleData: any) => {
          let colrow: any = Tripcount + 1;
          worksheetTripReport.getColumn(colu).width = 11;

          const col0 = worksheetTripReport.getCell(colrow, colu);
          col0.value = singleData[1];
          col0.alignment = { horizontal: "left" };
          colrow += 1;
          const col1 = worksheetTripReport.getCell(colrow, colu);
          col1.value = singleData[2];
          col1.alignment = { horizontal: "left" };
          colrow += 1;
          const col2 = worksheetTripReport.getCell(colrow, colu);
          col2.value = singleData[3];
          col2.alignment = { horizontal: "left" };
          colrow += 1;
          const col3 = worksheetTripReport.getCell(colrow, colu);
          col3.value = singleData[4];
          col3.alignment = { horizontal: "left" };
          colrow += 1;
          const col4 = worksheetTripReport.getCell(colrow, colu);
          col4.value = singleData[5];
          col4.alignment = { horizontal: "left" };
          colrow += 1;
          const col5 = worksheetTripReport.getCell(colrow, colu);
          col5.value = singleData[6];
          col5.alignment = { horizontal: "left" };
          colrow += 1;
          const col6 = worksheetTripReport.getCell(colrow, colu);
          col6.value = singleData[7];
          col6.alignment = { horizontal: "left" };
          colrow += 1;
          const col7 = worksheetTripReport.getCell(colrow, colu);
          col7.value = singleData[8];
          col7.alignment = { horizontal: "left" };
          colrow += 1;
          const col8 = worksheetTripReport.getCell(colrow, colu);
          col8.value = singleData[9];
          col8.alignment = { horizontal: "left" };
          colrow += 1;
          const col9 = worksheetTripReport.getCell(colrow, colu);
          col9.value = singleData[10];
          col9.alignment = { horizontal: "left" };
          colrow += 1;
          const col10 = worksheetTripReport.getCell(colrow, colu);
          col10.value = singleData[11];
          col10.alignment = { horizontal: "left" };
          colrow += 1;
          const col11 = worksheetTripReport.getCell(colrow, colu);
          col11.value = singleData[12];
          col11.alignment = { horizontal: "left" };
          colrow += 1;
          const col12 = worksheetTripReport.getCell(colrow, colu);
          col12.value = singleData[13];
          col12.alignment = { horizontal: "left" };
          colrow += 1;
          const col13 = worksheetTripReport.getCell(colrow, colu);
          col13.value = singleData[14];
          col13.alignment = { horizontal: "left" };
          colrow += 1;
          const col14 = worksheetTripReport.getCell(colrow, colu);
          col14.value = singleData[15];
          col14.alignment = { horizontal: "left" };
          colrow += 1;
          const col15 = worksheetTripReport.getCell(colrow, colu);
          col15.value = singleData[16];
          col15.alignment = { horizontal: "left" };
          colrow += 1;
          const col16 = worksheetTripReport.getCell(colrow, colu);
          col16.value = singleData[17];
          col16.alignment = { horizontal: "left" };
          colrow += 1;
          const col17 = worksheetTripReport.getCell(colrow, colu);
          col17.value = singleData[18];
          col17.alignment = { horizontal: "left" };
          colrow += 1;
          const col18 = worksheetTripReport.getCell(colrow, colu);
          col18.value = singleData[19];
          col18.alignment = { horizontal: "left" };
          colrow += 1;

          colu += 1;
        });
        Tripcount += 21;
      }

      // loop through all of the rows and set the outline style.
      worksheetTripReport.eachRow({ includeEmpty: false }, (row: any) => {
        // store each cell to currentCell
        const currentCell = row._cells;
        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell: any) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          // apply border
          worksheetTripReport.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf]),
        "CoolerBox " + dateHelper.dateConversion(new Date()) + ".xlsx"
      );
    } catch (error) {
      console.error("Something Went Wrong", error);
    }
  };
 
  return (
    <div className={classes.root}>
      <div style={{ display: "inline-block" }} id="Reports">
      {/* <SideTab
            filter={props.period}
           type="Reports"
           fromAndTo={props.fromAndTo}
          handleLeftBarChange={handleLeftBarChange}
        loadingCircleData={loadingCircleData}
           value={props.value}
          /> */}
            <FormControl style={{height:"30px"}} className={classes.formControl}>
        <Select
          labelId="denso-time-period-select-outlined-label"
          id="denso-time-period-select-outlined"
          data-testid="timePeriodSelect"
          //id="demo-customized-select"
          IconComponent={ExpandMoreIcon}
          classes={{icon:classes.icon, iconOpen:classes.iconOpen,select:classes.selectSelect}}
          value={assetType}
          onChange={handleChangeAsset}
          style={{width:"117px",marginTop:"-12px",fontSize:"27px",
          
          marginLeft:"35px"}}
          input={<BootstrapInput />}
       
        >
          
          <MenuItem value={"Reefer Truck"} style={{color:"#000"}}>Reefer Truck</MenuItem>
          <MenuItem value={"Active Cooler Box"} style={{color:"#000"}}>Cooler Box</MenuItem>
        </Select>
        <Autocomplete
        getOptionDisabled={(options) => (values.length > 4 ? true : false)}
        multiple
        freeSolo
      id="combo-box-demo"
      limitTags={2}
      value={values}
      getOptionLabel={(assetNumberList) => assetNumberList.value}
      onChange={handleChangeValue}
      style={{marginTop:"-48px",marginLeft:"170px"}}
      popupIcon={<KeyboardArrowDownIcon style={{color:"#000"}}/>}
      classes={{
        option: styles.option
      }}
         
      options={assetNumberList}
      sx={{ width: 400,marginLeft:"40px"}}
      renderInput={(params) => <TextField {...params}
  
      placeholder="Search..."
       label="Search"/>}
    />
       
        </FormControl>
        
       {/* <Autocomplete
          getOptionDisabled={(options) => (values.length > 4 ? true : false)}
          multiple
          style={{marginTop:"3px" }}
          className="acField"
          freeSolo
          limitTags={2}
          placeholder="Search..."
          id="multiple-limit-tags"
          options={assetNumberList}
          value={values}
          getOptionLabel={(assetNumberList) => assetNumberList.value}
          onChange={handleChangeValue}
          size="small"
          popupIcon={<KeyboardArrowDownIcon />}
          
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              placeholder="Search..."
              style={{ float: "right", height: "2.5rem",paddingTop:"-10px",marginRight:"-10px",backgroundColor:"#fff",marginTop:"-8px"
            ,border:"1px solid red"}}
            />
          )}
        /> */}
        
        
      </div>
      {/* <IconButton style={{ float: "right" }} onClick={ReportAPI}>
        <Tooltip title="Export Report" placement="bottom">
          <TableChartOutlined />
        </Tooltip>
      </IconButton> */}
      {/* <Select
        style={{ float: "right" }}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        data-testid="period"
        IconComponent={ExpandMoreIcon}
        value={period}
        onChange={handleChange}
        onClick={handleChangeCustom}
        input={<BootstrapInput />}
      >
        <MenuItem value={"daily"}>Last 24 Hours</MenuItem>
        <MenuItem value={"weekly"}>Last 7 Days</MenuItem>
        <MenuItem value={"monthly"}>Last 30 Days</MenuItem>
        <MenuItem value={"custom"}>Custom Date</MenuItem>
      </Select> */}
      {/* <div>
        <hr className={"hrCss"} />
      </div> */}

      <div style={{marginTop:"20px"}}>
        <ReportGraph
          period={period}
          fromDate={fromDate}
          toDate={toDate}
          assetType={assetType}
          assetIds={assetIds}
        />
      </div>
      <div>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Dialog
          open={customDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">{"Custom Date"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <br />
              <TextField
                id="date"
                label="Start date"
                type="date"
                sx={{ width: 220, marginBottom: "30px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errorText === true ? errorText : validationStart}
                helperText={
                  errorText === true
                    ? "start date cannot be greater than end date"
                    : validationStart === true
                    ? "Select start date"
                    : ""
                }
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
              />
              &nbsp;&nbsp;
              <TextField
                id="date"
                label="End date"
                type="date"
                // defaultValue="2017-05-24"
                sx={{ width: 220, marginBottom: "30px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                error={validationEnd === true ? validationEnd : endNotGreater}
                helperText={
                  validationEnd === true
                    ? "Select end date"
                    : endNotGreater === true
                    ? "End date cannot be greater than todays date"
                    : ""
                }
                onChange={(e) => setendDate(e.target.value)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDates}>Ok</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Snackbar
          open={messageOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={5000}
          onClose={handleMessage}
        >
          <Alert variant="filled" severity="info">
            There is no data found
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
