import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from "@material-ui/icons/Add";
import IconButton from '@mui/material/IconButton';
import EditIcon from "@material-ui/icons/Edit";
import { data } from '../../../../../test/Denso/data';
import { Grid, MenuItem, Select } from '@material-ui/core';
import { useState } from 'react';
import calendar from '../../../../../assets/images/calendar.svg'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EEF2F6",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const Users = (props: any) => {
  const [customDialog, setcustomDialog] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [timePeriod, setTimePeriod] = useState("daily");
  const [endDate, setendDate] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [validationStart, setValidationStart] = useState(false);
  const [validationEnd, setValidationEnd] = useState(false);
  const [startAndEnd, setStartAndEnd] = useState("");
    const Headingstyle: any = {
        textAlign: 'left',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight:"normal",
        fontSize: '1rem',
        color: "#000000DE",
        marginLeft:"17px",
        marginTop:"20px"
    }
    const users = props.datas;
    const handleChangeCustom = (data: any) => {

    }
      const handleTimePeriodChange = (event: any) => {
          if (event.target.value === "custom") {
            setcustomDialog(true);
          } else {
            setTimePeriod(event.target.value);
            setstartDate("");
            setendDate("");
            setStartAndEnd("");
          }
        };
    // console.log(topFiveStoppagesDatas);
    return (
        <div>
         
            {/* <Grid item xs={6}>  <div style={Headingstyle}>User Login Status</div></Grid> */}
          
            {/* <TableContainer component={Paper}> */}
            <Table aria-label="customized table" style={{width:"95%",marginLeft:"27px",marginTop:"22px"}}>
              <TableHead >
                <TableRow >
                  {/* <StyledTableCell data-testid="Infrastructure">Infrastructure</StyledTableCell> */}
                  <StyledTableCell align="left" data-testid="User" style={{fontWeight:"normal"}}>User</StyledTableCell>
                  <StyledTableCell align="center" data-testid="Date" style={{fontWeight:"normal"}}>Date</StyledTableCell>
                  <StyledTableCell align="center" data-testid="Comments" style={{fontWeight:"normal"}}>Comments</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid="TableContent">
                {users?.map((row: any, index: number) => (
                  <StyledTableRow key={index} data-testid='row'>
                    <StyledTableCell align='left'>
                        {row.userName}
                    </StyledTableCell>
                    <StyledTableCell align='center'>{row.createdTimestamp}</StyledTableCell>
                    {/* <StyledTableCell align="right">{row.asset ? row.asset.assetType.name: ""}</StyledTableCell> */}
                    <StyledTableCell align="center">{row.comment}</StyledTableCell>
                    <StyledTableCell align="right">
                      {/* <IconButton aria-label="edit" onClick={()=>handleEdit(row)}>
                        <EditIcon />key={row.name}
                      </IconButton>
                      <IconButton aria-label="delete" onClick={()=>handleDelete(row.mobileNumber)}>
                        <DeleteIcon />
                      </IconButton> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          {/* </TableContainer> */}
   
        </div>
    )
}

export default Users;
