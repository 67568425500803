import React, { useEffect, useRef, useState } from "react";
import { recomposeColor, Theme } from "@material-ui/core/styles";
// import Tabs from '@material-ui/core/Tabs';
import {
  Tab as Taab,
  Tabs as Taabs,
  InputAdornment,
  IconButton,
  Paper,
  Button,
  InputBase,
  withStyles,
  createStyles,
  makeStyles,
  Divider,
} from "@material-ui/core";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Details from "./details";
import { useHistory } from "react-router-dom";
import HardwareInfo from "../common/hardwareInfo";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./style.css";
import axios from "axios";
import { assetBaseURL } from "../../../../services/_url";
import { assetTenantURL } from "../../../../services/_url";
import { setAssetToken } from "../../../../services/tokens";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShipperHelper from "../../../../services/_helpers";
import filter from "../../../../assets/images/filter.svg";
import moment from "moment";
import TelemeteryAPI from "../../../../services/telemeteryService";
import { getTrips_data } from "../../../../test/Transporter/testData";
import DashboardChartAPI from "../../../../services/ChartServices";
import UserService from "../../../../services/userService";
import { telemetry } from "../../../../services/_url";
import threewheelers from "../../../../assets/images/threewheelers.svg"
import twowheelers from "../../../../assets/images/twowheelers.svg"
import searchicon from "../../../../assets/images/searchicon.svg"
import reeferfour from "../../../../assets/images/reeferfour.svg"
import filterapplied from "../../../../assets/images/filterapplied.png"
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "8px 26px 8px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}


const list = [
  {
    active: true,
    assetType: {
      active: true,
      createdAt: "2021-09-21T12:00:58.383+0000",
      description: "this is Cooler box description ",
      id: "24c6da0a-23c8-4054-8352-19d02bfb0386",
      lastUpdatedAt: "2021-09-21T12:16:52.012+0000",
      name: "Active Cooler Box"
    },
    city: "Mumbai",
    createdAt: "2021-09-21T12:39:53.424+0000",
    id: "eb072827-9821-4aba-8d57-933c59277d94",
    deviceId: ["8FF915F78973290F", "7DF9FB3CC4662DC4"],
    lastUpdatedAt: "2021-09-21T12:39:53.424+0000",
    locality: "Mumbai",
    number: "Box 1",
    region: "India",
    underMaintenance: false,
    zone: "Mumbai Zone"
  },
  {
    active: true,
    assetType: {
      active: true,
      createdAt: "2021-09-21T12:00:58.383+0000",
      description: "this is Cooler box description ",
      id: "24c6da0a-23c8-4054-8352-19d02bfb0386",
      lastUpdatedAt: "2021-09-21T12:16:52.012+0000",
      name: "Active Cooler Box"
    },
    city: "Mumbai",
    createdAt: "2021-09-21T12:39:53.424+0000",
    id: "dab50bfb-2b38-42d4-9477-db245f6c6c00",
    deviceId: ["52BO5CF0888B8701", "4DE54BD3FCCA6113"],
    lastUpdatedAt: "2021-09-21T12:39:53.424+0000",
    locality: "Mumbai",
    number: "Box 2",
    region: "India",
    underMaintenance: false,
    zone: "Mumbai Zone"
  },
  {
    active: true,
    assetType: {
      active: true,
      createdAt: "2021-09-21T12:00:58.383+0000",
      description: "this is Cooler box description ",
      id: "24c6da0a-23c8-4054-8352-19d02bfb0386",
      lastUpdatedAt: "2021-09-21T12:16:52.012+0000",
      name: "Active Cooler Box"
    },
    city: "Mumbai",
    createdAt: "2021-09-21T12:39:53.424+0000",
    id: "6c5d306d-26cd-4653-9d19-32c9d14eee18",
    deviceId: ["B528E49501511155", "49ED62FEE6959B9B"],
    lastUpdatedAt: "2021-09-21T12:39:53.424+0000",
    locality: "Mumbai",
    number: "Box 3",
    region: "India",
    underMaintenance: false,
    zone: "Mumbai Zone"
  },
  // {
  //   active: true,
  //   assetType: {
  //     active: true,
  //     createdAt: "2021-09-21T12:00:58.383+0000",
  //     description: "this is Cooler box description ",
  //     id: "24c6da0a-23c8-4054-8352-19d02bfb0386",
  //     lastUpdatedAt: "2021-09-21T12:16:52.012+0000",
  //     name: "Active Cooler Box"
  //   },
  //   city: "Mumbai",
  //   createdAt: "2021-09-21T12:39:53.424+0000",
  //   id: "6c77e305-7abf-4c48-8a1e-cad37e148e52",
  //   deviceId: "8B748FEE8A26EDD0",
  //   lastUpdatedAt: "2021-09-21T12:39:53.424+0000",
  //   locality: "Mumbai",
  //   number: "Box 4",
  //   region: "India",
  //   underMaintenance: false,
  //   zone: "Mumbai Zone"
  // },
  {
    active: true,
    assetType: {
      active: true,
      createdAt: "2021-09-21T12:00:58.383+0000",
      description: "this is Cooler box description ",
      id: "24c6da0a-23c8-4054-8352-19d02bfb0386",
      lastUpdatedAt: "2021-09-21T12:16:52.012+0000",
      name: "Active Cooler Box"
    },
    city: "Mumbai",
    createdAt: "2021-09-21T12:39:53.424+0000",
    id: "f7064b27-afc3-4b4b-9989-a6bda3870436",
    deviceId: ["8B748FEE8A26EDD0", "7BDCF2C8805B7597"],
    lastUpdatedAt: "2021-09-21T12:39:53.424+0000",
    locality: "Mumbai",
    number: "Denso",
    region: "India",
    underMaintenance: false,
    zone: "Mumbai Zone"
  }
]

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: '1px', backgroundColor: "white" }}>
          <div style={{ width: '100%' }}>
            <hr style={{ marginTop: '2.3rem', border: "1px solid lightgrey", width: '100%', paddingTop: '0.1px', borderTop: '0', borderLeft: '0', borderRight: '0' }} />
            <Typography style={{ width: '100%' }}>{children}</Typography>
          </div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a12yProps(index: number) {
  // console.log('index', index)
  return {
    id: `simple1-tab-${index}`,
    "aria-controls": `simple1-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    fontSize: "12pt",
    boxShadow: "none",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#000",
    minHeight: "15rem",
  },
  pageHeader: {
    fontSize: "1.5rem",
  },
  pageTopBar: {
    backgroundColor: "#fff",
  },
  tabsWrapper: {
    marginTop: 0,
    // marginBottom: "1.5rem",
    position: "relative",
    "@media (max-width:500px)": {
      marginTop: "3rem",
    },
  },
  tabs: {},
  tab: {
    textTransform: "capitalize",
    "&.Mui-selected": {
      outline: "none",
      color: "#0078A5",
    },
    outline: "none",
    color: "#737373",

  },
  tabsLeft: {
    "& .MuiTabs-flexContainer": {
      backgroundColor: "#E5E5E5",
      borderRadius: "40px",
      marginTop: "1rem",
      marginLeft: "2rem",
      marginRight: "2rem",
      border: "5px solid #E5E5E5",
      color: "#E5E5E5",
      textTransform: "none",
      height: "3rem",
      //  width:"17rem"

    }
  },
  tabsLeft1: {
    "&.Mui-selected": {
      backgroundColor: "#ffffff",
      borderRadius: "40px",


      border: "5px solid #E5E5E5",

      textTransform: "none",
      height: "3rem",
      color: "#0078A5",
    },
    color: "#737373",
    borderRadius: "40px",
    height: "1rem",
    width: "50%",
    marginTop: '-4.5px',
    marginLeft: '-3px',
    marginRight: '9px'



  },
  // tabTruck: {

  //   "&.Mui-selected": {

  //     outline: "none",
  //     color: "#0078A5",
  //     backgroundColor: "#ffffff",

  //     borderRadius: "40px",
  //     textTransform:"none",
  //     // height:"1rem",
  //     "@media only screen and (max-width:750px) and (min-width:1030px)": {

  //       fontSize: "18px",

  //     }
  //   },
  //   outline: "none",
  //   color: "#737373",
  //   borderRadius: "40px",
  //   height:"1rem",
  //   width: "50%",
  //   marginTop:'-1px',



  // },
  tabPanel: {
    backgroundColor: "#fafafa",
  },


}));

export default function VerticalTabs(props: any) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(1);
  const [modArray, setModArray] = useState([]);
  const [modArray1, setModArray1] = useState([]);
  const [modArray2, setModArray2] = useState([]);
  const [modArray3, setModArray3] = useState([]);
  const [err] = useState("");
  const [assetOrginalData, setAssetOrginalData] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [assetCities, setAssetCities] = useState<any>([]);
  const [assetData1, setAssetData1] = useState([]);
  const [assetCategory, setAssetCategory] = useState({ categories: { Active: false, Inactive: false, Bangalore: false, Delhi: false, Chandigarh: false } })
  const [lastRecordedAt, setRecorded] = useState("");
  const [keys, setKeys] = useState("DETAILS");
  const [textFieldValue, setTextFieldValue] = useState<string>("");
  const [page, setPage] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalAssetsByPage, setTotalAssetsByPage] = useState(0);
  const [TotalAssetCount, setTotalAssetCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [selAssetData, setSelAssetData] = useState({ data: {}, id: '' });
  var itemOption: any[] = [];
  const [period, setPeriod] = React.useState("daily");
  const [dDialog, setDDialog] = useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  let history = useHistory();
  const [statusVisible, setStatusVisible] = useState(false);
  const [locationVisible, setLocationVisible] = useState(false);
  const [statusFlag, setStatusFlag] = useState(false);
  const [runstatusFlag, setrunStatusFlag] = useState(false);
  const [eventsFlag, setEventsFlag] = useState(false);
  const [locationFlag, setLocationFlag] = useState(false);
  const [backupFlag, setBackupFlag] = useState(false);
  const telemeteryService = new TelemeteryAPI();
  const userService = new UserService();
  const userDetails = userService.getUserDetails();
  const [overviewData, setOverviewData] = useState([]);
  const [text, setText] = useState("filter")
  let dateHelper = new ShipperHelper();
  const [selectedCity, setSelectedCity] = useState([]);
  const [date, setDate] = useState();
  const [filterOn, setFilterOn] = useState(false);
  const handleDate = (data: (prevState: undefined) => undefined) => {
    setDate(data);
  }


  const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }


  const handleChangeST = (event: React.ChangeEvent<{}>, newValue: number, item: any) => {
    if (assetOrginalData.length > 1) {
      assetOrginalData.map((id, index) => {
        if (id.id === item.id) {
          setValue1(-1);
          setTimeout(() => {
            setValue1(index);
          }, 250);
        }
      })
    }
    else {
      setValue1(1);
    }
  };

  window.addEventListener("click", (event: any) => {
    if (!event.target.matches(".dropdown")
      && (!event.target.matches(".divZ"))
      && (!event.target.matches(".insideDiv"))
      && (!event.target.matches(".cbBox"))
      && !event.target.matches(".dropbtn") && !event.target.matches(".li")) {
      if (dDialog) {
        setDDialog(false);
      }
    }
  });

  const showDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (dDialog) {
      setDDialog(false);
    } else {
      setDDialog(true);
    }
  }

  const filterNames = ['test', 'test1'];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleLeftBarChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    props.handleLeftBarChange(newValue);
  };

  const handleChangeKey = (k: any) => {
    setTabValue(0);
  };



  const checkboxHandlerCity = (e: any, val: string) => {

    const selectedCities = [...selectedCity];
    const findIndex = selectedCities.indexOf(val);
    if (findIndex !== -1) {
      selectedCities.splice(findIndex, 1);
      setSelectedCity(selectedCities);
      if (selectedCities && selectedCities.length === 0) {
        setFilterOn(false)
      }
    }
    else {
      selectedCities.push(val);
      setSelectedCity(selectedCities);
      setFilterOn(true);
    }
  }

  const clearFilter = () => {
    setFilterOn(false)
    setSelectedCity([]);
    setAssetOrginalData(assetList);
    setText("filter")
    setStatusFlag(false)
    setrunStatusFlag(false)
    setLocationFlag(false)
    setDDialog(false)
  }

  const filterData = async () => {
    const tmp = [...assetList];

    let temSelectedCity = [...selectedCity]
    let values: any = []
    if (temSelectedCity.length >= 1) {
      if (temSelectedCity.includes('Active')) {
        let data = tmp.filter((item) => item.active && !item.underMaintenance);
        values = [...values, ...data];
        let postion = temSelectedCity.indexOf('Active');
        temSelectedCity.splice(postion, 1)
      }

      if (temSelectedCity.includes('Inactive')) {
        let data = tmp.filter((item) => item.active && item.underMaintenance);
        values = [...values, ...data];
        let postion = temSelectedCity.indexOf('Inactive');
        temSelectedCity.splice(postion, 1)
      }
      if (temSelectedCity.includes('onTrip')) {
        let data = tmp.filter((item) => item.onTrip === "On Trip");
        values = [...values, ...data];
        let postion = temSelectedCity.indexOf('onTrip');
        temSelectedCity.splice(postion, 1)
      }
      if (temSelectedCity.includes('Idle')) {
        let data = tmp.filter((item) => item.onTrip === "Idle");
        values = [...values, ...data];
        let postion = temSelectedCity.indexOf('Idle');
        temSelectedCity.splice(postion, 1)
      }
      if (temSelectedCity.includes('Backup')) {
        let data = tmp.filter((item) => item.backup);
        values = [...values, ...data];
        let postion = temSelectedCity.indexOf('Backup');
        temSelectedCity.splice(postion, 1)
      }
      if (temSelectedCity.length) {
        values = !values.length ? tmp : values;
        values = values.filter((item: any) => temSelectedCity.includes(item.city));
      }
      values.sort((a: any, b: any) => {
        if (a?.assetType.name > b?.assetType.name) {
          return -1;
        }
        if (a?.assetType.name < b?.assetType.name) {
          return 1;
        }
        return 0;
      })
      setAssetOrginalData(values);

    } else {
      setAssetOrginalData(assetList);
    }
  }
  useEffect(() => {
    filterData();
  }, [selectedCity])

  /*const handleData = () => {
    history.push("/tempData");
  }*/




  const searchAsset = async (page?: any, textValues?: any, textValues1?: any) => {
    if (props.value === 0) {
      // axios
      //   .get(
      //     `${assetBaseURL}/assets/type?type=${props.type}&searchParam=${textValues}&page=${page}&size=100&srvCst=1&drv=1`
      //   )
      //   .then((res: any) => {
      //     let temp1 = res.data.data;
      //     if(temp1.length  > 0){
      //       setAssetList(temp1)
      //       setAssetOrginalData(temp1);
      //     }
      //     setTotalAssetsByPage(res.data.data.length);
      //     setTotalAssetCount(res.data.data.length);
      //     setTotalAssets(res.data.total);
      //     setPage(0);
      //     setValue1(0);
      //   })
      // axios.get(`${assetBaseURL}/assets/type?type=3W_EV&searchParam=${textValues}&page=${page}&size=100&srvCst=1&drv=1`)
      //   .then((res: any) => {
      //     let temp2 = res.data.data
      //     if(temp2.length  > 0){
      //       setAssetList(temp2)
      //       setAssetOrginalData(temp2);
      //     }

      //     setTotalAssetsByPage(res.data.data.length);
      //     setTotalAssetCount(res.data.data.length);
      //     setTotalAssets(res.data.total);
      //     setPage(0);
      //     setValue1(0);

      //   })
      // axios.get(`${assetBaseURL}/assets/type?type=2W_EV&searchParam=${textValues}&page=${page}&size=100&srvCst=1&drv=1`)
      //   .then((res: any) => {
      //     let temp3 = res.data.data;
      //     if(temp3.length  > 0){
      //       setAssetList(temp3)
      //       setAssetOrginalData(temp3);
      //     }

      //     setTotalAssetsByPage(res.data.data.length);
      //     setTotalAssetCount(res.data.data.length);
      //     setTotalAssets(res.data.total);
      //     setPage(0);
      //     setValue1(0);
      //     //console.log(res.data.data,"repsonse1")
      //   })
      const response1 = axios.get(`${assetBaseURL}/assets/type?type=${props.type}&searchParam=${textValues}&page=${page}&size=100&srvCst=1&drv=1`);
      const response2 = axios.get(`${assetBaseURL}/assets/type?type=3W_EV&searchParam=${textValues}&page=${page}&size=100&srvCst=1&drv=1`);
      const response3 = axios.get(`${assetBaseURL}/assets/type?type=2W_EV&searchParam=${textValues}&page=${page}&size=100&srvCst=1&drv=1`);
      const [result1, result2, result3] = await Promise.allSettled([response1, response2, response3]);
      const combinedData = [];

      result1.status === 'fulfilled' && combinedData.push(...result1.value.data.data);
      result2.status === 'fulfilled' && combinedData.push(...result2.value.data.data);
      result3.status === 'fulfilled' && combinedData.push(...result3.value.data.data);
      let finaldata = combinedData.map(async (element: any) => {
        if (element?.id) {
          const result: any = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${element?.id}`).then((res: any) => {
            return res.data
          }).catch((err: any) => {
            console.log(err);
          })
          let statusvalue = result.lastSyncTs ? (result.onTrip ? "On Trip" : "Idle") : "";
          // console.log(result.lastSyncTs,"st");

          element.onTrip = statusvalue;
          return element
        }

      })
      setAssetList(combinedData)
      setAssetOrginalData(combinedData)
      setTotalAssetsByPage(combinedData.length);
      setTotalAssetCount(combinedData.length);
      setTotalAssets(combinedData.length);
      setPage(0);
      setValue1(0);
    } else {
      // setAssetOrginalData(list)
      // setAssetList(list)
      axios
        .get(`${assetBaseURL}/assets/type?type=Active%20Cooler%20Box&searchParam=${textValues}&page=${page}&size=100`)
        .then((res: any) => {

          let finaldata = res.data.data.map(async (element: any) => {
            if (element?.id) {
              const result: any = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${element?.id}`).then((res: any) => {
                return res.data
              }).catch((err: any) => {
                console.log(err);
              })
              let statusvalue = result.lastSyncTs ? (result.onTrip ? "On Trip" : "Idle") : "";
              element.onTrip = statusvalue;
              return element
            }

          })
          setAssetList(res.data.data)
          setAssetOrginalData(res.data.data)
        })
    }
  };

  const handleDisplay = () => {
    let url = `${assetBaseURL}/assets/type?type=${props.type}&page=0&size=100&srvCst=1&drv=1`;
    let url1 = `${assetBaseURL}/assets/type?type=3W_EV&page=0&size=100&srvCst=1&drv=1`;
    let url2 = `${assetBaseURL}/assets/type?type=2W_EV&page=0&size=100&srvCst=1&drv=1`
    if (props.selectedCloseMarket && props.selectedCloseMarket !== 'All') {
      url = `${url}&tenantId=${props.selectedCloseMarket}`
      //console.log(url,"selected market")
      url1 = `${url1}&tenantId=${props.selectedCloseMarket}`
      url2 = `${url2}&tenantId=${props.selectedCloseMarket}`
    }
    axios
      .get(url)
      .then((res: any) => {
        let cities: any = new Set(res.data.data.map((ele: any) => ele.city)
        )
        if (props.type === "Reefer Truck") {
          let temp1 = res.data.data;
          axios.get(url1)
            .then((res: any) => {
              let temp2 = res.data.data
              let temp = [...temp1, ...temp2]
              //console.log(temp,"combined")
              //console.log(temp2,"combined values")
              setAssetList(temp)
              setAssetOrginalData(temp);
              axios.get(url2)
                .then((res: any) => {
                  let temp3 = res.data.data
                  let temp = [...temp1, ...temp2, ...temp3]
                  //console.log(temp,"combined")
                  //console.log(temp2,"combined values")
                  //  setAssetData(temp);

                  cities = [...cities]
                  setAssetCities(cities)

                  setAssetList(temp);
                  setAssetOrginalData(temp);
                  setTotalAssetsByPage(res.data.data.length);
                  setTotalAssetCount(res.data.data.length);
                  setTotalAssets(res.data.total);
                  setValue1(0);
                })
            })
        } else {
          let temp1 = res.data.data;
          let cities: any = new Set(res.data.data.map((ele: any) => ele.city)
          )
          cities = [...cities]
          setAssetCities(cities)
          setAssetList(res.data.data);
          setAssetOrginalData(res.data.data);
          setTotalAssetsByPage(res.data.data.length);
          setTotalAssetCount(res.data.data.length);
          setTotalAssets(res.data.total);
          setValue1(0);
        }
      }

      )
  }
  const handleClearSearch = () => {
    setTextFieldValue("");
    if (props.value === 0) {
      axios
        .get(`${assetBaseURL}/assets/type?type=${props.type}&page=0&size=100&srvCst=1&drv=1`)
        .then((res: any) => {
          let temp1 = res.data.data;
          setAssetList(temp1)
          setAssetOrginalData(temp1);
          setTotalAssetsByPage(res.data.data.length);
          setTotalAssetCount(res.data.data.length);
          setTotalAssets(res.data.total);
          setPage(0);
          setValue1(0);
          axios.get(`${assetBaseURL}/assets/type?type=3W_EV&page=0&size=100&srvCst=1&drv=1`)
            .then((res: any) => {
              let temp2 = res.data.data;
              let temp = [...temp1, ...temp2]
              setAssetList(temp)
              setAssetOrginalData(temp);
              setTotalAssetsByPage(res.data.data.length);
              setTotalAssetCount(res.data.data.length);
              setTotalAssets(res.data.total);
              setPage(0);
              setValue1(0);
              axios.get(`${assetBaseURL}/assets/type?type=2W_EV&page=0&size=100&srvCst=1&drv=1`)
                .then((res: any) => {
                  let temp3 = res.data.data;
                  let temp = [...temp1, ...temp2, ...temp3]
                  let finaldata = temp.map(async (element: any) => {
                    if (element?.id) {
                      const result: any = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${element?.id}`).then((res: any) => {
                        return res.data
                      }).catch((err: any) => {
                        console.log(err);
                      })
                      let statusvalue = result.lastSyncTs ? (result.onTrip ? "On Trip" : "Idle") : "";
                      // console.log(result.lastSyncTs,"st");

                      element.onTrip = statusvalue;
                      return element
                    }
                  })
                  setAssetList(temp)
                  setAssetOrginalData(temp);
                  setTotalAssetsByPage(res.data.data.length);
                  setTotalAssetCount(res.data.data.length);
                  setTotalAssets(res.data.total);
                  setPage(0);
                  setValue1(0);

                })
            })
        })
    } else {
      // setAssetOrginalData(list)
      // setAssetList(list)
      axios
        .get(`${assetBaseURL}/assets/type?type=Active%20Cooler%20Box&size=100`)
        .then((res: any) => {
          let finaldata = res.data.data.map(async (element: any) => {
            if (element?.id) {
              const result: any = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${element?.id}`).then((res: any) => {
                return res.data
              }).catch((err: any) => {
                console.log(err);
              })
              let statusvalue = result.lastSyncTs ? (result.onTrip ? "On Trip" : "Idle") : "";
              // console.log(result.lastSyncTs,"st");

              element.onTrip = statusvalue;
              return element
            }
          })
          setAssetList(res.data.data)
          setAssetOrginalData(res.data.data)
        })
    }

  }

  useEffect(() => {
    let tempObj = { data: {}, id: '' };
    let tempArray: any[] = [];


    if (assetOrginalData.length > 1) {
      assetOrginalData.map((id, index) => {
        if (index == value1) {
          tempObj.data = id;
          tempObj.id = id.id;
        }
        tempArray.push(id);
      });
      setSelAssetData(tempObj);
      props.setSelectedAssetId(tempObj.id)
      setTimeout(() => {
        setTabValue(1);
        setTabValue(0);
      }, 100);
    }
    else {
      assetOrginalData.map((id, index) => {
        // if (index == value1) {
        tempObj.data = id;
        tempObj.id = id.id;
        // }
        tempArray.push(id);
      });
      setSelAssetData(tempObj);
      props.setSelectedAssetId(tempObj.id)
      // console.log(selAssetData,"asset");
      setTimeout(() => {
        setTabValue(1);
        setTabValue(0);
      }, 100);
    }
    if (tempArray.length > 0) {
      setModArray(tempArray);
    }
  }, [value1]);

  useEffect(() => {
    if (modArray.length > 0) {
      // getLastRecordAt(modArray);
    }
  }, [modArray]);

  useEffect(() => {
    if (modArray1.length > 0) {
      // getTelemetry(modArray1);
    }
  }, [modArray1]);

  useEffect(() => {
    if (modArray2.length > 0) {
      let temp: any[] = [];
      for (let i = 0; i < modArray2.length; i++) {
        let tmpObj = { tstamp: '', gatewayId: '' };
        tmpObj.tstamp = (modArray2[i].data.data[1].ndtimestamp) ? dateHelper.dateConversion(modArray2[i].data.data[1].ndtimestamp) : "---";
        tmpObj.gatewayId = modArray2[i].data.gatewayId;
        temp.push(tmpObj);
      }
      setModArray3(temp);
    }
  }, [modArray2]);

  useEffect(() => {
    let temp: any[] = [];
    if (modArray3.length > 0) {
      for (let i = 0; i < modArray1.length; i++) {
        for (let j = 0; j < modArray3.length; j++) {
          for (let k = 0; k < modArray1[i].data.length; k++) {
            if (modArray3[j].gatewayId == modArray1[k].gatewayId) {
              temp.push(modArray1[i]);
            }
          }
        }
      }
    }
  }, [modArray3]);

  useEffect(() => {
    setValue1(null);
    let url = `${assetBaseURL}/assets/type?type=${props.type}&page=0&size=100&srvCst=1&drv=1`;
    let url1 = `${assetBaseURL}/assets/type?type=3W_EV&page=0&size=100&srvCst=1&drv=1`;
    let url2 = `${assetBaseURL}/assets/type?type=2W_EV&page=0&size=100&srvCst=1&drv=1`
    if (props.selectedCloseMarket && props.selectedCloseMarket !== 'All') {
      url = `${url}&tenantId=${props.selectedCloseMarket}`
      //console.log(url,"selected market")
      url1 = `${url1}&tenantId=${props.selectedCloseMarket}`
      url2 = `${url2}&tenantId=${props.selectedCloseMarket}`
    }
    axios
      .get(url)
      .then((res: any) => {
        let cities: any = new Set(res.data.data.map((ele: any) => ele.city)
        )
        if (props.type === "Reefer Truck") {
          let temp1 = res.data.data;
          let finaldata = [];
          axios.get(url1)
            .then((res: any) => {
              let temp2 = res.data.data
              let temp = [...temp1, ...temp2]
              setAssetList(temp)
              setAssetOrginalData(temp);
              axios.get(url2)
                .then(async (res: any) => {
                  let temp3 = res.data.data
                  let temp = [...temp1, ...temp2, ...temp3]
                  //  setAssetData(temp);

                  finaldata = temp.map(async (element: any) => {
                    if (element?.id) {
                      console.log("element", element.id)
                      const result: any = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${element?.id}`).then((res: any) => {
                        return res.data
                      }).catch((err: any) => {
                        console.log(err);
                      })
                      let statusvalue = result.lastSyncTs ? (result.onTrip ? "On Trip" : "Idle") : "";
                      // console.log(result.lastSyncTs,"st");

                      element.onTrip = statusvalue;
                      return element
                    }
                  })
                  cities = [...cities]
                  setAssetCities(cities)
                  setAssetList(temp)
                  setAssetOrginalData(temp);
                  setTotalAssetsByPage(res.data.data.length);
                  setTotalAssetCount(res.data.data.length);
                  setTotalAssets(res.data.total);
                  setValue1(0);
                })
            })
        } else {
          let temp1 = res.data.data;
          let cities: any = new Set(res.data.data.map((ele: any) => ele.city)
          )
          cities = [...cities]
          setAssetCities(cities)
          setAssetList(res.data.data)
          setAssetOrginalData(res.data.data);
          // setAssetOrginalData(list);
          setTotalAssetsByPage(res.data.data.length);
          setTotalAssetCount(res.data.data.length);
          setTotalAssets(res.data.total);
          setValue1(0);
        }
      }

      )
      .catch((err: any) => {
        const UserToken = new ShipperHelper()
        UserToken.UserTokenExpiration()
      })
    document.addEventListener("click", () => {
      if (dDialog) {
        setDDialog(false);
      }
    })

  }, [props.selectedCloseMarket])


  /*const handleChange = (e: any) => {
    if (e.target.name === "status") {
      if (e.target.checked) {
        setStatusVisible(true);
      } else {
        setStatusVisible(false)
      }
    } else if (e.target.name === "location") {
      if (e.target.checked) {
        setLocationVisible(true);
      } else {
        setLocationVisible(false)
      }
    } else {
      return 0;
    }
  };*/




  const filterHandler = (event: any, name: string) => {
    if (name === "status") {
      if (!statusFlag) {
        setStatusFlag(true);
      }
      else {
        setStatusFlag(false);
      }
      setText("status")
    }
    else if (name === "location") {
      if (!locationFlag) {
        setLocationFlag(true);
      }
      else {
        setLocationFlag(false);
      }
      setText("location")
    }
    else if (name === "events") {
      if (!eventsFlag) {
        setEventsFlag(true);
      }
      else {
        setEventsFlag(false);
      }
    }
    else if (name === "rstatus") {
      if (!runstatusFlag) {
        setrunStatusFlag(true);
      }
      else {
        setrunStatusFlag(false);
      }
      setText("run")
    }
    else if (name === "backup") {
      if (!backupFlag) {
        setBackupFlag(true);
      }
      else {
        setBackupFlag(false);
      }
      setText("backup")
    }
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ backgroundColor: "#F4F6F8" }}>
        <Grid item xs={12} md={3} lg={3} style={{ backgroundColor: "#F4F6F8" }}>
          <Paper className={classes.paper + " p-0"} style={{ height: "44.2rem", boxShadow: "none" }}>
            <Taabs
              value={props.value}
              onChange={handleLeftBarChange}
              // indicatorColor="primary"
              style={{ width: "100%" }}

              className={classes.tabsLeft}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#E5E5E5",
                  width: "0px"
                }
              }}
            >
              <Taab
                className={classes.tabsLeft1}
                style={{ fontSize: "15px", textTransform: "capitalize" }}
                {...a12yProps(value)}

                label="Vehicles"
              />
              <Taab
                className={classes.tabsLeft1}
                {...a12yProps(value)}
                style={{ fontSize: "15px", textTransform: "capitalize" }}
                label="Cooler Box"
              />
              {/* <Taab
              className={classes.tabTruck}
               {...a12yProps(value)}
                label="REPORTS"
              /> */}
            </Taabs>
            <div style={{ height: "36rem", paddingTop: '2rem', marginLeft: "1rem" }}>

              <FormControl style={{ marginLeft: "-2rem", marginRight: "-1.3rem" }} >
                <TextField
                  style={{ width: '98%', border: "1px solid #CED4D9", marginTop: "-1rem", height: "2.5rem" }}
                  id="search-input-with-icon-adornment"
                  variant="standard"
                  value={textFieldValue}
                  autoComplete="off"
                  data-testid='assetSearch'
                  onChange={(e: any) => {
                    setTextFieldValue(e.target.value)
                    const text = e.target.value;
                    if (text.length >= 1 && (text.length - 1) % 1 === 0 || text.length == 0) {
                      if (text.length === 0) {
                        handleClearSearch()
                      }
                      else {
                        setTextFieldValue(text);
                        searchAsset(0, text)
                      }
                    }
                  }}

                  InputProps={{
                    startAdornment: (<InputAdornment position="start" style={{ color: "gray" }}>
                      <img src={searchicon} style={{ marginLeft: "7px" }} />
                    </InputAdornment>),
                    endAdornment: (textFieldValue ?
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          {/* <HighlightOffIcon /> */}
                        </IconButton>
                      </InputAdornment>
                      : <>
                        <div style={{ minWidth: '45px', fontSize: '16px', color: "#CED4D9" }}></div>
                      </>)
                  }}
                  placeholder='Search…'
                />
                <span className="divider" style={{ marginLeft: "9.4rem", marginTop: "-39px" }} />
                <div>
                  <div className="dropdown">
                    <ul className="dropbtn"  >

                      <li className="li" style={{
                        listStyle: "none", fontSize: "14px", float: "right",
                        color: "#74788D", marginTop: "-40px", marginRight: "0.6rem"
                      }} onClick={showDropdown}>

                        {filterOn ? <span style={{ marginRight: "-22px" }}>Filter<img src={filterapplied} style={{ marginLeft: "6px", width: "20px", height: "20px" }} /></span> : "Filter"}

                        <img src={filter} style={{ marginTop: "6px", marginLeft: "38px" }} onClick={clearFilter} /></li>


                    </ul>

                    {dDialog ? <div id="myDropdown" className="dropdown-content divZ">

                      <div className="insideDiv" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <li className="cbBox"

                          style={{ height: "1rem", width: "1rem", listStyle: "none", textAlign: "left", marginLeft: "1rem" }} >
                          <button className="cbBox" style={{ backgroundColor: "white", border: "none" }}
                            onClick={(e) => filterHandler(e, "status")} >Status</button></li>
                      </div>

                      {statusFlag && <div className="cbBox" style={{ marginLeft: "6rem" }}>


                        <div className="cbBox" style={{ marginTop: "20px", marginBottom: "1rem", marginLeft: "-230px" }}>
                          <input className="cbBox" type="checkbox" checked={selectedCity.includes("Active")} onChange={(e) => checkboxHandlerCity(e, "Active")}
                            style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "5rem" }} />
                          <span className="cbBox" style={{ color: "#000" }}>Active</span></div>
                        <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                          <input className="cbBox" type="checkbox" checked={selectedCity.includes("Inactive")} onChange={(e) => checkboxHandlerCity(e, "Inactive")}
                            style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "-100px" }} />
                          <span className="cbBox" style={{ color: "#000" }}>Maintenance</span></div>
                        <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                          <input className="cbBox" type="checkbox" checked={selectedCity.includes("Backup")} onChange={(e) => checkboxHandlerCity(e, "Backup")}
                            style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "-140px" }} />
                          <span className="cbBox" style={{ color: "#000" }}>Backup</span></div>
                      </div>}

                      <div className="insideDiv" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <li className="cbBox"

                          style={{ height: "1rem", width: "1rem", listStyle: "none", textAlign: "left", marginLeft: "1rem" }} >
                          <button className="cbBox" style={{ backgroundColor: "white", border: "none" }}
                            onClick={(e) => filterHandler(e, "rstatus")} >Running&nbsp;Status</button></li>
                      </div>

                      {runstatusFlag &&

                        <div className="cbBox" style={{ marginLeft: "6rem" }}>


                          <div className="cbBox" style={{ marginTop: "20px", marginBottom: "1rem", marginLeft: "-230px" }}>
                            <input className="cbBox" type="checkbox" checked={selectedCity.includes("onTrip")} onChange={(e) => checkboxHandlerCity(e, "onTrip")}
                              style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "5.5rem" }} />
                            <span className="cbBox" style={{ color: "#000" }}>On Trip</span></div>
                          <div className="cbBox" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                            <input className="cbBox" type="checkbox" checked={selectedCity.includes("Idle")} onChange={(e) => checkboxHandlerCity(e, "Idle")}


                              style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "-165px" }} />
                            <span className="cbBox" style={{ color: "#000" }}>Idle</span></div>
                        </div>



                      }
                      <hr />

                      <div className="insideDiv" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <li className="cbBox"

                          style={{ height: "1rem", width: "1rem", listStyle: "none", textAlign: "left", marginLeft: "1rem" }} >
                          <button className="cbBox" style={{ backgroundColor: "white", border: "none" }}
                            onClick={(e) => filterHandler(e, "location")} >Location</button></li>
                      </div>

                      {locationFlag && <div className="cbBox" style={{ marginLeft: "4.6rem" }}>
                        {assetCities && assetCities?.filter((ele: any) => ele !== undefined).map((ele: any) => {
                          return (
                            <div className="cbBox" style={{ marginTop: "25px", marginBottom: "1rem", textAlign: "start", color: "#000" }}>
                              <input className="cbBox" type="checkbox" checked={selectedCity.includes(ele)} onChange={(e) => checkboxHandlerCity(e, ele)}
                                style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "-3.2rem" }} />
                              <span className="cbBox" >{ele}</span></div>
                          )
                        })
                        }
                      </div>}
                      {userDetails.roleName === "LithPwr" && locationFlag && <div className="cbBox" style={{ marginLeft: "4.6rem" }}>
                        {assetCities && ["Bangalore"].map((ele: any) => {
                          return (
                            <div className="cbBox" style={{ marginTop: "25px", marginBottom: "1rem", textAlign: "start", color: "#000" }}>
                              <input className="cbBox" type="checkbox" checked={selectedCity.includes(ele)} onChange={(e) => checkboxHandlerCity(e, ele)}
                                style={{ height: "1rem", width: "1rem", marginRight: "1rem", marginLeft: "-3.2rem" }} />
                              <span className="cbBox" >{ele}</span></div>
                          )
                        })
                        }
                      </div>}
                    </div> : null}
                  </div>
                </div>
              </FormControl>


              {!props.overviewFlag ? <Taabs
                orientation="vertical"
                variant="scrollable"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#0078A5"
                  }
                }}
                scrollButtons="off"
                aria-label="Vertical tabs example"
                className={classes.tabs}
                value={value1}
                style={{ minWidth: "150px", clear: "both", paddingTop: "1rem", width: '100%', overflowY: "auto", height: "95vh", marginTop: "-20px" }}
              >

                {
                  assetOrginalData.map((id, index) => {
                    return (
                      <div  >
                        <Taab
                          onChange={(e) => handleChangeST(e, index, id)}
                          value={index}
                          style={{
                            height: "64px",
                            width: '100%',
                            borderBottom: "2px solid rgb(206, 212, 217)",
                            maxWidth: "100%",
                            marginLeft: "-10px",

                          }}
                          icon={
                            <div style={{ width: "100%", marginBottom: "-30px" }}>
                              <div style={{ marginTop: "1.7rem", marginLeft: "-3px" }}>
                                {" "}
                                <span style={{ float: "left", color: "#000000", opacity: 1, marginRight: "7px" }}>

                                  {(id.assetType?.description === "3wheeler type"
                                    || id.assetType?.description ===
                                    "2wheeler type") ?
                                    ((id.assetType?.description ===
                                      "2wheeler type") ? <img src={twowheelers} /> : <img src={threewheelers} />)
                                    : <img src={reeferfour} />}
                                </span>
                                <span style={{ float: "left", color: "#000000", opacity: 1 }}>
                                  {id.number}
                                </span>
                                <span style={{ float: "left", color: "#000000", opacity: 1 }}>
                                  {id.assetType?.name === '3W_EV' || id.assetType?.name === '2W_EV' ? '(EV)' : id.assetType?.name === 'Active Cooler Box' ? '' : '(ICE)'}
                                </span>

                                {" "}
                                <br />{" "}
                                <span id="Time" >
                                  {moment(id.lastUpdatedAt).format('DD-MM-YYYY h:mm a')}
                                  {/* {moment().format('DD-MM-YYYY h:mm a')} */}
                                </span>{" "}
                              </div>
                              <span style={{ marginTop: "-4.4rem", height: "5.9rem", marginLeft: "-50rem", width: "1.1rem" }}
                                id={id.underMaintenance ? "statusOcolor" : id.active ? "statusGcolor" : "statusRed"}>
                              </span>
                              {/* <span style={{ float: "right", marginTop: "-2.7rem" }} id={id.underMaintenance ? "statusOrange" : id.active ? "statusGreen" : "statusRed"}>{id.city}</span> */}
                              <span style={{ float: "right", marginTop: "-4rem", fontSize: '12px' }}> {id.underMaintenance ? (
                                <span id="statusOcolor" >Under Maintenance</span>
                              ) : id.active ? (
                                <span id="statusGcolor" >Active</span>
                              ) : (
                                <span id="statusRed">In Active</span>
                              )}</span>

                              <span style={{ float: "left", marginTop: "-4rem", fontSize: '12px', marginLeft: "9rem" }}> {id.backup ? (
                                <span id="backup" >Backup</span>
                              ) : (
                                < ></>
                              )}</span>
                              {/* {statusVisible &&
                              <span
                                style={{ float: "right", paddingBottom: "25px" }}
                              >
                                {" "}
                                {id.active ? (
                                  <span id="statusGcolor">Active</span>
                                ) : id?.underMaintenance ? (
                                  <span id="statusOcolor">Maintenance</span>
                                ) : (
                                  <span id="statusRed">In Active</span>
                                )}
                              </span>} */}

                              {/* {locationVisible && <span>
                              <span id="statusGreen">{id.city}</span>
                            </span>} */}
                              <br />
                            </div>
                          }
                          {...a12yProps(index)}
                          onClick={() => {
                            setAssetToken("true");
                          }}
                        />
                        {/* <Divider style={{width:"90rem",height:"2px",backgroundColor:"grey",marginRight:"-20px"}} /> */}
                      </div>
                    );
                  })}

              </Taabs>

                :
                <Taabs
                  orientation="vertical"
                  variant="scrollable"
                  indicatorColor="primary"
                  scrollButtons="off"
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                  value={value1}

                  style={{ minWidth: "150px", clear: "both", paddingTop: "1rem", width: '100%' }}
                >
                  {overviewData.map((id: any, index: number) => {
                    return (
                      <div>
                        <Taab
                          onChange={(e) => handleChangeST(e, index, id)}
                          value={index}
                          style={{
                            height: "70px",
                            width: '100%',
                            border: "1px solid lightgrey",
                            maxWidth: "100%",
                          }}
                          icon={
                            <div style={{ width: "100%" }}>
                              <div style={{ marginTop: "1.7rem", marginLeft: "1rem" }}>
                                <span style={{ float: "left", color: "#000000", opacity: 1 }}>
                                  {id.vehicleNum}
                                </span>
                              </div>
                            </div>
                          }
                          {...a12yProps(index)}
                          onClick={() => {
                            setAssetToken("true");
                          }}
                        />
                      </div>
                    )
                  })}
                </Taabs>
              }

            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={classes.paper + " p-0"} style={{ height: "43rem", marginLeft: "10px", marginBottom: "-30px", boxShadow: "none " }}>


            <Taabs
              value={tabValue}
              onChange={changeTab}
              indicatorColor="primary"
              textColor="inherit"
              className={classes.tabs}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#0078A5"
                }
              }}
            >
              <Taab
                className={classes.tab}
                {...a11yProps(value1)}
                label="Details"
              />
              <Taab
                className={classes.tab}
                {...a11yProps(value1)}
                label="Hardware Info"
              />list
            </Taabs>

            <TabPanel >
              {tabValue == 0 ?
                <Details
                  data={selAssetData.data}
                  assetID={selAssetData.id}
                  filter={props.filter}
                  fromAndTo={props.fromAndTo}
                  loadingCircleData={props.loadingCircleData}
                  keys={keys}
                  handleChangeKey={handleChangeKey}
                  recorded={handleDate}
                />

                : <HardwareInfo
                  data={selAssetData.data}
                  assetID={selAssetData.id}
                  filter={props.filter}
                  dates={props.dates}
                  fromAndTo={props.fromAndTo}
                  loadingCircleData={props.loadingCircleData}

                />
              }
            </TabPanel>


          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

