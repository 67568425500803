import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import axios from 'axios';
import UserService from './services/userService';
import { getUpdateToken } from './services/_helpers';
import coldchainLog from './services/consoleHelper';

//Testing build pls ignore this comment
//testing commits
axios.interceptors.request.use(
  config => {
    if (config) {
      
      const userApi = new UserService();
      const userDetails = userApi.getUserDetails();
      const updateToken = getUpdateToken();
      coldchainLog("AXIOS INTERCEPTION",updateToken);
      if(userDetails?.token) {
        // adds authorization token if available
        config.headers.Authorization = `Bearer ${userDetails.token}`;
        // config.headers.Authorization = `Bearer ${ userDetails.roles && userDetails.roles.includes('ClosedMarketplace') ? 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwcmVtLmFuYW5kLmExd0BhcC5kZW5zby5jb20iLCJST0xFUyI6IkFETUlOIiwiVVNFUklEIjoiNzkzYjVlMmMtNzVkYS00Yjk2LTkxZmUtZjk0MTU3Zjc2MmU1IiwiZXhwIjoxNjYyNTg1ODU2LCJURU5BTlQiOiIwMWY1YjhlMC01ODNhLTRiNTktOTFlOS1hNjA1NDgyMTY3OWMiLCJpYXQiOjE2NjI1Njc4NTZ9.UDxOK7a62N5MH-hGTfOH4TqsYivAhJLfjFPSymCYOyZytIH_yiOVCAx6wg_ewq_YWqD8yQ__p6nW4jzweDLlSQ' : userDetails.token}`;
      }
      return config;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App elevation={0} />
        </PersistGate>
      </BrowserRouter>
    </Provider>
    {/* <App /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(coldchainLog))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();