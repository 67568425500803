import axios from "axios";
import { assetBaseURL } from "./_url";
import jwt_decode from "jwt-decode";

export default class AssetTypeService {
  getAssetsType = (headers:any) => {
    return axios.get(`${assetBaseURL}/assetTypes`,{headers}).then((res:any)=>{
      return res.data
    })
  };
  updateAssetType = (headers:any, id:string) => {
    return axios.delete(`${assetBaseURL}/assetTypes/${id}`,{headers}).then((res:any)=>{
      return res.data
    })
  }
  
}