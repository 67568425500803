import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
//import Slider from "@material-ui/core/Slider";
import { useField } from "formik";
 import { Grid, Slider } from '@mui/material';

import './style.css'
const useStyles = makeStyles({
  root: {
  
    // color:'white'
  },
  slider :{
    
    color:'#1A0DAB',
    height:"24",
    
  }
  

});


function valuetext(value:any) {
  
  return `${value}°C`;
  
}

export default function   RangeSlider(props:any) {
  const classes = useStyles();
  const [value1, setValue1] = useState([-10, 10]);
  const {  ...rest } = props;
  const [field, meta, helper] = useField(props);

   const { setValue } = helper;

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue1(newValue as number[]);
    setValue(newValue as number[]);
  };
  return (
    <Grid xs={12}>
      <Typography id="range-slider" gutterBottom>
        <div>
        <div  style={{
    color: "#000000DE",fontSize:'14px',marginLeft:"3%",marginTop:'1.3%'}}> Temperature Required  </div> 
    <div style={{fontSize:"14px",color:"#000",marginLeft:"3%",marginTop:"1%"}}>-5°C to 2°C</div>
       
        </div>
    
      </Typography>
   

    <Slider
     getAriaLabel={() => 'Temperature range'}
  {...field}
        {...rest}
        className={classes.slider}
        min={-10}
        max={10}
        style={{width:"94%",marginLeft:"3%"}}
       size="small"
  onChange={handleChange}
  valueLabelDisplay="on"
  valueLabelFormat={value => <div>{`${value}°C`}</div>}

  // getAriaValueText={valuetext}
/>
<div>
<div style={{color:"#000",marginLeft:"2.7%"}}>-10°C
  <div style={{marginLeft:"94.7%",color:"#000",marginTop:'-1.3%'}}>10°C</div>
</div>
</div>

     
    </Grid>
  );
}
