import checkoutFormModel from './checkoutFormModel';
const date:String=new Date().getFullYear()+ "-"+((new Date().getMonth() + 1) < 10 ? '0' : '')
 + (new Date().getMonth()+1 )+"-"+(new Date().getDate() < 10 ? '0' : '')
 + (new Date().getDate())  
 
 var startTime = ((new Date().getHours()<10?'0':"")+new Date().getHours()) + ":" + ((new Date().getMinutes()<10?'0':"")+ new Date().getMinutes());

const {
  
  formField: {
    shipdoorBuilding,
    shipaddress,
    shipcity,
    shippincode,
    shiplandmark,
    shipmobile,
    shipper,
    deldoorBuilding,
    delstreetaddress,
    delcity,
    delpincode,
    dellandmark,
    delmobile,
    delname,
    source,
    destination,
    pickupStartDate,
    pickupEndDate,
    dropStartDate,
    dropEndDate,
    pickupTimeStart,
    pickupTimeEnd,
    dropTimeStart,
    dropTimeEnd,
    dropTimeEnd2,
    range,
    temperature,
    volume,
    weight,
    price,
    instructions,
    fragileItem,
    transporter,
    assetName,
    assetId,
    driverName,
    driverNumber,
    indicativePrice,
  
    
  }
} = checkoutFormModel;

export default {
  [shipdoorBuilding.name]:'',
  [shipaddress.name]:'',
  [shipcity.name]:'',
  [shippincode.name]:'',
  [shiplandmark.name]:'',
  [shipmobile.name]:'',
  [shipper.name]:'',
  [deldoorBuilding.name]:'',
  [delstreetaddress.name]:'',
  [delcity.name]:'',
  [delpincode.name]:'',
  [dellandmark.name]:'',
  [delmobile.name]:'',
  [delname.name]:'',
  [source.name]: '',
  [destination.name]: '',
  [pickupStartDate.name]: date,
  [pickupEndDate.name]:'',
  [dropStartDate.name]: date,
  [dropEndDate.name]:'',
  [pickupTimeStart.name]:startTime,
  [pickupTimeEnd.name]:'',
  [dropTimeStart.name]:startTime,
  [dropTimeEnd.name]:"",
  [dropTimeEnd2.name]:"",
  [range.name]:'',
  [temperature.name]:[10,-10],
  [volume.name] :'',
  [weight.name] :'',
  [instructions.name]:'',
  [price.name]:"",
  [fragileItem.name]:false,
  [transporter.name]: "",
  [assetName.name]:"",
  [assetId.name]:"",
  [driverName.name]:"",
  [driverNumber.name]:"",
  [indicativePrice.name]:"",
  
  };
