import React from 'react';
import Plot from 'react-plotly.js';
import { useState, useEffect } from 'react';


const LightEvents = (props:any) => {

    const Headingstyle: any = {
        textAlign:'left',
        marginLeft:'1rem',
        marginTop: '-36px',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontWeight: "normal",
        fontSize: '1rem',
        color: "#000000DE"
    }


    const [totals, setTotals]: any = useState({
        x: [],
        y: [],
        type: 'bar',
        name: 'Totals',
       // width:0.5,
        marker: {
            color: '#0E6BA6',
            //width:4
        }
    })

    
    const trace1:any = {
        x: props.totals,
        y: props.xaxis,
        
        name: 'Totals',
        type: 'bar',
       width:0.5,
        
        marker: {
            color: '#1769aa',
            width:0.5
        }
      };
      
        useEffect(() => {
      
        let ts: any = []
       

        // console.log("totals"); 
      Object.keys(props.data).forEach(function(key) {

        ts = [...ts, props.data[key].totals]
        // console.log(totals);
      });
        
    //   console.log(totals);
                setTotals({ ...totals, x: props.xaxis,y:ts })
                // console.log("totals");
               
    }, [])

    return (
        <div>
            {/* <div style={Headingstyle}>Lid Open Events</div> */}
            {props.noDataLightEvents !== '' ? <div style={{minHeight:'330px'}}>{props.noDataLightEvents}</div> :
            
            <Plot
                data={[
                    trace1
                   
                ]}
                layout={{
                    margin: { l: 30, r: 35, b: 60, t: 20 },
                    xaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 14,
                        },
                        tick0: 0,
                        rangemode: 'tozero',
                    
                    },
                    yaxis: {
                        tickfont: {
                            family: 'Roboto',
                            size: 10,
                        },
                        tick0: 0,
                        rangemode: 'nonnegative',
                        zerolinewidth: 1,
                    },
                    barmode: 'group',
                    height: 300,
                
                    legend: {
                        x: 0.35,
                        y: -0.7,
                        orientation: 'h'
                    },
                  
                   

                }}
                style={{ maxWidth: '1700px', maxHeight: '300px' }}
                config={{ displayModeBar: false, responsive: true, displaylogo: false }}
            />}
        </div>
    )
}

export default LightEvents
