import {  Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import  react, { useEffect, useState } from 'react';


export interface IImagesProps {
  id:any;
  imageUploads: any
}

export  function Images (props: IImagesProps) {
    const [open, setOpen] = useState(false);
    const [img, setImages] = useState([]);
    const [dialogimg,setDialogImg]= useState("fish1");



    

    const handleClickOpen = (img:any) => {
     
      setOpen(true);
      setDialogImg(img);
      
    };
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      if(props.imageUploads){
        setImages(props.imageUploads)
      }
    }, [props.imageUploads])

   
  return (
    <div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" >
          Image
          <span onClick={handleClose} style={{float:'right'}}>X</span>
        </DialogTitle>
        <DialogContent dividers>
          <img src={dialogimg} style={{width:'50%',height:'50%',marginLeft:"25%"}}/>
         
         
        </DialogContent>
       
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs>
        <div className="d-flex flex-row bd-highlight">
          {img ?
          <div>
            {
                img.map((img)=>{
                    return(
                    <span className="p-2 bd-highlight"> 
                    <img src={img.fullHttpsUrl} style={{width:'70px',height:'70px'}}
                    data-testid="imgOpen" onClick={()=>handleClickOpen(img.fullHttpsUrl)} alt="images"/>
                     </span> 

                    )
                })
            }
            </div>
            :"No Image Uploaded"}
  
</div>
       
        </Grid>
        
        </Grid>
    </div>
  );
}
