import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import coldchainLog from "../../../../services/consoleHelper";
import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
// import ShipperHelper, { ShippingRequestStatus,} from '../../../../services/_helpers';
// import { assetBaseURL, marketBaseURL } from "../../../../services/_url";
// import ShipperAPI from "../../../../services/shippingLanding";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import UserService from "../../../../services/userService";
// import mapMyIndiaApi from "../../../../services/mapMyIndia";
import ShipperHelper, {
  ShippingRequestStatus,
} from "../../../../services/_helpers";
import ShipperAPI from "../../../../services/shippingLanding";
import { assetBaseURL, marketBaseURL, driverBaseURL } from "../../../../services/_url";

import { toast } from "react-toastify";
toast.configure();



const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "left",
      fontSize: "20px",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(1.3),
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    chipCSS: {
      color: "#1A0DAB",
      backgroundColor: "rgba(26, 13, 171,.1)",
      marginLeft: "10px",
      "&:hover": {
        color: "#1A0DAB",
        backgroundColor: "rgba(26, 13, 171,.1)",
      },
    },
    chip: {
      marginLeft: "10px",
    },

    textFeild: {
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#1A0DAB",
          borderWidth: "2px",
        },
      },
      "& .MuiFormControl-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#1A0DAB",
          borderWidth: "2px",
        },
      },
      marginTop: "-5px",
    },
    select: {
      "& .MuiSelect-outlined	": {
        "&.Mui-focused fieldset": {
          borderColor: "#1A0DAB",
          borderWidth: "2px",
        },
      },
    },
  })
);

export function RouteAssign(props: any) {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const userDetails = new UserService().getUserDetails();
  const [transporterData, setTransporterData] = useState([]);
  const [selectedTransporter, setSelectedTransporter] = useState("");
  const [assetNumber, setAssetNumber] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [AcceptDisable, setAcceptDisable] = useState(false);
  const [chipStyle, setChipStyle] = useState("Both");
  const [assert, SetAssert] = useState([]);
  const [filterAssets, setFinalAssert] = useState([]);
  const [drivers, setDrivers] = useState([])

  const getAssetId: any = (assertNumber: string) =>
    filterAssets.filter((val: any) => val.value === assertNumber);
  const getTransporterId: any = (name: any) =>
    transporterData.filter((val: any) => val.value === name);



  function getAllDrivers(id: any) {
    axios
    .get(driverBaseURL + `/driver/getAllDrivers?tenantId=${id}`).then((res: any) => {
          console.log(res);
          if(res.data.response === "Driver Details are loaded succesfully!") {
            //toast('Driver Loaded');
            setDrivers(res.data.driverList);
          } else {
            toast("something went wrong while loading driver list!");
          }
      })
      
  }

  
  useEffect(() => {
    setOpen(props.open);
    if (userDetails.roles[0] !== "ClosedMarketplace") {
      const shipperService = new ShipperAPI();
      shipperService
        .getShipperID()
        //axios.get(`${tenantandUser}/tenants`)
        .then((res: any) => {
          let transporterOptions: any = [];
          res.map((val: any) => {
            if (val.roles[0]?.name === "Transporter") {
              let test = { key: "", value: "" };
              test.key = val.id;
              test.value = val.name;
              transporterOptions = [...transporterOptions, test];
            }
          });
          //console.log(transporterOptions);
          setTransporterData(transporterOptions);
          //getAssets(userDetails.tenantId)
        });
    } else {
      getAssets(userDetails.tenantId);
      setSelectedTransporter(userDetails.tenantId);
    }
    getAllDrivers(userDetails.tenantId)
  }, [props.open]);

  const getAssets = (id: any) => {
    axios
      .get(assetBaseURL + `/assets?tenantId=${id}&page=0&size=150`)
      .then((res: any) => {
        let options: any = [];
        res.data?.map((val: any) => {
          if (chipStyle === "Both") {
            let test = { key: "", value: "" };
            test.key = val.id;
            test.value = val.number;
            options = [...options, test];
          } else if (chipStyle === val.assetType.name) {
            let test = { key: "", value: "" };
            test.key = val.id;
            test.value = val.number;
            options = [...options, test];
          }
        });
        SetAssert(res.data);
        // handleClick(chipStyle)
        setFinalAssert(options);
      });
    coldchainLog("trans", selectedTransporter, filterAssets);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const id = getTransporterId(event.target.value as string)[0]?.key;
    setSelectedTransporter(id);
    getAssets(id);
  };

  const handleClick = (event: SelectChangeEvent) => {
    let item = event.target.value;
    let options: any = [];
    assert.map((val: any) => {
      if (item === "Both") {
        let test = { key: "", value: "" };
        test.key = val.id;
        test.value = val.number;
        options = [...options, test];
      } else if (item === val.assetType.name) {
        let test = { key: "", value: "" };
        test.key = val.id;
        test.value = val.number;
        options = [...options, test];
      }
    });
    setFinalAssert(options);
    setChipStyle(item);
  };

  const handleChangeAsset = (event: SelectChangeEvent) => {
    setAssetNumber(event.target.value as string);
  };
  const handleChangeDriverName = (event: any) => {
    console.log("Driver name " + event.target.value)
    setDriverName(event.target.value as string);
  };
  const handleChangeDriverNumber = (event: any) => {
    setDriverNumber(event.target.value as string);
  };

  function handleClose() {
    setOpen(false);
    props.onAssignPopupClose(false);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let allocatDate = new ShipperHelper();
    let TransporterID = selectedTransporter;
    coldchainLog("aaaa", TransporterID, selectedTransporter);

    let apiAssetId = getAssetId(assetNumber)[0].key;
    if (props.shippingRequests.length) {
      props.shippingRequests.forEach(async (d: any, index: any) => {
        let data = {
          shippingRequestId: d.id,
          assetId: apiAssetId,
          status: allocatDate.getStatusKeyByValue(
            ShippingRequestStatus.Accepted
          ),
          driverDetails: {
            name: driverName,
            phoneNumber: "+91" + driverNumber,
          },
          transporterId: selectedTransporter,
          routeplan_seq_num: index,
          audit: [
            {
              action: "Created",
              userId: userDetails?.userId,
              timestamp: allocatDate.dateToUTC(new Date()),
            },
          ],
        };
        console.log(data);
        await axios
          .post(marketBaseURL + "/marketplace/trips", data)
          .then((resp) => {
            setOpen(false);
            props.onAssignPopupClose("disable");
            // console.log("done");
          });
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        data-testid="dialog"
        aria-labelledby="customized-dialog-title"
        fullWidth
        style={{
          width: "500px",
          height: "620px",
          marginLeft: "500px",
          marginTop: "60px",
        }}
      >
        <React.Fragment>
          <DialogTitle style={{ height: "50px", marginTop: "-8px" }}>
            <span style={{ fontSize: "16px", color: "#000" }}>
              Asset and Driver Details
            </span>
          </DialogTitle>
          <DialogContent>
            <Divider
              style={{
                marginTop: "-1px",
                width: "550px",
                marginLeft: "-25px",
                backgroundColor: "#626363",
                height: "1.4px",
              }}
            />

            <form onSubmit={handleSubmit}>
              {userDetails.roles[0] !== "ClosedMarketplace" && (
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginTop: "20px" }}
                  >
                    Transporter *
                  </InputLabel>
                  <Select
                    data-testid="transpoter"
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ marginTop: "20px" }}
                    //   value={age}
                    label="Transporter *"
                    onChange={handleChange}
                  >
                    {transporterData.map((option: any) => {
                      return (
                        <MenuItem
                          style={{ color: "#000" }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      );
                    })}
                    <br />
                  </Select>
                  <p
                    style={{
                      fontSize: "14px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    Asset Details
                  </p>
                </FormControl>
              )}
              {/* <SelectField 
                            name="transporter"
                            label="Transporter"
                            options={transporterResult}
                            
                            fullWidth
                            /> */}

              {/* <Grid item xs={12} lg={6}> */}
              <FormControl fullWidth style={{ marginTop: "30px" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginTop: "-20px" }}
                >
                  Asset Details
                </InputLabel>
                <Select
                  style={{ width: "100%", marginTop: "-20px" }}
                  name="assetType"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  data-testid="assetType"
                  label="Asset Details"
                  // disabled={props.values.transporter ? false : true}
                  onChange={handleClick}
                >
                  {[
                    { value: "Reefer Truck", title: "Vehicles" },
                    { value: "Cooler Box", title: "Cooler Box" },
                    { value: "Both", title: "Both" },
                  ].map((option: any) => {
                    return (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{ color: "#000" }}
                      >
                        {option.title}
                      </MenuItem>
                    );
                  })}
                </Select>
                <br />
              </FormControl>
              {/* </Grid> */}
              {/* <div>
                    <Chip size="small"
                        label="Reefer Trucks"
                        data-testid="chipReefer"
                        onClick={() => handleClick("Reefer Truck")}
                        className={chipStyle === "Reefer Truck" ? classes.chipCSS : classes.chip}
                    />
                    <Chip size="small"
                        label="Cooler Boxes"
                        data-testid="chipCooler"
                        onClick={() => handleClick("Active Cooler Box")}
                        className={chipStyle === "Active Cooler Box" ? classes.chipCSS : classes.chip}
                    />
                    <Chip size="small"
                        label="Both"
                        data-testid="chipBoth"
                        onClick={() => handleClick("Both")}
                        className={chipStyle === "Both" ? classes.chipCSS : classes.chip}
                    />
                    </div> */}
              <br />

              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginTop: "-4px" }}
                >
                  Asset Name *
                </InputLabel>
                <Select
                  required
                  className={classes.textFeild}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Asset Name *"
                  onChange={handleChangeAsset}
                >
                  {filterAssets.map((option: any) => {
                    return (
                      <MenuItem
                        key={option.key}
                        value={option.value}
                        style={{ color: "#000" }}
                      >
                        {option.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              <p style={{ marginTop: "10px", color: "#000" }}>Driver Details</p>
              <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginTop: "20px" }}
                  >
                    Driver Name *
                  </InputLabel>
                  <Select
                    data-testid="driver"
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ marginTop: "20px" }}
                    //   value={age}
                    label="Driver Name *"
                    onChange={handleChangeDriverName}
                  >
                    {drivers.map((option: any) => {
                      console.log(option)
                      return (
                        <MenuItem
                          style={{ color: "#000" }}
                          key={option.driverId}
                          value={option.firstName + option.lastName}
                        >
                          {option.firstName + option.lastName}
                        </MenuItem>
                      );
                    })}
                    <br />
                  </Select>
                  <br />
              </FormControl>
              <TextField
                id="outlined-basic"
                type="number"
                className={classes.textFeild}
                required
                onChange={handleChangeDriverNumber}
                label=" Mobile Number"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91-</InputAdornment>
                  ),
                }}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                fullWidth
              />
              <br />

              <DialogActions>
                <Button
                  type="button"
                  className="outline"
                  style={{
                    border: "1px solid rgb(202 198 198)",
                    marginTop: "11px",
                    height: "36px",
                    textTransform: "capitalize",
                    color: "rgb(202 198 198)",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  // disabled={isSubmitting}
                  data-testid="assignSubmit"
                  style={{
                    backgroundColor: "#0078a5",
                    color: "#fff",
                    textTransform: "capitalize",
                    marginTop: "11px",
                    marginRight: "-6px",
                  }}
                //   onClick={handleSubmit}
                >
                  Assign and Accept
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  );
}
