import React, { Fragment, useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { InputField } from "../../../common/FormFields";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { assetBaseURL } from "../../../../../services/_url";
import axios from "axios";
import ShipperAPI from "../../../../../services/shippingLanding";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export default function AssetDetails(props: any) {
  // console.log(props);
  const [assetName, setAssetName] = useState([]);
  
  const [assetState, setAssetState] = useState({ AssetType: props.location.asset.assetType.name, 
    AssetName: props.location.asset.assetType.name, AssetID: props.location.asset.number, 
    Status: props.location.asset.assetType.active ? "Active" : "Under Maintenance" , id: props.location.id,description : props.location.asset.assetType.description})
  const classes = useStyles();
  const [assetData, setAssetData] = useState([]);
  
  const [transporterData, setTransporterData] = useState([]);

  const [type, setType] = useState(props.location.asset.assetType.name);
console.log(props.location,"hard");

  const changeHandler = (e: any) => {
    const { name, value } = e.target;
    // if(name === 'AssetName') {
    //   setAssetState(prevState => ({
    //     ...prevState, [name]: value.number, AssetId: value.id
    //   }))
    //   props.formData(assetState);
    //   return;
    // }
    if (name === 'AssetType') {
      setType(value)
    }
    setAssetState(prevState => ({
      ...prevState, [name]: value
    }))
    props.formData(assetState);
  }

  useEffect(() => {
    const type = "assets";
    axios
      .get(`${assetBaseURL}/assetTypes`)
      .then((res: any) => {
        // console.log(res.data)
        setAssetData(res.data);
      })
      .catch((err: any) => {
        setAssetData([]);
      });

      //
      const shipperService = new ShipperAPI();
    shipperService.getShipperID()
      //axios.get(`${tenantandUser}/tenants`)  
      .then((res: any) => {
        let transporterOptions: any = []
        res.map((val: any) => {
          if ((val.roles[0]?.name === "Transporter")) {
            let test = { key: "", value: "" }
            test.key = val.id;
            test.value = val.name
            transporterOptions = [...transporterOptions, test]
          }
        })
        setTransporterData(transporterOptions);
      })

  }, []);

  useEffect(() => {
    if (type) {
      axios
        .get(`${assetBaseURL}/assets/type?type=${type}&page=0&size=100`)
        .then((res: any) => {
          // console.log(res.data.data)
          setAssetName(res.data.data);
        })
        .catch((err: any) => {
          setAssetName([]);
        });
    }

  }, [type]);

 

  // console.log(assetData, "assetData")
  return (
    <Fragment>
      <div className={classes.root}>
        <div className="layout-border-requirement">
          <Grid container spacing={3} style={{ marginLeft: "2rem" }}>
            <Grid item xs={12} >
              <Grid item xs={12} lg={6} style={{float:"right",width:"100%",marginTop:"2.8rem"}}>
                <br />
                <FormControl fullWidth>
                  <TextField
                    style={{ width: "80%", }}
                    data-testid="AssetName"
                    id="outlined-basic"
                    variant="outlined"
                    name="AssetName"
                    label="Asset Name"
                    onChange={changeHandler}
                    value={assetState.AssetName}
                  >
                  <br />
                  </TextField>
                </FormControl>
                <br /><br />
                <FormControl fullWidth>
                  <TextField
                    style={{ width: "80%", }}
                    data-testid="Description"
                    id="outlined-basic"
                    variant="outlined"
                    name="Description"
                    label="Description"
                    onChange={changeHandler}
                    value={assetState.description}
                  >
                  <br />
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <p style={{ fontSize: "14px" }} className="cardTitle">
                  Asset Details
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Asset type
                  </InputLabel>
                  <Select
                    style={{ width: "80%", }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="AssetType"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.AssetName}
                  >
                    {assetData.map((option: any) => {
                      return (
                        <MenuItem style={{color:'#000000'}}key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
              </Grid>
              <br />
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                    Asset Number
                  </InputLabel>
                  <Select
                    style={{ width: "80%", }}
                    labelId="demo-simple-select-label"
                    data-testid="assetNumber"
                    id="demo-simple-select"
                    name="AssetNumber"
                    label="AssetNUmber"
                    onChange={changeHandler}
                    value={assetState.AssetID}
                  >
                    {assetName.map((option: any) => {
                      return (
                        <MenuItem style={{color:'#000000'}} key={option.id} value={option.number}>
                          {option.number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
              </Grid>
              <br />
              {/* <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                  Asset ID
                  </InputLabel>
                  <Select
                    style={{ width: "80%", }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="AssetId"
                    label="Transporter"
                  onChange={changeHandler}
                  >
                    {assetName.map((option: any) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.id}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              {/* <FormControl fullWidth>
                  <InputField   name="AssetId"
                  label="Asset ID"
                  fullWidth id="demo-simple-select-label">
                    Asset ID
                    </InputField>
                           
              
                  <Select
                    style={{ width: "80%" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="AssetId"
                    label="Transporter"
                    onChange={changeHandler}
                  >
                     {assetData.map((option: any) => {
                      return (
                        <MenuItem key={option.id} value={option.number}>
                          {option.number}
                        </MenuItem>
                      );
                    })} 
                   
                  </Select>
                </FormControl> */}

              {/* </Grid> */}
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    style={{ width: "80%" }}
                    labelId="demo-simple-select-label"
                    data-testid="transporter"
                    id="demo-simple-select"
                    name="Status"
                    label="Transporter"
                    onChange={changeHandler}
                    value={assetState.Status}
                  >
                    <MenuItem style={{color:'#000000'}} value="Active">Active</MenuItem>
                    <MenuItem style={{color:'#000000'}} value="Inactive">Inactive</MenuItem>
                    <MenuItem style={{color:'#000000'}} value="Undermantance">Undermantance</MenuItem>
                  </Select>
                </FormControl>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
}