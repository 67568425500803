import React from 'react'
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Badge, ClickAwayListener } from '@material-ui/core';
import ShipperHelper from '../../../../services/_helpers';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { useState, useEffect } from 'react';
import MarketPlaceAPI from '../../../../services/marketPlaceService';
import { useHistory } from 'react-router-dom';
import { ROLE } from '../../../../services/userService';
import Bell from'../../../../assets/images/bell.svg';
const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            // right: -3,
            // top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
            backgroundColor: '#F2531D'
        },
    })
)(Badge);

const Notification = (props: any) => {

    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        if (rowID.length !== 0) {
            bellNotificationIcon.bellNotificationUpdate(rowID)
        }
        setRowID([])
        setAnchorEl(null);
        
    };

    const bellNotificationIcon = new MarketPlaceAPI()
    let dateHelper = new ShipperHelper();
    const [rowState, setRowState] = useState([]);
    const [bellCount, setBellCount] = useState(0);
    const [rowID, setRowID] = useState([]);
    
    useEffect(() => {
        let belldata: any = []
        let totalbellcount: any = 0

        let NotificationData: any = (props.userDetails?.roles[0]).toLowerCase() === "shipper" ? bellNotificationIcon.bellNotificationShipper(props.userDetails?.tenantId) :
            (props.userDetails?.roles[0]).toLowerCase() === "transporter" ? bellNotificationIcon.bellNotificationTranspoter(props.userDetails?.tenantId) :
                bellNotificationIcon.bellNotificationDenso()

        NotificationData
            .then((values: any) => { 
                var data= values.data;
                data.map((item: any) => {
                    belldata = [...belldata, {
                        "Id": item.id,
                        "RequestId": item.shipperReqId,
                        "category": item.category,
                        "severity": item.severity,
                        "message": item.message,
                        "timestamp": item.audit[0].timestamp
                    }]
                })
                belldata.forEach((element: any) => {
                    if (element.Id) {
                        totalbellcount += 1
                    }
                });
                setRowState(belldata)
                setBellCount(totalbellcount)
            })
            .catch((err: any) => {
                console.error(err);
                const UserToken = new ShipperHelper()
                UserToken.UserTokenExpiration()
            })

    }, [props.userDetails?.tenantId])

    const bellnotificationClear = (id: any, index: any) => {
        setRowID([...rowID, id])
        if (bellCount !== 0) {
            setBellCount(bellCount - 1)
        }
    }
    const bellnotificationClearAll = () => {
        let row: any = rowID
        rowState.forEach(element => {
            if (element.Id) {
                row = [...row, element.Id]
            }
        });
        setRowID(row)
        setRowState([])
        setBellCount(0)

    }

    const RequestIdClick = (requestId: any, id: any, index: any) => {
        let Roles: any = ''
        if (props.userDetails?.token) {
            if (props.userDetails?.roles?.includes(ROLE.transporter)) {
                Roles = "transporter"
            }
            if (props.userDetails?.roles?.includes(ROLE.shipper)) {
                Roles = "shipper"
            }
            if (props.userDetails?.roles?.includes(ROLE.denso)) {
                Roles = "denso"
            }
        }
        if (rowID.length !== 0) {
            bellNotificationIcon.bellNotificationUpdate(rowID)
        }
        setBellCount(bellCount - 1)
        bellNotificationIcon.bellNotificationUpdate([id])
        history.push(`/${Roles}/${requestId}`)
        window.location.reload()
    }


    return (
        
        <div className='container'>
            <IconButton
                aria-label="show 0 new notifications"
                color="inherit"
                data-testid = 'bellbtn'
                style={{ color: '#5A5A5A' }}
                size="medium"
                onClick={handleClick}
            >
                <StyledBadge badgeContent={bellCount} color="secondary">
                    <img src={Bell} style={{marginTop:"0.5rem",marginRight:"2rem",width: '24px',height: '24px'}}/>
                </StyledBadge>
            </IconButton>
            <div>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    PaperProps={{
                        sx: {
                            maxHeight: 454,
                            overflow: 'hidden',
                        }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                    <div className='container' style={{ width: "450px" }}>
                        <Grid container spacing={6}>
                            <Grid item xs={3}>
                                <div style={{ padding: "6px", font: "bold 14px roboto",color:"#000"}}>
                                    Notification
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ textAlign: 'right' }}>
                                    <Button size='small' data-testid='clearall'onClick={bellnotificationClearAll} >Clear All</Button>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div style={{ textAlign: 'right' }}>
                                    <Button size='small' data-testid='close'onClick={handleClose}>Close</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    </ClickAwayListener>
                    <div style={{ height: '405px', overflowY: 'auto' }}>
                        {rowState.map((data: any, index: any) => (
                            <Card style={{ width: "450px", height: "90px", margin: '10px' }}>
                                <CardContent>
                                    <Grid container spacing={2} >
                                        {data.severity === "Critical" ?
                                            <Grid item xs="auto" style={{ padding: '0px' }}>
                                                <div style={{ backgroundColor: 'red', width: "7px", height: '100%' }} />
                                            </Grid> :
                                            data.severity === "High" ?
                                                <Grid item xs="auto" style={{ padding: '0px' }}>
                                                    <div style={{ backgroundColor: '#FF6666', width: "7px", height: '100%' }} />
                                                </Grid> :
                                                data.severity === "Medium" ?
                                                    <Grid item xs="auto" style={{ padding: '0px' }}>
                                                        <div style={{ backgroundColor: '#FF9933', width: "7px", height: '100%' }} />
                                                    </Grid> :
                                                    <Grid item xs="auto" style={{ padding: '0px' }}>
                                                        <div style={{ backgroundColor: '#AEB404', width: "7px", height: '100%' }} />
                                                    </Grid>
                                        }
                                        <Grid item xs={11} style={{ padding: '2px 0px 2px 10px' }}>
                                            <div>
                                                <div style={{ width: "2px", height: "2px", float: 'right' }}>
                                                    <IconButton size="small" data-testid='clear'onClick={() => bellnotificationClear(data.Id, index)}><CloseIcon /></IconButton>
                                                </div>
                                                <Typography variant='body2'>
                                                    <span style={{ fontWeight: 'bold' }}>{data.category}</span>
                                                    <span> - </span>
                                                    <span
                                                        style={{
                                                            color: data.severity === "Critical" ? 'red'
                                                                : data.severity === "High" ? '#FF6666'
                                                                    : data.severity === "Medium" ? "#FF9933"
                                                                        : '#AEB404'
                                                        }}>
                                                        {data.severity}
                                                    </span>
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant='body2' noWrap>{data.message}</Typography>
                                                <Button onClick={(e) => RequestIdClick(data.RequestId, data.Id, index)}>
                                                    <Typography variant='body2' color='blue' noWrap>{data.RequestId == null ? "---" : data.RequestId}</Typography>
                                                </Button>
                                            </div>
                                            <div style={{ fontSize: "12px", color: 'gray', textAlign: 'right', paddingBottom: '5px' }} >
                                                {dateHelper.dateConversion(data.timestamp)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </Menu>
            </div>
        </div>
    )
}

export default Notification