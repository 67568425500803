import Button from '@material-ui/core/Button';
import { useHistory  } from "react-router-dom";


import {CircularProgress} from '@mui/material';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
//import {  Tab, Tabs } from 'react-bootstrap';
import Completed from './Requests/completed';
import Active from './Requests/active';
import './densoStyle.css';
import AddIcon from "@material-ui/icons/AddCircleOutline";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { TableManageColumnsFormData } from '../Dashboard/Common/Interfaces/custom';
// import { Popover, Tooltip } from '@material-ui/core';
// import TableFilter from '../common/TableFilter';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import MarketPlaceAPI from '../../../services/marketPlaceService';
import mapMyIndiaApi from '../../../services/mapMyIndia';
import UserService, { ROLE } from '../../../services/userService';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../redux/user/user.action";
import { selectCurrentUser } from "../../../redux/user/user.selector";
import Helper, { ShippingRequestStatus } from '../../../services/_helpers';
// import Badge from '@mui/material/Badge';
// import TableManageColumns from '../common/TableManageColumn/columnReorder';
import ShipperHelper from '../../../services/_helpers';
import upload from'../../../assets/images/upload.svg';
import route from'../../../assets/images/route.svg';
import createShipment from'../../../assets/images/createShipment.svg';
import Download from "../../../assets/images/Download.svg";
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Tabs,
  Tab,
  Box,
  Typography,
  // FormControl,
  // Select,
  // MenuItem,
  // IconButton,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { PlanRoute } from '../Utils/PlanRoute';
import { BulkUpload } from '../Utils/BulkUpload';
// import AssetAllocationForm from './Requests/Assign';
import AssetAllocationForm1 from './Requests/newAssign';
import { toast } from 'react-toastify';
import { ResponsiveDrawer } from '../common/Drawer';
toast.configure();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,    
      marginLeft:'-8px',
      boxShadow: "none",     
    },
    paper: {           
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:'18px',
        float:'left',
       fontWeight:599 ,
       
            
    },
    tabsWrapper: {
      position: "relative",
      "@media (max-width:500px)": {
        marginTop: "3rem",
      },
    },
    tabs: {},
    tab: {
      
      "&.Mui-selected": {
        outline: "#0078A5",
        color:"#0078A5"
      },
      outline: "none",   
      borderColor:"yellow", 
      fontSize:"14px",
      textTransform:"capitalize", 
    },    
    tabPanel: {
      backgroundColor: "#fafafa",
      marginTop:'2rem',
    },    
    button: {
        margin: theme.spacing(1),
   
       textTransform:"capitalize",
        fontWeight:400,
        border:' 2px solid #2196f3',
        backgroundColor:'#2196f3',
        color:'#fff',
        '&:hover':{
          border:' 2px solid #2196f3',
            color:'#2196f3',
        },
        "&:disabled": {
        border:' 2px solid #0078A5',
        color:'#0078A5',
        backgroundColor:'#fff'
        },
        
      },
      tableWidth:{
          width:'100%',         
      },
      disableExportIcon: {
        color: '#999999',
        cursor: 'progress'
      },
      cnDiv:{
        // textAlign:"right",
        // width:"20%",
        float:"right", 
        // marginRight:'-10px',
        '@media (max-width:700px)': {
          width:'40%'
      }
    }
  }),
);
export interface IShipperLandingProps {
  searchReqId: string
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    value: index,
  };
}
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{padding:'1px', backgroundColor:"#F4F6F8"}}>
          <div style={{width:'100%'}}>
          <hr style={{border:"1px solid lightgrey", width:'100%', paddingTop:'1.3rem', borderTop:'0', borderLeft:'0', borderRight:'0'}}/>
          <Typography style={{width:'100%'}}>{children}</Typography>         
          </div>
        </Box>
      )}
    </div>
  );
}

export  function ShipperLanding (props: any) {
  
    let {   currentUser, setCurrentUser } = props;
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [manageColumnsOptions, setManageColumnsOptions] = useState<TableManageColumnsFormData[]>(JSON.parse(sessionStorage.ManageColumn || {}));
    const [userName, setUserName] = useState<string>('user-error');
    const [shipmentRowData, setShipmentRowData] = useState([]);
    const [locationArray, setLocationArray] = useState([]);
    const [requestNos, setRequestNos] = useState([]);
    const [planArray, setPlanArray] = useState([]);
    const [locationStr, setLocationStr] = useState("");
    const [tripData, setTripData] = useState([]);
    const [distance, setDistance] = useState("");
    const [duration, setDuration] = useState("");
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>();
    const helper = new Helper();
    const [clickedExport, setClickedExport] = useState<boolean>(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [anchorElManageColumnsPopOver] = useState(null);
    const [btnFlag, setBtnFlag] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [isReady, setReady] = useState(false);
    const [isAssetOpen, setAssetOpen] = useState(false);
    const [isActive, setActive] = useState(false);
    const [fetchTableFlag,setFetchTableFlag] = useState(false);
    const mapApi = new mapMyIndiaApi();
    const userApi = new UserService();
    const userDetails = userApi.getUserDetails();

    const [dataToDownload, setDataToDownload] = useState<any>([
      ["firstname", "lastname", "email"],
      ["Ahmed", "Tomi", "ah@smthing.co.com"],
      ["Raed", "Labes", "rl@smthing.co.com"],
      ["Yezzi", "Min  l3b", "ymin@cocococo.com"]
    ]);
    const [anchorElFilterPopOver, setAnchorElFilterPopOver] = React.useState(null);
    const [filterOptions, setFilterOptions] = React.useState(JSON.parse(sessionStorage.ApplyFilter));
    const [keys, setKeys] = React.useState('activeRequest')
    const [searchReqId, setSearchReqId] = useState<string>("");
    const [showRoutePlanning, setShowRoutePlanning] = useState(false)
    const history = useHistory();
    const [isRoutePlanningInProgress, setIsRoutePlanningInProgress] = useState(false);

    const handleChangeKey = (k:any) => {
      setKeys(k)
    }

    const handleTabChange = (event: any, newValue: any) => {
      setValue(newValue);
    };
    
    const handleExportIconClick = (event?: any) => {
      // let isActive = true; // if required get from tab selection commented by sarath on 05.10.2021
      if(!exportLoading) {
        setExportLoading(true);
        let marketApi = new MarketPlaceAPI();
        /* const reqStatus = isActive ? [
          helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
          helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
          helper.getStatusKeyByValue(ShippingRequestStatus.InTransit)
        ] : [
          helper.getStatusKeyByValue(ShippingRequestStatus.Completed)
        ];
        commented by sarath on 05.10.2021 - if export should be dependent on tab selection, this will be used.
        */
        const allStatuses = [
          helper.getStatusKeyByValue(ShippingRequestStatus.PendingAcceptance),
          helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
          helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
          helper.getStatusKeyByValue(ShippingRequestStatus.Completed),
          helper.getStatusKeyByValue(ShippingRequestStatus.Cancelled)
        ];
        marketApi.getRequestsByQuery(0, 1000, 'DESC', { status: allStatuses})
          .then((data: any) => {
            let columnsData:any = []
            manageColumnsOptions.forEach((item:any) => {
              columnsData = [...columnsData, item.title]
            })
            setUserName((new UserService()).getUserDetails().email.split('@')[0]);
            // let mappedData = [["Status", "Request ID", "Provider", "Shipper", "Asset", "Driver", "Price (INR)", "Created On"]];
            /* istanbul ignore next */
            
            let mappedData = [columnsData];
            /* istanbul ignore next */
            data.data.forEach((item: any) => {
              let DataToPush:any = []
              /* istanbul ignore next */
              for (let index = 0; index < columnsData.length; index++) {                
                if(columnsData[index] === "STATUS"){
                  DataToPush = [...DataToPush, (item?.status) ? ShippingRequestStatus[item.status] : '!Error']
                }
                else if(columnsData[index] === "REQUEST ID"){
                  DataToPush = [...DataToPush, (item?.requestNumber) ? String(item.requestNumber) : '!Error']
                }
                else if(columnsData[index] === "PROVIDER"){
                  DataToPush = [...DataToPush, (item?.transporter?.name) ? item.transporter.name : 'N/A']
                }
                else if(columnsData[index] === "SHIPPER"){
                  DataToPush = [...DataToPush, (item?.shipper?.name) ? item.shipper.name : 'N/A']
                }
                else if(columnsData[index] === "ASSET"){
                  DataToPush = [...DataToPush, (item?.asset?.number) ? item.asset.number : 'N/A']
                }
                else if(columnsData[index] === "DRIVER"){
                  DataToPush = [...DataToPush, (item?.driverDetails?.name) ? item.driverDetails.name : 'N/A']
                }
                else if(columnsData[index] === "PRICE (INR)"){
                  DataToPush = [...DataToPush, (item?.paymentDetails?.actualAmount || item?.paymentDetails?.actualAmount === 0) ? item.paymentDetails?.actualAmount : 'N/A']
                }
                else if(columnsData[index] === "CREATED ON"){
                  DataToPush = [...DataToPush, (item?.createdAt) ? moment(new Date(item.createdAt)).format('DD-MMM-YY hh:mma') : 'Date Error']
                }
              }
              /* istanbul ignore next */
              mappedData.push(
                DataToPush
              );
              // mappedData.push([
              //   (item?.status) ? ShippingRequestStatus[item.status] : '!Error',
              //   (item?.requestNumber) ? String(item.requestNumber) : '!Error',
              //   (item?.shipper?.name) ? item.shipper.name : 'N/A',
              //   (item?.transporter?.name) ? item.transporter.name : 'N/A',
              //   (item?.asset?.number) ? item.asset.number : 'N/A',
              //   (item?.driverDetails?.name) ? item.driverDetails.name : 'N/A',
              //   (item?.paymentDetails?.actualAmount   || item?.paymentDetails?.actualAmount   === 0) ? item.paymentDetails?.actualAmount   : 'N/A',
              //   (item?.createdAt) ? moment(new Date(item.createdAt)).format('DD-MMM-YY hh:mma') : 'Date Error'
              // ]);
            });
            /* istanbul ignore next */
            setDataToDownload(mappedData);
            /* istanbul ignore next */
            setClickedExport(true);
            /* istanbul ignore next */
            setExportLoading(false);
          })
          .catch((err: any) => {
            // todo: toast error
            console.error(err);
            setExportLoading(false);
            const UserToken = new ShipperHelper()
            UserToken.UserTokenExpiration()
          })
      }
    }

    useEffect(() => {
      /* istanbul ignore next */
      if(clickedExport && csvLinkRef?.current && dataToDownload) {
        csvLinkRef.current.link.click();
        setClickedExport(false);
        setDataToDownload('');
      }
    }, [clickedExport, dataToDownload]);
  
    const handleFilterPopOverClick = (event: any) => {
      setAnchorElFilterPopOver(event.currentTarget);
    };
  
    const handleFilterPopOverClose = () => {
      setAnchorElFilterPopOver(null);
    };

    const validatePlanRouteButton =(event:any, row:any, rowData:any) => {
      
     // if(event.target.checked){
        if(row !== undefined){
          //console.log(rowData,"row")

          for(let item of rowData){
            if(item.cbFlag && item.status !== "PendingAcceptance"){
              setBtnFlag(true);   
              break;
            }
            else {
              let planRouteData = rowData.filter((r:any)=> r.cbFlag && r.status === "PendingAcceptance");
              setShipmentRowData(planRouteData);
              setBtnFlag(false);
            }
          }
          let count = 0;
          for(let item of rowData){
            if(!item.cbFlag ){
              count++;
              if(count === rowData.length){
                setBtnFlag(true);
              }
            }
          }
    }
  }
  
    const openFilterPopOver = Boolean(anchorElFilterPopOver);
    const filterPopOverId = openFilterPopOver ? 'transporter-filter-popover' : undefined;
    const openManageColumnsPopOver = Boolean(anchorElManageColumnsPopOver);
    const manageColumnsPopOverId = openManageColumnsPopOver ? 'transporter-manage-columns-popover' : undefined;   

    useEffect(() => {
      if (isRoutePlanningInProgress) {
        history.push("/RoutePlanning", shipmentRowData);
        // Optional: Perform any cleanup or reset the flag after route planning is complete
        setIsRoutePlanningInProgress(false);
      }
    }, [isRoutePlanningInProgress]);
    
    const onRoutePlanning = async() => {
      if(shipmentRowData.length > 1 && ((shipmentRowData.every(obj => obj.status === "PendingAcceptance")) || (shipmentRowData.every(obj => obj.status === "Accepted")))) {
        setIsRoutePlanningInProgress(true); // Set a flag to indicate that route planning is in progress
        // history.push("/RoutePlanning", shipmentRowData);
        // setShowRoutePlanning(true);
      }
    }
    
    const callMMIAPI =  async(e:any) => {
      if(locationArray.length - 1 === shipmentRowData.length) {
        //toast.success("Some technical issue. you can try after some time");
        setTimeout(() => delayTheCallMMI(), -250)
      } else {
        setTimeout(() => delayTheCallMMI(), 2000)
      }
        
      //const url = `https://apis.mapmyindia.com/advancedmaps/v1/aa169342806b63256bf0d6d667b90f15/trip_optimization_eta/driving/${locationStr}` 
      //setTimeout(() => delayTheCallMMI(), -250)
      //console.log(resultData);
    }

    useEffect(()=> {
      if(shipmentRowData.length === locationArray.length - 1) {
        setReady(false);
      } else {
        setReady(true);
      }
    },[planArray, shipmentRowData])

    const delayTheCallMMI = async() => {
      var str = '';
      //console.log(process.env.REACT_APP_ENV);
      // Todo - Need to be fix with in backend side. Also need some solution encodeURI encodeURIComponent did not work
      if(locationArray.length <= 2) {
        toast.success("Please select multiple request.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return '';
      }

      if(process.env.REACT_APP_ENV === 'preproduction') {
        //setBtnFlag(true)
          for(var i = 1; i < locationArray.length; i++) {
            const element = locationArray[i]
            str +=   element[1] + "%2C" + element[0] + (i+1 === locationArray.length ? "" : "%3B");
          }
      } else {
        //setBtnFlag(true)
        for(var i = 1; i < locationArray.length; i++) {
          const element = locationArray[i]
          str +=   element[1] + "," + element[0] + (i+1 === locationArray.length ? "" : ";");
        }
      }
      const resultData = await mapApi.getDrivingTripEtaDetails(str);
      
      
      let newLocArray: any = [locationArray[0]];
      let requestArray: any = [];
      if(resultData.status === 'Success') {
        //console.log(JSON.parse(resultData.response),resultData,"location");
        let result = JSON.parse(resultData.response);
        let distance = (result.trips[0].distance / 1000).toFixed(2);
        let duration = (result.trips[0].duration / 3600).toFixed(2);
        // console.log(distance, duration);
        setDistance(distance);
        setDuration(duration);
        for (let index = 0; index < result.waypoints.length; index++) {
          const element = result.waypoints[index];
          let reqN = requestNos[index];
          element.requestNo = reqN;
        }
        let sortArray = result.waypoints.sort((a : any, b: any ) => a.waypoint_index - b.waypoint_index);
        for (let index = 0; index < sortArray.length; index++) {
          const element = sortArray[index];
          newLocArray.push([String(element.location[1]), String(element.location[0])]);
          requestArray.push(element.requestNo);

          if(index+1 === result.waypoints.length) {
            setPlanArray(newLocArray);
            setRequestNos(requestArray);
          }
        }
      } else {
        setIsOpen(false)
        toast.error("Some technical issue. you can try after some time", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      
      if(resultData.status === 'Success') {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
    const callUpload =  async(e:any) => {
      setIsUpload(true)
      
    }
    
    
    const handleMapPopup = (val: boolean) => {
      setIsOpen(false);
      setAssetOpen(val);
    }

    const handleRoutePlanningPopup = (val: boolean) => {
      setShowRoutePlanning(false);
    }

    const handleAssetPopup = (val: boolean) => {
      setActive(val);
      setShipmentRowData([]);
    }
    const handleUploadPopup = () => {
      setIsUpload(false);
    }
    
  useEffect(()=>{
    
    if(shipmentRowData.length) { 
      updateLocationofn(shipmentRowData);
      setBtnFlag(false);
    } else {
      setBtnFlag(true);
    }
    
  },[shipmentRowData]);

  const updateLocationofn = async(shipmentRowData: any) => {
    let makeLocationArray: any = [];
    let shipRowSelectedData: any = [];
    let requestNoData: any = [];
    for (let index = 0; index < shipmentRowData.length; index++) {
      const d = shipmentRowData[index];
      // console.log(d, 'doctor');
      let obj: any = {};
      requestNoData.push(d.requestNumber)
      let locationELocFrom = await mapApi.getLatitudeAndLongitude(`${String(d.fromLocation?.doorNoAndBuildingName)}, ${d.fromLocation?.streetAddress}, ${d.fromLocation?.city}, ${d.fromLocation?.pincode}`);
      let locationELocTo = await mapApi.getLatitudeAndLongitude(`${d.toLocation?.doorNoAndBuildingName}, ${d.toLocation?.streetAddress}, ${d.toLocation?.city}, ${d.toLocation?.delpincode}`);
      
      if(makeLocationArray.length === 0) {
        makeLocationArray.push([String(locationELocFrom.latitude), String(locationELocFrom.longitude)],[String(locationELocTo.latitude), String(locationELocTo.longitude)]);
      } else {
        makeLocationArray.push([String(locationELocTo.latitude), String(locationELocTo.longitude)]);
      }
      //debugger;
      obj.id = d.id;
      obj.latitude = d.latitude;
      obj.longitude = d.longitude;
      shipRowSelectedData.push(obj);
    }
     //console.log(requestNoData, 'doctor');
     setLocationArray(makeLocationArray);
     setRequestNos(requestNoData);
      //console.log(shipRowSelectedData);
     setTripData(shipRowSelectedData);
  }
 
  return (
    <Box sx={{ display: 'flex',marginTop:"-13px",marginLeft:"-115px" }}>
     <ResponsiveDrawer setSearchReqId={setSearchReqId} disableSearch={true} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
    <Box component={"main"} sx={{flexGrow:1,p:3}}>
    <div className={classes.root}>       
      <div>
           <div>
            <p className={classes.title}>Trip Management</p>
           </div>  
           <div style={{ padding: "3px", border: "1px solid #CED4D9", backgroundColor: "#fff", width: "37px", marginRight: "15px", marginTop: "0.5rem", borderRadius: "4px", height: "37px", paddingTop: "0" }}
                className={classes.cnDiv}
              //  onClick={pdfGen}
                data-testid="assetDownload"
              >
                <img src={Download} style={{ width: "24px", marginLeft: "2.3px",marginTop:"7px",height:"20px" }} />
                {/* <span>file_download</span> */}
              </div>              
            <div className={classes.cnDiv} > 
            {
              userDetails.roles.includes(ROLE.closedMarketplace) ? <Link to='/createshipment' style={{color:"#fff",textDecorationLine:'none'}}>  
              <Button variant="outlined" style={{backgroundColor:"#007AA6",color:"#fff",border:0,height:"37px"}} className={classes.button}> 
              <AddIcon style={{marginLeft:"-10px",marginRight:"3px",width:"20px"}}/> Create Shipment</Button></Link> : 
              <Link to='/densocreateshipment' style={{color:"#fff",textDecorationLine:'none'}}>  
              <Button variant="outlined" style={{backgroundColor:"#007AA6",color:"#fff",border:0,height:"37px"}} className={classes.button}> 
              <AddIcon style={{marginLeft:"-10px",marginRight:"3px",width:"20px"}}/> Create Shipment</Button></Link>
            }
            
           </div>


          
           
           <div className={classes.cnDiv} > 
            {
              userDetails.roles.includes(ROLE.closedMarketplace) ? 
              <Button onClick={(e) => callUpload(e)} variant="outlined" style={{backgroundColor:"#fff",borderRadius:"4px",color:"#000",border:"1px solid #CED4D9",height:"37px"}} className={classes.button}>
        
          
        <img src={upload} style={{paddingRight:"8px",marginTop:"-2px"}}/> Bulk Upload  </Button> : 
              <Button onClick={(e) => callUpload(e)} variant="outlined" style={{backgroundColor:"#fff",borderRadius:"4px",color:"#000",border:"1px solid #CED4D9",height:"37px"}} className={classes.button}>
                <img src={upload} style={{paddingRight:"8px",marginTop:"-2px"}}/>Bulk Upload</Button>
            }
            
           </div>
           <div className={classes.cnDiv} > 
            {
              userDetails.roles.includes(ROLE.closedMarketplace) ?  <Link to='/viewuploadstatus' style={{color:"#fff",textDecorationLine:'none'}}> 
              <Button variant="outlined" style={{backgroundColor:"#007AA6",color:"#fff",border:0,height:"37px"}} className={classes.button}> view upload status</Button></Link> : 
              <Link to='/viewuploadstatus' style={{color:"#fff",textDecorationLine:'none'}}><Button variant="outlined" style={{height:"37px",backgroundColor:"#007AA6",color:"#fff",border:0}} className={classes.button}> view upload status</Button></Link>
            }
           </div> 
           {/* commented route optimization for now */}
           {/* <div className={classes.cnDiv} > */}
            {/* {console.log(shipmentRowData.length, locationArray.length, 'compare')} */}
            
              {/* <Button variant="outlined" style={{textTransform:"capitalize"}} onClick={(e) => setTimeout(() =>callMMIAPI(e),-1000)} 
              className={classes.button} disabled={btnFlag ? btnFlag : (locationArray.length - 1 === shipmentRowData.length) ? false: true}><img src={route} style={{marginRight:"5px"}}/> Route Optimisation</Button>
              
           </div>  */}

           <div className={classes.cnDiv} >
            {/* {console.log(shipmentRowData.length, locationArray.length, 'compare')} */}
            {(userDetails.roleName === "DSIACCTest" || userDetails.roleName === "DSIA CC" || userDetails.roleName === "DENSO") && (
              <Button variant="outlined" style={{textTransform:"capitalize"}} onClick={(e) => setTimeout(() =>onRoutePlanning(),-1000)} 
              className={classes.button} ><img src={route} style={{marginRight:"5px"}}/> Route Planning</Button>
              )}
           </div> 

           <div className={classes.cnDiv} >  
            {/* {
              userDetails.roles.includes(ROLE.closedMarketplace) ?  <Link to='/viewuploadstatus' style={{color:"#fff",textDecorationLine:'none'}}> 
              <Button variant="outlined" style={{backgroundColor:"#007AA6",color:"#fff",border:0,height:"37px"}} className={classes.button}> view Transfers</Button></Link> : 
              <Link to='/viewuploadstatus' style={{color:"#fff",textDecorationLine:'none'}}><Button variant="outlined" style={{height:"37px",backgroundColor:"#007AA6",color:"#fff",border:0}} className={classes.button}> view upload status</Button></Link>
            } */}
            <Link to='/ViewPendingTransfers' style={{color:"#fff",textDecorationLine:'none'}}> 
              <Button variant="outlined" style={{backgroundColor:"#007AA6",color:"#fff",border:0,height:"37px"}} className={classes.button}> view Transfers</Button></Link>
           </div>                
      </div>
      <br />
      <div className={classes.tabsWrapper}>
          <Paper className={classes.root}>
          <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="inherit"
              data-testid="requestTabs"
              className={classes.tabs}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#0078A5"
                }
              }}
            >
              <Tab
                className={classes.tab}
                {...a11yProps(0)}
                label="Active"
                style={{color:"#0078A5"}}
              />
              <Tab
                className={classes.tab}
                {...a11yProps(1)}
                style={{color:"#0078A5"}}
                label="Completed"
              />
            </Tabs>
            <TabPanel className={classes.tabPanel} value={value} index={0}>
            <Active searchReqId={searchReqId} filterOptions={filterOptions} setFetchTableFlag={isActive}
            manageColumnsOptions={manageColumnsOptions} handleChangeKey={handleChangeKey} 
            validateRouteButton={(e:any, row:any, rowData:any) => validatePlanRouteButton(e, row, rowData)} /> 
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={1}>
            <Completed searchReqId={searchReqId} filterOptions={filterOptions} manageColumnsOptions={manageColumnsOptions} handleChangeKey={handleChangeKey} /> 
            </TabPanel>
        </Paper>
      </div>
      {/* {console.log('compare', locationArray.length,shipmentRowData.length )} */}
      {locationArray.length - 1 === shipmentRowData.length ? 
        <div>
        <PlanRoute isLoad={isReady} requestArray={requestNos} distance={distance} duration={duration} open={isOpen} data={planArray.length ? planArray : locationArray} closePopup={(v: boolean) => handleMapPopup(v)} />
        </div> :
        shipmentRowData.length === 0 ? 
          "" : 
          <div style={{width: "100px", height: "100px", position: "absolute", top:0, bottom: 0, left: 0, right: 0, margin: "auto"}}>
            <CircularProgress />  
          </div>
      }
      <div>
       <BulkUpload open={isUpload} data={locationArray} closePopup={() => handleUploadPopup()} />
      </div>
      <div><AssetAllocationForm1 setFetchTableFlag={(v: boolean) => handleAssetPopup(v)} open={isAssetOpen} shippingId={'63e09b3256e98358a8bbdc7c'} datas={tripData} status={'true'} expectedToPckUpTime ='06-02-2023 18:40:12' handleMessage="hello"/></div>
    </div>
    </Box>
    </Box>
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const matchDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, matchDispatchToProps)(ShipperLanding);