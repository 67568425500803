import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableSortLabel } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Row from '../../Utils/Row';
import '../../common/Alert/style.css';
import noData from '../../../../assets/images/No_data.svg'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MarketPlaceAPI from '../../../../services/marketPlaceService';
import Helper, { getCompleteToken, setCompleteToken, ShippingRequestStatus } from '../../../../services/_helpers';
import { TableManageColumnsFormData } from '../../Dashboard/Common/Interfaces/custom';
import coldchainLog from '../../../../services/consoleHelper';
import TablePaginationCustom from '../../Utils/TablePagination';
import ShipperHelper from '../../../../services/_helpers';

type Order = 'ASC' | 'DESC';


export default function DensoCompleted(props:any) {
  const { searchReqId, manageColumnsOptions, filterOptions } = props;
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowData, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [orderDirection,setOrderDirection]=React.useState<Order>('DESC');
  const [valueToOrderBy,setValueToOrderBy]=React.useState('timestamp');
  const [fetchTableFlag, setFetchTableFlag] = React.useState(JSON.parse(sessionStorage.fetchTableFlag));

  // const [fetchTableFlag2, setfetchTableFlag2] = React.useState(true);
  const[loadFlag,setLoadFlag]= React.useState(true);
  const [filterQuery, setFilterQuery] = useState(null);
  const helper = new Helper();

  // const Custom=()=>{
  //   return(
  //   <div className="d-flex flex-row bd-highlight mb-1 " >
  //       <span className="p-2 bd-highlight align-self-center " style={{backgroundColor:'#D10814',padding:0,height:'50px',width:'54px'}}><InfoIcon style={{color:'#fff'}}/></span>
  //       <div className="p-1 bd-highlight align-self-center"> <span> No Request has been processed</span></div>
       
  //       </div>
  //   )}
  
  
    useEffect(() => {
      if(fetchTableFlag || getCompleteToken() === "true") {
        setCompleteToken("false")
        // setFetchTableFlag(false);
        setFetchTableFlag(sessionStorage.setItem('fetchTableFlag', "false"))
        if(filterOptions) {
          let tempFilter: string[] = [];
          filterOptions.status.forEach((opt: any) => {
            if(ShippingRequestStatus[opt.value] === ShippingRequestStatus.Completed || ShippingRequestStatus[opt.value] === ShippingRequestStatus.Cancelled) {
              tempFilter.push(opt.value);
            }
          });
          if(tempFilter.length > 0) {
            setFilterQuery({status: tempFilter});
            fetchTableData(0, rowsPerPage, orderDirection, {status: tempFilter});
          } else {
            setRows([])
            setLoadFlag(false);
          }
        }
        else{
          fetchTableData();
        }
       }
    }, [fetchTableFlag, getCompleteToken()]);
    

  useEffect(() => {
    if(!searchReqId) {
      if(filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if(ShippingRequestStatus[opt.value] === ShippingRequestStatus.Completed || ShippingRequestStatus[opt.value] === ShippingRequestStatus.Cancelled) {
            tempFilter.push(opt.value);
          }
        });
        if(tempFilter.length > 0) {
          setFilterQuery({status: tempFilter});
          fetchTableData(0, rowsPerPage, orderDirection, {status: tempFilter});
        } else {
          // setFilterQuery(null);
          // fetchTableData(0, rowsPerPage, orderDirection, null, true);
          setRows([])
          /* istanbul ignore next */

          setLoadFlag(false);
          props.handleChangeKey("activeRequest")
        }
      } else {
        setFilterQuery(null);
        fetchTableData(0, rowsPerPage, orderDirection, null, true);
      }
    }
    // setFetchTableFlag(true);
  },[filterOptions, searchReqId]);
// useEffect(() => {
//   if(fetchTableFlag2) {
//     setfetchTableFlag2(false);
//     fetchTableData();
//   }
// },[fetchTableFlag2]);
  useEffect(() => {
    if(searchReqId) {
      if(filterOptions) {
        let tempFilter: string[] = [];
        filterOptions.status.forEach((opt: any) => {
          if(ShippingRequestStatus[opt.value] === ShippingRequestStatus.Completed || ShippingRequestStatus[opt.value] === ShippingRequestStatus.Cancelled) {
            tempFilter.push(opt.value);
          }
        });
        if(tempFilter.length > 0) {
          setFilterQuery({status: tempFilter});
          fetchTableDataByReqId(0, rowsPerPage, orderDirection, {status: tempFilter});
        } else {
          // setFilterQuery(null);
          // fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
          setRows([])
          /* istanbul ignore next */

          setLoadFlag(false);
          props.handleChangeKey("activeRequest")
        }
      } else {
        setFilterQuery(null);
        fetchTableDataByReqId(0, rowsPerPage, orderDirection, null, true);
        if(rowData.length === 0){
          /* istanbul ignore next */

          props.handleChangeKey("activeRequest")
        }
      }
    }
  }, [searchReqId, filterOptions, rowData.length]);

  const fetchTableDataByReqId = (page: number = 0, size: number = 10, orderBy: Order = 'DESC', query?: any, shouldForceDefaultQuery?: boolean) => {
    const marketApi = new MarketPlaceAPI();
    const defaultQuery = {
      status: [
        helper.getStatusKeyByValue(ShippingRequestStatus.Completed),
        helper.getStatusKeyByValue(ShippingRequestStatus.Cancelled)
      ]
    }
    setPage(page);
    setRowsPerPage(size);
    marketApi.searchByRequestNumber(searchReqId, page, size, orderBy, query ? query : filterQuery && !shouldForceDefaultQuery ? filterQuery : defaultQuery)
      .then((resp: any) => {
        setRows(resp.data);
        setTotalRows(resp.total);
        setLoadFlag(false);
      })
      .catch((err: any) => {
        setRows([]);
        setTotalRows(0);
        setLoadFlag(false);
        const UserToken = new ShipperHelper()
        UserToken.UserTokenExpiration()
      });
  }

const fetchTableData = (page: number = 0, size: number = 10, orderBy: Order = 'DESC', query?: any, shouldForceDefaultQuery?: boolean) => {
  const marketApi = new MarketPlaceAPI();
  const defaultQuery = {
    status: [
      helper.getStatusKeyByValue(ShippingRequestStatus.Completed),
      helper.getStatusKeyByValue(ShippingRequestStatus.Cancelled)
    ]
  }
  setPage(page);
  setRowsPerPage(size);
  marketApi.getRequestsByQuery(page, size, orderBy, query ? query : filterQuery && !shouldForceDefaultQuery ? filterQuery : defaultQuery)
    .then((resp: any) => {
      setRows(resp.data);
      setTotalRows(resp.total);
      setLoadFlag(false);
    })
    .catch((err: any) => {
      setRows([]);
      setTotalRows(0);
      console.error('Failded to load completed shipping requests.', err);
      setLoadFlag(false);
      const UserToken = new ShipperHelper()
      UserToken.UserTokenExpiration()
    });
}


 const handleChangePage = (event: unknown, newPage: number) => {
  fetchTableData(newPage, rowsPerPage, orderDirection);
};

const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  fetchTableData(0, +event.target.value);
};
const handleRequestSort=(event:any) =>{
     let isAscending:boolean;
     if(valueToOrderBy === "timestamp" && orderDirection === 'ASC' ){
      isAscending =true;
     }else{
      isAscending =false;
     }
  setValueToOrderBy('timestamp');
  setOrderDirection(isAscending ? 'DESC' : 'ASC');
  fetchTableData(0, rowsPerPage, isAscending ? 'DESC' : 'ASC');
}

coldchainLog("kfjklf",filterOptions)

  return (
    <Paper >
    <TableContainer component={Paper} style={{boxShadow: "none"}} >
      <Table aria-label="collapsible table"  style={{width:"100%",marginLeft: "15px"}}>
        <TableHead >
          <TableRow >
            <TableCell />
            {
              manageColumnsOptions.sort((a: TableManageColumnsFormData, b: TableManageColumnsFormData) => {
                return a.order - b.order;
              }).map((reorder: TableManageColumnsFormData) => {
                if(reorder.defaultOrder === 8) {
                  return (
                    <TableCell style={{fontSize:'12px'}} align="left" key ='timestamp'>
                      <TableSortLabel  active={valueToOrderBy ==='timestamp' }
                        data-testid="createdOnSort"
                        direction={valueToOrderBy ==='timestamp'?(orderDirection.toLocaleLowerCase() as 'asc' | 'desc'):"asc"} 
                        onClick={handleRequestSort} IconComponent={ArrowDropDownIcon} 
                      >{reorder.title}</TableSortLabel>
                    </TableCell>
                  )
                } else {
                  return <TableCell style={{fontSize:'14px'}} align={reorder.defaultOrder === 7 ? "right" : "left"}>{reorder.title}</TableCell>
                }
              })
            }
            <TableCell style={{fontSize:'14px',textTransform:"capitalize"}} align="center">Action</TableCell>
            
          </TableRow>
        </TableHead>
         
        <TableBody>
        {loadFlag?"":
          <>
        
        { rowData.length>0 ? (
            rowData.map((row: any)=>{
              return <Row key={row.id} row={row} showCheckBox="false" manageColumnsOptions={manageColumnsOptions} setFetchTableFlag={setFetchTableFlag} />;
            })
          ):
          (
          <>
         <TableRow >
          <TableCell style={{ paddingBottom: 50, paddingTop: 50,margin:'5rem' }} colSpan={9}>
          <div style={{marginLeft:'auto',marginRight:'auto',textAlign:'center'}}>
          <span style={{fontSize:'20px',fontWeight:500}}>There are no completed trips requests.</span>
          <p style={{fontSize:'14px',color:'#5A5A5A',}}>You will see the requests here once the status is changed to “Completed".</p>
          <img src={noData}/>
          </div>
            </TableCell></TableRow>
         
         

          
          </>
          )
          }
          </>}
         
        </TableBody>
      </Table>
    </TableContainer>
    {/* <TablePagination
    style={{fontSize:'12px'}}
    data-testid="completedPagination"
        rowsPerPageOptions={[5,10, 25, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{variant:'outlined'}}
        labelRowsPerPage={'Rows per page'}
        labelDisplayedRows={({ from, to, count }) => ""}
        ActionsComponent={TablePaginationCustom}
      /> */}
   </Paper>
    );
}

