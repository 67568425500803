import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import BubbleMap from "../../Utils/map";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import { marketBaseURL, telemetry } from "../../../../services/_url";
import { assetBaseURL } from "../../../../services/_url";
import { setAssetToken } from "../../../../services/tokens";
import TelemeteryAPI from "../../../../services/telemeteryService";
import ShipperHelper from "../../../../services/_helpers";
import coldchainLog from "../../../../services/consoleHelper";
import EditIcon from '@mui/icons-material/Edit';
import StopPages from "../../../../assets/images/stopPages.svg";
import event from "../../../../assets/images/event.svg";
import Delete from "../../../../assets/images/Delete.svg";
import Elevation from "../../../../assets/images/elevation.svg";
import start from "../../../../assets/images/start.svg";
import ReeferTruck from "../../../../assets/images/reeferTruck.svg";
import ReeferRun from "../../../../assets/images/reeferRun.svg";
import DryRun from "../../../../assets/images/dryRun.svg";
import Start from "../../../../assets/images/source.svg";
import charge from "../../../../assets/images/charge.svg";
import fuel from "../../../../assets/images/Fuel.svg";
import { Console } from "console";
import End from "../../../../assets/images/destination.svg";
import moment from "moment";
import UserService from "../../../../services/userService";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import InfoIcon from "@material-ui/icons/Info";
import Auto from '../../../assets/images/Auto.svg'

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { dateCalculator } from "../../Utils/dateUtil";
const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

export default function Details(props: any) {
  const [hardwareKits, setHardwareKits] = useState([]);
  const [legents, setLegents] = useState<any>({live: true});
  const [lastLocation, setLastLocation] = useState("");
  const [lastLocation1, setLastLocation1] = useState("");
  const [lastRecordedAt, setRecorded] = useState("");
  //const [last, setLast] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [utilizedHours, setUtilizedHours] = useState("");
  const [idleStatus, setIdleStatus] = useState("");
  const telemeteryService = new TelemeteryAPI();
  let dateHelper = new ShipperHelper();
  // const [sensorId, setSensorId] = useState("");
  const [latArr, setLatArr] = useState('');
  const [longArr, setLongArr] = useState('');
  const [removeFlg, setRemoveFlg] = useState(false)
  const userApi = new UserService();
  const userDetails = userApi.getUserDetails();
  const [tenantName, setTenantName] = useState("");
  const [tenantAssignmentId, setTenantAssignmentId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const data: any = [props.data, tenantId, tenantAssignmentId];
  const [sensorTagId, setSensorTagId] = useState("");
  props.recorded(lastRecordedAt)
  useEffect(() => {
    if (props.data.number && (props?.data?.assetType?.name === 'Active Cooler Box' || props?.data?.assetType?.name === 'Reefer Truck' || props.data.assetType.name === '3W_EV')) {
      validDataForAsset(props.data.id)
    }
  }, [props.filter,props.fromAndTo]);


  useEffect(() => {
    utilizationHours(props.data.id);
    getIdleStatus(props.data.id);
  }, []);
  useEffect(() => {
    // getting current tenant deatils
    if(props.data.id){
          axios
            .get(`${assetBaseURL}/assetAssignments/asset/${props.data.id}`)
            .then((res: any) => {
               setTenantId(res.data[0].tenantId)
               setTenantAssignmentId(res.data[0].id)
               
            })
            .catch((err: any) => {
              setTenantId("")
              setTenantAssignmentId("")
            });
          }
    
      }, []);
      
  const utilizationHours = async (id: any) => {
    if(id){
      let result = await axios.get(`${telemetry}/telemetry/travelMetrics?assetId=${id}`)
      let hours = Number(result.data.numOfHoursRun).toFixed(2);
      if (hours !== "0.00") {
        setUtilizedHours(hours)
      }
    }

  }
  const getIdleStatus = async (id: any) => {
    if(id){
      let result = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${id}`)
      let status = result.data.lastSyncTs ? (result.data.onTrip ? "On Trip" : "Idle") : "";
      setIdleStatus(status)
    }
    
  }

  //  console.log( idleStatus ,"hours");
  // useEffect(() => {
  //   if(props.assetID ){
  //     axios
  //       .get(`${assetBaseURL}/sensors/assetId?assetId=${props.assetID}`)
  //       .then((res: any) => {
  //         // console.log(res.data[0].tagId,'called')
  //         setSensorId(res.data[0].tagId)
  //       })
  //     }

  // }, [props.assetID,props.data.number]);
  // console.log(sensorId,"sensor");
  const success = () => {
    toast.success(
      <div className="d-flex flex-row bd-highlight mb-1 ">
        <span
          className="p-2 bd-highlight align-self-center "
          style={{
            backgroundColor: "#159A40",
            padding: 0,
            height: "50px",
            width: "54px",
          }}
        >
          <InfoIcon style={{ color: "#fff" }} />
        </span>
        <div className="p-1 bd-highlight align-self-center">
          {" "}
          <span>Asset deleted successfully</span>
        </div>
      </div>,
      {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  const handleCancel = () => {
    setRemoveFlg(false)
  }
  const handleremoveasset = () => {
    setRemoveFlg(true);
  }
  
  const removeasset = async () => {
    setRemoveFlg(false)
    let postData = {
      active: false,
      id: props.data.id,
      assetType: props.data.assetType,
      number: props.data.number,
      city: props.data.city,
      locality: props.data.locality,
      region:props.data.region ,
      zone: props.data.zone
    }
    let results = await axios.patch(`${assetBaseURL}/assets/${props.data.id}`, postData)
      .then((res) => {
        if (res.status === 200) {
          success();
        }
      })
      .catch((err: any) => { });
    window.location.reload();
  }


  const validDataForAsset = async (id: any) => {
    props.loadingCircleData(true)
    let lastLocDate;
    let lastRecord;
    let subDay = props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30;
    const to = moment().format("YYYYMMDDHHMM");
    const from = moment().subtract(subDay, 'days').format("YYYYMMDDHHMM");
    try {
      let response;
      let sensordata: any = []
      if (props.data.number && props?.data?.assetType?.name !== 'Active Cooler Box') {
        let fromValue;
        let toValue;
        if (props.filter === "custom") {
          let date = props.fromAndTo.split('=')
          fromValue = date[1].split('&')[0]
          toValue = date[2]
        }else{
          toValue = moment().format('MM-DD-YYYY HH:MM');
          fromValue = moment().subtract(subDay, 'days').format('MM-DD-YYYY HH:MM');
        }
        console.log(props.fromAndTo,props.filter,toValue,fromValue, "edit");
        let number = props?.data?.assetType?.name === 'Reefer Truck' ? `KA52A${props.data.number}` : props.data.number;
        
        let fromDate = new Date(fromValue).valueOf()
        let toDate = new Date(toValue).valueOf();
       
        const condition = ['9689','9690','9790','9791','9792','9841','DEV1','DEV2','DEV3','DEV4','DEV5','EV1','EV2','EV3','EV4','EV5','EV6','EV7','EV8','EV9','EV10']
        if(condition.includes(props.data.number)){
          response = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/history?vehicle_number=${number}&from=${fromDate}&to=${toDate}`)
        }
          //   try{
      //   response = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck/history?vehicle_number=${number}&from=${fromDate}&to=${toDate}`, { timeout: 5000 })
      // }catch (error) {
      //   if (axios.isCancel(error)) {
      //     console.log('API call canceled due to timeout');
      //   } else {
      //     console.log('An error occurred during the API call:', error);
      //   }
      // }
    }
      else if (props?.data?.assetType?.name === 'Active Cooler Box') {
        let sensorId: any;
        if (props.assetID) {
          let sensors = await axios.get(`${assetBaseURL}/sensors/assetId?assetId=${props.assetID}`)
          // console.log(sensors.data[0].tagId,"sensor");
          sensorId = sensors.data[0].tagId;
          setSensorTagId(sensorId);
        }
        const date = dateCalculator(props.filter, props.fromAndTo);
        let data = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${date.fromDate}&to=${date.toDate}&assetId=${props.assetID}&fetchtype=cb&tId=${sensorId}`)
        data.data.data = data.data.data ? data.data.data : data.data;
        // data.data.data.sort((a: any, b: any) => b.ts - a.ts)
        data.data.data.sort((a: any, b: any) => new Date(b.ts).valueOf() - new Date(a.ts).valueOf())
        if(data.data.data[0].dts){
          setRecorded(moment(data.data.data[0].dts).format("MMMM Do YYYY h:mm a"));

          lastRecord = data.data.data[0];
          telemeteryService
          .getLocationMapMyIndia(
            lastRecord.lat || lastRecord.latitude,
            lastRecord.lng || lastRecord.longitude,
          )
          .then((loc: any) => {
            let locality = !loc.data.results[0]["locality"] && !loc.data.results[0]["city"] && !loc.data.results[0]["subDistrict"] && !loc.data.results[0]["district"] ? '--' :
              loc.data.results[0]["locality"] && loc.data.results[0]["city"] ? `${loc.data.results[0]["locality"]}, ${loc.data.results[0]["city"]}` :
                !loc.data.results[0]["locality"] && !loc.data.results[0]["city"] ? `${loc.data.results[0]["subDistrict"]}, ${loc.data.results[0]["district"]}` :
                  loc.data.results[0]["locality"] && !loc.data.results[0]["city"] ? loc.data.results[0]["locality"] :
                    !loc.data.results[0]["locality"] && loc.data.results[0]["city"] ? loc.data.results[0]["city"] : '--'
            let state = loc.data.results[0]["state"]
            locality = locality ===  '--' ? loc.data.results[0]["area"] : locality
            setLastLocation(
              locality
            );


            props.loadingCircleData(false)
          }).catch((err: any) => {
            props.loadingCircleData(false)
            console.error("ERRORLASTtIME", err);
            console.log("timeout");
            
            setAssetToken("false");
            const UserToken = new ShipperHelper();
            UserToken.UserTokenExpiration();
          });
        }
        response = await axios.get(`https://2wdata-puller.maas-diin.com/tel/api/v1/location?no_of_days=2`)
        //  console.log(response.data.data,sensorId,"cooler");

        response.data?.data?.map((ele: any) => {
          if (sensorId === ele.deviceId) {
            sensordata.push(ele)
          }
        });
        // console.log(sensordata,"element");
      }
      else {
        response = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${from}&to=${to}&assetId=${id}`)
      }
      props.loadingCircleData(false)
      if (response.data) {
        const result = response?.data?.data?.data || sensordata || response.data;
        // console.log(result, 'random')
        let length = result.length;
        //let fixerNum = Number((length / 10).toFixed(0)) - 1;
        lastRecord = result[length - 1];
        //console.log(lastRecord.dts, 'called', result[length - 1]);
        if (lastRecord.dts || lastRecord.time) {
          lastLocDate = lastRecord.dts || lastRecord.time;
          if(lastRecord.dts || lastRecord.time){
            setRecorded(moment(lastRecord.dts || lastRecord.time).format("MMMM Do YYYY h:mm a"));
          }
        } else {
          //console.log(moment().subtract({hours: 3}).format("MMMM Do YYYY h:mm a"),'called1')
          // setRecorded(moment().subtract({ hours: 3 }).format("MMMM Do YYYY h:mm a"));
        }
        //console.log(props.props.filter,moment(lastRecord.dts).format("MMMM Do YYYY"), 'calledtime',moment(moment(result[length - 1].dts).format("X")).format("MMMM Do YYYY"));
        telemeteryService
          .getLocationMapMyIndia(
            lastRecord.lat || lastRecord.latitude,
            lastRecord.lng || lastRecord.longitude,
          )
          .then((loc: any) => {
            let locality = !loc.data.results[0]["locality"] && !loc.data.results[0]["city"] && !loc.data.results[0]["subDistrict"] && !loc.data.results[0]["district"] ? '--' :
              loc.data.results[0]["locality"] && loc.data.results[0]["city"] ? `${loc.data.results[0]["locality"]}, ${loc.data.results[0]["city"]}` :
                !loc.data.results[0]["locality"] && !loc.data.results[0]["city"] ? `${loc.data.results[0]["subDistrict"]}, ${loc.data.results[0]["district"]}` :
                  loc.data.results[0]["locality"] && !loc.data.results[0]["city"] ? loc.data.results[0]["locality"] :
                    !loc.data.results[0]["locality"] && loc.data.results[0]["city"] ? loc.data.results[0]["city"] : '--'
            let state = loc.data.results[0]["state"]
            locality = locality ===  '--' ? loc.data.results[0]["area"] : locality
            setLastLocation(
              locality
            );


            props.loadingCircleData(false)
          }).catch((err: any) => {
            props.loadingCircleData(false)
            console.error("ERRORLASTtIME", err);
            console.log("timeout");
            
            setAssetToken("false");
            const UserToken = new ShipperHelper();
            UserToken.UserTokenExpiration();
          });

        let dataset: any = [];
        for (let index = 0; index < length; index++) {
          const element = result[index];
          //console.log(element, index, "Hello");
          if (element.latitude !== null && element.longitude !== null) {
            dataset.push(element);
          }
        }
        // lastRecord.latitude =  lastRecord.lat;
        // lastRecord.longitude = lastRecord.lng;
        // dataset.push(lastRecord)
        setOriginalData(dataset);
      }
      // console.log(originalData,"data");

    } catch (error) {
      props.loadingCircleData(false)
      //console.log(error, "hello")
      mockDataForEmpty();
    }
  }


  const mockDataForEmpty = () => {
    // axios.get(`https://2wdata-puller.maas-diin.com/api/v1/refer-truck?vehicle_number=${props.data.assetType.name === '2W_EV' || props.data.assetType.name === '3W_EV' ? props.data.number : `KA52A${props.data.number}`}`)
    // .then((res: any) => {
    //     console.log(res, "hello");
    //     setRecorded(res?.data?.data?.data?.commtime)
    //        telemeteryService
    //         .getLocationMapMyIndia(
    //           res?.data?.data?.data?.lat,
    //           res?.data?.data?.data?.lng,
    //         )
    //         .then((loc: any) => {
    //           let locality = !loc.data.results[0]["locality"] && !loc.data.results[0]["city"] ? '--' : 
    //           loc.data.results[0]["locality"] && loc.data.results[0]["city"] ? `${loc.data.results[0]["locality"]}, ${loc.data.results[0]["city"]}` : 
    //           loc.data.results[0]["locality"] && !loc.data.results[0]["city"] ? loc.data.results[0]["locality"] : 
    //           !loc.data.results[0]["locality"] && loc.data.results[0]["city"] ? loc.data.results[0]["city"] : '--'
    //           setLastLocation(
    //             locality
    //           );
    //         }).catch((err: any) => {
    //           props.loadingCircleData(false)
    //             console.error("ERRORLASTtIME", err);
    //             setAssetToken("false");
    //             const UserToken = new ShipperHelper();
    //             UserToken.UserTokenExpiration();
    //           });
    //     })
    //     .catch((err: any) => {
    //     props.loadingCircleData(false)
    //       console.error("ERRORLASTtIME", err);
    //       setAssetToken("false");
    //       const UserToken = new ShipperHelper();
    //       UserToken.UserTokenExpiration();
    //     });
  }


  const hadleClickLegent = (type: string) => {
    const orginalValue = { ...legents };
    orginalValue[type] = !orginalValue[type]
    if(type === 'ReeferRun' || type === 'DryRun') {
      orginalValue.live = true
      orginalValue.MMI = false
      orginalValue.Intellicar = false
    }
    setLegents(orginalValue)
  }


  return (
    <div  style={{height : "39.7rem"}}>
      <Grid container spacing={1}>
        {/* <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "-19px" }}>
          
          <Table size="small" style={{width:"90%"}}>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",
              
                  color:"#000"
                }}
              >  
               <span style={{   display:"table-header-group"}}>Locality</span> 
              </TableCell>
            </TableRow>
            <TableBody> 
              <TableRow>
                <TableCell component="th" scope="row" >
                  {props.data.locality ?  <span style={{   display:"table-header-group"}}>{props.data.locality}
                  </span> : 
                  <span style={{   display:"table-header-group"}}>---</span>}
                
                 
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid> */}

        {/* <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "-19px",marginBottom: "16px" }}>
          <Table size="small" style={{marginLeft:"-60px",width:"90%"}}>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",
              
                  color:"#000"
                }}
              >  
           <span style={{   display:"table-header-group",marginLeft:"40px"}}>City</span>
              </TableCell>
            </TableRow>
            <TableBody> 
              <TableRow>
                <TableCell component="th" scope="row" >
                   {props.data.city ? <span style={{   display:"table-header-group"}}>{props.data.city}</span> :
                <span style={{   display:"table-header-group"}}>---</span> }
                 
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "-19px", marginBottom: "16px" }}>
          <Table size="small" style={{ marginLeft: "10px", width: "90%" }}>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",

                  color: "#000"
                }}
              >
                <span style={{ display: "table-header-group", marginLeft: "40px" }}>Driver</span>
              </TableCell>
            </TableRow>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" >
                <span style={{ display: "table-header-group" }}>Senthil</span>
                  {/* {props.data.driver ? <span style={{ display: "table-header-group" }}>{props.data.driver.firstName}{props.data.driver.lastName}</span> :
                    <span style={{ display: "table-header-group" }}>---</span>} */}

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "-19px", marginBottom: "16px" }}>
          <Table size="small" style={{ marginLeft: "-40px", width: "110%" }}>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",

                  color: "#000"
                }}
              >
                <span style={{ display: "table-header-group" }}>Customer</span>
              </TableCell>
            </TableRow>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" >
                <span style={{ display: "table-header-group" }}>Demo Customer</span>
                  {/* {props.data.servedCustomer ? <span style={{ display: "table-header-group" }}>{props.data.servedCustomer.customerDesc}</span> :
                    <span style={{ display: "table-header-group" }}>---</span>} */}

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        {userDetails.roleName === "LithPwr" ?
          <>
            <Grid item xs={12} sm={6} md={2} lg={1} style={{ marginTop: "-19px" }}>
              <Table size="small" style={{ marginLeft: "-100px", width: "160%" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000"
                    }}
                  >
                    <span style={{ display: "table-header-group" }}>Expected Operational hours</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <span >16 hours</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} style={{ marginTop: "-19px" }}>
              <Table size="small" style={{ marginLeft: "-80px", width: "100%" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000",

                    }}
                  >
                    <span style={{ display: "table-header-group" }}>Actual Operational hours</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {utilizedHours ?
                        <span >{utilizedHours} Hours</span> :
                        <span >---</span>
                      }



                      {/* {state ? state : "---"} */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: "-19px" }}>
              <Table size="small" style={{ width: "45%", marginLeft: "-80px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000"
                    }}
                  >
                    <span style={{ display: "table-header-group" }}>LAST RECORDED AT</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {/* {lastRecordedAt
                    ? dateHelper.dateConversion(lastRecordedAt)
                    : "---"} */}
                    {/* {lastRecordedAt ? dateHelper.dateConversion(lastRecordedAt) : "---"} */}
                      {lastRecordedAt
                        ? <span >{lastRecordedAt}</span>
                        : <span >---</span>}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: "-43px" }}>
              <Table size="small" style={{ marginLeft: "600px", width: "50%", marginTop: "-70px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000"
                    }}
                  >
                    <span style={{ display: "table-header-group" }}>LAST KNOWN LOCATION</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {lastLocation
                        ? <span >{lastLocation}</span>
                        : <span >---</span>}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: "-43px" }}>
              <Table size="small" style={{ marginLeft: "430px", width: "30%", marginTop: "-70px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000"
                    }}
                  >
                    <span style={{ display: "table-header-group" }}>STATUS</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {idleStatus
                        ? <span >{idleStatus}</span>
                        : <span >---</span>}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "-42px" }}>
              <Table size="small" style={{ marginLeft: "180px", width: "90%", marginTop: "-70px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000"
                    }}
                  >
                    <span style={{ marginLeft: "-10px" }}> Action</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Link to={{ pathname: '/Editasset', state: data }}>
                        <EditIcon
                          style={{ marginLeft: "-10px", color: "#737373" }}
                        />
                      </Link>
                      &nbsp;  &nbsp;
                      {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> */}
                      <img src={Delete} />
                    </TableCell>
                  </TableRow>
                </TableBody>
                <Grid item xs={12} sm={4} md={4} lg={1} style={{ marginTop: "-3.7rem", marginLeft: "10rem" }}>
                </Grid>
              </Table>
            </Grid>
          </> :
          <>
            <Grid item xs={12} sm={6} md={2} lg={2} style={{ marginTop: "-19px" }}>
              <Table size="small" style={{ marginLeft: "-40px", width: "160%" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000",

                    }}
                  >
                    <span style={{ display: "table-header-group" }}>LAST KNOWN LOCATION</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">

                      {lastLocation ?
                        <span style={{ display: "table-header-group" }}>{lastLocation}</span> :
                        <span style={{ display: "table-header-group" }}>---</span>}

                      {/* {state ? state : "---"} */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} style={{ marginTop: "-19px" }}>
              <Table size="small" style={{ marginLeft: "40px", width: "160%" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000",

                    }}
                  >
                    <span style={{ display: "table-header-group" }}>LAST RECORDED AT</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                    {/* <span style={{ display: "table-header-group" }}>
                    {lastRecordedAt ? dateHelper.dateConversion(lastRecordedAt) : "---"}
                    </span> */}

                      {lastRecordedAt ?
                        <span style={{ display: "table-header-group" }}>{lastRecordedAt}</span> :
                        <span style={{ display: "table-header-group" }}>---</span>}

                      {/* {state ? state : "---"} */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} style={{ marginTop: "-19px" }}>
              <Table size="small" style={{ marginLeft: "160px", width: "90%" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",

                      color: "#000",

                    }}
                  >
                    <span style={{ display: "table-header-group" }}>ACTION</span>
                  </TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <span style={{ marginLeft: "-65px" }}>
                        <Link to={{ pathname: '/Editasset', state: data }}>
                          <EditIcon style={{ color: "#737373" }} />
                        </Link>
                        &nbsp;  &nbsp;
                        {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> */}
                        <div style={{
                          border: "0px solid rgb(206, 212, 217)", backgroundColor: "#fff",
                          width: "20px", marginLeft: "28px", marginTop: "-22px", height: "21px"
                        }} onClick={handleremoveasset}
                        >
                          <img src={Delete} /></div>
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginTop: "-23px",marginLeft:"-230px" }}>
          <Table size="small" style={{marginLeft:"760px",width:"90%",marginTop: "-70px"}}>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",
               
                  color:"#000"
                }}
              >
               <span style={{marginLeft:"-10px"}}> Action</span>  
              </TableCell>
            </TableRow>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                <Link to={{ pathname: '/Editasset',state: props.data}}>
                  <EditIcon
                    style={{marginLeft:"-10px",color:"#737373"}}
                  />
                  </Link>
                  &nbsp;  &nbsp; 
                  {/* <div  style={{fontSize:'14px'}}> {id.primary?<span id='statusGreen' style={{float:'right',}}>Primary</span>:""}</div> 
                  <img src={Delete}  />
                </TableCell>
              </TableRow>
            </TableBody>
            <Grid item xs={12} sm={4} md={4} lg={1} style={{marginTop:"-3.7rem",marginLeft:"10rem"}}>
          {/* <span style={{  marginLeft:"-3rem" }}>
            {props.data.underMaintenance ? (
              <span id="statusOcolor">Maintenance</span>
            ) :  (
              <span id="statusG1color">Active</span>
            ) }
          </span>
          <br /> 
          
        </Grid>
          </Table>
        </Grid> */}
          </>
        }



        {/* <div
            style={{ float: "right", marginLeft: "5rem", marginTop: "1rem" }}
          >
            {props.data.underMaintenance ? (
              <span id="statusOrange">Maintenance</span>
            ) : (
              <span></span>
            )}
          </div> */}

      </Grid>

      {props.data.number ?
        <Grid item xs={12} sm={12} lg={12}>
          <div className="hoverDiv" style={{ marginTop: "2.2rem", marginRight: "-1.6rem", height: "auto" }}>
            <span style={{ marginBottom: "1rem" }}>
              {/* <p className="hoverP" >Stoppages</p><img alt="img1" src={StopPages} className="hoverImg" /> */}
              <p className="hoverP" style={{ fontWeight: legents && legents?.Events ? 'bold' : 'normal',marginTop:"-5px" }} onClick={() => hadleClickLegent('Events')}>Events</p><img alt="img1" src={event} className="hoverImg" />
              {/* <p className="hoverP">Elevation</p><img alt="img1" src={Elevation} className="hoverImg" /> */}
              <p className="hoverP" style={{ fontWeight: legents && legents?.Start ? 'bold' : 'normal',marginTop:"-5px"  }} onClick={() => hadleClickLegent('Start')}>Start Point</p><img alt="img1" src={Start} className="hoverImg" />
              <p className="hoverP" style={{ fontWeight: legents && legents?.Start ? 'bold' : 'normal',marginTop:"-5px"  }} onClick={() => hadleClickLegent('End')}>End Point</p><img alt="img1" src={End} className="hoverImg" />
              <p className="hoverP" style={{ fontWeight: legents && legents?.Fuel ? 'bold' : 'normal',marginTop:"-5px"  }} onClick={() => hadleClickLegent('Fuel')}>Fuel Station</p><img alt="img1" src={fuel} className="hoverImg" />
              <p className="hoverP" style={{ fontWeight: legents && legents?.Charge ? 'bold' : 'normal',marginTop:"-5px"  }} onClick={() => hadleClickLegent('Charge')}>Charge Station</p><img alt="img1" src={charge} className="hoverImg" />
              <p className="hoverP" style={{ fontWeight: legents && legents?.ReeferRun ? 'bold' : 'normal' ,marginTop:"-7px"}} onClick={() => hadleClickLegent('ReeferRun')}>Reefer Run</p><img alt="img1" src={ReeferRun} className="hoverImg" />
              <p className="hoverP" style={{ fontWeight: legents && legents?.DryRun ? 'bold' : 'normal',marginTop:"-7px" }} onClick={() => hadleClickLegent('DryRun')}>Dry Run</p><img alt="img1" src={DryRun} className="hoverImg" />
              <p className="hoverP" style={{ fontWeight: legents && legents?.MMI ? 'bold' : 'normal',marginTop:"-7px" , color:"#360f75"}} onClick={() => hadleClickLegent("MMI")}>MMI</p>
              <p className="hoverP" style={{ fontWeight: legents && legents?.Intellicar ? 'bold' : 'normal',marginTop:"-7px" ,color:"#f56a07"}} onClick={() => hadleClickLegent("Intellicar")}>Intellicar</p>
              {/* <p className="hoverP" style={{ fontWeight: legents && legents?.live ? 'bold' : 'normal',marginTop:"-7px", color:"#360f75" }} onClick={() => hadleClickLegent("live")}>Live</p>             */}
            </span>
          </div>

          <BubbleMap
            latArr={latArr}
            longArr={longArr}
            legents={legents}
            filter={props.filter}
            assetID={props.assetID}
            selectedVehicle={props.data}
            data={props.data}
            gatewayId={hardwareKits && hardwareKits[0]?.gatewayId}
            fromAndTo={props.fromAndTo}
            loadingCircleData={props.loadingCircleData}
            keys={props.keys}
            tagId={sensorTagId}
            originalData={originalData}
            fromComponent={"AO"}  // Asset Overview
          />
        </Grid> : null}
      <Grid item xs={12} sm={12}></Grid>
      {removeFlg ? <>
        <Dialog onClose={handleCancel} aria-labelledby="customized-dialog-title" open={removeFlg}
          maxWidth="sm" >
          <DialogTitle id="customized-dialog-title" style={{ color: "#000" }} >
            Remove Asset
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#000" }}>
              Are you sure you want to remove asset {props.data.number} ?
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={removeasset} color="primary">
              Remove
            </Button>
            <Button onClick={handleCancel} color="primary" data-testid="cancel">
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </>
        : <> </>
      }
    </div>
  );
}
