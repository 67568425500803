import { useEffect } from 'react';
import './style.css';
import { setAssetToken } from '../../../services/tokens';
import TelemeteryAPI from '../../../services/telemeteryService';
import source from '../../../assets/images/source.svg';
import destination from '../../../assets/images/destination.svg'
import EV_Fill from '../../../assets/images/EV_Fill.svg';
import hublocation from '../../../assets/images/hublocation.svg';
import Auto from '../../../assets/images/Auto.svg'
import { defaultZoomLevel } from '../../../services/_helpers';
import Event from "../../../assets/images/events.svg";
import { telemetry } from "../../../services/_url";
import event from '../../../assets/images/events.svg';
import reeferfour from '../../../assets/images/reeferfour.svg';
import threewheelers from '../../../assets/images/threewheelers.svg';
import TwoWheelerIcon from '../../../assets/images/TwoWheeler.svg';
import { useState } from 'react';
import ShipperHelper, { AvgIcons } from "../../../services/_helpers";
import getDistance from 'geolib/es/getDistance';
import * as geolib from 'geolib';
import moment from "moment";
import axios from 'axios';
import { dateCalculator } from './dateUtil';

declare var mappls: any; // Declaring Mapmyindia
declare var L: any;

export default function BubbleMap(props: any) {

  var map: any = null;
  var startLat = ""
  var startLong = ""
  var endLat = ""
  var endLong = ""
  var poly: any[] = [];
  var latArr: any[] = [];
  var longArr: any[] = [];
  var shock;
  let dateHelper = new ShipperHelper();
  const [mapValues, setMapValues] = useState([]);
  const telemeteryService = new TelemeteryAPI();

  const [temp, setTemp] = useState([]);
  const [lidOpen, setLidOpen] = useState([]);
  const [shockevent, setShockEvent] = useState([]);

  const addMarker = (position: any, icon: any, title: any, draggable: any, flag: boolean = false) => {
    new mappls.Marker({
      map: map,
      position: { "lat": position[0], "lng": position[1] },
      fitbounds: true,
      popupHtml: title,
      icon_url: icon || Event,
      title: title[0] + "  " + title[1],
    });
    return;
  }

  const addMarkerEV = (position: any, icon: any, title: any, draggable: any, flag: boolean = false, isTitle = false) => {
    new mappls.Marker({
      map: map,
      position: { "lat": position[0], "lng": position[1] },
      fitbounds: true,
      popupHtml: title,
      icon_url: icon,
      // e !flag ? Auto : EV_Fill,
      // title: title[0] + "  " + title[1],
    });
    return;
  }

  //asset path using mmi api
  const MMIDataForAsset = async (id: any, value: string) => {
    // console.log(id, value, '122333')

    props.loadingCircleData(true)
    let subDay = props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30;
    const to = moment().format("YYYYMMDDHHMM");
    const from = moment().subtract(subDay, 'days').format("YYYYMMDDHHMM");

    try {
      let response;
      if (props.data.number && props?.data?.assetType?.name !== 'Active Cooler Box') {
        let fromValue;
        let toValue;
        if (props.filter === "custom") {
          let date = props.fromAndTo.split('=')
          fromValue = date[1].split('&')[0]
          toValue = date[2]
        }
        if (value === "MMI") {
          response = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${from}&to=${to}&assetId=${id}&sid=102`)
        } else if (value === "Intellicar") {
          response = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${from}&to=${to}&assetId=${id}&sid=101`)
        }
      }

      props.loadingCircleData(false)
      // console.log(response.data, '122333')
      if (response.data) {
        const result = response?.data?.data?.data || response.data;
        let length = result.length;
        let dataset: any = [];
        for (let index = 0; index < length; index++) {
          const element = result[index];
          //console.log(element, index, "Hello");
          if (element.lat !== 0 && element.lng !== 0) {
            element.latitude = element.lat;
            element.longitude = element.lng;
            dataset.push(element);
          }
        }
        return dataset
      }
    } catch (error) {
      //console.log(error, "hello")
    }
  }


  const plotMap = async () => {
    // console.log(props.legents, 'props.legents 1222')


    if (!mapValues || !mapValues.length) {
      endLat = "";
      endLong = "";
      startLat = ""
      startLong = ""
      return
    }
    endLat = mapValues[0].latitude
    endLong = mapValues[0].longitude


    startLat = mapValues[mapValues.length - 1].latitude
    startLong = mapValues[mapValues.length - 1].longitude

    if (!mapValues || !mapValues.length || !endLat || !endLong || !startLat || !startLong) {
      endLat = "";
      endLong = "";
      startLat = ""
      startLong = ""
      return
    }
    mapValues.map((x: any) => {
      let dis = getDistance(
        { latitude: startLat, longitude: startLong },
        { latitude: x.latitude, longitude: x.longitude }
      );
      if (geolib.convertDistance(dis, 'km') < 300) {
        poly.push({ lat: x.latitude, lng: x.longitude });
      }
    })
    document.getElementById('weathermap').innerHTML = ''
    document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
    // const result = await axios.get(`https://apis.mappls.com/advancedmaps/v1/12f41c29551173819542ec5ee8c8e153/snapToRoad?pts=${poly.join()}`);
    // console.log(result)
    map = new mappls.Map('map', {
      center: [22, 78],
      zoom: defaultZoomLevel,
    });
    map.setZoom(defaultZoomLevel);
    if (props.legents.live) {
      new mappls.Polyline({
        map: map,
        paths: props.legents.live ? poly : [],
        strokeColor: '#5a5a5a',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        fitbounds: true
      });
    }
    if (props.legents.MMI) {
      let data = await MMIDataForAsset(props.data.id, 'MMI')
      if (data && data.length) {
        let intellicarLines: any = []
        data.map((x: any) => {
          intellicarLines.push([x.latitude, x.longitude]);
        })
        new mappls.Polyline({
          map: map,
          // paths: intellicar,
          path: {
            "type": "FeatureCollection",
            "features": [{
              "type": "Feature",
              "properties": {
                "color": "#0078A5"
              },
              "geometry": {
                "type": "LineString",
                "coordinates": intellicarLines
              }
            }
            ]
          },
          strokeOpacity: 1.0,
          strokeWeight: 3,
          fitbounds: true
        });
      }
    }

    if (props.legents.Start) {
      new mappls.Marker({
        map: map,
        position: {
          "lat": startLat,
          "lng": startLong
        },
        fitbounds: true,
        icon_url: source
      });

    }

    if (props.legents.End) {
      new mappls.Marker({
        map: map,
        position: {
          "lat": endLat,
          "lng": endLong
        },
        fitbounds: true,
        icon_url: destination
      });
    }

    const temp: any = []
    const lid: any = [];
    for (let index = 0; index < mapValues.length; index++) {
      const element: any = mapValues && mapValues[index];
      let dis = getDistance(
        { latitude: startLat, longitude: startLong },
        { latitude: element.latitude, longitude: element.longitude }
      );
      let isDistance = geolib.convertDistance(dis, 'km') < 300;
      if (element && props.legents.ReeferRun && isDistance && +element?.temp && +element?.temp < 20) {
        temp.push([element.latitude, element.longitude])
      }
      if (element && props.legents.DryRun && isDistance && +element?.temp && +element?.temp > 20) {
        lid.push([element.latitude, element.longitude])
      }
      if (element && element.lo && props.legents.Events && isDistance) {
        addMarker([element.latitude, element.longitude], Event, ` Lid Open Events:${element?.lo} Shock Events:${element.se} Time:${moment(element.dts).format('DD-MM-YYYY h:mm a')} ${element?.temp ? `Temp:${element?.temp.toFixed(2)}C` : ''}`, '');
      }
      if (element && element.se && props.legents.Events && isDistance) {
        addMarker([element.latitude, element.longitude], Event, ` Lid Open Events:${element?.lo} Shock Events:${element.se} Time:${moment(element.dts).format('DD-MM-YYYY h:mm a')} ${element?.temp ? `Temp:${element?.temp.toFixed(2)}C` : ''}`, '');
      }
    }
    if (temp.length && props.legents.ReeferRun) {
      map.addListener('load', function () {
        new mappls.Polyline({
          map: map,
          path: {
            "type": "FeatureCollection",
            "features": [{
              "type": "Feature",
              "properties": {
                "color": "#de7f11"
              },
              "geometry": {
                "type": "LineString",
                "coordinates": temp
              }
            }
            ]
          },
          strokeOpacity: 1.0,
          strokeWeight: 3,
          fitbounds: true, // or false
        });
      })
    }
    if (lid.length && props.legents.DryRun) {
      map.addListener('load', function () {
        new mappls.Polyline({
          map: map,
          path: {
            "type": "FeatureCollection",
            "features": [{
              "type": "Feature",
              "properties": {
                "color": "#e65587"
              },
              "geometry": {
                "type": "LineString",
                "coordinates": lid
              }
            }
            ]
          },
          strokeOpacity: 1.0,
          strokeWeight: 3,
          fitbounds: true, // or false
        });
      })
    }


    if (props.legents.Intellicar) {
      let data = await MMIDataForAsset(props.data.id, 'Intellicar')
      // console.log(data, 'Intellicar 1222')
      if (data && data.length) {
        let intellicar: any = []
        data.map((element: any) => {
          intellicar.push([element.latitude, element.longitude])
        })
        new mappls.Polyline({
          map: map,
          paths: intellicar,
          strokeColor: '#3475e0',
          strokeOpacity: 1.0,
          strokeWeight: 5,
          fitbounds: true
        });
      }

    }

    /* istanbul ignore next */
    map.on("contextmenu", function (e: any) {
      // map.setZoom(defaultZoomLevel);
      map.panTo(poly[0], true, 1.0);
      //setTimeout(()=>{map.panTo(poly[0],true,1.0);},1000)
      setTimeout(() => { map.setZoom(defaultZoomLevel); }, 10000)

    });
    props.loadingCircleData(false)
    // console.log(new Date(),"Map End Time")
    setAssetToken("false");
    // setperiodValue(false)
  }

  // const getMapLayerData = async () => {
  //   props.loadingCircleData(true)
  //   const results = await Promise.allSettled([telemetryService.getTempDat(props.tagId, props.filter, props.fromAndTo), telemetryService.getLightDat(props.tagId, props.filter, props.fromAndTo), telemetryService.getShockDat(props.tagId, props.filter, props.fromAndTo)]);
  //   results.forEach((result: any, index) => {
  //     if (result.status === 'fulfilled' && index === 0) setTemp(result.value.data.tagDatas);
  //     if (result.status === 'fulfilled' && index === 1) setLidOpen(result.value.data.tagDatas);
  //     if (result.status === 'fulfilled' && index === 2) setShockEvent(result.value.data.tagDatas);
  //   })
  //   props.loadingCircleData(false)
  // }

  // const [periodValue, setperiodValue] = useState(false);

  // useEffect(() => {
  //   setperiodValue(true)
  //   setLegents({})
  // }, [props.filter, props.fromAndTo])

  useEffect(() => {
    if (mapValues) {

      plotMap()
      // setLegents(props.legents)
    }
  }, [mapValues])



  //   const showZoomData = () => {
  //     if(props.truckNoData && props.truckNoData.nearestSwapStation){
  //     const evResultData = props.truckNoData.nearestSwapStation;
  //     var markerData: any = [];
  //     // evResultData.map((element: any) => {
  //       if (evResultData && evResultData.latitude) {
  //         addMarkerEV([evResultData.lat, evResultData.longitude], EV_Fill, `<b>Name:</b> ${evResultData.name}, <br /> <b>Address:</b> ${evResultData.address},<br /> <b>Map Link:</b> <a href="${evResultData.links}" target="_blank">Click</a>`, "", true);
  //         markerData.push({
  //           "type": "Feature",
  //           "properties": {
  //             "htmlPopup": `Lat: ${+evResultData?.latitude}, Lng: ${+evResultData?.longitude}`
  //           },
  //           "geometry": {
  //             "type": "Point",
  //             "coordinates": [+evResultData.latitude, +evResultData.longitude]
  //           }
  //         })
  //       }
  //       if(props.truckNoData.data) {
  //         markerData.push({
  //           "type": "Feature",
  //           "properties": {
  //             "htmlPopup": `Lat: ${+props.truckNoData.data?.latitude}, Lng: ${+props.truckNoData.data?.longitude}`
  //           },
  //           "geometry": {
  //             "type": "Point",
  //             "coordinates": [+props.truckNoData.data.latitude, +props.truckNoData.data.longitude]
  //           }
  //         })
  //       }
  //     // })
  //     var geoData = {
  //       "type": "FeatureCollection",
  //       "features": markerData
  //     };
  //     var marker = mappls.Marker({
  //       map: map,
  //       position: geoData,
  //       fitbounds: true,
  //       clusters: false,
  //       fitboundOptions: {
  //         padding: 120,
  //         duration: 1000
  //       },
  //       popupOptions: {
  //         offset: {
  //           'bottom': [0, -20]
  //         }
  //       }
  //     });
  //   }
  // }

  useEffect(() => {
    if (Object.keys(props.legents).length) {
      plotMap()
    }
  }, [temp, lidOpen, shockevent, props.legents])


  const telemetryService: any = new TelemeteryAPI()
  var icon3: any;

  useEffect(() => {
    // console.log(props?.legents, 'props?.legents')
    //console.log('called')
    // if(getAssetToken() === "true"){
    if (props?.selectedVehicle?.assetType?.name === "2W_EV") {
      (async () => {
        try {
          props.loadingCircleData(true)
          let fromDate: any = props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : props.filter === 'monthly' ? 30 : 1;
          let number = props?.selectedVehicle?.number.split(" ").join("");
          let result: any = await axios.get(`https://2wdata-puller.maas-diin.com/api/v1/location?vehicle_number=${number}&no_of_days=${fromDate}`);

          if (!result || !result?.data?.data.length) {
            endLat = "";
            endLong = "";
            startLat = ""
            startLong = ""
            if (document.getElementById('weathermap')) {
              document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
            }
            map = new mappls.Map('map', { center: [28.61, 77.23], zoomControl: true, hybrid: true });
            props.loadingCircleData(false)
            setMapValues([])
          } else {
            result = !result?.data?.data ? [] : result?.data?.data;
            // console.log( "called1");
            let dataList = result.map((ele: any) => {
              ele.longitude = ele?.location?.lng
              ele.latitude = ele?.location?.lat
              return ele;
            })
            setMapValues(dataList)
            props.loadingCircleData(false)
          }
        } catch (error) {
          endLat = "";
          endLong = "";
          startLat = ""
          startLong = ""
          props.loadingCircleData(false)
          if (document.getElementById('weathermap')) {
            document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
          }
        }
      })()
    }

    if ((props.keys === "DETAILS" && props?.selectedVehicle?.assetType?.name === 'Reefer Truck') || props?.selectedVehicle?.assetType?.name === '3W_EV' || props?.data?.assetType?.name === 'Active Cooler Box') {
      (async () => {
        // console.log(props.filter, 'props.fromAndTo')
        props.loadingCircleData(true)
        try {
          let number = props?.selectedVehicle?.assetType?.name === 'Reefer Truck' ? `KA52A${props.selectedVehicle.number}` : props.selectedVehicle.number;
          let result: any = [];
          if (props.originalData.length) {
            setMapValues(props.originalData);
            props.loadingCircleData(false);

          }
          if (props?.data?.assetType?.name === 'Active Cooler Box' && props.tagId) {
            const date = dateCalculator(props.filter, props.fromAndTo)
            result = await axios.get(`${telemetry}/telemetry/gatewayanddevice?from=${date.fromDate}&to=${date.toDate}&assetId=${props.selectedVehicle.id}&fetchtype=cb&tId=${props.tagId}`)
            // result = await axios.get(` https://2wdata-puller.maas-diin.com/tel/api/v1/location?no_of_days=${props.filter === 'daily' ? 1 : props.filter === 'weekly' ? 7 : 30}`)
            let list: any = [];
            result = result.data.data ? result.data.data: result.data
            result.forEach((ele: any) => {
              if ((props?.data.deviceId && props?.data.deviceId.includes(ele.deviceId)) || ele.tid) {
                ele.latitude = ele.lat
                ele.longitude = ele.lng
                list.push(ele)
              }
            })
            if (!list.length) {
              endLat = "";
              endLong = "";
              startLat = ""
              startLong = ""

              if (document.getElementById('weathermap')) {
                document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
              }
              map = new mappls.Map('map', { center: [28.61, 77.23], zoomControl: true, hybrid: true });
              return;
            }
            setMapValues(list)
            props.loadingCircleData(false);
          }
          else if (!result || !result.data?.data?.data?.length) {
            endLat = "";
            endLong = "";
            startLat = ""
            startLong = ""

            if (document.getElementById('weathermap')) {
              document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
            }
            map = new mappls.Map('map', { center: [28.61, 77.23], zoomControl: true, hybrid: true });
            //props.loadingCircleData(false)
          } else {
            setMapValues(!result.data?.data?.data ? [] : result.data?.data?.data)
            //props.loadingCircleData(false)
          }
          //props.loadingCircleData(false)
          setAssetToken("false");
        }
        catch (error) {
          endLat = "";
          endLong = "";
          startLat = ""
          startLong = ""
          if (document.getElementById('weathermap')) {
            document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
          }
          setMapValues([])
          map = new mappls.Map('map', { center: [28.61, 77.23], zoomControl: true, hybrid: true });
          props.loadingCircleData(false)
        }
      })()
    }
    // }
    else if (props.overviewData && props.overviewData.length && mappls) {
      document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
      map = new mappls.Map('map', {
        center: [props.overviewData[0]?.data?.lat || 28.61, props.overviewData[0]?.data?.lng || 77.23],
        zoom: defaultZoomLevel,
      });
      map.addListener('load', async function () {
        let markerData: any = [];
        if (props.fleetFlag && props.latLngTruck.length && (props?.legents?.FourWheeler || props?.legents?.ICE)) {
         await props.latLngTruck.map(async (ele: any) => {
            // console.log(ele)
            if (ele.latitude && ele.longitude) {
              const dateFormat = moment(ele.commtime_timestamp).format('DD-MM-YYYY h:mm a');
              addMarkerEV([ele.latitude || 28.61, ele.longitude || 77.23], reeferfour, `<b>Vehicle Number:</b> ${ele.number},
               <br /> <b>Vehicle Battery:</b> ${ele.vehbattery}, <br />
               <b>Customer:</b>   Gourmet Gardens,<br />
              <b>Driver:</b>   Amit Joshi,
               <br /> <b>speed:</b>   ${ele.speed}, <br />  <b>Ign status:</b> ${ele?.ignstatus}, <br />  <b>Date and Time:</b> ${dateFormat}`, '');
              markerData.push({
                "type": "Feature",
                "properties": {
                  "htmlPopup": `Lat: ${+ele?.latitude}, Lng: ${+ele?.longitude}`
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [+ele?.latitude, +ele?.longitude]
                }
              })
            }
          })
        }
        if (props.latLngTwoWheeler && props.latLngTwoWheeler.length && (props?.legents?.TwoWheeler || props?.legents?.EV)) {
          await props.latLngTwoWheeler.map(async (ele: any) => {
            if (ele.latitude && ele.longitude) {
              const dateFormat = moment(ele.created_at).format('DD-MM-YYYY h:mm a');
              // addMarker([ele?.longitude || 28.61, ele?.longitude || 77.23], TwoWheelerIcon, `<b>Vehicle Number:</b> ${ele.vehicleNum}, <br /> ` , "");

              addMarkerEV([ele.latitude || 28.61, ele.longitude || 77.23], TwoWheelerIcon,
                `<b>Vehicle Number:</b> ${ele.vehicle_number}, <br /> <b>Driver Name:</b> 
                   ${ele.driver_name}, <br />  <b>Trip Number:</b> ${ele?.trip_number}, <br /> 
                     <b>Date and Time:</b> ${dateFormat}`, '');
              markerData.push({
                "type": "Feature",
                "properties": {
                  "htmlPopup": `Lat: ${+ele?.latitude}, Lng: ${+ele?.longitude}`
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [+ele?.latitude, +ele?.longitude]
                }
              })
            }
          })
        }

        /**
         * Fleet overview 3W rendering
         */
        if (props.fromComponent === "FO" && props.topBarValue === 1 && (props?.legents?.ThreeWheeler)) {
          await props.threeWheeler.map(async (ele: any) => {
            if (ele.latitude && ele.longitude) {
              const dateFormat = moment(ele.commtime_timestamp).format('DD-MM-YYYY h:mm a');
              addMarkerEV([ele.latitude || 28.61, ele.longitude || 77.23], Auto, `<b>Vehicle Number:</b> ${ele.number},
               <br /> <b>Vehicle Battery:</b> ${ele.vehbattery}, <br />
               <b>Customer:</b>   Gourmet Gardens,<br />
              <b>Driver:</b>   Amit Joshi,
               <br /> <b>speed:</b>   ${ele.speed}, <br />  <b>Ign status:</b> ${ele?.ignstatus}, <br />  <b>Date and Time:</b> ${dateFormat}`, '');
              markerData.push({
                "type": "Feature",
                "properties": {
                  "htmlPopup": `Lat: ${+ele?.latitude}, Lng: ${+ele?.longitude}`
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [+ele?.latitude, +ele?.longitude]
                }
              })
            }
          })
        }

         /**
         * Asset overview 3W rendering
         */
        if (props.fromComponent === "AO" && props.topBarValue === 1 && (props?.legents?.ThreeWheeler | props?.legents?.EV)) {
         await Promise.all(props.overviewData.map(async (element: any) => {
          try{
            let result: any = await axios.get(`${telemetry}/telemetry/lastSyncData?assetId=${element?.assetId}`)
            result = result.data || {};  
            let statusvalue = result.lastSyncTs ? (result.onTrip ? "On Trip" : "Idle") : "";
            let batteryperc = Number(element?.data?.devbatteryPerc).toFixed(2);
            let driver;
            if (element?.driver?.firstName) {
              driver = `${element?.driver?.firstName} ${element?.driver?.lastName} `
            }
            else {
              driver = ' '
            }
            if (element && element?.data && element?.data?.lat) {
              if (statusvalue === "On Trip") {
                const dateFormat = moment(element.data.commtime_timestamp).format('DD-MM-YYYY h:mm a');
                addMarkerEV([element?.data?.lat || 28.61, element?.data?.lng || 77.23], Auto, `<b>Vehicle Number:</b> ${element.vehicleNum}, <br /> 
              <b>Battery Soc:</b>   ${batteryperc || ''}%, <br /> 
              <b>Customer:</b>   ${element?.servedCustomer?.customerDesc || ''},<br />
              <b>Driver:</b>   ${driver},<br />
               <b>Nr. Swap Station Name:</b> ${element?.nearestSwapStation?.name || ''},
                <br /> <b>Nr. Swap Station Distance:</b> ${element?.nearestSwapStation?.distance || ''},
                <br /> <b>Date and Time:</b> ${dateFormat},
                <br /> <b>Status:</b> ${statusvalue || ''}`, "");
                markerData.push({
                  "type": "Feature",
                  "properties": {
                    "htmlPopup": `Lat: ${+element?.data?.lat}, Lng: ${+element?.data?.lng}`
                  },
                  "geometry": {
                    "type": "Point",
                    "coordinates": [+element?.data?.lat, +element?.data?.lng]
                  }
                })
              }
              else {
                const dateFormat = moment(element.data.commtime_timestamp).format('DD-MM-YYYY h:mm a');
                addMarkerEV([element?.data?.lat || 28.61, element?.data?.lng || 77.23], threewheelers, `<b>Vehicle Number:</b> ${element.vehicleNum}, <br /> 
            <b>Battery Soc:</b>   ${batteryperc || ''}%, <br /> 
            <b>Customer:</b>   ${element?.servedCustomer?.customerDesc || ''},<br />
            <b>Driver:</b>   ${driver},<br />
             <b>Nr. Swap Station Name:</b> ${element?.nearestSwapStation?.name || ''},
              <br /> <b>Nr. Swap Station Distance:</b> ${element?.nearestSwapStation?.distance || ''}
              <br /> <b>Date and Time:</b> ${dateFormat},
              <br /> <b>Status:</b> ${statusvalue || ''}`, "");
                markerData.push({
                  "type": "Feature",
                  "properties": {
                    "htmlPopup": `Lat: ${+element?.data?.lat}, Lng: ${+element?.data?.lng}`
                  },
                  "geometry": {
                    "type": "Point",
                    "coordinates": [+element?.data?.lat, +element?.data?.lng]
                  }
                })
              }
            }
          } catch(error) {
            console.log(error)
          }
          }))
        }

        if (props.latLangData && (props?.legents?.ChargeStation | props?.legents?.EV)) {
          await props.latLangData.map(async (ele: any) => {
            addMarkerEV([ele?.lat || 28.61, ele?.lng || 77.23], EV_Fill, `<b>Name:</b> ${ele?.name}, <br /> <b>Address:</b> ${ele?.address},<br /> <b>Map Link:</b> <a href="${ele?.links}" target="_blank">Click</a>`, "", true);
            markerData.push({
              "type": "Feature",
              "properties": {
                "htmlPopup": `Lat: ${+ele?.lat}, Lng: ${+ele?.lng}`
              },
              "geometry": {
                "type": "Point",
                "coordinates": [+ele?.lat, +ele?.lng]
              }
            })
          })
        }
        if (props.hublocation && (props?.legents?.HubLocation)) {
          await props.hublocation.map(async (ele: any) => {
            addMarkerEV([ele?.lat || 28.61, ele?.lng || 77.23], hublocation, `<b>Customer Name:</b> ${ele?.name},<br /> <b>Address:</b> ${ele?.address}`, "", true);
            markerData.push({
              "type": "Feature",
              "properties": {
                "htmlPopup": `Lat: ${+ele?.lat}, Lng: ${+ele?.lng}`
              },
              "geometry": {
                "type": "Point",
                "coordinates": [+ele?.lat, +ele?.lng]
              }
            })
          })
        }
        var geoData = {
          "type": "FeatureCollection",
          "features": markerData
        };
        var marker = mappls.Marker({
          map: map,
          position: geoData,
          fitbounds: true,
          clusters: false,
          fitboundOptions: {
            padding: 120,
            duration: 1000
          },
          popupOptions: {
            offset: {
              'bottom': [0, -20]
            }
          }
        });

        // else {
        //   if (props.selectedIndex !== -1 && props?.legents?.ThreeWheeler) {
        //     const result: any = props?.overviewData.at(props?.selectedIndex);
        //     if (result && result?.data && result?.data?.lat) {
        //       const dateFormat = moment(result.data.commtime_timestamp).format('DD-MM-YYYY h:mm a');
        //       addMarkerEV([result?.data?.lat || 28.61, result?.data?.lng || 77.23], icon3, `<b>Vehicle Number:</b> ${result.vehicleNum}, </br /> <b>Battery Soc:</b>   ${result?.data?.devbattery}%, <br />  <b>Nr. Swap Station Name:</b> ${result?.nearestSwapStation?.name}, <br /> <b>Nr. Swap Station Distance:</b> ${result?.nearestSwapStation?.distance}<br /> <b>Date and Time:</b> ${dateFormat}` , "");
        //       if (!props.legents.Auto) {
        //         markerData.push({
        //           "type": "Feature",
        //           "properties": {
        //             "htmlPopup": `Lat: ${+result.lat}, Lng: ${+result.lng}`
        //           },
        //           "geometry": {
        //             "type": "Point",
        //             "coordinates": [+result.lat, +result.lng]
        //           }
        //         })
        //       }
        //       var geoData = {
        //         "type": "FeatureCollection",
        //         "features": markerData
        //       };
        //       var marker = mappls.Marker({
        //         map: map,
        //         position: geoData,
        //         fitbounds: true,
        //         clusters: false,
        //         fitboundOptions: {
        //           padding: 120,
        //           duration: 1000
        //         },
        //         popupOptions: {
        //           offset: {
        //             'bottom': [0, -20]
        //           }
        //         }
        //       });
        //     }
        //     if (props.legents.Auto) {
        //       showZoomData()
        //     }
        //   }
        // }
      });
      //   if(props.topBarValue === 1){
      //   props.overviewData.map((element: any) => {
      //     if (element && element.data && element.data.lat) {
      //       const dateFormat  = moment(element.data.commtime_timestamp).format('DD-MM-YYYY h:mm a');
      //       addMarker([element.data.lat, element.data.lng], icon3, [element.vehicleNum,  dateFormat], "");
      //     }
      //   })
      // }
      // else {      
      //   if(props.selectedIndex !== -1){
      //  const result:any = props.overviewData.at(props.selectedIndex);
      //  const dateFormat  = moment(result.data.commtime_timestamp).format('DD-MM-YYYY h:mm a');
      //  addMarker([result.data.lat, result.data.lng], icon3, [result.vehicleNum,  dateFormat], "");
      //   }
      // }

    }
    else {
      endLat = "";
      endLong = "";
      startLat = ""
      startLong = ""
      // props.loadingCircleData(false)
      if (document.getElementById('weathermap')) {
        document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
      }

      setMapValues([])
      // var container = L.DomUtil.get('map');
      /* istanbul ignore next */
      // if (container != null) { container._leaflet_id = null; }
      map = new mappls.Map('map', { center: [28.61, 77.23], zoomControl: true, hybrid: true });
      // props.loadingCircleData(falseprops.loadingCircleData(false))

      // document.getElementById('weathermap').innerHTML = "<div id='map' ></div>";
      // var container = L.DomUtil.get('map');
      /* istanbul ignore next */
      // if (container != null) { container._leaflet_id = null; }
      // map = new mappls.Map('map', { center: [28.61, 77.23], zoomControl: true, hybrid: true });
      // props.loadingCircleData(false)
    }

    //
    if (props.evData !== undefined) {
      const evResultData = props.evData.swapStations.twoWheeler;
      evResultData.map((element: any) => {
        if (element && element.lat) {
          addMarkerEV([element.lat, element.lng], icon3, [element.name, ""], "", true);
        }
      })

    }

  }, [props.assetID, props.keys, props.tagId, props.gatewayId, props.overviewData, props.evData, props.filter, props.fromAndTo, props.legents, props.latLngTwoWheeler, props?.legents?.FourWheeler, props.selectedVehicle, props.latLngTruck, props.originalData, props.threeWheeler])


  return (
    <div>
      <div id='weathermap'></div>
    </div>
  )
}