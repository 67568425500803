import React from "react";
import { Grid, InputAdornment, Typography } from "@material-ui/core";
import { InputField, SwitchField } from "../../common/FormFields";
import TemperatureSlider from "../../common/FormFields/TemperatureSlider";
import Switch from '@mui/material/Switch';
import HelpIcon from "@material-ui/icons/Help";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import axios from "axios";
import ShipperHelper from "../../../../services/_helpers";
import { useState } from "react";
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { indicativeKey } from "../../../../services/_url";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

export default function RequirementForm(props: any) {
  let Ship = new ShipperHelper();
  const {
    formField: { volume, weight, instructions, fragileItem, indicativePrice },
    setFieldValue,
  } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      
    
      
    })
  );

  const [messageOpen, setmessageOpen] = useState(false);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event:any) => {
    setChecked(event.target.checked);
  };
  const handleMessage = () => {
    setmessageOpen(false);
  };

  useEffect(() => {
    let fragile: any = props?.values?.fragileItem === false ? 0 : 1;

    if (
      props?.values?.pickupStartDate !== "" &&
      props?.values?.pickupTimeStart !== "" &&
      props?.values?.dropStartDate !== "" &&
      props?.values?.dropTimeStart !== ""
    ) {
      axios
        .post(`${indicativeKey}/dc/${props?.getrequestId}`, {
          expPickupTime: Ship.dateToUTC(
            props?.values?.pickupStartDate +
              " " +
              props?.values?.pickupTimeStart
          ),
          ExpDeliveryTime: Ship.dateToUTC(
            props?.values?.dropStartDate + " " + props?.values?.dropTimeStart
          ),
          currentTime: Ship.dateToUTC(new Date()),
          minTempRequired: props?.values?.temperature[0],
          maxTempRequired: props?.values?.temperature[1],
          distanceInKms: "0",
          shipmentVolume: props?.values?.volume,
          isFragile: fragile,
          instructions: props?.values?.instructions,
        })
        .then((response: any) => {
          setFieldValue(
            indicativePrice.name,
            response.data.data.anticipatedPrice
          );
        })
        .catch((error) => {
          setmessageOpen(true);
          setFieldValue(indicativePrice.name, 0);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    }
  }, [
    props?.values?.pickupStartDate,
    props?.values?.pickupTimeStart,
    props?.values?.dropStartDate,
    props?.values?.dropTimeStart,
    props?.values?.temperature[0],
    props?.values?.temperature[1],
    props?.values?.volume,
    props?.values?.fragileItem,
  ]);

  createStyles({
    root: {
      flexGrow: 1,
    },
  });
  // console.log("PROPSSSSSSSSSSSSSS1", props);

  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root} style={{height:"560px"}}>
      <Grid container spacing={3} style={{marginTop:"2px"}}>
      <Grid item xs={12}>
            
            <TemperatureSlider  name="temperature" />
          
        </Grid>
        <Grid item xs={12} md={12}>
       <div style={{color:"#000",marginTop:'18px',marginLeft:"3.4%"}}>Indicative Capacity</div>   
        </Grid>
        <Grid item xs={12} md={6} >
          <InputField
          style={{width:"90%",marginTop:"14px",marginLeft:"40px"}}
            type="number"
            name={volume.name}
            label={volume.label}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  
                </InputAdornment>
              ),
            }}
            onInput={(e: any) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0,);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
              <InputField
                type="number"
                style={{width:"90%",marginTop:"2.4%"}}
                name={weight.name}
                label={weight.label}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      
                    </InputAdornment>
                  ),
                }}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0,);
                }}
                fullWidth
              />
            </Grid>
            <Grid container spacing={1}>
            <Grid item xs={12} >
            <div style={{color:"#000",marginTop:'18px',marginLeft:"3.4%"}}>  
           Indicative Price{" "}
           <LightTooltip
               title="This is an indicative pricing based on the shipment criteria. The Actual price may differ**"
               placement="top"
             >
                <InfoOutlinedIcon style={{ color: "#707070",fontSize:"20px" }} />
             </LightTooltip>
         </div>
         
             
         
       </Grid>
       <Grid item xs={12} md={6} style={{marginTop:"0.5%",marginLeft:"3.3%"}}>
         {/* <Button style={{ color: 'blue' }} onClick={findIndicativePrice}>Get Indicative Price</Button> */}
         <InputField
           type="number"
           name={indicativePrice.name}
           label={""}
           placeholder="N/A"
           fullWidth
           style={{width:"90%"}}
           // value={getindicativePrice}
           InputProps={{
             readOnly: true,
           }}
         />
        
       </Grid>
       <div style={{border:"1px solid #BEBEBE",height:"60px",marginTop:"0.7%",borderRadius:"3px",marginLeft:"-2.9%",width:"44%"}}> 
            {/* <p>Automatically Approve the request after transporter accepts</p>
            <p style={{marginTop:"-10px"}}>Review price before approving</p> */}
            <FormControl >
     
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel value="female" control={<Radio 
         sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
          color: "grey",
          '&.Mui-checked': {
            color: "rgb(0, 120, 165)",
          },
        }}style={{marginLeft:"17px",marginTop:"-4px"}} />} 
        label={<Typography variant="body2" style={{color:"black",marginTop:"-3px",fontSize:"13px"}}>Automatically Approve the request after transporter accepts</Typography>}></FormControlLabel>
        <FormControlLabel value="male" style={{marginTop:"-23.3px",fontSize:"12px"}} control={<Radio 
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
          color: "grey",
          '&.Mui-checked': {
            color: "rgb(0, 120, 165)",
          },
        }}style={{marginLeft:"17px"}}/>}
         label={<Typography variant="body2" style={{color:"black",marginTop:"2px",fontSize:"13px"}}>Review price before approving</Typography>}/>
      
      </RadioGroup>
    </FormControl>
            </div>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} md={12}  style={{marginTop:"19px"}}>
              <div style={{color:"#000",marginLeft:'3.4%'}}>Item Type</div>
              <div
                
                style={{  marginLeft:"50.7%",color:"#000",marginTop:"-1.6%" }}
              >
                Instructions
              </div>
             
            </Grid>
            <Grid item xs={12} md={6} style={{marginTop:"10px"}}>
              {/* <Box className='border-box2' > */}
              {/* <span className="d-flex flex-row bd-highlight mb-1 border-box2" style={{height:"57px"}}>
                <span
                  className="p-1 bd-highlight align-self-center"
                  style={{ fontSize: "16px",color:"#000" }}
                >
                  {" "}
                  Fragile item{" "}
                </span>
                <br />
                <span style={{paddingTop:"8px",marginLeft:"420px"}}>
                <Switch
                style={{color:"#0078A5",fontWeight:200}}
                
                name={fragileItem.name}
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
             {" "}
                </span>
              </span> */}
               <div style={{border:"1px solid #BEBEBE",height:"57px",marginLeft:"40px",borderRadius:"4px",width:"90%",marginTop:"-7px"}}>
               <div
                 
                  style={{ fontSize: "16px",color:"#000",marginLeft:"3%",marginTop:"2.8%" }}
                >
                  {" "}
                  Fragile item{" "}
                  <div style={{marginLeft:'86%',marginTop:"-5.5%"}}>
                  <Switch
                style={{color:"#0078A5",fontWeight:200}}
                
                name={fragileItem.name}
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
                  </div>
                  
                </div>
               </div>
            </Grid>
            <Grid item xs={12} md={6} >
              <InputField
                type="text"
                name={instructions.name}
                label={instructions.label}
                fullWidth
                style={{width:"90%",marginTop:"4px"}}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
          </Grid>
        </Grid>
          <div>
            <Snackbar
              open={messageOpen}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              autoHideDuration={5000}
              onClose={handleMessage}
            >
              <Alert variant="filled" severity="error">
                API Services not reachable.
              </Alert>
            </Snackbar>
          </div>
      </div>
    </React.Fragment>
  );
}
