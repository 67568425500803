import {
    createStyles,
    makeStyles,
    Theme,
    Button,
    Grid,
    TextField
  } from "@material-ui/core";
  import { useState } from "react";
  import { useLocation, useHistory } from 'react-router-dom';
  import { connect } from 'react-redux';
  import InputLabel from '@mui/material/InputLabel';
  import MenuItem from '@mui/material/MenuItem';
  import FormControl from '@mui/material/FormControl';
  import Select, { SelectChangeEvent } from '@mui/material/Select';
  import moment from "moment";
  import DriverService from '../../../../services/driverService';
  import {toast} from 'react-toastify';
  import React from "react";
  const driverApi = new DriverService();
  toast.configure();
 
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
      boxShadow: "none",
    },
    pageHeader: {
      fontSize: "1.5rem",
    },
    pageTopBar: {
      backgroundColor: "#fff",
    },
    tabsWrapper: {
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
      position: "relative",
      "@media (max-width:500px)": {
        marginTop: "3rem",
      },
    },
  })
  );
  
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  export interface IAssetProps {
    user: any
  }
  
  function DriverData(props: IAssetProps) {
    
    const classes = useStyles();
    const { search } = useLocation();
    //const [value, setValue] = useState(0);
    const[firstName, setFirst] = useState("");
    const[lastName, setLast] = useState("");
    const[mobileNumber, setMobileNumber] = useState("");
    const [status, setStatus] = React.useState("true");
    const[CompanyName,setCompanyName ] = useState("");
    const[Experience,setExperience ] = useState("");
    const[actualStatus, setActualStatus ] = useState(false);
    const[Location, setLocation ] = useState("");
    const [driverId, setDriverId] = useState("");
    //let { id } = useParams<{ id: string }>();
    let history = useHistory();
    const headers = {
        'Authorization': `Bearer ${props.user.token}`,
        'X-Frame-Options':'DENY'
    }
    const handleSave = () => {
      // console.log('button clicked');
      let objData = {
        "mobileNumber": mobileNumber,
        "driver": {
            
                "createdTimestamp": moment().unix(),
                "driverId": driverId,
                "firstName": firstName,
                "lastName": lastName,
                "mobileNumber": mobileNumber,
                "otp": 0,
                "status": status
            }
        }
        // console.log(objData);
        driverApi.addDriver(headers, objData).then((res: any) => {
            // console.log(res);
            if(res.response === "Driver Details have been saved successfully!") {
                toast('Driver data Added succesfully');
                history.push('/driver')
                //setDrivers(res.driverList);
            } else {
                toast("something went wrong!");
            }
        });
    }    
    const handleChange = (event: SelectChangeEvent) => {
      // console.log(event.target.value);
      if(event.target.value === 'true') {
          setStatus(event.target.value as string);
          setActualStatus(true);
          //setAge(event.target.value as string);
      } else {
          setStatus(event.target.value as string);
          setActualStatus(false);
      }
    };
  
    return (
      <div className={classes.root}>
        <div className={classes.pageTopBar}>
          <div className={classes.pageHeader}>
           DriverData
            {/* <div className={classes.addNewAsset}>
                <Button variant="outlined" color="primary" disabled>
                  <AddIcon style={{ color: "#b5b5b5" }} /> ADD NEW ASSET
                </Button>
              </div> */}
          </div>
          <hr></hr>
          <div className="layout-border">
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" InputLabelProps={{ shrink: true }} value={firstName} data-testid="setfirsts" type="Name" label="First Name" variant="outlined" onChange={event => setFirst(event.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" InputLabelProps={{ shrink: true }} value={lastName} type="Name" label="Last Name" variant="outlined" onChange={event => setLast(event.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" InputLabelProps={{ shrink: true }} value={mobileNumber} type="MobileNumber" label="Mobile Number" variant="outlined" onChange={event => setMobileNumber(event.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChange}
                        >
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>InActive</MenuItem>
                        </Select>
                </FormControl>
              </Grid>
             
              {/* <Grid item xs={12}>
              <TextField fullWidth id="outlined-basic" type="CompanyName" label="Company/Tenant Attached To" variant="outlined" onChange={event => setCompanyName(event.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" type="Experience" label="Year of Experience"variant="outlined" onChange={event => setExperience(event.target.value)}  />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" type="Age" label="Age" variant="outlined" onChange={event => setAge(event.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" type="Location" label="Work Location"variant="outlined" onChange={event => setLocation(event.target.value)}  />
              </Grid> */}
              <Grid>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth type="button" color="primary" onClick={handleSave} variant="contained">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
  
  function mapStateToProps(state: any, ownProps: {}) {
    // console.log(state);
    return {
      user: state.user.currentUser
    };
  }
  export default connect(mapStateToProps, null)(DriverData);