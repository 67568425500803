import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Plot from "react-plotly.js";
import TelemeteryAPI from "../../../../services/telemeteryService";
import ShipperHelper from "../../../../services/_helpers";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ss = {
  width: "600",
  "@media (max-width:700px)": {
    width: "300",
  },
};

const ReportGraph = (props: any) => {
  let dateHelper = new ShipperHelper();
  const reportsAPI = new TelemeteryAPI();

  const Headingstyle: any = {
    textAlign: "left",
    marginLeft: "1rem",
    paddingTop: "1.5rem",
    marginBottom: "1rem",
    fontFamily: "Roboto",

    fontSize: "1rem",
    color: "#000000DE",
  };

  const [DatesRange, setDatesRange] = useState("");

  const [VehicleNameX, setVehicleNameX] = useState([]);

  const [TotalHoursY, setTotalHoursY] = useState([]);
  const [WithReeferUnitY, setWithReeferUnitY] = useState([]);
  const [WithoutReeferUnitY, setWithoutReeferUnitY] = useState([]);

  const [TotalHoursKmsY, setTotalHoursKmsY] = useState([]);
  const [WithReeferUnitKmsY, setWithReeferUnitKmsY] = useState([]);
  const [WithoutReeferUnitKmsY, setWithoutReeferUnitKmsY] = useState([]);

  const [NoDataToDisplay, setNoDataToDisplay] = useState(false);

  useEffect(() => {
    let from: any = "";
    let to: any = "";
    if (props.period === "custom") {
      from = props.fromDate;
      to = props.toDate;
    } else {
      let datesForShipment = dateHelper.telemetryDate(props.period);
      from = datesForShipment.substring(5, 25);
      to = datesForShipment.substring(29);
    }
    let assetId: any = [];
    props.assetIds.map((id: any) => (assetId = [...assetId, id.title]));
    let DateFrom: any;
    let DateTo: any;

    let AssetsSet: any = [];
    if (props.assetType === "Reefer Truck") {
      reportsAPI
        .getAssetReportsData(assetId, from, to)
        .then((result: any) => {
          // console.log(result);
          result.map((value: any) => {
            value.assets.map((data: any) => {
              AssetsSet = [
                ...AssetsSet,
                [
                  data.assetID,
                  data.totalHrs,
                  data.reeferHrs,
                  data.dryHrs,
                  data.totalKms,
                  data.reeferKms,
                  data.dryKms,
                ],
              ];
            });
          });

          AssetsSet.sort((a: any, b: any) =>
            a[0].toLowerCase().localeCompare(b[0].toLowerCase())
          );
          let VehiclesNumber = AssetsSet[0][0];

          let VehicleName: any = [];
          let TotalHours: any = [];
          let WithReeferUnit: any = [];
          let WithoutReeferUnit: any = [];

          let TotalHoursKms: any = [];
          let WithReeferUnitKms: any = [];
          let WithoutReeferUnitKms: any = [];

          let VName: any;
          let Hours: any = 0;
          let WithReefer: any = 0;
          let WithoutReefer: any = 0;

          let HoursKms: any = 0;
          let WithReeferKms: any = 0;
          let WithoutReeferKms: any = 0;

          for (let index = 0; index < AssetsSet.length; index++) {
            if (AssetsSet[index][0] === VehiclesNumber) {
              VName = "(" + AssetsSet[index][0] + ")";
              Hours = Hours + parseFloat(AssetsSet[index][1]);
              WithReefer = WithReefer + parseFloat(AssetsSet[index][2]);
              WithoutReefer = WithoutReefer + parseFloat(AssetsSet[index][3]);

              HoursKms = HoursKms + parseFloat(AssetsSet[index][4]);
              WithReeferKms = WithReeferKms + parseFloat(AssetsSet[index][5]);
              WithoutReeferKms =
                WithoutReeferKms + parseFloat(AssetsSet[index][6]);
            } else {
              VehicleName = [...VehicleName, VName];

              TotalHours = [...TotalHours, Hours];
              WithReeferUnit = [...WithReeferUnit, WithReefer];
              WithoutReeferUnit = [...WithoutReeferUnit, WithoutReefer];

              TotalHoursKms = [...TotalHoursKms, HoursKms];
              WithReeferUnitKms = [...WithReeferUnitKms, WithReeferKms];
              WithoutReeferUnitKms = [
                ...WithoutReeferUnitKms,
                WithoutReeferKms,
              ];

              Hours = 0;
              WithReefer = 0;
              WithoutReefer = 0;

              HoursKms = 0;
              WithReeferKms = 0;
              WithoutReeferKms = 0;

              VName = "(" + AssetsSet[index][0] + ")";

              Hours = Hours + parseFloat(AssetsSet[index][1]);
              WithReefer = WithReefer + parseFloat(AssetsSet[index][2]);
              WithoutReefer = WithoutReefer + parseFloat(AssetsSet[index][3]);

              HoursKms = HoursKms + parseFloat(AssetsSet[index][4]);
              WithReeferKms = WithReeferKms + parseFloat(AssetsSet[index][5]);
              WithoutReeferKms =
                WithoutReeferKms + parseFloat(AssetsSet[index][6]);

              VehiclesNumber = AssetsSet[index][0];
            }
          }
          VehicleName = [...VehicleName, VName];

          TotalHours = [...TotalHours, Hours];
          WithReeferUnit = [...WithReeferUnit, WithReefer];
          WithoutReeferUnit = [...WithoutReeferUnit, WithoutReefer];

          TotalHoursKms = [...TotalHoursKms, HoursKms];
          WithReeferUnitKms = [...WithReeferUnitKms, WithReeferKms];
          WithoutReeferUnitKms = [...WithoutReeferUnitKms, WithoutReeferKms];

          let CumulativeTotalHours: any = 0;
          let CumulativeWithReeferUnit: any = 0;
          let CumulativeWithoutReeferUnit: any = 0;

          let CumulativeTotalHoursKms: any = 0;
          let CumulativeWithReeferUnitKms: any = 0;
          let CumulativeWithoutReeferUnitKms: any = 0;

          if (assetId.length === 0) {
            for (let index = 0; index < TotalHours.length; index++) {
              CumulativeTotalHours += TotalHours[index];
            }
            for (let index = 0; index < WithReeferUnit.length; index++) {
              CumulativeWithReeferUnit += WithReeferUnit[index];
            }
            for (let index = 0; index < WithoutReeferUnit.length; index++) {
              CumulativeWithoutReeferUnit += WithoutReeferUnit[index];
            }

            for (let index = 0; index < TotalHoursKms.length; index++) {
              CumulativeTotalHoursKms += TotalHoursKms[index];
            }
            for (let index = 0; index < WithReeferUnitKms.length; index++) {
              CumulativeWithReeferUnitKms += WithReeferUnitKms[index];
            }
            for (let index = 0; index < WithoutReeferUnitKms.length; index++) {
              CumulativeWithoutReeferUnitKms += WithoutReeferUnitKms[index];
            }

            setVehicleNameX(["(Cumulative)"]);

            setTotalHoursY([CumulativeTotalHours]);
            setWithReeferUnitY([CumulativeWithReeferUnit]);
            setWithoutReeferUnitY([CumulativeWithoutReeferUnit]);

            setTotalHoursKmsY([CumulativeTotalHoursKms]);
            setWithReeferUnitKmsY([CumulativeWithReeferUnitKms]);
            setWithoutReeferUnitKmsY([CumulativeWithoutReeferUnitKms]);
          } else {
            setVehicleNameX(VehicleName);

            setTotalHoursY(TotalHours);
            setWithReeferUnitY(WithReeferUnit);
            setWithoutReeferUnitY(WithoutReeferUnit);

            setTotalHoursKmsY(TotalHoursKms);
            setWithReeferUnitKmsY(WithReeferUnitKms);
            setWithoutReeferUnitKmsY(WithoutReeferUnitKms);
          }
          // DateFrom = (from).substring(0, 10)
          // DateTo = (to).substring(0, 10)
          DateFrom = dateConversion(from.substring(0, 10));
          DateTo = dateConversion(to.substring(0, 10));
          setDatesRange(DateFrom + " to " + DateTo);
          setNoDataToDisplay(true);
        })
        .catch((err: any) => {
          console.error(err);
          setNoDataToDisplay(false);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    } else {
      let CoolerDailyData: any = [];

      reportsAPI
        .getAssetReportCoolerBoxDialyData(assetId, from, to)
        .then((res: any) => {
          if (res != 0) {
            res.map((result: any) => {
              result.assets.map((data: any) => {
                // console.log(data);
                CoolerDailyData = [
                  ...CoolerDailyData,
                  [data.assetID, data.tripOverviewMins, data.tripOverviewKms],
                ];
              });
            });
            CoolerDailyData.sort((a: any, b: any) =>
              a[0].toLowerCase().localeCompare(b[0].toLowerCase())
            );
            let VehiclesNumber = CoolerDailyData[0][0];

            let VehicleName: any = [];
            let TotalHoursRun: any = [];
            let TotalKmsRun: any = [];

            let VName: any;
            let TotalHours: any = 0;
            let TotalKms: any = 0;
            let TotHoursCalculation: any = 0;
            let TotHoursConverttoNumber: any = 0;

            for (let index = 0; index < CoolerDailyData.length; index++) {
              if (CoolerDailyData[index][0] === VehiclesNumber) {
                VName = "(" + CoolerDailyData[index][0] + ")";
                TotHoursCalculation =
                  parseFloat(CoolerDailyData[index][1]) / 60;
                TotHoursConverttoNumber = TotHoursCalculation.toFixed(2);
                TotalHours = TotalHours + Number(TotHoursConverttoNumber);
                TotalKms = TotalKms + parseFloat(CoolerDailyData[index][2]);
              } else {
                VehicleName = [...VehicleName, VName];
                TotalHoursRun = [...TotalHoursRun, TotalHours];
                TotalKmsRun = [...TotalKmsRun, TotalKms];

                TotalHours = 0;
                TotalKms = 0;
                TotHoursCalculation = 0;
                TotHoursConverttoNumber = 0;

                VehiclesNumber = CoolerDailyData[index][0];

                VName = "(" + CoolerDailyData[index][0] + ")";
                TotHoursCalculation =
                  parseFloat(CoolerDailyData[index][1]) / 60;
                TotHoursConverttoNumber = TotHoursCalculation.toFixed(2);
                TotalHours = TotalHours + Number(TotHoursConverttoNumber);
                TotalKms = TotalKms + parseFloat(CoolerDailyData[index][2]);
              }
            }
            VehicleName = [...VehicleName, VName];
            TotalHoursRun = [...TotalHoursRun, TotalHours];
            TotalKmsRun = [...TotalKmsRun, TotalKms];

            let CumulativeTotalHours: any = 0;
            let CumulativeTotalKms: any = 0;

            if (assetId.length === 0) {
              for (let index = 0; index < TotalHoursRun.length; index++) {
                CumulativeTotalHours += TotalHoursRun[index];
              }
              for (let index = 0; index < TotalKmsRun.length; index++) {
                CumulativeTotalKms += TotalKmsRun[index];
              }

              setVehicleNameX(["(Cumulative)"]);
              setTotalHoursY([CumulativeTotalHours]);
              setTotalHoursKmsY([CumulativeTotalKms]);
            } else {
              setVehicleNameX(VehicleName);
              setTotalHoursY(TotalHoursRun);
              setTotalHoursKmsY(TotalKmsRun);
            }

            DateFrom = dateConversion(from.substring(0, 10));
            DateTo = dateConversion(to.substring(0, 10));
            setDatesRange(DateFrom + " to " + DateTo);
            setNoDataToDisplay(true);
          } else {
            setNoDataToDisplay(false);
          }
        })
        .catch((err: any) => {
          console.error(err);
          setNoDataToDisplay(false);
          const UserToken = new ShipperHelper();
          UserToken.UserTokenExpiration();
        });
    }
  }, [props.period, props.assetIds, props.assetType]);

  const dateConversion = (rowDate: any) => {
    if (rowDate) {
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let date = new Date(rowDate);
      let year = String(date.getFullYear());
      let month = date.getMonth();
      let day = date.getDate();
      let finalDate = day + "-" + monthNames[month] + "-" + year.substr(2, 2);
      return finalDate;
    } else {
      return "----";
    }
  };

  var TotalHours: any = {
    x: VehicleNameX,
    y: TotalHoursY,
    width:
      VehicleNameX.length === 1
        ? [0.1, 0.1, 0.1, 0.1, 0.1]
        : [0.2, 0.2, 0.2, 0.2, 0.2],
    type: "bar",
    name: "Total Hours",
    marker: {
      color: "#0E6BA6",
    },
  };
  var WithReeferUnit: any = {
    x: VehicleNameX,
    y: WithReeferUnitY,
    width:
      VehicleNameX.length === 1
        ? [0.1, 0.1, 0.1, 0.1, 0.1]
        : [0.2, 0.2, 0.2, 0.2, 0.2],
    type: "bar",
    name: "With Reefer Unit",
    marker: {
      color: "#1692BD",
    },
  };
  var WithoutReeferUnit: any = {
    x: VehicleNameX,
    y: WithoutReeferUnitY,
    width:
      VehicleNameX.length === 1
        ? [0.1, 0.1, 0.1, 0.1, 0.1]
        : [0.2, 0.2, 0.2, 0.2, 0.2],
    type: "bar",
    name: "Without Reefer Unit",
    marker: {
      color: "#5BC2E7",
    },
  };

  var TotalHoursKms: any = {
    x: VehicleNameX,
    y: TotalHoursKmsY,
    width:
      VehicleNameX.length === 1
        ? [0.1, 0.1, 0.1, 0.1, 0.1]
        : [0.2, 0.2, 0.2, 0.2, 0.2],
    type: "bar",
    name: "Total Kms",
    marker: {
      color: "#582C83",
    },
  };
  var WithReeferUnitKms: any = {
    x: VehicleNameX,
    y: WithReeferUnitKmsY,
    width:
      VehicleNameX.length === 1
        ? [0.1, 0.1, 0.1, 0.1, 0.1]
        : [0.2, 0.2, 0.2, 0.2, 0.2],
    type: "bar",
    name: "With Reefer Unit",
    marker: {
      color: "#8246AF",
    },
  };
  var WithoutReeferUnitKms: any = {
    x: VehicleNameX,
    y: WithoutReeferUnitKmsY,
    width:
      VehicleNameX.length === 1
        ? [0.1, 0.1, 0.1, 0.1, 0.1]
        : [0.2, 0.2, 0.2, 0.2, 0.2],
    type: "bar",
    name: "Without Reefer Unit",
    marker: {
      color: "#A77BCA",
    },
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} style={{marginTop:"7px"}}>
          <Grid item xs={12} md={6} lg={6}  style={{marginLeft:"-12px"}}>
            <Item style={{marginTop:"-25px",marginLeft:"-2px",height:"28rem",boxShadow:"none",marginRight:"-6px"}}>
              <div id="HoursRun">
                <div style={Headingstyle} >Hours Run</div>
                {NoDataToDisplay ? (
                  <div style={{width:"35.6rem"}}>
                    <Plot
                      data={
                        props.assetType === "Reefer Truck"
                          ? [WithoutReeferUnit, WithReeferUnit, TotalHours]
                          : [TotalHours]
                      }
                      layout={{
                        title: DatesRange,
                        margin: { l: 43, r: 30, b: 40, t: 40 },
                        xaxis: {
                          tickfont: {
                            family: "Roboto",
                            size: 14,
                          },
                          nticks: VehicleNameX.length,
                        },
                        yaxis: {
                          tickfont: {
                            family: "Roboto",
                            size: 14,
                          },
                          rangemode: "nonnegative",
                        },
                        barmode: "relative",
                        height: 350,

                        showlegend: true,
                        legend: {
                          x: 0.1,
                          y: -0.5,
                          orientation: "h",
                        },
                      }}
                      style={ss}
                      config={{
                        displayModeBar: false,
                        responsive: true,
                        displaylogo: false,
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ height: "410px", paddingTop: "20%",color:"#000" }}>
                    No Data to Display
                  </div>
                )}
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={6} lg={6} style={{marginLeft:"12px"}}>
            <Item style={{marginTop:"-25px",marginLeft:"-2px",height:"28rem",boxShadow:"none",marginRight:"-18px"}} >
              <div id="DistanceTravelled">
                <div style={Headingstyle}>Distance Travelled (Kms)</div>
                {NoDataToDisplay ? (
                  <div style={{width:"35.6rem"}}>
                    <Plot
                      data={
                        props.assetType === "Reefer Truck"
                          ? [
                              WithoutReeferUnitKms,
                              WithReeferUnitKms,
                              TotalHoursKms,
                            ]
                          : [TotalHoursKms]
                      }
                      layout={{
                        title: DatesRange,
                        margin: { l: 43, r: 30, b: 40, t: 40 },
                        xaxis: {
                          tickfont: {
                            family: "Roboto",
                            size: 14,
                          },
                          nticks: VehicleNameX.length,
                        },
                        yaxis: {
                          tickfont: {
                            family: "Roboto",
                            size: 14,
                          },
                          rangemode: "nonnegative",
                        },
                        barmode: "relative",
                        height: 350,

                        showlegend: true,
                        legend: {
                          x: 0.1,
                          y: -0.5,
                          orientation: "h",
                        },
                      }}
                      style={ss}
                      config={{
                        displayModeBar: false,
                        responsive: true,
                        displaylogo: false,
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ height: "410px", paddingTop: "20%",color:"#000" }}>
                    No Data to Display
                  </div>
                )}
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ReportGraph;
