import axios from 'axios';
import {assetBaseURL, feedbackBaseURL, marketBaseURL, telemetry, tenantandUser} from './_url';

export default class ShipperAPI{


  getRequestByRequestNumber=(id:string) =>{
    return axios.get(`${marketBaseURL}/marketplace/shippingRequests/reqId/${id}`)
      .then((response: any) => response.data);
  }
  getByQuery = (query: any) => {
    query.page = query?.page ? query.page : 0;
    query.rowsPerPage = query?.rowsPerPage ? query.rowsPerPage : 10;
    return axios.get(`${marketBaseURL}/marketplace/shippingRequests/?page=${query.page}&size=${query.rowsPerPage}`)
      .then((response: any) => response.data);
  }
//  paginated_fetch= (page:any=0,previousResponse:any =[]) :any=> {
//  let response=[...previousResponse];
//  return axios.post(`${marketBaseURL}/marketplace/shippingRequests/?page=${page}`) // Append the page number to the base URL
//    .then((newResponse:any) => {

  
//       response = [...previousResponse,...newResponse.data.data]; // Combine the two arrays

      
//      if (newResponse.data.length === 20) {
//        page++;

//         return   this.paginated_fetch(page, response);
//      }
    
//      return response;
     
    
//    });
  
  
// }
getRequest= async () => {
  try {
    return await axios.get(`${marketBaseURL}/marketplace/shippingRequests`);
  } catch (e) {
    return e;
  }
 
}
getRequestByID=(id:string) =>{
  return axios.get(`${marketBaseURL}/marketplace/shippingRequests/${id}`)
    .then(res => {
      return res.data;
       
    });

}

getShipperID=() =>{
  
  return axios.get(`${tenantandUser}/tenants`).then(res => {return res.data});
}
getUserId=() =>{
  return axios.get(`${tenantandUser}/users`)
    .then((res) => {
    
      return  res.data;
       
    });
  }
postRequest=async (postData:any) =>{
 

    try {
      return await axios.post(`${marketBaseURL}/marketplace/shippingRequests`,postData).then((res)=>{
        return res.data
      })
    } catch (e) {
      return e;
    }

}

putRequest=async (patchData: any,id:any) =>{

  try {
    return await axios.patch(`${marketBaseURL}/marketplace/shippingRequests/${id}`,patchData).then((res)=>{
      return res.data
    })
  } catch (e) {
    return e;
  }
 

}
deleteRequest=(id:string)=>{
  return axios.delete(`${marketBaseURL}/marketplace/shippingRequests/${id}`).then(res => res.data);
}
getAsset=()=>{
  return axios.get(`${assetBaseURL}/assets?page=0&size=50`).then(res => {return res.data});
}
getFeedbackByID=(id:string)=>{
  return axios.get(`${feedbackBaseURL}/feedbacks/forshipment/${id}`).then(res => {return res.data});
}
getTelemetryDataByID=(id:string)=>{
  return axios.get(`${telemetry}/telemetry/aggregation/${id}`).then(res => {return res.data});
}
}
