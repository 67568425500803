import {
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import React from "react";
import "./style.css";
import { TableFilterFormData } from "../../Dashboard/Common/Interfaces/custom";
import Helper, { ShippingRequestStatus } from "../../../../services/_helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: "12pt",
    },
    container: {},
    header: {
      fontSize: "1.25rem",
      padding: "0.75rem 1.5rem",
      borderBottom: "1px solid #c1c1c1",
    },
    content: {
      padding: "0.75rem 1.5rem",
      overflowX: "hidden",
      height: "28vh",
      overflowY: "auto",
    },
    footer: {
      padding: "0.75rem 1.5rem",
      boxShadow: "0px 3px 11px #c1c1c1",
    },
    filterLabel: {
      fontSize: "1rem",
      letterSpacing: "1px",
    },
    filterField: {},
    filterFieldFormControl: {
      margin: "8px 0px",
      width: "32rem",
      height: "4rem",
      "@media (max-width:700px)": {
        width: "26rem",
      },
      "@media (max-width:500px)": {
        width: "20rem",
      },
    },
    filterSelectFormControl: {
      margin: "8px 0px",
      width: "32rem",
      height: "4rem",
    },
    floatRight: {
      float: "right",
    },
    clearFilterButtonMargin: {
      marginRight: "1rem",
    },
  })
);

export interface ITableFilterProps {
  setFilterOptions: any;
  filterOptions: any;
  closeTableFilter: any;
}
export default function TableFilter(props: ITableFilterProps) {
  const { setFilterOptions, filterOptions, closeTableFilter } = props;
  const classes = useStyles();
  const helper = new Helper();
  const allStatuses = [
    {
      title: ShippingRequestStatus.PendingAcceptance,
      value: helper.getStatusKeyByValue(
        ShippingRequestStatus.PendingAcceptance
      ),
    },
    {
      title: ShippingRequestStatus.Accepted,
      value: helper.getStatusKeyByValue(ShippingRequestStatus.Accepted),
    },
    {
      title: ShippingRequestStatus.InTransit,
      value: helper.getStatusKeyByValue(ShippingRequestStatus.InTransit),
    },
    {
      title: ShippingRequestStatus.Completed,
      value: helper.getStatusKeyByValue(ShippingRequestStatus.Completed),
    },
    {
      title: ShippingRequestStatus.Cancelled,
      value: helper.getStatusKeyByValue(ShippingRequestStatus.Cancelled),
    },
  ];
  // const filteredStatuses = isActiveRequests ? [allStatuses[0], allStatuses[1], allStatuses[2]] : [allStatuses[3]];
  // commented by sarath on 27.09.2021 - if statuses need to be filtered based selected tab
  const [statuses, setStatuses] = React.useState(
    filterOptions?.status ? filterOptions.status : []
  );

  /* const handleCityChange = (event: any) => {
      setCity(event.target.value);
    };
    const handleSliderChange = (event: any, newValue: number | number[]) => {
        setSliderValue(newValue as number[]);
    };
    commented by sarath on 08.10.2021 - if other parameters of shipping requests needs to be filtered
    */
  const handleStatusesChange = (event: any, value: any) => {
    setStatuses(value);
  };
  const handleCancel = (event: any) => {
    closeTableFilter();
  };
  const handleClearFilters = (event: any) => {
    setStatuses([]);
  };
  const handleApplyFilter = (event: any) => {
    const formData: TableFilterFormData = {
      status: statuses,
      // providers: [''],
      // city: 'Bengaluru',
      // temperatureRange: [-1, 3]
    };
    // above properties are commented by sarath as those are in CR
    /* statuses.forEach((status: any) => {
            formData.status.push(status.value);
        }); */
    // debugger
    sessionStorage.setItem(
      "ApplyFilter",
      statuses.length === 0 ? null : JSON.stringify(formData)
    );
    setFilterOptions(statuses.length === 0 ? null : formData);
    closeTableFilter();
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          Filter Options{" "}
          {/* filterOptionsCount ? `(${filterOptionsCount})` : '' */}
        </div>
        <div className={classes.content}>
          <div className={classes.filterLabel}>Status</div>
          <div className={classes.filterField}>
            <FormControl
              variant="outlined"
              className={classes.filterFieldFormControl}
            >
              <Autocomplete
                multiple
                id="filter-status-tags-outlined"
                data-testid="filter-status-dropdown"
                options={allStatuses}
                onChange={handleStatusesChange}
                getOptionLabel={(option) => option.title}
                getOptionDisabled={(option) => {
                  let isOptionSelected: boolean = false;
                  statuses.forEach((status: any) => {
                    if (option.value === status.value) {
                      isOptionSelected = true;
                    }
                  });
                  return isOptionSelected;
                }}
                value={statuses}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={null}
                    placeholder="Search&#8230;"
                  />
                )}
              />
            </FormControl>
          </div>
          {/* commented by sarath as the below properties are under CR
                    <div className={classes.filterLabel}>
                        Provider
                    </div>
                    <div className={classes.filterField}>
                        <FormControl variant="outlined" className={classes.filterFieldFormControl}>
                            <Autocomplete
                                multiple
                                id="fitler-providers-tags-outlined"
                                options={filteredProviders}
                                getOptionLabel={(option) => option.title}
                                defaultValue={filteredProviders}
                                filterSelectedOptions
                                popupIcon={<ExpandMore />}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={null}
                                    placeholder="Search&#8230;"
                                />
                                )}
                            />
                        </FormControl>
                    </div>
                    <div className={classes.filterLabel}>
                        Location
                    </div>
                    <div className={classes.filterField}>
                        <FormControl variant="outlined" className={classes.filterSelectFormControl}>
                            <InputLabel id="denso-city-select-outlined-label">City</InputLabel>
                            <Select
                                labelId="denso-city-select-outlined-label"
                                id="denso-city-select-outlined"
                                value={city}
                                onChange={handleCityChange}
                                label="City"
                                IconComponent={ExpandMore}
                            >
                                <MenuItem value="">
                                    <em>Select&#8230;</em>
                                </MenuItem>

                                <MenuItem value={1}>Bengaluru</MenuItem>
                                <MenuItem value={2}>Chennai</MenuItem>
                                <MenuItem value={3}>Delhi</MenuItem>
                                <MenuItem value={4}>Mumbai</MenuItem>
                                <MenuItem value={5}>Kolkata</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.filterLabel}>
                        Temperature
                    </div>
                    <div className={classes.filterField} style={{marginTop: '3rem'}}>
                        <Slider
                            value={sliderValue}
                            onChange={handleSliderChange}
                            step={0.5}
                            aria-labelledby="range-slider"
                            getAriaValueText={sliderValueText}
                            valueLabelDisplay="on"
                            min={-15}
                            max={40}
                        />
                    </div> */}
        </div>
        <div className={classes.footer}>
          <Button data-testid="filter-cancel" onClick={handleCancel}>
            CANCEL
          </Button>
          <div className={classes.floatRight}>
            <Button
              color="primary"
              data-testid="filter-clear"
              className={classes.clearFilterButtonMargin}
              onClick={handleClearFilters}
            >
              CLEAR FILTERS
            </Button>
            <Button
              color="primary"
              data-testid="filter-apply"
              variant="outlined"
              onClick={handleApplyFilter}
            >
              APPLY
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
