export const setAssetToken = (assetToken:any) => {
    sessionStorage.setItem("assetToken", assetToken);
  };

export const getAssetToken = () => {
    return sessionStorage.getItem("assetToken") || "true";
  };


  export const getToken = () => {
    return JSON.parse(sessionStorage.getItem("token")) || "";
  };